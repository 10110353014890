import { FC, Fragment } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { RedoxCategoryType } from "DataTypes/PatientManagement.type";
import RedoxApi from "Service/Redox.api";
import CstmAccordion1 from "Components/CstmAccordion";
import { CommonStyle, Loader } from "Components";
import { CatSearchDocType } from "DataTypes/Services/Redox.type";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import ImgPath from "Constant/Imgs";

const Category: FC<{
  data: RedoxCategoryType[];
  loading: boolean;
  setViewDoc: (data: CatSearchDocType) => void;
}> = ({ data, loading, setViewDoc }) => {
  const dispatch = useDispatch();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );

  const handleClick: (data: CatSearchDocType, action: string) => void = async (
    data,
    action
  ) => {
    const { contentType, documentName, path } = data;
    const payload = {
      contentType,
      filePath: path,
      documentName,
    };
    const res = await RedoxApi.DocDownload({
      data: payload,
      id1: patientInfo.id,
    });
    if (res?.success) {
      const binaryResponse = res.data;
      const blob = new Blob([binaryResponse], {
        type: contentType,
      });
      const link = document.createElement("a");
      link.download = documentName;
      link.href = `${window.URL.createObjectURL(blob)}#toolbar=0&navpanes=0`;
      if (action == "view") {
        setViewDoc({ ...data, url: link.href });
      } else {
        link.click();
        dispatch(
          setAlertData({
            alertMessage: `${documentName} is downloading...`,
            alertVariant: "success",
            openAlert: true,
          })
        );
      }
    }
  };

  const SubContent: FC<{ data: RedoxCategoryType }> = ({ data }) => {
    return (
      <Fragment>
        {data.docList.map((docEl) => {
          return (
            <Box display={"flex"} alignItems="center" key={docEl.id}>
              <Typography width="80%" variant="subtitle2">
                {docEl.documentName}
              </Typography>
              <Box width="20%" display="flex" justifyContent="flex-end">
                {docEl.fileType == ".pdf" && (
                  <IconButton
                    sx={{ p: 0, mr: 1 }}
                    onClick={() => handleClick(docEl, "view")}
                  >
                    <ImgPath.RemoveRedEyeOutlined fontSize="small" />
                  </IconButton>
                )}
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => handleClick(docEl, "download")}
                >
                  <ImgPath.DownloadIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          );
        })}
      </Fragment>
    );
  };

  return (
    <Box sx={{ ...sxMain }}>
      {loading ? (
        <Loader />
      ) : (
        data.map((el) => {
          return (
            <CstmAccordion1
              key={el.id}
              mainContent={
                <Box
                  sx={{ ...CommonStyle.sxRow, justifyContent: "space-between" }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {el.label}
                  </Typography>
                  <Typography variant="subtitle1" mr={1}>
                    {el.count || 0}
                  </Typography>
                </Box>
              }
              subContent={<SubContent data={el} />}
            />
          );
        })
      )}
    </Box>
  );
};
export default Category;

const sxMain = {
  height: "100%",
  overflow: "auto",
  px: 2,
};
