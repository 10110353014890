import { GlobalType } from "DataTypes/Services/Patient.type";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";
import DateTime from "Utils/DateTime";

const GlobalList: (data: any) => GlobalType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: GlobalType[] = CheckedArr.map((el, index) => {
    const provider = CheckStringEmpty(el?.teamProviderName);
    const providerDes = CheckStringEmpty(el?.teamProviderDesignation);
    const firstName = CheckStringEmpty(el.patientFirstName);
    const middleName = CheckStringEmpty(el.patientMiddleName);
    const lastName = CheckStringEmpty(el.patientLastName);
    const name =
      (firstName ? `${firstName} ` : "") +
      (middleName ? `${middleName} ` : "") +
      (lastName ? lastName : "");
    return {
      contact1: CheckStringEmpty(el.patientHomeNumber),
      contact2: CheckStringEmpty(el.patientMobileNumber),
      dob: DateTime.ValidDate(el.patientDob),
      id: index,
      img: "",
      location: CheckStringEmpty(el?.practiceLocationName),
      locationId: CheckNumber(el?.practiceLocationId),
      name,
      patientId: CheckStringId(el?.patientId),
      practice: CheckStringEmpty(el?.practiceName),
      practiceId: CheckNumber(el?.practiceId),
      primaryins: CheckStringEmpty(el?.primaryInsurance),
      program: CheckStringEmpty(el?.abbrevation),
      provider: provider
        ? `${provider}${providerDes ? ", " + providerDes : ""}`
        : "",
      providerId: CheckNumber(el?.teamProviderId),
      secondaryins: CheckStringEmpty(el?.secondaryInsurance),
      status: CheckStringEmpty(el?.status),
      timezone: CheckStringEmpty(el?.practiceLocationTimezone),
    };
  });
  return newArr;
};

export default { GlobalList };
