import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from ".";

const read: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `categories/${props.id1}/read`,
    data: {},
  };
  return post(request);
};

const list: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: "categories/search",
    data: { data: data.data },
  };
  return post(request);
};

const create: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "categories/create",
    data: { data: payload.data },
  };
  return post(request);
};

const update: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `categories/${payload.id1}/update`,
    data: { data: payload.data },
  };
  return post(request);
};

const updateDelete: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `categories/${payload.id1}/delete`,
  };
  return post(request);
};

export default { list, create, update, updateDelete, read };
