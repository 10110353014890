import { FC, Fragment } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import { SideModalProps } from "DataTypes/Modal";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import PatientFormatter from "Utils/Patient.Formatter";
import DateTime from "Utils/DateTime";

const ProfileModal: FC<SideModalProps> = () => {
  const { patientInfo, patientOrg }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  return (
    <Box sx={{ "& >div": { ...CommonStyle.sxRow, mb: 1 } }}>
      <Fragment>
        <Box width={80} height={80} sx={{ ...CommonStyle.sxRowCenter }}>
          <Avatar src={""} alt="" sx={{ height: "100%", width: "100%" }} />
        </Box>
        <Box>
          <ImgPath.PersonIcon color="secondary" fontSize="small" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {patientInfo.name} ({patientInfo.age}, {patientInfo.gender})
          </Typography>
        </Box>
        <Box>
          <ImgPath.CalendarIcon color="secondary" fontSize="small" />
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {DateTime.ToLocalDate(patientInfo.dob)}
          </Typography>
        </Box>
        <Box>
          <ImgPath.LocationIcon color="secondary" fontSize="small" />
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {patientInfo.address}
          </Typography>
        </Box>
        <Box>
          <ImgPath.CallIcon color="secondary" fontSize="small" />
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {PatientFormatter.MobileNumber(patientInfo.home)}
          </Typography>
        </Box>
        <Box>
          <ImgPath.SmartphoneIcon color="secondary" fontSize="small" />
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {PatientFormatter.MobileNumber(patientInfo.mobile)}
          </Typography>
        </Box>
        <Box>
          <ImgPath.EmailIcon color="secondary" fontSize="small" />
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {patientInfo.email}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          fontWeight="bold"
          sx={{
            borderTop: "1px solid",
            borderColor: "custom.main",
            pt: 1,
            my: 1,
          }}
        >
          Organization Details
        </Typography>
        <Box>
          <ImgPath.OrganisationIcon color="secondary" fontSize="small" />
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {patientOrg.practiceName}
          </Typography>
        </Box>
        <Box>
          <ImgPath.LocationIcon color="secondary" fontSize="small" />
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {patientOrg.practiceLocationName}
          </Typography>
        </Box>
        <Box>
          <ImgPath.AddTimeLogIcon color="secondary" fontSize="small" />
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {patientOrg.practiceLocationTimezone}
          </Typography>
        </Box>
      </Fragment>
    </Box>
  );
};
export default ProfileModal;
