import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { getWeightData, HeadData } from "../Data";
import { ControlledForm, CommonStyle } from "Components";
import { GlobalThresholdProps } from "DataTypes/SettingsFormatter.type";
import { CheckStringEmpty } from "Utils/common";
import { probUOMType } from "DataTypes/Services/Settings.type";

const ProblemAlert: FC<GlobalThresholdProps> = ({
  data,
  deviceName,
  handleChange,
  singleDevice,
  isView,
}) => {
  const uniqueUomIds: probUOMType[] = [];
  data &&
    data.forEach((item) => {
      if (
        !uniqueUomIds.some(
          (uom) => uom.uomId !== "WEIGHT" && uom.uomId === item.uomId
        )
      ) {
        uniqueUomIds.push({
          uomId: item.uomId,
          uomLabel: item.uomLabel,
          problemId: item.problemId,
          problemName: item.problemName,
        });
      }
    });

  return (
    <Box display="flex">
      {!singleDevice && (
        <Typography
          width="14%"
          fontWeight="bold"
          sx={{
            ...CommonStyle.sxRowCenter,
            ...sxHeadBox,
          }}
        >
          {deviceName}
        </Typography>
      )}
      <Box width={singleDevice ? "100%" : "86%"}>
        {data &&
          uniqueUomIds
            .filter((filEl) =>
              data.find((findEl) => findEl.uomId == filEl.uomId)
            )
            .map((el) => {
              const filterArr = data.filter(
                (filEl1) => filEl1.uomId == el.uomId
              );
              const filterSingleData = filterArr.find(
                (item) => item.problemId === el.problemId
              );
              return (
                <Box display="flex" key={el.uomId}>
                  <Typography
                    width="15%"
                    sx={{
                      ...CommonStyle.sxRowCenter,
                      ...sxHeadBox,
                    }}
                    variant="body2"
                  >
                    {el.uomId === "WEIGHT" ? el.problemName : el.uomLabel}
                  </Typography>
                  {el.uomId !== "WEIGHT"
                    ? filterArr.length &&
                      HeadData.map((headEl) => {
                        return (
                          <Box
                            display="flex"
                            key={headEl.id}
                            mx={0.5}
                            width="17%"
                          >
                            {headEl.range.map((unitEl) => {
                              const valueEl = filterArr.find(
                                (valEl) => valEl.alertType == headEl.id
                              );
                              return (
                                <Box key={unitEl.id} width={unitEl.width}>
                                  <ControlledForm.Input
                                    id={unitEl.id}
                                    value={valueEl ? valueEl[unitEl.id] : ""}
                                    sxProps={{
                                      ...sxInputBox,
                                    }}
                                    readOnly={!valueEl ? true : isView}
                                    textAlignCenter={true}
                                    type="number"
                                    onChange={(el) =>
                                      valueEl &&
                                      handleChange({
                                        ...valueEl,
                                        [unitEl.id]: CheckStringEmpty(el),
                                      })
                                    }
                                  />
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      })
                    : filterSingleData &&
                      filterArr.length && (
                      <Box display="flex" mx={0.5} width="86%">
                        {getWeightData(filterSingleData)?.map((weightEl) => {
                          return (
                            <>
                              {weightEl.type !== "input" ? (
                                <Box key={weightEl.id} width={weightEl.width}>
                                  <Typography
                                    textAlign="center"
                                    sx={{
                                      border: "1px solid",
                                      borderColor: "custom.main",
                                      color: weightEl?.color,
                                      fontSize:
                                          (weightEl?.id !== "criteria" &&
                                            "small") ||
                                          "",
                                      fontWeight:
                                          weightEl?.id === "criteria"
                                            ? "bold"
                                            : "",
                                    }}
                                    py={1}
                                  >
                                    {weightEl.label}
                                  </Typography>
                                </Box>
                              ) : (
                                <Box key={weightEl.id} width={weightEl.width}>
                                  <ControlledForm.Input
                                    id={weightEl.id}
                                    value={
                                      weightEl.label
                                        ? String(weightEl.label)
                                        : ""
                                    }
                                    sxProps={{
                                      ...sxInputBox,
                                    }}
                                    readOnly={isView}
                                    textAlignCenter={true}
                                    type="number"
                                    onChange={(el) =>
                                      handleChange({
                                        ...filterSingleData,
                                        [weightEl.id]: CheckStringEmpty(el),
                                      })
                                    }
                                  />
                                </Box>
                              )}
                            </>
                          );
                        })}
                      </Box>
                    )}
                </Box>
              );
            })}
      </Box>
    </Box>
  );
};
export default ProblemAlert;

const sxHeadBox = {
  backgroundColor: "bg.main",
  border: "1px solid",
  borderColor: "custom.main",
  textAlign: "center",
};

const sxInputBox = {
  boxShadow: 0,
  borderRadius: 0,
  border: "1px solid",
  borderColor: "custom.main",
};
