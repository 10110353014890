import {
  AppSettingListType,
  RedoxSettingListType,
  RPMAlertType,
  SmartmeterSettingListType,
} from "DataTypes/Services/Settings.type";
import {
  CheckStringEmpty,
  CheckNumber,
  CheckArr,
  CheckBoolean,
  CheckTypeId,
  CheckAlertType,
  CheckStringId,
  CheckAlertStatus,
} from "Utils/common";
import DateTime from "./DateTime";

const formateRedoxSettingSearch: (data: any) => RedoxSettingListType[] = (
  data
) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    return {
      id: CheckNumber(el.id),
      version: CheckNumber(el.version),
      praticeLocationId: CheckNumber(el.praticeLocationId),
      type: CheckStringEmpty(el.type),
      data_model: CheckStringEmpty(el.dataModel),
      sourceId: CheckStringEmpty(el.sourceId),
      sourceName: CheckStringEmpty(el.sourceName),
      destinationId: CheckStringEmpty(el.destinationId),
      destinationName: CheckStringEmpty(el.destinationName),
      webhookToken: CheckStringEmpty(el.webhookToken),
      webhookTokenExpireAt: CheckStringEmpty(el.webhookTokenExpireAt),
      apiKey: CheckStringEmpty(el.apiKey),
      apiSecret: CheckStringEmpty(el.apiSecret),
      apiKID: CheckStringEmpty(el.apikid),
      apiExpiry: CheckStringEmpty(el.apiExpiry),
      patientUniqueIdtype: CheckStringEmpty(el.patientUniqueIdtype),
      createdBy: CheckNumber(el.createdBy),
      updated_by: CheckNumber(el.updatedBy),
      createdAt: CheckStringEmpty(el.createdAt),
      updatedAt: CheckStringEmpty(el.updatedAt),
      isDeleted: CheckBoolean(el.isDeleted),
      status: CheckStringEmpty(el.status),
      deletedAt: CheckStringEmpty(el.deletedAt),
      deletedBy: CheckNumber(el.deletedBy),
      authType: CheckStringEmpty(el.authType),
      urlEndPoint: CheckStringEmpty(el.urlEndPoint),
      practiceId: CheckNumber(el?.practiceId),
    };
  });
};

const List: (data: any) => AppSettingListType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      groupLabel: CheckStringEmpty(el?.groupLabel),
      groupSlug: CheckStringEmpty(el?.groupSlug),
      keyLable: CheckStringEmpty(el?.keyLable),
      keySlug: CheckStringEmpty(el?.keySlug),
      value: CheckStringEmpty(el?.value),
      label: CheckStringEmpty(el?.label),
      typeId: CheckTypeId(el?.typeId),
    };
  });
};

const formateSmartmeterSettingSearch: (
  data: any
) => SmartmeterSettingListType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    return {
      id: CheckNumber(el.id),
      practiceId: CheckNumber(el.practiceId),
      practiceLocationId: CheckNumber(el.practiceLocationId),
      baseUrl: CheckStringEmpty(el.baseUrl),
      secretKey: CheckStringEmpty(el.secretKey),
      secretToken: CheckStringEmpty(el.secretToken),
      tokenExpiryInMinutes: CheckNumber(el.tokenExpiryInMinutes),
    };
  });
};

const RPMAlertList: (data: any) => RPMAlertType[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => RPMAlert(el));
};

const RPMAlert: (data: any) => RPMAlertType = (data) => {
  const addressedByUserName = CheckStringEmpty(data?.addressedByUserName);
  const addressedAt = DateTime.ValidDate(data?.addressedAt);
  const addressedBy =
    addressedByUserName + " " + DateTime.ToLocalDateTime(addressedAt);
  return {
    activeRecurringId: CheckNumber(data?.activeRecurringId),
    addressedByUserId: CheckNumber(data?.addressedByUserId),
    addressedAt: DateTime.ValidDate(data?.addressedAt),
    addressedBy: addressedBy,
    alertId: CheckNumber(data?.alertId),
    alertMessage: CheckStringEmpty(data?.message),
    alertRead: CheckBoolean(data?.alertRead),
    alertReceiverId: CheckNumber(data?.alertReceiverId),
    alertStatus: CheckAlertStatus(data?.alertStatus),
    alertTime: DateTime.ValidDate(data?.alertTime),
    alertType: CheckAlertType(data?.alertType),
    careplanId: CheckNumber(data?.careplanId),
    concent: CheckStringEmpty(data?.concent),
    detailedMessage: CheckStringEmpty(data?.detailedMessage),
    deviceId: CheckNumber(data?.deviceId),
    deviceName: CheckStringEmpty(data?.deviceName),
    deviceTypeId: CheckStringEmpty(data?.deviceTypeId),
    highlight: false,
    id: CheckNumber(data?.alertId),
    name: CheckStringEmpty(data?.patientFullName),
    patientId: CheckStringId(data?.patientId),
    practiceName: CheckStringEmpty(data?.practiceName),
  };
};

export default {
  formateRedoxSettingSearch,
  List,
  formateSmartmeterSettingSearch,
  RPMAlert,
  RPMAlertList,
};
