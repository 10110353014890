import { Box, Checkbox, Typography } from "@mui/material";
import { DropdownArrPropsType } from "DataTypes/Form";
import { FC, Fragment } from "react";

type BoxType = {
  handleSelectAll?: () => void;
  allSelectCheck: boolean;
  dropArr: DropdownArrPropsType[];
  selectedItems: DropdownArrPropsType[];
  handleSelect: (el: DropdownArrPropsType) => void;
  disableItems: (string | number)[];
};

const MultiSelectDrop: FC<BoxType> = (props: BoxType) => {
  const {
    handleSelectAll,
    allSelectCheck,
    dropArr,
    selectedItems,
    handleSelect,
    disableItems,
  } = props;
  return (
    <Box sx={{ ...sxDrop, ...(handleSelectAll && sxSelectAll) }}>
      {dropArr.length > 0 ? (
        <Fragment>
          {handleSelectAll && (
            <Box onClick={() => handleSelectAll()} sx={{ ...sxBox }}>
              <Checkbox checked={allSelectCheck} />
              <Typography
                variant="subtitle1"
                sx={{ ...(allSelectCheck && sxSelected) }}
              >
                {"Select All"}
              </Typography>
            </Box>
          )}
          {dropArr.map((el: DropdownArrPropsType) => {
            const found = selectedItems.find(
              (selectedEl) => el.id == selectedEl.id
            );
            const isDisable = disableItems.find((disEl) => disEl == el.value);
            return (
              <Box
                key={el.id}
                {...(!isDisable && { onClick: () => handleSelect(el) })}
                sx={{
                  ...(isDisable ? sxDisable : sxNonDisable),
                  ...sxBox,
                }}
              >
                <Checkbox checked={found ? true : false} />
                <Typography
                  variant="subtitle1"
                  sx={{ ...(found && !isDisable && sxSelected) }}
                >
                  {el.label}
                </Typography>
              </Box>
            );
          })}
        </Fragment>
      ) : (
        <Typography variant="body2" p={1}>
          No Data Found
        </Typography>
      )}
    </Box>
  );
};
export default MultiSelectDrop;

const sxBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  p: 0.5,
  "& >span": {
    p: 0,
    mr: 1,
    "&.Mui-checked": {
      color: "checkbox.main",
    },
  },
};

const sxDisable = {
  opacity: 0.5,
  cursor: "default",
};

const sxNonDisable = {
  cursor: "pointer",
  ": hover": {
    "& >span": {
      color: "active.main",
    },
    "& >h6": {
      color: "active.main",
    },
  },
};

const sxSelectAll = {
  "& >div": {
    ":first-of-type": {
      borderBottom: "1px solid",
      borderColor: "custom.main",
    },
  },
};

const sxDrop = {
  width: "100%",
  position: "absolute",
  zIndex: "10",
  maxHeight: "200px",
  overflow: "auto",
  backgroundColor: "common.white",
  boxShadow: "0px 3px 6px #00000029",
  borderBottomLeftRadius: "5px",
  borderBottomRightRadius: "5px",
  top: "110%",
};

const sxSelected = {
  fontWeight: "bold",
};
