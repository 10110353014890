import { FC, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, Loader } from "Components";
import { CPProblemBoxProps } from "DataTypes/PatientManagement.type";
import NoData from "Components/NoData";

const ProblemBox: FC<CPProblemBoxProps> = (props) => {
  const { problemArr, loadingProblem } = props;

  return (
    <Box height="47%" sx={{ ...CommonStyle.sxWhiteBox, mr: 2 }}>
      <Typography
        variant="subtitle1"
        sx={{ ...CommonStyle.sxRow, ...sxCategoryHead }}
      >
        {"Problem(s)"}
      </Typography>
      <Box height="80%" overflow="auto" sx={{ px: 2 }}>
        {loadingProblem ? (
          <Loader />
        ) : problemArr.length < 1 ? (
          <NoData />
        ) : (
          <Fragment>
            {problemArr.map((el) => {
              return (
                <Typography
                  sx={{ ...sxCategoryBox }}
                  key={el}
                  variant="subtitle1"
                >
                  {el}
                </Typography>
              );
            })}
          </Fragment>
        )}
      </Box>
    </Box>
  );
};
export default ProblemBox;

const sxCategoryHead = {
  height: "20%",
  fontWeight: "bold",
  px: 2,
};

const sxCategoryBox = {
  borderBottom: "0.5px solid",
  borderColor: "custom.main",
  py: 1,
};
