import { FC, Fragment, useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import CarePlanApi from "Service/CarePlan.api";
import { RPMNoteType } from "DataTypes/Services/Patient.type";
import { useDispatch } from "react-redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import Format from "./Format";
import { CMTabSelectedEnrollmentType } from "DataTypes/Modal";
import DateTime from "Utils/DateTime";
import Loader from "Components/Loader";
import NoData from "Components/NoData";
import CstmIconBtn from "Components/CstmIconBtn";
import ImgPath from "Constant/Imgs";
import PatientApi from "Service/Patient.api";

const RevisionNotes: FC<CMTabSelectedEnrollmentType> = ({
  prgmDetails,
  onClose,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { careplanId, patientId } = prgmDetails;
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [rows, setRows] = useState<RPMNoteType[]>([]);
  const [rowIndex, setRowIndex] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<RPMNoteType | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadPdf, setLoadPdf] = useState(false);

  useEffect(() => {
    setData();
  }, []);

  const setData: () => void = async () => {
    const res = await PatientApi.RevisionNotes({ id1: careplanId });
    if (res?.success) {
      const list = Format.NoteSearch(res?.data);
      setRows(Format.NoteSearch(res?.data));
      list.length > 0 && setSelectedRow(list[0]);
    } else {
      setRows([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    selectedRow && handleGetPdf(true);
    return () => {
      pdfUrl && URL.revokeObjectURL(pdfUrl);
    };
  }, [selectedRow]);

  const handleGetPdf: (isViewOnly: boolean) => void = async (isViewOnly) => {
    isViewOnly && setLoadPdf(true);
    const subUrl = `CareplanSummary?isViewRequest=${isViewOnly}`;
    const res = await CarePlanApi.CPPDFDownload({
      id1: selectedRow?.careplanId,
      subUrl,
    });
    const binaryResponse = res.data;
    const blob = new Blob([binaryResponse], {
      type: "application/pdf",
    });
    const newUrl = `${window.URL.createObjectURL(blob)}#toolbar=0&navpanes=0`;
    if (isViewOnly) {
      setPdfUrl(newUrl);
      isViewOnly && setLoadPdf(false);
    } else {
      const link = document.createElement("a");
      link.href = newUrl;
      link.download = `${patientInfo.name}_Revision_Note.pdf`;
      link.click();
      URL.revokeObjectURL(newUrl);
    }
  };

  const handleView: () => void = () => {
    onClose && onClose();
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...prgmDetails,
        careplanId: selectedRow && selectedRow?.careplanId,
        readOnlyArr: [
          "uptoDate",
          "problem",
          "addDevice",
          "editDevice",
          "question",
          "team",
          "addNotes",
          "frequency",
          "interactive",
          "medically",
        ],
      })
    );
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${patientId}/${RouteUrls.revisionPlan}/${selectedRow?.careplanId}/${RouteUrls.careSummary}`
    );
  };

  if (loading) return <Loader />;

  if (rows.length < 1) return <NoData />;

  return (
    <Box width="100%" height="100%" display="flex">
      <Box width="20%" height="100%">
        <Tabs
          value={rows.findIndex(
            (el) => el.careplanId == selectedRow?.careplanId
          )}
          onChange={(event, index) => setSelectedRow(rows[index])}
          aria-label="basic tabs example"
          variant="scrollable"
          indicatorColor="secondary"
          orientation="vertical"
          scrollButtons="auto"
          sx={{
            borderRight: "1px solid",
            borderColor: "custom.main",
            height: "100%",
          }}
        >
          {rows.map((el, index) => {
            const secIndex = rows.findIndex(
              (el) => el.careplanId == selectedRow?.careplanId
            );
            return (
              <Tab
                sx={{ ...sxFilterPrgmTab }}
                key={el.careplanId}
                label={
                  <Box
                    width="100%"
                    sx={{
                      ...(index == secIndex ? sxListBoxSelected : sxListBox),
                    }}
                  >
                    <Typography variant="subtitle1">
                      {DateTime.UTCToLocalDateTime(el.revisionInitDateTime)}
                    </Typography>
                  </Box>
                }
                id={`simple-tab-${el.careplanId}`}
                aria-controls={`simple-tabpanel-${el.careplanId}`}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box width="80%" height="100%" pl={2}>
        {selectedRow ? (
          <Fragment>
            <Box
              height="10%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>
                Treatment Plan Initiated By -{" "}
                {selectedRow?.revisionInitiatedByName}
              </Typography>
              <Box display="flex" sx={{ "&>button": { ml: 1 } }}>
                <CstmIconBtn
                  icon={<ImgPath.ViewCarePlan />}
                  onClick={handleView}
                  title="View Previous Treatment Plans"
                />
                <CstmIconBtn
                  icon={<ImgPath.DownloadIcon />}
                  onClick={() => handleGetPdf(false)}
                  title="Download"
                />
              </Box>
            </Box>
            <Box height="90%">
              {loadPdf ? (
                <Loader />
              ) : (
                <object width={"100%"} height={"100%"} data={pdfUrl || ""} />
              )}
            </Box>
          </Fragment>
        ) : (
          <NoData />
        )}
      </Box>
    </Box>
  );
};
export default RevisionNotes;

const sxFilterPrgmTab = {
  minHeight: 0,
  minWidth: 0,
  py: 1,
  px: 0,
};

const sxListBox = {
  "& >h6": {
    color: "custom.dark",
  },
};

const sxListBoxSelected = {
  "& >h6": {
    color: "primary.main",
  },
};
