import { FormDataType } from "DataTypes/Form";

export const FormArr1: FormDataType[] = [
  {
    id: "care-manager",
    label: "Care Manager",
    placeholder: "Select Care Manager",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
    required: true,
    dropArr: [],
    enableFormLoad: true,
  },
  {
    id: "billable-provider",
    label: "Care Plan Billable Provider",
    placeholder: "Select Care Plan Billable Provider",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
    required: true,
    dropArr: [],
    enableFormLoad: true,
  },
  {
    id: "provider",
    label: "Billable Provider",
    placeholder: "Select Billable Provider",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
    required: true,
    dropArr: [],
    enableFormLoad: true,
  },
  {
    id: "speciality",
    label: "Billable Provider's Speciality",
    placeholder: "Enter Billable Provider's Speciality",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericName",
    required: false,
    readOnly: true,
    enableFormLoad: true,
  },
  {
    id: "pcc",
    label: "Primary Care Coordinator",
    placeholder: "Select Primary Care Coordinator",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
    required: true,
    dropArr: [],
    enableFormLoad: true,
  },
  {
    id: "scc",
    label: "Secondary Care Coordinator",
    placeholder: "Select Secondary Care Coordinator",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
    required: false,
    dropArr: [],
    enableFormLoad: true,
  },
  {
    id: "authorized-representative1",
    label: "Authorized Representative 1",
    placeholder: "Enter Authorized Representative",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "authRepresent",
    required: false,
    enableFormLoad: true,
  },
  {
    id: "authorized-representative2",
    label: "Authorized Representative 2",
    placeholder: "Enter Authorized Representative",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "authRepresent",
    required: false,
    enableFormLoad: true,
  },
  {
    id: "authorized-representative3",
    label: "Authorized Representative 3",
    placeholder: "Enter Authorized Representative",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "authRepresent",
    required: false,
    enableFormLoad: true,
  },
];

export const FormArr2: FormDataType[] = [
  {
    id: "additional-provider",
    label: "Additional Provider",
    placeholder: "Enter Provider Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericPeriod",
    required: true,
    enableFormLoad: true,
  },
  {
    id: "add-speciality",
    label: "Additional Provider's Speciality",
    placeholder: "Enter Provider Speciality",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "alphanumericName",
    enableFormLoad: true,
  },
  {
    id: "organization-name",
    label: "Organization Name",
    placeholder: "Enter Organization Name",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "org-name",
    enableFormLoad: true,
  },
  {
    id: "phone-number",
    label: "Phone Number",
    placeholder: "Enter Phone Number",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "mobile no.",
    enableFormLoad: true,
  },
  {
    id: "fax-number",
    label: "Fax Number",
    placeholder: "Enter Fax Number",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "fax-number",
    enableFormLoad: true,
  },
];
