import { RPMNoteType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import { CheckArr, CheckNumber, CheckStringEmpty } from "Utils/common";

const NoteSearch: (data: any) => RPMNoteType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    return {
      careplanId: CheckNumber(el?.careplanId),
      revisionInitiatedBy: CheckNumber(el?.revisionInitiatedBy),
      revisionInitiatedByName: CheckStringEmpty(el?.revisionInitiatedByName),
      revisionStatus: CheckStringEmpty(el?.revisionStatus),
      revisionInitDateTime: DateTime.ValidDate(el?.revisionInitDateTime),
      revisionCompletionDateTime: DateTime.ValidDate(
        el?.revisionCompletionDateTime
      ),
      careplanDocumentId: CheckStringEmpty(el?.careplanDocumentId),
    };
  });
};

export default { NoteSearch };
