import { FC, useEffect } from "react";
import { Box } from "@mui/material";
import InfoCard from "Components/InfoCard";
import ProgramCard from "Components/ProgramCard";
import PatientViewCard from "Components/PatientViewCard";
import { useDispatch } from "react-redux";
import { setLeftHead, setRightHead } from "Redux/Screen/Action";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const PatientDetails: FC = () => {
  const dispatch = useDispatch();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );

  useEffect(() => {
    dispatch(setLeftHead("patient-details"));
    dispatch(setRightHead("patient-details"));
    CommonFormatter.HandleNavArr({
      id: "patient-details",
      label: patientInfo.name,
      icon: "patient-notes",
      path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.details}`,
      level: 2,
      link: RouteUrls.details,
      navType: "patient",
    });
    return () => {
      dispatch(setLeftHead(""));
      dispatch(setRightHead(""));
    };
  }, []);

  return (
    <Box height="100%" width="100%">
      <Box display="flex" height={"45%"} flexDirection="row">
        <Box width={"50%"}>
          <InfoCard />
        </Box>
        <Box width={"50%"}>
          <ProgramCard />
        </Box>
      </Box>
      <Box height={"3%"}></Box>
      <Box height="52%" width="100%">
        <PatientViewCard />
      </Box>
    </Box>
  );
};
export default PatientDetails;
