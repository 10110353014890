import { FC, Fragment } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import ImgPath from "Constant/Imgs";
import { CommonStyle } from "Components";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import PatientFormatter from "Utils/Patient.Formatter";
import DateTime from "Utils/DateTime";

const InfoCard: FC = () => {
  const { patientInfo, patientOrg }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  return (
    <Box
      height="100%"
      sx={{ ...CommonStyle.sxWhiteBox, mr: 2, overflow: "auto" }}
    >
      <Fragment>
        <Box display="flex" flexDirection="row" sx={{ p: 2 }}>
          <Avatar src={""} alt="" sx={{ height: 100, width: 100, mr: 1 }} />
          <Box
            width="85%"
            sx={{
              "& >div:not(:last-of-type)": { ...CommonStyle.sxRow, mb: 1 },
            }}
          >
            <Box>
              <ImgPath.PersonIcon color="secondary" fontSize="small" />
              <Typography variant="body2" fontWeight="bold" sx={{ ml: 1 }}>
                {patientInfo.name} ({patientInfo.age}, {patientInfo.gender})
              </Typography>
            </Box>
            <Box>
              <ImgPath.LocationIcon color="secondary" fontSize="small" />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {patientInfo.address}
              </Typography>
            </Box>
            <Box>
              <ImgPath.CallIcon color="secondary" sx={{ fontSize: "medium" }} />
              <Typography variant="subtitle1" sx={{ ml: 1, mr: 2 }}>
                {PatientFormatter.MobileNumber(patientInfo.home)}
              </Typography>
              <Typography variant="h5" sx={{ color: "custom.main" }}>
                |
              </Typography>
              <ImgPath.SmartphoneIcon
                color="secondary"
                sx={{ fontSize: "medium", ml: 2, mr: 1 }}
              />
              <Typography variant="subtitle1">
                {PatientFormatter.MobileNumber(patientInfo.mobile)}
              </Typography>
            </Box>
            <Box>
              <ImgPath.EmailIcon
                color="secondary"
                sx={{ fontSize: "medium" }}
              />
              <Typography variant="subtitle1" sx={{ ml: 1, mr: 2 }}>
                {patientInfo.email}
              </Typography>
              <Typography variant="h5" sx={{ color: "custom.main" }}>
                |
              </Typography>
              <ImgPath.CalendarIcon
                color="secondary"
                sx={{ fontSize: "medium", ml: 2, mr: 1 }}
              />
              <Typography variant="subtitle1">
                {DateTime.ToLocalDate(patientInfo.dob)}
              </Typography>
            </Box>
            <Box
              sx={{
                borderTop: "1px solid",
                borderColor: "custom.main",
                pt: 1,
              }}
            >
              <Typography variant="body2" fontWeight="bold" sx={{ ml: 3.5 }}>
                Organization Details
              </Typography>
              <Box sx={{ ...CommonStyle.sxRow }}>
                <ImgPath.OrganisationIcon color="secondary" fontSize="small" />
                <Typography variant="subtitle1" sx={{ mr: 2, ml: 1 }}>
                  {patientOrg.practiceName}
                </Typography>
                <Typography variant="h5" sx={{ color: "custom.main", mr: 2 }}>
                  |
                </Typography>
                <ImgPath.LocationIcon color="secondary" fontSize="small" />
                <Typography variant="subtitle1" sx={{ mr: 2, ml: 1 }}>
                  {patientOrg.practiceLocationName}
                </Typography>
                <Typography variant="h5" sx={{ color: "custom.main", mr: 2 }}>
                  |
                </Typography>
                <ImgPath.AddTimeLogIcon color="secondary" fontSize="small" />
                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                  {patientOrg.practiceLocationTimezone}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fragment>
    </Box>
  );
};
export default InfoCard;
