import { ChangeEvent, Fragment, FC } from "react";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { InputType } from "DataTypes/Form";
import { InputImgSwitch } from "Utils/SwitchCases";
import { CommonStyle } from "Components";
import InputLabel from "./Label";
import { CheckStringEmpty } from "Utils/common";
import { ScreenInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const InputApi: FC<InputType> = (props) => {
  const {
    id,
    label,
    placeholder,
    error,
    errorText,
    required,
    readOnly,
    loading,
    type,
    defaultVal,
    inputVal,
    iconId,
    multiline,
    startAdornmentText,
    multilineRows,
    minVal,
    maxVal,
    maxLength,
    infoText,
    textAlignCenter,
    inputChange,
    onBlurChange,
    onFocusChange,
    iconClick,
    enableFormLoad,
  } = props;

  const { formLoad }: ScreenInitialStateType = useSelector(
    (state: any) => state?.screen
  );
  const viewOnly = readOnly || (enableFormLoad ? formLoad : false);

  return (
    <Fragment>
      {label && (
        <InputLabel label={label} required={required ? required : false} />
      )}
      <OutlinedInput
        name={id}
        {...(placeholder && { placeholder: placeholder })}
        {...(defaultVal && { defaultValue: defaultVal })}
        {...(maxLength && {
          onInput: (e: any) => {
            if (e.target.value) {
              e.target.value = e.target.value.slice(0, maxLength);
            }
          },
        })}
        {...(inputChange && {
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            inputChange(
              type == "file"
                ? e.target.files
                  ? e.target.files[0]
                  : ""
                : e.target.value
            );
          },
        })}
        {...(onBlurChange && { onBlur: (e) => onBlurChange(e.target.value) })}
        {...(onFocusChange && {
          onFocus: (e) => onFocusChange(e.target.value),
        })}
        readOnly={viewOnly}
        value={inputVal}
        {...(type && { type: type })}
        inputProps={{
          ...(type == "file" && { accept: "application/pdf" }),
          ...(minVal && { min: minVal }),
          ...(maxVal && { max: maxVal }),
          ...(textAlignCenter && { style: { textAlign: "center" } }),
        }}
        fullWidth
        sx={{
          ...(readOnly ? { ...sxReadOnly } : { ...CommonStyle.sxWhiteBox }),
          ...sxInput,
        }}
        multiline={multiline ? multiline : false}
        {...(multilineRows && { rows: multilineRows })}
        {...(startAdornmentText && {
          startAdornment: (
            <InputAdornment position="start">
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{ pl: 0.5 }}
              >
                {startAdornmentText}
              </Typography>
            </InputAdornment>
          ),
        })}
        {...(!readOnly && {
          endAdornment: (
            <Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {iconId &&
                (iconClick ? (
                  <IconButton onClick={() => iconClick(id)} sx={{ p: 0 }}>
                    <InputAdornment position="end">
                      {InputImgSwitch(iconId)}
                    </InputAdornment>
                  </IconButton>
                ) : CheckStringEmpty(infoText) ? (
                  <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                    <Tooltip title={infoText} arrow>
                      {InputImgSwitch(iconId)}
                    </Tooltip>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    {InputImgSwitch(iconId)}
                  </InputAdornment>
                ))}
            </Fragment>
          ),
        })}
        error={error ? error : false}
      />
      {error && (
        <Typography variant="subtitle2" color="red">
          {errorText}
        </Typography>
      )}
    </Fragment>
  );
};
export default InputApi;

const sxInput = {
  fontSize: "0.85rem",
  px: 0.5,
  py: 0,
  "& >input": {
    px: 0.5,
    py: 1,
  },
  "& >textarea": {
    px: 0.5,
    py: 1,
  },
  "& >fieldset": {
    borderWidth: 0,
  },
};

const sxReadOnly = {
  backgroundColor: "custom.grey",
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: 1.25,
  " >fieldset": {
    display: "none",
  },
};
