import { FormDataType } from "DataTypes/Form";
import { ControlledTable } from "Components";
import { actionArrType, columnType } from "DataTypes/Table";

export const actionArr: (handleEdit: () => void) => actionArrType[] = (
  handleEdit
) => {
  return [ControlledTable.ActionData.editAction({ handleClick: handleEdit })];
};

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.keyLabelCol("40%"),
  ControlledTable.ColumnData.valueCol("40%"),
  ControlledTable.ColumnData.ActionCol("20%"),
];

export const FormDataArr: FormDataType[] = [
  {
    id: "keylabel",
    label: "Key Label",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "description",
    readOnly: true,
    required: true,
  },
  {
    id: "value",
    label: "Value",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "description",
    required: true,
  },
];
