import { FC, useState, useEffect, Fragment } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ControlledTable } from "Components";
import { TableLayout } from "Layouts";
import CstmBtn from "Components/CstmBtn";
import { useNavigate, useParams } from "react-router-dom";
import { FormDataType } from "DataTypes/Form";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { useSelector } from "react-redux";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { FormDataArr, TableCol } from "./Data";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch } from "react-redux";
import {
  setAlertData,
  setLeftHead,
  startLoading,
  stopLoading,
} from "Redux/Screen/Action";
import {
  ListInitialStateType,
  PatientInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import DateTime from "Utils/DateTime";
import TrainingApi from "Service/Training.api";
import TrainingFormatter from "Utils/Training.Formatter";
import { TrainingCallLogsListType } from "DataTypes/Services/Training.type";
import { HandleChangeProps } from "DataTypes/Common";
import CommonFormatter from "Utils/Common.Formatter";

const DeviceCallLogs: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { patientDeviceId } = useParams();
  const {
    patientInfo,
    selectedPrgm,
    selectedTraining,
    selectedDevice,
  }: PatientInitialStateType = useSelector((state: any) => state?.patient);
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { callResultList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const [formArr, setFormArr] = useState<FormDataType[]>(FormDataArr());
  const [rowData, setRowData] = useState<TrainingCallLogsListType[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const [headHeight, setHeadHeight] = useState(60);
  const offsetform = document?.getElementById("form")?.offsetHeight;
  const tableHeight = `calc(95% - ${headHeight}px)`;
  const isTrainingCompleted =
    selectedTraining.trainingStatusId === "TRANING_COMPLETED";

  useEffect(() => {
    setHeadHeight(offsetform || 0);
  }, [offsetform]);

  useEffect(() => {
    dispatch(setLeftHead("patient-details"));
    CommonFormatter.HandleNavArr({
      id: "device-calllog",
      label: "Call Log",
      path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.device}/${patientDeviceId}/${RouteUrls.callLog}`,
      level: 4,
      link: RouteUrls.callLog,
      navType: "patient",
    });
    return () => {
      dispatch(setLeftHead(""));
    };
  }, []);

  useEffect(() => {
    handleSetFormData();
  }, []);

  const handleSetFormData: () => void = () => {
    const UserName = `${myInfo?.firstname} ${myInfo?.lastname}`;
    const newFormData: FormDataType[] = JSON.parse(
      JSON.stringify(FormDataArr())
    );
    const newArr: FormDataType[] = newFormData?.map((el) => {
      if (el.id == "caller_name") {
        el.value = UserName;
        el.readOnly = true;
      }
      if (el.id == "call_result") el.dropArr = callResultList;
      if (el.id == "next_call") el.minVal = DateTime.CurrentDateTime();
      if (el.id == "activity") el.value = selectedTraining.trainingActivity;
      if (el.id == "supplyName") el.value = selectedDevice.deviceName;
      return el;
    });
    setFormArr(newArr);
  };

  useEffect(() => {
    handleSetData(page, limit);
  }, [patientDeviceId]);

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const res: any = await TrainingApi.deviceCallLogList({
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
      id1: patientDeviceId,
    });
    if (res?.success) {
      setRowData(
        TrainingFormatter.TrainingCallLogList(res?.data, callResultList)
      );
      setTotalRecord(res?.criterion?.total);
      setPage(res?.criterion?.page);
      setLimit(res?.criterion?.limit);
    }
    setLoading(false);
  };

  const handleChange: (data: HandleChangeProps) => void = (data) => {
    const { id, value } = data;
    const newArr: FormDataType[] = JSON.parse(JSON.stringify(formArr));
    if (id == "next_call") {
      newArr[5].value = value && isTrainingCompleted ? "Follow up" : "";
    }
    setFormArr(HandleFormChange(data, newArr));
  };

  const handleSubmit: (btnText: string) => void = async (btnText) => {
    const newArr = ValidateFormData(formArr);
    setFormArr(newArr.formData);
    if (!newArr.errorCheck) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const payload = {
        notes: newArr.formData[5].value || null,
        callResult: newArr.formData[1].dropValue,
        nextCallDateTime:
          DateTime.ToUTCDateTime(newArr.formData[3].value) || null,
        activity: newArr.formData[4].value ? newArr.formData[4].value : null,
        programId: selectedPrgm.programId,
        careplanId: selectedPrgm.careplanId,
        recurringId: selectedPrgm.careplanRecurringId || null,
      };
      const res = await TrainingApi.deviceCallLogCreate({
        id1: patientDeviceId,
        data: payload,
      });
      if (res?.success) {
        handleSetData(page, limit);
        handleSetFormData();
        dispatch(setAlertData(res?.alert));
        if (btnText == "save-next") {
          navigate(
            `../${RouteUrls.training}/${selectedTraining.trainingId}/${RouteUrls.trainingNotes}`
          );
        }
        dispatch(stopLoading());
      }
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol()}
      tableRow={rowData}
    />
  );

  return (
    <Fragment>
      <Box id="form">
        <Box display="flex" pt={1}>
          <Box mr={4}>
            <Typography variant="subtitle1" color="custom.dark">
              Patient Name
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {patientInfo.name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" color="custom.dark">
              Provider Name
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {selectedPrgm.providerName}
            </Typography>
          </Box>
        </Box>
        <Grid container rowSpacing={0} columnSpacing={1}>
          {formArr?.map((el: FormDataType) => {
            return (
              <Grid key={el.id} item {...ControlledBoxSizeSwitch(3)}>
                {commonFormSwitch({
                  formObj: el,
                  onChange: handleChange,
                })}
              </Grid>
            );
          })}
        </Grid>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"flex-end"}
          pb={1}
          mt={2}
          sx={{ "& >button": { ml: 1 } }}
        >
          <CstmBtn label={"Save"} onClick={() => handleSubmit("save")} />
          {(formArr[1]?.dropValue == "answered" ||
            formArr[1]?.dropValue == "in_person") &&
            selectedTraining.trainingStatusId != "TRANING_COMPLETED" && (
            <CstmBtn
              label={"Save & Next"}
              onClick={() => handleSubmit("save-next")}
            />
          )}
          <CstmBtn
            label="Cancel"
            onClick={() => navigate(-1)}
            btnType="border"
            title="Cancel"
          />
        </Box>
      </Box>
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol())}
        loading={loading}
        height={tableHeight}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={handleSetData}
            tableStyle={true}
          />
        }
      />
    </Fragment>
  );
};
export default DeviceCallLogs;
