///bug resolve azure-384

import { Box, Chip, Divider, IconButton, Typography } from "@mui/material";
import { RouteUrls } from "Constant/RouteUrls";
import {
  ListInitialStateType,
  PatientInitialStateType,
  ScreenInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setAlertData,
  setTimerStatus,
  startLoading,
  stopLoading,
} from "Redux/Screen/Action";
import CarePlanApi from "Service/CarePlan.api";
import CPFormatter from "Utils/CP.Formatter";
import {
  CPDeviceListType,
  CPRecNoteType,
  CPSummaryV3Type,
} from "DataTypes/Services/Careplan.type";
import {
  CommonStyle,
  ControlledTable,
  ControlledAccord,
  ControlledForm,
} from "Components";
import { commonFormSwitch } from "Utils/SwitchCases";
import {
  AscendSort,
  CheckBoolean,
  CheckNumber,
  CheckStringEmpty,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import { FormDataType } from "DataTypes/Form";
import CstmBtn from "Components/CstmBtn";
import { ModalLayout, TableLayout } from "Layouts";
import { DateArr, FormDataArr, TableCol1 } from "./Data";
import { useNavigate, useSearchParams } from "react-router-dom";
import PatientThreshold from "Components/PatientThreshold";
import LineChart from "Components/Charts/LineChart";
import {
  formatDateUnit,
  generateRows,
  TableCol,
  transformData,
} from "../../../Devices/Details/Data";
import SummaryView from "Components/Summary";
import ImgPath from "Constant/Imgs";
import {
  DeviceDetailInfo,
  DeviceReadingTableType,
  GraphDataset,
} from "DataTypes/Services/Problem.type";
import DateTime from "Utils/DateTime";
import DeviceApi from "Service/Device.api";
import DeviceTypeFormatter from "Utils/DeviceType.Formatter";
import NoData from "Components/NoData";
import { CommonButtonArray } from "Components/Modal/Data";
import ProgramApi from "Service/Program.api";
import BreadUrls from "Constant/BreadUrls";
import { HandleChangeProps } from "DataTypes/Common";
import SettingsApi from "Service/Settings.api";
import Format from "./Format";

const RPMDeviceCharts: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedPrgm, patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { deviceUOMList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const {
    rightHead: { timerStatus },
  }: ScreenInitialStateType = useSelector((state: any) => state?.screen);
  const {
    careplanId: careId,
    careplanRecurringId: recId,
    teamMember,
    readOnlyArr,
  } = selectedPrgm;
  const currentUrl = window.location.href;
  const isRecurring = currentUrl.includes(RouteUrls.recurring);
  const viewOnly = readOnlyArr.includes("rpm-service");
  const viewOnlyMode = !(isRecurring && teamMember && !viewOnly);
  const viewNotesList = selectedPrgm.careplanRecurringId ? true : false;
  const viewNoteSection = viewNotesList || !viewOnlyMode;
  const viewGoalSection = isRecurring;
  const [searchParams, setSearchParams] = useSearchParams();
  const paramDeviceTypeId = searchParams.get("deviceTypeId");
  const paramFromDate = searchParams.get("fromDate");
  const paramToDate = searchParams.get("toDate");
  const [notes, setNotes] = useState<CPRecNoteType[]>([]);
  const [problemArr, setProblemArr] = useState<string[]>([]);
  const [deviceList, setDeviceList] = useState<CPDeviceListType[]>([]);
  const [viewDeviceId, setViewDeviceId] = useState<null | string>(
    paramDeviceTypeId || null
  );
  const [summaryV3, setSummaryV3] = useState<CPSummaryV3Type[]>([]);
  const [dateForm, setDateForm] = useState<FormDataType[]>(
    JSON.parse(JSON.stringify(DateArr))
  );
  const [graphData, setGraphData] = useState<{ datasets: GraphDataset[] }>({
    datasets: [],
  });
  const [deviceReading, setDeviceReading] = useState<DeviceDetailInfo[] | null>(
    null
  );
  const [formArr, setFormArr] = useState<FormDataType[]>([]);
  const [viewGoal, setViewGoal] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [limit, setLimit] = useState<number>(10);
  const [tableLoad, setTableLoad] = useState(true);
  const [readmoreIds, setReadmoreIds] = useState<string[]>([]);
  const [thresCount, setThresCount] = useState(0);
  const [medModal, setMedModal] = useState(false);
  const [tblRows, setTblRows] = useState<DeviceReadingTableType[]>([]);
  const [showCompletionBtn, setShowCompletionBtn] = useState(false);
  const probSectionHeight = viewGoalSection ? 40 : 50;
  const goalSectionHeight = 80;
  const spaceHeight = 15;
  const deviceSectionHeight = `calc(${100 - probSectionHeight}% - ${
    viewGoalSection ? spaceHeight * 2 + goalSectionHeight : spaceHeight
  }px)`;
  const firstNoteRef = useRef(false);
  const dateunit = useRef("day");

  useEffect(() => {
    handleInitialLoad();
    return () => {
      if (!viewOnlyMode && !firstNoteRef.current) handleUnMount();
    };
  }, [careId, recId]);

  const handleInitialLoad: () => void = async () => {
    if (isRecurring && timerStatus == 1 && teamMember) {
      dispatch(setTimerStatus({ status: 2, parentApiCall: true }));
    }
    dispatch(startLoading({ loading: true, loadtext: "Loading" }));
    !viewOnlyMode && (await handleMedSearch());
    viewNotesList && (await handleNoteSearch(1, limit));
    await handleProblemsLoad();
    handleDateSet();
    await handleDevicesLoad();
    viewGoalSection && (await handleSummaryLoad());
    await handleSystemSettings();
    dispatch(stopLoading());
  };

  const handleUnMount: () => Promise<void> = async () => {
    const payload = {
      careplanRecurringNotesId: null,
      isInteractiveCommunicationDone: false,
      notes: "RPM Data Review",
      sendNotificationToProvider: false,
    };
    const res = await CarePlanApi.RecNotesUpdate({
      data: payload,
      id1: careId,
      id2: recId,
    });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      await handleNoteSearch(1, limit);
    }
  };

  const handleMedSearch: () => Promise<void> = async () => {
    const newForArr: FormDataType[] = JSON.parse(JSON.stringify(FormDataArr()));
    const res = await CarePlanApi.RecMedSearch({ id1: careId, id2: recId });
    if (res?.success) {
      const updatedBy = CheckNumber(res?.data?.isMedicallyNecessaryUpdatedBy);
      // const value = res?.data?.isMedicallyNecessary;
      const newValue = CheckBoolean(res?.data?.isMedicallyNecessary);
      if (updatedBy) {
        newForArr[2].boxType = "empty-block";
        newForArr[2].required = false;
      }
      // newForArr[1].dropValue = newForArr[1].value = "Yes";
      newForArr[2].dropValue = newForArr[2].value = newValue ? "Yes" : "";
      // value == null || newValue ? "Yes" : "No";
    }
    setFormArr(newForArr);
  };

  const handleNoteSearch: (
    currentPage: number,
    currentLimit: number
  ) => Promise<void> = async (currentPage, currentLimit) => {
    setTableLoad(true);
    const payload = {
      fromDate: "",
      toDate: "",
    };
    const res = await CarePlanApi.RecNotesSearch({
      id1: careId,
      id2: recId,
      data: payload,
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
    });
    if (res?.success) {
      setNotes(CPFormatter.RecNoteArr(res?.data));
      setPage(res?.criterion?.page);
      setTotalRecord(res?.criterion?.total);
      setLimit(res?.criterion?.limit);
    }
    setTableLoad(false);
  };

  const handleNoteFormChange: (props: HandleChangeProps) => void = (props) => {
    const newDataArr: FormDataType[] = JSON.parse(JSON.stringify(formArr));
    const { id, value } = props;
    if (id == "interactive") {
      if (value && value == "Yes") {
        newDataArr[1].boxType = "input-box";
        newDataArr[1].required = true;
      } else {
        newDataArr[1].boxType = "empty-block";
        newDataArr[1].required = false;
        newDataArr[1].value = "";
      }
    }
    setFormArr(HandleFormChange(props, newDataArr));
  };

  const handleProblemsLoad: () => Promise<void> = async () => {
    const probRes = await CarePlanApi.careplanProblemsRead({
      id1: careId,
    });
    if (probRes?.success) {
      const newArr = CPFormatter.CheckCPProblem(probRes?.data?.careplanProblem);
      setProblemArr(
        newArr.map((el) => `${el.icdCodeDescription} (${el.icdCode})`)
      );
    }
  };

  const handleDateSet: () => void = () => {
    const today = DateTime.CurrentDate();
    const minVal = DateTime.CurrentDateSubtraction(today, 6, "month");
    const fromDate = paramFromDate || DateTime.CurrentMonthStartDate();
    const toDate = paramToDate || today;
    setDateForm(
      JSON.parse(
        JSON.stringify([
          {
            ...dateForm[0],
            dropValue: fromDate,
            value: fromDate,
            minVal: minVal,
            maxVal: today,
          },
          {
            ...dateForm[1],
            dropValue: toDate,
            value: toDate,
            minVal: fromDate,
            maxVal: today,
          },
        ])
      )
    );
  };

  const handleDevicesLoad: () => Promise<void> = async () => {
    const res = await CarePlanApi.PatientDeviceSearch({
      id1: careId,
      data: { isDeleted: false, isDeviceSuspended: false },
    });
    if (res?.success) {
      const newArr = CPFormatter.CheckCPPatientDevice(res?.data);
      if (newArr) {
        AscendSort(newArr, "deviceTypeDescription");
        setDeviceList(newArr);
        newArr.length &&
          setViewDeviceId(
            paramDeviceTypeId ? paramDeviceTypeId : newArr[0].deviceTypeId
          );
      }
    }
  };

  const handleSummaryLoad: () => Promise<void> = async () => {
    const sumRes = await CarePlanApi.CPSummaryV3({ id1: careId });
    if (sumRes?.success) setSummaryV3(CPFormatter.CPV3Summary(sumRes?.data));
  };

  const handleSystemSettings: () => Promise<void> = async () => {
    const payload = {
      id1: "recurring",
      data: "ui.complete.rpm.recurring.show.button",
    };
    const res = await SettingsApi.SystemSettings(payload);
    if (res?.success) {
      if (res?.data) {
        const systemProperty = CheckStringEmpty(res?.data?.systemPropertyValue);
        setShowCompletionBtn(systemProperty == "N" ? false : true);
      }
    }
  };

  useEffect(() => {
    handleDeviceReading();
  }, [viewDeviceId]);

  const handleDeviceReading: (
    propDateArr?: FormDataType[]
  ) => Promise<void> = async (propDateArr) => {
    const newArr = ValidateFormData(propDateArr ? propDateArr : dateForm);
    setDateForm(JSON.parse(JSON.stringify(newArr.formData)));
    if (viewDeviceId && !newArr.errorCheck) {
      dispatch(startLoading({ loading: true, loadtext: "Loading" }));
      const fromDate = DateTime.ToUTCDate(newArr.formData[0]?.dropValue || "");
      const toDate = DateTime.ToUTCDate(newArr.formData[1]?.dropValue || "");
      const payload = {
        patientId: patientInfo.id,
        deviceTypeId: viewDeviceId,
        dateFrom_param: fromDate ? fromDate + "T00:00:00.000Z" : "",
        dateTo_param: toDate ? toDate + "T23:59:59.000Z" : "",
      };
      const res = await DeviceApi.deviceDetailsReading({ data: payload });
      if (res?.success) {
        const formattedData = DeviceTypeFormatter.DeviceDetailsReadingFormate(
          res.data
        );
        setTblRows(Format.TabularReadings(formattedData));
        dateunit.current = formatDateUnit(
          newArr.formData[0]?.dropValue,
          newArr.formData[1]?.dropValue
        );
        setDeviceReading(formattedData);
        const updatedDatasets = transformData(formattedData, viewDeviceId);
        setGraphData({
          datasets: updatedDatasets,
        });
      } else {
        setGraphData({
          datasets: [],
        });
      }
      dispatch(stopLoading());
    }
  };

  const handleRefreshDate: () => void = () => {
    const today = DateTime.CurrentDate();
    const minVal = DateTime.CurrentDateSubtraction(today, 6, "month");
    const fromDate = paramFromDate || DateTime.CurrentMonthStartDate();
    const toDate = paramToDate || today;
    const newArr = [
      {
        ...dateForm[0],
        dropValue: fromDate,
        value: fromDate,
        minVal: minVal,
        maxVal: today,
      },
      {
        ...dateForm[1],
        dropValue: toDate,
        value: toDate,
        minVal: fromDate,
        maxVal: today,
      },
    ];
    handleDeviceReading(newArr);
  };

  const handleDateChange: (props: HandleChangeProps) => void = (props) => {
    const newDataArr: FormDataType[] = JSON.parse(JSON.stringify(dateForm));
    const { id, value } = props;
    if (id == "start-date-time") {
      newDataArr[1].minVal = DateTime.ValidDate(value);
    }
    if (id == "end-date-time") {
      newDataArr[0].maxVal = DateTime.ValidDate(value);
    }
    setDateForm(HandleFormChange(props, newDataArr));
  };

  const handleReadMore = (id: number | null): void => {
    setReadmoreIds((prevIds) => {
      if (prevIds.includes(id?.toString() ?? "")) {
        return prevIds.filter((prevId) => prevId !== (id?.toString() ?? ""));
      } else {
        return [...prevIds, id?.toString() ?? ""];
      }
    });
  };

  const checkReadMore = (id: number | null): boolean => {
    return !readmoreIds.includes(id?.toString() ?? "");
  };

  const handleSave: (
    btnType: "save" | "send-now" | "complete"
  ) => void = async (btnType) => {
    const newArr = ValidateFormData(formArr);
    setFormArr(newArr.formData);
    if (!newArr.errorCheck) {
      if (newArr.formData[2].dropValue == "No") setMedModal(true);
      else {
        dispatch(startLoading({ loading: true, loadtext: "Loading" }));
        if (newArr.formData[2].boxType != "empty-block")
          await handleUpdateMedNes();
        const payload = {
          careplanRecurringNotesId: null,
          isInteractiveCommunicationDone:
            newArr.formData[0].dropValue == "Yes" ? true : false,
          isInteractiveCommunicationDoneWithPartyName:
            newArr.formData[1].value || null,
          notes: newArr.formData[3].value,
          sendNotificationToProvider: btnType == "send-now",
        };
        const res = await CarePlanApi.RecNotesUpdate({
          data: payload,
          id1: careId,
          id2: recId,
        });
        if (res?.success) {
          await handleMedSearch();
          if (btnType == "complete")
            await dispatch(setTimerStatus({ status: 1, parentApiCall: true }));
          dispatch(setAlertData(res?.alert));
          await handleNoteSearch(1, limit);
          if (!firstNoteRef.current) {
            firstNoteRef.current = true;
          }
          if (btnType == "send-now" || btnType == "complete") {
            await handleUpdatePdf();
          }
          if (btnType == "complete") await handleCompleteRecurring();
        }
        dispatch(stopLoading());
      }
    }
  };

  const handleUnrollCall: () => void = async () => {
    setMedModal(false);
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const medPayload = {
      isMedicallyNecessary: false,
    };
    const medRes = await CarePlanApi.RecMedUpdate({
      data: medPayload,
      id1: careId,
      id2: recId,
    });
    if (medRes?.success) {
      dispatch(setAlertData(medRes?.alert));
    }
    const payload = {
      concent: "Unenrolled",
      unenrollmentReason:
        "RPM Services are not medically necessary & reasonable for this patient",
    };
    const res = await ProgramApi.UnenrollProgram({
      id1: patientInfo.id,
      id2: selectedPrgm.eligibleProgramId,
      data: payload,
    });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      navigate(BreadUrls.ClinicalSummary(patientInfo.id));
    }
    dispatch(stopLoading());
  };

  const handleUpdateMedNes: () => Promise<void> = async () => {
    const payload = { isMedicallyNecessary: true };
    await CarePlanApi.RecMedUpdate({ data: payload, id1: careId, id2: recId });
    // if (res?.success) dispatch(setAlertData(res?.alert));
  };

  const handleUpdatePdf: () => Promise<void> = async () => {
    const res = await CarePlanApi.RecpdfUpdate({
      id1: recId,
    });
    if (res.success) dispatch(setAlertData(res?.msg));
  };

  const handleCompleteRecurring: () => Promise<void> = async () => {
    const res = await CarePlanApi.RecurringCompletion({ id1: recId });
    if (res?.success) dispatch(setAlertData(res?.alert));
    navigate(-1);
  };

  const problemBox = (
    <Box height={`${probSectionHeight}%`} sx={{ ...CommonStyle.sxWhiteBox }}>
      <Typography variant="subtitle1" sx={{ ...sxCategoryHead }}>
        {"Problem(s)"}
      </Typography>
      <Box sx={{ ...sxCategoryBody }}>
        {problemArr.length < 1 ? (
          <NoData />
        ) : (
          problemArr.map((el) => {
            return (
              <Typography
                sx={{ ...sxCategoryBox }}
                key={el}
                variant="subtitle1"
              >
                {el}
              </Typography>
            );
          })
        )}
      </Box>
    </Box>
  );

  const deviceBox = (
    <Box
      height={deviceSectionHeight}
      sx={{ ...CommonStyle.sxWhiteBox, overflow: "auto" }}
    >
      <Typography variant="subtitle1" sx={{ ...sxCategoryHead }}>
        {"Device(s)"}
      </Typography>
      <Box sx={{ ...sxCategoryBody }}>
        {!deviceList.length ? (
          <NoData />
        ) : (
          deviceList.map((el) => {
            return (
              <ControlledAccord
                key={el.deviceTypeId}
                handleClick={() => {
                  setViewDeviceId(
                    viewDeviceId == el.deviceTypeId ? null : el.deviceTypeId
                  );
                  setViewGoal(false);
                }}
                open={viewDeviceId == el.deviceTypeId}
                head={
                  <Typography
                    variant="subtitle1"
                    sx={{ ...(viewDeviceId == el.deviceTypeId && sxSelected) }}
                  >
                    {el.deviceTypeDescription}
                  </Typography>
                }
              />
            );
          })
        )}
      </Box>
    </Box>
  );

  const goalBox = (
    <Fragment>
      <Box height={spaceHeight} />
      <Box height={goalSectionHeight} sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}>
        <ControlledAccord
          handleClick={() => {
            setViewDeviceId(null);
            setViewGoal(true);
          }}
          open={viewGoal}
          head={
            <Typography
              variant="subtitle1"
              sx={{ ...(viewGoal && sxSelected) }}
            >
              Goals and Interventions
            </Typography>
          }
        />
      </Box>
    </Fragment>
  );

  const dateBox = (
    <Box width="100%" display="flex" justifyContent="end">
      <Box width="40%" display="flex" alignItems="center" mb={2}>
        {deviceReading &&
          deviceReading?.length > 0 &&
          deviceReading[0]?.average &&
          deviceReading[0]?.average.map((ele) => (
            <Fragment key={ele.readingType}>
              <Box>
                <Typography variant="subtitle1">
                  {`Avg. ${ele.readingTypeUomDescription}`}
                </Typography>
                <Typography variant="subtitle1">{ele?.average}</Typography>
              </Box>
              <Box sx={{ ...sxSperator }}></Box>
            </Fragment>
          ))}
      </Box>
      <Box width="60%" display="flex" alignItems="center" mb={2}>
        <Box width="48%">
          {dateForm.slice(0, 1)?.map((el: FormDataType) => {
            return (
              <Fragment key={el.id}>
                {commonFormSwitch({
                  formObj: { ...el, width: "100%" },
                  onChange: handleDateChange,
                })}
              </Fragment>
            );
          })}
        </Box>
        <Typography sx={{ m: 1 }} variant="subtitle1" fontWeight="bold">
          TO
        </Typography>
        <Box width="48%">
          {dateForm.slice(1, 2)?.map((el: FormDataType) => {
            return (
              <Fragment key={el.id}>
                {commonFormSwitch({
                  formObj: { ...el, width: "100%" },
                  onChange: handleDateChange,
                })}
              </Fragment>
            );
          })}
        </Box>
        <Typography sx={{ ml: 1, display: "flex" }}>
          <IconButton
            title="Apply Filter"
            sx={{ ...CommonStyle.sxRoundIconsMainSm, mr: 1 }}
            onClick={() => handleDeviceReading()}
          >
            <ImgPath.SaveIcon />
          </IconButton>
          <IconButton
            title="Refesh"
            sx={{ ...CommonStyle.sxRoundIconsMainSm }}
            onClick={() => handleRefreshDate()}
          >
            <ImgPath.RefreshIcon />
          </IconButton>
        </Typography>
      </Box>
    </Box>
  );

  const TblBodyJSX1 = (
    <ControlledTable.TblBody
      tableColumn={(currentRow: any) =>
        TableCol1(handleReadMore, currentRow.readMoreLength, checkReadMore)
      }
      tableRow={notes}
    />
  );

  const TblHeadJSX = useMemo(() => {
    return ControlledTable.TblHead(
      TableCol(deviceUOMList.filter((el) => el.deviceTypeId == viewDeviceId))
    );
  }, [viewDeviceId]);

  const TblBodyJSX = useMemo(() => {
    return (
      <ControlledTable.TblBody
        tableColumn={() =>
          TableCol(
            deviceUOMList.filter((el) => el.deviceTypeId == viewDeviceId)
          )
        }
        tableRow={tblRows}
      />
    );
  }, [graphData.datasets]);

  const noteBox = (
    <Fragment>
      <Divider sx={{ my: 2 }}>
        <Chip label="Notes Section" size="small" color="primary" />
      </Divider>
      {!viewOnlyMode && (
        <Box display="flex">
          <Box width="100%">
            {formArr.map((el) => {
              if (el.boxType == "empty-block") return <Fragment key={el.id} />;
              if (el.id == "note") {
                return (
                  <Box display="flex" key={el.id} mb={2}>
                    <Box width="80%">
                      {commonFormSwitch({
                        formObj: el,
                        onChange: (data) =>
                          setFormArr(HandleFormChange(data, formArr)),
                      })}
                    </Box>
                    <Box sx={{ ...sxButtonBox }}>
                      <CstmBtn
                        label="Save"
                        onClick={() => handleSave("save")}
                      />
                      {selectedPrgm.teamMember && myInfo.roleId != 5 && (
                        <CstmBtn
                          label="Save & Send Now"
                          onClick={() => handleSave("send-now")}
                        />
                      )}
                      {showCompletionBtn && (
                        <CstmBtn
                          label="Complete"
                          onClick={() => handleSave("complete")}
                        />
                      )}
                      <CstmBtn
                        label="cancel"
                        btnType="border"
                        onClick={() => navigate(-1)}
                      />
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Box key={el.id} display="flex" alignItems="center" mb={1}>
                    <Typography
                      variant="subtitle1"
                      width="70%"
                      sx={{ "&>span": { color: "error.main" } }}
                    >
                      {el.label}
                      {el.required ? <span>*</span> : ""}:
                    </Typography>
                    <Box width="30%">
                      {el.boxType == "single-select-drop" ? (
                        <ControlledForm.SingleSelectDrop
                          {...el}
                          label=""
                          onClick={(value) =>
                            handleNoteFormChange({ id: el.id, value })
                          }
                        />
                      ) : (
                        <ControlledForm.Input
                          {...el}
                          label=""
                          defaultValue=""
                          value={CheckStringEmpty(el.value)}
                          onChange={(value) =>
                            handleNoteFormChange({ id: el.id, value })
                          }
                        />
                      )}
                    </Box>
                  </Box>
                );
              }
            })}
          </Box>
        </Box>
      )}
      {viewNotesList && (
        <TableLayout
          tblBody={TblBodyJSX1}
          tblHead={ControlledTable.TblHead(
            TableCol1(handleReadMore, 50, checkReadMore)
          )}
          height={300}
          loading={tableLoad}
          Pagination={
            <ControlledTable.Pagination
              mainPage={page}
              limit={limit}
              total={totalRecord}
              tableStyle={true}
              handleClick={handleNoteSearch}
            />
          }
        />
      )}
    </Fragment>
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="50%"
        heading={"Warning"}
        content={
          <Box textAlign={"center"}>
            <Typography mb={1}>
              Patient will be unenrolled from the RPM Program. Any futher RPM
              activities will not be applicable for this patient. All devices
              assigned to or ordered for this patient will be suspended / called
              back respectively. RPM Services for the patient will not be
              billable
            </Typography>
            <Typography>Do you still want to proceed?</Typography>
          </Box>
        }
        open={medModal}
        onClose={() => setMedModal(false)}
        buttonArr={CommonButtonArray(
          () => handleUnrollCall(),
          () => setMedModal(false),
          "Yes",
          "No"
        )}
      />
      <Box width="21%" mr={2}>
        {problemBox}
        <Box height={spaceHeight} />
        {deviceBox}
        {viewGoalSection && goalBox}
      </Box>
      {viewGoal || viewDeviceId ? (
        <Box width="67%" sx={{ ...CommonStyle.sxWhiteCard }} overflow="auto">
          {viewGoal ? (
            <SummaryView summary={summaryV3} />
          ) : (
            <Fragment>
              {deviceList.find((el) => el.deviceTypeId == viewDeviceId)
                ?.actualDeviceId ? (
                  <Fragment>
                    {dateBox}
                    <Box sx={{ ...sxDeviceDetails }}>
                      <LineChart data={graphData} dateUnit={dateunit.current} />
                    </Box>
                    <Box my={2}>
                      <TableLayout
                        tblBody={TblBodyJSX}
                        tblHead={TblHeadJSX}
                        {...(generateRows(graphData.datasets).length > 10 && {
                          height: "400px",
                        })}
                      />
                    </Box>
                  </Fragment>
                ) : (
                  <Fragment />
                )}
              <PatientThreshold
                showSaveButton={false}
                isSavedCounter={thresCount}
                patientId={patientInfo.id}
                filterData={{
                  deviceTypeId: viewDeviceId,
                }}
              />
              {selectedPrgm.teamMember && (
                <Box sx={{ ...sxThresButtonBox }}>
                  <CstmBtn
                    label="Save Thresholds"
                    onClick={() => setThresCount(thresCount + 1)}
                    width="16%"
                  />
                </Box>
              )}
              {viewNoteSection && noteBox}
            </Fragment>
          )}
        </Box>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
export default RPMDeviceCharts;

const sxDeviceDetails = {
  "& canvas": {
    height: "300px",
  },
};

const sxCategoryHead = {
  height: 40,
  fontWeight: "bold",
  px: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const sxCategoryBody = {
  height: "calc(100% - 40px)",
  overflow: "auto",
  px: 2,
};

const sxCategoryBox = {
  borderBottom: "0.5px solid",
  borderColor: "custom.main",
  py: 1,
};

const sxSelected = {
  fontWeight: "bold",
};

const sxButtonBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "end",
  "& >button": { mb: 1, width: "80%" },
  "& > button:last-of-type": { mb: 0 },
  width: "20%",
};

const sxThresButtonBox = {
  mt: 1,
  display: "flex",
  justifyContent: "flex-end",
};

const sxSperator = {
  mr: 2,
  ml: 2,
  backgroundColor: "#c3c3c3",
  width: "0.1%",
};
