// import { FC, Fragment } from "react";
// import {
//   TableContainer,
//   Table as MuiTable,
//   TableBody,
//   TableHead,
//   Box,
//   TableRow,
//   TableCell,
//   TableFooter,
// } from "@mui/material";
// import { TableLayoutType } from "DataTypes/Layout";
// import { Loader } from "Components";
// import NoData from "Components/NoData";

// const TableLayout: FC<TableLayoutType> = ({
//   tblHead,
//   tblBody,
//   height,
//   Pagination,
//   extraRow,
//   loading,
//   maxHeight,
// }: TableLayoutType) => {
//   const RowLength = tblBody?.props?.tableRow?.length || 0;
//   return (
//     <Box {...(height && { height: height })} width="100%">
//       <TableContainer
//         sx={{
//           ...(Pagination && RowLength > 0
//             ? sxTblBorderPagination
//             : sxTblBorder),
//           ...(height && Pagination && RowLength > 0
//             ? { height: Pagination ? "90%" : "100%" }
//             : { maxHeight: "100%" }),
//           ...(maxHeight
//             ? {
//               maxHeight: maxHeight,
//               overflowY: "auto",
//             }
//             : {}),
//         }}
//       >
//         <MuiTable stickyHeader aria-label="sticky table">
//           <TableHead sx={{ position: "relative" }}>{tblHead}</TableHead>
//           <TableBody>
//             {loading ? (
//               <TableRow sx={{ ...LoaderRow }}>
//                 <TableCell colSpan={10} valign="middle">
//                   <Loader />
//                 </TableCell>
//               </TableRow>
//             ) : RowLength > 0 ? (
//               tblBody
//             ) : (
//               <TableRow sx={{ ...LoaderRow }}>
//                 <TableCell colSpan={10} valign="middle">
//                   <NoData />
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//           {extraRow ?? <TableFooter>{extraRow}</TableFooter>}
//         </MuiTable>
//       </TableContainer>

//       {RowLength > 0 && Pagination}
//     </Box>
//   );
// };
// export default TableLayout;

// const sxTblBorder = {
//   border: "1px solid",
//   borderColor: "custom.main",
//   borderRadius: 1.25,
// };

// const sxTblBorderPagination = {
//   border: "1px solid",
//   borderColor: "custom.main",
//   borderRadius: 1.25,
//   borderBottomLeftRadius: 0,
//   borderBottomRightRadius: 0,
// };

// const LoaderRow = {
//   "& > td": {
//     borderBottom: "unset !important",
//   },
// };

import { FC, Fragment } from "react";
import {
  TableContainer,
  Table as MuiTable,
  TableBody,
  TableHead,
  Box,
  TableRow,
  TableCell,
  TableFooter,
} from "@mui/material";
import { TableLayoutType } from "DataTypes/Layout";
import { Loader } from "Components";
import NoData from "Components/NoData";

const TableLayout: FC<TableLayoutType> = ({
  tblHead,
  tblBody,
  height,
  Pagination,
  extraRow,
  loading,
  maxHeight,
}: TableLayoutType) => {
  const RowLength = tblBody?.props?.tableRow?.length || 0;
  const totalPages = Pagination?.props?.total || 0;
  return (
    <Box {...(height && { height })} width="100%">
      <TableContainer
        sx={{
          ...(Pagination ? sxTblBorderPagination : sxTblBorder),
          ...(height && Pagination
            ? { height: Pagination ? "90%" : "100%" }
            : { maxHeight: "100%" }),
          ...(maxHeight
            ? {
              maxHeight: maxHeight,
              overflowY: "auto",
            }
            : {}),
        }}
      >
        <MuiTable stickyHeader aria-label="sticky table">
          <TableHead sx={{ position: "relative" }}>{tblHead}</TableHead>
          <TableBody>
            {loading ? (
              <TableRow sx={{ ...LoaderRow }}>
                <TableCell colSpan={10} valign="middle">
                  <Loader />
                </TableCell>
              </TableRow>
            ) : RowLength > 0 ? (
              tblBody
            ) : (
              <TableRow sx={{ ...LoaderRow }}>
                <TableCell colSpan={10} valign="middle">
                  <NoData />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {extraRow ?? <TableFooter>{extraRow}</TableFooter>}
        </MuiTable>
      </TableContainer>
      {totalPages > 9 && Pagination}
    </Box>
  );
};
export default TableLayout;

const sxTblBorder = {
  border: "1px solid",
  borderColor: "custom.main",
  borderRadius: 1.25,
};

const sxTblBorderPagination = {
  border: "1px solid",
  borderColor: "custom.main",
  borderRadius: 1.25,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
};

const LoaderRow = {
  "& > td": {
    borderBottom: "unset !important",
  },
};
