import { Fragment, FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import PatientApi from "Service/Patient.api";
import { RedoxAllergyType } from "DataTypes/Services/Patient.type";
import Loader from "Components/Loader";
import NoData from "Components/NoData";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { SideModalProps } from "DataTypes/Modal";
import RedoxFormatter from "Utils/Redox.Formatter";

const Allergy: FC<SideModalProps> = () => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [allergyList, setAllergyList] = useState<RedoxAllergyType[]>([]);
  const [loading, setLoading] = useState(true);
  const getAllergyData = async () => {
    const res: any = await PatientApi.RedoxAllergy({ id1: patientInfo.id });
    if (res?.success) {
      setAllergyList(RedoxFormatter.AllergiesList(res?.data?.allergies));
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllergyData();
  }, []);

  if (loading) return <Loader />;

  return (
    <Fragment>
      {allergyList.length ? (
        allergyList.map((el) => {
          return (
            <Typography key={el.id} variant="body2" sx={{ mb: 1 }}>
              • {el.name}
            </Typography>
          );
        })
      ) : (
        <NoData />
      )}
    </Fragment>
  );
};
export default Allergy;
