import { Box, Divider, Typography } from "@mui/material";
import { useEffect, FC, Fragment, useState } from "react";
import { CommonStyle, ControlledForm, Loader } from "Components";
import AlertHeads from "./AlertHeads";
import ProblemAlert from "./ProblemAlert";
import CstmBtn from "Components/CstmBtn";
import {
  DeviceTypeType,
  ThresholdType,
} from "DataTypes/Services/Settings.type";
import ThresholdsApi from "Service/Thresholds.api";
import ThresholdsFormatter from "Utils/Thresholds.Formatter";
import { ConvertStringToNum } from "Utils/common";
import { ListInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector, useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { DropdownArrPropsType } from "DataTypes/Form";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const GlobalThresholdAlerts: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { orgDropList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const [orgVal, setOrgVal] = useState<string | number>("");
  const [orgList, setOrgList] = useState<DropdownArrPropsType[]>([]);
  const [data, setData] = useState<ThresholdType[]>([]);
  const [loading, setLoading] = useState(true);
  const [deviceType, setDeviceType] = useState<DeviceTypeType[]>([]);

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "global-thres-alerts",
      label: "Global Threshold Alerts",
      path: `/${RouteUrls.hs}/${RouteUrls.setting}/${RouteUrls.globalThresholdAlerts}`,
      level: 1,
      link: RouteUrls.globalThresholdAlerts,
      navType: "settings",
    });
  }, []);

  useEffect(() => {
    LoadOrgList();
  }, [orgDropList]);

  const LoadOrgList: () => void = () => {
    let newArr: DropdownArrPropsType[] = JSON.parse(
      JSON.stringify(orgDropList)
    );
    newArr = newArr.filter((el) => el.label != "CareVitality");
    if (myInfo.roleName == "Super Admin") {
      newArr = [{ id: "global", label: "Global", value: "global" }, ...newArr];
    }
    setOrgList(newArr);
  };

  useEffect(() => {
    if (orgList.length > 0) {
      setOrgVal(orgList[0].value);
    }
  }, [orgList]);

  useEffect(() => {
    ThresApiCall();
  }, [orgVal]);

  const filterDevices = (devices: ThresholdType[]): DeviceTypeType[] => {
    const filteredDevices: { [key: string]: DeviceTypeType } = {};

    return devices.reduce((type: DeviceTypeType[], item) => {
      if (item.deviceTypeId && !filteredDevices[item.deviceTypeId]) {
        filteredDevices[item?.deviceTypeId] = {
          deviceTypeId: item.deviceTypeId,
          deviceTypeDescription: item.deviceTypeDescription,
        };
        type.push(filteredDevices[item?.deviceTypeId]);
      }
      return type;
    }, []);
  };

  const ThresApiCall: () => void = async () => {
    setLoading(true);
    if (orgVal) {
      const payload = { practiceId: orgVal == "global" ? "" : orgVal };
      const res = await ThresholdsApi.Search({
        data: payload,
        offset: 0,
        limit: 1000,
      });
      if (res?.success) {
        const data = ThresholdsFormatter.Search(res?.data);
        setData(data);

        setDeviceType(filterDevices(data));
      }
    } else setData([]);
    setLoading(false);
  };

  const ChangeThreshold: (val: ThresholdType) => void = (val) => {
    setData((prevData) =>
      prevData.map((el) =>
        el.deviceTypeId === "WEIGHT_SCALE"
          ? el.deviceTypeId === val.deviceTypeId &&
            el.problemId === val.problemId
            ? val
            : el
          : el.deviceTypeId === val.deviceTypeId &&
            el.uomId === val.uomId &&
            el.alertTypeId === val.alertTypeId
            ? val
            : el
      )
    );
  };

  const HandleSave: () => void = async () => {
    setLoading(true);
    const payload = data.map((el) => {
      return {
        // thresholdType: el.thresholdType,
        operatorId: el.operatorId,
        practiceId: orgVal ? (orgVal == "global" ? null : orgVal) : null,
        uomId: el.uomId,
        deviceTypeId: el.deviceTypeId,
        alertType: el.alertType,
        problemId: el.problemId,
        upperLimit: ConvertStringToNum(el.upperLimit),
        weightValue: ConvertStringToNum(el.weightValue),
        weightFluctuation: el.weightFluctuation,
        daysLimit: ConvertStringToNum(el.daysLimit),
        lowerLimit: ConvertStringToNum(el.lowerLimit),
      };
    });
    const res = await ThresholdsApi.Update({ data: payload });
    if (res?.success) dispatch(setAlertData(res?.alert));
    setLoading(false);
  };

  return (
    <Box my={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {"Global Threshold Alerts"}
        </Typography>
        <Box width="20%">
          <ControlledForm.SingleSelectDrop
            id="org"
            label="Select Organization"
            dropValue={orgVal}
            dropArr={orgList}
            onClick={(val) =>
              setOrgVal(
                typeof val == "number" || typeof val == "string" ? val : ""
              )
            }
          />
        </Box>
      </Box>
      <Box my={1}>
        <AlertHeads />
      </Box>
      {loading ? (
        <Loader />
      ) : (
        deviceType &&
        deviceType
          .filter((filEl) =>
            data.find((findEl) => findEl.deviceTypeId == filEl.deviceTypeId)
          )
          .map((el, index) => {
            const filterArr = data.filter(
              (filEl1) => filEl1.deviceTypeId == el.deviceTypeId
            );
            return (
              <Fragment key={el.deviceTypeId}>
                <ProblemAlert
                  data={filterArr}
                  deviceName={el.deviceTypeDescription}
                  handleChange={ChangeThreshold}
                />
                {deviceType.length - 1 != index && <Divider sx={{ my: 0.5 }} />}
              </Fragment>
            );
          })
      )}
      <Box
        display="flex"
        justifyContent={"flex-end"}
        mt={1}
        sx={{ "&>button": { ml: 1, width: "10%" } }}
      >
        {data.length > 0 && <CstmBtn label="Save" onClick={HandleSave} />}
        <CstmBtn label="Cancel" onClick={() => navigate(-1)} btnType="border" />
      </Box>
    </Box>
  );
};
export default GlobalThresholdAlerts;
