import { Fragment, FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import PatientApi from "Service/Patient.api";
import Loader from "Components/Loader";
import NoData from "Components/NoData";
import { SideModalProps } from "DataTypes/Modal";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { RedoxVaccineType } from "DataTypes/Services/Patient.type";
import RedoxFormatter from "Utils/Redox.Formatter";
import DateTime from "Utils/DateTime";

const Vaccine: FC<SideModalProps> = () => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const newPatientId = patientInfo.id;
  const [vaccineList, setVaccineList] = useState<RedoxVaccineType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getVaccineData();
  }, []);

  const getVaccineData = async () => {
    const res: any = await PatientApi.RedoxVaccines({ id1: newPatientId });
    if (res?.success) {
      setVaccineList(RedoxFormatter.VaccineList(res?.data?.immunizations));
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <Fragment>
      {vaccineList.length ? (
        vaccineList.map((el) => {
          return (
            <Typography key={el.id} variant="body2" sx={{ mb: 1 }}>
              • {DateTime.ToLocalDate(el.dateTime)} - {el.name}
            </Typography>
          );
        })
      ) : (
        <NoData />
      )}
    </Fragment>
  );
};
export default Vaccine;
