import { FC, Fragment, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { FormDataType } from "DataTypes/Form";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { HandleChangeProps } from "DataTypes/Common";
import DateTime from "Utils/DateTime";
import CstmBtn from "Components/CstmBtn";
import {
  CheckStringEmpty,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import PatientApi from "Service/Patient.api";
import { setAlertData } from "Redux/Screen/Action";
import { useSelector } from "react-redux";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useDispatch } from "react-redux";
import { TimeModalFormData } from "./Data";

const LogTimeModal: FC<{ setClose: () => void }> = ({ setClose }) => {
  const dispatch = useDispatch();
  const { selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [formData, setFormData] = useState<FormDataType[]>([]);

  useEffect(() => {
    handleOpenFormData();
  }, []);

  const handleOpenFormData: () => void = () => {
    const newFormData: FormDataType[] = JSON.parse(
      JSON.stringify(TimeModalFormData)
    );
    newFormData[0].required = true;
    newFormData[1].required = true;
    newFormData[2].value = selectedPrgm.careplanActivity || "";
    newFormData[2].readOnly = true;
    newFormData[2].required = true;
    newFormData[3].readOnly = true;
    newFormData[3].value = "0";
    newFormData[3].required = false;
    setFormData(newFormData);
  };

  const handleChange: (props: HandleChangeProps) => void = (props) => {
    const { value, id } = props;
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formData));
    if (id == "start-date-time") {
      newFormData[1].minVal = CheckStringEmpty(value);
      newFormData[3].value = DateTime.CalculateDuration(
        CheckStringEmpty(value),
        CheckStringEmpty(newFormData[1].value)
      ).toString();
    }
    if (id == "end-date-time") {
      newFormData[0].maxVal = CheckStringEmpty(value);
      newFormData[3].value = DateTime.CalculateDuration(
        CheckStringEmpty(newFormData[0].value),
        CheckStringEmpty(value)
      ).toString();
    }
    setFormData(HandleFormChange(props, newFormData));
  };

  const handleModalTimeLog: () => void = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck) {
      const payload = {
        careplanId: selectedPrgm?.careplanId,
        careplanRecurringId: selectedPrgm?.careplanRecurringId || null,
        startDateTime: DateTime.ToUTCDateTime(formData[0].dropValue),
        endDateTime: DateTime.ToUTCDateTime(formData[1].dropValue),
        notes: CheckStringEmpty(formData[4].value),
        reasons: selectedPrgm?.careplanActivity || null,
        status: "ACTIVE",
        date: DateTime.CurrentUTCDateTime(),
        billingMonth: parseInt(DateTime.CurrentMonth()),
        billingYear: parseInt(DateTime.CurrentYear()),
      };
      const res = await PatientApi.timeLog({
        data: payload,
        id1: selectedPrgm?.careplanId,
      });
      if (res.success) {
        dispatch(setAlertData(res?.alert));
        setClose();
      }
    }
  };

  return (
    <Fragment>
      {/* <Box sx={{ ...sxHead }}>
        <Box mr={3}>
          <Typography variant="body2">{"Patient's Name:"}</Typography>
          <Typography variant="body2" fontWeight="bold">
            {patientName}
          </Typography>
        </Box>
        <Box mr={3}>
          <Typography variant="body2">Organization Name:</Typography>
          <Typography variant="body2" fontWeight="bold">
            {practiceName}
          </Typography>
        </Box>
        <Box mr={3}>
          <Typography variant="body2">Date:</Typography>
          <Typography variant="body2" fontWeight="bold">
            {DateTime.CurrentDate()}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">Billing Month:</Typography>
          <Typography variant="body2" fontWeight="bold">
            {billingMonth}
          </Typography>
        </Box>
      </Box> */}
      {/* <Box> */}
      <Grid container rowSpacing={1} columnSpacing={2}>
        {formData.map((el) => {
          return (
            <Grid
              key={el.id}
              item
              {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
            >
              {commonFormSwitch({ formObj: el, onChange: handleChange })}
            </Grid>
          );
        })}
      </Grid>
      <Box
        sx={{
          "&>button": { ml: 1, width: "10%" },
          display: "flex",
          justifyContent: "flex-end",
          mt: 1,
        }}
      >
        <CstmBtn label="Save" onClick={handleModalTimeLog} />
        <CstmBtn label="Close" onClick={setClose} btnType="border" />
      </Box>
      {/* </Box> */}
    </Fragment>
  );
};
export default LogTimeModal;

const sxHead = {
  display: "flex",
  flexdirection: "row",
  width: "100%",
  justifyContent: "space-between",
  mb: 2,
  "& >div": {
    "& >p:first-of-type": {
      width: "100%",
    },
  },
};
