import ControlledForm from "Components/ControlledForm";
import ControlledTable from "Components/ControlledTable";
import { FormDataType } from "DataTypes/Form";
import { actionArrType, columnType } from "DataTypes/Table";

export const TableCol: (programId: number | null) => columnType[] = (
  programId
) => {
  return [
    ControlledTable.ColumnData.StartDateTimeCol(),
    ControlledTable.ColumnData.EndDateTimeCol(),
    ControlledTable.ColumnData.DurationCol(),
    ControlledTable.ColumnData.ActivityCol(),
    ControlledTable.ColumnData.ActivityTypeCol(),
    ControlledTable.ColumnData.NoteCol(),
    ...(programId == 5
      ? [ControlledTable.ColumnData.InteractiveCommunicationCol()]
      : []),
    // ControlledTable.ColumnData.ActionCol(),
  ];
};

export const actionArr: (handleView: () => void) => actionArrType[] = (
  handleView
) => {
  return [ControlledTable.ActionData.viewTitleAction(handleView)];
};

export const DataArr = () => {
  const arr: FormDataType[] = [
    {
      id: "programName",
      label: "Program Name",
      dropArr: [],
      placeholder: "Select Program Name",
      boxType: "single-select-drop",
      boxSize: 6,
      validatetype: "dropdown",
      readOnly: true,
    },
    {
      id: "activity",
      label: "Activity",
      placeholder: "Select Activity",
      boxType: "input-box",
      boxSize: 6,
      validatetype: "name",
      readOnly: true,
    },
    {
      id: "start-date-time",
      label: "Start Date & Time",
      boxType: "mui-datetime",
      boxSize: 6,
      validatetype: "date",
      readOnly: true,
    },
    {
      id: "end-date-time",
      label: "End Date & Time",
      boxType: "mui-datetime",
      boxSize: 6,
      validatetype: "date",
      readOnly: true,
    },
    {
      id: "type",
      label: "Type",
      placeholder: "Select Type",
      boxType: "single-select-drop",
      dropArr: [],
      boxSize: 6,
      validatetype: "dropdown",
      readOnly: true,
    },
    ControlledForm.FormData.Duration(),
    {
      id: "interactive",
      label: "Was interactive communication done with the Patient?",
      placeholder: "Select an option",
      boxType: "empty-block",
      boxSize: 6,
      validatetype: "dropdown",
      readOnly: true,
      dropArr: [],
    },
    {
      id: "note",
      label: "Notes",
      placeholder: "Enter Notes",
      boxType: "input-box",
      boxSize: 12,
      validatetype: "notes",
      multiline: true,
      multilineRows: 5,
      readOnly: true,
    },
  ];
  return arr;
};
