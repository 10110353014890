import { FormDataType } from "DataTypes/Form";
import { TimeZoneArr } from "Constant/Arr";
import { ControlledForm } from "Components";
import { FormatOrgLocationType } from "DataTypes/PracticeMangement.type";
import BreadUrls from "Constant/BreadUrls";
import { BreadArrType } from "DataTypes/Common";

export const PracticeFormDataArr: FormDataType[] = [
  ControlledForm.FormData.PracticeNpiData({ required: true }),
  ControlledForm.FormData.PracticeNameData({ required: true }),
  ControlledForm.FormData.BusinessModelData({ required: true }),
  ControlledForm.FormData.ProgramData({ required: true }),
  ControlledForm.FormData.PracticeAdminNameData({ required: true }),
  ControlledForm.FormData.PrimEmailData({ required: true }),
  ControlledForm.FormData.SecEmailData(),
];

export const LocationFormDataArr: FormDataType[] = [
  ControlledForm.FormData.LocationNameData(),
  ControlledForm.FormData.Address1Data(),
  ControlledForm.FormData.Address2Data(),
  ControlledForm.FormData.CityNameData(),
  ControlledForm.FormData.StateNameData(),
  ControlledForm.FormData.ZipcodeData(),
  ControlledForm.FormData.CountryNameData(),
  ControlledForm.FormData.OfficeNoData(),
  ControlledForm.FormData.DirectNoData(),
  ControlledForm.FormData.DirectNo2Data(),
  ControlledForm.FormData.DirectNo3Data(),
  ControlledForm.FormData.DirectNo4Data(),
  ControlledForm.FormData.ServiceLocationData(),
  ControlledForm.FormData.TimezoneData({ dropArr: TimeZoneArr }),
];

export const LocationArr: FormatOrgLocationType = {
  id: null,
  direct1: null,
  direct2: null,
  direct3: null,
  direct4: null,
  locationFormData: LocationFormDataArr,
  status: "ACTIVE",
  isMasterPracticeLocation: true,
  practiceLocationOrder: 1,
};

export const BreadData: (
  myOrganization: boolean,
  OrgId: string | undefined
) => BreadArrType[] = (myOrganization, OrgId) => {
  if (myOrganization) {
    return [
      {
        id: 1,
        label: "Home",
        path: "/",
      },
      {
        id: 2,
        label: "My Organization",
        path: "",
      },
    ];
  } else {
    return [
      {
        id: 1,
        label: "Home",
        path: "/",
      },
      {
        id: 2,
        label: "Organization List",
        path: BreadUrls.OrgList(),
      },
      {
        id: 3,
        label: `${OrgId ? "Update" : "Add"} Organization`,
        path: "",
      },
    ];
  }
};
