import { RouteUrls } from "Constant/RouteUrls";
import { UserInitialStateType } from "DataTypes/Redux";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

interface propType {
  Child: FC;
  roleIds: number[];
}

const ProtectedRoute: FC<propType> = ({ Child, roleIds }) => {
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  if (roleIds.includes(myInfo.roleId || 0)) return <Child />;
  else return <Navigate to={`/${RouteUrls.hs}/${RouteUrls.notfound}`} />;
};

export default ProtectedRoute;
