import { MyInfoType, UserActionReturnType } from "DataTypes/Redux";
import {
  CLEAR_MY_INFO,
  CLEAR_MY_PERMISSION,
  CLEAR_USER,
  SET_MY_INFO,
  SET_MY_PERMISSION,
  UPDATE_RPM_ALERT_COUNT,
  UPDATE_SELECTED_ALERT,
} from "./type";

export const setMyInfo: (data: MyInfoType) => UserActionReturnType = (data) => {
  return {
    type: SET_MY_INFO,
    payload: data,
  };
};

export const clearMyInfo: () => UserActionReturnType = () => {
  return {
    type: CLEAR_MY_INFO,
  };
};

export const setMyPermission: (data: any) => UserActionReturnType = (data) => {
  return {
    type: SET_MY_PERMISSION,
    payload: data,
  };
};

export const clearMyPermission: () => UserActionReturnType = () => {
  return {
    type: CLEAR_MY_PERMISSION,
  };
};

export const updateRPMAlertCount: (data: number) => {
  type: string;
  payload: number;
} = (data) => {
  return {
    type: UPDATE_RPM_ALERT_COUNT,
    payload: data,
  };
};

export const updateSelectedAlert: (data: number | null) => {
  type: string;
  payload: number | null;
} = (data) => {
  return {
    type: UPDATE_SELECTED_ALERT,
    payload: data,
  };
};

export const clearUser: () => UserActionReturnType = () => {
  return {
    type: CLEAR_USER,
  };
};
