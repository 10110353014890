import { FormDataType } from "DataTypes/Form";
import {
  UserListApiType,
  UserPracticeApiType,
  UserReadApiType,
  UserRolePermissionApiType,
} from "DataTypes/Services/User.type";
import DateTime from "Utils/DateTime";
import {
  CheckStringEmpty,
  FormatSysMobileNo,
  GetUniqueArr,
} from "Utils/common";

const FindUniqueFromUser: (
  userArr: UserListApiType[],
  myDetails?: UserReadApiType | null
) => string[] = (userArr, myDetails) => {
  let mailArr = userArr.map((userEl) => userEl.email);
  mailArr = GetUniqueArr(mailArr);
  mailArr = mailArr.filter((mailEl) => mailEl.length > 0);
  myDetails &&
    (mailArr = mailArr.filter((mailEL) => mailEL != myDetails?.email));
  return mailArr;
};

const MapUserToForm: (
  user: UserReadApiType,
  formArr: FormDataType[]
) => FormDataType[] = (user, formArr) => {
  formArr[0].value = user.userName;
  formArr[1].value = user.firstName;
  formArr[2].value = user.lastName;
  formArr[3].dropValue = formArr[3].value = user.gender;
  formArr[4].value = user.speciality;
  formArr[4].required = user.role[0].id == 5 ? true : false;
  formArr[5].value = user.designation;
  formArr[6].dropValue = formArr[6].value = user.role[0].id || 0;
  formArr[7].value = user.licence;
  formArr[8].value = user.providerNpi;
  formArr[8].required = user.role[0].id == 5 ? true : false;
  formArr[9].value = user.email;
  formArr[10].value = FormatSysMobileNo(user.mobile);
  formArr[11].value = FormatSysMobileNo(user.officeNo);
  formArr[12].value = FormatSysMobileNo(user.directNo);
  formArr[13].value = DateTime.ToLocalDate(user.dob);
  formArr[14].value = user.address1;
  formArr[15].value = user.city;
  formArr[16].value = user.state;
  formArr[17].value = user.zipcode;
  formArr[18].value = user.country;
  formArr[19].dropValue = formArr[19].value = user.timezone;
  return formArr;
};

const ApiPayload: (
  form1: FormDataType[],
  permissionArr: UserRolePermissionApiType[],
  practiceArr: UserPracticeApiType[],
  myProfile: UserReadApiType | null
) => UserReadApiType = (form1, permissionArr, practiceArr, myProfile) => {
  const newArr = permissionArr.map((el) => {
    return { id: el.id };
  });

  return {
    ...(myProfile && myProfile.id && { id: myProfile.id }),
    userName: typeof form1[0].value == "string" ? form1[0].value : "",
    firstName: typeof form1[1].value == "string" ? form1[1].value : "",
    lastName: typeof form1[2].value == "string" ? form1[2].value : "",
    gender: typeof form1[3].dropValue == "string" ? form1[3]?.dropValue : "",
    speciality: typeof form1[4].value == "string" ? form1[4].value : "",
    designation: typeof form1[5].value == "string" ? form1[5].value : "",
    role: [
      {
        id: typeof form1[6].dropValue == "number" ? form1[6]?.dropValue : null,
      },
    ],
    licence: typeof form1[7].value == "string" ? form1[7].value : "",
    providerNpi: typeof form1[8].value == "string" ? form1[8].value : "",
    email: typeof form1[9].value == "string" ? form1[9].value : "",
    secondaryEmail: "test@gmail.com",
    mobile: CheckStringEmpty(form1[10].value).replaceAll("-", ""),
    officeNo: CheckStringEmpty(form1[11].value).replaceAll("-", ""),
    directNo: CheckStringEmpty(form1[12].value).replaceAll("-", ""),
    dob: DateTime.ToUTCDate(form1[13].value),
    address1: typeof form1[14].value == "string" ? form1[14].value : "",
    address2: "test",
    city: typeof form1[15].value == "string" ? form1[15].value : "",
    state: typeof form1[16].value == "string" ? form1[16].value : "",
    country: typeof form1[18].value == "string" ? form1[18].value : "",
    zipcode: typeof form1[17].value == "string" ? form1[17].value : "",
    timezone: typeof form1[19].value == "string" ? form1[19].value : "",
    practice: practiceArr,
    status: myProfile?.status || "ACTIVE",
    ...(!myProfile && { password: "123456" }),
    permission: newArr && newArr.length > 0 ? newArr : [{ id: 3 }],
  };
};

export default { FindUniqueFromUser, MapUserToForm, ApiPayload };
