import { Box } from "@mui/material";
import CstmBtn from "Components/CstmBtn";
import { FormDataType } from "DataTypes/Form";
import { PatientInitialStateType } from "DataTypes/Redux";
import { FC, Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addPatientInfo } from "Redux/Patient/Action";
import { setAlertData } from "Redux/Screen/Action";
import PatientApi from "Service/Patient.api";
import {
  CheckStringEmpty,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import { commonFormSwitch } from "Utils/SwitchCases";

const ClinicalNotesModal: FC<{ setClose: () => void }> = ({ setClose }) => {
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [formData, setFormData] = useState<FormDataType[]>([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = (): void => {
    setLoad(true);
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formArr));
    if (patientInfo.notes) {
      newFormData[0].value = patientInfo.notes;
    }
    setFormData(newFormData);
    setLoad(false);
  };

  const handleSubmit = async (): Promise<void> => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    setLoad(true);
    if (!newArr.errorCheck) {
      const payload = {
        alertNotes: newArr.formData[0].value || "",
        patientId: patientId,
      };
      const res = await PatientApi.PatientClinicalNotes({ data: payload });
      if (res?.success) {
        dispatch(
          addPatientInfo({
            ...patientInfo,
            notes: CheckStringEmpty(newArr.formData[0].value),
          })
        );
        dispatch(setAlertData(res?.alert));
      }
      setFormData([]);
      setClose();
    }
    setLoad(false);
  };

  return (
    <Fragment>
      <Box>
        {formData.length > 0 &&
          formData.map((el) => {
            return (
              <Box key={el.id} sx={{ margin: "auto" }}>
                {commonFormSwitch({
                  formObj: el,
                  onChange: (props) =>
                    setFormData(HandleFormChange(props, formData)),
                })}
              </Box>
            );
          })}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"flex-end"}
          sx={{ "& >button": { ml: 1, mt: 3, mb: 1 } }}
        >
          <CstmBtn label={"Save"} onClick={handleSubmit} disable={load} />
          <CstmBtn
            label={"cancel"}
            btnType="border"
            onClick={() => setClose()}
          />
        </Box>
      </Box>
    </Fragment>
  );
};
export default ClinicalNotesModal;

export const formArr: FormDataType[] = [
  {
    id: "note",
    label: "Clinical Notes",
    placeholder: "Enter Notes",
    boxType: "input-box",
    boxSize: 12,
    validatetype: "clinicalNotes",
    multiline: true,
    multilineRows: 4,
  },
];
