import { FC, Fragment } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import ImgPath from "Constant/Imgs";
import { CommonStyle } from "Components";
import { ModalLayoutType } from "DataTypes/Layout";
import CstmBtn from "Components/CstmBtn";
import { buttonArrType } from "DataTypes/Modal";

const ModalLayout: FC<ModalLayoutType> = (props: ModalLayoutType) => {
  const {
    modheight,
    modwidth,
    heading,
    content,
    open,
    onClose,
    buttonArr,
    warning,
  } = props;
  const body = (
    <Box
      sx={{
        ...sxMain,
        ...CommonStyle.sxWhiteBox,
        ...(modheight && { height: modheight }),
        width: modwidth,
      }}
    >
      {heading && (
        <Box
          sx={{
            ...CommonStyle.sxRow,
            ...sxHead,
            justifyContent: "space-between",
            height: "3rem",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              "&>svg": {
                mr: 1,
              },
            }}
          >
            {warning && <ImgPath.WarningIcon sx={{ color: "error.main" }} />}{" "}
            {heading}
          </Typography>
          {onClose && (
            <IconButton onClick={onClose}>
              <ImgPath.CloseIcon />
            </IconButton>
          )}
        </Box>
      )}
      <Box
        sx={{
          height: heading ? "calc(100% - 6rem)" : "calc(100% - 6rem)",
          p: 2,
        }}
      >
        {content}
      </Box>
      {buttonArr && (
        <Box
          sx={{
            px: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            height: "3rem",
          }}
        >
          {buttonArr.map((el: buttonArrType) => {
            return (
              <Fragment key={el.id}>
                <CstmBtn
                  sxProps={{ ml: 1 }}
                  label={el.label}
                  onClick={el.clickHandler}
                  btnType={el.type}
                  disable={el?.disable}
                />
              </Fragment>
            );
          })}
        </Box>
      )}
    </Box>
  );

  return (
    <div>
      <Modal
        open={open}
        {...(onClose && { onClose: onClose })}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default ModalLayout;

const sxMain = {
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: `translate(-${50}%, -${50}%)`,
  position: "absolute",
  outline: "none",
  // overflow: "auto",
  borderRadius: 1.25,
};

const sxHead = {
  backgroundColor: "active.main",
  px: 2,
  "& >p:first-of-type": {
    color: "common.white",
  },
  "& >button": {
    color: "common.white",
  },
};
