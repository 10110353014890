import { ControlledTable } from "Components";
import FilterData from "Components/Filter2/Data";
import { Filter2DataProps } from "DataTypes/Common";
import { columnType } from "DataTypes/Table";

export const TableCol: () => columnType[] = () => {
  const Arr: columnType[] = [
    {
      id: "actionType",
      label: "Action Type",
      width: "13%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    // As per client request to hide this column
    // {
    //   id: "actionOn",
    //   label: "Action On",
    //   width: "13%",
    //   align: "left",
    //   colType: "text",
    //   rowType: "text",
    // },
    {
      id: "actionBy",
      label: "Action By",
      width: "14%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    {
      id: "description",
      label: "Description",
      width: "30%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    {
      id: "ipAddress",
      label: "IP Address",
      width: "10%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    ControlledTable.ColumnData.DateTimeCol1("20%"),
  ];
  return Arr;
};

export const FilterArr: () => Filter2DataProps[] = () => {
  return [
    FilterData.activityType,
    FilterData.requestedBy,
    FilterData.requestedByDesig,
    FilterData.description,
    FilterData.startDate,
    FilterData.endDate,
  ];
};
