import { Box, Typography } from "@mui/material";
import { Fragment, useEffect, useState, FC } from "react";
import { CommonStyle, ControlledTable, Modal } from "Components";
import { ModalLayout, TableLayout } from "Layouts";
import { InitialFormDataArr, TableCol, actionArr } from "./Data";
import { CommonButtonArray } from "Components/Modal/Data";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { useDispatch } from "react-redux";
import { setAlertData, stopLoading } from "Redux/Screen/Action";
import { FormDataType } from "DataTypes/Form";
import SettingsApi from "Service/Settings.api";
import SettingsFormatter from "Utils/Settings.Formatter";
import { RouteUrls } from "Constant/RouteUrls";
import { SmartmeterSettingListType } from "DataTypes/Services/Settings.type";
import CommonFormatter from "Utils/Common.Formatter";

const SmartmeterSettings: FC = () => {
  const dispatch = useDispatch();
  const [settingsList, setSettingsList] = useState<SmartmeterSettingListType[]>(
    []
  );
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<FormDataType[] | []>(
    JSON.parse(JSON.stringify(InitialFormDataArr()))
  );

  useEffect(() => {
    getRedoxSettingFunction();
    CommonFormatter.HandleNavArr({
      id: "smartmeter-settings",
      label: "Smartmeter Settings",
      path: `/${RouteUrls.hs}/${RouteUrls.setting}/${RouteUrls.smartmeterSettings}`,
      level: 1,
      link: RouteUrls.smartmeterSettings,
      navType: "settings",
    });
  }, []);

  const getRedoxSettingFunction = async (): Promise<void> => {
    setFormData(InitialFormDataArr());
    const res: any = await SettingsApi.SmartmeterRedoxSettings();
    if (res?.success) {
      const formattedData = SettingsFormatter.formateSmartmeterSettingSearch(
        res?.data
      );
      setSettingsList(formattedData);
    }
    setLoading(false);
  };

  const handleSave = async (): Promise<void> => {
    dispatch(stopLoading());
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck) {
      const payload = {
        id1: selectedRow.id,
        data: {
          data: {
            baseUrl: formData[0]?.value,
            secretKey: formData[2]?.value,
            secretToken: formData[3]?.value,
            practiceId: selectedRow.practiceId,
            practiceLocationId: selectedRow.practiceLocationId,
            tokenExpiryInMinutes: formData[1]?.value,
          },
        },
      };
      const updateRes = await SettingsApi.UpdateSmartmeterSetting(payload);
      if (updateRes?.success) {
        dispatch(setAlertData(updateRes?.alert));
        getRedoxSettingFunction();
      }
      dispatch(stopLoading());
      setOpenModal(false);
    }
  };

  const handleClose = (): void => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  const handleEditIcon = (data: SmartmeterSettingListType): void => {
    setFormData(
      formData.map((el) => {
        if (el.id == "baseUrl") {
          el.value = data?.baseUrl;
        }
        if (el.id == "secretKey") {
          el.value = data?.secretKey;
        }
        if (el.id == "secretToken") {
          el.value = data?.secretToken;
        }
        if (el.id == "tokenExpiryInMinutes") {
          el.value =
            (data?.tokenExpiryInMinutes &&
              String(data?.tokenExpiryInMinutes)) ||
            0;
        }
        return el;
      })
    );
    setSelectedRow(data);
    setOpenModal(true);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={settingsList}
      tableAction={() => actionArr(handleEditIcon)}
    />
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="80%"
        heading={"Edit Setting"}
        content={
          <Modal.SmartmeterSettingsModel
            formArr={formData}
            handleChange={(data) =>
              setFormData(HandleFormChange(data, formData))
            }
          />
        }
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        buttonArr={CommonButtonArray(handleSave, handleClose, "Update")}
      />
      <Box
        height={"10%"}
        justifyContent={"space-between"}
        sx={{ ...CommonStyle.sxRow }}
      >
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {"Smartmeter Settings"}
        </Typography>
      </Box>
      <Box>
        <TableLayout
          tblBody={TblBodyJSX}
          loading={loading}
          tblHead={ControlledTable.TblHead(TableCol)}
          height={"100%"}
        />
      </Box>
    </Fragment>
  );
};
export default SmartmeterSettings;
