import { FC } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { CheckBoxProps } from "DataTypes/Form";

const CheckBoxGroup: FC<CheckBoxProps> = (props: CheckBoxProps) => {
  const { label, onChange, items, labelVariant, iconSize, optionFontSize } =
    props;

  return (
    <FormControl>
      {label && (
        <Typography variant={labelVariant ? labelVariant : "body1"}>
          {label}
        </Typography>
      )}
      {items.map((el) => {
        return (
          <FormControlLabel
            key={el.id}
            onChange={() => onChange(el)}
            sx={{
              ...sxCheckBox,
              "& >span:last-of-type": {
                fontSize: optionFontSize ? optionFontSize : "0.8rem",
              },
            }}
            control={
              <Checkbox
                checked={el.checked}
                disabled={el?.disabled} 
                color="secondary"
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: iconSize ? iconSize : "1rem",
                  },
                }}
              />
            }
            label={el.title}
          />
        );
      })}
    </FormControl>
  );
};
export default CheckBoxGroup;

const sxCheckBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  mx: 0,
  mb: 1.5,
  "& >span:first-of-type": {
    p: 0,
    mt: 0.25,
  },
};

