import { ApiServiceProps, objectValues } from "DataTypes/Services/Index.type";
import { post } from ".";

const myTraininglist: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "training/my-training/search",
    data: {
      data: payload.data || {},
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const myTrainingCount: () => any = () => {
  const request = {
    subUrl: "training/my-training/count",
  };
  return post(request);
};

const completedTrainingCount: () => any = () => {
  const request = {
    subUrl: "training/completed-training/count",
  };
  return post(request);
};

const pendingDeviceCount: () => any = () => {
  const request = {
    subUrl: "training/pending-device/count",
  };
  return post(request);
};

const completeTrainingList: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "training/completed-training/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const deviceCallLogList: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `training/calllog/${payload.id1}/search`,
    data: {
      data: {},
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const deviceCallLogCreate: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `training/calllog/${payload.id1}/create`,
    data: {
      data: payload.data,
    },
  };
  return post(request);
};

const trainingNotesList: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `training-notes/patient-device/${payload.id1}/search`,
    data: {
      data: payload.data,
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const updateTrainingNotes: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `training-notes/${payload.id1}/update`,
    data: {
      data: payload.data,
    },
  };
  return post(request);
};

const trainingStatusUpdate: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `training/${payload.id1}/status/update`,
    data: {
      data: payload.data,
    },
  };
  return post(request);
};

const trainingNoteDelete: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `training-notes/${payload.id1}/delete`,
  };
  return post(request);
};

export default {
  myTraininglist,
  myTrainingCount,
  completedTrainingCount,
  completeTrainingList,
  deviceCallLogList,
  deviceCallLogCreate,
  trainingNotesList,
  updateTrainingNotes,
  trainingStatusUpdate,
  trainingNoteDelete,
  pendingDeviceCount,
};
