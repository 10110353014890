import { RouteUrls } from "Constant/RouteUrls";
import { HeaderBoxType, HeaderType } from "DataTypes/Common";
import { MyInfoType } from "DataTypes/Redux";

export const HeadArr: (myInfo: MyInfoType, permission: any) => HeaderType[] = (
  myInfo,
  permission
) => {
  const home: HeaderType = {
    id: "home",
    label: "Home",
    path: "/",
    icon: "home",
    dropArr: [],
  };

  const myStaff: HeaderType = {
    id: "organization-staff",
    label: myInfo.practiceName + " Staff",
    path: `${RouteUrls.hs}/${RouteUrls.organization}/${myInfo.practiceId}/${RouteUrls.staff}/${RouteUrls.list}`,
    icon: "mystaff",
    dropArr: [],
  };

  const prgMaster: HeaderBoxType = {
    id: "program-master",
    label: "Program Master",
    path: `${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.prgm}`,
    type: "text",
  };

  const prblmMaster: HeaderBoxType = {
    id: "problem-master",
    label: "Problem Master",
    path: `${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.prob}/${RouteUrls.list}`,
    type: "text",
  };

  const deviceMaster: HeaderBoxType = {
    id: "device-master",
    label: "Device Master",
    path: `${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.device}`,
    type: "text",
  };

  const prgPrblmMaster: HeaderBoxType = {
    id: "program-problem-master",
    label: "Program Problem Master",
    path: `${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.prgmProbMap}`,
    type: "text",
  };

  const devicePrblmMaster: HeaderBoxType = {
    id: "device-problem-master",
    label: "Device Problem Master",
    path: `${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.deviceProbMap}`,
    type: "text",
  };

  const insPrgMaster: HeaderBoxType = {
    id: "insurance-program-master",
    label: "Program Insurance Master",
    path: `${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.insPrgmMap}`,
    type: "text",
  };

  const catMaster: HeaderBoxType = {
    id: "category-master",
    label: "Question Category Master",
    path: `${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.category}`,
    type: "text",
  };

  const quesMaster: HeaderBoxType = {
    id: "questionnaire-master",
    label: "Questionnaire Master",
    path: `${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.questionnaire}/${RouteUrls.list}`,
    type: "text",
  };

  const master: HeaderType = {
    id: "master",
    label: "Master",
    icon: "master",
    path: "",
    dropArr: [
      ...(permission?.programmaster ? [prgMaster] : []),
      ...(permission?.problemmaster ? [prblmMaster] : []),
      ...(permission?.devicemaster ? [deviceMaster] : []),
      ...(permission?.programproblemmaster ? [prgPrblmMaster] : []),
      ...(permission?.deviceproblemmaster ? [devicePrblmMaster] : []),
      ...(permission?.insurnaceporgrammaster ? [insPrgMaster] : []),
      ...(permission?.questioncategorymaster ? [catMaster] : []),
      ...(permission?.questionnairemaster ? [quesMaster] : []),
    ],
  };

  const org: HeaderType = {
    id: "org",
    label: "Organizations",
    path: `${RouteUrls.hs}/${RouteUrls.organization}/${RouteUrls.orgList}`,
    icon: "org",
    dropArr: [],
  };

  const smartmeterSettings: HeaderBoxType = {
    id: "smartmeterSettings",
    label: "Smartmeter Settings",
    path: `/${RouteUrls.hs}/${RouteUrls.setting}/${RouteUrls.smartmeterSettings}`,
    type: "text",
  };

  const redoxSettings: HeaderBoxType = {
    id: "redoxSettings",
    label: "Redox Settings",
    path: `/${RouteUrls.hs}/${RouteUrls.setting}/${RouteUrls.redoxSettings}`,
    type: "text",
  };

  const appSettings: HeaderBoxType = {
    id: "appSettings",
    label: "Application Settings",
    path: `/${RouteUrls.hs}/${RouteUrls.setting}/${RouteUrls.appSettings}`,
    type: "text",
  };

  const globalAlert: HeaderBoxType = {
    id: "globalAlert",
    label: "Global Threshold Alerts",
    path: `/${RouteUrls.hs}/${RouteUrls.setting}/${RouteUrls.globalThresholdAlerts}`,
    type: "text",
  };

  const alertSets: HeaderBoxType = {
    id: "alert-settings",
    label: "RPM Alert Settings",
    path: `/${RouteUrls.hs}/${RouteUrls.setting}/${RouteUrls.alertSettings}`,
    type: "text",
  };

  const settings: HeaderType = {
    id: "settings",
    label: "Settings",
    icon: "settings",
    path: "",
    dropArr: [
      ...(permission?.redoxSettings ? [smartmeterSettings] : []),
      ...(permission?.redoxSettings ? [redoxSettings] : []),
      ...(permission?.applicationSettings ? [appSettings] : []),
      ...(permission?.alertThresholds ? [globalAlert] : []),
      ...(permission?.alertSettings ? [alertSets] : []),
    ],
  };

  return [
    ...(permission?.home ? [home] : []),
    ...(permission?.mystaff ? [myStaff] : []),
    ...(master.dropArr.length > 0 ? [master] : []),
    ...(permission?.organizations ? [org] : []),
    ...(settings.dropArr.length > 0 ? [settings] : []),
  ];
};

export const ProfileArr: (
  myInfo: MyInfoType,
  permission: any
) => HeaderBoxType[] = (myInfo, permission) => {
  const profile: HeaderBoxType = {
    id: "profile",
    label: "My Profile",
    type: "text",
    path: `${RouteUrls.hs}/${RouteUrls.myProfile}`,
  };

  const changePass: HeaderBoxType = {
    id: "change-password",
    label: "Change Password",
    type: "text",
    path: `${RouteUrls.hs}/${RouteUrls.changePass}`,
  };

  const auditTrail: HeaderBoxType = {
    id: "audit-trail",
    label: "Audit Trail",
    type: "text",
    path: `${RouteUrls.hs}/${RouteUrls.userAudit}`,
  };

  const myOrg: HeaderBoxType = {
    id: "organization-details",
    label: "My Organization",
    type: "text",
    path: `${RouteUrls.hs}/${RouteUrls.myOrganization}`,
  };

  const aboutUs: HeaderBoxType = {
    id: "about-us",
    label: "About",
    type: "text",
    path: `${RouteUrls.hs}/${RouteUrls.aboutUs}`,
  };

  const logout: HeaderBoxType = {
    id: "logout",
    label: "LOGOUT",
    type: "button",
    path: "/login",
  };

  const arr = [
    ...(permission?.myprofile ? [profile] : []),
    ...(permission?.changepassword ? [changePass] : []),
    ...(permission?.myaudittrail ? [auditTrail] : []),
    ...(permission?.myorganization ? [myOrg] : []),
    aboutUs,
    logout,
  ];
  return arr;
};
