import { Box } from "@mui/material";
import { CommonStyle, SubNavBar } from "Components";
import { SubNavDataType } from "DataTypes/Layout";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { billingTraining, completedtraining, mytraining } from "./Data";
import { RouteUrls } from "Constant/RouteUrls";
import { MyInfoType, UserInitialStateType } from "DataTypes/Redux";
import { label } from "Constant/En";
import CommonFormatter from "Utils/Common.Formatter";

const Trainings: FC = () => {
  const location = useLocation();
  const pathArr = location.pathname.split("/");
  const currentPath = pathArr[pathArr.length - 1];
  const { myPermission, myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const [arr, setArr] = useState<SubNavDataType[]>([]);

  useEffect(() => {
    setArr(TrainingArr(myPermission?.dashlinks, myInfo));
  }, []);

  useEffect(() => {
    if (arr.length) {
      const obj = arr.find((el) => el.link == currentPath);
      CommonFormatter.HandleNavArr({
        id: "training-list",
        label: obj?.label || "",
        path: `/${RouteUrls.hs}/${RouteUrls.training}/${obj?.link}`,
        level: 1,
        link: obj?.link || "",
        navType: "patient",
      });
    }
  }, [currentPath, arr]);

  return (
    <Box height="91%">
      <SubNavBar data={TrainingArr(myPermission?.dashlinks, myInfo)} />
      <Box
        height="92%"
        sx={{ ...CommonStyle.sxWhiteBoxWithNavbar, px: 2, overflow: "auto" }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default Trainings;

export const TrainingArr = (trainingPermission: any, myInfo: MyInfoType) => {
  const Arr: SubNavDataType[] = [
    ...(trainingPermission?.mytraining
      ? [
        {
          ...mytraining,
          label:
              myInfo.roleId == 1 || myInfo.roleId == 2
                ? label.pendingTraining
                : label.myTraining,
        },
      ]
      : []),
    ...(trainingPermission?.completedtraining ? [completedtraining] : []),
    ...(trainingPermission?.billing && myInfo.roleId === 6
      ? [billingTraining]
      : []),
  ];
  return Arr;
};
