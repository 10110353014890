import Profile from "./Profile";
import Find from "./Find";
import CareManagement from "./CM";
import Problems from "./Problems";
import TaskVisit from "./TaskVisit";
import Meds from "./Meds";
import Allergy from "./Allergy";
import Vaccine from "./Vaccine";
import Vital from "./Vital";
import Result from "./Result";
import Quality from "./Quality";

export default {
  Profile,
  Find,
  CareManagement,
  Problems,
  TaskVisit,
  Meds,
  Allergy,
  Vaccine,
  Vital,
  Result,
  Quality,
};
