import ControlledTable from "Components/ControlledTable";
import { RedoxContactType } from "DataTypes/Services/Patient.type";
import { TableLayout } from "Layouts";
import { Fragment } from "react";
import { EmergencyTabelCol } from "./Data";

const EmergencyContacts = (EmergencyContactsData: RedoxContactType[]) => {
  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => EmergencyTabelCol}
      tableRow={EmergencyContactsData}
    />
  );
  return (
    <Fragment>
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(EmergencyTabelCol)}
        height={""}
      />
    </Fragment>
  );
};
export default EmergencyContacts;
