import { DropdownArrPropsType } from "DataTypes/Form";
import {
  CLEAR_LIST,
  STORE_MODEL_DROP_LIST,
  STORE_PROGRAM_DROP_LIST,
  STORE_PROGRAM_LIST,
  STORE_ROLE_DROP_LIST,
  STORE_NOTIFICATION_LIST,
  STORE_GENDER_LIST,
  STORE_QUALITY_LIST,
  STORE_CALLRESULT_LIST,
  STORE_FREQUENCY_LIST,
  STORE_ORG_DROP_LIST,
  STORE_TASK_TYPE_LIST,
  STORE_REMINDER_DROP_LIST,
  STORE_RPM_FREQUENCY_LIST,
  STORE_TASK_STATUS_LIST,
  STORE_SMARTMETER_USER_LIST,
  STORE_GROUP_LIST,
  IS_LOGGEDIN_FIRST_TIME,
  STORE_WEIGHTSCALE_PROBLEM_LIST,
  STORE_TIMELOG_TYPE_LIST,
  STORE_RPM_TIMELOG_TYPE_LIST,
  STORE_TRAINING_REQ_TYPE_LIST,
  STORE_DEVICE_PROVIDER_LIST,
  STORE_DEVICE_UOM_LIST,
} from "./type";
import { PrgmListResType } from "DataTypes/Services/Prgm.type";
import { NotificationType } from "DataTypes/Notification";
import { DeviceUOMType } from "DataTypes/Services/Device.type";

export const addModelDropList = (modelList: DropdownArrPropsType[]) => {
  return {
    type: STORE_MODEL_DROP_LIST,
    payload: modelList,
  };
};

export const addNotificationList = (list: NotificationType[]) => {
  return {
    type: STORE_NOTIFICATION_LIST,
    payload: list,
  };
};

export const addPrgDropList = (prgList: DropdownArrPropsType[]) => {
  return {
    type: STORE_PROGRAM_DROP_LIST,
    payload: prgList,
  };
};

export const addProgramList = (prgList: PrgmListResType[]) => {
  return {
    type: STORE_PROGRAM_LIST,
    payload: prgList,
  };
};

export const addRoleDropList = (roleList: DropdownArrPropsType[]) => {
  return {
    type: STORE_ROLE_DROP_LIST,
    payload: roleList,
  };
};

export const addGenderDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_GENDER_LIST,
    payload: list,
  };
};

export const addQualityDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_QUALITY_LIST,
    payload: list,
  };
};

export const addCallDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_CALLRESULT_LIST,
    payload: list,
  };
};

export const addFreqDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_FREQUENCY_LIST,
    payload: list,
  };
};

export const addOrgDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_ORG_DROP_LIST,
    payload: list,
  };
};

export const addTaskTypeDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_TASK_TYPE_LIST,
    payload: list,
  };
};

export const addReminderDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_REMINDER_DROP_LIST,
    payload: list,
  };
};

export const addRPMFreqDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_RPM_FREQUENCY_LIST,
    payload: list,
  };
};

export const addTaskStatusList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_TASK_STATUS_LIST,
    payload: list,
  };
};

export const addSmartmeterUserList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_SMARTMETER_USER_LIST,
    payload: list,
  };
};

export const addGroupList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_GROUP_LIST,
    payload: list,
  };
};

export const isLoggedInFirstTimeRender = (data: boolean) => {
  return {
    type: IS_LOGGEDIN_FIRST_TIME,
    payload: data,
  };
};

export const addWeightScaleProblemDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_WEIGHTSCALE_PROBLEM_LIST,
    payload: list,
  };
};

export const addTimeLogTypeDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_TIMELOG_TYPE_LIST,
    payload: list,
  };
};

export const addRPMTimeLogTypeDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_RPM_TIMELOG_TYPE_LIST,
    payload: list,
  };
};

export const addTrainingReqTypeDropList = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_TRAINING_REQ_TYPE_LIST,
    payload: list,
  };
};

export const AddDeviceProvider = (list: DropdownArrPropsType[]) => {
  return {
    type: STORE_DEVICE_PROVIDER_LIST,
    payload: list,
  };
};

export const AddDeviceUOMList = (list: DeviceUOMType[]) => {
  return {
    type: STORE_DEVICE_UOM_LIST,
    payload: list,
  };
};

export const clearListData = () => {
  return {
    type: CLEAR_LIST,
  };
};
