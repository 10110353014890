import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userReducer from "./User/reducer";
import listReducer from "./List/reducer";
import screenReducer from "./Screen/reducer";
import patientReducer from "./Patient/reducer";
import headerReducer from "./Header/reducer";
import { persistStore, persistReducer } from "redux-persist";
// defaults to localStorage for web
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  list: listReducer,
  screen: screenReducer,
  patient: patientReducer,
  header: headerReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({ reducer: persistedReducer });
const persistor = persistStore(store);
// store.subscribe(() => console.log("update State", store.getState()));

export { store, persistor };

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;
