import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { Filter2DataProps } from "DataTypes/Common";
import FilterData from "Components/Filter2/Data";
import { TrainingListType } from "DataTypes/Services/Training.type";

export const actionArr: (
  handleChart: () => void,
  handleCallDetails: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void,
  rowData: TrainingListType,
  roleId: number | null
) => actionArrType[] = (
  handleChart,
  handleCallDetails,
  handlePatientDetails,
  handleTask,
  rowData,
  roleId
) => {
  return [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ...(rowData?.actualDeviceId && roleId !== 1 && roleId !== 2
      ? [ControlledTable.ActionData.callAction(handleCallDetails)]
      : []),
    ControlledTable.ActionData.taskAction(handleTask),
  ];
};

export const TableCol: (roleId: number | null) => columnType[] = (roleId) => [
  ControlledTable.ColumnData.PicCol("8%"),
  ControlledTable.ColumnData.PatientInfoCol("13%"),
  ControlledTable.ColumnData.PracticeCol("14%"),
  ControlledTable.ColumnData.TeamProviderCol("10%"),
  ControlledTable.ColumnData.DeviceNameCol("13%"),
  ControlledTable.ColumnData.TrainingTypeDescCol("12%"),
  roleId == 1 || roleId == 2
    ? ControlledTable.ColumnData.TrainerNameCol("10%")
    : ControlledTable.ColumnData.TrainingActivityCol("10%"),
  ControlledTable.ColumnData.ActionCol("14%"),
];

export const FilterArr: () => Filter2DataProps[] = () => {
  const Arr: Filter2DataProps[] = [
    FilterData.patientName,
    FilterData.dob,
    FilterData.homeNo,
    FilterData.mobile,
    FilterData.orgName,
    FilterData.orgLocation,
    FilterData.providerName,
    FilterData.deviceName,
    FilterData.tTypeDescription,
  ];
  return Arr;
};
