import { Grid, Typography, Box } from "@mui/material";
import {
  CommonStyle,
  ControlledForm,
  ControlledTable,
  Loader,
  Modal,
} from "Components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, FC } from "react";
import CstmBtn from "Components/CstmBtn";
import ProblemApi from "Service/Problem.api";
import { FetchListProps } from "DataTypes/Common";
import NoData from "Components/NoData";
import { RouteUrls } from "Constant/RouteUrls";
import { useUploadForm } from "Hooks/useUploadFile";
import { ModalLayout } from "Layouts";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import ProbFormatter from "Utils/Prob.Formatter";
import { ProblemListType } from "DataTypes/Services/Problem.type";
import { LimitArr2 } from "Constant/DropArr";
import { CheckStringEmpty } from "Utils/common";

const ProblemList: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<ProblemListType[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [limit, setLimit] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [headHeight, setHeadHeight] = useState<number>(0);
  const [pageHeight, setPageHeight] = useState<number>(0);
  const [probSearch, setProbSearch] = useState("");
  const tableHeight = `calc(100% - ${headHeight + pageHeight}px)`;
  const headOffset = document?.getElementById("head")?.offsetHeight;
  const pageOffset = document?.getElementById("page")?.offsetHeight;
  const CancelReq = axios.CancelToken.source();

  const { uploadForm, progress, setProgress, inProgress } = useUploadForm(
    `${process.env.REACT_APP_BASE_URL}/api/problem/upload`
  );

  useEffect(() => {
    handleSetData({ page: 1, limit });
  }, []);

  useEffect(() => {
    setProgress(0);
  }, [selectedFile]);

  useEffect(() => {
    setHeadHeight(headOffset || 0);
  }, [headOffset]);

  useEffect(() => {
    setPageHeight(pageOffset || 0);
  }, [pageOffset]);

  const handleSetData: (props: FetchListProps) => void = async (props) => {
    const { page: currentPage, limit: currentLimit } = props;
    setLoading(true);
    const res = await ProblemApi.list({
      limit: currentLimit,
      offset: (currentPage - 1) * currentLimit,
      data: { problemName: probSearch },
    });
    if (res?.success) {
      setRows(ProbFormatter.List(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res?.criterion?.total);
      setLimit(res?.criterion?.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(100);
    }
    setLoading(false);
  };

  const hanldeImportFile: () => void = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const res = await uploadForm({
        data: formData,
      });
      if (res?.success) {
        res?.alert && dispatch(setAlertData(res.alert));
        handleSetData({ page: 1, limit });
      }
    }
  };

  const handleDownload: () => void = () => {
    const fileUrl = process.env.PUBLIC_URL + "/SampleTemplate.xlsx";
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.setAttribute("download", "SampleTemplate.xlsx");
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Box height={"100%"} sx={{ ...CommonStyle.sxWhiteBox }} overflow={"auto"}>
      <ModalLayout
        modwidth="35%"
        heading={"Upload File"}
        content={
          <Modal.UploadFileModal
            changeFile={(data) => setSelectedFile(data)}
            fileName={selectedFile?.name || ""}
            progress={progress}
            inProgress={inProgress}
          />
        }
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          !inProgress && setSelectedFile(null);
        }}
        buttonArr={[
          {
            id: "1",
            label: inProgress
              ? "Uploading"
              : progress == 100
                ? "Uploaded"
                : "Upload",
            clickHandler: hanldeImportFile,
            disable:
              inProgress || progress == 100 || !selectedFile ? true : false,
          },
          {
            id: "2",
            label: "Close",
            clickHandler: () => {
              setOpenModal(false);
              !inProgress && setSelectedFile(null);
            },
            type: "border",
          },
        ]}
      />
      <Box display="flex" justifyContent="space-between" p={2} id="head">
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {"Problem Master"}
        </Typography>
        <Box
          sx={{ "&>button": { ml: 1 } }}
          display={"flex"}
          alignItems={"flex-end"}
        >
          <ControlledForm.Input
            id="problemSearch"
            placeholder="Problem Search"
            onChange={(val) => setProbSearch(CheckStringEmpty(val))}
            iconId="search"
            iconClickHandler={() => handleSetData({ limit: 100, page: 1 })}
            sxProps={{ width: 300 }}
          />
          <CstmBtn
            label="Upload Problem List"
            onClick={() => setOpenModal(true)}
          />
          <CstmBtn
            loadingText="Downloading.."
            label="Download Sample Template"
            onClick={handleDownload}
          />
        </Box>
      </Box>
      <Box height={tableHeight} px={2} overflow="auto">
        {loading ? (
          <Loader />
        ) : rows?.length > 0 ? (
          <Grid container spacing={1}>
            {rows.map((el) => {
              return (
                <Grid key={el?.id} item lg={6}>
                  <Typography
                    variant="body2"
                    sx={{ ...sxText }}
                    onClick={() =>
                      navigate(`../${RouteUrls.icdCodes}?id=${el?.id}`, {
                        state: {
                          problemName: el?.name,
                          problemId: el?.id,
                        },
                      })
                    }
                  >
                    • {el?.name || "-"}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <NoData />
        )}
      </Box>
      <Box id={"page"} py={1}>
        <ControlledTable.Pagination
          handleClick={(currentPage, currentLimit) =>
            handleSetData({ page: currentPage, limit: currentLimit })
          }
          limit={limit}
          mainPage={page}
          total={totalRecord}
          tableStyle={false}
          limitArr={LimitArr2}
        />
      </Box>
    </Box>
  );
};
export default ProblemList;

const sxText = {
  cursor: "pointer",
  ":hover": {
    color: "#1077bb",
  },
};
