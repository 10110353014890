import { FC } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { ScreenInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

type BtnProps = {
  label: string;
  fontSize?: string;
  width?: string | number;
  py?: number;
  onClick: () => void;
  btnType?: string;
  disable?: boolean;
  sxProps?: any;
  loading?: boolean;
  loadingText?: string;
  title?: string;
  children?: JSX.Element;
  enableFormLoad?: boolean;
};

const CstmBtn: FC<BtnProps> = (props: BtnProps) => {
  const { disable, loading, enableFormLoad } = props;
  const { formLoad }: ScreenInitialStateType = useSelector(
    (state: any) => state?.screen
  );
  const newDisable = disable || loading || (enableFormLoad ? formLoad : false);
  const newLoading = loading || (enableFormLoad ? formLoad : false);

  return (
    <Button
      disabled={newDisable}
      sx={{
        ...sxStyle,
        ...(props.btnType == "border" && sxSecondaryBtn),
        py: props.py ? props.py : 1,
        ...(props.width && { width: props.width }),
        ...props?.sxProps,
        ...(newDisable && sxDisableBtn),
      }}
      onClick={props.onClick}
      {...(props?.title && { title: props?.title })}
    >
      {newLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
      <Typography
        sx={{ fontSize: props.fontSize ? props.fontSize : "0.75rem" }}
      >
        {newLoading ? props?.loadingText || props.label : props.label}
      </Typography>
      {props.children && props.children}
    </Button>
  );
};
export default CstmBtn;

const sxStyle = {
  height: "fit-content",
  backgroundColor: "secondary.main",
  "& >p": {
    color: "common.white",
  },
  "&:hover": {
    backgroundColor: "primary.main",
  },
};

const sxSecondaryBtn = {
  backgroundColor: "common.white",
  border: "1px solid",
  borderColor: "primary.main",
  "& >p": {
    color: "primary.main",
  },
  "&:hover": {
    backgroundColor: "common.white",
  },
};

export const sxDisableBtn = {
  backgroundColor: "custom.main",
  cursor: "default",
  "& >p": {
    color: "primary.main",
  },
  "&:hover": {
    backgroundColor: "custom.main",
  },
};
