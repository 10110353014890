import { FormObjType } from "DataTypes/Form";
import { CheckStringEmpty, CheckStringNumber } from "Utils/common";

const NameRegex = /^[a-zA-Z ]*$/;
const CategoryNameRegex = /^[-a-zA-Z0-9()"',&/\\ ]{1,100}$/;
const orgName = /^[0-9A-Za-z\s'/&-.,#]+$/;
const AlphaNumericRegex = /^[a-zA-Z0-9 ]*$/;
const PeriodRegex = /^[-a-zA-Z0-9\s.,]*$/;
const PeriodRegex1 = /^[-a-zA-Z0-9\s.,()]*$/;
const AlphaPeriodRegex = /^[a-zA-Z\s.,]*$/;
const EmailRegex = /^[a-zA-Z0-9'._-]+@[a-zA-Z0-9'._-]+\.[a-zA-Z]{2,6}$/;
// Because of sql injection, remove % * from password validation regex
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,14}$/;
const PassRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!?&])[A-Za-z\d@$!?&]{8,14}$/;
const usernameRegex = /^[a-zA-Z0-9._@]{4,20}$/;
const addressRegex = /^[0-9A-Za-z\s.,#/-]+$/;
const mobileNoRegex = /^[0-9]{10}$/;
const phoneNoRegex = /^([2-9]{1}[0-9]{2})-[0-9]{3}-[0-9]{4}$/;
const zipcodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
const numericDeviceRegex = /^\d{15,20}$/;
const alphaNumericDashRegex = /^[0-9-]+$/;

const InputValidation: (formObj: FormObjType) => {
  errorText: string;
  error: boolean;
} = (formObj) => {
  let errorText = "";
  let error = false;
  const newValue = CheckStringEmpty(formObj.inputVal);
  if (formObj.required || newValue.length > 0) {
    switch (formObj.validatetype) {
      case "name": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 100) {
          errorText = "Character length must be less than 100";
          error = true;
        } else if (NameRegex.test(newValue) === false) {
          errorText = "White Spaces & Special Characters are not allowed";
          error = true;
        } else if (formObj.notUnique) {
          error = true;
          errorText = "Name already exists";
        }
        break;
      }
      case "category": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 100) {
          error = true;
          errorText = "Name should be less then 100 characters";
        } else if (CategoryNameRegex.test(newValue) == false) {
          error = true;
          errorText =
            "Only alphabets, numbers, spaces, slashes, Apostrophes, ampersands, hypens, commas are allowed.";
        }
        break;
      }
      case "description": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        }
        break;
      }
      case "org-name": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length < 6 || newValue.length > 100) {
          errorText =
            "Character length must be greater than 5 and less than 100";
          error = true;
        } else if (orgName.test(newValue) === false) {
          errorText =
            "Name can only includes letters, numbers and the following character: . , ' / & -";
          error = true;
        } else if (formObj.notUnique) {
          error = true;
          errorText = "Name already exists";
        }
        break;
      }
      case "duration": {
        if (newValue.length === 0) {
          errorText = "Duration cannot be empty";
          error = true;
        } else if (parseInt(newValue) < 0) {
          errorText = "Invalid end date and time selected";
          error = true;
        }
        break;
      }
      case "address": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 500) {
          errorText = "Character length must be less than 40";
          error = true;
        } else if (addressRegex.test(newValue) === false) {
          errorText =
            "Only alphabets, numbers, spaces, periods, commas, # and hyphens are allowed.";
          error = true;
        }
        break;
      }
      case "alphanumeric": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 40) {
          errorText = "Character length must be less than 40";
          error = true;
        } else if (AlphaNumericRegex.test(newValue) === false) {
          errorText = "Only alphabets and numbers are allowed.";
          error = true;
        }
        break;
      }
      case "alphanumericName": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 100) {
          errorText = "Character length must be less than 100";
          error = true;
        } else if (AlphaNumericRegex.test(newValue) === false) {
          errorText = "Only alphabets and numbers are allowed.";
          error = true;
        }
        break;
      }
      case "alphanumericPeriod": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 100) {
          errorText = "Character length must be less than 100";
          error = true;
        } else if (PeriodRegex.test(newValue) === false) {
          errorText =
            "Only alphabets, numbers, periods(.), hypens(-) and commas(,) are allowed.";
          error = true;
        }
        break;
      }
      case "authRepresent": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 100) {
          errorText = "Character length must be less than 100";
          error = true;
        } else if (PeriodRegex1.test(newValue) === false) {
          errorText =
            "Only alphabets, numbers, periods(.), hypens(-), parenthesis(), and commas(,) are allowed.";
          error = true;
        }
        break;
      }
      case "alphaPeriod": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 100) {
          errorText = "Character length must be less than 100";
          error = true;
        } else if (AlphaPeriodRegex.test(newValue) === false) {
          errorText =
            "Only alphabets, whitespaces, periods(.) and commas(,) are allowed.";
          error = true;
        }
        break;
      }
      case "userid": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 20 || newValue.length <= 4) {
          errorText = "Length must be greater than 4 & less than 20";
          error = true;
        } else if (usernameRegex.test(newValue) === false) {
          errorText =
            "Numeric, alphabet and (._@) characters are allowed only.";
          error = true;
        } else if (formObj.notUnique) {
          error = true;
          errorText = "User Name already exists";
        }
        break;
      }
      case "password": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 14 || newValue.length < 8) {
          errorText = "Length must be between 8 to 14 characters.";
          error = true;
        } else if (PassRegex.test(newValue) === false) {
          errorText =
            "The new password doesn't meet the password policy requirements: 8-14 characters, at least 1 uppercase, 1 lowercase, 1 numeric, and 1 special character i.e @ $ ! ? &";
          error = true;
        }
        break;
      }
      case "login-password": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 14 || newValue.length < 8) {
          errorText = "Length must be between 8 to 14 characters.";
          error = true;
        }
        break;
      }
      case "email": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (EmailRegex.test(newValue) === false) {
          errorText = "Please enter a valid email address";
          error = true;
        } else if (formObj.notUnique) {
          error = true;
          errorText = "Email already exists";
        }
        break;
      }
      case "mobile no.": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (phoneNoRegex.test(newValue) === false) {
          errorText = "Enter a valid number. Ex: XXX-XXX-XXXX";
          error = true;
        }
        break;
      }
      case "npi no.": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (mobileNoRegex.test(newValue) === false) {
          errorText = "Enter a 10-digit valid npi number";
          error = true;
        }
        break;
      }
      case "zipcode": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (zipcodeRegex.test(newValue) === false) {
          errorText = "Enter a valid zipcode. Ex: 99999 or 99999-9999";
          error = true;
        }
        break;
      }
      case "fax-number": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        }
        break;
      }
      case "otp": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (!CheckStringNumber(newValue)) {
          errorText = "Please enter a valid otp";
          error = true;
        }
        break;
      }
      case "number": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (!CheckStringNumber(newValue)) {
          errorText = "Please enter a valid number";
          error = true;
        }
        break;
      }
      case "question": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length < 3 || newValue.length > 500) {
          errorText = "Text length must be greater than 2 and less than 500";
          error = true;
        }
        break;
      }
      case "option": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 500) {
          errorText = "Text length must be less than 500";
          error = true;
        } else if (formObj.notUnique) {
          error = true;
          errorText = "Name already exists";
        }
        break;
      }
      case "notes": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        }
        break;
      }
      case "callnotes": {
        if (newValue.length === 0) {
          errorText = "Text Field cannot be empty";
          error = true;
        } else if (newValue.length > 100) {
          errorText = "Text length must be less than 100";
          error = true;
        }
        break;
      }
      case "deviceId": {
        if (newValue.length === 0) {
          errorText = "Device ID cannot be empty";
          error = true;
        } else if (newValue.length > 25) {
          errorText = "Character length must be less than or equal to 25";
          error = true;
        }
        // else if (!alphaNumericDashRegex.test(newValue)) {
        //   errorText = "Device ID must be alphanumeric with '-'";
        //   error = true;
        // } 
        else if (!numericDeviceRegex.test(newValue.replace(/-/g, ""))) {
          errorText =
            "Device ID should have numeric digits with length between 15 to 20";
          error = true;
        }
        break;
      }
    }
  }
  return { errorText, error };
};

export default InputValidation;
