import TblHead from "./TblHead";
import TblBody from "./TblBody";
import ColumnData from "./ColumnData";
import ActionData from "./ActionData";
import Pagination from "./Pagination";
export default {
  TblHead,
  TblBody,
  ColumnData,
  ActionData,
  Pagination,
};
