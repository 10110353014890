import ImgPath from "Constant/Imgs";
import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { label } from "Constant/En";
import { RouteUrls } from "Constant/RouteUrls";
import { NavArrType } from "DataTypes/Common";
import { objectValues } from "DataTypes/Services/Index.type";
import { MyInfoType } from "DataTypes/Redux";

export const NavArr = (
  permission: any,
  tilesCount: objectValues | undefined,
  myInfo: MyInfoType
) => {
  const Arr: NavArrType[] = [
    ...(permission?.eligible
      ? [
        {
          id: 1,
          label: label.el,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.eligible}`,
          type: "text",
          data: tilesCount?.eligible ?? "",
          sublabel: label.subel,
          Icon: <ImgPath.EligibleIcon />,
        },
      ]
      : []),
    ...(permission?.pendingassign
      ? [
        {
          id: 2,
          label: label.pendingAssignment,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.pendingAssign}`,
          type: "text",
          data: tilesCount?.pendingAssignment ?? "",
          sublabel: label.subpend,
          Icon: <ImgPath.PccIcon />,
        },
      ]
      : []),
    ...(permission?.enrolled
      ? [
        {
          id: 3,
          label: label.enrolled,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.enrolled}`,
          type: "text",
          data: tilesCount?.enrolledPatient ?? "",
          sublabel: label.submypt,
          lablesuper: label?.submyptSuper,
          Icon: <ImgPath.myptIcon />,
        },
      ]
      : []),
    ...(permission?.mypatient
      ? [
        {
          id: 4,
          label: label.mp,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.myPatients}`,
          type: "text",
          data: tilesCount?.myPatient ?? "",
          sublabel: label.submypt,
          lablesuper: label?.submyptSuper,
          Icon: <ImgPath.myptIcon />,
        },
      ]
      : []),
    ...(permission?.pendingapproval
      ? [
        {
          id: 5,
          label: label.pendpr,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.pendingApproval}`,
          type: "text",
          data: tilesCount?.pendingPlanApproval ?? "",
          sublabel: label.subpendpr,
          Icon: <ImgPath.ppaIcon />,
        },
      ]
      : []),
    ...(permission?.pending_care
      ? [
        {
          id: 6,
          label: label.continuousCare,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.pendingCare}`,
          type: "text",
          data: tilesCount?.pendingContinuousCare ?? "",
          sublabel: label.subpendcc,
          Icon: <ImgPath.pcmcIcon />,
        },
      ]
      : []),
    ...(permission?.completedcare
      ? [
        {
          id: 7,
          label: label.comcc,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.completedCare}`,
          type: "text",
          data: tilesCount?.completedContinuousCare ?? "",
          sublabel: label.subcomcc,
          Icon: <ImgPath.ccmcIcon />,
        },
      ]
      : []),
    ...(permission?.inactive
      ? [
        {
          id: 8,
          label: label.inactive,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.inactive}`,
          type: "text",
          data: tilesCount?.patientInactive ?? "",
          sublabel: label.subcomcc,
          Icon: <ImgPath.clIcon />,
        },
      ]
      : []),
    ...(permission?.ongoing
      ? [
        {
          id: 9,
          label: label.ongpt,
          link: `${RouteUrls.hs}/${RouteUrls.list}/${RouteUrls.ongoing}`,
          type: "text",
          data: tilesCount?.onGoing ?? "",
          sublabel: label.subongpt,
          Icon: <ImgPath.ongIcon />,
        },
      ]
      : []),
    ...(permission?.mytraining
      ? [
        {
          id: 12,
          label:
              myInfo.roleId == 1 || myInfo.roleId == 2
                ? label.pendingTraining
                : label.myTraining,
          link: `${RouteUrls.hs}/${RouteUrls.training}/${RouteUrls.myTraining}`,
          type: "text",
          data: tilesCount?.myTraining ?? "",
          sublabel: "",
          Icon: <ImgPath.cptIcon />,
        },
      ]
      : []),
    ...(permission?.completedtraining
      ? [
        {
          id: 13,
          label: label.cpt,
          link: `${RouteUrls.hs}/${RouteUrls.training}/${RouteUrls.completedTraining}`,
          type: "text",
          data: tilesCount?.completedTraining ?? "",
          sublabel: "",
          Icon: <ImgPath.cptIcon />,
        },
      ]
      : []),
    ...(permission?.billing
      ? [
        {
          id: 10,
          label: label.billing,
          link: `${RouteUrls.hs}/${myInfo.roleId === 6 ? RouteUrls.training : RouteUrls.list}/${RouteUrls.billing}`,
          type: "img",
          imgUrl: ImgPath.Billing,
          data: tilesCount?.billing ?? "",
          sublabel: "",
          Icon: <ImgPath.billingIcon />,
        },
      ]
      : []),
    ...(permission?.tasking
      ? [
        {
          id: 11,
          label: label.myTask,
          link: `${RouteUrls.hs}/${RouteUrls.tasking}/${RouteUrls.incoming}`,
          type: "text",
          data: tilesCount?.tasking ?? "",
          sublabel: "",
          Icon: <ImgPath.mtIcon />,
        },
      ]
      : []),
    // ...[
    //   {
    //     id: 15,
    //     label: label.revision,
    //     link: `${RouteUrls.hs}/${RouteUrls.revision}/${RouteUrls.list}`,
    //     type: "text",
    //     data: tilesCount?.revision ?? "",
    //     sublabel: "",
    //     Icon: <ImgPath.EligibleIcon />,
    //   },
    // ],
    ...(permission?.pendingdevice
      ? [
        {
          id: 14,
          label: label.pendingDevice,
          link: `${RouteUrls.hs}/${RouteUrls.pendingDevice}`,
          type: "text",
          data: tilesCount?.pendingDevice ?? "",
          sublabel: "",
          Icon: <ImgPath.pendingDevice />,
        },
      ]
      : []),
  ];
  return Arr;
};

export const TableCol: (role: string) => columnType[] = (role: string) => {
  return [
    ControlledTable.ColumnData.PicCol("8%"),
    ControlledTable.ColumnData.PatientInfoCol("13%"),
    ...(role == "Super Admin"
      ? [ControlledTable.ColumnData.ClinicalStaffCol("10%")]
      : []),
    ControlledTable.ColumnData.SepDateTimeCol("11%"),
    ControlledTable.ColumnData.ProviderCol("10%"),
    ...(role != "TRAINER"
      ? [ControlledTable.ColumnData.PracticeCol("13%")]
      : []),
    ControlledTable.ColumnData.ActivityCol("10%"),
    ...(role != "TRAINER"
      ? [ControlledTable.ColumnData.ImportanceCol("10%")]
      : []),
    ControlledTable.ColumnData.ActionCol("15%"),
  ];
};

export const actionArr: (
  handleChart: () => void,
  handleCallDetails: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void
) => actionArrType[] = (
  handleChart,
  handleCallDetails,
  handlePatientDetails,
  handleTask
) => {
  return [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ControlledTable.ActionData.callAction(handleCallDetails),
    ControlledTable.ActionData.taskAction(handleTask),
  ];
};

export const CCMCatCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.EligibleCol("30%"),
    ControlledTable.ColumnData.EnrolledCol("30%"),
    ControlledTable.ColumnData.NoneCol("40%"),
  ];
};

export const CCMTimeCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.Min014Col("20%"),
    ControlledTable.ColumnData.Min1519Col("20%"),
    ControlledTable.ColumnData.Min2039Col("20%"),
    ControlledTable.ColumnData.Min4059Col("20%"),
    ControlledTable.ColumnData.Min60Col("20%"),
  ];
};

export const PCMCatCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.EligibleCol("30%"),
    ControlledTable.ColumnData.EnrolledCol("30%"),
    ControlledTable.ColumnData.NoneCol("40%"),
  ];
};

export const PCMTimeCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.Min014Col("20%"),
    ControlledTable.ColumnData.Min1519Col("20%"),
    ControlledTable.ColumnData.Min3059Col("20%"),
    ControlledTable.ColumnData.Min60Col("20%"),
    ControlledTable.ColumnData.NoneCol("20%"),
  ];
};

export const BHICatCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.EligibleCol("30%"),
    ControlledTable.ColumnData.EnrolledCol("30%"),
    ControlledTable.ColumnData.NoneCol("40%"),
  ];
};

export const BHITimeCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.Min014Col("20%"),
    ControlledTable.ColumnData.Min1519Col("20%"),
    ControlledTable.ColumnData.Min20Col("20%"),
    ControlledTable.ColumnData.NoneCol("20%"),
    {
      id: "1",
      label: "",
      width: "20%",
      align: "left",
      colType: "text",
      rowType: "none",
    },
  ];
};

export const RPMCatCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.EligibleCol("30%"),
    ControlledTable.ColumnData.EnrolledCol("30%"),
    ControlledTable.ColumnData.PtDeviceCol("40%"),
  ];
};

export const RPMTimeCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.Min019Col("20%"),
    ControlledTable.ColumnData.Min2039Col("20%"),
    ControlledTable.ColumnData.Min4059Col("20%"),
    ControlledTable.ColumnData.Min60Col("20"),
    ControlledTable.ColumnData.NoneCol("20%"),
  ];
};

export const TCMTableCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.TcmOpportunityCol("25%"),
    ControlledTable.ColumnData.OngoingCol("25%"),
    ControlledTable.ColumnData.CompletedCol("25%"),
    ControlledTable.ColumnData.BilledCol("25%"),
  ];
};
