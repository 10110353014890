import { ApiServiceProps, objectValues } from "DataTypes/Services/Index.type";
import { post } from ".";

const deviceTypelist: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "device-type/search",
    data: {
      data: { ...(payload.data ? payload.data : {}) },
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const updateMappedDevice: (payload: objectValues) => any = (payload) => {
  const request = {
    subUrl: "device-type/problem/update",
    data: { data: payload },
  };
  return post(request);
};

const mappedDeviceSearch: (payload: objectValues) => any = (payload) => {
  const request = {
    subUrl: "device-type/problem/search",
    data: { data: payload },
  };
  return post(request);
};

const deviceList: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "device/search",
    data: {
      data: { ...(payload.data ? payload.data : {}) },
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const fetchSmartmeterUserList: () => any = () => {
  const request = {
    subUrl: "user/smartmeter-access/search",
    isAuth: true,
  };
  return post(request);
};

const fetchDeviceTaskStatus: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "statusItem/search",
    data: {
      data: { ...(payload.data ? payload.data : {}) },
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const fetchDeviceReadingCycle: (payload: ApiServiceProps) => any = (
  payload
) => {
  const request = {
    subUrl: `patient-device-reading/${payload.id1}/dropdown-search`,
    data: { data: payload.data },
  };
  return post(request);
};

const deviceReadings: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient-device-reading/${payload.id1}/search`,
    data: { data: payload.data },
  };
  return post(request);
};

const deviceDetailsReading: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient-device-reading/search",
    data: { data: payload.data },
  };
  return post(request);
};

const DeviceFirstReading: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient-device-reading/first-reading/${payload.id1}/search`,
    data: { data: payload.data },
  };
  return post(request);
};

const UOMSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "uom/default-units/search",
    data: { data: payload.data },
  };
  return post(request);
};

export default {
  deviceTypelist,
  updateMappedDevice,
  mappedDeviceSearch,
  deviceList,
  fetchSmartmeterUserList,
  fetchDeviceTaskStatus,
  fetchDeviceReadingCycle,
  deviceReadings,
  DeviceFirstReading,
  deviceDetailsReading,
  UOMSearch,
};
