import { FC, useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { actionArr, FilterArr, TableCol } from "./Data";
import { TableLayout } from "Layouts";
import { ControlledTable, Filter2 } from "Components";
import { RouteUrls } from "Constant/RouteUrls";
import { useSelector } from "react-redux";
import { Filter2DataProps } from "DataTypes/Common";
import PatientApi from "Service/Patient.api";
import Formate from "./Formate";
import { OngoingType } from "DataTypes/Services/Patient.type";
import { CreateFilter2Payload } from "Utils/common";
import { useDispatch } from "react-redux";

const Ongoing: FC = () => {
  const navigate = useNavigate();
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.enrolledpatient
  );
  const [limit, setLimit] = useState<number>(10);
  const [loading, setLoading] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<OngoingType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    handleSetData(1, limit);
  }, [filterData]);

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
    };
    const res = await PatientApi.Ongoing({
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
      data: payload,
    });
    if (res?.success) {
      setRows(Formate.OngoingList(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res.criterion.total);
      setLimit(res.criterion.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: OngoingType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`
    );
  };

  const handleChart: (data: OngoingType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleTask: (data: OngoingType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${RouteUrls.add}`
    );
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={rows}
      tableAction={(data: OngoingType) =>
        actionArr(
          () => handleChart(data),
          () => handlePatientDetails(data),
          () => handleTask(data)
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Fragment>
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      {permission?.search && (
        <TableLayout
          tblBody={TblBodyJSX}
          tblHead={ControlledTable.TblHead(TableCol)}
          height={tableHeight}
          loading={loading}
          Pagination={
            <ControlledTable.Pagination
              mainPage={page}
              limit={limit}
              total={totalRecord}
              handleClick={handleSetData}
              tableStyle={true}
            />
          }
        />
      )}
    </Fragment>
  );
};
export default Ongoing;
