import { FC } from "react";
import { Tooltip, Typography } from "@mui/material";
import { CstmtipProps } from "DataTypes/Common";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 6px #00000029",
    marginTop: "4px !important",
  },
}));

const Cstmtip: FC<CstmtipProps> = ({ titleUI, textUI }) => {
  return <HtmlTooltip title={titleUI}>{textUI}</HtmlTooltip>;
};
export default Cstmtip;
