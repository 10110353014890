import {
  ReduxLoadType,
  ReduxScreenLeftHeadType,
  ReduxScreenRightHeadType,
  ScreenActionReturnType,
  ScreenInitialStateType,
} from "DataTypes/Redux";
import {
  CLEAR_ALERT,
  CLEAR_SCREEN,
  SET_ALERT,
  SET_LEFT_HEAD,
  SET_NAV_ARR,
  SET_RIGHT_HEAD,
  SET_TIMER_START_DATE,
  SET_TIMER_STATUS,
  SET_TIMER_VALUE,
  START_SCREEN_LOAD,
  STOP_SCREEN_LOAD,
  SET_ONLY_TIMER,
  SET_FORM_LOAD,
} from "./type";
import { AlertProps } from "DataTypes/Common";

const alertInitialState: AlertProps = {
  alertMessage: "",
  openAlert: false,
  alertVariant: "",
};

const screenLoadInitialState: ReduxLoadType = {
  loading: false,
  loadtext: "",
};

const screenLeftHeadInitialState: ReduxScreenLeftHeadType = {
  id: "",
};

const screenRightHeadInitialState: ReduxScreenRightHeadType = {
  id: "",
  timerStatus: 1,
  timerStartDate: null,
  timerVal: { s: 0, m: 0, h: 0 },
  isOnlyTimer: false,
  timerApiCallByParentCom: false,
};

const INITIAL_STATE: ScreenInitialStateType = {
  alert: alertInitialState,
  screenLoad: screenLoadInitialState,
  formLoad: false,
  navArr: [],
  leftHead: screenLeftHeadInitialState,
  rightHead: screenRightHeadInitialState,
};

const reducer = (state = INITIAL_STATE, action: ScreenActionReturnType) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case CLEAR_ALERT:
      return {
        ...state,
        alert: alertInitialState,
      };
    case START_SCREEN_LOAD:
      return {
        ...state,
        screenLoad: action.payload,
      };
    case STOP_SCREEN_LOAD:
      return {
        ...state,
        screenLoad: screenLoadInitialState,
      };
    case SET_NAV_ARR:
      return {
        ...state,
        navArr: [...action.payload],
      };
    case SET_LEFT_HEAD:
      return {
        ...state,
        leftHead: {
          ...state.leftHead,
          id: action.payload,
        },
      };
    case SET_RIGHT_HEAD:
      return {
        ...state,
        rightHead: {
          ...state.rightHead,
          id: action.payload,
        },
      };
    case SET_TIMER_STATUS:
      return {
        ...state,
        rightHead: {
          ...state.rightHead,
          timerStatus: action.payload?.status,
          timerApiCallByParentCom: action.payload?.parentApiCall,
        },
      };
    case SET_TIMER_START_DATE:
      return {
        ...state,
        rightHead: {
          ...state.rightHead,
          timerStartDate: action.payload,
        },
      };
    case SET_TIMER_VALUE:
      return {
        ...state,
        rightHead: {
          ...state.rightHead,
          timerVal: action.payload,
        },
      };
    case SET_ONLY_TIMER:
      return {
        ...state,
        rightHead: {
          ...state.rightHead,
          isOnlyTimer: action.payload,
        },
      };
    case SET_FORM_LOAD:
      return {
        ...state,
        formLoad: action.payload,
      };
    case CLEAR_SCREEN:
      return {
        ...state,
        alert: alertInitialState,
        screenLoad: screenLoadInitialState,
        formLoad: false,
        navArr: [],
        leftHead: screenLeftHeadInitialState,
        rightHead: screenRightHeadInitialState,
      };
    default:
      return state;
  }
};

export default reducer;
