import { ControlledForm } from "Components";
import { FormDataType } from "DataTypes/Form";

export const MdmArr = [
  {
    id: 1,
    label: "Low Complex",
    value: "low-complex",
    selected: false,
  },
  {
    id: 2,
    label: "Moderate Complex",
    value: "moderate-complex",
    selected: false,
  },
  {
    id: 3,
    label: "High Complex",
    value: "high-complex",
    selected: false,
  },
];


export const DischargeSummaryBread = [
  {
    id: 1,
    label: "Discharge Summary",
    path: "",
  },
];

export const PersonalDataArr = () => {
  const Arr: FormDataType[] = [
    ControlledForm.FormData.FacilityName(),
    ControlledForm.FormData.InitialContact(),
    ControlledForm.FormData.FormOfContact(),
    ControlledForm.FormData.DateOfContact(),
    ControlledForm.FormData.TcmAppointment(),
    ControlledForm.FormData.DateOfAdmission(),
    ControlledForm.FormData.DateOfDischarge(),
    ControlledForm.FormData.MedicalDecisionMaking(),
    ControlledForm.FormData.PrimaryDiagnosis(),
    ControlledForm.FormData.SecondaryDiagnosis(),
    ControlledForm.FormData.TertiaryDiagnosis(),
    ControlledForm.FormData.DischargeDisposition(),
    ControlledForm.FormData.ReasonForHospitalization(),
    ControlledForm.FormData.TcmNotes(),
  ]
  return Arr;
}
