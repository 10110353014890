import { FC } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
  // shadowPlugin
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["8:00AM", "9:05AM", "10:08AM", "1:37PM", "3:28PM", "05:35PM"];

export const data = {
  labels,
  datasets: [
    {
      label: "SBP",
      borderColor: "#2D335D",
      backgroundColor: "#2D335D",
    },
    {
      label: "DBP",
      borderColor: "#EE7404",
      backgroundColor: "#EE7404",
    },
  ],
};

type CharProps = {
  data: any;
  dateUnit: string;
};

const LineChart: FC<CharProps> = ({ data, dateUnit }) => {
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const option: any = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: dateUnit, // Set unit to minute if all on the same day
          tooltipFormat: myInfo.chartDateTimeSecFormat, // <- HEREdisplayFormats:
        },
        title: {
          display: true,
          text: "Date",
        },
        ticks: {
          source: "auto",
          autoSkip: false,
        },
      },
      y: {
        title: {
          display: true,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",
        },
        zoom: {
          enabled: true,
          mode: "xy",
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.label || "";
            const deviceId = context.raw.actualDeviceId
              ? `Device ID: ${context.raw.actualDeviceId}`
              : "";
            return [`${label}: ${context.raw.y}`, deviceId];
          },
        },
      },
    },
  };
  return <Line data={data} options={option} />;
};
export default LineChart;
