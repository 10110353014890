import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from "Service";

const listApplicationSettings: (payload: ApiServiceProps) => any = ({
  data,
}) => {
  const request = {
    subUrl: "gobalsettings/search",
    data: {
      data: data,
    },
  };
  return post(request);
};

const UpdateAppSettings: (payload: ApiServiceProps) => any = ({
  data,
  id1,
}) => {
  const request = {
    subUrl: `gobalsettings/${id1}/update`,
    data: {
      data: data,
    },
  };
  return post(request);
};

const saveRedoxSettings: (payload: any) => any = ({ data }) => {
  const request = {
    subUrl: "setting/create",
    isRedox: true,
    data: {
      data: data,
    },
  };
  return post(request);
};

const listRedoxSettings: (payload: ApiServiceProps) => any = ({ data }) => {
  const request = {
    subUrl: "setting/search",
    isRedox: true,
    data: {
      data: data,
    },
  };
  return post(request);
};

const deleteRedoxSettings: (payload: ApiServiceProps) => any = ({ id1 }) => {
  const request = {
    subUrl: `setting/${id1}/delete`,
    isRedox: true,
  };
  return post(request);
};

const updateRedoxSettings: (payload: ApiServiceProps) => any = ({
  id1,
  data,
}) => {
  const request = {
    subUrl: `setting/${id1}/update`,
    isRedox: true,
    data: data,
  };
  return post(request);
};

const searchDropSettings: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "dropdownsettins/search",
    data: payload,
  };
  return post(request);
};

const AlertSetUserSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "alert-setting/user/search",
    data: payload,
  };
  return post(request);
};

const AlertSetPatientSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "alert-setting/patient/search",
    data: payload,
  };
  return post(request);
};

const AlertSetUserUpdate: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "alert-setting/user/update",
    data: payload,
  };
  return post(request);
};

const SmartmeterRedoxSettings: () => any = () => {
  const request = {
    subUrl: "smartmeter/setting/search ",
  };
  return post(request);
};

const UpdateSmartmeterSetting: (payload: ApiServiceProps) => any = (
  payload
) => {
  const request = {
    subUrl: `smartmeter/setting/${payload.id1}/update`,
    data: payload?.data,
  };
  return post(request);
};

const RPMAlertSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "alert/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const RPMMarkAsAddressedAlert: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `alert/${payload.id1}/mark-as-addressed`,
  };
  return post(request);
};

const RPMMarkAsReadAlert: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `alert/${payload.id1}/mark-as-read`,
  };
  return post(request);
};

const SystemSettings : (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `systemProperty/${payload.id1}/${payload.data}/search`,
  };
  return post(request);
};

export default {
  listApplicationSettings,
  UpdateAppSettings,
  saveRedoxSettings,
  listRedoxSettings,
  deleteRedoxSettings,
  updateRedoxSettings,
  searchDropSettings,

  AlertSetUserSearch,
  AlertSetPatientSearch,
  AlertSetUserUpdate,

  SmartmeterRedoxSettings,
  UpdateSmartmeterSetting,

  RPMAlertSearch,
  RPMMarkAsAddressedAlert,
  RPMMarkAsReadAlert,

  SystemSettings
};
