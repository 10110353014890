import { PendingAssignType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const PendingAssignList: (data: any) => PendingAssignType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: PendingAssignType[] = CheckedArr.map((el, index) => {
    const user = CheckStringEmpty(el?.consentGainedBy);
    const userDes = CheckStringEmpty(el?.consentGainedByDesignation);
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      careManagerId: CheckNumber(el?.careManagerId),
      careplanId: CheckNumber(el?.careplanId),
      consentCreatedBy: CheckNumber(el?.consentCreatedBy),
      consentgainedby: user ? `${user}${userDes ? ", " + userDes : ""}` : "",
      enrollmentdate: DateTime.ValidDate(el?.enrolmentDate),
      enrolledProgramId: CheckNumber(el?.enrolledProgramId),
      id: index,
      patientId: CheckStringId(el?.id),
      program: CheckStringEmpty(el?.programAbbrevation),
      programId: CheckNumber(el?.programId),
      provider: CheckStringEmpty(el?.providerName),
      providerId: CheckNumber(el?.providerId),
      status: CheckStringEmpty(el?.status),
    };
  });
  return newArr;
};

export default { PendingAssignList };
