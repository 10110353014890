const CPDischarge = "Pending discharge form";
const CPAssignment = "Pending assignment"; //if no team member is assigned.
const CPProblem = "Pending problem assignment";
const CPQues = "Pending questionnaire";
const CPTeam = "Pending team assignment";
const CPShare = "Pending share with provider";
const CPSignOff = "Pending for provider approval";
const Recurring = "Pending for recurring";

///For RPM Program Changes
const CPDeviceAssign = "Pending device assignment";

const CPStage = [
  CPDischarge,
  CPAssignment,
  CPProblem,

  ///For RPM Program Changes
  CPDeviceAssign,
  CPQues,
  CPTeam,
  CPShare,
  CPSignOff,
  Recurring,
];

export default {
  CPDischarge,
  CPAssignment,
  CPProblem,
  
  ///For RPM Program Changes
  CPDeviceAssign,

  CPQues,
  CPTeam,
  CPShare,
  CPSignOff,
  Recurring,
  CPStage,
};
