import {
  AlertSetListType,
  AlertSetType,
} from "DataTypes/Services/Settings.type";
import {
  CheckAlertType,
  CheckArr,
  CheckBoolean,
  CheckNumber,
  CheckStringEmpty,
} from "Utils/common";

const UserAlertSetSearch: (data: any) => AlertSetListType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    return {
      alertSettings: AlertSetTypeList(el?.alertSettings),
      bussinessModel: CheckStringEmpty(el?.bussinessModel),
      hasUserAccessToReceiveSMS: CheckBoolean(el?.hasUserAccessToReceiveSMS),
      roleName: CheckStringEmpty(el?.roleName),
      userFullNAme: CheckStringEmpty(el?.userFullName),
      userId: CheckNumber(el?.userId),
    };
  });
};

const PatientAlertSetSearch: (data: any) => AlertSetType[] = (data) => {
  const CheckedArr = CheckArr(data);
  if (CheckedArr.length > 0) {
    return AlertSetTypeList(CheckedArr[0]?.alertSettings);
  } else return [];
};

const AlertSetTypeList: (data: any) => AlertSetType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      emailEnabled: CheckBoolean(el?.emailEnabled),
      pushEnabled: CheckBoolean(el?.pushEnabled),
      smsEnabled: CheckBoolean(el?.smsEnabled),
      alertType: CheckAlertType(el?.alertType),
    };
  });
};

export default { PatientAlertSetSearch, UserAlertSetSearch, AlertSetTypeList };
