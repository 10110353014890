import { FC, Fragment, useEffect } from "react";
import { Box } from "@mui/material";
import AuditCard from "Components/AuditCard";
import HistoryCard from "Components/HistoryCard";
import InfoCard from "Components/InfoCard";
import ProgramCard from "Components/ProgramCard";
import { RouteUrls } from "Constant/RouteUrls";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLeftHead, setRightHead } from "Redux/Screen/Action";
import CommonFormatter from "Utils/Common.Formatter";

const ClinicalSummary: FC = () => {
  const dispatch = useDispatch();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );

  useEffect(() => {
    dispatch(setLeftHead("patient-clinical-summary"));
    dispatch(setRightHead("patient-details"));
    CommonFormatter.HandleNavArr({
      id: "patient-details",
      label: "Clinical Summary",
      path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.clinicalSummary}`,
      level: 3,
      link: RouteUrls.clinicalSummary,
      navType: "patient",
    });
    return () => {
      dispatch(setLeftHead(""));
      dispatch(setRightHead(""));
    };
  }, []);

  return (
    <Fragment>
      <Box height="100%" width="50%">
        <Box height="45%">
          <InfoCard />
        </Box>
        <Box height="3%" />
        <Box height="52%">
          <HistoryCard />
        </Box>
      </Box>
      <Box height="100%" width="50%">
        <Box height={"45%"}>
          <ProgramCard />
        </Box>
        <Box height={"3%"} />
        <Box height={"52%"}>
          <AuditCard />
        </Box>
      </Box>
    </Fragment>
  );
};

export default ClinicalSummary;
