///bug resolved 450

import { FC, useState, useEffect, Fragment } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import PatientApi from "Service/Patient.api";
import {
  PatientInitialStateType,
  ReduxScreenNavType,
  ScreenInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { ConvertStringToNum } from "Utils/common";
import {
  CPNavArr,
  CPNavComprehensive,
  CPRNavArr,
  DeviceReadingNavArr,
  RPMNavArr,
  RPMRNavArr,
  TCMNavArr,
} from "./Data";
import { setAlertData, setNavArr, setRightHead } from "Redux/Screen/Action";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CareNavType,
  FormatEligiblePrgmType,
} from "DataTypes/PatientManagement.type";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const CarePlan: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { patientInfo, selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { navArr }: ScreenInitialStateType = useSelector(
    (state: any) => state?.screen
  );
  const currentUrl = window.location.href;
  const isRPM = currentUrl.includes(RouteUrls.treatmentPlan);
  const isRecurring = currentUrl.includes(RouteUrls.recurring);
  const isTCM = currentUrl.includes(RouteUrls.tcm);
  const isDeviceReading = currentUrl.includes(RouteUrls.deviceReading);
  const { careplanId } = useParams();
  const newPatientId = patientInfo.id;
  const newCPId = ConvertStringToNum(careplanId);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleHistoryPrgmCall();
    dispatch(setRightHead("care-plan"));
    return () => {
      dispatch(setRightHead(""));
    };
  }, [newCPId]);

  useEffect(() => {
    dispatch(
      setNavArr([...navArr.filter((el) => el.level < 4), ...handleNavArr()])
    );
    return () => {
      dispatch(setNavArr([...navArr.filter((el) => el.level < 4)]));
    };
  }, [
    newCPId,
    selectedPrgm.careplanStage,
    selectedPrgm.isCareplanComprehensive,
  ]);

  const handleNavArr: () => ReduxScreenNavType[] = () => {
    let newArr: CareNavType[] = [];
    let switchpath = "";
    switchpath = CommonFormatter.HandleParentRoute({
      prgm: selectedPrgm.abbrevation,
      careId: selectedPrgm.careplanId,
      isRec: isRecurring,
      recId: selectedPrgm.careplanRecurringId,
    });
    if (isTCM) newArr = TCMNavArr;
    else if (isRPM) {
      if (isDeviceReading) newArr = DeviceReadingNavArr;
      else if (isRecurring) newArr = RPMRNavArr;
      else newArr = RPMNavArr;
    } else {
      if (isRecurring) newArr = CPRNavArr;
      else {
        if (selectedPrgm.isCareplanComprehensive) newArr = CPNavComprehensive;
        else newArr = CPNavArr;
      }
    }
    newArr = newArr.filter((el) => el.stage <= selectedPrgm?.careplanStage);
    return newArr.map((el) => {
      return {
        id: el.id,
        label: el.label,
        path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${switchpath}/${el.path}`,
        level: el.level,
        link: el.link,
        navType: "patient",
      };
    });
  };

  const handleHistoryPrgmCall: () => void = async () => {
    const res: any = await PatientApi.enrollPrgmHistory({
      id1: newPatientId,
    });
    if (res.success) {
      const formatArr = PatientFormatter.CheckEligiblePrgmRes(
        res?.data,
        myInfo?.id
      );
      const find = formatArr.find((el) => el.careplanId == newCPId);
      if (find && selectedPrgm) {
        const newObj: FormatEligiblePrgmType = {
          ...selectedPrgm,
          ...find,
          careplanRecurringId:
            selectedPrgm.careplanRecurringId ||
            find.careplanRecurringId ||
            null,
          careplanRecurringStatus: selectedPrgm?.careplanRecurringStatus,
        };
        dispatch(addSelectedPrgm(newObj));
      } else {
        dispatch(
          setAlertData({
            alertMessage: "This appointment is either closed or invaid",
            alertVariant: "info",
            openAlert: true,
          })
        );
        navigate(-1);
      }
    }
    setLoading(false);
  };

  return <Fragment>{!loading && selectedPrgm && <Outlet />}</Fragment>;
};

export default CarePlan;
