import { FC, useState, useEffect } from "react";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import {
  CommonStyle,
  ControlledForm,
  ControlledTable,
  Loader,
} from "Components";
import { useSelector } from "react-redux";
import { ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import CstmBtn from "Components/CstmBtn";
import NoData from "Components/NoData";
import { PageLimitArr } from "Constant/DropArr";
import { useDispatch } from "react-redux";
import DeviceApi from "Service/Device.api";
import ProgramApi from "Service/Program.api";
import ProbFormatter from "Utils/Prob.Formatter";
import { ProblemListType } from "DataTypes/Services/Problem.type";
import { OptionsProps } from "DataTypes/Form";
import { DeviceTypePrbmBoxProps } from "DataTypes/Services/Device.type";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { PrgmListResType } from "DataTypes/Services/Prgm.type";
import DeviceTypeFormatter from "Utils/DeviceType.Formatter";
import { objectValues } from "DataTypes/Services/Index.type";
import { ListInitialStateType } from "DataTypes/Redux";

const DeviceProblemBox: FC<DeviceTypePrbmBoxProps> = (props) => {
  const { selectedDevice } = props;
  const dispatch = useDispatch();
  const programs: PrgmListResType[] = useSelector(
    (state: any) => state.list.programList
  );
  const { weightscaleproblem }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const [headHeight, setHeadHeight] = useState(60);
  const [pageHeight, setPageHeight] = useState(40);
  const [infoHeight, setInfoHeight] = useState(20);
  const headOffset = document?.getElementById("head")?.offsetHeight;
  const pageOffset = document?.getElementById("page")?.offsetHeight;
  const infoOffset = document?.getElementById("info-box")?.offsetHeight;
  const tableHeight = `calc(100% - ${headHeight + pageHeight + infoHeight}px)`;
  const [disableSave, setDisableSave] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [deviceProblemList, setDeviceProblemList] = useState<ProblemListType[]>(
    []
  );
  const [localChanges, setLocalChanges] = useState<(number | null)[]>([]);
  const [mappedList, setMappedList] = useState<(number | null)[]>([]);
  const [devicePageCriteria, setDevicePageCriteria] = useState<objectValues>({
    limit: 25,
    page: 1,
    total: 1,
    offset: 0,
  });

  useEffect(() => {
    setHeadHeight(headOffset || 60);
  }, [headOffset]);

  useEffect(() => {
    setPageHeight(pageOffset || 40);
  }, [pageOffset]);

  useEffect(() => {
    setInfoHeight(infoOffset || 20);
  }, [infoOffset]);

  useEffect(() => {
    compareArr();
  }, [localChanges, deviceProblemList]);

  const compareArr: () => void = () => {
    const arr1 = [...localChanges];
    const arr2 = [...mappedList];
    const difference = arr1
      .filter((x) => !arr2.includes(x))
      .concat(arr2.filter((x) => !arr1.includes(x)));
    setDisableSave(difference.length == 0 ? true : false);
  };

  useEffect(() => {
    getDeviceTypeProblems({
      page: devicePageCriteria.page,
      limit: devicePageCriteria.limit,
    });
  }, []);

  useEffect(() => {
    getMappedDeviceProblem();
  }, [selectedDevice?.deviceTypeId]);

  const getMappedDeviceProblem: () => Promise<void> = async () => {
    setLoading(true);
    if (selectedDevice?.deviceTypeId) {
      const res = await DeviceApi.mappedDeviceSearch({
        deviceTypeId: selectedDevice?.deviceTypeId,
      });
      if (res?.success) {
        const list = DeviceTypeFormatter.MappedDeviceType(res?.data);
        const idList = list?.problems.map(
          (el: { problemId: number; problemName: string }) => el.problemId
        );
        setLocalChanges(idList);
        setMappedList(idList);
      } else {
        setLocalChanges([]);
        setMappedList([]);
      }
    }
    setLoading(false);
  };

  const getDeviceTypeProblems: (props: objectValues) => Promise<void> = async (
    props
  ) => {
    setLoading(true);
    const { page, limit } = props;
    const offset = (page - 1) * limit;
    const rpmId = programs.find((program) => program.abbrevation === "RPM");
    if (rpmId) {
      const res = await ProgramApi.mappedProblemList({
        id1: rpmId.id,
        limit: limit,
        offset: offset,
      });
      if (res?.success) {
        setDeviceProblemList([
          ...ProbFormatter.MappedProblems(res?.data, weightscaleproblem),
        ]);
        setDevicePageCriteria(res.criterion);
      } else {
        // setDevicePageCriteria({});
        setDeviceProblemList([]);
      }
    }
    setLoading(false);
  };

  const handleChange: (obj: OptionsProps, alreadyChecked: boolean) => void = (
    obj,
    alreadyChecked
  ) => {
    if (alreadyChecked)
      setLocalChanges(localChanges.filter((el) => el != obj.id));
    else setLocalChanges([...localChanges, obj.id]);
  };

  const handleSelectAll: (isChecked: boolean) => void = (isChecked) => {
    if (isChecked) {
      const arr1 = [...localChanges];
      const arr2 = deviceProblemList.map((el) => el.id);
      const difference = arr2.filter((x) => !arr1.includes(x));
      setLocalChanges([...arr1, ...difference]);
    } else {
      setLocalChanges([]);
    }
  };

  const handleSave: () => void = async () => {
    if (selectedDevice?.deviceTypeId) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const res = await DeviceApi.updateMappedDevice({
        deviceTypeId: selectedDevice?.deviceTypeId,
        problemIds: localChanges,
      });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        await getMappedDeviceProblem();
      }
      dispatch(stopLoading());
    }
  };

  const handleSelectAllSave: () => void = async () => {
    if (selectedDevice?.deviceTypeId) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const res = await DeviceApi.updateMappedDevice({
        deviceTypeId: selectedDevice?.deviceTypeId,
        problemIds: [-1],
      });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        await getMappedDeviceProblem();
      }
      dispatch(stopLoading());
    }
  };

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
      <Box sx={{ ...sxHead }} id="head">
        <Typography variant="subtitle1">
          {`Problems > ${selectedDevice?.deviceTypeDescription}`}
        </Typography>
        <Box
          sx={{
            ...CommonStyle.sxRow,
            "& >button": { ml: 1 },
          }}
        >
          <CstmBtn
            label="Select All & Save"
            onClick={handleSelectAllSave}
            btnType="border"
          />
          <CstmBtn label="Save" onClick={handleSave} disable={disableSave} />
        </Box>
      </Box>
      <Box
        py={1}
        px={2}
        id="info-box"
        sx={{ backgroundColor: "custom.light", "& label": { mb: 0 } }}
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={localChanges.length === deviceProblemList.length}
            color="secondary"
            onClick={(e: any) => handleSelectAll(e.target.checked)}
            sx={{ "& .MuiSvgIcon-root": { fontSize: "1rem" }, p: 0 }}
          />
          <Typography variant="subtitle1" ml={1}>
            Select all <b>{deviceProblemList && deviceProblemList?.length}</b>{" "}
            problem(s) on this page
          </Typography>
        </Box>
        <Typography variant="subtitle1">
          Total <b>{localChanges.length}</b> problem(s) are selected out of{" "}
          <b>{deviceProblemList.length && deviceProblemList.length}</b>
        </Typography>
      </Box>
      <Box height={tableHeight} overflow="auto" px={2}>
        {loading ? (
          <Loader />
        ) : deviceProblemList.length == 0 ? (
          <NoData />
        ) : (
          <Grid container spacing={1} pt={1}>
            {deviceProblemList.map((el) => {
              const found = localChanges.find((el1) => el1 == el.id);
              return (
                <Grid key={el.id} item {...ControlledBoxSizeSwitch(6)}>
                  <ControlledForm.CheckBoxGroup
                    items={[
                      {
                        id: el.id,
                        title: el.name,
                        checked: found ? true : false,
                        disabled:
                          selectedDevice?.deviceTypeId == "WEIGHT_SCALE"
                            ? el?.disabled
                            : false,
                      },
                    ]}
                    onChange={(obj) => handleChange(obj, found ? true : false)}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <Box id={"page"} py={1}>
        <ControlledTable.Pagination
          handleClick={async (page, limit) => {
            await getDeviceTypeProblems({ page: page, limit: limit });
          }}
          limit={devicePageCriteria.limit}
          mainPage={devicePageCriteria.page}
          total={devicePageCriteria.total}
          tableStyle={false}
          limitArr={PageLimitArr}
        />
      </Box>
    </Box>
  );
};
export default DeviceProblemBox;

const sxHead = {
  px: 2,
  py: 1,
  ...CommonStyle.sxRow,
  justifyContent: "space-between",
  "& >h6:first-of-type": {
    fontWeight: "bold",
  },
};
