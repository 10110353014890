// import { FC, Fragment, useEffect, useState } from "react";
// import { FormDataType } from "DataTypes/Form";
// import { Box, Divider, Grid, Typography } from "@mui/material";
// import Loader from "Components/Loader";
// import { ControlledBoxSizeSwitch, commonFormSwitch } from "Utils/SwitchCases";
// import NoData from "Components/NoData";
// import CarePlanApi from "Service/CarePlan.api";
// import { FormData1, FormData2 } from "./Data";
// import CPFormatter from "Utils/CP.Formatter";
// import { AddProviderFormType } from "DataTypes/PatientManagement.type";
// import Format from "./Format";
// import { CMTabSelectedEnrollmentType } from "DataTypes/Modal";
// import { useDispatch } from "react-redux";
// import { addSelectedPrgm } from "Redux/Patient/Action";
// import { useNavigate } from "react-router-dom";
// import { RouteUrls } from "Constant/RouteUrls";
// import { selectedPrgmInitialState } from "Redux/Patient/reducer";
// import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
// import { useSelector } from "react-redux";
// import { CommonStyle } from "Components";
// import CstmIconBtn from "Components/CstmIconBtn";
// import ImgPath from "Constant/Imgs";

// const CareTeam: FC<CMTabSelectedEnrollmentType> = ({
//   prgmDetails,
//   onClose,
// }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { patientInfo }: PatientInitialStateType = useSelector(
//     (state: any) => state?.patient
//   );
//   const { myInfo }: UserInitialStateType = useSelector(
//     (state: any) => state?.user
//   );
//   const { careplanId, teamInitiatorRoleId, abbrevation } = prgmDetails;
//   const [formArr1, setFormArr1] = useState<FormDataType[]>([]);
//   const [formArr2, setFormArr2] = useState<AddProviderFormType[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);

//   useEffect(() => {
//     setData();
//   }, []);

//   const setData: () => void = async () => {
//     const res = await CarePlanApi.CPTeamSearch({ id1: careplanId });
//     if (res.success) {
//       const checkedRes = CPFormatter.CheckCPTeam(res?.data);
//       setFormArr1(Format.MainForm(checkedRes, FormData1));
//       setFormArr2(Format.AddForm(checkedRes, FormData2));
//     }
//     setLoading(false);
//   };

//   const handleEditTeam: () => void = () => {
//     onClose && onClose();
//     dispatch(
//       addSelectedPrgm({
//         ...selectedPrgmInitialState,
//         careplanId: prgmDetails.careplanId,
//       })
//     );
//     navigate(
//       `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.carePlan}/${prgmDetails.careplanId}/${RouteUrls.editTeam}`
//     );
//   };

//   return (
//     <Fragment>
//       {loading ? (
//         <Loader />
//       ) : formArr1.length > 0 ? (
//         <Fragment>
//           <Box
//             height="10%"
//             display="flex"
//             alignItems="center"
//             justifyContent="space-between"
//           >
//             <Typography
//               sx={{ ...CommonStyle.sxHeading }}
//             >{`${prgmDetails.abbrevation} Team`}</Typography>
//             {prgmDetails.status == "Enrolled" &&
//               (myInfo.roleId == 1 || myInfo.roleId == 2) && (
//                 <CstmIconBtn
//                   icon={<ImgPath.EditOutlined />}
//                   onClick={handleEditTeam}
//                   title="Edit"
//                 />
//               )}
//           </Box>
//           <Box overflow={"auto"} height="88%">
//             <Grid container rowSpacing={1} columnSpacing={1} mb={1}>
//               {formArr1.map((el) => {
//                 if (
//                   el.id == "cp-billable-provider" &&
//                   teamInitiatorRoleId == 3
//                 ) {
//                   return <Fragment key={el.id} />;
//                 }
//                 if (el.id == "cp-billable-provider" && abbrevation == "RPM") {
//                   return (
//                     <Grid
//                       key={el.id}
//                       item
//                       {...ControlledBoxSizeSwitch(el.boxSize)}
//                     >
//                       {commonFormSwitch({
//                         formObj: {
//                           ...el,
//                           label: "Treatment Plan Billable Provider",
//                         },
//                       })}
//                     </Grid>
//                   );
//                 }
//                 return (
//                   <Grid
//                     key={el.id}
//                     item
//                     {...ControlledBoxSizeSwitch(el.boxSize)}
//                   >
//                     {commonFormSwitch({ formObj: el })}
//                   </Grid>
//                 );
//               })}
//             </Grid>
//             {formArr2.map((el) => {
//               return (
//                 <Fragment key={el.id}>
//                   <Divider sx={{ mt: 2, mb: 1 }} />
//                   <Grid container rowSpacing={1} columnSpacing={1} mb={1}>
//                     {el.formArr.map((el1: FormDataType) => {
//                       return (
//                         <Grid
//                           key={el1.id}
//                           item
//                           {...ControlledBoxSizeSwitch(el1.boxSize)}
//                         >
//                           {commonFormSwitch({ formObj: el1 })}
//                         </Grid>
//                       );
//                     })}
//                   </Grid>
//                 </Fragment>
//               );
//             })}
//           </Box>
//         </Fragment>
//       ) : (
//         <NoData />
//       )}
//     </Fragment>
//   );
// };
// export default CareTeam;
import { FC, Fragment, useEffect, useState } from "react";
import { FormDataType } from "DataTypes/Form";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Loader from "Components/Loader";
import { commonFormSwitch } from "Utils/SwitchCases";
import NoData from "Components/NoData";
import CarePlanApi from "Service/CarePlan.api";
import {
  FormArr1,
  FormArr2,
} from "Pages/Home/HeadScreen/PatientMgmt/CarePlan/CareTeam/Data";
import CPFormatter from "Utils/CP.Formatter";
import { AddProviderFormType } from "DataTypes/PatientManagement.type";
import Format from "./Format";
import { CMTabSelectedEnrollmentType } from "DataTypes/Modal";
import { useDispatch } from "react-redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { CommonStyle } from "Components";
import CstmIconBtn from "Components/CstmIconBtn";
import ImgPath from "Constant/Imgs";

const CareTeam: FC<CMTabSelectedEnrollmentType> = ({
  prgmDetails,
  onClose,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { careplanId, abbrevation } = prgmDetails;
  const [formArr1, setFormArr1] = useState<FormDataType[]>([]);
  const [formArr2, setFormArr2] = useState<AddProviderFormType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setData();
  }, []);

  const setData: () => void = async () => {
    const res = await CarePlanApi.CPTeamSearch({ id1: careplanId });
    if (res.success) {
      const checkedRes = CPFormatter.CheckCPTeam(res?.data);
      setFormArr1(Format.MainForm(checkedRes, FormArr1, abbrevation));
      setFormArr2(Format.AddForm(checkedRes, FormArr2));
    }
    setLoading(false);
  };

  const handleEditTeam: () => void = () => {
    onClose && onClose();
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        careplanId: prgmDetails.careplanId,
      })
    );
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.carePlan}/${prgmDetails.careplanId}/${RouteUrls.editTeam}`
    );
  };

  const PrimSection: JSX.Element = (
    <Grid container rowSpacing={1} columnSpacing={2} mb={2}>
      {formArr1.map((el) => {
        return el.boxType == "empty-block" ? (
          <Fragment />
        ) : (
          <Grid key={el.id} item {...{ md: 4, lg: 3, xl: 3 }}>
            {commonFormSwitch({ formObj: el })}
          </Grid>
        );
      })}
    </Grid>
  );

  const SecSection: JSX.Element = (
    <Fragment>
      {formArr2.map((el) => {
        return (
          <Fragment key={el.id}>
            <Divider />
            <Box sx={{ ...CommonStyle.sxRow }} position="relative">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={2}
                sx={{ mb: 2, mt: 1 }}
              >
                {el.formArr.map((formEl: FormDataType) => {
                  return (
                    <Grid key={formEl.id} item {...{ md: 4, lg: 3, xl: 3 }}>
                      {commonFormSwitch({ formObj: formEl })}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Fragment>
        );
      })}
    </Fragment>
  );

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : formArr1.length > 0 ? (
        <Fragment>
          <Box
            height="10%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{ ...CommonStyle.sxHeading }}
            >{`${prgmDetails.abbrevation} Team`}</Typography>
            {prgmDetails.status == "Enrolled" &&
              (myInfo.roleId == 1 || myInfo.roleId == 2) && (
              <CstmIconBtn
                icon={<ImgPath.EditOutlined />}
                onClick={handleEditTeam}
                title="Edit"
              />
            )}
          </Box>
          <Box overflow={"auto"} height="88%">
            {PrimSection}
            {SecSection}
          </Box>
        </Fragment>
      ) : (
        <NoData />
      )}
    </Fragment>
  );
};
export default CareTeam;
