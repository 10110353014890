import { ControlledTable } from "Components";
import { columnType } from "DataTypes/Table";

export const TableCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.DateCol("8%"),
    ControlledTable.ColumnData.DeviceNameCol("10%"),
    ControlledTable.ColumnData.deviceReadingCol("10%"),
  ];
};
