import { Loader } from "Components";
import { RouteUrls } from "Constant/RouteUrls";
import { HeaderInitialStateType } from "DataTypes/Redux";
import { clearOrg } from "Redux/Header/Action";
import { stopLoading } from "Redux/Screen/Action";
import CommonFormatter from "Utils/Common.Formatter";
import { ConvertStringToNum } from "Utils/common";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";

const OrganizationStaff: FC = () => {
  const { orgId } = useParams();
  const newOrgId = ConvertStringToNum(orgId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organization }: HeaderInitialStateType = useSelector(
    (state: any) => state?.header
  );
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    CommonFormatter.HandleNavArr({
      id: "org-list",
      label: organization.name,
      path: `/${RouteUrls.hs}/${RouteUrls.organization}/${organization.id}/${RouteUrls.staff}/${RouteUrls.list}`,
      level: 2,
      link: RouteUrls.list,
      navType: "org",
    });
    setLoader(false);
    return () => {
      dispatch(clearOrg());
    };
  }, []);

  if (newOrgId != organization.id) {
    dispatch(stopLoading());
    navigate(-1);
  }

  return loader ? <Loader /> : <Outlet />;
};
export default OrganizationStaff;
