import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import { ControlledTable } from "Components";
import { actionArrType, columnType } from "DataTypes/Table";

export const actionArr: (
  handleEdit: (data: any) => void,
  handleDeleteIcon: (data: any) => void
) => actionArrType[] = (handleEdit, handleDeleteIcon) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.editAction({ handleClick: handleEdit }),
    ControlledTable.ActionData.deleteAction(handleDeleteIcon),
  ];
  return Arr;
};
export const TableCol: columnType[] = [
  ControlledTable.ColumnData.TypeCol(),
  ControlledTable.ColumnData.webhookTokenCol(),
  ControlledTable.ColumnData.webhookTokenExpireAtCol(),
  ControlledTable.ColumnData.destinationIdCol(),
  ControlledTable.ColumnData.destinationNameCol(),
  ControlledTable.ColumnData.SourceIdCol(),
  ControlledTable.ColumnData.SourceNameCol(),
  ControlledTable.ColumnData.DataModelCol(),
  ControlledTable.ColumnData.ActionCol(),
];

const typeDropArr: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "Api",
    value: "api",
  },
  {
    id: 2,
    label: "Event",
    value: "event",
  },
];

export const WebFormDataArr: FormDataType[] = [
  {
    id: "dataModel",
    label: "DataModel",
    placeholder: "Enter DataModel",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "sourceId",
    label: "Source Id",
    placeholder: "Enter Source Id",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "sourceName",
    label: "Source Name",
    placeholder: "Enter Source Name",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "destinationId",
    label: "Destination Id",
    placeholder: "Enter Destination Id",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "destinationName",
    label: "Destination Name",
    placeholder: "Enter Destination Name",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "urlEndPoint",
    label: "Url EndPoint",
    placeholder: "Enter Url EndPoint",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "authType",
    label: "Auth Type",
    placeholder: "Enter Auth Type",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "webhookToken",
    label: "Webhook Token",
    placeholder: "Enter Webhook Token",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "webhookTokenExpireAt",
    label: "Webhook Token Expire At",
    placeholder: "Enter Webhook Token Expire At",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
];
export const APIFormDataArr: FormDataType[] = [
  {
    id: "dataModel",
    label: "DataModel",
    placeholder: "Enter DataModel",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "sourceId",
    label: "Source Id",
    placeholder: "Enter Source Id",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "sourceName",
    label: "Source Name",
    placeholder: "Enter Source Name",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "destinationId",
    label: "Destination Id",
    placeholder: "Enter Destination Id",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "destinationName",
    label: "Destination Name",
    placeholder: "Enter Destination Name",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "urlEndPoint",
    label: "Url EndPoint",
    placeholder: "Enter Url EndPoint",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "authType",
    label: "Auth Type",
    placeholder: "Enter Auth Type",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "apiKey",
    label: "API Key",
    placeholder: "Enter API Key",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "apiSecret",
    label: "API Secret",
    placeholder: "Enter API Secret",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "apiExpiry",
    label: "API Expiry",
    placeholder: "Enter API Expiry",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
  {
    id: "apiKID",
    label: "API KID",
    placeholder: "Enter API KID",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "alphanumericName",
    required: true,
  },
];
export const InitialFormDataArr = (
  orgDropArr: DropdownArrPropsType[],
  orgLocDropArr: DropdownArrPropsType[]
) => {
  const Arr: FormDataType[] = [
    {
      id: "organizations",
      label: "Organizations",
      placeholder: "Select Organization",
      boxType: "single-select-drop",
      dropArr: orgDropArr,
      boxSize: 4,
      validatetype: "dropdown",
      required: true,
    },
    {
      id: "practiceLocations",
      label: "Organizations Locations",
      placeholder: "Select Organization Location",
      boxType: "single-select-drop",
      dropArr: orgLocDropArr,
      boxSize: 4,
      validatetype: "dropdown",
      required: true,
    },
    {
      id: "type",
      label: "Type",
      placeholder: "Enter Type",
      dropArr: typeDropArr,
      boxType: "single-select-drop",
      boxSize: 4,
      validatetype: "alphanumericName",
      required: true,
    },
  ];
  return Arr;
};
