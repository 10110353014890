import {
  ThresholdHeadType,
  ThresholdType,
  WeightDataType,
} from "DataTypes/Services/Settings.type";

export const HeadData: ThresholdHeadType[] = [
  {
    id: "CRITICAL_LOW",
    label: "Critical Low",
    color: "error.light",
    range: [
      {
        id: "upperLimit",
        label: "Less Than",
        width: "100%",
      },
    ],
  },
  {
    id: "ABNORMAL_LOW",
    label: "Abnormal Low",
    color: "warning.light",
    range: [
      {
        id: "lowerLimit",
        label: "Min",
        width: "50%",
      },
      {
        id: "upperLimit",
        label: "Max",
        width: "50%",
      },
    ],
  },
  {
    id: "NORMAL",
    label: "Normal",
    color: "success.light",
    range: [
      {
        id: "lowerLimit",
        label: "Min",
        width: "50%",
      },
      {
        id: "upperLimit",
        label: "Max",
        width: "50%",
      },
    ],
  },
  {
    id: "ABNORMAL_HIGH",
    label: "Abnormal High",
    color: "warning.light",
    range: [
      {
        id: "lowerLimit",
        label: "Min",
        width: "50%",
      },
      {
        id: "upperLimit",
        label: "Max",
        width: "50%",
      },
    ],
  },
  {
    id: "CRITICAL_HIGH",
    label: "Critical High",
    color: "error.light",
    range: [
      {
        id: "lowerLimit",
        label: "Greater Than",
        width: "100%",
      },
    ],
  },
];

export const getWeightData = (data: ThresholdType): WeightDataType[] => [
  {
    id: "desc",
    label: (
      data.weightFluctuation +
      " " +
      data.operatorDescription
    ).toLowerCase(),
    color: "black",
    width: "45%",
    type: "static",
  },
  {
    id: "weightValue",
    label: data.weightValue,
    color: "black",
    width: "10%",
    type: "input",
  },
  {
    id: "unit",
    label: (data.uomLabel + " in").toLowerCase(),
    color: "black",
    width: "20%",
    type: "static",
  },
  {
    id: "daysLimit",
    label: data.daysLimit,
    color: "black",
    width: "10%",
    type: "input",
  },
  {
    id: "days",
    label: "days",
    color: "black",
    width: "10%",
    type: "static",
  },
  {
    id: "criteria",
    label:
      (data.alertType &&
        HeadData.find((el) => el.id === data.alertType)?.label) ||
      null,
    color:
      (data.alertType &&
        HeadData.find((el) => el.id === data.alertType)?.color) ||
      null,
    width: "25%",
    type: "static",
  },
];
