import { DropdownArrPropsType } from "DataTypes/Form";
import {
  ICDCodeType,
  IcdCodeType,
  ProblemListType,
  QuestionProblemType,
} from "DataTypes/Services/Problem.type";
import { FormatDropdownData } from "Utils/DataFormatter";
import { AscendSort, CheckArr, CheckNumber, CheckStringEmpty } from "./common";

const List: (
  data: any,
  weightscaleproblem?: DropdownArrPropsType[]
) => ProblemListType[] = (data, weightscaleproblem) => {
  const isdisabledIdsArray = Array.isArray(weightscaleproblem)
    ? weightscaleproblem
    : [];
  const disabledproblems = isdisabledIdsArray
    ? weightscaleproblem?.map((item) => item.value)
    : [];
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      name: CheckStringEmpty(el?.name),
      disabled: disabledproblems ? disabledproblems.includes(el?.name) : false,
    };
  });
};

const DropList: (data: any) => DropdownArrPropsType[] = (data) => {
  const newArr = Array.isArray(data) ? data : [];
  return FormatDropdownData(newArr, "id", "name", "id");
};

const MappedProblems: (
  data: any,
  weightscaleproblem?: DropdownArrPropsType[]
) => ProblemListType[] = (data, weightscaleproblem) => {
  const isdisabledIdsArray = Array.isArray(weightscaleproblem)
    ? weightscaleproblem
    : [];
  const disabledproblems = isdisabledIdsArray
    ? weightscaleproblem?.map((item) => item.value)
    : [];
  const newArr: ProblemListType[] = Array.isArray(data)
    ? data.map((el) => {
        return {
          id: CheckNumber(el?.problem?.id),
          name: CheckStringEmpty(el?.problem?.name),
          disabled: disabledproblems
            ? disabledproblems.includes(el?.problem?.name)
            : false,
        };
      })
    : [];
  return newArr;
};

const QuestionProblem: (data: any) => QuestionProblemType = (data) => {
  return {
    id: CheckNumber(data?.id),
    name: CheckStringEmpty(data?.name),
  };
};

const QuestionProblemArr: (data: any) => QuestionProblemType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: QuestionProblemType[] = CheckedArr.map((el) =>
    QuestionProblem(el)
  );
  return AscendSort(newArr, "name");
};

const IcdCodes: (res: any) => IcdCodeType[] = (res) => {
  const CheckedArr = CheckArr(res);
  return CheckedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      icdCode: CheckStringEmpty(el?.icdCode),
      description: CheckStringEmpty(el?.description),
      problemId: CheckNumber(el?.problem?.id),
      problem: CheckStringEmpty(el?.problem?.name),
    };
  });
};

const IcdCodeSearch: (res: any) => ICDCodeType[] = (res) => {
  const CheckedArr = CheckArr(res);
  return CheckedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      icdCodeId: CheckNumber(el?.id),
      icdCodeName: CheckStringEmpty(el?.icdCode),
      icdCodeDescription: CheckStringEmpty(el?.description),
    };
  });
};

export default {
  List,
  DropList,
  QuestionProblem,
  QuestionProblemArr,
  MappedProblems,
  IcdCodes,
  IcdCodeSearch,
};
