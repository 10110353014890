import { CategoryModalProps } from "DataTypes/Modal";
import { Fragment, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { commonFormSwitch } from "Utils/SwitchCases";
import { FormDataType } from "DataTypes/Form";

const CategoryModal = ({ formArr, handleChange }: CategoryModalProps) => {
  const [formData, setFormData] = useState<FormDataType[]>([]);

  useEffect(() => {
    formArr && setFormData(formArr);
  }, [formArr]);

  return (
    <Fragment>
      <Box>
        {formData &&
          handleChange &&
          formData.length > 0 &&
          formData.map((el) => {
            return (
              <Box key={el.id} margin={"10px"}>
                {commonFormSwitch({
                  formObj: el,
                  onChange: (data) => handleChange(data, [el]),
                })}
              </Box>
            );
          })}
      </Box>
    </Fragment>
  );
};
export default CategoryModal;
