import { ControlledForm, ControlledTable } from "Components";
import { actionArrType, columnType } from "DataTypes/Table";
import { BreadArrType } from "DataTypes/Common";
import { FormDataType } from "DataTypes/Form";

export const TableCol: (
  isView: boolean,
  handleReadMore?: (id: number | null) => void,
  readMoreLength?: number,
  checkReadMore?: (id: number | null) => boolean
) => columnType[] = (isView, handleReadMore, readMoreLength, checkReadMore) => {
  const columns: columnType[] = [
    ControlledTable.ColumnData.DateTimeCol("15%"),
    {...ControlledTable.ColumnData.TrainingNotesCol(
      "70%",
      handleReadMore,
      readMoreLength,
      checkReadMore
    ), label: "Training Notes"},
  ];

  if (isView) {
    columns.push(ControlledTable.ColumnData.ActionCol("18%"));
  }

  return columns;
};

export const NotesBreadArr: BreadArrType[] = [
  {
    id: 1,
    label: "Home",
    path: "/",
  },
  {
    id: 2,
    label: "Training Notes",
    path: "",
  },
];

export const FormDataArr: () => FormDataType[] = () => {
  return [ControlledForm.FormData.Notes()];
};

export const actionArr: (
  handleEdit: () => void,
  handleDelete: () => void
  // isView: boolean
) => actionArrType[] = (handleEdit, handleDelete) => {
  return [
    ControlledTable.ActionData.editAction({ handleClick: handleEdit }),
    ControlledTable.ActionData.deleteAction(handleDelete),
  ];
};
