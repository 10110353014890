import { ListActionType, ListInitialStateType } from "DataTypes/Redux";
import {
  CLEAR_LIST,
  STORE_CALLRESULT_LIST,
  STORE_FREQUENCY_LIST,
  STORE_GENDER_LIST,
  STORE_MODEL_DROP_LIST,
  STORE_NOTIFICATION_LIST,
  STORE_ORG_DROP_LIST,
  STORE_PROGRAM_DROP_LIST,
  STORE_PROGRAM_LIST,
  STORE_QUALITY_LIST,
  STORE_REMINDER_DROP_LIST,
  STORE_ROLE_DROP_LIST,
  STORE_TASK_TYPE_LIST,
  STORE_RPM_FREQUENCY_LIST,
  STORE_TASK_STATUS_LIST,
  STORE_SMARTMETER_USER_LIST,
  STORE_GROUP_LIST,
  IS_LOGGEDIN_FIRST_TIME,
  STORE_WEIGHTSCALE_PROBLEM_LIST,
  STORE_TIMELOG_TYPE_LIST,
  STORE_RPM_TIMELOG_TYPE_LIST,
  STORE_TRAINING_REQ_TYPE_LIST,
  STORE_DEVICE_PROVIDER_LIST,
  STORE_DEVICE_UOM_LIST,
} from "./type";

const INITIAL_STATE: ListInitialStateType = {
  modelDropList: [],
  prgDropList: [],
  roleDropList: [],
  programList: [],
  notificationList: [],
  genderList: [],
  qualityList: [],
  callResultList: [],
  frequencyList: [],
  orgDropList: [],
  taskTypeList: [],
  reminderDropList: [],
  rpmFrequencyList: [],
  taskStatusList: [],
  smartmeterUserList: [],
  groupList: [],
  isLoggedInFirstTime: true,
  weightscaleproblem: [],
  addTimeLogType: [],
  addRPMTimeLogType: [],
  trainingReq: [],
  deviceProvider: [],
  deviceUOMList: [],
};

const reducer = (
  state = JSON.parse(JSON.stringify(INITIAL_STATE)),
  action: ListActionType
) => {
  switch (action.type) {
    case STORE_MODEL_DROP_LIST:
      return {
        ...state,
        modelDropList: [...action.payload],
      };
    case STORE_PROGRAM_DROP_LIST:
      return {
        ...state,
        prgDropList: [...action.payload],
      };
    case STORE_PROGRAM_LIST:
      return {
        ...state,
        programList: [...action.payload],
      };
    case STORE_ROLE_DROP_LIST:
      return {
        ...state,
        roleDropList: [...action.payload],
      };
    case STORE_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: [...action.payload],
      };
    case STORE_GENDER_LIST:
      return {
        ...state,
        genderList: [...action.payload],
      };
    case STORE_QUALITY_LIST:
      return {
        ...state,
        qualityList: [...action.payload],
      };
    case STORE_CALLRESULT_LIST:
      return {
        ...state,
        callResultList: [...action.payload],
      };
    case STORE_FREQUENCY_LIST:
      return {
        ...state,
        frequencyList: [...action.payload],
      };
    case STORE_ORG_DROP_LIST:
      return {
        ...state,
        orgDropList: [...action.payload],
      };
    case STORE_TASK_TYPE_LIST:
      return {
        ...state,
        taskTypeList: [...action.payload],
      };
    case STORE_REMINDER_DROP_LIST:
      return {
        ...state,
        reminderDropList: [...action.payload],
      };
    case STORE_RPM_FREQUENCY_LIST:
      return {
        ...state,
        rpmFrequencyList: [...action.payload],
      };
    case STORE_TASK_STATUS_LIST:
      return {
        ...state,
        taskStatusList: [...action.payload],
      };
    case STORE_SMARTMETER_USER_LIST:
      return {
        ...state,
        smartmeterUserList: [...action.payload],
      };
    case STORE_GROUP_LIST:
      return {
        ...state,
        groupList: [...action.payload],
      };
    case IS_LOGGEDIN_FIRST_TIME:
      return {
        ...state,
        isLoggedInFirstTime: action.payload,
      };
    case STORE_WEIGHTSCALE_PROBLEM_LIST:
      return {
        ...state,
        weightscaleproblem: [...action.payload],
      };
    case STORE_TIMELOG_TYPE_LIST:
      return {
        ...state,
        addTimeLogType: [...action.payload],
      };
    case STORE_RPM_TIMELOG_TYPE_LIST:
      return {
        ...state,
        addRPMTimeLogType: [...action.payload],
      };
    case STORE_TRAINING_REQ_TYPE_LIST:
      return {
        ...state,
        trainingReq: [...action.payload],
      };
    case STORE_DEVICE_PROVIDER_LIST:
      return {
        ...state,
        deviceProvider: [...action.payload],
      };
    case STORE_DEVICE_UOM_LIST:
      return {
        ...state,
        deviceUOMList: [...action.payload],
      };
    case CLEAR_LIST:
      return {
        ...state,
        modelDropList: [],
        prgDropList: [],
        roleDropList: [],
        programList: [],
        outgoingTaskList: [],
        notificationList: [],
        genderList: [],
        qualityList: [],
        callResultList: [],
        frequencyList: [],
        orgDropList: [],
        taskTypeList: [],
        reminderDropList: [],
        rpmFrequencyList: [],
        taskStatusList: [],
        smartmeterUserList: [],
        groupList: [],
        isLoggedInFirstTime: true,
        weightscaleproblem: [],
        addTimeLogType: [],
        addRPMTimeLogType: [],
        trainingReq: [],
        deviceProvider: [],
        deviceUOMList: [],
      };
    default:
      return state;
  }
};

export default reducer;
