import { FC } from "react";
import { objectValues } from "DataTypes/Services/Index.type";
import { Box, Typography } from "@mui/material";
import { CommonStyle, Loader } from "Components";
import DateTime from "Utils/DateTime";
import NoData from "Components/NoData";

const Time: FC<{ data: objectValues[]; loading: boolean }> = ({
  data,
  loading,
}) => {
  return (
    <Box sx={{ ...sxMain }}>
      {loading ? (
        <Loader />
      ) : data?.length > 0 ? (
        data?.map((data) => (
          <Box
            key={data?.id}
            sx={{
              borderBottom: "1px solid",
              borderColor: "custom.main",
              pb: 1,
            }}
          >
            <Box
              sx={{
                ...CommonStyle.sxRow,
                justifyContent: "space-between",
                mt: 2,
                mb: 0.5,
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                {data?.title}
              </Typography>
              <Typography variant="subtitle2">
                {data?.dateTime
                  ? DateTime.UTCToLocalDateTime(data?.dateTime)
                  : "----"}
              </Typography>
            </Box>
            <Typography variant="subtitle2" width={"50%"}>
              {data?.description}
            </Typography>
          </Box>
        ))
      ) : (
        <NoData />
      )}
    </Box>
  );
};
export default Time;

const sxMain = {
  height: "100%",
  overflow: "auto",
  px: 2,
};
