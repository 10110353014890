import {
  EligiblePrgmResType,
  QualityMeasureType,
  PatientAuditType,
  CallLogListType,
  ConsentHistoryListType,
  ConsentPrgmType,
  DischargeSummaryType,
  LoggedTimeType,
  PatientDeviceType,
  PatientDeviceTrainingType,
  PatientDeviceDetails,
  DeviceReadingType,
  ActivityTimeLogType,
} from "DataTypes/Services/Patient.type";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  ConvertStringToNum,
  CheckCPStatus,
  AscendSortByNumber,
  CheckBoolean,
  CheckStringId,
} from "./common";
import {
  // FormatEligiblePrgmType,
  ListPatientInfoType,
} from "DataTypes/PatientManagement.type";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import {
  // MyInfoType,
  PatientInfoType,
  PatientOrgInfoType,
} from "DataTypes/Redux";
import DateTime from "./DateTime";
import Stages from "Constant/Stages";
import { RouteUrls } from "Constant/RouteUrls";
import DeviceTypeFormatter from "./DeviceType.Formatter";

const EligiblePrgmResObj: (
  res: any,
  loginUserId: number | null
) => EligiblePrgmResType = (res, loginUserId) => {
  let cpState = CheckStringEmpty(res?.careplan_state);
  const caremanagerId = CheckNumber(res?.caremanager_id);
  const providerId = CheckNumber(res?.team_provider_id);
  const providerName = CheckStringEmpty(res?.team_provider_name);
  const billableproviderId = CheckNumber(res?.billable_provider_id);
  const primaryccId = CheckNumber(res?.primary_care_coordinator_id);
  const secondaryccId = CheckNumber(res?.secondary_care_coordinator_id);
  const careplanRecurringId = ConvertStringToNum(res?.careplan_recurring_id);
  const status = CheckCPStatus(res?.status);
  const abbrevation = CheckStringEmpty(res?.abbrevation);
  const teamInitiatorRoleId = CheckNumber(res?.team_initiator_role_id);
  const teamInitiatedBy = CheckNumber(res?.team_intiated_by);
  let careplanActivity: string | null = null;
  // before making any code changes, refer the rules file on
  // src\Pages\Home\HeadScreen\PatientMgmt\CarePlan\Rules.txt

  if (abbrevation == "TCM") careplanActivity = "TCM";
  ///For RPM Program Changes
  else if (abbrevation == "RPM") {
    if (status == "Enrolled") {
      if (careplanRecurringId) {
        careplanActivity = `${abbrevation} Services`;
      } else {
        careplanActivity = `${abbrevation} Treatment Plan`;
      }
    } else {
      careplanActivity = `${abbrevation} Call Reminder`;
    }
  } else {
    if (status == "Enrolled") {
      if (careplanRecurringId) {
        careplanActivity = `${abbrevation} Recurring`;
      } else {
        careplanActivity = `${abbrevation} Care Plan`;
      }
    } else {
      careplanActivity = `${abbrevation} Call Reminder`;
    }
  }

  ///Check for pending assignment state seperately
  if (
    !billableproviderId &&
    !caremanagerId &&
    !providerId &&
    status == "Enrolled"
  ) {
    cpState = Stages.CPAssignment;
  }

  // Check for nextSchedule for a plan
  let nextSchedule = CheckStringEmpty(res?.next_schedule);
  if (status == "Enrolled") {
    if (abbrevation == "TCM") nextSchedule = "TCM";
    else {
      if (careplanRecurringId) {
        if (abbrevation == "RPM") nextSchedule = "Services";
        else nextSchedule = "Recurring";
      } else {
        if (abbrevation == "RPM") nextSchedule = "Treatment Plan";
        else nextSchedule = "Care Plan";
      }
    }
  } else nextSchedule = "";

  //Check for seniorTeamMember & teamMember
  //Treatment plan provider cannot do recurring or revision
  let teamMember = false;
  let seniorTeamMember = false;
  if (
    loginUserId == caremanagerId ||
    loginUserId == billableproviderId ||
    loginUserId == providerId
  ) {
    seniorTeamMember = true;
    teamMember = true;
  }
  if (loginUserId == primaryccId || loginUserId == secondaryccId) {
    teamMember = true;
  }

  ////For task #13110 in PMS
  if (
    abbrevation == "RPM" &&
    loginUserId == billableproviderId &&
    loginUserId != providerId &&
    careplanRecurringId
  ) {
    seniorTeamMember = false;
    teamMember = false;
  }

  const compreCare = CheckStringEmpty(
    res?.is_careplan_comprehensive
  ).toLowerCase();
  return {
    abbrevation,
    activity: CheckStringEmpty(res?.activity),
    appointment_date: DateTime.ValidDate(res?.appointment_date),
    appointment_id: CheckNumber(res?.appointment_id),
    billableproviderId,
    caremanagerId,
    careplanActivity,
    careplanCreatedInEhr: CheckStringEmpty(res?.careplan_created_in_ehr),
    careplanFrequency: CheckStringEmpty(res?.careplan_frequency),
    careplanId: ConvertStringToNum(res?.careplan_id),
    careplanInitDateInEhr: CheckStringEmpty(res?.careplan_init_date_in_ehr),
    careplanRecurringId,
    careplanRecurringStatus: CheckStringEmpty(res?.careplan_recurring_status),
    careplanState: cpState,
    careplanStage: GetCPStage(cpState),
    careplanType: "careplan",
    dischargeDate: DateTime.ValidDate(res?.date_of_discharge),
    eligibleProgramId: CheckNumber(res?.eligible_program_id),
    enrollmentDate: DateTime.ValidDate(res?.enrolment_date),
    enrollmentId: CheckNumber(res?.enrollment_id),
    isCareplanComprehensive: compreCare && compreCare == "yes" ? true : false,
    nextSchedule,
    patientDevices: CheckPatientDeviceList(res?.patientDevice),
    patientId: CheckStringId(res?.patient_id),
    planningMonth: ConvertStringToNum(res?.planning_month),
    planningYear: ConvertStringToNum(res?.planning_year),
    practiceId: CheckNumber(res?.practice_id),
    practiceLocationId: CheckNumber(res?.practice_location_id),
    practiceLocationName: CheckStringEmpty(res?.practice_location_name),
    practiceLocationTimezone: CheckStringEmpty(res?.practice_location_timezone),
    practiceName: CheckStringEmpty(res?.practice_name),
    primaryccId: CheckNumber(res?.primary_care_coordinator_id),
    programId: CheckNumber(res?.program_id),
    providerId,
    providerName,
    recurringPlanningMonth: ConvertStringToNum(
      res?.careplan_recurring_planning_month
    ),
    recurringPlanningYear: ConvertStringToNum(
      res?.careplan_recurring_planning_year
    ),
    secondaryccId: CheckNumber(res?.secondary_care_coordinator_id),
    seniorTeamMember,
    status,
    teamInitiatorRoleId,
    teamInitiatedBy,
    teamMember,
  };
};

const CheckPatientDeviceList: (arr: any) => PatientDeviceType[] = (arr) => {
  return CheckArr(arr).map((el) => CheckPatientDevice(el));
};

const CheckPatientDevice: (el: any) => PatientDeviceType = (el) => {
  return {
    ...CheckPatientDeviceDetails(el),
    ...CheckPatientDeviceTraining(el),
  };
};

const CheckPatientDeviceDetails: (el: any) => PatientDeviceDetails = (el) => {
  return {
    ...DeviceTypeFormatter.DeviceNameObj(el),
    actualDeviceId: CheckStringEmpty(el?.actualDeviceId),
    deviceId: CheckNumber(el?.deviceId),
    deviceProvider: CheckStringEmpty(el?.deviceProvider),
    deviceProviderValue: CheckStringEmpty(el?.deviceProviderValue),
    fromDate: DateTime.ValidDate(el?.fromDate),
    id: CheckNumber(el?.patientDeviceId),
    isDeleted: CheckBoolean(el?.isDeleted),
    isDeviceSuspended: CheckBoolean(el?.isDeviceSuspended),
    patientDeviceId: CheckNumber(el?.patientDeviceId),
    thruDate: DateTime.ValidDate(el?.thruDate),
  };
};

const CheckPatientDeviceTraining: (el: any) => PatientDeviceTrainingType = (
  el
) => {
  return {
    id: CheckNumber(el?.trainingId),
    trainingActivity: CheckStringEmpty(el?.trainingActivity),
    trainerId: CheckNumber(el?.trainerId),
    trainerName: CheckStringEmpty(el?.trainerName),
    trainingCreatedById: CheckNumber(el?.trainingCreatedBy),
    trainingCreatedBy: CheckStringEmpty(el?.trainingCreatedByUser_name),
    trainingId: CheckNumber(el?.trainingId),
    trainingRequired: CheckBoolean(el?.trainingRequired),
    trainingStatusDescription: CheckStringEmpty(el?.trainingStatusDescription),
    trainingStatusId: CheckStringEmpty(el?.trainingStatusId),
    trainingTypeDescription: CheckStringEmpty(el?.trainingTypeDescription),
    trainingTypeId: CheckStringEmpty(el?.trainingTypeId),
  };
};

const CheckEligiblePrgmRes: (
  res: any,
  loginUserId: number | null
) => EligiblePrgmResType[] = (res, loginUserId) => {
  const CheckedArr = CheckArr(res);
  const newArr: EligiblePrgmResType[] = CheckedArr.map((el) =>
    EligiblePrgmResObj(el, loginUserId)
  );
  return AscendSortByNumber(newArr, "programId");
};

const ActivityTimeLogRes: (data: any) => ActivityTimeLogType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    return {
      abbreviation: CheckStringEmpty(el.abbreviation),
      activity: CheckStringEmpty(el.activity),
      careplanId: CheckNumber(el.careplanId),
      careplanRecurringId: CheckNumber(el.careplanRecurringId),
      programId: CheckNumber(el.programId),
      dischargeDate: CheckStringEmpty(el.dateOfDischarge),
      enrollmentDate: CheckStringEmpty(el.enrolmentDate),
    };
  });
};
// const FormatEligiblePrgmRes: (
//   res: any,
//   loginUserId: number | null
// ) => FormatEligiblePrgmType[] = (res, loginUserId) => {
//   return CheckArr(res).map((prgmObj) =>
//     FormatEligiblePrgmObj(prgmObj, loginUserId)
//   );
// };

// const FormatEligiblePrgmObj: (
//   data: any,
//   loginUserId: number | null
// ) => FormatEligiblePrgmType = (data, loginUserId) => {
//   const enrollmentDate = "";
//   const prgmObj = EligiblePrgmResObj(data, loginUserId);
//   return {
//     ...prgmObj,
//     showLogTime: false,
//     readOnly: false,
//     enrollmentDate,
//     careplanId: prgmObj.status == "Enrolled" ? prgmObj.careplanId : null,
//     careplanRecurringId:
//       prgmObj.status == "Enrolled" ? prgmObj.careplanRecurringId : null,
//     readOnlyArr: [],
//     callDefaultRoute: "",
//     showSaveBtn: false,
//     showSubmitBtn: false,
//   };
// };

// const AppointEligiblePrgmRes: (
//   res: any,
//   loginUser: MyInfoType
// ) => FormatEligiblePrgmType[] = (res, loginUser) => {
//   let newArr = FormatEligiblePrgmRes(res, loginUser.id);
//   newArr = newArr.filter((el) => {
//     if (el.careplanState != Stages.CPAssignment) {
//       if (el.abbrevation == "TCM") {
//         if (el.status == "Enrolled" && el.careplanState != Stages.Recurring)
//           return el;
//       } else return el;
//     }
//   });
//   const findRPMIndex = newArr.findIndex((el) => el.abbrevation == "RPM");
//   if (findRPMIndex != -1) {
//     const findLoginUserIndex = newArr[findRPMIndex].patientDevices.findIndex(
//       (el) => el.trainerId == loginUser.id && el.actualDeviceId
//     );
//     if (findLoginUserIndex != -1) {
//       if (loginUser.roleId == 6) {
//         return [
//           {
//             ...newArr[findRPMIndex],
//             careplanActivity: "RPM Device Setup & Training",
//           },
//         ];
//       } else {
//         newArr.push({
//           ...newArr[findRPMIndex],
//           careplanActivity: "RPM Device Setup & Training",
//         });
//       }
//     }
//   }
//   return newArr;
// };

const GetCPStage: (cpState: string) => number = (cpState) => {
  return Stages.CPStage.findIndex((el) => el == cpState) + 1;
};

const CheckQualitySearch: (res: any) => QualityMeasureType[] = (res) => {
  const CheckedArr = CheckArr(res);
  return CheckedArr.map((el) => {
    return {
      canonicalName: CheckStringEmpty(el?.canonicalName),
      createdAt: CheckStringEmpty(el?.createdAt),
      dueDate: CheckStringEmpty(el?.dueDate),
      takenOn: CheckStringEmpty(el?.takenOn),
      id: CheckNumber(el?.id),
      isCompleted: CheckBoolean(el?.isCompleted),
      measureId: CheckStringEmpty(el?.measureId),
      measureType: CheckStringEmpty(el?.measureType),
      note: CheckStringEmpty(el?.note),
      dateArr: CheckQualitySearch(el?.qualityMeasureNotesListResponseDtos),
    };
  });
};

export const MobileNumber: (data: string) => string = (data) => {
  const cleaned = ("" + data).replace(/\D/g, "");
  //Check if the input is of correct length
  const match1 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  const match2 = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  const match3 = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
  if (match1) {
    return "(" + match1[1] + ") " + match1[2] + "-" + match1[3];
  } else if (match2) {
    return (
      "+" + match2[1] + " (" + match2[2] + ") " + match2[3] + "-" + match2[4]
    );
  } else if (match3) {
    return (
      "+" + match3[1] + " (" + match3[2] + ") " + match3[3] + "-" + match3[4]
    );
  } else return data;
};

export const StateRouteSwitch: (cpState: string) => string = (cpState) => {
  switch (cpState) {
    case "Pending problem assignment":
      return RouteUrls.careProblem;
    case "Pending device assignment":
      return RouteUrls.rpmAddDevice;
    case "Pending questionnaire":
      return RouteUrls.careQuestion;
    case "Pending team assignment":
      return RouteUrls.careTeam;
    case "Pending share with provider":
      return RouteUrls.careSummary;
    case "Pending for provider approval":
      return RouteUrls.careSummary;
    default:
      return RouteUrls.careSummary;
  }
};

const FormatePatientInfo: (data: any) => PatientInfoType = (data) => {
  const city = CheckStringEmpty(data?.city);
  const country = CheckStringEmpty(data?.country);
  const dob = DateTime.ValidDate(data?.dob);
  const email = CheckStringEmpty(
    data?.emailAddresses?.length > 0 ? data?.emailAddresses[0]?.address : ""
  );
  const firstName = CheckStringEmpty(data?.firstName);
  const home = CheckStringEmpty(data?.home);
  const id = CheckStringId(data?.id);
  const lastName = CheckStringEmpty(data?.lastName);
  const middleName = CheckStringEmpty(data?.middleName);
  const mobile = CheckStringEmpty(data?.mobile);
  const name =
    (firstName ? `${firstName} ` : "") +
    (middleName ? `${middleName} ` : "") +
    (lastName ? lastName : "");
  const sex = CheckStringEmpty(data?.sex);
  const state = CheckStringEmpty(data?.state);
  const streetAddress = CheckStringEmpty(data?.streetAddress);
  const zip = CheckStringEmpty(data?.zip);
  const notes = CheckStringEmpty(data?.notes);
  const address =
    (streetAddress ? `${streetAddress}, ` : "") +
    (city ? `${city}, ` : "") +
    (state ? `${state} ` : "") +
    (zip ? `${zip}` : "") +
    ((state || zip) && country ? ", " : "") +
    (country ? country : "");
  const age = DateTime.AgeCalculator(dob);
  const gender = sex.slice(0, 1);
  return {
    address,
    age,
    city,
    country,
    dob,
    email,
    firstName,
    gender,
    home,
    id,
    lastName,
    middleName,
    mobile,
    name,
    sex,
    state,
    streetAddress,
    zip,
    notes,
  };
};

const FormatPatientOrg: (data: any) => PatientOrgInfoType = (data) => {
  return {
    practiceId: CheckNumber(data?.practice?.id),
    practiceName: CheckStringEmpty(data?.practice?.name),
    practiceLocationId: CheckNumber(data?.id),
    practiceLocationName: CheckStringEmpty(data?.location),
    practiceLocationTimezone: CheckStringEmpty(data?.timezone),
  };
};

const PatientAuditLog: (data: any) => PatientAuditType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    const name = CheckStringEmpty(el?.requestedByName);
    const des = CheckStringEmpty(el?.requestedByDesignation);
    const actionBy = name ? name + `${des ? ", " + des : ""}` : "";
    return {
      id: CheckNumber(el?.id),
      actionType: CheckStringEmpty(el?.actionType),
      actionBy,
      description: CheckStringEmpty(el?.description),
      datetime: DateTime.ValidDate(el?.createdAt),
    };
  });
};

const ListPatientInfo1: (data: any) => ListPatientInfoType = (data) => {
  const firstName = CheckStringEmpty(data?.firstName);
  const middleName = CheckStringEmpty(data?.middleName);
  const lastName = CheckStringEmpty(data?.lastName);
  const name =
    (firstName ? `${firstName} ` : "") +
    (middleName ? `${middleName} ` : "") +
    (lastName ? lastName : "");
  return {
    img: "",
    name: name.trim(),
    dob: DateTime.ValidDate(data?.dob),
    contact1: CheckStringEmpty(data?.home),
    contact2: CheckStringEmpty(data?.mobile),
    practiceId: CheckNumber(data?.organizationId),
    practice: CheckStringEmpty(data?.organizationName),
    locationId: CheckNumber(data?.practiceLocationId),
    location: CheckStringEmpty(data?.location),
    timezone: CheckStringEmpty(data?.timezone),
  };
};

const CallLogList: (
  data: any,
  callResultList: DropdownArrPropsType[]
) => CallLogListType[] = (data, callResultList) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    const find = callResultList.find(
      (dropEl) => dropEl.value == el?.callResult
    );
    return {
      id: CheckNumber(el?.id),
      callername: CheckStringEmpty(el?.userName),
      datetime: DateTime.ValidDate(el?.createdAt),
      callresult: CheckStringEmpty(find ? find.label : el?.callResult),
      nextcall: DateTime.ValidDate(el?.nextCallDate),
      note: CheckStringEmpty(el?.notes),
    };
  });
};

const ConsentHistoryList: (data: any) => ConsentHistoryListType[] = (data) => {
  const newData = CheckArr(data);
  const newArr: ConsentHistoryListType[] =
    newData.map((userEl, userIndex) => {
      const newPrgmArr = CheckArr(userEl?.listConcet);
      const newPrgmArr1: ConsentPrgmType[] = newPrgmArr.map(
        (prgmEl: any, prgmIndex: number) => {
          return {
            id: prgmIndex + 1,
            programId: CheckNumber(prgmEl?.programId),
            program: CheckStringEmpty(prgmEl?.abbrevation),
            consent: CheckStringEmpty(prgmEl?.concent),
            doe: DateTime.ValidDate(prgmEl?.enrolmentDate),
            typeconsent: CheckStringEmpty(prgmEl?.concentType),
            cpcreated: CheckStringEmpty(prgmEl?.careplanCreatedInEhr),
            cpinitiation: DateTime.ValidDate(prgmEl?.careplanInitDateInEhr),
            reason: CheckStringEmpty(prgmEl?.concentReason),
          };
        }
      );
      return {
        id: userIndex + 1,
        userName: CheckStringEmpty(userEl?.userName),
        programArr: newPrgmArr1,
      };
    }) || [];
  return newArr;
};

const DischargeSummary: (data: any) => DischargeSummaryType = (data) => {
  return {
    facility_name: CheckStringEmpty(data?.facilityName),
    initialAttemptDate: DateTime.ValidDate(data?.initialAttemptDate),
    formOfContact: CheckStringEmpty(data?.formOfContact),
    dateOfContact: DateTime.ValidDate(data?.dateOfContact),
    tcmAppointment: DateTime.ValidDate(data?.tcmAppointment),
    dateOfAdmission: DateTime.ValidDate(data?.dateOfAdmission),
    dateOfDischarge: DateTime.ValidDate(data?.dateOfDischarge),
    medicalDecisionMaking: CheckStringEmpty(data?.medicalDecisionMaking),
    primaryDiagnosis: CheckStringEmpty(data?.primaryDiagnosis),
    secondaryDiagnosis: CheckStringEmpty(data?.secondaryDiagnosis),
    tertiaryDiagnosis: CheckStringEmpty(data?.tertiaryDiagnosis),
    dischargeDisposition: CheckStringEmpty(data?.dischargeDisposition),
    reasonForHospitalization: CheckStringEmpty(data?.reasonForHospitalization),
    notes: CheckStringEmpty(data?.notes),
  };
};

const DischargeSummaryForm: (
  data: DischargeSummaryType,
  formArr: FormDataType[]
) => FormDataType[] = (data, formArr) => {
  formArr[0].value = data.facility_name;
  formArr[1].value = DateTime.ToLocalDate(data.initialAttemptDate);
  formArr[2].value = data.formOfContact;
  formArr[3].value = DateTime.ToLocalDate(data.dateOfContact);
  formArr[4].value = DateTime.ToLocalDate(data.tcmAppointment);
  formArr[5].value = DateTime.ToLocalDate(data.dateOfAdmission);
  formArr[6].value = DateTime.ToLocalDate(data.dateOfDischarge);
  formArr[7].dropValue = data.medicalDecisionMaking;
  formArr[8].value = data.primaryDiagnosis;
  formArr[9].value = data.secondaryDiagnosis;
  formArr[10].value = data.tertiaryDiagnosis;
  formArr[11].value = data.dischargeDisposition;
  formArr[12].value = data.reasonForHospitalization;
  formArr[13].value = data.notes;
  return formArr;
};

const DischargeSummaryPayload: (
  data: FormDataType[]
) => DischargeSummaryType = (data) => {
  const attemptDate = DateTime.ToUTCDate(data[1]?.value);
  const contactDate = DateTime.ToUTCDate(data[3]?.value);
  const appointDate = DateTime.ToUTCDate(data[4]?.value);
  const addDate = DateTime.ToUTCDate(data[5]?.value);
  const dischargeDate = DateTime.ToUTCDate(data[6]?.value);
  return {
    facility_name: CheckStringEmpty(data[0]?.value),
    initialAttemptDate: attemptDate ? attemptDate + "T00:00" : "",
    formOfContact: CheckStringEmpty(data[2]?.value),
    dateOfContact: contactDate ? contactDate + "T00:00" : "",
    tcmAppointment: appointDate ? appointDate + "T00:00" : "",
    dateOfAdmission: addDate ? addDate + "T00:00" : "",
    dateOfDischarge: dischargeDate ? dischargeDate + "T00:00" : "",
    medicalDecisionMaking: CheckStringEmpty(data[7]?.dropValue),
    primaryDiagnosis: CheckStringEmpty(data[8]?.value),
    secondaryDiagnosis: CheckStringEmpty(data[9]?.value),
    tertiaryDiagnosis: CheckStringEmpty(data[10]?.value),
    dischargeDisposition: CheckStringEmpty(data[11]?.value),
    reasonForHospitalization: CheckStringEmpty(data[12]?.value),
    notes: CheckStringEmpty(data[13]?.value),
  };
};

const LoggedTimeList: (data: any) => LoggedTimeType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    const startdatetime = DateTime.ValidDate(el.startDateTime);
    const enddatetime = DateTime.ValidDate(el.endDateTime);
    return {
      activity: CheckStringEmpty(el.reasons),
      activitytype: CheckStringEmpty(el.type),
      duration: DateTime.CalculateDuration(
        startdatetime,
        enddatetime
      ).toString(),
      enddatetime,
      id: CheckNumber(el.id),
      note: CheckStringEmpty(el.notes),
      interactiveCommunication: CheckBoolean(el?.interactiveCommunicationDone),
      programId: CheckNumber(el?.programId),
      startdatetime,
    };
  });
};

const FormatDeviceReading: (res: any) => DeviceReadingType[] = (res) => {
  const CheckedArr = CheckArr(res);
  if (CheckedArr.length < 1) return [];
  return FormatDeviceReadingUOM(CheckedArr[0]?.readings);
};

const FormatDeviceReadingUOM: (arr: any) => DeviceReadingType[] = (arr) => {
  return CheckArr(arr).map((el, index) => {
    return { ...FormatDeviceReadingUOMObj(el), readingId: index + 1 };
  });
};

const FormatDeviceReadingUOMObj: (data: any) => DeviceReadingType = (data) => {
  return {
    measureUomAbbreviation: CheckStringEmpty(data?.abbreviation),
    measureUomId: CheckStringEmpty(data?.measureUomId),
    measureUomDescription: CheckStringEmpty(data?.measureUomDescription),
    readingId: null,
    readingType: CheckStringEmpty(data?.readingType),
    readingTypeDescription: CheckStringEmpty(data?.readingTypeUomDescription),
    recordedTime: DateTime.ValidDate(data?.recordedTime),
    value: CheckStringEmpty(data?.value),
  };
};

export default {
  ///CheckedFunctions
  EligiblePrgmResObj,
  CheckEligiblePrgmRes,
  // FormatEligiblePrgmRes,
  // FormatEligiblePrgmObj,
  // AppointEligiblePrgmRes,
  GetCPStage,
  CheckQualitySearch,
  MobileNumber,
  StateRouteSwitch,
  FormatePatientInfo,
  FormatPatientOrg,
  PatientAuditLog,
  ListPatientInfo1,
  CallLogList,
  ConsentHistoryList,
  DischargeSummary,
  DischargeSummaryForm,
  DischargeSummaryPayload,
  LoggedTimeList,
  CheckPatientDevice,
  CheckPatientDeviceDetails,
  CheckPatientDeviceTraining,
  FormatDeviceReading,
  FormatDeviceReadingUOM,
  FormatDeviceReadingUOMObj,
  ActivityTimeLogRes,
};
