import { TableRow, TableCell } from "@mui/material";
import { ServiceBillingType } from "DataTypes/Services/Patient.type";
import { TableBillingBodyType } from "DataTypes/Table";
import SwitchCase from "Utils/SwitchCase";
import { FC, Fragment } from "react";

const TblBillingBody: FC<TableBillingBodyType> = (
  props: TableBillingBodyType
) => {
  const { tableColumn, tableRow, tableAction, searchText, downloadPermission } =
    props;

  return (
    <Fragment>
      {tableRow.map((rowData) => {
        const actionData = tableAction && tableAction(rowData);
        const Col2 = tableColumn(rowData).slice(
          downloadPermission ? -13 : -12,
          -10
        );
        const Col3 = tableColumn(rowData).slice(-10);
        return (
          <Fragment key={rowData.id}>
            <TableRow sx={{ ...sxTblRow }}>
              {Col2.map((colEl) => {
                const val = JSON.parse(JSON.stringify(rowData))[
                  colEl.id as keyof any
                ];
                return (
                  <TableCell
                    rowSpan={rowData.child.length + 1}
                    key={colEl.id}
                    align={colEl.align}
                    width={colEl.width}
                  >
                    {SwitchCase.RowSwitchCase(
                      colEl,
                      rowData,
                      val,
                      actionData,
                      searchText
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
            {rowData?.child.map((child: ServiceBillingType) => {
              return (
                <TableRow key={child.id} sx={{ ...sxTblRow }}>
                  {Col3.map((colEl) => {
                    const childVal = JSON.parse(JSON.stringify(child))[
                      colEl.id as keyof any
                    ];
                    return (
                      <TableCell
                        key={colEl.id}
                        align={colEl.align}
                        width={colEl.width}
                      >
                        {SwitchCase.RowSwitchCase(
                          colEl,
                          rowData,
                          childVal,
                          actionData,
                          searchText
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </Fragment>
        );
      })}
    </Fragment>
  );
};
export default TblBillingBody;

const sxTblRow = {
  zIndex: -1,
  "& >td": {
    zIndex: -1,
    padding: 0,
    p: 1,
  },
};
