import AppointmentModal from "./AddAppointment";
import AddCategoryModal from "./AddCategory";
import RedoxSettingsModal from "./RedoxSettingsModel";
import AddModel from "./AddModel";
import AssignPatient from "./AssignPatient";
import LogTimeModal from "./LogTimeModal";
import ThresholdsModal from "./PatientThresholdsModal";
import ProgramConsentModal from "./ProgramConsentModal";
import StaffInactiveModal from "./StaffInactiveModal";
import UnenrollConsent from "./UnenrollConsent";
import VerifyOtp from "./VerifyOtp";
import UploadFileModal from "./UploadFile";
import Task from "../../Pages/Home/HeadScreen/PatientMgmt/Task/index";
import AppSettingsModal from "./AppSettings";
import DeviceTask from "Pages/Home/HeadScreen/PatientMgmt/DeviceTask";
import TrainingNotesModal from "./TrainingNotesModal";
import ClinicalNotesModal from "./ClinicalNotesModal";
import SmartmeterSettingsModel from "./SmartmeterSettingsModel";

export default {
  AppointmentModal,
  AddCategoryModal,
  AddModel,
  VerifyOtp,
  AssignPatient,
  LogTimeModal,
  Task,
  DeviceTask,
  RedoxSettingsModal,
  UnenrollConsent,
  StaffInactiveModal,
  ThresholdsModal,
  ProgramConsentModal,
  UploadFileModal,
  AppSettingsModal,
  TrainingNotesModal,
  ClinicalNotesModal,
  SmartmeterSettingsModel,
};
