import { FC, useState } from "react";
import ControlledAccord from "./Controlled";
import { UncontrolledCstmAccordProps } from "DataTypes/Common";

const UncontrolledAccord: FC<UncontrolledCstmAccordProps> = (props) => {
  const { head, body, handleClick, accordianOpen } = props;
  const [open, setOpen] = useState(false);

  return (
    <ControlledAccord
      head={head}
      body={body}
      open={accordianOpen}
      handleClick={() => {
        setOpen(open ? false : true);
        handleClick && handleClick();
      }}
    />
  );
};
export default UncontrolledAccord;
