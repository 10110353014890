import { Fragment, FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, Loader } from "Components";
import PatientApi from "Service/Patient.api";
import NoData from "Components/NoData";
import { SideModalProps } from "DataTypes/Modal";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { RedoxVitalType } from "DataTypes/Services/Patient.type";
import RedoxFormatter from "Utils/Redox.Formatter";
import DateTime from "Utils/DateTime";

const Vital: FC<SideModalProps> = () => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const newPaitientId = patientInfo.id;
  const [vitalData, setVitalData] = useState<RedoxVitalType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchVitals();
  }, []);

  const fetchVitals = async () => {
    const res: any = await PatientApi.RedoxVitals({ id1: newPaitientId });
    if (res?.success) {
      setVitalData(RedoxFormatter.VitalList(res?.data?.vitals));
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <Fragment>
      {vitalData.length ? (
        vitalData.map((el) => {
          return (
            <Fragment key={el.id}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                • {DateTime.ToLocalDate(el.dateTime)}
              </Typography>
              <Box
                sx={{
                  ...CommonStyle.sxRow,
                  mb: 1.5,
                  "& >h6": { mr: 3 },
                }}
              >
                <Typography variant="subtitle1">
                  {el.name} - {el.value}
                </Typography>
              </Box>
            </Fragment>
          );
        })
      ) : (
        <NoData />
      )}
    </Fragment>
  );
};
export default Vital;
