import {
  MasterInitialStateType,
  OrganizationInitialStateType,
} from "DataTypes/Redux";
import {
  CLEAR_MASTER,
  CLEAR_HEADER,
  ADD_ORG,
  CLEAR_ORG,
  UPDATE_MASTER,
} from "./type";

export const UpdateMaster: (payload: MasterInitialStateType) => {
  type: string;
  payload: MasterInitialStateType;
} = (payload) => {
  return {
    type: UPDATE_MASTER,
    payload,
  };
};

export const clearMaster: () => {
  type: string;
} = () => {
  return {
    type: CLEAR_MASTER,
  };
};

export const addOrg: (data: OrganizationInitialStateType) => {
  type: string;
  payload: OrganizationInitialStateType;
} = (data) => {
  return {
    type: ADD_ORG,
    payload: data,
  };
};

export const clearOrg: () => {
  type: string;
} = () => {
  return {
    type: CLEAR_ORG,
  };
};

export const clearHeader: () => {
  type: string;
} = () => {
  return {
    type: CLEAR_HEADER,
  };
};
