import { post } from ".";
import { fetchAssociatedPrgApiDto } from "DataTypes/Services/Insurance.type";
import { ApiServiceProps } from "DataTypes/Services/Index.type";

const saveUpdate: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "insurance/save-update",
    data: { data: payload.data },
  };
  return post(request);
};

const read: (data: fetchAssociatedPrgApiDto) => any = (data) => {
  const request = {
    subUrl: "insurance/read",
    data,
  };
  return post(request);
};

const list: () => any = () => {
  const request = {
    subUrl: "insurance/search",
  };
  return post(request);
};

export default { saveUpdate, read, list };
