import { ControlledForm } from "Components";
import BreadUrls from "Constant/BreadUrls";
import { AnsDropArr } from "Constant/DropArr";
import { BreadArrType } from "DataTypes/Common";
import { FormDataType } from "DataTypes/Form";

export const AddQuestionBreadArr = (category: string, edit: boolean) => {
  const Arr: BreadArrType[] = [
    {
      id: 1,
      label: "Questionnaire Master",
      path: BreadUrls.MasterQuesList(),
    },
    {
      id: 2,
      label: edit
        ? `Update Question: ${category}`
        : `Add Question: ${category}`,
      path: "",
    },
  ];
  return Arr;
};

export const Arr: () => FormDataType[] = () => {
  return [
    ControlledForm.FormData.Question(),
    ControlledForm.FormData.AnswerType({ dropArr: AnsDropArr }),
  ];
};

export const Arr1: () => FormDataType[] = () => {
  return [
    ControlledForm.FormData.ProgramData({
      required: true,
      disableSelectAll: true,
      width: "40%",
    }),
    ControlledForm.FormData.ProblemChip({ required: false }),
    ControlledForm.FormData.VisitChip({ required: false }),
  ];
};

export const FormArr1: FormDataType[] = [
  ControlledForm.FormData.Question(),
  ControlledForm.FormData.AnswerType({ dropArr: AnsDropArr }),
];

export const FormArr2: FormDataType[] = [
  ControlledForm.FormData.ProgramData({
    required: true,
    disableSelectAll: true,
    width: "40%",
  }),
  ControlledForm.FormData.ProblemChip({ required: false }),
  ControlledForm.FormData.VisitChip({ required: false }),
];

export const SampleInputObject: (
  value: string,
  readOnly: boolean
) => FormDataType = (value, readOnly) => {
  return {
    id: "option",
    label: "",
    placeholder: "Enter Option",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "option",
    readOnly: readOnly,
    required: true,
    value: value,
  };
};
