import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, Loader } from "Components";
import ImgPath from "Constant/Imgs";
import { CategoryBoxProps } from "DataTypes/Master";
import NoData from "Components/NoData";

const CategoryBox: FC<CategoryBoxProps> = (props) => {
  const { categoryArr, catLoading, selectedCat, onClick } = props;
  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
      <Box height="10%" sx={{ ...sxCategoryHead, ...CommonStyle.sxRow, px: 2 }}>
        <Typography variant="subtitle1">{"Category Name"}</Typography>
        <Typography variant="subtitle1">Questions</Typography>
      </Box>
      <Box height="90%" overflow="auto" sx={{ px: 2 }}>
        {catLoading ? (
          <Loader />
        ) : categoryArr.length > 0 ? (
          categoryArr.map((el) => {
            return (
              <Box
                key={el.id}
                sx={{ ...CommonStyle.sxRow, ...sxCategoryBox }}
                onClick={() => onClick(el.id)}
              >
                <Box sx={{ ...CommonStyle.sxRow, wordBreak: "break-all" }}>
                  <Box sx={{ ...CommonStyle.sxRoundIconsMainSm }}>
                    {selectedCat?.id == el.id ? (
                      <ImgPath.MinusIcon />
                    ) : (
                      <ImgPath.PlusIcon />
                    )}
                  </Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      ml: 1,
                      ...(selectedCat?.id == el.id && sxSelected),
                    }}
                  >
                    {el.name}
                  </Typography>
                </Box>
                <Typography variant="subtitle1">{el.questioncount}</Typography>
              </Box>
            );
          })
        ) : (
          <NoData />
        )}
      </Box>
    </Box>
  );
};
export default CategoryBox;

const sxCategoryHead = {
  "& >h6:first-of-type": {
    width: "80%",
    fontWeight: "bold",
  },
  "& >h6:last-of-type": {
    width: "20%",
    fontWeight: "bold",
  },
};

const sxCategoryBox = {
  borderBottom: "0.5px solid",
  borderColor: "custom.main",
  cursor: "pointer",
  py: 1,
  "& >div:first-of-type": {
    width: "80%",
    ":hover": {
      "& >div": {
        backgroundColor: "roundiconbghover.main",
      },
      "& >h6": {
        color: "textHover.main",
      },
    },
  },
  "& >h6:first-of-type": {
    width: "20%",
    textAlign: "center",
  },
};

const sxSelected = {
  fontWeight: "bold",
};
