import { OngoingType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const OngoingList: (data: any) => OngoingType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: OngoingType[] = CheckedArr.map((el, index) => {
    const provider = CheckStringEmpty(el?.teamProviderName);
    const providerDes = CheckStringEmpty(el?.teamProviderDesignation);
    const staff = CheckStringEmpty(el?.clinicalStaff);
    const staffDes = CheckStringEmpty(el?.clinicalStaffDesignation);
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      carecoordination: CheckStringEmpty(el?.careCoordination),
      careplanId: CheckNumber(el?.careplanId),
      careplanRecurringId: CheckNumber(el?.recurringCareplanId),
      careplanRecurringState: CheckStringEmpty(el?.recurringCareplanState),
      careplanState: CheckStringEmpty(el?.careplanState),
      clinicalStaff: staff ? `${staff}${staffDes ? ", " + staffDes : ""}` : "",
      cpinitiation: DateTime.ValidDate(el?.careplanInitDate),
      id: index,
      importance: CheckStringEmpty(el?.importance),
      patientId: CheckStringId(el?.id),
      primaryins: CheckStringEmpty(el?.primaryInsurance),
      provider: provider
        ? `${provider}${providerDes ? ", " + providerDes : ""}`
        : "",
      providerId: CheckNumber(el?.teamProviderId),
      secondaryins: CheckStringEmpty(el?.secondaryInsurance),
    };
  });
  return newArr;
};

export default { OngoingList };
