///Bug resolve azure-400

import { useState, useEffect, Fragment, FC } from "react";
import { Box, Grid } from "@mui/material";
import { FormDataType } from "DataTypes/Form";
import {
  CPSummaryV3Type,
  TCMSummaryV2Type,
} from "DataTypes/Services/Careplan.type";
import CarePlanApi from "Service/CarePlan.api";
import CPFormatter from "Utils/CP.Formatter";
import { Arr } from "./Data";
import { commonFormSwitch } from "Utils/SwitchCases";
import Loader from "Components/Loader";
import DateTime from "Utils/DateTime";
import { ListInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import SummaryView from "Components/Summary";
import TCMSummaryView from "Components/Summary/TCM";
import { CMTabSelectedEnrollmentType } from "DataTypes/Modal";
import ImgPath from "Constant/Imgs";
import PatientApi from "Service/Patient.api";
import { useNavigate, useParams } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import CstmIconBtn from "Components/CstmIconBtn";
import { useDispatch } from "react-redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import PatientFormatter from "Utils/Patient.Formatter";
import { CheckNumber, CheckStringEmpty } from "Utils/common";
import { ReadOnlyType } from "DataTypes/PatientManagement.type";
import Stages from "Constant/Stages";

const CarePlan: FC<CMTabSelectedEnrollmentType> = ({
  prgmDetails,
  onClose,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { frequencyList, rpmFrequencyList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { careplanId, abbrevation, isCareplanComprehensive } = prgmDetails;
  const [formData, setFormData] = useState<FormDataType[]>(Arr);
  const [loading, setLoading] = useState(true);
  const [summaryV3, setSummaryV3] = useState<CPSummaryV3Type[]>([]);
  const [tcmV2, setTCMV2] = useState<TCMSummaryV2Type | null>(null);

  useEffect(() => {
    handleReadApiCalls();
  }, []);

  const handleReadApiCalls: () => void = async () => {
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formData));
    const searchRes = await CarePlanApi.search({ id1: careplanId });
    if (searchRes?.success) {
      const FormatedRes = CPFormatter.CheckCPSearch(searchRes?.data);
      newFormData[0].value = FormatedRes.carePlanAdditionalNotes;
      newFormData[1].value = newFormData[1].dropValue =
        FormatedRes.careplan_frequency;
      newFormData[2].value = DateTime.UTCToLocalDateTime(
        FormatedRes.careplanCompletionDate
      );
    }
    if (abbrevation == "TCM") {
      //TCM summary Implementation
      const TcmSearchRes = await CarePlanApi.TCMSummaryV2({
        id1: careplanId,
      });
      if (TcmSearchRes?.success)
        setTCMV2(CPFormatter.TCMV2Summary(TcmSearchRes?.data));
    } else {
      const sumRes = await CarePlanApi.CPSummaryV3({ id1: careplanId });
      if (sumRes?.success) setSummaryV3(CPFormatter.CPV3Summary(sumRes?.data));
    }
    newFormData[1].dropArr =
      abbrevation == "RPM" ? rpmFrequencyList : frequencyList;
    newFormData[0].readOnly =
      newFormData[1].readOnly =
      newFormData[2].readOnly =
        true;
    if (isCareplanComprehensive) newFormData[0].label = "Message";
    setFormData(newFormData);
    setLoading(false);
  };

  const ComprehensiveUI = (
    <Grid container spacing={2}>
      {formData.map((formEl: FormDataType) => {
        return (
          <Grid key={formEl.id} item sm={4}>
            {commonFormSwitch({ formObj: formEl })}
          </Grid>
        );
      })}
    </Grid>
  );

  const handleRevisionInitiate: (type: string) => void = async (type) => {
    setLoading(true);
    const payload = {
      id1: careplanId,
    };
    const res: any = await PatientApi.RevisionInitiate(payload);
    if (res?.success) {
      const careplanstate = CheckStringEmpty(res?.data?.carePlanState) || "";
      const careId = CheckNumber(res?.data?.id) || null;
      const callDefaultRoute = `../${
        RouteUrls.revisionPlan
      }/${careId}/${PatientFormatter.StateRouteSwitch(careplanstate)}`;
      dispatch(
        addSelectedPrgm({
          ...selectedPrgmInitialState,
          ...prgmDetails,
          careplanRecurringId: null,
          careplanType: "REVISION",
          careplanState: careplanstate,
          showLogTime: true,
          callDefaultRoute: callDefaultRoute,
          showSaveBtn: true,
          showSubmitBtn: true,
          careplanId: careId,
          careplanStage: PatientFormatter.GetCPStage(careplanstate),
          caremanagerId: prgmDetails.caremanagerId,
          billableproviderId: prgmDetails.billableproviderId,
          providerId: prgmDetails.providerId,
          readOnlyArr: ["team", "editDevice"],
          ...(myInfo.roleId == 3 &&
          PatientFormatter.GetCPStage(careplanstate) > 7
            ? { showSubmitBtn: false }
            : {}),
          teamInitiatedBy: myInfo.id,
          teamInitiatorRoleId: myInfo.roleId,
        })
      );

      if (type === "start") {
        navigate(
          `../${RouteUrls.patient}/${patientId}/${
            RouteUrls.revisionPlan
          }/${careId}/${PatientFormatter.StateRouteSwitch(careplanstate)}`
        );
      } else {
        navigate(`../${RouteUrls.patient}/${patientId}/${RouteUrls.callLog}`);
      }
      onClose && onClose();
    }
    setLoading(false);
  };

  const handleEditCarePlan: () => void = () => {
    onClose && onClose();
    const defaultArr: ReadOnlyType[] = [
      "uptoDate",
      "problem",
      "question",
      "team",
      "interactive",
      "medically",
    ];
    prgmDetails.careplanState == Stages.Recurring &&
      defaultArr.push("addNotes", "addDevice", "editDevice");
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...prgmDetails,
        readOnlyArr: defaultArr,
        showSaveBtn: true,
        ...(prgmDetails.abbrevation == "RPM" &&
        PatientFormatter.GetCPStage(Stages.Recurring) >
          prgmDetails.careplanStage
          ? { showLogTime: true }
          : {}),
      })
    );
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${patientId}/${
        prgmDetails.abbrevation == "RPM"
          ? RouteUrls.treatmentPlan
          : RouteUrls.carePlan
      }/${prgmDetails.careplanId}/${RouteUrls.careSummary}`
    );
  };

  const NonComprehensiveUI = (
    <Box display="flex" flexDirection="row" height="100%">
      <Box width="75%" height="100%" overflow="auto">
        {abbrevation == "TCM" ? (
          <TCMSummaryView summary={tcmV2} />
        ) : (
          <SummaryView summary={summaryV3} />
        )}
      </Box>

      <Box
        width="25%"
        height="100%"
        sx={{ pl: 1, "&>div:first-of-type": { mb: 1 } }}
      >
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          {abbrevation == "RPM" &&
            prgmDetails.status == "Enrolled" &&
            prgmDetails.careplanStage == 9 &&
            prgmDetails.seniorTeamMember && (
          //For RPM Program
            <Fragment>
              <CstmIconBtn
                icon={<ImgPath.CarePlanIcon />}
                onClick={() => handleRevisionInitiate("start")}
                title="Start Treatment Plan Revision"
                sxProps={{ mr: 1 }}
              />
              <CstmIconBtn
                icon={<ImgPath.CallIcon />}
                onClick={() => handleRevisionInitiate("call")}
                title="Call"
                sxProps={{ mr: 1 }}
              />
            </Fragment>
          )}
          {prgmDetails.abbrevation != "TCM" &&
            prgmDetails.status == "Enrolled" &&
            prgmDetails.seniorTeamMember && (
            <CstmIconBtn
              icon={<ImgPath.EditOutlined />}
              onClick={handleEditCarePlan}
              title="Edit"
              sxProps={{ mr: 1 }}
            />
          )}
        </Box>
        {formData.map((formEl) => {
          if (abbrevation == "TCM" && formEl.id == "call-freq") {
            return <></>;
          }
          return (
            <Fragment key={formEl.id}>
              {commonFormSwitch({ formObj: formEl })}
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );

  if (loading) return <Loader />;

  if (isCareplanComprehensive) return ComprehensiveUI;
  else return NonComprehensiveUI;
};
export default CarePlan;
