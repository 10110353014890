// #14143
import { Fragment, useEffect, useState, FC } from "react";
import { Box, Divider, IconButton, Link, Typography } from "@mui/material";
import { label } from "Constant/En";
import { CommonStyle, ControlledForm, Loader, Modal } from "Components";
import { ModalLayout } from "Layouts";
import { CommonButtonArray } from "Components/Modal/Data";
import { useNavigate } from "react-router-dom";
import ImgPath from "Constant/Imgs";
import PatientApi from "Service/Patient.api";
import ProgramApi from "Service/Program.api";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { useDispatch } from "react-redux";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { RouteUrls } from "Constant/RouteUrls";
import {
  addRPMPrgm,
  addSelectedDevice,
  addSelectedPrgm,
} from "Redux/Patient/Action";
import { columnArr } from "./Data";
import {
  ActionIdType,
  FormatEligiblePrgmType,
  PrgmDetailsType,
} from "DataTypes/PatientManagement.type";
import DateTime from "Utils/DateTime";
import AppointmentApi from "Service/Appointment.api";
import AppointmentFormatter from "Utils/Appointment.Formatter";
import Format from "./Format";
import { FormDataType } from "DataTypes/Form";
import {
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
  ValidateFormData,
} from "Utils/common";
import {
  AppointmentType,
  EligiblePrgmResType,
} from "DataTypes/Services/Patient.type";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import PatientFormatter from "Utils/Patient.Formatter";
import { FormatDropdownData } from "Utils/DataFormatter";
import CommonFormatter from "Utils/Common.Formatter";

const ProgramCard: FC = () => {
  const { patientInfo, selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openUnenrollModal, setOpenUnenrollModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [unenrollReason, setUnenrollReason] = useState<string | number>("");
  const [clickedElement, setClickedElement] =
    useState<FormatEligiblePrgmType | null>(null);
  const [dataArr, setDataArr] = useState<PrgmDetailsType[]>([]);
  const [editAppointment, setEditAppointment] =
    useState<AppointmentType | null>(null);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<FormDataType[]>([]);
  const [headHeight, setHeadHeight] = useState(20);
  const offsetHead = document?.getElementById("head")?.offsetHeight;
  const boxHeight = `calc(100% - ${headHeight}px - 16px)`;

  useEffect(() => {
    setHeadHeight(offsetHead || 20);
  }, [offsetHead]);

  useEffect(() => {
    setOpen(editAppointment ? true : false);
  }, [editAppointment]);

  useEffect(() => {
    fetchPatientProgramsData();
  }, []);

  const fetchPatientProgramsData: () => void = async () => {
    const res = await PatientApi.eligiblePrgmList({
      id1: CheckStringId(patientInfo.id),
    });
    if (res?.success) {
      const formattedRes = Format.PrgmDetails(
        res?.data,
        myInfo.roleId,
        myInfo.id
      );
      const rpmprgm = formattedRes.find(
        (prgmEL) => prgmEL.abbrevation == "RPM"
      );
      if (rpmprgm) {
        dispatch(
          addRPMPrgm({
            ...rpmprgm,
            consentGainedBy: null,
            showLogTime: false,
            enrollmentDate: "",
            readOnlyArr: [],
            callDefaultRoute: "",
            showSaveBtn: false,
            showSubmitBtn: false,
          })
        );
      }
      setDataArr([
        ...formattedRes.filter((prgmEL) => prgmEL.abbrevation != "RPM"),
        ...(rpmprgm ? [rpmprgm] : []),
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (clickedElement) setOpenUnenrollModal(true);
    else setOpenUnenrollModal(false);
  }, [clickedElement]);

  const handleUnenroll: (
    payloadPrgm: FormatEligiblePrgmType | null,
    reason: string | number
  ) => void = async (payloadPrgm, reason) => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const payload = {
      concent: "Unenrolled",
      unenrollmentReason: reason,
    };
    const res = await ProgramApi.UnenrollProgram({
      id1: CheckStringId(patientInfo.id),
      id2: payloadPrgm?.eligibleProgramId,
      data: payload,
    });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      fetchPatientProgramsData();
      dispatch(stopLoading());
    }
  };

  const handleEditAppointment: (id: number | null) => void = async (id) => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const readRes = await AppointmentApi.Read({ id1: id });
    if (readRes?.success) {
      const editData = AppointmentFormatter.ReadAppointmemt(readRes?.data);
      setEditAppointment(editData);
    }
    dispatch(stopLoading());
  };

  const handleClose: () => void = () => {
    setEditAppointment(null);
    setOpen(false);
  };

  const handleSave: () => void = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck) {
      const timezone = CheckStringEmpty(formData[6]?.dropValue);
      const dateTime = DateTime.ValidDate(formData[5]?.value);
      const payload = {
        patientId: formData[0]?.dropValue,
        appointmentType: formData[1]?.dropValue,
        providerId: selectedPrgm?.providerId,
        appointmentDate: timezone
          ? DateTime.AddHoursToUTCDateTime(
            DateTime.HoursTimeZoneSwitch(timezone),
            dateTime
          )
          : DateTime.ToUTCDateTime(dateTime),
        timeZone: timezone,
        notes: formData[7]?.value,
        status: "OPEN",
        careplanId: selectedPrgm?.careplanId,
        careplanRecurringId: selectedPrgm?.careplanRecurringId,
        patientDeviceId: editAppointment?.patientDeviceId || null,
      };
      const res: any = await AppointmentApi.Update({
        data: payload,
        id1: editAppointment?.id,
      });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        handleClose();
        fetchPatientProgramsData();
      }
    }
  };

  const dispatchPrgm: (prgm: PrgmDetailsType) => EligiblePrgmResType = (
    prgm
  ) => {
    const newObj = JSON.parse(JSON.stringify(prgm));
    delete newObj?.actionArr;
    const newObj1: EligiblePrgmResType = JSON.parse(JSON.stringify(newObj));
    return newObj1;
  };

  const handleViewCallLogs: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        readOnlyArr: ["call-log"],
      })
    );
    navigate(`../${RouteUrls.callLog}`);
  };

  const handleUnEnroll: (prgm: PrgmDetailsType) => void = (prgm) => {
    setClickedElement({
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
    });
  };

  const handleTcmEnroll: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
      })
    );
    navigate(`../${RouteUrls.dischargeSummary}`);
  };

  const handleViewDischarge: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
      })
    );
    navigate(`../${RouteUrls.dischargeSummary}`);
  };

  const handleTcmCall: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        callDefaultRoute: `../${RouteUrls.tcm}/${prgm.careplanId}` +
          `/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`,
        showLogTime: true,
        showSaveBtn: true,
        showSubmitBtn: true,
      })
    );
    navigate(`../${RouteUrls.callLog}`);
  };

  const handleViewTcm: (prgm: PrgmDetailsType) => void = (prgm) => {
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      readOnlyArr: ["question", "team", "addNotes", "frequency"],
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(
      `../${RouteUrls.tcm}/${prgm.careplanId
      }/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`
    );
  };

  const handleCareplanEnroll: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        callDefaultRoute: `../${RouteUrls.program}`,
      })
    );
    navigate(`../${RouteUrls.callLog}`);
  };

  const handleCareplanCall: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        callDefaultRoute: `../${RouteUrls.carePlan}/${prgm.careplanId}` +
          `/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`,
        showLogTime: true,
        showSaveBtn: true,
        showSubmitBtn: true,
      })
    );
    navigate(`../${RouteUrls.callLog}`);
  };

  const handleViewCareplan: (prgm: PrgmDetailsType) => void = (prgm) => {
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      readOnlyArr: [
        "uptoDate",
        "problem",
        "addDevice",
        "editDevice",
        "question",
        "team",
        "addNotes",
        "frequency",
        "interactive",
        "medically",
      ],
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(
      `../${RouteUrls.carePlan}/${prgm.careplanId
      }/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`
    );
  };

  const handleEditCareplan: (prgm: PrgmDetailsType) => void = (prgm) => {
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      ...(myInfo.roleId == 3 ? { showSaveBtn: true } : {}),
      ...(myInfo.roleId == 5 ? { showSubmitBtn: true } : {}),
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(
      `../${RouteUrls.carePlan}/${prgm.careplanId
      }/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`
    );
  };

  const handleTreatmentplanCall: (prgm: PrgmDetailsType) => void = (prgm) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        callDefaultRoute: `../${RouteUrls.treatmentPlan}/${prgm.careplanId}` +
          `/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`,
        showLogTime: true,
        showSaveBtn: true,
        showSubmitBtn: true,
      })
    );
    navigate(`../${RouteUrls.callLog}`);
  };

  const handleViewTreatmentplan: (prgm: PrgmDetailsType) => void = (prgm) => {
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      readOnlyArr: [
        "uptoDate",
        "problem",
        "addDevice",
        "editDevice",
        "question",
        "team",
        "addNotes",
        "frequency",
        "interactive",
        "medically",
      ],
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(
      `../${RouteUrls.treatmentPlan}/${prgm.careplanId
      }/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`
    );
  };

  const handleEditTreatmentplan: (prgm: PrgmDetailsType) => void = (prgm) => {
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      ...(myInfo.roleId == 3 ? { showSaveBtn: true, showLogTime: true } : {}),
      ...(myInfo.roleId == 5 ? { showSubmitBtn: true, showLogTime: true } : {}),
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(
      `../${RouteUrls.treatmentPlan}/${prgm.careplanId
      }/${PatientFormatter.StateRouteSwitch(prgm.careplanState)}`
    );
  };

  const handleRecurringCall: (prgm: PrgmDetailsType) => void = (prgm) => {
    const pgrmName = prgm.abbrevation;
    const route = `../${CommonFormatter.HandleParentRoute({
      prgm: pgrmName,
      isRec: true,
      recId: prgm.careplanRecurringId,
      careId: prgm.careplanId,
    })}/${prgm.abbrevation == "RPM"
      ? RouteUrls.rpmrecurring
      : RouteUrls.careQuestion
    }`;
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        callDefaultRoute: route,
        careplanRecurringStatus: "not-completed",
      })
    );
    if (pgrmName == "CCM" || pgrmName == "PCM" || pgrmName == "BHI") {
      const appointYear = parseInt(DateTime.GetYear(prgm.appointment_date));
      const currentYear = parseInt(DateTime.CurrentYear());
      const appointMonth = parseInt(DateTime.GetMonth(prgm.appointment_date));
      const currentMonth = parseInt(DateTime.CurrentMonth());
      if ((appointYear < currentYear) ||
        ((appointYear == currentYear) && (!(appointMonth > currentMonth)))) {
        navigate(`../${RouteUrls.callLog}`);
      } else {
        dispatch(setAlertData({
          openAlert: true,
          alertVariant: "warning",
          alertMessage: "The scheduled recurring month has not yet arrived."
        }))
      }
    } else navigate(`../${RouteUrls.callLog}`);
  };

  const handleViewRecurringplan: (prgm: PrgmDetailsType) => void = (prgm) => {
    const route = `../${CommonFormatter.HandleParentRoute({
      prgm: prgm.abbrevation,
      isRec: true,
      recId: prgm.careplanRecurringId,
      careId: prgm.careplanId,
    })}/${prgm.abbrevation == "RPM"
      ? RouteUrls.rpmrecurring
      : RouteUrls.careQuestion
    }`;
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      careplanRecurringStatus: "not-completed",
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(route);
  };

  const handleDeviceDetails: (prgm: PrgmDetailsType) => void = (prgm) => {
    const userId = myInfo.id;
    const isViewOnly =
      prgm.billableproviderId == userId ||
      prgm.caremanagerId == userId ||
      prgm.providerId == userId;
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      readOnlyArr: !isViewOnly ? ["addDevice", "editDevice"] : [],
      callDefaultRoute: `../${RouteUrls.rpmDeviceList}`,
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(`../${RouteUrls.rpmDeviceList}`);
  };

  const handleMonthlyReadings: (prgm: PrgmDetailsType) => void = (prgm) => {
    const newObj: FormatEligiblePrgmType = {
      ...selectedPrgmInitialState,
      ...dispatchPrgm(prgm),
      readOnlyArr: ["question", "addNotes", "frequency"],
    };
    dispatch(addSelectedPrgm(newObj));
    navigate(`../${RouteUrls.deviceReading}`);
  };

  const handleSelectedDevice: (prgm: PrgmDetailsType, val: any) => void = (
    prgm,
    val
  ) => {
    const newVal = CheckNumber(val);
    const getDevice = prgm.patientDevices.find(
      (device) => device.patientDeviceId == newVal
    );
    if (getDevice) {
      dispatch(addSelectedDevice({ ...getDevice }));
    }
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        ...dispatchPrgm(prgm),
        careplanRecurringStatus: "not-completed",
      })
    );
    navigate(
      `../${CommonFormatter.HandleParentRoute({
        prgm: prgm.abbrevation,
        isRec: prgm.careplanRecurringId ? true : false,
        careId: prgm.careplanId,
        recId: prgm.careplanRecurringId,
      })}/${prgm.careplanRecurringId
        ? RouteUrls.rpmrecurring
        : RouteUrls.deviceReading
      }?deviceTypeId=${getDevice?.deviceTypeId}`
    );
  };

  const IconSwitch: (
    actionId: ActionIdType,
    prgm: PrgmDetailsType
  ) => JSX.Element = (actionId, prgm) => {
    switch (actionId) {
      case "view-call-logs":
        return (
          <IconButton
            title={"View Call Logs"}
            onClick={() => handleViewCallLogs(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
      case "un-enroll":
        return (
          <IconButton title={"Unenroll"} onClick={() => handleUnEnroll(prgm)}>
            <ImgPath.UnenrollIcon />
          </IconButton>
        );
      case "tcm-enroll":
        return (
          <IconButton
            title={"Discharge Summary"}
            onClick={() => handleTcmEnroll(prgm)}
          >
            <ImgPath.EnrollIcon />
          </IconButton>
        );
      case "view-discharge":
        return (
          <IconButton
            title={"View Discharge Summary"}
            onClick={() => handleViewDischarge(prgm)}
          >
            <ImgPath.ViewCarePlan />
          </IconButton>
        );
      case "tcm-call":
        return (
          <IconButton
            title={"Call Details"}
            onClick={() => handleTcmCall(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
      case "view-tcm":
        return (
          <IconButton title={"View TCM"} onClick={() => handleViewTcm(prgm)}>
            <ImgPath.ViewCarePlan />
          </IconButton>
        );
      case "tcm-re-enroll":
        return (
          <IconButton
            title={"Reenroll TCM"}
            onClick={() => handleTcmEnroll(prgm)}
          >
            <ImgPath.ReenrollIcon />
          </IconButton>
        );
      case "enroll":
        return (
          <IconButton
            title={"Enroll"}
            onClick={() => handleCareplanEnroll(prgm)}
          >
            <ImgPath.EnrollIcon />
          </IconButton>
        );
      case "care-plan-call":
        return (
          <IconButton
            title={"Call Details"}
            onClick={() => handleCareplanCall(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
      case "view-care-plan":
        return (
          <IconButton
            title={"View Careplan"}
            onClick={() => handleViewCareplan(prgm)}
          >
            <ImgPath.ViewCarePlan />
          </IconButton>
        );
      case "edit-care-plan":
        return (
          <IconButton
            title={"Care Plan"}
            onClick={() => handleEditCareplan(prgm)}
          >
            <ImgPath.CarePlanIcon />
          </IconButton>
        );
      case "treatment-plan-call":
        return (
          <IconButton
            title={"Call Details"}
            onClick={() => handleTreatmentplanCall(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
      case "view-treatment-plan":
        return (
          <IconButton
            title={"View Treatmentplan"}
            onClick={() => handleViewTreatmentplan(prgm)}
          >
            <ImgPath.ViewCarePlan />
          </IconButton>
        );
      case "edit-treatment-plan":
        return (
          <IconButton
            title={"Treatment Plan"}
            onClick={() => handleEditTreatmentplan(prgm)}
          >
            <ImgPath.CarePlanIcon />
          </IconButton>
        );
      case "rpm-recurring-call":
        return (
          <IconButton
            title={"Call Details"}
            onClick={() => handleRecurringCall(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
      case "view-rpm-recurring-plan":
        return (
          <IconButton
            title={"View Recurring Plan"}
            onClick={() => handleViewRecurringplan(prgm)}
          >
            <ImgPath.ViewCarePlan />
          </IconButton>
        );
      case "re-enroll":
        return (
          <IconButton
            title={"Reenroll"}
            onClick={() => handleCareplanEnroll(prgm)}
          >
            <ImgPath.ReenrollIcon />
          </IconButton>
        );
      case "recurring-call":
        return (
          <IconButton
            title={"Call Details"}
            onClick={() => handleRecurringCall(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
      case "view-recurring-plan":
        return (
          <IconButton
            title={"View Recurring Plan"}
            onClick={() => handleViewRecurringplan(prgm)}
          >
            <ImgPath.ViewCarePlan />
          </IconButton>
        );
      default:
        return (
          <IconButton
            title={"Call Details"}
            onClick={() => handleCareplanEnroll(prgm)}
          >
            <ImgPath.CallLogsIcon />
          </IconButton>
        );
    }
  };

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
      <ModalLayout
        modwidth="55%"
        heading={"Edit Appointment"}
        content={
          <Modal.AppointmentModal
            setForm={(data) => setFormData(data)}
            editAppointment={editAppointment}
          />
        }
        open={open}
        onClose={handleClose}
        buttonArr={CommonButtonArray(handleSave, handleClose, "Save")}
      />
      <ModalLayout
        modwidth="520px"
        heading={"Unenroll"}
        content={
          <Modal.UnenrollConsent
            onReasonselect={(reason: string | number) => {
              setUnenrollReason(reason);
            }}
            programName={clickedElement?.abbrevation || ""}
            patientName={patientInfo.name}
          />
        }
        buttonArr={CommonButtonArray(
          () => {
            handleUnenroll(clickedElement, unenrollReason);
            setClickedElement(null);
            setUnenrollReason("");
          },
          () => {
            setClickedElement(null);
            setUnenrollReason("");
          },
          "Confirm",
          "",
          unenrollReason ? false : true
        )}
        open={openUnenrollModal}
        onClose={() => {
          setClickedElement(null);
          setUnenrollReason("");
        }}
      />
      {/* <Box p={2}> */}
      {/* <Typography variant="body2" fontWeight="bold" mb={1}>
          {label.prgmoverview}
        </Typography> */}
      {/* according to this task 10931  */}
      {/* <Typography variant="subtitle1" sx={{ mb: 1, mt: 1 }}>
          Note: Following are the status of the Programs Patient is eligible for
          :
        </Typography> */}
      <Box id="head" sx={{ ...sxHead, px: 2, pt: 2 }}>
        {columnArr.map((el) => {
          return (
            <Typography key={el.id} variant="subtitle1" width={el.width}>
              {el.label}
            </Typography>
          );
        })}
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Box height={boxHeight} overflow="auto" px={2}>
          {dataArr.map((el) => {
            const date = DateTime.UTCToLocalDateTime(el.appointment_date);
            return (
              <Fragment key={el.programId}>
                {el.abbrevation == "RPM" && <Divider sx={{ mb: 1 }} />}
                <Box sx={{ ...sxContainer }}>
                  <Typography variant="subtitle1" width={columnArr[0].width}>
                    {el.abbrevation}
                  </Typography>
                  <Typography variant="subtitle1" width={columnArr[1].width}>
                    {el.status}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    width={columnArr[2].width}
                    sx={{ "& >button": { ...sxIcons } }}
                  >
                    {el.actionArr.length
                      ? el.actionArr.map((el1) => {
                        return (
                          <Fragment key={el1}>{IconSwitch(el1, el)}</Fragment>
                        );
                      })
                      : label.naText}
                  </Typography>
                  <Typography variant="subtitle1" width={columnArr[3].width}>
                    {el.nextSchedule || label.naText}
                  </Typography>
                  <Box width={columnArr[4].width}>
                    {date && myInfo.roleId != 6 ? (
                      <Link
                        sx={{ cursor: "pointer" }}
                        variant="subtitle1"
                        onClick={() => handleEditAppointment(el.appointment_id)}
                      >
                        {date}
                      </Link>
                    ) : (
                      <Typography variant="subtitle1">
                        {label.naText}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {el.status == "Enrolled" &&
                  el.abbrevation == "RPM" &&
                  el.careplanStage > 4 && (
                  <Box
                    mb={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box width="30%">
                      <ControlledForm.SingleSelectDrop
                        id="device-reading"
                        placeholder="Select Device Type"
                        dropArr={FormatDropdownData(
                          el.patientDevices,
                          "patientDeviceId",
                          "deviceTypeDescription",
                          "patientDeviceId"
                        )}
                        onClick={(val) => handleSelectedDevice(el, val)}
                      />
                    </Box>
                    {myInfo.roleId !== 6 && (
                    //Trainer can not access device details
                      <Fragment>
                        <Link onClick={() => handleDeviceDetails(el)}>
                          <Typography
                            variant="subtitle1"
                            sx={{ cursor: "pointer" }}
                          >
                              Device Details
                          </Typography>
                        </Link>
                        <Link onClick={() => handleMonthlyReadings(el)}>
                          <Typography
                            variant="subtitle1"
                            sx={{ cursor: "pointer" }}
                          >
                              Monthly Readings
                          </Typography>
                        </Link>
                      </Fragment>
                    )}
                  </Box>
                )}
              </Fragment>
            );
          })}
        </Box>
      )}
      {/* </Box> */}
    </Box>
  );
};
export default ProgramCard;

const sxHead = {
  display: "flex",
  "& >h6": {
    fontWeight: "bold",
  },
};

const sxContainer = {
  display: "flex",
  mb: 1,
};

const sxIcons = {
  p: 0.5,
  mr: 0.5,
  backgroundColor: "roundiconbg.main",
  color: "common.white",
  ":hover": {
    backgroundColor: "roundiconbghover.main",
  },
  "& >svg": {
    width: 20,
    height: 20,
    "& path": {
      fill: "#fff",
    },
  },
};
