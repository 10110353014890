import {
  DeviceAvgReadings,
  DeviceDetailInfo,
  DeviceDetailReadings,
  DeviceReading,
  DeviceReadingInfo,
  DeviceTypeProblemType,
} from "DataTypes/Services/Problem.type";
import {
  CheckArr,
  CheckDeviceIdType,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "./common";
import {
  DeviceListType,
  DeviceTypeMappedApiProps,
  DeviceUOMType,
} from "DataTypes/Services/Device.type";
import { DeviceTypeType } from "DataTypes/Services/Settings.type";
import DateTime from "./DateTime";

const DeviceNameListRes: (data: any) => DeviceListType[] = (data) => {
  return CheckArr(data).map((checkEl) => {
    return DeviceNameObj(checkEl);
  });
};

const DeviceNameObj: (data: any) => DeviceListType = (data) => {
  return {
    id: CheckNumber(data?.id),
    deviceId: CheckNumber(data?.id),
    deviceName: CheckStringEmpty(data?.deviceName),
    supplierName: CheckStringEmpty(data?.supplierName),
    ...DeviceTypeObj(data),
  };
};

const DeviceTypeListRes: (data: any) => DeviceTypeType[] = (data) => {
  return CheckArr(data).map((checkEl) => {
    return DeviceTypeObj(checkEl);
  });
};

const DeviceTypeObj: (data: any) => DeviceTypeType = (data) => {
  return {
    deviceTypeId: CheckDeviceIdType(data?.deviceTypeId),
    deviceTypeDescription: CheckStringEmpty(data?.deviceTypeDescription),
  };
};

const MappedDeviceType: (data: any) => DeviceTypeMappedApiProps = (data) => {
  const id = CheckStringId(data?.deviceTypeId);
  const problems: DeviceTypeProblemType[] = Array.isArray(data?.problems)
    ? data?.problems.map((el: DeviceTypeProblemType) => {
      return {
        problemId: CheckNumber(el?.problemId),
        problemName: CheckStringEmpty(el?.problemName),
      };
    })
    : [];
  return {
    deviceTypeId: id,
    problems: problems,
  };
};

const DeviceReadingFormate: (data: any) => DeviceReadingInfo = (data) => {
  const totalCount = CheckNumber(data?.totalCount);
  const activeCount = CheckNumber(data?.activeCount);
  const avg = CheckStringEmpty(data?.avg);
  const readings: DeviceReading[] = Array.isArray(data?.readings)
    ? data?.readings.map((el: any) => {
      return {
        date: CheckStringEmpty(el?.recordedDate),
        deviceName: CheckStringEmpty(el?.descriptions),
        patientId: CheckStringId(el?.patientId) || null,
        readingTaken: CheckStringEmpty(el?.readingTaken),
      };
    })
    : [];
  return {
    totalCount: totalCount,
    activeCount: activeCount,
    avg: avg,
    readings: readings,
  };
};

const DeviceDetailsReadingFormate: (data: any) => DeviceDetailInfo[] = (
  data
) => {
  const deviceData: DeviceDetailInfo[] = Array.isArray(data)
    ? data?.map((el: DeviceDetailInfo) => {
      return {
        ...DeviceTypeObj(el),
        average: Array.isArray(el?.average)
          ? el?.average.map((subEl: DeviceAvgReadings) => {
            return {
              average: CheckStringEmpty(subEl?.average),
              readingType: CheckStringEmpty(subEl?.readingType),
              readingTypeUomDescription: CheckStringEmpty(
                subEl?.readingTypeUomDescription
              ),
            };
          })
          : [],
        readings: Array.isArray(el?.readings)
          ? el?.readings.map((subEl: DeviceDetailReadings) => {
            return {
              readingCategory: CheckStringEmpty(subEl?.readingCategory),
              readingType: CheckStringEmpty(subEl?.readingType),
              readingTypeUomId: CheckStringEmpty(subEl?.readingTypeUomId),
              readingTypeUomDescription: CheckStringEmpty(
                subEl?.readingTypeUomDescription
              ),
              readingTypeDescription: CheckStringEmpty(
                subEl?.readingTypeDescription
              ),
              measureUomId: CheckStringEmpty(subEl?.measureUomId),
              measureUomDescription: CheckStringEmpty(
                subEl?.measureUomDescription
              ),
              receivedTime: DateTime.ValidDate(
                CheckStringEmpty(subEl?.receivedTime)
              ),
              recordedTime: DateTime.ValidDate(
                CheckStringEmpty(subEl?.recordedTime)
              ),
              value: CheckStringEmpty(subEl?.value),
              timezoneOffset: CheckStringEmpty(subEl?.timezoneOffset),
              actualDeviceId: CheckStringEmpty(subEl?.actualDeviceId),
              patientDeviceId: CheckNumber(subEl?.patientDeviceId),
              deviceName: CheckStringEmpty(subEl?.deviceName),
              batteryPercentage: CheckNumber(subEl?.batteryPercentage),
              externalReadingId: CheckNumber(subEl?.externalReadingId),
              abbreviation: CheckStringEmpty(subEl?.abbreviation),
              measurementId: CheckNumber(subEl?.measurementId),
            };
          })
          : [],
      };
    })
    : [];
  return deviceData;
};

const DeviceUOMList: (data: any) => DeviceUOMType[] = (data) => {
  return CheckArr(data).map((el) => {
    return {
      deviceDefaultUnitsId: CheckNumber(el?.deviceDefaultUnitsId),
      deviceTypeId: CheckStringEmpty(el?.deviceTypeId),
      id: CheckNumber(el?.deviceDefaultUnitsId),
      measureUomAbbreviation: CheckStringEmpty(el?.measureUomAbbreviation),
      measureUomId: CheckStringEmpty(el?.measureUomId),
      measureUomDescription: CheckStringEmpty(el?.measureUomDescription),
      readingTypeAbbreviation: CheckStringEmpty(el?.readingTypeAbbreviation),
      readingTypeUomDescription: CheckStringEmpty(
        el?.readingTypeUomDescription
      ),
      readingTypeUomId: CheckStringEmpty(el?.readingTypeUomId),
      vendorId: CheckStringEmpty(el?.vendorId),
    };
  });
};

export default {
  DeviceNameListRes,
  DeviceTypeObj,
  DeviceNameObj,
  DeviceTypeListRes,
  MappedDeviceType,
  DeviceReadingFormate,
  DeviceDetailsReadingFormate,
  DeviceUOMList,
};
