import { RouteUrls } from "Constant/RouteUrls";
import { CareNavType } from "DataTypes/PatientManagement.type";

export const problem: CareNavType = {
  id: "patient-care-problems",
  label: "Problems",
  path: RouteUrls.careProblem,
  level: 5,
  link: RouteUrls.careProblem,
  stage: 3,
  navType: "patient",
};

export const device: CareNavType = {
  id: "patient-care-device",
  label: "Device",
  path: RouteUrls.rpmAddDevice,
  level: 6,
  link: RouteUrls.rpmAddDevice,
  stage: 4,
  navType: "patient",
};

export const question: CareNavType = {
  id: "patient-care-question",
  label: "Questionnaire",
  path: RouteUrls.careQuestion,
  level: 7,
  link: RouteUrls.careQuestion,
  stage: 5,
  navType: "patient",
};

export const team: CareNavType = {
  id: "patient-care-team",
  label: "Team",
  path: RouteUrls.careTeam,
  level: 8,
  link: RouteUrls.careTeam,
  stage: 6,
  navType: "patient",
};

export const summary: CareNavType = {
  id: "patient-care-summary",
  label: "Summary",
  path: RouteUrls.careSummary,
  level: 9,
  link: RouteUrls.careSummary,
  stage: 7,
  navType: "patient",
};

export const recurring: CareNavType = {
  id: "patient-recurring",
  label: "Recurring Plan",
  path: RouteUrls.careQuestion,
  level: 7,
  link: RouteUrls.careQuestion,
  stage: 5,
  navType: "patient",
};

export const deviceReading: CareNavType = {
  id: "device-Reading",
  label: "Device Chart & Reading",
  path: RouteUrls.deviceReading,
  level: 4,
  link: RouteUrls.deviceReading,
  stage: 1,
  navType: "patient",
};

export const rpmService: CareNavType = {
  id: "rpm-service",
  label: "RPM Services",
  path: RouteUrls.rpmrecurring,
  level: 4,
  link: RouteUrls.rpmrecurring,
  stage: 5,
  navType: "patient",
};

export const CPNavArr: CareNavType[] = [problem, question, team, summary];
export const CPNavComprehensive: CareNavType[] = [problem, team, summary];
export const CPRNavArr: CareNavType[] = [recurring];
export const TCMNavArr: CareNavType[] = [question, team, summary];
export const RPMNavArr: CareNavType[] = [
  problem,
  device,
  question,
  team,
  summary,
];
export const RPMRNavArr: CareNavType[] = [rpmService];
export const DeviceReadingNavArr: CareNavType[] = [deviceReading];
