import { AlertProps, AppContextType, ScreenLoadType } from "DataTypes/Common";
import { ReactNode, useState, createContext, FC } from "react";
// @types.todo.ts

interface Props {
  children: ReactNode;
}

export const AppContext = createContext<AppContextType | null>(null);

const AppProvider: FC<Props> = ({ children }) => {
  const [openErrModal, setOpenErrModal] = useState(false);
  const [screenLoad, setScreenLoad] = useState<ScreenLoadType | null>(null);
  const [alertData, setAlertData] = useState<AlertProps>({
    alertMessage: "",
    openAlert: false,
    alertVariant: "",
  });

  return (
    <AppContext.Provider
      value={{ openErrModal, setOpenErrModal, screenLoad, setScreenLoad, alertData, setAlertData }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
