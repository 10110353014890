import { EligibleType } from "DataTypes/Services/Patient.type";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  AscendSortByNumber,
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
  GetUniqueArr,
} from "Utils/common";

interface PrgmType {
  name: string;
  id: number | null;
}

const EligibleList: (data: any) => EligibleType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: EligibleType[] = CheckedArr.map((el, index) => {
    const allInsPrgm: PrgmType[] = [];
    const insuranceArr = CheckArr(el?.insuarance).map((el1) => {
      return {
        company: CheckStringEmpty(el1?.company),
        plan: CheckStringEmpty(el1?.plan),
        priority: CheckStringEmpty(el1?.priority),
        program: CheckArr(el1?.program).map((el2) => {
          const obj: PrgmType = {
            id: CheckNumber(el2?.program_id),
            name: CheckStringEmpty(el2?.abbrevation),
          };
          allInsPrgm.push(obj);
          return obj;
        }),
      };
    });
    AscendSortByNumber(allInsPrgm, "id");
    const newInsArr = allInsPrgm.map((el) => el.name);
    const primIns = insuranceArr.find((insEl) => insEl.priority == "Primary");
    const secIns = insuranceArr.find((insEl) => insEl.priority == "Secondary");
    const proFirst = CheckStringEmpty(el?.provider[0]?.firstName);
    const proLast = CheckStringEmpty(el?.provider[0]?.lastName);

    return {
      ...PatientFormatter.ListPatientInfo1(el),
      contact1: CheckStringEmpty(el?.houseNumber),
      id: index,
      location: CheckStringEmpty(el?.practiceLocation?.location),
      locationId: CheckNumber(el?.practiceLocation?.id),
      program: GetUniqueArr(newInsArr),
      patientId: CheckStringId(el?.id),
      practice: CheckStringEmpty(el?.practiceLocation?.practice?.name),
      practiceId: CheckNumber(el?.practiceLocation?.practice?.id),
      primaryins: primIns ? `${primIns.company} : ${primIns.plan}` : "",
      provider: `${proFirst} ${proLast}`.trim(),
      secondaryins: secIns ? `${secIns.company} : ${secIns.plan}` : "",
      timezone: CheckStringEmpty(el?.practiceLocation?.timezone),
    };
  });
  return newArr;
};

export default { EligibleList };
