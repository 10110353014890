import { useState, FC, Fragment, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Arr, Arr1, Arr2 } from "./Data";
import CstmBtn from "Components/CstmBtn";
import { CommonStyle, ControlledForm } from "Components";
import { ModalLayout } from "Layouts";
import { useNavigate } from "react-router-dom";
import { CommonButtonArray, SingleBtnArray } from "Components/Modal/Data";
import {
  ListInitialStateType,
  PatientInitialStateType,
  ScreenInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import CarePlanApi from "Service/CarePlan.api";
import {
  CheckNumber,
  CheckStringEmpty,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import { HandleChangeProps } from "DataTypes/Common";
import { RouteUrls } from "Constant/RouteUrls";
import CPFormatter from "Utils/CP.Formatter";
import {
  CPSearchType,
  CPSummaryV3Type,
  TCMSummaryV2Type,
} from "DataTypes/Services/Careplan.type";
import { commonFormSwitch } from "Utils/SwitchCases";
import { useDispatch } from "react-redux";
import {
  setAlertData,
  setRightHead,
  setTimerStartDate,
  setTimerStatus,
  startLoading,
  stopLoading,
} from "Redux/Screen/Action";
import DateTime from "Utils/DateTime";
import PatientApi from "Service/Patient.api";
import Stages from "Constant/Stages";
import SummaryView from "Components/Summary";
import ProgramApi from "Service/Program.api";
import BreadUrls from "Constant/BreadUrls";
import SettingsApi from "Service/Settings.api";
import TCMSummaryView from "Components/Summary/TCM";

const CarePlan: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    rightHead: { timerStatus, timerVal, timerStartDate },
  }: ScreenInitialStateType = useSelector((state: any) => state?.screen);
  const { frequencyList, rpmFrequencyList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const { selectedPrgm, patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const careId = selectedPrgm.careplanId;
  const recurringId = selectedPrgm.careplanRecurringId;
  const currentUrl = window.location.href;
  const isRecurring = currentUrl.includes(RouteUrls.recurring);
  const isTCM = currentUrl.includes(RouteUrls.tcm);
  const isRPM = selectedPrgm.abbrevation == "RPM" ? true : false;
  const readOnlyNotes = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "addNotes")
    : true;
  const readOnlyFreq = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "frequency")
    : true;
  const readOnlyInteractive = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "interactive")
    : true;
  const readOnlyMedically = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "medically")
    : true;
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState<FormDataType[]>(Arr);
  const [formData1, setFormData1] = useState<FormDataType[]>(Arr1);
  const [formData2, setFormData2] = useState<FormDataType[]>(Arr2);
  const [timeLogModal, setTimeLogModal] = useState(false);
  const [tcmV2, setTCMV2] = useState<TCMSummaryV2Type | null>(null);
  const [summaryV3, setSummaryV3] = useState<CPSummaryV3Type[]>([]);
  const [medModal, setMedModal] = useState(false);
  const [hideInteractive, setHideInteractive] = useState(true);
  let hideTime = 0;

  useEffect(() => {
    hideInteractive && handleHideInteractive();
  }, [timerVal]);

  const handleHideInteractive: () => void = async () => {
    if (hideTime == 0) {
      const payload = {
        id1: "careplan",
        data: "ui.rpm.careplan.interactive.communication.question.delay",
      };
      const res = await SettingsApi.SystemSettings(payload);
      if (res?.success) {
        if (res?.data) {
          hideTime = parseInt(res?.data?.systemPropertyValue);
        }
      }
    }
    if (timerVal.h > 0 || timerVal.m > 0 || timerVal.s > hideTime) {
      setHideInteractive(false);
    }
  };

  useEffect(() => {
    handleReadApiCalls();

    ///For RPM Program Changes
    if (isRPM && timerStatus == 1 && !readOnlyNotes && !readOnlyFreq) {
      dispatch(setRightHead("care-plan"));
      dispatch(setTimerStatus({ status: 2, parentApiCall: true }));
    }
  }, [careId, recurringId]);

  const handleReadApiCalls: () => void = async () => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    let newFormData: FormDataType[] = JSON.parse(JSON.stringify(formData));
    let newFormData1: FormDataType[] = JSON.parse(JSON.stringify(formData1));
    let newFormData2: FormDataType[] = JSON.parse(JSON.stringify(formData2));
    let CPFormatedRes: CPSearchType | null = null;
    let frequencyUpdated: DropdownArrPropsType[] = frequencyList;

    if (isRecurring) {
      const recurringRes = await CarePlanApi.careplanRecurringsearch({
        id1: recurringId,
      });
      if (recurringRes?.success) {
        CPFormatedRes = CPFormatter.CheckRecurringCPSearch(recurringRes?.data);
      }
    } else {
      const searchRes = await CarePlanApi.search({ id1: careId });
      if (searchRes?.success) {
        CPFormatedRes = CPFormatter.CheckCPSearch(searchRes?.data);
      }
      if (isTCM) {
        //TCM summary Implementation
        const TcmSearchRes = await CarePlanApi.TCMSummaryV2({ id1: careId });
        if (TcmSearchRes?.success)
          setTCMV2(CPFormatter.TCMV2Summary(TcmSearchRes?.data));
        newFormData1[0].readOnly = true;
        if (CPFormatedRes && !CPFormatedRes?.careplan_frequency) {
          CPFormatedRes.careplan_frequency = "30";
        }
      } else {
        const sumRes = await CarePlanApi.CPSummaryV3({ id1: careId });
        if (sumRes?.success)
          setSummaryV3(CPFormatter.CPV3Summary(sumRes?.data));
      }
    }
    if (isRPM) {
      frequencyUpdated = rpmFrequencyList;
      newFormData2 = await handleRPMReadCalls(newFormData2);
    }
    newFormData1[0].dropArr = frequencyUpdated;
    newFormData[0].value = CPFormatedRes?.carePlanAdditionalNotes || "";
    newFormData1[0].value = newFormData1[0].dropValue =
      CPFormatedRes?.careplan_frequency || "";
    newFormData1[1].readOnly = true;
    newFormData1[1].value = newFormData1[1].dropValue =
      DateTime.UTCToLocalDateTime(CPFormatedRes?.careplanCompletionDate) ||
      DateTime.CurrentDateTime();
    if (readOnlyNotes) {
      newFormData = newFormData.map((el) => {
        el.readOnly = true;
        return el;
      });
    }
    if (readOnlyFreq) {
      newFormData1 = newFormData1.map((el) => {
        el.readOnly = true;
        return el;
      });
    }
    if (
      selectedPrgm.isCareplanComprehensive &&
      newFormData.length > 0 &&
      !recurringId
    ) {
      newFormData[0].label = "Message";
      newFormData[0].readOnly = true;
      newFormData[0].value = `Care Plan initiated on ${DateTime.ToLocalDate(
        selectedPrgm.careplanInitDateInEhr
      )} by the provider in the EHR is comprehensive and up to date.`;
    }
    setFormData(newFormData);
    setFormData1(newFormData1);
    setFormData2(newFormData2);
    dispatch(stopLoading());
  };

  const handleRPMReadCalls: (
    newFormData2: FormDataType[]
  ) => Promise<FormDataType[]> = async (newFormData2) => {
    const interRes = await CarePlanApi.CPInteractiveSearch({
      id1: careId,
    });
    if (interRes?.success) {
      const val = interRes?.data?.isInteractiveCommunicationDone;
      const userId = CheckNumber(
        interRes?.data?.interactiveCommunicationUpdatedBy
      );
      const newVal = typeof val == "boolean" ? (val ? "Yes" : "No") : "";
      newFormData2[0].boxType = "single-select-drop";
      newFormData2[0].dropValue = newVal;
      newFormData2[0].required = true;
      if ((newVal == "Yes" && myInfo.id != userId) || readOnlyInteractive) {
        newFormData2[0].readOnly = true;
      }
      if (newVal) setHideInteractive(false);
      else {
        const payload = { timeLimit: 30 };
        const res30sec = await CarePlanApi.CP30SecSerach({
          id1: careId,
          data: payload,
        });
        if (res30sec.success) {
          if (res30sec.data) setHideInteractive(false);
        }
      }
    }
    if (myInfo.roleName == "Provider") {
      const medRes = await CarePlanApi.CPMedicallySearch({
        id1: careId,
      });
      if (medRes?.success) {
        const val = medRes?.data?.isMedicallyNecessary;
        const newVal = typeof val == "boolean" ? (val ? "Yes" : "No") : "";
        newFormData2[1].boxType = "single-select-drop";
        newFormData2[1].dropValue = newVal;
        newFormData2[1].required = true;
        if (readOnlyMedically) newFormData2[1].readOnly = true;
      }
    }
    return newFormData2;
  };

  const handleTimeLog: () => Promise<void> = async () => {
    const endDate = DateTime.CurrentUTCDateTimeSec();
    const startDate = timerStatus == 2 ? timerStartDate : endDate;
    const payload = {
      careplanId: careId,
      careplanRecurringId: recurringId,
      startDateTime: startDate,
      endDateTime: endDate,
      notes: "",
      reasons: selectedPrgm?.careplanActivity || null,
      status: "ACTIVE",
      date: endDate,
      billingMonth: parseInt(DateTime.CurrentMonth()),
      billingYear: parseInt(DateTime.CurrentYear()),
    };
    const res = await PatientApi.timeLog({ data: payload, id1: careId });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      dispatch(setTimerStartDate(null));
    }
  };

  const handleInitiateSaveExit: () => void = async () => {
    if (!handleValidateForm()) {
      if (isRPM) handleRPMSubmit();
      else {
        if (selectedPrgm?.showLogTime) {
          const checkPayload = {
            careplanId: recurringId ? null : careId,
            careplanRecurringId: recurringId ? recurringId : null,
          };
          const checkRes = await PatientApi.CheckTimeLogged({
            data: checkPayload,
          });
          if (checkRes?.success) {
            if (checkRes?.data) handleFinalSaveExit(true);
            else {
              timerStatus == 1
                ? setTimeLogModal(true)
                : handleFinalSaveExit(false);
            }
          }
        } else handleFinalSaveExit(true);
      }
    }
  };

  const handleFinalSaveExit: (timeLogged: boolean) => void = async (
    timeLogged
  ) => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    timeLogModal && setTimeLogModal(false);
    if (timerStatus == 1) {
      if (!timeLogged && selectedPrgm?.showLogTime) await handleTimeLog();
    } else {
      await handleTimeLog();
      dispatch(setTimerStatus({ status: 1, parentApiCall: false }));
    }
    const payload = {
      notes: formData[0]?.value,
      callFrequency: formData1[0]?.dropValue,
      careplanCompletionDate: DateTime.ToUTCDateTime(formData1[1]?.value),
    };
    if (isRecurring) {
      const updateRes = await CarePlanApi.RecurringCpQuestionStatusUpdate({
        id1: recurringId,
        data: { hasCareplanRecurringQuestionnaireCompleted: true },
      });
      if (updateRes?.success) {
        const recurringRes = await CarePlanApi.RecurringCPSubmit({
          id1: recurringId,
          data: payload,
        });
        if (recurringRes?.success) {
          await CarePlanApi.RCPSummaryV2Pdf({ id1: recurringId });
          setOpenModal(true);
        }
      }
    } else {
      const updateRes = await CarePlanApi.CpQuestionStatusUpdate({
        id1: careId,
        data: { hasCareplanQuestionnaireCompleted: true },
      });
      if (updateRes?.success) {
        const careRes = await CarePlanApi.careplanSubmit({
          id1: careId,
          data: payload,
        });
        if (careRes?.success) {
          if (!selectedPrgm.isCareplanComprehensive) {
            if (isTCM) await CarePlanApi.TCMSummaryV2Pdf({ id1: careId });
            if (!isTCM && myInfo.roleId == 5)
              await CarePlanApi.CPSummaryV2Pdf({ id1: careId });
          }
          setOpenModal(true);
        }
      }
    }
    if (timerStatus == 1) {
      if (!timeLogged && selectedPrgm?.showLogTime) await handleTimeLog();
    }
    dispatch(stopLoading());
  };

  const handleRPMSubmit: () => void = () => {
    if (myInfo.roleName == "Provider" && formData2[1].dropValue == "No") {
      setMedModal(true);
    } else handleFinalSaveExit(true);
  };

  const handleUnrollCall: () => void = async () => {
    setMedModal(false);
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const payload = {
      concent: "Unenrolled",
      unenrollmentReason:
        "RPM Services are not medically necessary & reasonable for this patient",
    };
    const res = await ProgramApi.UnenrollProgram({
      id1: patientInfo.id,
      id2: selectedPrgm.eligibleProgramId,
      data: payload,
    });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      navigate(BreadUrls.ClinicalSummary(patientInfo.id));
    }
    dispatch(stopLoading());
  };

  const handlePlanSave: () => void = async () => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    if (!handleValidateForm()) {
      const payload = {
        notes: formData[0]?.value,
        callFrequency: formData1[0]?.dropValue,
        careplanCompletionDate: DateTime.ToUTCDateTime(formData1[1]?.value),
      };
      if (isRecurring) {
        dispatch(
          setAlertData({
            alertMessage: "Recurring save is not implemented yet",
            alertVariant: "info",
            openAlert: true,
          })
        );
      } else {
        const res = await CarePlanApi.SummarySave({
          id1: careId,
          data: payload,
        });
        if (res?.success) {
          dispatch(setAlertData(res?.alert));
        }
      }
    }
    dispatch(stopLoading());
  };

  const handleValidateForm: () => boolean = () => {
    let errorCheck = false;
    const newArr = ValidateFormData(formData);
    if (newArr.errorCheck) errorCheck = true;
    setFormData(newArr.formData);
    const newArr1 = ValidateFormData(formData1);
    if (newArr1.errorCheck) errorCheck = true;
    setFormData1(newArr1.formData);
    const newArr2 = ValidateFormData(formData2);
    if (newArr2.errorCheck) errorCheck = true;
    setFormData2(newArr2.formData);
    return errorCheck;
  };

  const handleForm2Changes: (props: HandleChangeProps) => void = async (
    props
  ) => {
    const { id, value } = props;
    if (id == "interactive") {
      await handleInteractive(CheckStringEmpty(value));
    }
    if (id == "medically") {
      await handleMedically(CheckStringEmpty(value));
    }
    setFormData2(HandleFormChange(props, formData2));
  };

  const handleInteractive: (value: string) => Promise<void> = async (value) => {
    const newBoolean = value == "Yes" ? true : false;
    await CarePlanApi.CPInteractiveUpdate({
      id1: careId,
      data: { isInteractiveCommunicationDone: newBoolean },
    });
  };

  const handleMedically: (value: string) => Promise<void> = async (value) => {
    const newBoolean = value == "Yes" ? true : false;
    await CarePlanApi.CPMedicallyUpdate({
      id1: careId,
      data: { isMedicallyNecessary: newBoolean },
    });
  };

  const fetchModalText: () => string = () => {
    if (selectedPrgm.showLogTime) {
      if (isRecurring) {
        return "All details saved successfully.";
      } else if (isTCM) {
        return `TCM for ${patientInfo?.name} has been successfully done.`;
      } else if (isRPM) {
        if (
          selectedPrgm?.careplanState == Stages.CPShare &&
          myInfo.roleId == 3
        ) {
          return "Treatment plan has been successfully submitted to the Provider.";
        } else {
          return "Treatment plan has been Approved by Provider.";
        }
      } else {
        if (
          selectedPrgm?.careplanState == Stages.CPShare &&
          myInfo.roleId == 3
        ) {
          return "Care plan has been successfully submitted to the Provider.";
        } else {
          return "Care plan has been Approved by Provider.";
        }
      }
    } else {
      if (
        selectedPrgm?.careplanState == Stages.CPSignOff &&
        myInfo.roleId == 5
      ) {
        return "Care plan has been Approved by Provider.";
      } else {
        return "All details saved successfully.";
      }
    }
  };

  const fetchBtnText: () => string = () => {
    if (selectedPrgm.showLogTime) {
      if (isRecurring || isTCM) {
        return "Complete";
      } else {
        if (
          selectedPrgm?.careplanState == Stages.CPShare &&
          myInfo.roleId == 3
        ) {
          return "Share with provider";
        } else {
          return "Sign Off";
        }
      }
    } else {
      if (
        selectedPrgm?.careplanState == Stages.CPSignOff &&
        myInfo.roleId == 5
      ) {
        return "Sign Off";
      } else {
        return "Save Changes";
      }
    }
  };

  return (
    <Fragment>
      <ModalLayout
        modwidth="33%"
        content={
          <Box textAlign={"center"}>
            <Typography mb={1}>
              Please enter your time before proceeding.
            </Typography>
            <Typography>
              Do you want to continue without entering time?
            </Typography>
          </Box>
        }
        open={timeLogModal}
        onClose={() => setTimeLogModal(false)}
        buttonArr={CommonButtonArray(
          () => handleFinalSaveExit(false),
          () => setTimeLogModal(false),
          "Yes",
          "No"
        )}
      />
      <ModalLayout
        modwidth="50%"
        heading={"Warning"}
        content={
          <Box textAlign={"center"}>
            <Typography mb={1}>
              Patient will be unenrolled from the RPM Program. Any futher RPM
              activities will not be applicable for this patient. All devices
              assigned to or ordered for this patient will be suspended / called
              back respectively.
            </Typography>
            <Typography>Do you still want to proceed?</Typography>
          </Box>
        }
        open={medModal}
        onClose={() => setMedModal(false)}
        buttonArr={CommonButtonArray(
          () => handleUnrollCall(),
          () => setMedModal(false),
          "Yes",
          "No"
        )}
      />
      <ModalLayout
        modwidth="30%"
        content={<Typography>{fetchModalText()}</Typography>}
        open={openModal}
        buttonArr={SingleBtnArray(() =>
          navigate(
            isRecurring
              ? `../../../${RouteUrls.clinicalSummary}`
              : `../../${RouteUrls.clinicalSummary}`,
            { replace: true }
          )
        )}
      />
      <Box sx={{ ...sxMain }}>
        {isTCM ? (
          <TCMSummaryView summary={tcmV2} />
        ) : (
          <SummaryView summary={summaryV3} />
        )}
        {formData2.map((el) => {
          if (el.boxType == "empty-block") return <></>;
          if (hideInteractive && el.id == "interactive") return <></>;
          return (
            <Box key={el.id} display="flex" mb={1} alignItems="center">
              <Typography
                variant="subtitle1"
                width="40%"
                sx={{
                  "&>span": {
                    color: "error.main",
                  },
                }}
              >
                {el.label}
                {el.required ? <span>*</span> : ""}:
              </Typography>
              <Box width="20%">
                <ControlledForm.SingleSelectDrop
                  {...el}
                  label=""
                  onClick={(value) => handleForm2Changes({ id: el.id, value })}
                />
              </Box>
            </Box>
          );
        })}
        <Box display="flex" justifyContent="space-between">
          {formData.map((formEl) => {
            return (
              <Box width="60%" key={formEl.id}>
                {commonFormSwitch({
                  formObj: formEl,
                  onChange: (data) =>
                    setFormData(HandleFormChange(data, formData)),
                })}
              </Box>
            );
          })}
          <Box
            width="20%"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            {!isTCM &&
              formData1.map((formEl) => {
                return (
                  <Box key={formEl.id}>
                    {commonFormSwitch({
                      formObj: formEl,
                      onChange: (data) =>
                        setFormData1(HandleFormChange(data, formData1)),
                    })}
                  </Box>
                );
              })}
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{ "&>button": { mt: 3 } }}
          >
            {selectedPrgm.showSaveBtn && (
              <CstmBtn label={"Save"} onClick={handlePlanSave} />
            )}
            {selectedPrgm.showSubmitBtn && (
              <CstmBtn
                label={fetchBtnText()}
                onClick={handleInitiateSaveExit}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
export default CarePlan;
const sxMain = {
  ...CommonStyle.sxWhiteCard,
  display: "flex",
  flexDirection: "column",
  // justifyContent: "space-between",
  width: "91%",
  overflow: "auto",
};
