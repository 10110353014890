import { FC } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { CommonStyle } from "Components";

type IconProps = {
  fontSize?: string;
  onClick: () => void;
  disable?: boolean;
  sxProps?: any;
  loading?: boolean;
  title?: string;
  icon: JSX.Element;
  none?: boolean;
};

const CstmIconBtn: FC<IconProps> = (props: IconProps) => {
  const isDisabled = props.disable;

  return props.none ? (
    <></>
  ) : (
    <IconButton
      sx={{
        ...(isDisabled
          ? CommonStyle.sxRoundIconsDisabledMd
          : CommonStyle.sxRoundIconsMainMd),
        ...props.sxProps,
      }}
      onClick={props.onClick}
      {...(props.title && { title: props.title })}
    >
      {props.loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
      {props.icon}
    </IconButton>
  );
};

export default CstmIconBtn;
