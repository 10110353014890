import { Box } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { CommonStyle } from "Components";

const SettingManagement: FC = () => {
  return (
    <Box
      height="91%"
      sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}
      overflow={"auto"}
    >
      <Outlet />
    </Box>
  );
};
export default SettingManagement;
