import { FC, Fragment, useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { TableCol, FormDataArr, ProgramColArr } from "./Data";
import { CommonStyle, ControlledTable, Loader, Modal } from "Components";
import { ModalLayout, TableLayout } from "Layouts";
import { useNavigate } from "react-router-dom";
import CstmAccordion1 from "Components/CstmAccordion";
import { SingleBtnArray } from "Components/Modal/Data";
import PatientApi from "Service/Patient.api";
import CstmBtn from "Components/CstmBtn";
import ProgramApi from "Service/Program.api";
import { EligibleProgramFormArr } from "DataTypes/PatientManagement.type";
import { FormDataType } from "DataTypes/Form";
import { commonFormSwitch } from "Utils/SwitchCases";
import { CheckNumber, CheckStringEmpty, ValidateFormData } from "Utils/common";
import { HandleChangeProps } from "DataTypes/Common";
import NoData from "Components/NoData";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import Formate from "./Formate";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { label } from "Constant/En";
import { RouteUrls } from "Constant/RouteUrls";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import DateTime from "Utils/DateTime";
import { ConsentHistoryListType } from "DataTypes/Services/Patient.type";
import PatientFormatter from "Utils/Patient.Formatter";
import CommonFormatter from "Utils/Common.Formatter";

const ConsentGained: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [userlist, setUserList] = useState<ConsentHistoryListType[]>([]);
  const [prgmArr, setPrgmArr] = useState<EligibleProgramFormArr[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [prgmLoading, setPrgmLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [modalPrgm, setModalPrgm] = useState("");

  useEffect(() => {
    setOpenModal(modalPrgm.length > 0 ? true : false);
  }, [modalPrgm]);

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "patient-consent",
      label: "Program Consent",
      path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.prgm}`,
      level: 4,
      link: RouteUrls.prgm,
      navType: "patient",
    });
    handleUserList();
    handleProgramList();
  }, []);

  const handleUserList: () => void = async () => {
    const res = await PatientApi.enrolledPrgmUserList({
      id1: patientInfo.id,
    });
    if (res?.success) {
      setUserList(PatientFormatter.ConsentHistoryList(res?.data));
    }
    setUserLoading(false);
  };

  const handleProgramList: () => void = async () => {
    const res = await PatientApi.eligiblePrgmList({
      id1: patientInfo.id,
    });
    if (res?.success) {
      const formatedData = Formate.FormateEligibleRes(res?.data, FormDataArr());
      const filterArr = formatedData.filter((filEl) => filEl.program_id != 3);
      setPrgmArr(filterArr);
    }
    setPrgmLoading(false);
  };

  const handleChange: (
    data: HandleChangeProps,
    prgm: EligibleProgramFormArr
  ) => void = (data, prgm) => {
    const { value, id } = data;
    if (id == "consent-drop" && value == "Enrolled")
      setModalPrgm(prgm.abbrevation);
    setPrgmArr(Formate.HandleChange(data, prgm.id, prgmArr));
  };

  const handleSave: (
    btnType: "save" | "save-next",
    clickedPrgm: EligibleProgramFormArr
  ) => void = async (btnType, clickedPrgm) => {
    const newArr = ValidateFormData(clickedPrgm.formArr);
    const newPrgmArr =
      prgmArr.map((el) => {
        if (el.id == clickedPrgm.id) el.formArr = newArr.formData;
        return el;
      }) || [];
    setPrgmArr(newPrgmArr);
    if (!newArr.errorCheck) {
      dispatch(startLoading({ loading: true, loadtext: "Loading" }));
      const pId = patientInfo.id;
      const eId = clickedPrgm.eligibleProgramId;
      const initDate = DateTime.ToUTCDate(clickedPrgm.formArr[5].value);
      const enrollDate = DateTime.ToUTCDate(clickedPrgm.formArr[2].value);
      const EnrolledPayload = {
        concentType: clickedPrgm.formArr[3].dropValue,
        careplanCreatedInEhr: clickedPrgm.formArr[4].dropValue,
        careplanInitDateInEhr: initDate ? initDate + "T00:00" : "",
        enrolmentDate: enrollDate ? enrollDate + "T00:00" : "",
      };
      const payload = {
        ...(clickedPrgm.formArr[1].dropValue == "Enrolled"
          ? EnrolledPayload
          : {}),
        concentReason: clickedPrgm.formArr[6].dropValue,
        concent: clickedPrgm.formArr[1].dropValue,
      };
      const res = await ProgramApi.EnrollProgram({
        id1: pId,
        id2: eId,
        data: payload,
      });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        handleUserList();
        if (CheckStringEmpty(clickedPrgm.formArr[1].dropValue) == "Enrolled") {
          setPrgmArr(
            newPrgmArr.filter((el) => el.program_id != clickedPrgm.program_id)
          );
        }
        if (btnType == "save-next") {
          dispatch(
            addSelectedPrgm({
              ...selectedPrgmInitialState,
              consentGainedBy: myInfo.id,
              careplanId: CheckNumber(res?.data?.careplanId),
              showLogTime: true,
              showSaveBtn: true,
              showSubmitBtn: true,
            })
          );
          ///For RPM Program Changes
          if (
            myInfo.roleName == "Care Coordinator" &&
            clickedPrgm.abbrevation == "RPM"
          ) {
            navigate(
              `../${RouteUrls.treatmentPlan}/${res?.data?.careplanId}/${RouteUrls.careProblem}`,
              {
                replace: true,
              }
            );
            dispatch(stopLoading());
          } else {
            handleAssignToMe(
              CheckNumber(res?.data?.id),
              CheckNumber(res?.data?.careplanId),
              CheckStringEmpty(clickedPrgm.abbrevation)
            );
          }
        } else dispatch(stopLoading());
      }
    }
  };

  const handleAssignToMe: (
    EID: number | null,
    CPID: number | null,
    prgm: string
  ) => void = async (EID, CPID, prgm) => {
    const cmpayload = {
      caremanagerId: myInfo.id,
    };
    const provpayload = {
      billableProviderId: myInfo.id,
    };
    const res = await PatientApi.AssignToMe({
      id1: EID,
      data: myInfo.roleId == 5 ? provpayload : cmpayload,
    });
    if (res.success) {
      let route = RouteUrls.carePlan;
      if (prgm == "RPM") route = RouteUrls.treatmentPlan;
      navigate(`../${route}/${CPID}/${RouteUrls.careProblem}`, {
        replace: true,
      });
    }
    dispatch(stopLoading());
  };

  return (
    <Fragment>
      <ModalLayout
        modwidth="58%"
        heading={"Program Details"}
        content={<Modal.ProgramConsentModal modalPrgm={modalPrgm} />}
        open={openModal}
        onClose={() => setModalPrgm("")}
        buttonArr={SingleBtnArray(() => setModalPrgm(""), "Close")}
      />
      <Box width="91%" sx={{ ...CommonStyle.sxWhiteCard }} overflow="auto">
        <Typography mb={1} variant="body1" fontWeight={"bold"}>
          {label.programs}
        </Typography>
        {prgmLoading ? (
          <Box height="30%">
            <Loader />
          </Box>
        ) : prgmArr.length > 0 ? (
          <Fragment>
            {/* Label UI Section */}
            <Grid
              container
              spacing={1}
              columns={14}
              sx={{
                mb: 0.5,
                ...(myInfo.roleId == 4 &&
                !prgmArr.find((el) => el.abbrevation == "RPM")
                  ? sxFormWith1Btn
                  : sxFormWith2Btns),
              }}
            >
              {ProgramColArr.map((prgmEl) => {
                return (
                  <Grid key={prgmEl.id} item sm={prgmEl.boxsize}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {prgmEl.label}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            {prgmArr.map((programEl) => {
              return (
                <Box
                  key={programEl?.id}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  {/* Form UI Section */}
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      ...(myInfo.roleId == 4 &&
                      !prgmArr.find((el) => el.abbrevation == "RPM")
                        ? sxFormWith1Btn
                        : sxFormWith2Btns),
                    }}
                    columns={14}
                  >
                    {programEl.formArr.map((el: FormDataType) => {
                      return (
                        <Grid key={el.id} item sm={el.boxSize}>
                          {commonFormSwitch({
                            formObj: el,
                            onChange: (data) => handleChange(data, programEl),
                          })}
                        </Grid>
                      );
                    })}
                  </Grid>
                  {/* Button UI Section */}
                  <Box
                    sx={{
                      ...CommonStyle.sxRow,
                      ...(myInfo.roleId == 4 &&
                      !prgmArr.find((el) => el.abbrevation == "RPM")
                        ? sxBtnWith1Btn
                        : sxBtnWith2Btns),
                      ...(myInfo.roleId == 4
                        ? {
                          flexDirection: "row-reverse",
                          justifyContent: "flex-start",
                          "&>button:first-of-type": {
                            ml: 1,
                          },
                        }
                        : {
                          justifyContent: "flex-end",
                          "&>button:first-of-type": {
                            mr: 1,
                          },
                        }),
                    }}
                  >
                    <CstmBtn
                      label="Save"
                      onClick={() => handleSave("save", programEl)}
                      disable={!programEl.formArr[1].dropValue}
                    />
                    {!(
                      myInfo.roleId == 4 && programEl.abbrevation !== "RPM"
                    ) && (
                      <CstmBtn
                        label="Save & Next"
                        onClick={() => handleSave("save-next", programEl)}
                        disable={
                          programEl.formArr[1].dropValue != "Enrolled"
                            ? true
                            : false
                        }
                      />
                    )}
                  </Box>
                </Box>
              );
            })}
          </Fragment>
        ) : (
          <Box height="30%">
            <NoData />
          </Box>
        )}
        <Box mt={2}>
          <Typography mb={1} variant="body1" fontWeight={"bold"}>
            Consent History
          </Typography>
          {userLoading ? (
            <Box height="30%">
              <Loader />
            </Box>
          ) : userlist.length > 0 ? (
            userlist.map((userEl) => {
              return (
                <CstmAccordion1
                  key={userEl.id}
                  open={true}
                  subContent={
                    <TableLayout
                      tblBody={
                        <ControlledTable.TblBody
                          tableColumn={() => TableCol()}
                          tableRow={userEl.programArr}
                        />
                      }
                      tblHead={ControlledTable.TblHead(TableCol())}
                      height={"100%"}
                    />
                  }
                  mainContent={
                    <Typography variant="subtitle1" fontWeight="bold">
                      {userEl.userName}
                    </Typography>
                  }
                />
              );
            })
          ) : (
            <Box height="30%">
              <NoData />
            </Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};
export default ConsentGained;

const sxFormWith2Btns = {
  width: {
    xs: "84%",
    sm: "85%",
    md: "86%",
    lg: "87%",
    xl: "88%",
  },
};

const sxBtnWith2Btns = {
  width: {
    xs: "16%",
    sm: "15%",
    md: "14%",
    lg: "13%",
    xl: "12%",
  },
};

const sxFormWith1Btn = {
  width: {
    xs: "94%",
    sm: "94%",
    md: "94.5%",
    lg: "95%",
    xl: "95%",
  },
};

const sxBtnWith1Btn = {
  width: {
    xs: "6%",
    sm: "6%",
    md: "5.5%",
    lg: "5%",
    xl: "5%",
  },
};
