import { FC, useEffect, useState } from "react";
import { CommonStyle, ControlledForm, ControlledTable } from "Components";
import { Box, Divider, Typography } from "@mui/material";
import { TableLayout } from "Layouts";
import Format from "./Format";
import {
  BHICatCol,
  BHITimeCol,
  CCMCatCol,
  CCMTimeCol,
  PCMCatCol,
  PCMTimeCol,
  RPMCatCol,
  RPMTimeCol,
  TCMTableCol,
} from "./Data";
import PatientApi from "Service/Patient.api";
import { DashDataType, TCMDashDataType } from "DataTypes/Services/Patient.type";
import { DropdownArrPropsType } from "DataTypes/Form";
import { ListInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const Dashboard: FC = () => {
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { orgDropList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState<DashDataType[]>([]);
  const [data2, setData2] = useState<DashDataType[]>([]);
  const [data3, setData3] = useState<DashDataType[]>([]);
  const [data4, setData4] = useState<DashDataType[]>([]);
  const [data5, setData5] = useState<TCMDashDataType[]>([]);
  const [orgList, setOrgList] = useState<DropdownArrPropsType[]>([]);
  const [orgVal, setOrgVal] = useState<string | number>("");

  useEffect(() => {
    LoadOrgList();
  }, [orgDropList]);

  const LoadOrgList: () => void = () => {
    let newArr: DropdownArrPropsType[] = JSON.parse(
      JSON.stringify(orgDropList)
    );
    newArr = newArr.filter((el) => el.label != "CareVitality");
    if (myInfo.roleName == "Super Admin") {
      newArr = [{ id: "all", label: "All", value: "all" }, ...newArr];
    }
    setOrgList(newArr);
  };

  useEffect(() => {
    if (orgList.length > 0) {
      setOrgVal(orgList[0].value);
    }
  }, [orgList]);

  useEffect(() => {
    DashApiCall(orgVal);
  }, [orgVal]);

  const DashApiCall: (orgId: number | string) => void = async (orgId) => {
    setLoading(true);
    if (orgVal) {
      const payload = {
        programAbbrevation: [],
        practiceId: orgId == "all" ? [] : [orgId],
      };
      const res = await PatientApi.DashboardCount({ data: payload });
      if (res?.success) {
        const formatedData = Format.DashboardData(res?.data);
        setData1(formatedData.ccm);
        setData2(formatedData.pcm);
        setData3(formatedData.bhi);
        setData4(formatedData.rpm);
        setData5(formatedData.tcm);
      }
    } else {
      setData1([]);
      setData2([]);
      setData3([]);
      setData4([]);
      setData5([]);
    }
    setLoading(false);
  };

  const CCMCatBodyJSX = (
    <ControlledTable.TblBody tableColumn={() => CCMCatCol()} tableRow={data1} />
  );
  const CCMTimeBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => CCMTimeCol()}
      tableRow={data1}
    />
  );

  const PCMCatBodyJSX = (
    <ControlledTable.TblBody tableColumn={() => PCMCatCol()} tableRow={data2} />
  );
  const PCMTimeBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => PCMTimeCol()}
      tableRow={data2}
    />
  );

  const BHICatBodyJSX = (
    <ControlledTable.TblBody tableColumn={() => BHICatCol()} tableRow={data3} />
  );
  const BHITimeBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => BHITimeCol()}
      tableRow={data3}
    />
  );

  const RPMCatBodyJSX = (
    <ControlledTable.TblBody tableColumn={() => RPMCatCol()} tableRow={data4} />
  );
  const RPMTimeBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => RPMTimeCol()}
      tableRow={data4}
    />
  );

  const TCMTblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TCMTableCol()}
      tableRow={data5}
    />
  );

  return (
    <Box sx={{ ...CommonStyle.sxWhiteCard, mt: 2, pt: 1 }}>
      <Box display="flex" alignItems="end">
        <Box width="5%" />
        <Typography width="35%" variant="body1" fontWeight="bold">
          Category
        </Typography>
        <Typography width="40%" variant="body1" fontWeight="bold">
          Time Range
        </Typography>
        <Box width="20%">
          <ControlledForm.SingleSelectDrop
            id="org"
            label="Select Organization"
            dropValue={orgVal}
            dropArr={orgList}
            onClick={(val) =>
              setOrgVal(
                typeof val == "number" || typeof val == "string" ? val : ""
              )
            }
          />
        </Box>
      </Box>
      <Box sx={{ ...sxTblUI }}>
        <Typography variant="body1" fontWeight="bold">
          CCM
        </Typography>
        <TableLayout
          tblBody={CCMCatBodyJSX}
          tblHead={ControlledTable.TblHead(CCMCatCol())}
          loading={loading}
        />
        <TableLayout
          tblBody={CCMTimeBodyJSX}
          tblHead={ControlledTable.TblHead(CCMTimeCol())}
          loading={loading}
        />
      </Box>
      <Box sx={{ ...sxTblUI }}>
        <Typography variant="body1" fontWeight="bold">
          PCM
        </Typography>
        <TableLayout
          tblBody={PCMCatBodyJSX}
          tblHead={ControlledTable.TblHead(PCMCatCol())}
          loading={loading}
        />
        <TableLayout
          tblBody={PCMTimeBodyJSX}
          tblHead={ControlledTable.TblHead(PCMTimeCol())}
          loading={loading}
        />
      </Box>
      <Box sx={{ ...sxTblUI }}>
        <Typography variant="body1" fontWeight="bold">
          BHI
        </Typography>
        <TableLayout
          tblBody={BHICatBodyJSX}
          tblHead={ControlledTable.TblHead(BHICatCol())}
          loading={loading}
        />
        <TableLayout
          tblBody={BHITimeBodyJSX}
          tblHead={ControlledTable.TblHead(BHITimeCol())}
          loading={loading}
        />
      </Box>
      <Box sx={{ ...sxTblUI }}>
        <Typography variant="body1" fontWeight="bold">
          RPM
        </Typography>
        <TableLayout
          tblBody={RPMCatBodyJSX}
          tblHead={ControlledTable.TblHead(RPMCatCol())}
          loading={loading}
        />
        <TableLayout
          tblBody={RPMTimeBodyJSX}
          tblHead={ControlledTable.TblHead(RPMTimeCol())}
          loading={loading}
        />
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ ...sxTCMTblUI }}>
        <Typography variant="body1" fontWeight="bold">
          TCM
        </Typography>
        <TableLayout
          tblBody={TCMTblBodyJSX}
          tblHead={ControlledTable.TblHead(TCMTableCol())}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;

const sxTblUI = {
  display: "flex",
  alignItems: "center",
  "&>p": { width: "5%" },
  "&>div:first-of-type": {
    width: "35%",
  },
  "&>div:last-of-type": {
    width: "60%",
  },
};

const sxTCMTblUI = {
  display: "flex",
  alignItems: "center",
  "&>p": { width: "5%" },
};
