import { FC, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import { HeadData } from "../Data";

export interface AlertHeadsProps {
  singleDevice?: string | null;
}

const AlertHeads: FC<AlertHeadsProps> = ({ singleDevice }) => {
  return (
    <Fragment>
      <Box display="flex">
        {!singleDevice && <Box width="14%" /> }
        <Box width={singleDevice ? "100%" : "86%"} display="flex">
          <Box width="15%" />
          {HeadData.map((el) => {
            return (
              <Box
                key={el.id}
                width="17%"
                sx={{ backgroundColor: "bg.main" }}
                mx={0.5}
              >
                <Typography
                  color={el.color}
                  fontWeight="bold"
                  textAlign="center"
                  sx={{
                    border: "1px solid",
                    borderColor: "custom.main",
                  }}
                  py={0.5}
                >
                  {el.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box display="flex">
        {!singleDevice && <Box width="14%" />}
        <Box width={singleDevice ? "100%" : "86%"} display="flex">
          <Box width="15%" />
          {HeadData.map((el) => {
            return (
              <Box display="flex" key={el.id} mx={0.5} width="17%">
                {el.range.map((el1) => {
                  return (
                    <Box
                      key={el1.id}
                      width={el1.width}
                      sx={{ backgroundColor: "secondary.main" }}
                    >
                      <Typography
                        textAlign={"center"}
                        sx={{
                          border: "1px solid",
                          borderColor: "custom.main",
                          color: "white",
                        }}
                        py={0.5}
                      >
                        {el1.label}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Fragment>
  );
};
export default AlertHeads;
