import { RedoxSettingsLists } from "DataTypes/SettingsFormatter.type";
import { CheckArr } from "Utils/common";

const RedoxSettingList: (data: any) => RedoxSettingsLists[] = (data) => {
  const newArr1 = CheckArr(data);
  const newArr: any[] = newArr1.map((el) => {
    return {
      id: el.id,
      settingType: el.type,
      dataModel: el.data_model,
      sourceId: el.sourceId,
      sourceName: el.sourceName,
      destinationId: el.destinationId,
      destinationName: el.destinationName,
      webhookToken: el.webhookToken,
      webhookTokenExpireAt: el.webhookTokenExpireAt,
      praticeLocationId:el.praticeLocationId,
      apiKey: el.apiKey,
      apiSecret: el.apiSecret,
      apiKID: el.apiKID,
      apiExpiry: el.apiExpiry,
      authType: el.authType,
      urlEndPoint: el.urlEndPoint,
      practiceId: el.practiceId,
    };
  });
  return newArr;
};

export default { RedoxSettingList };
