import { Box, Typography } from "@mui/material";
import React from "react";
type ModalProps = {
  PatientName: string;
  status: string;
};

const StaffInactiveModal = (props: ModalProps) => {
  return (
    <Box>
      <Typography sx={{ fontWeight: 600, textAlign: "center" }}>
        Do you want to {props?.status} {props?.PatientName}?
      </Typography>
    </Box>
  );
};

export default StaffInactiveModal;