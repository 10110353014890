import { ControlledTable } from "Components";
import FilterData from "Components/Filter2/Data";
import { label } from "Constant/En";
import { RouteUrls } from "Constant/RouteUrls";
import { Filter2DataProps } from "DataTypes/Common";
import { SubNavDataType } from "DataTypes/Layout";
import { RPMAlertType } from "DataTypes/Services/Settings.type";
import { actionArrType, columnType } from "DataTypes/Table";

export const Unread: SubNavDataType = {
  id: RouteUrls.unread,
  label: label.unread,
  link: `${RouteUrls.unread}`,
};

export const Read: SubNavDataType = {
  id: RouteUrls.read,
  label: label.open,
  link: `${RouteUrls.read}`,
};

export const Addressed: SubNavDataType = {
  id: RouteUrls.addressed,
  label: label.addressed,
  link: `${RouteUrls.addressed}`,
};

export const TableCol: (alertType: string) => columnType[] = (alertType) => {
  return [
    ControlledTable.ColumnData.PatientCol("15%"),
    ControlledTable.ColumnData.PracticeColWithoutInfo("15%"),
    ControlledTable.ColumnData.AlertMsg("20%"),
    ControlledTable.ColumnData.AlertDetail("20%"),
    ControlledTable.ColumnData.AlertTime("15%"),
    ...(alertType == RouteUrls.addressed
      ? [ControlledTable.ColumnData.AddressedBy("15%")]
      : []),
    ControlledTable.ColumnData.ActionCol("15%"),
  ];
};

export const FilterArr: (alertType: string) => Filter2DataProps[] = (
  alertType
) => {
  return [
    FilterData.patientName,
    FilterData.practiceName1,
    FilterData.alertDesc,
    FilterData.alertTime,
    ...(alertType == RouteUrls.addressed
      ? [FilterData.addressedByUserName]
      : []),
  ];
};

export const actionArr: (
  handleSummary: () => void,
  handleView: () => void,
  handleTimeLog: () => void,
  handleRead: () => void,
  handleAddressed: () => void,
  alertType: string,
  rowData: RPMAlertType
) => actionArrType[] = (
  handleSummary,
  handleView,
  handleTimeLog,
  handleRead,
  handleAddressed,
  alertType,
  rowData
) => {
  return [
    ControlledTable.ActionData.chartAction(handleSummary),
    ...(rowData.concent == "Enrolled"
      ? [ControlledTable.ActionData.viewTitleAction(handleView)]
      : []),
    ControlledTable.ActionData.timeLogAction(handleTimeLog),
    ...(alertType == RouteUrls.unread
      ? [
        ControlledTable.ActionData.MarkReadAction({
          handleClick: handleRead,
        }),
      ]
      : []),
    ...(alertType != RouteUrls.addressed
      ? [
        ControlledTable.ActionData.MarkAddressedAction({
          handleClick: handleAddressed,
        }),
      ]
      : []),
  ];
};
