import { FormDataType } from "DataTypes/Form";
import { FormatOrgLocationType } from "DataTypes/PracticeMangement.type";
import {
  PracticeReadApiType,
  PracticeReadLocationType,
} from "DataTypes/Services/Practice.type";
import {
  AscendSortByNumber,
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  FormatSysMobileNo,
} from "Utils/common";

const FormateReadResponse: (
  orgData: PracticeReadApiType,
  formArr1: FormDataType[],
  formArr2: FormatOrgLocationType,
  viewOnlyMode: boolean
) => {
  orgInfo: FormDataType[];
  locInfo: FormatOrgLocationType[];
} = (verifiedRes, formArr1, formArr2, viewOnlyMode) => {
  const prgmArr = verifiedRes.program.map((prgmEl) => prgmEl.id);
  const adminDetails = verifiedRes.user.find((el) => el.isPracticeAdmin);
  let newFormArr1: FormDataType[] = JSON.parse(JSON.stringify(formArr1));
  newFormArr1[0].value = verifiedRes.npi;
  newFormArr1[0].readOnly = true;
  newFormArr1[1].value = verifiedRes.name;
  newFormArr1[1].readOnly = false;
  newFormArr1[2].value = newFormArr1[2].dropValue =
    verifiedRes.businessModels.id || "";
  newFormArr1[2].readOnly = true;
  newFormArr1[3].value = newFormArr1[3].dropValue = prgmArr;
  newFormArr1[4].value = adminDetails?.firstName || "";
  newFormArr1[5].value = adminDetails?.email || "";
  newFormArr1[6].value = adminDetails?.secondaryEmail || "";
  if (viewOnlyMode) {
    newFormArr1 = newFormArr1.map((el) => {
      return {
        ...el,
        readOnly: true,
      };
    });
  }
  const locationArr: PracticeReadLocationType[] = AscendSortByNumber(
    verifiedRes.practiceLocations,
    "practiceLocationOrder"
  );
  const newFormArr2 = locationArr.map((locEl, index) => {
    const newArr: FormatOrgLocationType = JSON.parse(JSON.stringify(formArr2));
    newArr.id = locEl.id || null;
    newArr.direct1 = locEl.practiceLocationDirectNumbers[0]?.id || null;
    newArr.direct2 = locEl.practiceLocationDirectNumbers[1]?.id || null;
    newArr.direct3 = locEl.practiceLocationDirectNumbers[2]?.id || null;
    newArr.direct4 = locEl.practiceLocationDirectNumbers[3]?.id || null;
    newArr.status = locEl.status;
    newArr.isMasterPracticeLocation = locEl.isMasterPracticeLocation;
    newArr.practiceLocationOrder = index + 1;
    newArr.locationFormData[0].value = locEl.location1;
    newArr.locationFormData[1].value = locEl.address1;
    newArr.locationFormData[2].value = locEl.address2;
    newArr.locationFormData[3].value = locEl.city;
    newArr.locationFormData[4].value = locEl.state;
    newArr.locationFormData[5].value = locEl.zipCode;
    newArr.locationFormData[6].value = locEl.country;
    newArr.locationFormData[7].value = FormatSysMobileNo(locEl.officeNumber);
    newArr.locationFormData[8].value = FormatSysMobileNo(
      locEl.practiceLocationDirectNumbers[0]?.directNumber || ""
    );
    newArr.locationFormData[9].value = FormatSysMobileNo(
      locEl.practiceLocationDirectNumbers[1]?.directNumber || ""
    );
    newArr.locationFormData[10].value = FormatSysMobileNo(
      locEl.practiceLocationDirectNumbers[2]?.directNumber || ""
    );
    newArr.locationFormData[11].value = FormatSysMobileNo(
      locEl.practiceLocationDirectNumbers[3]?.directNumber || ""
    );
    newArr.locationFormData[12].value = locEl.serviceLocation;
    newArr.locationFormData[13].value = newArr.locationFormData[13].dropValue =
      locEl.timezone;
    if (viewOnlyMode) {
      newArr.locationFormData = newArr.locationFormData.map((el) => {
        return {
          ...el,
          readOnly: true,
        };
      });
    }
    return newArr;
  });

  return {
    orgInfo: newFormArr1,
    locInfo: newFormArr2,
  };
};

const FormatPayload: (
  orgInfo: FormDataType[],
  locInfo: FormatOrgLocationType[],
  orgData: PracticeReadApiType | null,
) => PracticeReadApiType = (orgInfo, locInfo, orgData) => {
  const verifyPrgmArr = CheckArr(orgInfo[3].dropValue);
  const prgmArr = verifyPrgmArr.map((el) => {
    return { id: CheckNumber(el) };
  });
  const newLocArr = locInfo.map((locEl) => {
    return {
      ...(locEl.id && { id: locEl.id }),
      officeNumber: CheckStringEmpty(
        locEl.locationFormData[7].value
      ).replaceAll("-", ""),
      address1: CheckStringEmpty(locEl.locationFormData[1].value),
      address2: CheckStringEmpty(locEl.locationFormData[2].value),
      city: CheckStringEmpty(locEl.locationFormData[3].value),
      state: CheckStringEmpty(locEl.locationFormData[4].value),
      country: CheckStringEmpty(locEl.locationFormData[6].value),
      zipCode: CheckStringEmpty(locEl.locationFormData[5].value),
      serviceLocation: CheckStringEmpty(locEl.locationFormData[12].value),
      location1: CheckStringEmpty(locEl.locationFormData[0].value),
      status: locEl.status || null,
      timezone: CheckStringEmpty(locEl.locationFormData[13].dropValue),
      redox_organization_id: null,
      isMasterPracticeLocation: locEl.isMasterPracticeLocation,
      practiceLocationOrder: locEl.practiceLocationOrder,
      practiceLocationDirectNumbers: [
        ...(CheckStringEmpty(locEl.locationFormData[8].value).length > 0
          ? [
              {
                ...(locEl.direct1 && { id: locEl.direct1 }),
                directNumber: CheckStringEmpty(
                  locEl.locationFormData[8].value
                ).replaceAll("-", ""),
              },
            ]
          : []),
        ...(CheckStringEmpty(locEl.locationFormData[9].value).length > 0
          ? [
              {
                ...(locEl.direct2 && { id: locEl.direct2 }),
                directNumber: CheckStringEmpty(
                  locEl.locationFormData[9].value
                ).replaceAll("-", ""),
              },
            ]
          : []),
        ...(CheckStringEmpty(locEl.locationFormData[10].value).length > 0
          ? [
              {
                ...(locEl.direct3 && { id: locEl.direct3 }),
                directNumber: CheckStringEmpty(
                  locEl.locationFormData[10].value
                ).replaceAll("-", ""),
              },
            ]
          : []),
        ...(CheckStringEmpty(locEl.locationFormData[11].value).length > 0
          ? [
              {
                ...(locEl.direct4 && { id: locEl.direct4 }),
                directNumber: CheckStringEmpty(
                  locEl.locationFormData[11].value
                ).replaceAll("-", ""),
              },
            ]
          : []),
      ],
    };
  });
  const admin = orgData?.user?.find((el) => el?.isPracticeAdmin);
  return {
    npi: CheckStringEmpty(orgInfo[0].value),
    name: CheckStringEmpty(orgInfo[1].value),
    businessModels: { id: CheckNumber(orgInfo[2].dropValue) },
    program: prgmArr,
    user: [
      {
        ...(admin ? { id: admin.id } : {}),
        firstName: CheckStringEmpty(orgInfo[4].value),
        email: CheckStringEmpty(orgInfo[5].value),
        secondaryEmail: CheckStringEmpty(orgInfo[6].value),
        isPracticeAdmin: true,
      },
    ],
    practiceLocations: newLocArr,
    masterPractice: orgData?.masterPractice || false,
    status: orgData?.status || "ACTIVE",
  };
};

export default { FormateReadResponse, FormatPayload };
