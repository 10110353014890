import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Loader } from "Components";
import NoData from "Components/NoData";
import DateTime from "Utils/DateTime";
import { label } from "Constant/En";
import { MedSearchType } from "DataTypes/Services/Redox.type";

const Historical: FC<{ data: MedSearchType[]; loading: boolean }> = ({
  data,
  loading,
}) => {
  return (
    <Box sx={{ ...sxMain }}>
      {loading ? (
        <Loader />
      ) : data.length ? (
        data.map((el) => {
          return (
            <Box key={el.id} sx={{ ...sxContainer }}>
              <Typography variant="subtitle1" width={"100%"} fontWeight="bold">
                {el.productName || label.naText}
              </Typography>
              <Typography variant="subtitle1">
                Stop Date: {DateTime.ToLocalDate(el.stopDate) || label.naText}
              </Typography>
            </Box>
          );
        })
      ) : (
        <NoData />
      )}
    </Box>
  );
};

export default Historical;

const sxMain = {
  height: "100%",
  overflow: "auto",
  px: 2,
};

const sxContainer = {
  py: 1,
  borderBottom: "1px solid",
  borderBottomColor: "custom.main",
};
