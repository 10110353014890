import { FormatOrgListType } from "DataTypes/PracticeMangement.type";
import { PrgmListResType } from "DataTypes/Services/Prgm.type";
import OrganizationFormatter from "Utils/Organization.Formatter";
import { AscendSortByNumber } from "Utils/common";

const FormatListResponse: (data: any) => FormatOrgListType[] = (data) => {
  const verifiedArr = OrganizationFormatter.VerifyListArrResponse(data);
  const newArr: FormatOrgListType[] = verifiedArr.map((varEl) => {
    const sortedPrgmArr: PrgmListResType[] = AscendSortByNumber(
      varEl.program,
      "id"
    );
    const prgmArr = sortedPrgmArr
      .filter((prgmEl) => prgmEl.isDeleted == false)
      .map((prgmEl) => prgmEl.abbrevation || "");
    return {
      id: varEl.id || null,
      name: varEl.name,
      model: varEl.businessModels.name || "",
      modelId: varEl.businessModels.id || null,
      program: prgmArr,
      masterPractice: varEl.masterPractice,
      status: varEl.status,
    };
  });
  return newArr;
};

export default { FormatListResponse };
