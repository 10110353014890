import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { TypeOfConsent } from "Constant/Arr";
import { ConsentType } from "DataTypes/Services/Patient.type";
import { FormatDropdownData } from "Utils/DataFormatter";
import { store } from "Redux/Store";
import FilterData from "Components/Filter2/Data";

export const actionArr: (
  handleChart: () => void,
  handlePatientDetails: () => void,
  handlEnrollDownload: () => void,
  handlUnenrollDownload: () => void,
  data: ConsentType
) => actionArrType[] = (
  handleChart,
  handlePatientDetails,
  handlEnrollDownload,
  handlUnenrollDownload,
  data
) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ControlledTable.ActionData.downloadAction(
      handlEnrollDownload,
      "Download Consent PDF",
      false
    ),
    ControlledTable.ActionData.downloadAction(
      handlUnenrollDownload,
      "Download Cancellation Form PDF",
      data.consent == "Unenrolled" ? false : true
    ),
  ];
  return Arr;
};

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.PicCol("8%"),
  ControlledTable.ColumnData.PatientInfoCol("12%"),
  ControlledTable.ColumnData.PracticeCol("12%"),
  ControlledTable.ColumnData.ProgramCol("12%", "text"),
  ControlledTable.ColumnData.EnrollmentDateCol("13%"),
  ControlledTable.ColumnData.TypeConsentCol("13%"),
  ControlledTable.ColumnData.ConsentGainedByCol("15%"),
  ControlledTable.ColumnData.ActionCol("15%"),
];

export const ConsentTAbBreadArr: BreadArrType[] = [
  {
    id: 1,
    label: "Consent",
    path: "",
  },
];

export const consentTypeArr = [
  {
    id: 1,
    label: "Written",
    value: "Written",
  },
  {
    id: 2,
    label: "Verbal",
    value: "Verbal",
  },
];

export const FilterArr: () => Filter2DataProps[] = () => {
  const { prgDropList } = store.getState().list;
  const Arr: Filter2DataProps[] = [
    FilterData.patientName,
    FilterData.dob,
    FilterData.homeNo,
    FilterData.mobile,
    FilterData.orgName,
    FilterData.orgLocation,
    {
      ...FilterData.prgmName,
      dropArr: FormatDropdownData(prgDropList, "id", "label", "label"),
    },
    FilterData.enrollFrom,
    FilterData.enrollTo,
    { ...FilterData.consentType, dropArr: TypeOfConsent },
    FilterData.consentGainedBy,
    FilterData.consentGainedByDesig,
  ];
  return Arr;
};
