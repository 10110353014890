import { FormObjType, HandleClickType } from "DataTypes/Form";
import { CPDeviceListType } from "DataTypes/Services/Careplan.type";
import DeviceApi from "Service/Device.api";
import CommonFormatter from "Utils/Common.Formatter";
import { FormatDropdownData } from "Utils/DataFormatter";
import DeviceTypeFormatter from "Utils/DeviceType.Formatter";

const HandleClickChange: (
  props: HandleClickType,
  formArr: FormObjType[],
  deviceHistory: CPDeviceListType[]
) => Promise<FormObjType[]> = async (props, formArr, deviceHistory) => {
  const newArr: FormObjType[] = JSON.parse(JSON.stringify([...formArr]));
  const { dropVal, id } = props;
  if (
    newArr[0].boxType == "single-select" &&
    newArr[1].boxType == "single-select" &&
    newArr[2].boxType == "single-select" &&
    newArr[3].boxType == "single-select" &&
    newArr[4].boxType == "single-select" &&
    newArr[5].boxType == "single-select" &&
    newArr[6].boxType == "single-select" &&
    newArr[7].boxType == "input"
  ) {
    if (id == "device-type") {
      if (dropVal) {
        const supplyNameRes = await DeviceApi.deviceList({
          data: { deviceTypeId: dropVal },
        });
        const list = DeviceTypeFormatter.DeviceNameListRes(supplyNameRes?.data);
        newArr[1].readOnly = false;
        newArr[1].dropArr = FormatDropdownData(list, "id", "deviceName", "id");
      } else {
        newArr[1].readOnly = true;
        newArr[1].dropArr = [];
      }

      newArr[1].dropVal =
        newArr[2].dropVal =
        newArr[3].dropVal =
        newArr[4].dropVal =
        newArr[5].dropVal =
        newArr[6].dropVal =
          null;

      newArr[1].inputVal =
        newArr[2].inputVal =
        newArr[3].inputVal =
        newArr[4].inputVal =
        newArr[5].inputVal =
        newArr[6].inputVal =
        newArr[7].inputVal =
          "";

      newArr[1].error =
        newArr[2].error =
        newArr[3].error =
        newArr[4].error =
        newArr[5].error =
        newArr[6].error =
        newArr[7].error =
          false;

      newArr[1].errorText =
        newArr[2].errorText =
        newArr[3].errorText =
        newArr[4].errorText =
        newArr[5].errorText =
        newArr[6].errorText =
        newArr[7].errorText =
          "";

      newArr[2].required = true;
      newArr[2].readOnly = false;

      newArr[3].required = true;
      newArr[3].readOnly = false;

      newArr[4].required = false;
      newArr[4].readOnly = true;

      newArr[5].required = false;

      newArr[6].required = true;
      newArr[6].readOnly = false;

      newArr[7].required = false;
      newArr[7].readOnly = false;
    }

    if (id == "training-required") {
      if (dropVal && dropVal == "Yes") {
        const newVal = newArr[0].dropVal;
        const findDevice = deviceHistory.find(
          (el) => el.deviceTypeId == newVal && el.isDeviceSuspended
        );
        if (findDevice) {
          newArr[5].dropVal = newArr[5].inputVal = "REPLACEMENT";
        } else {
          newArr[5].dropVal = newArr[5].inputVal = "INITIAL_SETUP";
        }
        newArr[4].readOnly = false;
        newArr[4].required = newArr[5].required = true;
      } else {
        newArr[4].readOnly = true;
        newArr[4].required = newArr[5].required = false;
        newArr[4].dropVal = newArr[5].dropVal = null;
        newArr[4].inputVal = newArr[5].inputVal = "";
      }
    }
    if (id == "device-provier") {
      if (dropVal && dropVal == "Patient") {
        newArr[7].required = true;
      } else {
        newArr[7].required = false;
      }
    }
  }
  return CommonFormatter.HandleClickChange(props, newArr);
};

const HandleEditDevice: (
  editData: CPDeviceListType,
  formArr: FormObjType[]
) => FormObjType[] = (editData, formArr) => {
  const newArr: FormObjType[] = JSON.parse(JSON.stringify([...formArr]));
  if (
    newArr[0].boxType == "single-select" &&
    newArr[1].boxType == "single-select" &&
    newArr[2].boxType == "single-select" &&
    newArr[3].boxType == "single-select" &&
    newArr[4].boxType == "single-select" &&
    newArr[5].boxType == "single-select" &&
    newArr[6].boxType == "single-select" &&
    newArr[7].boxType == "input"
  ) {
    newArr[0].dropVal = editData.deviceTypeId;
    newArr[0].readOnly = true;
    newArr[0].dropArr = [
      {
        id: editData.deviceTypeId || "",
        label: editData.deviceTypeDescription || "",
        value: editData.deviceTypeId || "",
      },
    ];

    newArr[1].dropVal = editData.deviceId;
    newArr[1].readOnly = true;
    newArr[1].dropArr = [
      {
        id: editData.deviceId || "",
        label: editData.deviceName || "",
        value: editData.deviceId || "",
      },
    ];

    newArr[2].dropVal = editData.reminderId;
    newArr[2].readOnly = false;

    const isTrainingCompleted =
      editData.trainingStatusId == "TRANING_COMPLETED";
    newArr[3].dropVal = editData.trainingRequired ? "Yes" : "No";
    newArr[3].readOnly = isTrainingCompleted ? true : false;

    newArr[4].readOnly = isTrainingCompleted
      ? true
      : editData.trainingRequired
      ? false
      : true;
    newArr[4].dropVal = editData.trainerId;

    newArr[5].dropVal = editData.trainingTypeId;
    newArr[5].readOnly = true;

    newArr[6].dropVal = editData.deviceProvider;
    newArr[6].readOnly = true;

    newArr[7].inputVal = editData.actualDeviceId || "";
    newArr[7].readOnly = true;
  }
  return newArr;
};

const HandleViewDevice: (
  viewData: CPDeviceListType,
  formArr: FormObjType[]
) => FormObjType[] = (viewData, formArr) => {
  const newArr = HandleEditDevice(viewData, formArr);
  return newArr.map((el) => {
    return {
      ...el,
      readOnly: true,
    };
  });
};

export default {
  HandleClickChange,
  HandleEditDevice,
  HandleViewDevice,
};
