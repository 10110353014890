// New Code

import { FC } from "react";
import { Modal, Box, Typography } from "@mui/material";
import ImgPath from "Constant/Imgs";
import { CommonStyle } from "Components";
import { SidebarModalLayoutType } from "DataTypes/Layout";

const SidebarModalLayout: FC<SidebarModalLayoutType> = (
  props: SidebarModalLayoutType
) => {
  const { modwidth, heading, content, open, onClose, twoLayers } = props;
  const body = (
    <Box sx={{ ...sxMain1, width: modwidth ? modwidth : "25%" }}>
      <Box sx={{ ...sxHeader }}>
        <Typography fontWeight="bold">{heading}</Typography>
        <Box sx={{ ...CommonStyle.sxRoundIconsMainSm }} onClick={onClose}>
          <ImgPath.CloseIcon titleAccess="Close" />
        </Box>
      </Box>
      <Box sx={{ ...sxBody }}>{content}</Box>
    </Box>
  );

  const body2 = <Box sx={{ ...sxMain2 }}>{content}</Box>;

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {twoLayers ? body2 : body}
      </Modal>
    </div>
  );
};

export default SidebarModalLayout;

const sxMainBody = {
  top: "57%",
  left: "9%",
  transform: `translate( 0%, -${50}%)`,
  position: "absolute",
  outline: "none",
  height: "84%",
  borderRadius: 1.25,
};

const sxMain1 = {
  ...sxMainBody,
  ...CommonStyle.sxWhiteBox,
};

const sxMain2 = {
  ...sxMainBody,
  ...CommonStyle.sxRow,
  width: "90%",
  "&>div": {
    ":first-of-type": {
      height: "100%",
      ...CommonStyle.sxWhiteBox,
    },
    ":nth-of-type(3)": {
      height: "100%",
      ...CommonStyle.sxWhiteBox,
    },
  },
};

const sxHeader = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  justifyContent: "space-between",
  height: "7%",
  px: 2,
};

const sxBody = {
  overflow: "auto",
  height: "91%",
  px: 2,
};
