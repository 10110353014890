import { UserTableListType } from "DataTypes/PracticeMangement.type";
import { UserListApiType } from "DataTypes/Services/User.type";

const ConvertUserList: (data: UserListApiType[]) => UserTableListType[] = (
  data
) => {
  const newArr: UserTableListType[] =
    data.map((dataEl) => {
      const findCV = dataEl.practice.find(
        (pracEl) => pracEl.isMasterPractice == true
      );
      return {
        firstName: dataEl.firstName,
        id: dataEl.id || null,
        lastName: dataEl.lastName,
        designation: dataEl.designation,
        name: `${dataEl.firstName} ${dataEl.lastName}`,
        role: dataEl.role[0]?.name,
        practiceName: findCV?.name || "",
        officeNo: dataEl.officeNo,
        practiceId: findCV?.id || null,
        mobileNo: dataEl.mobile,
        directNo: dataEl.directNo,
        status: dataEl.status || null,
      };
    }) || [];
  return newArr;
};

export default { ConvertUserList };
