import { Fragment, FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import PatientApi from "Service/Patient.api";
import { RedoxResultType } from "DataTypes/Services/Patient.type";
import Loader from "Components/Loader";
import NoData from "Components/NoData";
import { SideModalProps } from "DataTypes/Modal";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import RedoxFormatter from "Utils/Redox.Formatter";

const Result: FC<SideModalProps> = () => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [resultData, setResultData] = useState<RedoxResultType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async () => {
    const res: any = await PatientApi.RedoxResults({ id1: patientInfo.id });
    if (res?.success) {
      setResultData(RedoxFormatter.ResultList(res?.data?.results));
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <Fragment>
      {resultData.length ? (
        resultData.map((el) => {
          return (
            <Typography key={el.id} variant="body2" sx={{ mb: 1 }}>
              • {el.resultName} - {el.observationName}
              {el.value
                ? "- " + el.value + (el.units ? " " + el.units : "")
                : ""}
            </Typography>
          );
        })
      ) : (
        <NoData />
      )}
    </Fragment>
  );
};
export default Result;
