import { useState, FC, useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CstmBtn from "Components/CstmBtn";
import { FormDataType } from "DataTypes/Form";
import { TargetDateArr, TaskFormDataArr } from "./Data";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { CommonStyle } from "Components";
import { useLocation, useParams } from "react-router-dom";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { useSelector, useDispatch } from "react-redux";
import PatientApi from "Service/Patient.api";
import { objectValues } from "DataTypes/Services/Index.type";
import { setAlertData, setFormLoader } from "Redux/Screen/Action";
import { checkTargetdate, formatFormData } from "./formatFormData";
import Patient from "Utils/ApiDataFormatter/Patient";
import DateTime from "Utils/DateTime";
import { ListInitialStateType, PatientInitialStateType } from "DataTypes/Redux";
import { RouteUrls } from "Constant/RouteUrls";
import { ModalLayout } from "Layouts";
import { CommonButtonArray } from "Components/Modal/Data";
import CommonFormatter from "Utils/Common.Formatter";

interface propsTypes {
  from?: string;
  setClose?: () => void;
}
const Task: FC<propsTypes> = ({ from, setClose }: propsTypes) => {
  const [formData, setFormData] = useState<FormDataType[]>([]);
  const [formData1, setFormData1] = useState<FormDataType[]>(TargetDateArr);
  const location = useLocation();
  const loggedUser = useSelector((state: any) => state.user.myInfo);
  const { patientInfo, patientOrg }: PatientInitialStateType = useSelector(
    (state: any) => state.patient
  );
  const { taskTypeList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const patId = patientInfo.id;
  const [priorityType, setPriorityType] = useState("Normal");
  const [assigneeType, setAssigneType] = useState("Group");
  const [readRes, setReadRes] = useState<objectValues>();
  const [prevAssignee, setPrevAssignee] = useState<objectValues | null>(null);
  const [openAssigneeWarningModal, setAssigneeWarningModal] = useState(false);
  const onAssigneeChange = (e: any): void => {
    setAssigneType(e.target.value);
  };
  const dispatch = useDispatch();

  const { taskId } = useParams();
  const currentUrl = window.location.href;
  const view = currentUrl.includes("view");
  const incoming = currentUrl.includes("incoming");
  const outgoing = currentUrl.includes("outgoing");
  const global = currentUrl.includes("global");
  const isValidTaskId = taskId && !isNaN(parseInt(taskId));
  const assigneeUserName =
    formData &&
    formData[2]?.dropValue &&
    formData[2]?.dropArr &&
    formData[2]?.dropArr.find((user) => user?.value == formData[2]?.value);

  const formatList = (list: objectValues[]) => {
    return list.map((item: objectValues) => {
      return {
        ...item,
        label: item.name
          ? capitalizeAllWords(item.name)
          : item?.firstName
            ? capitalizeAllWords(item?.firstName) +
            `${
              item?.lastName ? " " + capitalizeAllWords(item?.lastName) : ""
            }` +
            `${
              item?.designation
                ? ", " + capitalizeAllWords(item?.designation)
                : ""
            }`
            : "",
        value: item.name
          ? capitalizeAllWords(item.name)
          : item?.firstName
            ? capitalizeAllWords(item?.firstName) +
            `${
              item?.lastName ? " " + capitalizeAllWords(item?.lastName) : ""
            }` +
            `${
              item?.designation
                ? ", " + capitalizeAllWords(item?.designation)
                : ""
            }`
            : "",
      };
    });
  };

  const capitalizeAllWords = (str: string) => {
    if (!str) return ""; // Handle empty string
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    fetchFunc();
  }, [patId, taskId]);

  const fetchFunc = async () => {
    let result: any = null;
    if (isValidTaskId) {
      result = await PatientApi.readTask(parseInt(taskId));
      const formattedData = Patient.TaskResFormatter(result?.data);
      if (loggedUser.id === formattedData?.assigneeUserId && incoming) {
        setFormData1(
          formData1.map((el) => {
            el.readOnly = true;
            return el;
          })
        );
      }
      if (formattedData?.targateDate) {
        setFormData1(
          formData1.map((el) => {
            el.value = DateTime.UTCToLocalDateTime(formattedData?.targateDate);
            return el;
          })
        );
      }
      setReadRes(formattedData);
      setPriorityType(capitalizeAllWords(result?.data?.priority));
      setAssigneType(result?.data?.assigneeUserId ? "User" : "Group");
      setPrevAssignee({
        id: formattedData?.assigneeUserId,
        value: formattedData?.assigneeUserName,
      });
    }
  };

  useEffect(() => {
    const temp = checkTargetdate({
      priorityType: priorityType,
      userId: loggedUser.id,
      assigneeUserId: readRes?.assigneeUserId,
      incoming: incoming,
      taskId: taskId || "",
      view: view,
      targateDate: readRes?.targateDate,
      formData: formData1,
    });
    setFormData1(temp);
  }, [priorityType]);

  const fetchGroupList = async () => {
    let result: any = null;

    if (isValidTaskId) {
      result = await PatientApi.readTask(parseInt(taskId));
    }
    const res: any =
      assigneeType === "Group"
        ? await PatientApi.fetchGroupList()
        : await PatientApi.fetchUserList({
          data: {
            data: {
              practiceLocationId: patientOrg.practiceLocationId,
              isDeleted: false,
            },
          },
        });

    if (res.success) {
      const data = formatList(res.data);
      const exlucdeDeviceOrder = taskTypeList.filter(
        (item) => item.value != "Device Order"
      );
      const oldArr: any = JSON.parse(
        JSON.stringify(TaskFormDataArr(exlucdeDeviceOrder))
      );
      const indexToUpdate = oldArr.findIndex(
        (obj: any) => obj.label === "Assignee"
      );
      if (indexToUpdate !== -1) {
        oldArr[indexToUpdate].dropArr = data;
      }
      const updateOldValue: any = oldArr.map((item: objectValues) => {
        const indexOfOldValue: objectValues[] =
          formData.length > 0
            ? formData.filter((obj: any) => obj.id === item.id)
            : [];
        const submittedData: any = indexOfOldValue[0]?.value
          ? indexOfOldValue[0].value
          : result
            ? formatFormData(item.id, result.data, data)
            : undefined;

        return {
          ...item,
          value: submittedData,
          errorText: "",
          error: false,
          dropValue: submittedData,
        };
      });

      setFormData(updateOldValue);
    }
  };
  useEffect(() => {
    fetchGroupList();
  }, [assigneeType]);
  const onRadioChange = (e: any) => {
    setPriorityType(e.target.value);
  };
  // const onDateChange = (data: any, formData: FormDataType[]) => {
  //   // setDate(data);
  // };
  const createTask = async (formData: FormDataType[] | []): Promise<void> => {
    const newArr = ValidateFormData(formData);
    const newArr1 = ValidateFormData(formData1);
    setFormData(newArr.formData);
    setFormData1(newArr1.formData);
    if (!newArr.errorCheck && !newArr1.errorCheck) {
      if (
        prevAssignee &&
        assigneeUserName &&
        prevAssignee.id !== assigneeUserName?.id
      ) {
        setAssigneeWarningModal(true);
      } else {
        updateTask(formData);
      }
    }
  };

  const updateTask = async (formData: FormDataType[] | []): Promise<void> => {
    dispatch(setFormLoader(true));
    const object: objectValues = formData.filter(
      (data) => data.id === "task-assign-to"
    )[0];

    const selectedValue: string = object?.value;
    const assigneeGroupId = object?.dropArr.find(
      (item: objectValues) => item.value === selectedValue
    );

    const payload = {
      data: {
        patientId: patId,
        priority: priorityType,
        source: formData[0]?.value,
        type: formData[1]?.value,
        assigneeUserId: assigneeType === "Group" ? null : assigneeGroupId?.id,
        taskDescription: formData[4]?.value,
        subject: formData[3]?.value,
        notes: formData[5]?.value,
        status: formData[6]?.dropValue?.value
          ? formData[6]?.dropValue?.value
          : formData[6]?.value,
        targateDate: formData1[0].value
          ? DateTime.ToUTCDateTime(formData1[0].value)
          : DateTime.TomorrowDateTime(),
        assigneeGroupId: assigneeType === "Group" ? assigneeGroupId?.id : null,
      },
    };

    const res: any = isValidTaskId
      ? await PatientApi.updateTask(readRes?.id, payload)
      : await PatientApi.createTask(patId, payload);
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      from ? setClose && setClose() : window.history.back();
    }
    dispatch(setFormLoader(false));
  };

  useEffect(() => {
    if (!from) {
      const path = view
        ? `${RouteUrls.task}/${taskId}/${RouteUrls.View}`
        : incoming
          ? `${RouteUrls.tasking}/${RouteUrls.incoming}/${taskId}/${RouteUrls.update}`
          : outgoing
            ? `${RouteUrls.tasking}/${RouteUrls.outgoing}/${taskId}/${RouteUrls.update}`
            : `${RouteUrls.task}/${RouteUrls.add}`;
      CommonFormatter.HandleNavArr({
        id: "patient-task",
        label: view
          ? "Task View"
          : incoming
            ? "Incoming Task"
            : outgoing
              ? "Outgoing Task"
              : "Add Task",
        path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${path}`,
        level: 3,
        link: path,
        navType: "patient",
      });
    }
    fetchGroupList();
  }, [patId, taskId]);

  const setFunction = (id: string) => {
    return (id === "note" || id === "Status") &&
      loggedUser.id === readRes?.assigneeUserId &&
      incoming
      ? false
      : (id === "task-description" ||
          id === "subject" ||
          // id === "task-assign-to" ||
          id === "type" ||
          id === "source") &&
        loggedUser.id === readRes?.assigneeUserId &&
        incoming
        ? true
        : view;
  };
  return (
    <>
      <Box
        height="100%"
        width={from ? "100%" : "91%"}
        overflow="auto"
        paddingLeft={from ? "" : "40px"}
        sx={
          from
            ? {
              ...CommonStyle.sxContentLeft,
            }
            : { ...CommonStyle.sxWhiteBox, ...CommonStyle.sxContentLeft }
        }
      >
        <Box width={from ? "100%" : "70%"}>
          <Box sx={{ ...sxHead }}>
            <Box mr={3}>
              <Typography variant="body2">{"Patient's Name:"}</Typography>
              <Typography variant="body2" fontWeight="bold">
                {view || isValidTaskId
                  ? readRes?.patientName
                  : patientInfo.name}
              </Typography>
            </Box>
            <Box mr={3}>
              <Typography variant="body2">{"Created By:"}</Typography>
              <Typography variant="body2" fontWeight="bold">
                {view || isValidTaskId
                  ? readRes?.createdByName
                  : `${loggedUser?.firstname}  ${loggedUser?.lastname}`}
              </Typography>
            </Box>
            <Box mr={3}>
              <Typography variant="body2">{"Date & Time:"}</Typography>

              <Typography variant="body2" fontWeight="bold">
                {isValidTaskId
                  ? DateTime.UTCToLocalDateTime(readRes?.createdAt)
                  : DateTime.CurrentDateTime()}
              </Typography>
            </Box>
          </Box>
          <Grid
            item
            sx={{ ...CommonStyle.sxRow }}
            {...ControlledBoxSizeSwitch(6)}
            justifyContent={"space-between"}
          >
            {/* <Box display="flex"> */}
            <Box width={"100%"} display="flex">
              <Typography sx={{ mr: 2, mt: 1.3 }} variant="body2">
                Priority :
              </Typography>

              <RadioGroup
                row
                value={priorityType}
                onChange={(e) => onRadioChange(e)}
                name="controlled-radio-buttons-group"
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "0.8rem",
                  },
                }}
              >
                <FormControlLabel
                  value="Urgent"
                  disabled={
                    loggedUser.id === readRes?.assigneeUserId && incoming
                      ? true
                      : view
                  }
                  control={<Radio color="secondary" />}
                  label="Urgent"
                />
                <FormControlLabel
                  value="Normal"
                  disabled={
                    loggedUser.id === readRes?.assigneeUserId && incoming
                      ? true
                      : view
                  }
                  control={<Radio color="secondary" />}
                  label="Normal"
                />
              </RadioGroup>
            </Box>

            {priorityType && (
              <Box width="100%" display="flex">
                <Typography sx={{ mt: 1, ml: 1 }} variant="body2">
                  Target Date & Time
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: 0.5,
                    "&>span": {
                      color: "error.main",
                    },
                  }}
                >
                  <span>*</span>
                </Typography>{" "}
                <Typography
                  variant="subtitle1"
                  sx={{
                    mr: 2,
                    mt: 1,
                  }}
                >
                  <span>:</span>
                </Typography>{" "}
                <Box>
                  {formData1.map((el) => {
                    return (
                      <Grid
                        key={el.id}
                        item
                        {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
                      >
                        {commonFormSwitch({
                          formObj: el,
                          onChange: (data) =>
                            setFormData1(HandleFormChange(data, formData1)),
                        })}
                      </Grid>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Grid>

          <Grid container rowSpacing={1} columnSpacing={2}>
            {formData && formData.length > 0
              ? formData.slice(0, 2).map((el: FormDataType) => {
                if (!(global || incoming) && el.id === "status") {
                  if (location?.state?.path !== "editTask") {
                    return <></>;
                  } else {
                    return (
                      <Grid
                        key={el.id}
                        item
                        {...(el.boxSize &&
                            ControlledBoxSizeSwitch(el.boxSize))}
                      >
                        {commonFormSwitch({
                          formObj: el,
                          onChange: (data) =>
                            setFormData(HandleFormChange(data, formData)),
                        })}
                      </Grid>
                    );
                  }
                }

                const id: string = el?.id;
                return (
                  <Grid
                    key={el.id}
                    item
                    {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
                  >
                    {commonFormSwitch({
                      formObj: {
                        ...el,

                        readOnly: setFunction(el?.id),
                      },
                      onChange: (data) =>
                        setFormData(HandleFormChange(data, formData)),
                    })}
                  </Grid>
                );
              })
              : null}
          </Grid>
          <Grid
            item
            sx={{ ...CommonStyle.sxRow }}
            {...ControlledBoxSizeSwitch(6)}
            justifyContent={"space-between"}
          >
            {/* <Box display="flex"> */}
            <Box width="100%" display="flex" sx={{ mt: 1.3 }}>
              <Typography sx={{ mr: 2, mt: 1.3 }} variant="body2">
                Assignee Type:
              </Typography>
              <RadioGroup
                row
                value={assigneeType}
                onChange={(e) => onAssigneeChange(e)}
                name="controlled-radio-buttons-group"
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "0.8rem",
                  },
                }}
              >
                <FormControlLabel
                  disabled={view}
                  value="User"
                  control={<Radio color="secondary" />}
                  label="User"
                />
                <FormControlLabel
                  disabled={view}
                  value="Group"
                  control={<Radio color="secondary" />}
                  label="Group"
                />
              </RadioGroup>
            </Box>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={2}>
            {formData && formData.length > 0
              ? formData
                .slice(2, formData.length + 1)
                .map((el: FormDataType) => {
                  if (!(global || incoming) && el.id === "status") {
                    if (!isValidTaskId) {
                      return <></>;
                    } else {
                      return (
                        <>
                          <Grid
                            key={el.id}
                            item
                            {...(el.boxSize &&
                                ControlledBoxSizeSwitch(el.boxSize))}
                          >
                            {commonFormSwitch({
                              formObj: {
                                ...el,
                                readOnly: view,
                              },
                              onChange: (data) =>
                                setFormData(HandleFormChange(data, formData)),
                            })}
                          </Grid>
                        </>
                      );
                    }
                  }
                  return (
                    <Grid
                      key={el.id}
                      item
                      {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
                    >
                      {commonFormSwitch({
                        formObj: {
                          ...el,
                          readOnly: setFunction(el?.id),
                        },
                        onChange: (data) =>
                          setFormData(HandleFormChange(data, formData)),
                      })}
                    </Grid>
                  );
                })
              : null}
          </Grid>
          <Box sx={{ mt: 2.5, ...CommonStyle.sxRow }} justifyContent={"end"}>
            {location?.state?.path == "sidebarTask" ? (
              location?.state?.value == "Completed" ? (
                <></>
              ) : view ? (
                ""
              ) : (
                <CstmBtn
                  sxProps={{ mr: 2 }}
                  label="Save"
                  onClick={() => console.log(true)}
                  width="15%"
                />
              )
            ) : view ? (
              ""
            ) : (
              <CstmBtn
                sxProps={{ mr: 2 }}
                label="Save"
                onClick={() => createTask(formData)}
                width="15%"
                enableFormLoad={true}
              />
            )}
            <CstmBtn
              label="Cancel"
              onClick={() => {
                from ? setClose && setClose() : window.history.back();
                setFormData([]);
                setPriorityType("Normal");
                setAssigneType("Group");
                setFormData1(TargetDateArr);
              }}
              btnType="border"
              width="15%"
            />
          </Box>
        </Box>
        <ModalLayout
          modwidth="50%"
          heading={"Warning"}
          content={
            <Typography textAlign={"center"}>
              Task assignee {prevAssignee && prevAssignee?.value} is changed to{" "}
              {assigneeUserName && assigneeUserName?.value}. This task will no
              longer be visible to your incoming task list as assigned to a new
              user.
            </Typography>
          }
          open={openAssigneeWarningModal}
          onClose={() => setAssigneeWarningModal(false)}
          buttonArr={CommonButtonArray(
            () => {
              updateTask(formData);
            },
            () => {
              setAssigneeWarningModal(false);
            },
            "Save",
            "Cancel"
          )}
        />
      </Box>
    </>
  );
};
export default Task;

const sxHead = {
  display: "flex",
  flexdirection: "row",
  width: "100%",
  justifyContent: "space-between",
  mb: 2,
  "& >div": {
    "& >p:first-of-type": {
      width: "100%",
    },
  },
};
