import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ControlledTable, CommonStyle } from "Components";
import { TableLayout } from "Layouts";
import { TableCol } from "./Data";
import ProgramApi from "Service/Program.api";
import { useSelector } from "react-redux";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const ProgramMaster: FC = () => {
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.program
  );
  const [programList, setProgramList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "prgm-list",
      label: "Program Master",
      path: `/${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.prgm}`,
      level: 1,
      link: RouteUrls.prgm,
      navType: "master",
    });
    getProgramList();
  }, []);

  const getProgramList = async () => {
    const res: any = await ProgramApi.list();
    if (res?.success) setProgramList(res?.data);
    setLoading(false);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={programList}
    />
  );

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}>
      <Box
        height={"10%"}
        justifyContent={"space-between"}
        sx={{ ...CommonStyle.sxRow }}
      >
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {"Program Master"}
        </Typography>
      </Box>
      {permission?.search && (
        <Box width="50%" height="88%">
          <TableLayout
            tblBody={TblBodyJSX}
            tblHead={ControlledTable.TblHead(TableCol)}
            loading={loading}
            height={"100%"}
          />
        </Box>
      )}
    </Box>
  );
};
export default ProgramMaster;
