export const ADD_PATIENT_INFO = "ADD_PATIENT_INFO";
export const CLEAR_PATIENT_INFO = "CLEAR_PATIENT_INFO";
export const ADD_PATIENT_ORG_INFO = "ADD_PATIENT_ORG_INFO";
export const CLEAR_PATIENT_ORG_INFO = "CLEAR_PATIENT_ORG_INFO";
export const ADD_SELECTED_PRGM_INFO = "ADD_SELECTED_PRGM_INFO";
export const CLEAR_SELECTED_PRGM_INFO = "CLEAR_SELECTED_PRGM_INFO";
export const ADD_RPM_PRGM_INFO = "ADD_RPM_PRGM_INFO";
export const CLEAR_RPM_PRGM_INFO = "CLEAR_RPM_PRGM_INFO";
export const CLEAR_PATIENT = "CLEAR_PATIENT";
export const ADD_SELECTED_TRAINING_INFO = "ADD_SELECTED_TRAINING_INFO";
export const CLEAR_SELECTED_TRAINING_INFO = "CLEAR_SELECTED_TRAINING_INFO";
export const ADD_SELECTED_DEVICE_INFO = "ADD_SELECTED_DEVICE_INFO";
export const CLEAR_SELECTED_DEVICE_INFO = "CLEAR_SELECTED_DEVICE_INFO";
