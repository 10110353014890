import { Box, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import { RouteUrls } from "Constant/RouteUrls";
import { FC, useEffect } from "react";
import CommonFormatter from "Utils/Common.Formatter";

const NotFound: FC = () => {
  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "not-found",
      label: "Not Found",
      path: `/${RouteUrls.notfound}`,
      level: 1,
      link: RouteUrls.notfound,
      navType: "my-items",
    });
  }, []);

  return (
    <Box height="91%" sx={{ ...CommonStyle.sxWhiteBox, overflow: "auto" }}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ImgPath.NotFoundIcon
          sx={{ color: "custom.main", width: "8em", height: "8em" }}
        />
        <Typography
          my={2}
          color="custom.main"
          variant="body1"
          fontWeight="bold"
        >
          Page Not Found
        </Typography>
      </Box>
    </Box>
  );
};

export default NotFound;
