import { Box, IconButton, Typography } from "@mui/material";
import { FC, Fragment, useEffect, useState } from "react";
import { CommonStyle, ControlledTable } from "Components";
import {
  PatientInitialStateType,
  ScreenInitialStateType,
} from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { TableLayout } from "Layouts";
import { FormDataArr, generateRows, TableCol, transformData } from "./Data";
import PatientThreshold from "Components/PatientThreshold";
import { FormDataType } from "DataTypes/Form";
import { commonFormSwitch } from "Utils/SwitchCases";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { useDispatch } from "react-redux";
import {
  setIsOnlyTimer,
  setLeftHead,
  setNavArr,
  setRightHead,
  setTimerStatus,
  startLoading,
  stopLoading,
} from "Redux/Screen/Action";
import ImgPath from "Constant/Imgs";
import DeviceApi from "Service/Device.api";
import DeviceTypeFormatter from "Utils/DeviceType.Formatter";
import {
  DeviceDetailInfo,
  GraphDataset,
} from "DataTypes/Services/Problem.type";
import DateTime from "Utils/DateTime";

const DeviceDetails: FC = () => {
  const dispatch = useDispatch();
  const { patientInfo, selectedDevice }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const {
    navArr,
    rightHead: { timerStatus },
  }: ScreenInitialStateType = useSelector((state: any) => state?.screen);
  const [formArr, setFormArr] = useState<FormDataType[]>(FormDataArr());
  const [graphData, setGraphData] = useState<{ datasets: GraphDataset[] }>({
    datasets: [],
  });
  const [deviceReading, setDeviceReading] = useState<DeviceDetailInfo[] | null>(
    null
  );
  const [startDate, setStartDate] = useState<string | Date | null>(
    DateTime.CurrentMonthStartDate()
  );
  const [endDate, setEndDate] = useState<string | null>(
    DateTime.CurrentUTCDateTime()
  );

  useEffect(() => {
    fetchDeviceDetailsreading();
  }, [selectedDevice.deviceTypeId, startDate, endDate]);

  useEffect(() => {
    formArr[0].maxVal = DateTime.CurrentUTCDateTime();
    formArr[1].maxVal = DateTime.CurrentUTCDateTime();
    if (timerStatus == 1) {
      dispatch(setIsOnlyTimer(true));
      dispatch(setRightHead("care-plan"));
      dispatch(setLeftHead("device-details"));
      dispatch(setTimerStatus({ status: 2, parentApiCall: true }));
    }
    return () => {
      dispatch(setNavArr([...navArr.filter((el) => el.level < 4)]));
      dispatch(setIsOnlyTimer(false));
      dispatch(setRightHead(""));
      dispatch(setLeftHead(""));
      dispatch(setTimerStatus({ status: 1, parentApiCall: true }));
    };
  }, []);

  const fetchDeviceDetailsreading = async (): Promise<void> => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const payload = {
      data: {
        patientId: patientInfo.id,
        deviceTypeId: selectedDevice.deviceTypeId,
        dateFrom_param: startDate,
        dateTo_param: endDate,
      },
    };
    const deviceReadingRes = await DeviceApi.deviceDetailsReading(payload);
    if (deviceReadingRes.success) {
      const formattedData = DeviceTypeFormatter.DeviceDetailsReadingFormate(
        deviceReadingRes.data
      );
      setDeviceReading(formattedData);
      const updatedDatasets = transformData(
        formattedData,
        selectedDevice.deviceTypeId
      );
      setGraphData({
        datasets: updatedDatasets,
      });
    } else {
      setGraphData({
        datasets: [],
      });
    }
    dispatch(stopLoading());
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol([])}
      tableRow={generateRows(graphData.datasets)}
    />
  );

  const handleApply = (): void => {
    const newArr = ValidateFormData(formArr);
    setFormArr(newArr.formData);
    if (!newArr.errorCheck) {
      const startDate = DateTime.ToUTCDateTime(newArr.formData[0].dropValue);
      const endDate = DateTime.ToUTCDateTime(newArr.formData[1].dropValue);
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  return (
    <Fragment>
      <Box sx={{ ...sxTopHead }}>
        <Box sx={{ ...CommonStyle.sxHeading }}>
          {deviceReading &&
            deviceReading?.length > 0 &&
            deviceReading[0]?.average &&
            deviceReading[0]?.average.map((ele) => (
              <Fragment key={ele.readingType}>
                <Box>
                  <Typography variant="subtitle1">{`Avg. ${ele.readingTypeUomDescription}`}</Typography>
                  <Typography variant="subtitle1">{ele.average}</Typography>
                </Box>
                <Box sx={{ ...sxSperator }}></Box>
              </Fragment>
            ))}
        </Box>
        <Box sx={{ ...PickerBox }}>
          {formArr.slice(0, 1)?.map((el: FormDataType) => {
            return (
              <Fragment key={el.id}>
                {commonFormSwitch({
                  formObj: el,
                  onChange: (props) =>
                    setFormArr(HandleFormChange(props, formArr)),
                })}
              </Fragment>
            );
          })}
          <Typography sx={{ m: 1 }} variant="subtitle1" fontWeight="bold">
            TO
          </Typography>
          {formArr.slice(1, 2)?.map((el: FormDataType) => {
            return (
              <Fragment key={el.id}>
                {commonFormSwitch({
                  formObj: el,
                  onChange: (props) =>
                    setFormArr(HandleFormChange(props, formArr)),
                })}
              </Fragment>
            );
          })}
          <Typography sx={{ ml: 1, display: "flex" }}>
            <IconButton
              title="Apply Filter"
              sx={{ ...CommonStyle.sxRoundIconsMainSm, mr: 1 }}
              onClick={() => handleApply()}
            >
              <ImgPath.SaveIcon />
            </IconButton>
            <IconButton
              title="Refesh"
              sx={{ ...CommonStyle.sxRoundIconsMainSm }}
              onClick={() => {
                setFormArr(FormDataArr());
                setStartDate(DateTime.CurrentMonthStartDate());
                setEndDate(DateTime.CurrentUTCDateTime());
              }}
            >
              <ImgPath.RefreshIcon />
            </IconButton>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: "60%",
          width: "100%",
        }}
      >
        {/* <LineChart data={graphData} /> */}
      </Box>
      <Box sx={{ mt: 1, mb: 1 }}>
        <TableLayout
          tblBody={TblBodyJSX}
          tblHead={ControlledTable.TblHead(TableCol([]))}
          maxHeight="200px"
        />
      </Box>
      <Box sx={{ mb: 1 }}>
        <PatientThreshold
          showSaveButton={false}
          isSavedCounter={0}
          patientId={patientInfo.id}
          filterData={{
            deviceTypeId: selectedDevice.deviceTypeId,
          }}
        />
      </Box>
    </Fragment>
  );
};

export default DeviceDetails;

const sxTopHead = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  mt: 2,
  "& >div:nth-of-type(2)": {
    width: "30%",
    "@media screen and (max-width:1366px)": {
      width: "50%",
    },
    "& >div": {
      "& >div": {
        input: {
          p: 1,
          fontSize: "0.8em",
          textTransform: "uppercase",
        },
      },
    },
  },
};

const PickerBox = {
  display: "flex",
  alignItems: "center",
  mb: 1,
};

const sxSperator = {
  mr: 2,
  ml: 2,
  backgroundColor: "#c3c3c3",
  width: "0.1%",
};

{
  /* <Box sx={{ marginBottom: 2, display: "flex", gap: 2 }}>
          {["1d", "1w", "1m", "3m", "6m"].map((range) => (
            <Typography
              key={range}
              onClick={() => handleRangeChange(range)}
              sx={{
                cursor: "pointer",
                fontWeight: selectedRange === range ? "bold" : "normal",
                color:
                  selectedRange === range ? "primary.main" : "text.primary",
                "&:hover": {
                  color: "primary.dark",
                },
              }}
            >
              {range === "1d"
                ? "1 Day"
                : range === "1w"
                  ? "1 Week"
                  : range === "1m"
                    ? "1 Month"
                    : range === "3m"
                      ? "3 Months"
                      : "6 Months"}
            </Typography>
          ))}
        </Box> */
}
