import { Box, Divider } from "@mui/material";
import { CommonStyle } from "Components";
import CstmBtn from "Components/CstmBtn";
import { FormDataType } from "DataTypes/Form";
import { useState, useEffect } from "react";
import { commonFormSwitch } from "Utils/SwitchCases";
import { DataArr, IconClickArr } from "./Data";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { changesPwd } from "Service/Auth.api";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { setAlertData } from "Redux/Screen/Action";
import { useDispatch } from "react-redux";
import { UserInitialStateType } from "DataTypes/Redux";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const [formData, setFormData] = useState<FormDataType[] | []>([]);

  useEffect(() => {
    setFormData(JSON.parse(JSON.stringify(DataArr)));
    CommonFormatter.HandleNavArr({
      id: "change-pass",
      label: "Change Password",
      path: `/${RouteUrls.hs}/${RouteUrls.changePass}`,
      level: 1,
      link: RouteUrls.changePass,
      navType: "my-items",
    });
  }, []);

  const handlePassClick: (id: string) => void = (id) => {
    const newArr = formData.map((el) => {
      if (el.id == id) el.type = el.type == "text" ? "password" : "text";
      return el;
    });
    setFormData(JSON.parse(JSON.stringify(newArr)));
  };

  const handleChangePassword: () => void = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData || formData);
    if (!newArr.errorCheck) {
      if (newArr.formData[1].value == newArr.formData[2].value) {
        const oldPassword = newArr.formData[0].value;
        const newPassword = newArr.formData[1].value;
        const confirmPassword = newArr.formData[2].value;
        const payload: any = {
          data: {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
          },
        };
        const res: any = await changesPwd(myInfo?.id, payload);
        if (res?.success) {
          dispatch(setAlertData(res?.alert));
          localStorage.clear();
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        }
      } else {
        dispatch(
          setAlertData({
            alertMessage: "Both passwords are different.",
            openAlert: true,
            alertVariant: "error",
          })
        );
      }
    }
  };

  return (
    <Box
      height="91%"
      sx={{ ...CommonStyle.sxWhiteBox, overflow: "auto", px: 2 }}
    >
      <Box py={1}>
        <Box sx={{ opacity: 0, height: 0 }}>
          <input type="text" />
          <input type="password" />
        </Box>
        {formData.map((el: FormDataType) => {
          return (
            <Box key={el.id} width="25%" sx={{ mb: 1 }}>
              {commonFormSwitch({
                formObj: el,
                onChange: (data) =>
                  setFormData(HandleFormChange(data, formData)),
                iconClickArr: IconClickArr(handlePassClick),
              })}
            </Box>
          );
        })}
        <Divider sx={{ my: 2 }} />
        <Box
          justifyContent="flex-end"
          sx={{
            ...CommonStyle.sxRow,
            "& >button:first-of-type": {
              mr: 1,
            },
          }}
        >
          <CstmBtn
            label="Save"
            onClick={() => handleChangePassword()}
            width="10%"
          />
          <CstmBtn
            label="Cancel"
            onClick={() => navigate("/")}
            width="10%"
            btnType="border"
          />
        </Box>
      </Box>
    </Box>
  );
};
export default ChangePassword;
