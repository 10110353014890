import { ControlledForm } from "Components";
import { FormDataType } from "DataTypes/Form";
import {
  AlertSetHeadType,
  AlertSetSubHeadType,
} from "DataTypes/Services/Settings.type";

export const SubHead: AlertSetSubHeadType[] = [
  {
    id: "smsEnabled",
    label: "SMS",
  },
  {
    id: "emailEnabled",
    label: "E-mail",
  },
  {
    id: "pushEnabled",
    label: "Push",
  },
];

export const HeadData: AlertSetHeadType[] = [
  {
    id: "CRITICAL_LOW",
    label: "Critical Low",
    color: "error.light",
  },
  {
    id: "ABNORMAL_LOW",
    label: "Abnormal Low",
    color: "warning.light",
  },
  {
    id: "NORMAL",
    label: "Normal",
    color: "success.light",
  },
  {
    id: "ABNORMAL_HIGH",
    label: "Abnormal High",
    color: "warning.light",
  },
  {
    id: "CRITICAL_HIGH",
    label: "Critical High",
    color: "error.light",
  },
];

export const FormDataArr: () => FormDataType[] = () => {
  return [ControlledForm.FormData.SelectOrg()];
};
