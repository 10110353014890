import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";

export const TargetDateArr: FormDataType[] = [
  {
    id: "filter",
    boxType: "mui-datetime",
    boxSize: 3,
    validatetype: "date",
    required: true,
  },
];

export const TaskDeviceData: FormDataType[] = [
  {
    id: "deviceType",
    label: "Device Type",
    placeholder: "Enter Device Type",
    boxType: "input-box",
    readOnly: true,
    boxSize: 6,
    validatetype: "name",
    enableFormLoad: true,
  },
  {
    id: "deviceId",
    label: "Device ID",
    placeholder: "Enter Device ID",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "deviceId",
    enableFormLoad: true,
  },
];

export const TaskStatusData: FormDataType[] = [
  {
    id: "status",
    label: "Status",
    placeholder: "Select Status",
    boxType: "single-select-drop",
    dropValue: {
      id: 1,
      label: "Open",
      value: "Open",
    },
    dropArr: [
      {
        id: 1,
        label: "Open",
        value: "Open",
      },
      {
        id: 2,
        label: "Completed",
        value: "Completed",
      },
    ],
    boxSize: 6,
    validatetype: "dropdown",
    enableFormLoad: true,
  },
];

export const TaskFormDataArr = (
  typeArr: DropdownArrPropsType[]
): FormDataType[] => [
  {
    id: "source",
    label: "Source",
    required: true,
    placeholder: "Select Source",
    boxType: "single-select-drop",
    dropArr: [
      {
        id: 1,
        label: "Patient",
        value: "Patient",
      },
      {
        id: 2,
        label: "Authorized Representative",
        value: "Authorized Representative",
      },
      {
        id: 3,
        label: "Provider",
        value: "Provider",
      },
    ],
    boxSize: 6,
    validatetype: "dropdown",
    enableFormLoad: true,
  },
  {
    id: "type",
    label: "Task Type",
    placeholder: "Select Task Type",
    required: true,
    boxType: "single-select-drop",
    dropArr: typeArr,
    boxSize: 6,
    validatetype: "dropdown",
    enableFormLoad: true,
  },
  {
    id: "task-assign-to",
    label: "Assignee",
    placeholder: "Select Assignee",
    boxType: "single-select-drop",
    dropArr: [],
    required: true,
    boxSize: 6,
    validatetype: "dropdown",
    enableFormLoad: true,
  },
  {
    id: "subject",
    label: "Subject",
    placeholder: "Enter Subject",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
    enableFormLoad: true,
  },
  {
    id: "task-description",
    label: "Task Description",
    required: true,
    placeholder: "Enter Task Description",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "description",
    multiline: true,
    multilineRows: 3,
    enableFormLoad: true,
  },
  {
    id: "note",
    label: "Notes",
    placeholder: "Enter Notes",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
    multiline: true,
    multilineRows: 3,
    enableFormLoad: true,
  },
];

export const TaskPermission = {
  //create mode
  ADD: "add",

  //update mode
  INCOMING: "incoming",
  OUTGOING: "outgoing",

  //view mode
  VIEW: "view",

  //Super Admin access
  GLOBAL: "global",
};
