import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from "Service";

const Search: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "threshold/search",
    data: {
      data: payload.data,
      criterion: {
        offset: payload.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const Update: (payload: ApiServiceProps) => any = ({ data }) => {
  const request = {
    subUrl: "threshold/update",
    data: { data: data },
  };
  return post(request);
};

export default { Search, Update };
