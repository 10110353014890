import Stages from "Constant/Stages";
import {
  ActionIdType,
  PrgmDetailsType,
} from "DataTypes/PatientManagement.type";
import PatientFormatter from "Utils/Patient.Formatter";
import { AscendSortByNumber, CheckArr } from "Utils/common";

const PrgmDetails: (
  res: any,
  roleId: number | null,
  userId: number | null
) => PrgmDetailsType[] = (res, roleId, userId) => {
  //Refer to the rules text file in this folder before making any
  //changes in the code.
  const CheckedArr = CheckArr(res);
  const newArr = CheckedArr.map((el) => {
    const Obj = PatientFormatter.EligiblePrgmResObj(el, userId);
    const actionArr: ActionIdType[] = [];
    let adminRole = false;
    if (roleId == 1 || roleId == 2) adminRole = true;

    if (roleId != 6) {
      if (Obj.abbrevation == "TCM") {
        if (Obj.status == "Enrolled") {
          if (Obj.careplanState == Stages.Recurring) {
            if (Obj.seniorTeamMember) actionArr.push("un-enroll");
            actionArr.push("view-call-logs");
            actionArr.push("view-tcm");
          } else if (Obj.careplanState == Stages.CPAssignment) {
            actionArr.push("view-call-logs");
            actionArr.push("view-discharge");
          } else {
            if (Obj.seniorTeamMember) {
              actionArr.push("un-enroll");
              actionArr.push("tcm-call");
            } else {
              actionArr.push("view-call-logs");
              actionArr.push("view-tcm");
            }
          }
        } else {
          if (!adminRole) {
            if (Obj.status == "Not Enrolled") actionArr.push("tcm-enroll");
            else actionArr.push("tcm-re-enroll");
          }
        }
      } else if (Obj.abbrevation == "RPM") {
        if (Obj.status == "Enrolled") {
          if (Obj.careplanState == Stages.Recurring) {
            if (Obj.teamMember) {
              actionArr.push("un-enroll");
              actionArr.push("rpm-recurring-call");
            } else {
              actionArr.push("view-call-logs");
              actionArr.push("view-rpm-recurring-plan");
            }
          } else if (Obj.careplanState == Stages.CPAssignment) {
            actionArr.push("view-call-logs");
          } else {
            ///Changing conditions for coordinator role since coordinator can view care plan #9985
            if (Obj.seniorTeamMember) {
              actionArr.push("un-enroll");
              if (Obj.careplanState == Stages.CPSignOff) {
                actionArr.push("edit-treatment-plan");
              } else {
                actionArr.push("treatment-plan-call");
              }
            } else {
              actionArr.push("view-call-logs");
              actionArr.push("view-treatment-plan");
            }
          }
        } else {
          if (!adminRole) {
            if (Obj.status == "Not Enrolled") actionArr.push("enroll");
            else actionArr.push("re-enroll");
          }
        }
      } else {
        //for other programs
        if (Obj.status == "Enrolled") {
          //status == enroll
          if (Obj.careplanState == Stages.Recurring) {
            if (Obj.teamMember) {
              actionArr.push("un-enroll");
              actionArr.push("recurring-call");
            } else {
              actionArr.push("view-call-logs");
              actionArr.push("view-recurring-plan");
            }
          } else if (Obj.careplanState == Stages.CPAssignment) {
            actionArr.push("view-call-logs");
          } else {
            ///Changing conditions for coordinator role since coordinator can view care plan #9985
            if (Obj.seniorTeamMember) {
              actionArr.push("un-enroll");
              if (Obj.careplanState == Stages.CPSignOff) {
                actionArr.push("edit-care-plan");
              } else actionArr.push("care-plan-call");
            } else {
              actionArr.push("view-call-logs");
              actionArr.push("view-care-plan");
            }
          }
        } else {
          if (!adminRole) {
            if (Obj.status == "Not Enrolled") actionArr.push("enroll");
            else actionArr.push("re-enroll");
          }
        }
      }
    }

    return {
      ...Obj,
      actionArr,
      careplanId: Obj.status == "Enrolled" ? Obj.careplanId : null,
      careplanRecurringId:
        Obj.status == "Enrolled" ? Obj.careplanRecurringId : null,
    };
  });
  return AscendSortByNumber(newArr, "programId");
};

export default { PrgmDetails };
