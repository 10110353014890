import { CompleteCareType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const CompleteCareList: (data: any) => CompleteCareType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: CompleteCareType[] = CheckedArr.map((el, index) => {
    const provider = CheckStringEmpty(el?.teamProviderName);
    const providerDes = CheckStringEmpty(el?.teamProviderDesignation);
    const coord = CheckStringEmpty(el?.primaryCareCoordination);
    const coordDes = CheckStringEmpty(el?.primaryCareCoordinationDesignation);
    const staff = CheckStringEmpty(el?.clinicalStaff);
    const staffDes = CheckStringEmpty(el?.clinicalStaffDesignation);
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      activity: CheckStringEmpty(el?.activity),
      carecoordination: CheckStringEmpty(el?.careCoordination),
      careplanId: CheckNumber(el?.careplanId),
      careplanRecurringId: CheckNumber(el?.careplanRecurringId),
      careplanRecurringState: CheckStringEmpty(el?.careplanRecurringState),
      careplanState: CheckStringEmpty(el?.careplanState),
      clinicalStaff: staff ? `${staff}${staffDes ? ", " + staffDes : ""}` : "",
      duedate: DateTime.ValidDate(el?.dateOfCompletion),
      id: index,
      importance: CheckStringEmpty(el?.importance),
      patientId: CheckStringId(el?.id),
      primcarecoordinator: coord
        ? `${coord}${coordDes ? ", " + coordDes : ""}`
        : "",
      program: CheckStringEmpty(el?.abbrevation),
      programId: CheckNumber(el?.programId),
      provider: provider
        ? `${provider}${providerDes ? ", " + providerDes : ""}`
        : "",
      providerId: CheckNumber(el?.teamProviderId),
      status: CheckStringEmpty(el?.status),
    };
  });
  return newArr;
};

export default { CompleteCareList };
