import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ProgramBox from "./ProgramBox";
import ProblemBox from "./ProblemBox";
import { useSelector } from "react-redux";
import { PrgmListResType } from "DataTypes/Services/Prgm.type";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const ProgramProblem = () => {
  const programs: PrgmListResType[] = useSelector(
    (state: any) => state.list.programList
  );
  const [selectedPrgm, setSelectedPrgm] = useState<PrgmListResType | null>(
    null
  );
  const [programList, setProgramList] = useState<PrgmListResType[]>([]);

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "prgm-prbm-map",
      label: "Program Problem Mapping",
      path: `/${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.prgmProbMap}`,
      level: 1,
      link: RouteUrls.prgmProbMap,
      navType: "master",
    });
    setProgramList(formatData(programs));
  }, []);

  const formatData = (recievedData: PrgmListResType[]) => {
    const filterData = recievedData.filter(
      (data: PrgmListResType) => data.abbrevation != "TCM"
    );
    filterData?.length > 0 && setSelectedPrgm(filterData[0]);
    return filterData.map((data: PrgmListResType) => {
      return {
        ...data,
      };
    });
  };

  return (
    <Box display="flex" flexDirection="row" height="100%">
      <Box width="30%" sx={{ mr: 1.5 }}>
        <ProgramBox
          programList={programList}
          selectedPrgm={selectedPrgm}
          handleChangePrgm={(el) => setSelectedPrgm(el)}
        />
      </Box>
      <Box width="70%" overflow="auto">
        {selectedPrgm && selectedPrgm?.id && (
          <ProblemBox selectedPrgm={selectedPrgm} />
        )}
      </Box>
    </Box>
  );
};

export default ProgramProblem;
