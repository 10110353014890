import { useEffect, useState, FC } from "react";
import { Box, ClickAwayListener } from "@mui/material";
import { DropdownArrPropsType, MultiDropDataType } from "DataTypes/Form";
import MultiSelectDrop from "./Box";
import ControlledForm from "Components/ControlledForm";
const MultiSelectDropDown: FC<MultiDropDataType> = (
  props: MultiDropDataType
) => {
  const [openDrop, setOpenDrop] = useState(false);
  const [selectedItems, setSelectedItems] = useState<DropdownArrPropsType[]>(
    []
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [allSelectCheck, setAllSelectCheck] = useState(false);

  const {
    id,
    label,
    placeholder,
    dropArr,
    dropValue,
    required,
    onClick,
    width,
    error,
    errorText,
    disableSelectAll,
    disableArr,
    readOnly,
  } = props;
  const handleClick = () => {
    setOpenDrop(openDrop ? false : true);
  };

  useEffect(() => {
    const newArr: DropdownArrPropsType[] = [];
    dropValue?.forEach((el) => {
      const newObj = dropArr?.find((el1) => el1.value == el);
      newObj && newArr.push(newObj);
    });
    setInputValue(newArr.map((el) => el.label).join(", "));
    setSelectedItems(newArr);
  }, [dropValue, dropArr]);

  useEffect(() => {
    handleCheckAll();
  }, [selectedItems]);

  const handleSelect = (obj: DropdownArrPropsType) => {
    const found = selectedItems.find(
      (el: DropdownArrPropsType) => el.id == obj.id
    );
    let newArr;
    if (!found) {
      newArr = [...selectedItems, obj];
    } else {
      newArr = selectedItems.filter((el) => el.id !== obj.id);
    }
    setInputValue(newArr.map((el) => el.label).toString());
    setSelectedItems(newArr);
    onClick && onClick(newArr.map((el) => el.value) || []);
  };

  const handleSelectAll = () => {
    if (allSelectCheck) {
      setInputValue("");
      setSelectedItems([]);
      onClick && onClick([]);
    } else {
      const newArr: DropdownArrPropsType[] = JSON.parse(
        JSON.stringify(dropArr)
      );
      setInputValue(
        newArr.map((el: DropdownArrPropsType) => el.label).toString()
      );
      setSelectedItems(newArr);
      onClick && onClick(newArr.map((el) => el.value) || []);
    }
  };

  const handleCheckAll = () => {
    if (dropArr) {
      let allselect = true;
      for (let i = 0; i < dropArr?.length; i++) {
        const newObj = selectedItems.find(
          (el: DropdownArrPropsType) => el.id == dropArr[i].id
        );
        if (!newObj) {
          allselect = false;
          break;
        }
      }
      setAllSelectCheck(allselect);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenDrop(false)}>
      <Box sx={{ ...sxDrop }} width={width ? width : "100%"}>
        <ControlledForm.Input
          readOnly={readOnly}
          id={id}
          {...(label && { label: label })}
          {...(placeholder && { placeholder: placeholder })}
          {...(required && { required: required })}
          value={inputValue}
          iconClickHandler={() => handleClick()}
          iconId={openDrop ? "dropup" : "dropdown"}
          error={error ? error : false}
          errorText={errorText}
          required={required}
        />
        {openDrop && (
          <MultiSelectDrop
            {...(!disableSelectAll && { handleSelectAll: handleSelectAll })}
            allSelectCheck={allSelectCheck}
            dropArr={dropArr ? dropArr : []}
            selectedItems={selectedItems}
            handleSelect={(el) => handleSelect(el)}
            disableItems={disableArr || []}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};
export default MultiSelectDropDown;

const sxDrop = {
  position: "relative",
  cursor: "pointer",
};
