import { FC, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { addPatientInfo } from "Redux/Patient/Action";
import { useDispatch } from "react-redux";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

type IconProps = {
  disable?: boolean;
  sxProps?: any;
  title: React.ReactNode;
  icon: JSX.Element;
  none?: boolean;
  tooltipDelay?: number;
  autoTooltipDisplay?: boolean;
};

const CstmTooltip: FC<IconProps> = (props: IconProps) => {
  const dispatch = useDispatch();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const {
    disable,
    tooltipDelay,
    none,
    icon,
    title,
    sxProps,
    autoTooltipDisplay,
  } = props;

  useEffect(() => {
    if (autoTooltipDisplay && !disable && !none) {
      setTooltipOpen(true);
      const timer = setTimeout(() => {
        setTooltipOpen(false);
      }, tooltipDelay);

      return () => {
        clearTimeout(timer);
        dispatch(
          addPatientInfo({ ...patientInfo, firstTimeNotesVisit: false })
        );
      };
    }
  }, [autoTooltipDisplay, disable, none, tooltipDelay]);

  if (disable || none) {
    return <>{icon}</>;
  }

  return (
    <Tooltip
      open={tooltipOpen}
      onClose={() => setTooltipOpen(false)}
      onOpen={() => setTooltipOpen(true)}
      title={title}
      arrow
      sx={sxProps}
      enterDelay={autoTooltipDisplay ? 0 : undefined}
    >
      {icon}
    </Tooltip>
  );
};

export default CstmTooltip;
