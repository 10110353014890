import { FC } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { CommonStyle } from "Components";
import { ThemeProvider, Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { InputType } from "DataTypes/Form";
import InputLabel from "../Input/Label";
import dayjs, { Dayjs } from "dayjs";
import { Locale } from "Constant/En";
import DateTime from "Utils/DateTime";

const newTheme = (appTheme: any) =>
  createTheme({
    ...appTheme,
    components: {
      MuiPickersLayout: {
        styleOverrides: {
          contentWrapper: {
            paddingTop: 10,
          },
        },
      },
      MuiDayPicker: {
        styleOverrides: {
          header: {
            "& >span": {
              width: 30,
              height: 30,
              margin: 0,
            },
          },
          weekContainer: {
            margin: 0,
          },
          slideTransition: {
            minHeight: 190,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            width: 30,
            height: 30,
            margin: 0,
          },
        },
      },
      MuiCalendarOrClockPicker: {
        styleOverrides: {
          root: {
            "&>div": {
              width: 250,
              maxHeight: 260,
            },
          },
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            width: "100%",
            maxHeight: 260,
          },
          viewTransitionContainer: {
            maxHeight: 220,
            minHeight: 220,
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxHeight: 40,
            minHeight: 40,
            margin: 0,
            padding: 0,
          },
          labelContainer: {
            maxHeight: 40,
            minHeight: 40,
            margin: 0,
            fontSize: 14,
            marginLeft: 15,
          },
          switchViewButton: {
            padding: 0,
            margin: 0,
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          root: {
            "& >button": {
              padding: 0,
              ":last-of-type": {
                marginRight: 10,
              },
            },
          },
        },
      },
      MuiYearPicker: {
        styleOverrides: {
          root: {
            padding: 0,
            maxHeight: 220,
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          root: {
            height: "100%",
          },
          weekDayLabel: {
            width: 30,
            height: 30,
            margin: 0,
          },
          slideTransition: {
            minHeight: 190,
          },
          weekContainer: {
            margin: 0,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 0,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 15,
            paddingLeft: 15,
            justifyContent: "space-between",
            "& >button": {
              fontSize: 12,
              fontWeight: "bold",
              minWidth: 0,
              padding: 0,
              borderRadius: 0,
            },
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            width: 240,
            maxHeight: 260,
            margin: 0,
            height: 260,
          },
          viewTransitionContainer: {
            height: 220,
            "& >div": {
              height: "100%",
            },
          },
        },
      },
      MuiYearCalendar: {
        styleOverrides: {
          root: {
            width: 240,
            maxHeight: 220,
            padding: 0,
            height: "100%",
          },
        },
      },
      MuiMultiSectionDigitalClock: {
        styleOverrides: {
          root: {
            maxHeight: 260,
          },
        },
      },
      MuiMultiSectionDigitalClockSection: {
        styleOverrides: {
          root: {
            width: 40,
            "::after": {
              height: 0,
            },
          },
          item: {
            padding: 0,
            margin: 0,
            marginLeft: 2,
            marginRight: 2,
            width: 30,
            height: 30,
            minHeight: 30,
            fontSize: 12,
            borderRadius: "50%",
          },
        },
      },
    },
  });

const NewDateTimePicker: FC<InputType> = (props) => {
  const {
    label,
    id,
    required,
    readOnly,
    error,
    errorText,
    inputVal,
    minVal,
    maxVal,
    inputChange,
  } = props;

  return (
    <ThemeProvider theme={newTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {label && (
          <InputLabel label={label} required={required ? required : false} />
        )}
        <DesktopDateTimePicker
          format={Locale.dateTime}
          {...(inputVal && { value: dayjs(inputVal) })}
          onChange={(val: Dayjs | null) => {
            const dateTime = DateTime.ToLocalDateTime(
              val?.format(Locale.dateTime)
            );
            inputChange && inputChange(dateTime);
          }}
          disabled={readOnly ? readOnly : false}
          {...(minVal && { minDateTime: dayjs(minVal) })}
          {...(maxVal && { maxDateTime: dayjs(maxVal) })}
          slotProps={{
            textField: {
              name: id,
              sx: {
                ...(readOnly
                  ? { ...sxReadOnly }
                  : { ...CommonStyle.sxWhiteBox }),
                "& >div": { ...sxInput },
              },
              error: error ? error : false,
              fullWidth: true,
            },
            actionBar: { actions: ["clear", "today", "cancel", "accept"] },
          }}
          timeSteps={{ minutes: 1 }}
          closeOnSelect={false}
        />
        {error && (
          <Typography variant="subtitle2" color="red">
            {errorText}
          </Typography>
        )}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default NewDateTimePicker;

const sxInput = {
  fontSize: "0.85rem",
  px: 0.5,
  py: 0,
  "& >input": {
    px: 0.5,
    py: 1,
  },
  "& >fieldset": {
    borderWidth: 0,
  },
  "& >div": {
    "&>button": {
      padding: 0,
      margin: 0,
      "& >svg": {
        width: 20,
        height: 20,
      },
    },
  },
};

const sxReadOnly = {
  backgroundColor: "custom.grey",
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: 1.25,
  " >fieldset": {
    display: "none",
  },
};
