import {
  MasterQuestionType,
  QuestionOptionType,
} from "DataTypes/Services/Question.type";
import {
  AscendSortByNumber,
  CheckAnsType,
  CheckArr,
  CheckBoolean,
  CheckNumber,
  CheckStringEmpty,
} from "./common";
import PrgmFormatter from "./Prgm.Formatter";
import ProblemFormatter from "./Prob.Formatter";
import VisitFormatter from "./Visit.Formatter";
import { AnsDropArr } from "Constant/DropArr";

const CheckMasterQuestionArr: (res: any) => MasterQuestionType[] = (res) => {
  const CheckedArr = CheckArr(res);
  const newArr: MasterQuestionType[] = CheckedArr.map((el) =>
    CheckMasterQuestion(el)
  );
  return newArr;
};

const CheckMasterQuestion: (data: any) => MasterQuestionType = (data) => {
  const find = AnsDropArr.find((el) => el.value == CheckAnsType(data?.type));
  return {
    id: CheckNumber(data?.id),
    categoryId: CheckNumber(data?.category_id),
    parentQuestionId: CheckNumber(data?.parent_question_id),
    parentOptionId: CheckNumber(data?.parent_option_id),
    question: CheckStringEmpty(data?.question),
    type: CheckAnsType(data?.type),
    typeLabel: find?.label || "",
    programs: PrgmFormatter.QuestionPrgmArr(data?.programs),
    problems: ProblemFormatter.QuestionProblemArr(data?.problems),
    visittypes: VisitFormatter.QuestionVisitArr(data?.visittypes),
    options: CheckQuestionOptions(data?.options),
    questionRequired: CheckBoolean(data?.required_question),
  };
};

const CheckQuestionOptions: (data: any) => QuestionOptionType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: QuestionOptionType[] = CheckedArr.map((el) => {
    return {
      id: el?.id,
      option: el?.option,
      requiredOption: el?.requiredOption,
    };
  });
  AscendSortByNumber(newArr, "id");
  return newArr;
};

export default {
  CheckMasterQuestionArr,
  CheckMasterQuestion,
  CheckQuestionOptions,
};
