import { useState, FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Radio, Typography } from "@mui/material";
import { CpProblemCol1, FormArr1, actionArr } from "./Data";
import CstmBtn from "Components/CstmBtn";
import { CommonStyle, ControlledTable, Loader } from "Components";
import PatientApi from "Service/Patient.api";
import ProgramApi from "Service/Program.api";
import ProblemApi from "Service/Problem.api";
import {
  DropdownArrPropsType,
  FormObjType,
  HandleChangeType,
  HandleClickType,
} from "DataTypes/Form";
import CarePlanProblemsApi from "Service/CarePlan.api";
import { useDispatch } from "react-redux";
import {
  PatientInitialStateType,
  ScreenInitialStateType,
} from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { RouteUrls } from "Constant/RouteUrls";
import {
  setAlertData,
  setRightHead,
  setTimerStatus,
  startLoading,
  stopLoading,
} from "Redux/Screen/Action";
import { CheckCPComprehensive, CheckNumber } from "Utils/common";
import NoData from "Components/NoData";
import { TableLayout } from "Layouts";
import ProbFormatter from "Utils/Prob.Formatter";
import { FormatDropdownData } from "Utils/DataFormatter";
import CPFormatter from "Utils/CP.Formatter";
import { ICDCodeType } from "DataTypes/Services/Problem.type";
import { FormObjSwitch } from "Utils/SwitchCases";
import PatientFormatter from "Utils/Patient.Formatter";
import Stages from "Constant/Stages";
import DateTime from "Utils/DateTime";
import CarePlanApi from "Service/CarePlan.api";
import { RedoxProblemAltCodeType } from "DataTypes/Services/Patient.type";
import RedoxFormatter from "Utils/Redox.Formatter";
import { CPProblemType } from "DataTypes/Services/Careplan.type";
import Format from "./Format";
import { addSelectedPrgm } from "Redux/Patient/Action";
import CommonFormatter from "Utils/Common.Formatter";

const Problems: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientInfo, selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const careId = selectedPrgm.careplanId;
  const currentUrl = window.location.href;
  const isRPM =
    currentUrl.includes(RouteUrls.treatmentPlan) ||
    currentUrl.includes(RouteUrls.revisionPlan);
  const readOnlyUptoDate = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "uptoDate")
    : true;
  const readOnlyProblems = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "problem")
    : true;
  const {
    rightHead: { timerStatus },
  }: ScreenInitialStateType = useSelector((state: any) => state?.screen);
  ///Checked Variables
  const [cpc, setCPC] = useState(false);
  const [redoxLoad, setRedoxLoad] = useState(true);
  const [tblRows, setTblRows] = useState<CPProblemType[]>([]);
  const [icdArr, setIcdArr] = useState<ICDCodeType[]>([]);
  const [formData, setFormData] = useState<FormObjType[]>(FormArr1(isRPM));
  const [redoxPrblm, setRedoxPrblm] = useState<RedoxProblemAltCodeType[]>([]);

  ///Checked Functions
  useEffect(() => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    handleRedoxCall();
    if (isRPM) {
      if (timerStatus == 2)
        dispatch(setTimerStatus({ status: 1, parentApiCall: true }));
      dispatch(setRightHead(""));
    }
  }, []);

  const handleRedoxCall: () => void = async () => {
    const res = await PatientApi.RedoxProblemSearch({ id1: patientInfo.id });
    if (res?.success) {
      setRedoxPrblm(RedoxFormatter.ProblemAltCodeList(res?.data));
    }
    setRedoxLoad(false);
  };

  useEffect(() => {
    initialLoad();
  }, [careId]);

  const initialLoad: () => void = async () => {
    let newFormArr: FormObjType[] = JSON.parse(JSON.stringify(formData));
    const newArr = await handleCPProblemCall("");
    if (newFormArr[0].boxType == "single-select")
      newFormArr[0].dropArr = newArr;
    const cpProblemArr = await handleCPProblemSearch();
    const newArr1 = cpProblemArr.map((el) => el.id || "");
    formData[1].boxType == "multi-select" &&
      setFormData([{ ...formData[0] }, { ...formData[1], dropVal: newArr1 }]);
    formData[1].boxType == "single-select" &&
      setFormData([
        { ...formData[0] },
        { ...formData[1], dropVal: newArr1[0] },
      ]);
    setTblRows(cpProblemArr);
    if (newFormArr[1].boxType == "multi-select") {
      newFormArr[1].dropVal = cpProblemArr.map((el) => el.icdCodeId || "");
    }
    if (newFormArr[1].boxType == "single-select") {
      newFormArr[1].dropVal = cpProblemArr.map((el) => el.icdCodeId || "")[0];
    }
    if (readOnlyProblems) {
      newFormArr = newFormArr.map((el) => {
        el.readOnly = true;
        return el;
      });
    }
    setFormData(newFormArr);
    dispatch(stopLoading());
  };

  const handleCPProblemCall: (
    searchVal: string
  ) => Promise<DropdownArrPropsType[]> = async (searchVal) => {
    const payload = { problemName: searchVal };
    const res = await ProgramApi.mappedProblemList({
      id1: selectedPrgm?.programId || null,
      limit: 10000,
      offset: 0,
      data: payload,
    });
    if (res?.success) {
      const formatRes = ProbFormatter.MappedProblems(res?.data);
      return FormatDropdownData(formatRes, "id", "name", "id");
    } else return [];
  };

  const handleCPICDCodeCall: (
    searchVal: string,
    probId: number | null
  ) => Promise<DropdownArrPropsType[]> = async (searchVal, probId) => {
    const payload = { search: searchVal };
    const res = await ProblemApi.icdCodeSearch({
      id1: probId,
      data: payload,
      limit: 10000,
      offset: 0,
    });
    if (res?.success) {
      const formatRes = ProbFormatter.IcdCodeSearch(res?.data);
      setIcdArr(formatRes);
      return formatRes.map((el) => {
        return {
          id: el.icdCodeId || "",
          label: `${el.icdCodeName} - ${el.icdCodeDescription}`,
          value: el.icdCodeId || "",
        };
      });
    } else return [];
  };

  const handleCPProblemSearch: () => Promise<CPProblemType[]> = async () => {
    const res = await CarePlanProblemsApi.careplanProblemsRead({
      id1: careId,
    });
    if (res?.success) {
      const val = CheckCPComprehensive(res?.data?.isCareplanComprehensive);
      setCPC(val == "yes" ? true : false);
      return CPFormatter.CheckCPProblem(res?.data?.careplanProblem);
    } else return [];
  };

  const handleInputChange: (props: HandleChangeType) => void = async (
    props
  ) => {
    const { id, inputVal } = props;
    const newInputVal = typeof inputVal == "string" ? inputVal : "";
    const newFormArr: FormObjType[] = JSON.parse(JSON.stringify(formData));
    if (
      newFormArr[0].boxType == "single-select" &&
      (newFormArr[1].boxType == "multi-select" ||
        newFormArr[1].boxType == "single-select")
    ) {
      if (id == "cp-problem") {
        newFormArr[0].loading = true;
        newFormArr[0].inputVal = newInputVal;
        setFormData(newFormArr);
        newFormArr[0].dropArr = await handleCPProblemCall(newInputVal);
        newFormArr[0].loading = false;
        newFormArr[0].dropVal = newInputVal ? newFormArr[0].dropVal : null;
      }
      if (id == "cp-icdCodes") {
        newFormArr[1].loading = true;
        newFormArr[1].inputVal = newInputVal;
        setFormData(newFormArr);
        const probId = CheckNumber(newFormArr[0].dropVal);
        newFormArr[1].dropArr = probId
          ? await handleCPICDCodeCall(newInputVal, probId)
          : [];
        newFormArr[1].loading = false;
      }
      setFormData(JSON.parse(JSON.stringify(newFormArr)));
    }
  };

  const handleClickChange: (props: HandleClickType) => void = async (props) => {
    const { id, dropVal, boxType } = props;
    const newFormArr: FormObjType[] = JSON.parse(JSON.stringify(formData));
    if (
      newFormArr[0].boxType == "single-select" &&
      (newFormArr[1].boxType == "multi-select" ||
        newFormArr[1].boxType == "single-select")
    ) {
      if (id == "cp-problem" && boxType == "single-select") {
        const newDropVal = CheckNumber(dropVal);
        newFormArr[1].loading = true;
        newFormArr[1].inputVal = "";
        setFormData(newFormArr);
        newFormArr[1].dropArr = newDropVal
          ? await handleCPICDCodeCall("", newDropVal)
          : [];
        newFormArr[1].loading = false;
        newFormArr[0].dropVal = dropVal;
        const findProb = newFormArr[0].dropArr?.find(
          (el) =>
            newFormArr[0].boxType == "single-select" && el.value == dropVal
        );
        newFormArr[0].inputVal = findProb?.label || "";
      }
      if (id == "cp-icdCodes" && boxType == "multi-select") {
        if (tblRows.length > dropVal.length) {
          //value removed
          const find = tblRows.find(
            (el) => el.id && dropVal.indexOf(el.id) == -1
          );
          find && setTblRows(tblRows.filter((el) => el.id != find.id));
        } else {
          //value added
          const newArr = tblRows.map((el) => el.id);
          const find = dropVal.find((el) => el && newArr.indexOf(el) == -1);
          const find1 = icdArr.find((el) => el.id == find);
          const findProbObj = newFormArr[0].dropArr?.find(
            (el) =>
              newFormArr[0].boxType == "single-select" &&
              el.value == CheckNumber(newFormArr[0].dropVal)
          );
          find1 &&
            findProbObj &&
            setTblRows([
              {
                id: find1.id,
                problemId: CheckNumber(findProbObj.value),
                problemName: findProbObj.label,
                icdCodeId: find1.icdCodeId,
                icdCode: find1.icdCodeName,
                icdCodeDescription: find1.icdCodeDescription,
              },
              ...tblRows,
            ]);
        }
        newFormArr[1].dropVal = dropVal;
      } else if (id == "cp-icdCodes" && boxType == "single-select") {
        const findProbObj = newFormArr[0].dropArr?.find(
          (el) =>
            newFormArr[0].boxType === "single-select" &&
            el.value === CheckNumber(newFormArr[0].dropVal)
        );

        const newSelection = dropVal;
        const find1 = icdArr.find((el) => el.id === newSelection);

        if (find1 && findProbObj) {
          // Check if the problem already exists in the table
          const existingIndex = tblRows.findIndex(
            (row) => row.problemId === CheckNumber(findProbObj.value)
          );

          if (existingIndex !== -1) {
            // Update existing entry
            const updatedTblRows = [...tblRows];
            updatedTblRows[existingIndex] = {
              ...updatedTblRows[existingIndex],
              icdCodeId: find1.icdCodeId,
              icdCode: find1.icdCodeName,
              icdCodeDescription: find1.icdCodeDescription,
            };
            setTblRows(updatedTblRows);
          } else {
            // Add new entry
            setTblRows([
              {
                id: find1.id,
                problemId: CheckNumber(findProbObj.value),
                problemName: findProbObj.label,
                icdCodeId: find1.icdCodeId,
                icdCode: find1.icdCodeName,
                icdCodeDescription: find1.icdCodeDescription,
              },
              ...tblRows,
            ]);
          }

          newFormArr[1].dropVal = dropVal;
          // newFormArr[1].inputVal = findProbObj?.label;
        }
      }
      setFormData(JSON.parse(JSON.stringify(newFormArr)));
    }
  };

  const handleUpdateApiCall: () => void = async () => {
    let apiCall = true;
    if (tblRows.length < 1) apiCall = false;
    const probIdArr = tblRows.map((el) => el.problemId);
    if (
      selectedPrgm.abbrevation == "RPM" &&
      !CommonFormatter.CheckDistinctArrVal(probIdArr)
    ) {
      apiCall = false;
    }
    if (tblRows.length < 1) {
      dispatch(
        setAlertData({
          alertMessage: "Please select atleast one icd problem to proceed",
          alertVariant: "error",
          openAlert: true,
        })
      );
    } else if (
      selectedPrgm.abbrevation == "RPM" &&
      !CommonFormatter.CheckDistinctArrVal(probIdArr)
    ) {
      dispatch(
        setAlertData({
          alertMessage:
            "Only one icd code is allowed per problem for RPM program",
          alertVariant: "error",
          openAlert: true,
        })
      );
    } else {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const payloadObj = {
        problems: Format.CreatePayload1(tblRows),
        isComprehensive: cpc ? "Yes" : "No",
      };
      const res = await CarePlanProblemsApi.careplanProblemUpdate({
        id1: careId,
        data: payloadObj,
      });
      if (res?.success == true) {
        dispatch(setAlertData(res?.alert));
        if (cpc) {
          const payload = { hasCareplanQuestionnaireCompleted: true };
          const updateStatusRes = await CarePlanApi.CpQuestionStatusUpdate({
            id1: careId,
            data: payload,
          });
          if (updateStatusRes?.success) {
            selectedPrgm &&
              dispatch(
                addSelectedPrgm({
                  ...selectedPrgm,
                  ...(selectedPrgm.careplanStage >
                  PatientFormatter.GetCPStage(Stages.CPProblem)
                    ? {}
                    : {
                      careplanState: Stages.CPTeam,
                      careplanStage: PatientFormatter.GetCPStage(
                        Stages.CPTeam
                      ),
                    }),
                  isCareplanComprehensive: true,
                })
              );
            navigate(`../${RouteUrls.careTeam}`, { replace: true });
          }
        } else {
          if (selectedPrgm) {
            ///For RPM Program Changes
            if (selectedPrgm.abbrevation == "RPM") {
              dispatch(
                addSelectedPrgm({
                  ...selectedPrgm,
                  ...(selectedPrgm.careplanStage >
                  PatientFormatter.GetCPStage(Stages.CPProblem)
                    ? {}
                    : {
                      careplanState: Stages.CPDeviceAssign,
                      careplanStage: PatientFormatter.GetCPStage(
                        Stages.CPDeviceAssign
                      ),
                    }),
                  isCareplanComprehensive: false,
                })
              );
              navigate(`../${RouteUrls.rpmAddDevice}`, { replace: true });
            } else {
              dispatch(
                addSelectedPrgm({
                  ...selectedPrgm,
                  ...(selectedPrgm.careplanStage >
                  PatientFormatter.GetCPStage(Stages.CPProblem)
                    ? {}
                    : {
                      careplanState: Stages.CPQues,
                      careplanStage: PatientFormatter.GetCPStage(
                        Stages.CPQues
                      ),
                    }),
                  isCareplanComprehensive: false,
                })
              );
              navigate(`../${RouteUrls.careQuestion}`, { replace: true });
            }
          }
        }
      }
      dispatch(stopLoading());
    }
  };

  const handleDelete: (data: CPProblemType) => void = (data) => {
    const newVal = [...tblRows.filter((el) => el.id != data.id)];
    const newArr = newVal.map((el) => el.id || "");
    if (formData[1].boxType === "multi-select") {
      // Handle multi-select scenario
      setFormData([{ ...formData[0] }, { ...formData[1], dropVal: newArr }]);
    } else if (formData[1].boxType === "single-select") {
      // Handle single-select scenario
      if (newArr.length > 0) {
        // Set the first item as the selected value if available
        const newSelectedValue = newArr[0];
        setFormData([
          { ...formData[0] },
          { ...formData[1], dropVal: newSelectedValue },
        ]);
      } else {
        // If no items are left, clear the selected value
        setFormData([{ ...formData[0] }, { ...formData[1], dropVal: null }]);
      }
    }
    setTblRows(newVal);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => CpProblemCol1}
      tableRow={tblRows}
      tableAction={(data: CPProblemType) =>
        readOnlyProblems ? [] : actionArr(() => handleDelete(data))
      }
    />
  );

  return (
    <Box width="91%" sx={{ ...CommonStyle.sxWhiteCard, overflow: "auto" }}>
      <Box display={"flex"}>
        <Box width={"70%"}>
          <Typography fontWeight={"bold"}>
            {`${
              selectedPrgm.abbrevation == "RPM" ? "Treatment Plan" : "Care Plan"
            }`}{" "}
            Problems
          </Typography>
          {selectedPrgm?.careplanInitDateInEhr && (
            <Box mb={1}>
              <Typography variant="body2">
                Is the Care Plan initiated on{" "}
                {DateTime.ToLocalDate(selectedPrgm.careplanInitDateInEhr)} by
                the provider in the EHR comprehensive and up to date?
              </Typography>
              <Box display="flex" alignItems="center">
                <Radio
                  checked={cpc}
                  value="yes"
                  color="secondary"
                  onChange={() => setCPC(true)}
                  disabled={readOnlyUptoDate}
                />
                <Typography variant="subtitle1" mr={3}>
                  Yes
                </Typography>
                <Radio
                  checked={!cpc}
                  value="no"
                  color="secondary"
                  onChange={() => setCPC(false)}
                  disabled={readOnlyUptoDate}
                />
                <Typography variant="subtitle1">No</Typography>
              </Box>
            </Box>
          )}
          {formData.map((formEl) => {
            return (
              <Box width="90%" key={formEl.id}>
                {FormObjSwitch({
                  formObj: formEl,
                  inputChange: handleInputChange,
                  onClick: handleClickChange,
                })}
              </Box>
            );
          })}
          <Box mt={2} width={"89%"}>
            <TableLayout
              tblBody={TblBodyJSX}
              tblHead={ControlledTable.TblHead(CpProblemCol1)}
              height={""}
            />
          </Box>
        </Box>
        <Box width="30%">
          <Typography fontWeight={"bold"}>
            {"Patient's Problem List"}
          </Typography>
          {redoxLoad ? (
            <Loader />
          ) : redoxPrblm.length ? (
            <Box height="300px" overflow="auto">
              {redoxPrblm.map((el) => {
                return (
                  <Typography
                    sx={{ ...sxList }}
                    key={el.id}
                    variant="body2"
                  >{`${el.altCode + " -"} ${el.altCodeName}`}</Typography>
                );
              })}
            </Box>
          ) : (
            <NoData />
          )}
        </Box>
      </Box>
      {readOnlyProblems ? (
        (selectedPrgm?.careplanStage || 0) >
          PatientFormatter.GetCPStage(Stages.CPProblem) && (
          <Box sx={{ ...CommonStyle.sxRow, justifyContent: "center", mt: 2 }}>
            <CstmBtn
              label={"Next"}
              onClick={() =>
                navigate(
                  `../${
                    cpc
                      ? RouteUrls.careTeam
                      : selectedPrgm.abbrevation == "RPM"
                        ? RouteUrls.rpmAddDevice
                        : RouteUrls.careQuestion
                  }`,
                  { replace: true }
                )
              }
              width="10%"
            />
          </Box>
        )
      ) : (
        <Box sx={{ ...CommonStyle.sxRow, justifyContent: "center", mt: 2 }}>
          <CstmBtn
            label={"Save & Next"}
            onClick={() => handleUpdateApiCall()}
            width="10%"
          />
        </Box>
      )}
    </Box>
  );
};
export default Problems;

const sxList = {
  pt: 1.5,
  pb: 1,
  mr: 1,
  borderBottom: "1px solid",
  borderBottomColor: "custom.main",
};
