import {
  patientImportSearchType,
  PracticeDirectType,
  PracticeListApiType,
  PracticeReadApiType,
  PracticeReadLocationType,
  PracticeUserType,
} from "DataTypes/Services/Practice.type";
import {
  AscendSortByNumber,
  CheckArr,
  CheckBoolean,
  CheckNumber,
  CheckStatus,
  CheckStringEmpty,
} from "./common";
import PrgmFormatter from "./Prgm.Formatter";
import DateTime from "./DateTime";

const VerifyListArrResponse: (data: any) => PracticeListApiType[] = (data) => {
  const newArr: PracticeListApiType[] = CheckArr(data).map((checkEl) =>
    VerifyListObjResponse(checkEl)
  );
  return newArr;
};

const VerifyListObjResponse: (data: any) => PracticeListApiType = (data) => {
  return {
    id: CheckNumber(data?.id),
    name: CheckStringEmpty(data?.name),
    businessModels: {
      id: CheckNumber(data?.businessModels?.id),
      name: CheckStringEmpty(data?.businessModels?.name),
    },
    program: PrgmFormatter.PrgmListRes(data?.program),
    masterPractice: CheckBoolean(data?.masterPractice),
    status: CheckStatus(data?.status),
  };
};

const VerifyReadResponse: (data: any) => PracticeReadApiType = (data) => {
  return {
    npi: CheckStringEmpty(data?.npi),
    user: VerifyOrgUserResponse(data?.user),
    practiceLocations: VerifyOrgLocationResponse(data?.practiceLocations),
    ...VerifyListObjResponse(data),
  };
};

const VerifyOrgUserResponse: (data: any) => PracticeUserType[] = (data) => {
  const newArr = CheckArr(data).map((verEl) => {
    return {
      id: CheckNumber(verEl?.id),
      firstName: CheckStringEmpty(verEl?.firstName),
      email: CheckStringEmpty(verEl?.email),
      secondaryEmail: CheckStringEmpty(verEl?.secondaryEmail),
      isPracticeAdmin: CheckBoolean(verEl?.isPracticeAdmin),
    };
  });
  return AscendSortByNumber(newArr, "id");
};

const VerifyOrgLocationResponse: (data: any) => PracticeReadLocationType[] = (
  data
) => {
  let newArr: PracticeReadLocationType[] = CheckArr(data).map((verEl) => {
    return {
      id: CheckNumber(verEl?.id),
      location1: CheckStringEmpty(verEl?.location1),
      address1: CheckStringEmpty(verEl?.address1),
      address2: CheckStringEmpty(verEl?.address2),
      city: CheckStringEmpty(verEl?.city),
      state: CheckStringEmpty(verEl?.state),
      zipCode: CheckStringEmpty(verEl?.zipCode),
      country: CheckStringEmpty(verEl?.country),
      officeNumber: CheckStringEmpty(verEl?.officeNumber),
      practiceLocationDirectNumbers: VerifyOrgDirectResponse(
        verEl?.practiceLocationDirectNumbers
      ),
      isMasterPracticeLocation: CheckBoolean(verEl?.isMasterPracticeLocation),
      practiceLocationOrder: CheckNumber(verEl?.practiceLocationOrder),
      serviceLocation: CheckStringEmpty(verEl?.serviceLocation),
      timezone: CheckStringEmpty(verEl?.timezone),
      status: CheckStatus(verEl?.status),
    };
  });
  newArr = AscendSortByNumber(
    JSON.parse(JSON.stringify(newArr)),
    "practiceLocationOrder"
  );
  return newArr;
};

const VerifyOrgDirectResponse: (data: any) => PracticeDirectType[] = (data) => {
  const newArr: PracticeDirectType[] = CheckArr(data).map((verEl) => {
    return {
      id: CheckNumber(verEl?.id),
      directNumber: CheckStringEmpty(verEl?.directNumber),
      status: CheckStatus(verEl?.status),
    };
  });
  return AscendSortByNumber(newArr, "id");
};

const patientImportSearch: (data: any) => patientImportSearchType[] = (
  data
) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    const failedFilePath = CheckStringEmpty(el?.failedFilePath);
    const importFilePath = CheckStringEmpty(el?.importFilePath);
    const failedFileName =
      failedFilePath &&
      CheckStringEmpty(failedFilePath.split("/").pop()).trim();
    const importFileName =
      importFilePath &&
      CheckStringEmpty(importFilePath.split("/").pop()).trim();
    return {
      id: CheckNumber(el?.id),
      enddatetime: DateTime.ValidDate(el.endTime),
      errorList: CheckStringEmpty(el?.errorList),
      failedFilePath: failedFilePath,
      importFilePath: importFilePath,
      practiceId: CheckNumber(el?.practiceId),
      practiceName: CheckStringEmpty(el?.practiceName),
      practiceLocationId: CheckNumber(el?.practiceLocationId),
      locationName: CheckStringEmpty(el?.locationName),
      startdatetime: DateTime.ValidDate(el.startTime),
      status: CheckStringEmpty(el?.status),
      title: CheckStringEmpty(el?.title),
      userId: CheckNumber(el?.userId),
      userFullName: CheckStringEmpty(el?.userFullName),
      failedFileName: failedFileName,
      fileName: importFileName,
    };
  });
};

export default {
  VerifyListArrResponse,
  VerifyListObjResponse,
  VerifyReadResponse,
  VerifyOrgUserResponse,
  VerifyOrgLocationResponse,
  VerifyOrgDirectResponse,
  patientImportSearch,
};
