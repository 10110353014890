import { FC, useEffect, Fragment, useRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import CstmBtn from "Components/CstmBtn";
import ImgPath from "Constant/Imgs";
import { LogtimeProps } from "DataTypes/PatientManagement.type";
import { label } from "Constant/En";
import { useSelector } from "react-redux";
import {
  PatientInitialStateType,
  ScreenInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { useDispatch } from "react-redux";
import {
  setAlertData,
  setTimeVal,
  setTimerStartDate,
  setTimerStatus,
} from "Redux/Screen/Action";
import DateTime from "Utils/DateTime";
import PatientApi from "Service/Patient.api";
import { RouteUrls } from "Constant/RouteUrls";

const StartStopLogTime: FC<LogtimeProps> = (props) => {
  const dispatch = useDispatch();
  const {
    rightHead: { timerStatus, timerVal, timerApiCallByParentCom },
  }: ScreenInitialStateType = useSelector((state: any) => state?.screen);
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const currentUrl = window.location.href;
  const isRPM =
    currentUrl.includes(RouteUrls.treatmentPlan) ||
    currentUrl.includes(RouteUrls.revisionPlan);
  const isRecurring = currentUrl.includes(RouteUrls.rpmrecurring);
  const {
    openLogModal,
    openTaskModal,
    openPatientThresholdModal,
    isOnlyTimer,
  } = props;

  let updatedS = timerVal.s,
    updatedM = timerVal.m,
    updatedH = timerVal.h;
  const latestStartTime = useRef<string | null>("");
  const latestInterval = useRef<ReturnType<typeof setInterval> | undefined>();
  const latestParentApiCall = useRef(false);

  useEffect(() => {
    latestParentApiCall.current = timerApiCallByParentCom;
  }, [timerApiCallByParentCom]);

  useEffect(() => {
    if (timerStatus == 2) startTimer();
    else stopTimer();
  }, [timerStatus]);

  /*
  Start function will start the timer in the following conditions:
  1. When click on the start button.
  2. Auto timer start in rpm treatment plan and service selected screens.
  */
  const startTimer: () => void = () => {
    const startTime = DateTime.CurrentUTCDateTimeSec();
    dispatch(setTimerStartDate(startTime));
    latestStartTime.current = startTime;
    run();
    const interval = setInterval(run, 1000);
    latestInterval.current = interval;
  };

  const run: () => void = () => {
    if (updatedM === 59 && updatedS === 59) {
      updatedH++;
      updatedM = 0;
      updatedS = 0;
    } else if (updatedS === 59) {
      updatedM++;
      updatedS = 0;
    } else {
      updatedS++;
    }
    dispatch(setTimeVal({ s: updatedS, m: updatedM, h: updatedH }));
  };

  /*
  Manual log time can be done in the following conditions:
  1. When click on the stop button of timer.
  2. In rpm flow, when go back to non timer screens like problems and addDevice.
  */
  const stopTimer: () => void = () => {
    dispatch(setTimerStatus({ status: 1, parentApiCall: false }));
    latestInterval.current && clearInterval(latestInterval.current);
    dispatch(setTimeVal({ s: 0, m: 0, h: 0 }));
    latestParentApiCall.current && handleTimeLog();
  };

  /*
  Cleanup function can logtime for running timer in the following conditions:
  1. Come out from the plan screens without stopping the timer or submitting.
  Note: Plan means care/treatment/recurring/revision.
  */
  useEffect(() => {
    return () => stopTimer();
  }, []);

  const handleTimeLog: () => void = async () => {
    const endTime = DateTime.CurrentUTCDateTimeSec();
    const startTime = latestStartTime.current;
    const payload = {
      careplanId: selectedPrgm?.careplanId,
      careplanRecurringId: selectedPrgm?.careplanRecurringId || null,
      startDateTime: startTime || "",
      endDateTime: endTime,
      notes: "",
      reasons: selectedPrgm?.careplanActivity || null,
      status: "ACTIVE",
      date: endTime,
      billingMonth: parseInt(DateTime.CurrentMonth()),
      billingYear: parseInt(DateTime.CurrentYear()),
    };
    const res = await PatientApi.timeLog({
      data: payload,
      id1: selectedPrgm?.careplanId,
    });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      dispatch(setTimerStartDate(null));
    }
  };

  return (
    <Box
      sx={{ ...CommonStyle.sxRow, "& >button": { ml: 1 }, "& >h6": { ml: 1 } }}
    >
      {isRPM && !isRecurring && (
        <CstmBtn
          label="Patient Threshold"
          onClick={openPatientThresholdModal}
        />
      )}
      {!isOnlyTimer && (
        <Typography
          sx={{ ...CommonStyle.sxWhiteBox, p: 0.5 }}
          variant="subtitle1"
        >
          {selectedPrgm?.careplanActivity || ""}
        </Typography>
      )}
      <Typography variant="subtitle1" width={100} textAlign="center">
        Time:{" "}
        {`${timerVal.h < 10 ? `0${timerVal.h}` : timerVal.h}` +
          ":" +
          `${timerVal.m < 10 ? `0${timerVal.m}` : timerVal.m}` +
          ":" +
          `${timerVal.s < 10 ? `0${timerVal.s}` : timerVal.s}`}
      </Typography>
      {!isRPM && !isOnlyTimer ? (
        <Fragment>
          <CstmBtn
            onClick={() =>
              dispatch(setTimerStatus({ status: 2, parentApiCall: true }))
            }
            label="Start"
            disable={
              myInfo.roleId == 1 || myInfo.roleId == 2 ? true : timerStatus != 1
            }
          />
          <CstmBtn
            onClick={() =>
              dispatch(setTimerStatus({ status: 1, parentApiCall: true }))
            }
            label="Stop"
            disable={
              myInfo.roleId == 1 || myInfo.roleId == 2 ? true : timerStatus != 2
            }
          />
        </Fragment>
      ) : (
        <Fragment />
      )}
      {!isOnlyTimer && (
        <IconButton
          sx={{ ...CommonStyle.sxRoundIconsMainMd }}
          title={label.ct}
          onClick={openTaskModal}
        >
          <ImgPath.CreateTaskIcon />
        </IconButton>
      )}
      {!isRPM && !isOnlyTimer ? (
        <IconButton
          sx={{
            ...(myInfo.roleId == 1 || myInfo.roleId == 2
              ? CommonStyle.sxRoundIconsDisabledMd
              : timerStatus == 1
                ? CommonStyle.sxRoundIconsMainMd
                : CommonStyle.sxRoundIconsDisabledMd),
          }}
          title="Log Time"
          {...(myInfo.roleId != 1 &&
            myInfo.roleId != 2 &&
            timerStatus == 1 && {
            onClick: () => openLogModal(),
          })}
        >
          <ImgPath.AddTimeLogIcon />
        </IconButton>
      ) : (
        <Fragment />
      )}
    </Box>
  );
};
export default StartStopLogTime;
