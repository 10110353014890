import { useState, FC } from "react";
import dayjs, { Dayjs } from "dayjs";
import { StaticDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./style.css";
import DateTime from "Utils/DateTime";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import ColorData1 from "CstmTheme/ColorData";

type CalendarProps = {
  onChange: (date: string) => void;
  value: string;
};

const newTheme = (appTheme: any) =>
  createTheme({
    ...appTheme,
    components: {
      MuiDayCalendar: {
        styleOverrides: {
          header: {
            backgroundColor: ColorData1.secondary.main,
            "& >span": { color: "#fff", fontWeight: "bold" },
          },
          weekContainer: {
            "& >button": {
              border: "1px solid",
              borderColor: ColorData1.custom.light,
            },
          },
        },
      },
    },
  });

const Calender: FC<CalendarProps> = (props: CalendarProps) => {
  const { onChange, value } = props;
  const [calendarValue, setCalendarValue] = useState<Dayjs | null>(
    value ? dayjs(value, "MM/DD/YYYY") : null
  );

  const handleChange: (newValue: Dayjs | null) => void = (
    newValue: Dayjs | null
  ) => {
    onChange(dayjs(newValue).format("MM/DD/YYYY"));
    setCalendarValue(newValue);
  };

  return (
    <ThemeProvider theme={newTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          className="maincalendar"
          displayStaticWrapperAs="desktop"
          value={calendarValue}
          onChange={(newValue) => handleChange(newValue)}
          minDate={dayjs(DateTime.CurrentDate(), "MM/DD/YYYY")}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default Calender;
