import { ControlledTable } from "Components";
import FilterData from "Components/Filter2/Data";
import { Filter2DataProps } from "DataTypes/Common";
import { actionArrType, columnType } from "DataTypes/Table";
import { store } from "Redux/Store";
import { FormatDropdownData } from "Utils/DataFormatter";

export const TableCol: (
  columnClick: () => void,
  rowClick: (rowId: string | number) => void,
  selectAll: boolean,
  selectedIdChecked: boolean,
  disbleCheckBox: boolean,
  downloadPermission: boolean
) => columnType[] = (
  columnClick,
  rowClick,
  selectAll,
  selectedIdChecked,
  disbleCheckBox,
  downloadPermission
) => {
  const Arr: columnType[] = [
    ...(downloadPermission
      ? [
        {
          ...ControlledTable.ColumnData.CheckBoxCol(
            "4%",
            columnClick,
            (props) => rowClick(props.value),
            selectAll,
            selectedIdChecked,
            disbleCheckBox
          ),
        },
      ]
      : []),
    ControlledTable.ColumnData.PatientCol("9%"),
    ControlledTable.ColumnData.DOBCol("7%"),
    { ...ControlledTable.ColumnData.ProviderCol("9%"), childRowExpected: true },
    {
      ...ControlledTable.ColumnData.ClinicalStaffCol("8%"),
      childRowExpected: true,
    },
    { ...ControlledTable.ColumnData.ScopeMeCol("8%"), childRowExpected: true },
    {
      ...ControlledTable.ColumnData.CareTeamTimeCol("8%"),
      childRowExpected: true,
    },
    {
      ...ControlledTable.ColumnData.CareTeamClinicTimeCol("8%"),
      childRowExpected: true,
    },
    {
      ...ControlledTable.ColumnData.ProviderClinicTimeCol("8%"),
      childRowExpected: true,
    },
    {
      ...ControlledTable.ColumnData.TotalTimeCol("8%"),
      childRowExpected: true,
    },
    {
      ...ControlledTable.ColumnData.ProgramCol("8%", "billingPrgm"),
      childRowExpected: true,
    },
    {
      ...ControlledTable.ColumnData.CodeBilledCol("8%"),
      childRowExpected: true,
    },
    { ...ControlledTable.ColumnData.StatusCol("8%"), childRowExpected: true },
  ];
  return Arr;
};

export const actionArr: (permission?: any) => actionArrType[] = (
  permission
) => {
  const Arr: actionArrType[] = [];

  if (permission?.read) {
    const ArrTemp = [
      ControlledTable.ActionData.userAction(),
      ControlledTable.ActionData.chartAction(),
      ControlledTable.ActionData.callAction(),
    ];
    Arr.push(...ArrTemp);
  }

  if (permission?.create) {
    Arr.push(ControlledTable.ActionData.taskAction());
  }

  return Arr;
};

export const MonthArr = [
  {
    id: 1,
    label: "January",
    value: "January",
  },
  {
    id: 2,
    label: "February",
    value: "February",
  },
  {
    id: 3,
    label: "March",
    value: "March",
  },
  {
    id: 4,
    label: "April",
    value: "April",
  },
  {
    id: 5,
    label: "May",
    value: "May",
  },
  {
    id: 6,
    label: "June",
    value: "June",
  },
  {
    id: 7,
    label: "July",
    value: "July",
  },
  {
    id: 8,
    label: "August",
    value: "August",
  },
  {
    id: 9,
    label: "September",
    value: "September",
  },
  {
    id: 10,
    label: "October",
    value: "October",
  },
  {
    id: 11,
    label: "November",
    value: "November",
  },
  {
    id: 12,
    label: "December",
    value: "December",
  },
];

export const FilterArr = (isBiometric: boolean): Filter2DataProps[] => {
  const { prgDropList } = store.getState().list;
  return [
    FilterData.patientName,
    ...(isBiometric
      ? [
        { ...FilterData.startDate, label: "Start Date & Time" },
        { ...FilterData.endDate, label: "End Date & Time" },
        FilterData.trainerName,
        FilterData.deviceName,
      ]
      : [
        FilterData.providerName,
        FilterData.providerDesig,
        FilterData.clinicalStaff,
        FilterData.clinicalStaffDesig,
        {
          ...FilterData.prgmName,
          id: "abbreviation",
          dropArr: FormatDropdownData(prgDropList, "id", "label", "label"),
        },
      ]),
    {
      id: "codeBilled",
      label: "Code Billed",
      type: "input",
      payType: "string",
      value: [],
    },
    {
      ...FilterData.orgStatus,
      dropArr: [
        {
          id: 1,
          label: "Billable",
          value: "Billable",
        },
        {
          id: 2,
          label: "Non-Billable",
          value: "Non-Billable",
        },
      ],
    },
  ];
};

export const TableColBio: () => columnType[] = () => {
  const Arr: columnType[] = [
    ControlledTable.ColumnData.PatientCol("10%"),
    ControlledTable.ColumnData.DOBCol("8%"),
    ControlledTable.ColumnData.StartDateTimeCol("10%"),
    ControlledTable.ColumnData.EndDateTimeCol("10%"),
    ControlledTable.ColumnData.TrainerNameCol("9%"),
    ControlledTable.ColumnData.NumberOfReadingDays("10%"),
    ControlledTable.ColumnData.DeviceNameCol("17%"),
    ControlledTable.ColumnData.CPTCodeBilledCol("8%"),
    ControlledTable.ColumnData.CPTCodeBilled2Col("8%"),
    ControlledTable.ColumnData.StatusCol("10%"),
  ];
  return Arr;
};
