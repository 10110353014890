import { FC, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addSelectedDevice,
  clearSelectedDevice,
  clearSelectedTraining,
} from "Redux/Patient/Action";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { RouteUrls } from "Constant/RouteUrls";
import { Loader } from "Components";
import { Box } from "@mui/material";
import { CommonStyle } from "Components";
import CommonFormatter from "Utils/Common.Formatter";

const Devices: FC = () => {
  const dispatch = useDispatch();
  const { patientInfo, selectedDevice, rpmPrgm }: PatientInitialStateType =
    useSelector((state: any) => state?.patient);
  const { patientDeviceId } = useParams();
  const patientDeviceIdNumber = patientDeviceId && parseInt(patientDeviceId);
  const [loading, setLoading] = useState(true);
  const selectedDeviceData =
    rpmPrgm.patientDevices &&
    rpmPrgm.patientDevices.find(
      (item) => item.patientDeviceId === patientDeviceIdNumber
    );

  useEffect(() => {
    if (selectedDeviceData) {
      dispatch(addSelectedDevice({ ...selectedDeviceData }));
    }
    CommonFormatter.HandleNavArr({
      id: "patient-device",
      label: selectedDevice.deviceTypeDescription,
      path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.device}/${selectedDevice.patientDeviceId}/${RouteUrls.deviceDetails}`,
      level: 3,
      link: RouteUrls.deviceDetails,
      disabled: true,
      navType: "patient",
    });
    setLoading(false);
    return () => {
      dispatch(clearSelectedTraining());
      dispatch(clearSelectedDevice());
    };
  }, [patientDeviceId]);

  return (
    <Box width="91%" sx={{ ...CommonStyle.sxWhiteBox }} overflow="auto" px={2}>
      {loading ? <Loader /> : <Outlet />}
    </Box>
  );
};
export default Devices;
