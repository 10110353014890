import { useState, useEffect, FC, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, ControlledForm } from "Components";
import ImgPath from "Constant/Imgs";
import { PageLimitArr } from "Constant/DropArr";
import ColorData1 from "CstmTheme/ColorData";
import { CheckNumber } from "Utils/common";
import { PaginationProps } from "DataTypes/Table";

const Pagination: FC<PaginationProps> = (props) => {
  const { limit, total, mainPage, handleClick, tableStyle, limitArr } = props;
  const [pageArr, setPageArr] = useState<number[] | []>([]);

  useEffect(() => {
    handleNumberOfPages();
  }, [limit, total]);

  const handleNumberOfPages: () => void = () => {
    const lenght = Math.ceil(total / limit);
    const newArr = Array.from({ length: lenght }, (_, i) => i + 1);
    setPageArr(newArr);
  };

  const renderPageNumbers: () => JSX.Element = () => {
    const adjacentPages = 2;
    const maxCenterPages = 3;
    const pageNumbers = [];

    if (pageArr.length <= adjacentPages * 2 + maxCenterPages) {
      pageNumbers.push(...pageArr);
    } else {
      const secondLast = pageArr.length - 1;
      const last = pageArr.length;
      pageNumbers.push(1, 2);
      if (mainPage - 3 > 1) pageNumbers.push("...");
      for (let i = mainPage - 1; i <= mainPage + 1; i++) {
        if (i > 2 && i < secondLast) pageNumbers.push(i);
      }
      if (secondLast - (mainPage + 1) > 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(secondLast, last);
    }

    return (
      <Fragment>
        {pageNumbers.map((el, index) => (
          <Typography
            {...(typeof el === "number" && {
              onClick: () => handleClick(el, limit),
            })}
            key={index}
            sx={{
              ...(typeof el === "number"
                ? mainPage === el
                  ? sxPageSelect
                  : sxPageUnSelect
                : {}),
            }}
          >
            {typeof el === "number" ? el : "..."}
          </Typography>
        ))}
      </Fragment>
    );
  };

  return (
    <Box
      sx={{
        ...sxPaginationBox,
        ...CommonStyle.sxRow,
        ...(tableStyle && sxTableBox),
      }}
    >
      <Box sx={{ ...CommonStyle.sxRow }}>
        <Typography variant="subtitle1" sx={{ mr: 1 }}>
          {"Per Page Limit: "}
        </Typography>
        <ControlledForm.SingleSelectDrop
          id="page limit"
          dropArr={limitArr ? limitArr : PageLimitArr}
          sxProps={{ width: 80, "& input": { py: 0.5 } }}
          onClick={(val) => handleClick(1, CheckNumber(val) || 10)}
          dropValue={limit}
        />
      </Box>
      {pageArr.length > 0 && (
        <Box display="flex" sx={{ ...sxPaginationBtn }}>
          <Box
            sx={{
              ...CommonStyle.sxRow,
              ...(mainPage == pageArr[0] && sxBtnInActive),
            }}
            {...(mainPage != pageArr[0] && {
              onClick: () => handleClick(mainPage - 1, limit),
            })}
          >
            <ImgPath.BreadcrumbIcon />
            <Typography variant="body2">Previous</Typography>
          </Box>

          {renderPageNumbers()}
          <Box
            sx={{
              ...CommonStyle.sxRow,
              ...(mainPage == pageArr[pageArr.length - 1] && sxBtnInActive),
            }}
            {...(mainPage != pageArr[pageArr.length - 1] && {
              onClick: () => handleClick(mainPage + 1, limit),
            })}
          >
            <Typography variant="body2">Next</Typography>
            <ImgPath.BreadcrumbIcon />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Pagination;

const sxPaginationBox = {
  display: "flex",
  justifyContent: "space-between",
  px: 2,
};

const sxTableBox = {
  border: "1px solid",
  borderColor: "custom.main",
  borderRadius: 1.25,
  borderTop: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
};

const sxPaginationBtn = {
  "& >div": {
    cursor: "pointer",
    "&>svg": {
      width: 15,
      "& >path": {
        stroke: ColorData1.primary.main,
        strokeWidth: 3,
      },
    },
  },
  "& >div:first-of-type": {
    mr: 3,
    "&>svg": {
      mr: 0.5,
    },
  },
  "& >div:last-of-type": {
    "&>svg": {
      rotate: "180deg",
      ml: 0.5,
    },
    ml: 3,
  },
  "& >p": {
    mx: 0.5,
    cursor: "pointer",
    px: 1,
  },
};

const sxBtnInActive = {
  opacity: 0.5,
  cursor: "default",
};

const sxPageUnSelect = {
  border: "1px solid",
  borderColor: "common.white",
  // backgroundColor: "red",
};

const sxPageSelect = {
  border: "1px solid",
  cursor: "default",
  borderColor: "primary.main",
};
