import { Switch } from "@mui/material";
import { label } from "Constant/En";
import ImgPath from "Constant/Imgs";
import { actionArrType, actionProps } from "DataTypes/Table";

const enableDisableAction: (
  handleClick?: (data: any) => void,
  enabled?: boolean,
  disable?: boolean
) => actionArrType = (handleClick, enabled, disable) => {
  return {
    id: "Enabled",
    permission: "update",
    icon: (
      <Switch
        color="secondary"
        checked={enabled ? enabled : false}
        title={enabled ? "Active" : "Inactive"}
        disabled={disable ? disable : false}
      />
    ),
    title: enabled ? "Active" : "Inactive",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
    disable,
  };
};

const editAction: (props: actionProps) => actionArrType = ({
  handleClick,
  disable,
}: actionProps) => {
  return {
    id: "edit",
    icon: <ImgPath.EditOutlined />,
    title: "Edit",
    permission: "update",
    onClick: handleClick,
    disable: disable ? disable : false,
  };
};

const downloadAction: (
  handleClick?: (data: any) => void,
  titleName?: string,
  disableFlag?: boolean
) => actionArrType = (handleClick, titleName, disableFlag) => {
  return {
    id: "download",
    disable: disableFlag ? disableFlag : false,
    icon: <ImgPath.DownloadIcon />,
    title: titleName ? titleName : "Download Associated Patient & Task List",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const selfAssignAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "edit",
    icon: <ImgPath.MoveToIcon fontSize="medium" />,
    title: "Assign To Self",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const deleteAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "delete",
    icon: <ImgPath.DeleteOutline />,
    title: "Delete",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const timeLogAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "addlogtime",
    icon: <ImgPath.AddTimeLogIcon />,
    title: "Log Time",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const userAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "user",
    icon: <ImgPath.PatientDetailsIcon width="20px" height="20px" />,
    title: label.profile,
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const chartAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "chart",
    icon: <ImgPath.ClinicalSummaryIcon />,
    title: "Clinical Summary",
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const callAction: (
  handleClick?: (data: any) => void,
  disable?: boolean
) => actionArrType = (handleClick, disable) => {
  return {
    id: "call",
    icon: <ImgPath.CallLogsIcon />,
    title: label.cd,
    ...(disable && { disable: disable || false }),
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const taskAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "task",
    icon: <ImgPath.CreateTaskIcon />,
    title: label.ct,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const pendingAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "pending",
    icon: <ImgPath.PendingActions />,
    title: label.pend,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const pointAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "pointout",
    icon: <ImgPath.PointOutIcon />,
    title: label.point,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const tickAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "RoundTick",
    icon: <ImgPath.RoundTickIcon />,
    title: label.point,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const forwardAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "ForwardArr",
    icon: <ImgPath.ForwardArrowIcon />,
    title: label.point,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const cmAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "CM",
    icon: <ImgPath.CMIcon />,
    title: label.point,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const carePlanAction: (
  handleClick?: (data: any) => void,
  title?: string
) => actionArrType = (handleClick, title) => {
  return {
    id: "CarePlan",
    icon: <ImgPath.CarePlanIcon />,
    title: title ? title : label.careplan,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const startCarePlanAction: (
  handleClick?: (data: any) => void
) => actionArrType = (handleClick) => {
  return {
    id: "CarePlan",
    icon: <ImgPath.StartCareIcon />,
    title: "Start Care Plan",
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const ViewCarePlanAction: (
  handleClick?: (data: any) => void,
  title?: string
) => actionArrType = (handleClick, title) => {
  return {
    id: "ViewCarePlan",
    icon: <ImgPath.ViewCarePlan />,
    title: title || "View Care Plan",
    width: "100%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const closeStatusAction: (
  handleClick?: (data: any) => void
) => actionArrType = (handleClick) => {
  return {
    id: "Close",
    icon: <ImgPath.CloseIcon fontSize="small" />,
    title: "Close",
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};
const closeStatusActionAlert: (
  handleClick?: (data: any) => void
) => actionArrType = (handleClick) => {
  return {
    id: "Close",
    icon: <ImgPath.CloseIcon fontSize="small" />,
    title: "Remove From List",
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};
const viewTitleAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "View",
    title: "View",
    icon: <ImgPath.RemoveRedEyeOutlined fontSize="small" />,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const MarkReadAction: (props: actionProps) => actionArrType = ({
  handleClick,
  disable,
}) => {
  return {
    id: "markRead",
    title: "Mark As Read",
    icon: <ImgPath.MarkReadIcon fontSize="small" />,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
    disable: disable || false,
  };
};

const MarkAddressedAction: (props: actionProps) => actionArrType = ({
  handleClick,
  disable,
}) => {
  return {
    id: "markAddressed",
    title: "Mark As Addressed",
    icon: <ImgPath.PlaylistAddCheckCircleIcon fontSize="small" />,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
    disable: disable || false,
  };
};

const removeTitleAction: (
  handleClick?: (data: any) => void
) => actionArrType = (handleClick) => {
  return {
    id: "Remove",
    title: "Remove",
    icon: <span style={{ fontSize: "8px", color: "White" }}>Remove</span>,
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const deleteTitleAction: (
  handleClick?: (data: any) => void
) => actionArrType = (handleClick) => {
  return {
    id: "Delete",
    title: "Delete",
    icon: <span style={{ fontSize: "10px", color: "White" }}>Delete</span>,
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const ImportFileAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "import",
    title: "Patient Data Import",
    icon: <ImgPath.ImportIcon fontSize="medium" />,
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const MoveToAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "moveto",
    title: "Assign To Self",
    icon: <ImgPath.MoveToIcon fontSize="medium" />,
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const MoveBackToUnassign: (
  handleClick?: (data: any) => void
) => actionArrType = (handleClick) => {
  return {
    id: "movebacktoUnassign",
    title: "Unassign",
    icon: (
      <ImgPath.MoveToIcon
        sx={{ transform: "rotate(180deg)" }}
        fontSize="medium"
      />
    ),
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const RpmChartAction: (handleClick?: (data: any) => void) => actionArrType = (
  handleClick
) => {
  return {
    id: "chart",
    icon: <ImgPath.rpmchartIcon />,
    title: "View Report",
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

const StopAction: (handleClick?: (data: any) => void) => actionArrType = (handleClick) => {
  return {
    id: "markAddressed",
    title: "Suspend Device",
    icon: <ImgPath.StopIcon fontSize="small" />,
    width: "65%",
    ...(handleClick && { onClick: (data) => handleClick(data) }),
  };
};

export default {
  enableDisableAction,
  editAction,
  userAction,
  chartAction,
  callAction,
  taskAction,
  pointAction,
  tickAction,
  forwardAction,
  cmAction,
  deleteAction,
  timeLogAction,
  startCarePlanAction,
  ViewCarePlanAction,
  carePlanAction,
  closeStatusAction,
  viewTitleAction,
  removeTitleAction,
  deleteTitleAction,
  RpmChartAction,
  closeStatusActionAlert,
  selfAssignAction,
  ImportFileAction,
  downloadAction,
  MoveToAction,
  MoveBackToUnassign,
  MarkReadAction,
  pendingAction,
  MarkAddressedAction,
  StopAction
};
