const ColorData1 = {
  primary: {
    main: "#002255",
  },
  secondary: {
    main: "#EE7404",
  },
  bg: {
    main: "#F7F4F1",
  },
  active: {
    main: "#1077BB",
  },
  custom: {
    light: "#e7e7e7",
    main: "#c7c7c7",
    dark: "#5a5a5a",
    grey: "#cecece",
  },
  textHover: {
    main: "#1077BB",
  },
  roundiconbg: {
    main: "#EE7404",
  },
  roundiconbghover: {
    main: "#1077BB",
  },
  checkbox: {
    main: "#1077BB",
  },
  radio: {
    main: "#EE7404",
  },
};

export default ColorData1;
