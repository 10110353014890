import { ClickIconArrDataType, FormDataType } from "DataTypes/Form";
import { label } from "Constant/En";

export const DataArr: FormDataType[] = [
  {
    id: "email",
    placeholder: "Email Id",
    boxType: "login-input-box",
    validatetype: "userid",
    value: "Test13@gmail.com",
    readOnly: true,
    boxSize: 6,
  },
  {
    id: "username",
    placeholder: "Enter User Name",
    boxType: "login-input-box",
    validatetype: "name",
    iconId: "login-icon",
    boxSize: 6,
  },
  {
    id: "first-name",
    placeholder: "Enter First Name",
    boxType: "login-input-box",
    type: "text",
    validatetype: "name",
    value: "Andrew",
    boxSize: 6,
  },
  {
    id: "last-name",
    placeholder: "Enter Last Name",
    boxType: "login-input-box",
    type: "text",
    validatetype: "name",
    boxSize: 6,
  },
  {
    id: "gender",
    placeholder: "Select Gender",
    boxType: "login-input-box",
    validatetype: "name",
    boxSize: 6,
  },
  {
    id: "mobile-number",
    placeholder: "Enter Mobile Numver",
    boxType: "login-input-box",
    validatetype: "name",
    boxSize: 6,
  },
  {
    id: "new-password",
    placeholder: "Enter Password",
    boxType: "login-input-box",
    type: "password",
    validatetype: "password",
    iconId: "password-icon",
    iconClick: true,
    required: true,
    requiredSign: true,
    boxSize: 6,
  },
  {
    id: "confirm-password",
    placeholder: "Enter Confirm Password",
    boxType: "login-input-box",
    type: "password",
    validatetype: "password",
    iconId: "password-icon",
    iconClick: true,
    required: true,
    requiredSign: true,
    boxSize: 6,
  },
];

export const IconClickArr = (handlePassClick: (id: string) => void) => {
  const arr: ClickIconArrDataType[] = [
    {
      iconId: "password-icon",
      onClick: (id: string) => handlePassClick(id),
    },
  ];
  return arr;
};
