import { FC, useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import PracticeApi from "Service/Practice.api";
import Master from "./Master";
import OtherPractice from "./Other";
import { PracticeReadApiType } from "DataTypes/Services/Practice.type";
import UserApi from "Service/User.api";
import { UserReadApiType } from "DataTypes/Services/User.type";
import AuthApi from "Service/Auth.api";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import OrganizationFormatter from "Utils/Organization.Formatter";
import UserFormatter from "Utils/Users.Formatter";
import { CheckArr, CheckStatus } from "Utils/common";
import { HeaderInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const OrganizationStaffUpdate: FC = () => {
  const dispatch = useDispatch();
  const { organization }: HeaderInitialStateType = useSelector(
    (state: any) => state?.header
  );
  const { staffId } = useParams();
  const [myPractice, setMyPractice] = useState<PracticeReadApiType | null>(
    null
  );
  const [myProfile, setMyProfile] = useState<UserReadApiType | null>(null);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    const url = staffId ? RouteUrls.update : RouteUrls.add;
    CommonFormatter.HandleNavArr({
      id: "add-staff",
      label: staffId ? "Update Staff" : "Add Staff",
      path: `/${RouteUrls.hs}/${RouteUrls.organization}/${organization.id}/${RouteUrls.staff}/${url}`,
      level: 3,
      link: url,
      navType: "org",
    });
  }, [organization]);

  const onLoad = async () => {
    if (staffId) {
      const userRes = await UserApi.read({
        id1: staffId ? parseInt(staffId) : null,
      });
      if (userRes?.success) {
        const formateUser = UserFormatter.VerifyReadResponse(userRes?.data);
        setMyProfile(formateUser);
        handleMyOrganization(formateUser);
      } else {
        dispatch(setAlertData(userRes?.alert));
        window.history.back();
      }
    } else {
      handleMyOrganization();
    }
  };

  const handleMyOrganization = async (formateUser?: UserReadApiType) => {
    let newOrgId = organization.id;
    if (formateUser) {
      const findMaster = formateUser.practice.find(
        (el) => el.isMasterPractice == true
      );
      if (findMaster) newOrgId = findMaster.id;
      else {
        dispatch(
          setAlertData({
            alertMessage: "Organization not found",
            alertVariant: "error",
            openAlert: true,
          })
        );
        dispatch(stopLoading());
        window.history.back();
      }
    }
    const res = await PracticeApi.read({ id1: newOrgId });
    if (res?.success) {
      setMyPractice(OrganizationFormatter.VerifyReadResponse(res?.data));
    } else {
      dispatch(setAlertData(res?.alert));
      window.history.back();
    }
  };

  const handleDisableUser = async () => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const changeRes: any = await UserApi.changeStatus({
      id1: myProfile?.id,
      status: myProfile?.status == "DISABLED" ? "ENABLED" : "DISABLED",
    });
    if (changeRes?.success) {
      dispatch(setAlertData(changeRes?.alert));
      myProfile &&
        setMyProfile({
          ...myProfile,
          status: CheckStatus(
            myProfile?.status == "DISABLED" ? "ENABLED" : "DISABLED"
          ),
        });
    }
    dispatch(stopLoading());
  };

  const handleForgetUser = async () => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const payload = { userName: myProfile?.userName };
    const res: any = await AuthApi.forget({ data: payload });
    if (res?.success) {
      dispatch(
        setAlertData({
          alertMessage: `Password link sent to ${myProfile?.firstName} ${myProfile?.lastName} to Reset Password`,
          openAlert: true,
          alertVariant: "success",
        })
      );
    }
    dispatch(stopLoading());
  };

  const searchUserEmail: (data: string) => Promise<boolean> = async (data) => {
    const payload = { isDeleted: null, email: data };
    const res: any = await UserApi.list({
      data: payload,
      offset: 0,
      limit: 25,
    });
    if (res?.success) {
      const newRes = CheckArr(res?.data);
      if (newRes.length > 0) {
        if (myProfile) {
          const find = newRes.find((el) => el?.id == myProfile.id);
          if (find) return false;
          else return true;
        } else return true;
      } else return false;
    } else return false;
  };

  const searchUserId: (data: string) => Promise<boolean> = async (data) => {
    const payload = { isDeleted: null, userName: data };
    const res: any = await UserApi.list({
      data: payload,
      offset: 0,
      limit: 25,
    });
    if (res?.success) {
      const newRes = CheckArr(res?.data);
      if (newRes.length > 0) {
        if (myProfile) {
          const find = newRes.find((el) => el?.id == myProfile.id);
          if (find) return false;
          else return true;
        } else return true;
      } else return false;
    } else return false;
  };

  return (
    <Fragment>
      {myPractice?.masterPractice ? (
        <Master
          myPractice={myPractice}
          myProfile={myProfile}
          handleDisableUser={handleDisableUser}
          handleForgetUser={handleForgetUser}
          checkUserEmail={searchUserEmail}
          checkUserId={searchUserId}
        />
      ) : (
        <OtherPractice
          myPractice={myPractice}
          myProfile={myProfile}
          handleDisableUser={handleDisableUser}
          handleForgetUser={handleForgetUser}
          checkUserEmail={searchUserEmail}
          checkUserId={searchUserId}
        />
      )}
    </Fragment>
  );
};

export default OrganizationStaffUpdate;
