import DateTime from "Utils/DateTime";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const formatCareTeamSearchres = (data: any) => {
  return {
    authorizedRepresentative1: CheckStringEmpty(
      data?.authorizedRepresentative1
    ),
    authorizedRepresentative2: CheckStringEmpty(
      data?.authorizedRepresentative2
    ),
    authorizedRepresentative3: CheckStringEmpty(
      data?.authorizedRepresentative3
    ),
    billableProviderId: CheckNumber(data?.billableProviderId),
    caremanagerId: CheckNumber(data?.caremanagerId),
    careplanAdditionalProvider: CheckArr(data?.careplanAdditionalProvider),
    careplanId: CheckNumber(data?.careplanId),
    id: CheckNumber(data?.id),
    patientId: CheckStringId(data?.patientId),
    practiceId: CheckNumber(data?.practiceId),
    practiceLocationId: CheckNumber(data?.practiceLocationId),
    primaryCareCoordinatorId: CheckNumber(data?.primaryCareCoordinatorId),
    programId: CheckNumber(data?.programId),
    providerId: CheckNumber(data?.teamProviderId),
    secondaryCareCoordinatorId: CheckNumber(data?.secondaryCareCoordinatorId),
  };
};

const TaskResFormatter: (data: any) => any = (data) => {
  return {
    actualDeviceId: data?.actualDeviceId,
    assigneeUserId: data?.assigneeUserId,
    assigneeUserName: data?.assigneeUserName,
    createdAt: data?.createdAt,
    createdBy: data?.createdBy,
    createdByName: data?.createdByName,
    deviceType: data?.deviceType,
    groupId: data?.groupId,
    groupName: data?.groupName,
    id: data?.id,
    isDeleted: data?.isDeleted,
    notes: data?.notes,
    patientId: data?.patientId,
    patientName: data?.patientName,
    practiceId: data?.practiceId,
    practiceLocationId: data?.practiceLocationId,
    priority: data?.priority,
    source: data?.source,
    status: data?.status,
    subject: data?.subject,
    targateDate: DateTime.UTCToLocalDateTime(data?.targateDate),
    taskDescription: data?.taskDescription,
    type: data?.type,
  };
};

export default {
  formatCareTeamSearchres,
  TaskResFormatter,
  ///New and checked funtions
};
