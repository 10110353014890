import { FC, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CommonStyle, ControlledTable, Filter2 } from "Components";
import { TableLayout } from "Layouts";
import { actionArr, FilterArr, TableCol } from "./Data";
import { useSelector } from "react-redux";
import PracticeApi from "Service/Practice.api";
import { Filter2DataProps } from "DataTypes/Common";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch } from "react-redux";
import { setAlertData, setRightHead } from "Redux/Screen/Action";
import Format from "./Format";
import { FormatOrgListType } from "DataTypes/PracticeMangement.type";
import { addOrg } from "Redux/Header/Action";
import { CreateFilter2Payload } from "Utils/common";
import CommonFormatter from "Utils/Common.Formatter";

const OrganizationList: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.practice
  );
  const [loading, setLoading] = useState(true);
  const [practiceList, setPracticeList] = useState<FormatOrgListType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    dispatch(setRightHead("org-list"));
    CommonFormatter.HandleNavArr({
      id: "org-list",
      label: "Organizations",
      path: `/${RouteUrls.hs}/${RouteUrls.organization}/${RouteUrls.orgList}`,
      level: 1,
      link: RouteUrls.orgList,
      navType: "org",
    });
    setLoading(false);
    return () => {
      dispatch(setRightHead(""));
    };
  }, []);

  useEffect(() => {
    handleSetData();
  }, [filterData]);

  const handleSetData: () => void = async () => {
    setLoading(true);
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
      isDeleted: null,
    };
    const orgRes: any = await PracticeApi.list({
      data: payload,
      offset: 0,
      limit: 25,
    });
    if (orgRes?.success) {
      setPracticeList(Format.FormatListResponse(orgRes?.data));
    }
    setLoading(false);
  };

  const handleStaff: (row: FormatOrgListType) => void = (row) => {
    dispatch(
      addOrg({
        id: row.id,
        name: row.name,
        masterPractice: row.masterPractice,
        modelId: row.modelId,
        modelName: row.model,
      })
    );
    navigate(`../${row.id}/${RouteUrls.staff}/${RouteUrls.list}`);
  };

  const handleEdit: (data: FormatOrgListType) => void = (data) => {
    navigate("../" + data?.id + "/update", { state: data });
  };

  const handleImportFile: (data: FormatOrgListType) => void = (data) => {
    dispatch(
      addOrg({
        id: data.id,
        name: data.name,
        masterPractice: data.masterPractice,
        modelId: data.modelId,
        modelName: data.model,
      })
    );
    navigate(`../${RouteUrls.patientUpload}`);
  };

  const handleClick: (rowData: FormatOrgListType) => void = async (rowData) => {
    const newStatus = rowData?.status == "ACTIVE" ? "DISABLED" : "ACTIVE";
    const statusRes: any = await PracticeApi.changeStatus({
      id1: rowData.id,
      status: newStatus,
    });
    if (statusRes?.success) {
      dispatch(
        setAlertData({
          alertMessage: `${rowData.name} is now ${newStatus}`,
          openAlert: true,
          alertVariant: "success",
        })
      );
      handleSetData();
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol(handleStaff)}
      tableRow={practiceList}
      tableAction={(data: FormatOrgListType) =>
        actionArr(
          () => handleEdit(data),
          () => handleClick(data),
          () => handleImportFile(data),
          data
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Box
      height="91%"
      sx={{ ...CommonStyle.sxWhiteBox, px: 2, overflow: "auto" }}
    >
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      {permission?.search && (
        <TableLayout
          tblBody={TblBodyJSX}
          tblHead={ControlledTable.TblHead(TableCol(handleStaff))}
          height={tableHeight}
          loading={loading}
        />
      )}
    </Box>
  );
};
export default OrganizationList;
