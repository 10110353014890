import { Box, Switch, Typography } from "@mui/material";
import { AlertSetRowProps } from "DataTypes/SettingsFormatter.type";
import { FC, Fragment } from "react";
import { CommonStyle } from "Components";
import { HeadData, SubHead } from "../Data";

const AlertRows: FC<AlertSetRowProps> = ({ userId, rowData, handleChange }) => {
  const filledWidth = "14.6%";

  return (
    <Fragment>
      {HeadData.map((headEl) => {
        const find = rowData.find((findEl) => findEl.alertType == headEl.id);
        return (
          <Box ml={0.5} width={filledWidth} key={headEl.id} display={"flex"}>
            {SubHead.map((subHeadEl) => {
              const val = find ? find[subHeadEl.id] : false;
              if (
                !userId &&
                (subHeadEl.id == "emailEnabled" ||
                  subHeadEl.id == "pushEnabled")
              ) {
                return <Typography key={subHeadEl.id} sx={{ ...sxSubHead }} />;
              } else {
                return (
                  <Typography key={subHeadEl.id} sx={{ ...sxSubHead }}>
                    <Switch
                      color="secondary"
                      checked={val}
                      title={val ? "Active" : "Inactive"}
                      size="small"
                      onChange={() =>
                        handleChange({
                          userId: userId,
                          newData: {
                            ...(find
                              ? find
                              : {
                                id: null,
                                alertType: headEl.id,
                                emailEnabled: false,
                                pushEnabled: false,
                                smsEnabled: false,
                              }),
                            [subHeadEl.id]: !val,
                          },
                        })
                      }
                    />
                  </Typography>
                );
              }
            })}
          </Box>
        );
      })}
    </Fragment>
  );
};
export default AlertRows;

const sxSubHead = {
  ...CommonStyle.sxSetCol,
  backgroundColor: "bg.main",
  py: 0.5,
  width: `${100 / 3}%`,
};
