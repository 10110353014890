import { SideModalCMTabType } from "DataTypes/Modal";

export const TabArr: SideModalCMTabType[] = [
  {
    id: "enroll-pdf",
    tabPanelVal: 1,
    label: "Consent Form",
    stage: 1,
    prgmArr: ["CCM", "PCM", "GCM", "BHI", "RPM"],
  },
  {
    id: "discharge-summary",
    tabPanelVal: 2,
    label: "Discharge Summary",
    stage: 1,
    prgmArr: ["TCM"],
  },
  {
    id: "rpm-device",
    tabPanelVal: 3,
    label: "Devices",
    stage: 5,
    prgmArr: ["RPM"],
  },
  {
    id: "care-team",
    tabPanelVal: 4,
    label: "Care Team",
    stage: 7,
    prgmArr: ["CCM", "PCM", "GCM", "BHI"],
  },
  {
    id: "rpm-team",
    tabPanelVal: 4,
    label: "Care Team",
    stage: 7,
    prgmArr: ["RPM"],
  },
  {
    id: "tcm-team",
    tabPanelVal: 4,
    label: "TCM Team",
    stage: 7,
    prgmArr: ["TCM"],
  },
  {
    id: "care-plan",
    tabPanelVal: 5,
    label: "Care Plan",
    stage: 8,
    prgmArr: ["CCM", "PCM", "GCM", "BHI"],
  },
  {
    id: "treatment-plan",
    tabPanelVal: 5,
    label: "Treatment Plan",
    stage: 8,
    prgmArr: ["RPM"],
  },
  {
    id: "tcm",
    tabPanelVal: 5,
    label: "TCM",
    stage: 8,
    prgmArr: ["TCM"],
  },
  {
    id: "care-plan-pdf",
    tabPanelVal: 6,
    label: "Care Plan Pdf",
    stage: 9,
    prgmArr: ["CCM", "PCM", "GCM", "BHI"],
  },
  {
    id: "tcm-plan-pdf",
    tabPanelVal: 6,
    label: "TCM Pdf",
    stage: 9,
    prgmArr: ["TCM"],
  },
  {
    id: "treatment-plan-pdf",
    tabPanelVal: 6,
    label: "Treatment Plan Pdf",
    stage: 9,
    prgmArr: ["RPM"],
  },
  {
    id: "revision-notes",
    tabPanelVal: 7,
    label: "Previous Treatment Plans",
    stage: 9,
    prgmArr: ["RPM"],
  },
  {
    id: "previous-notes",
    tabPanelVal: 8,
    label: "Previous Notes",
    stage: 9,
    prgmArr: ["CCM", "PCM", "GCM", "BHI", "RPM"],
  },
  {
    id: "add-time-log",
    tabPanelVal: 9,
    label: "Additional Time Logged",
    stage: 2,
    prgmArr: ["CCM", "PCM", "TCM", "GCM", "BHI", "RPM"],
  },
];

export const unenrollObj: SideModalCMTabType = {
  id: "unenroll-pdf",
  tabPanelVal: 10,
  label: "Cancellation Form",
  stage: 0,
  prgmArr: ["CCM", "PCM", "GCM", "BHI", "RPM"],
};
