import { ControlledTable } from "Components";
import { FormObjType } from "DataTypes/Form";
import { CPDeviceListType } from "DataTypes/Services/Careplan.type";
import { actionArrType, columnType } from "DataTypes/Table";

export const FormData: FormObjType[] = [
  {
    id: "device-type",
    label: "Device Type",
    placeholder: "Select Device Type",
    boxType: "single-select",
    boxSize: 4,
    validatetype: "dropdown",
    readOnly: true,
    required: true,
    enableFormLoad: true,
  },
  {
    id: "supply-name",
    label: "Device Name",
    placeholder: "Select Device Name",
    boxType: "single-select",
    boxSize: 4,
    validatetype: "dropdown",
    readOnly: true,
    required: true,
    enableFormLoad: true,
  },
  {
    id: "reading-reminder",
    label: "Reading Reminders",
    placeholder: "Select Reading Reminders",
    boxType: "single-select",
    boxSize: 4,
    validatetype: "dropdown",
    readOnly: true,
    required: true,
    enableFormLoad: true,
  },
  {
    id: "training-required",
    label: "Training Required",
    placeholder: "Select Training Required",
    boxType: "single-select",
    boxSize: 4,
    validatetype: "dropdown",
    readOnly: true,
    required: true,
    enableFormLoad: true,
  },
  {
    id: "trainer",
    label: "Trainer",
    placeholder: "Select Trainer",
    boxType: "single-select",
    boxSize: 4,
    validatetype: "dropdown",
    readOnly: true,
    enableFormLoad: true,
  },
  {
    id: "training-type",
    label: "Training Type",
    placeholder: "Select Training Type",
    boxType: "single-select",
    boxSize: 4,
    validatetype: "dropdown",
    readOnly: true,
    enableFormLoad: true,
  },
  {
    id: "device-provier",
    label: "Device Provider",
    placeholder: "Select Device Provider",
    boxType: "single-select",
    boxSize: 4,
    validatetype: "dropdown",
    readOnly: true,
    required: true,
    enableFormLoad: true,
  },
  {
    id: "device-id",
    label: "Device ID",
    placeholder: "Select Device ID",
    boxType: "input",
    boxSize: 4,
    validatetype: "deviceId",
    readOnly: true,
    inputVal: "",
    enableFormLoad: true,
  },
];

export const DeviceCol: columnType[] = [
  {
    id: "deviceTypeDescription",
    label: "Device Type",
    width: "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "deviceName",
    label: "Device Name",
    width: "18%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "deviceProviderValue",
    label: "Device Provider",
    width: "12%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "actualDeviceId",
    label: "Device ID",
    width: "12%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "fromDate",
    label: "From",
    width: "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "toDate",
    label: "To",
    width: "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "reminderDescription",
    label: "Reading Reminders",
    width: "12%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "isTrainingRequired",
    label: "Training Required",
    width: "12%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  ControlledTable.ColumnData.ActionCol("15%"),
];

export const actionArr: (
  handleEdit: () => void,
  handleSuspend: () => void,
  handleView: () => void,
  editDevice: boolean,
  suspendDevice: boolean,
  viewDevice: boolean,
  rowData: CPDeviceListType
) => actionArrType[] = (
  handleEdit,
  handleSuspend,
  handleView,
  editDevice,
  suspendDevice,
  viewDevice,
  rowData
) => {
  return [
    ...(editDevice && !rowData.isDeviceSuspended
      ? [ControlledTable.ActionData.editAction({ handleClick: handleEdit })]
      : []),
    ...(viewDevice && !rowData.isDeviceSuspended
      ? [ControlledTable.ActionData.viewTitleAction(handleView)]
      : []),
    ...(suspendDevice && !rowData.isDeviceSuspended && rowData.actualDeviceId
      ? [ControlledTable.ActionData.StopAction(handleSuspend)]
      : []),
  ];
};
