import { FC, Fragment, useState, useEffect } from "react";
import { TableLayout } from "Layouts";
import { ControlledTable, Filter2 } from "Components";
import { actionArr, FilterArr, TableCol } from "./Data";
import { FetchListProps, Filter2DataProps } from "DataTypes/Common";
import { CreateFilter2Payload } from "Utils/common";
import { objectValues } from "DataTypes/Services/Index.type";
import TrainingApi from "Service/Training.api";
import TrainingFormatter from "Utils/Training.Formatter";
import { TrainingListType } from "DataTypes/Services/Training.type";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch } from "react-redux";
import {
  addSelectedDevice,
  addSelectedPrgm,
  addSelectedTraining,
} from "Redux/Patient/Action";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import PatientFormatter from "Utils/Patient.Formatter";
import { UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const MyTrainingList: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<TrainingListType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const [pages, setPages] = useState<objectValues>({
    page: 1,
    total: 0,
    limit: 10,
  });
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    handleSetData({ page: 1, limit: pages.limit });
  }, [filterData]);

  const handleSetData: (props: FetchListProps) => void = async (props) => {
    const { page: currentPage, limit: currentLimit } = props;
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
    };
    setLoading(true);
    const res = await TrainingApi.myTraininglist({
      data: payload,
      limit: currentLimit,
      offset: (currentPage - 1) * currentLimit,
    });
    if (res?.success) {
      setRows(TrainingFormatter.TrainingList(res?.data));
      setPages({
        page: res.criterion.page,
        total: res?.criterion?.total,
        limit: res?.criterion?.limit,
      });
    } else {
      setRows([]);
      setPages({
        page: 1,
        total: 0,
        limit: 10,
      });
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: TrainingListType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`
    );
  };

  const handleChart: (data: TrainingListType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleTask: (data: TrainingListType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${RouteUrls.add}`
    );
  };

  const handleCallDetails: (data: TrainingListType) => void = (data) => {
    dispatch(
      addSelectedTraining(PatientFormatter.CheckPatientDeviceTraining(data))
    );
    dispatch(
      addSelectedDevice(PatientFormatter.CheckPatientDeviceDetails(data))
    );
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        careplanId: data.careplanId,
        programId: data.programId,
        providerName: data.teamProvider,
        providerId: data.teamProviderId,
      })
    );
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.device}/${data.patientDeviceId}/${RouteUrls.callLog}`
    );
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol(myInfo.roleId)}
      tableRow={rows}
      tableAction={(rowData) =>
        actionArr(
          () => handleChart(rowData),
          () => handleCallDetails(rowData),
          () => handlePatientDetails(rowData),
          () => handleTask(rowData),
          rowData,
          myInfo.roleId
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Fragment>
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        loading={loading}
        tblHead={ControlledTable.TblHead(TableCol(myInfo.roleId))}
        height={tableHeight}
        Pagination={
          <ControlledTable.Pagination
            mainPage={pages.page}
            limit={pages.limit}
            total={pages.total}
            handleClick={(currentPage, currentLimit) =>
              handleSetData({ page: currentPage, limit: currentLimit })
            }
            tableStyle={true}
          />
        }
      />
    </Fragment>
  );
};
export default MyTrainingList;
