import { FC, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { CommonStyle, ControlledTable, Modal } from "Components";
import CstmBtn from "Components/CstmBtn";
import { ModalLayout, TableLayout } from "Layouts";
import { actionArr, TableCol } from "./Data";
import UserApi from "Service/User.api";
import { RouteUrls } from "Constant/RouteUrls";
import { HeadContextType } from "DataTypes/Common";
import { HeadContext } from "Context/HeadContext";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { UserTableListType } from "DataTypes/PracticeMangement.type";
import Formate from "./Formate";
import { objectValues } from "DataTypes/Services/Index.type";
import { HeaderInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import UserFormatter from "Utils/Users.Formatter";
import { CommonButtonArray } from "Components/Modal/Data";
import CommonFormatter from "Utils/Common.Formatter";

const OrganizationStaffList: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.practice
  );
  const { organization }: HeaderInitialStateType = useSelector(
    (state: any) => state?.header
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { orgId } = useParams();
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState<UserTableListType[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [selectedUser, setSelectedUser] = useState<UserTableListType | null>(
    null
  );

  useEffect(() => {
    handleSetData(page, limit);
    CommonFormatter.HandleNavArr({
      id: "org-list",
      label: organization.name,
      path: `/${RouteUrls.hs}/${RouteUrls.organization}/${organization.id}/${RouteUrls.staff}/${RouteUrls.list}`,
      level: 2,
      link: RouteUrls.list,
      navType: "org",
    });
  }, [orgId]);

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const payload = {
      data: {
        practiceId: orgId,
        isDeleted: null,
      },
      criterion: {
        offset: (currentPage - 1) * currentLimit,
        limit: currentLimit,
      },
    };
    const res: any = await UserApi.list(payload);
    if (res?.success) {
      const formatedList = UserFormatter.VerifyListArrResponse(res?.data);
      setRowData(Formate.ConvertUserList(formatedList));
      setPage(res.criterion.page);
      setTotalRecord(res.criterion.total);
      setLimit(res.criterion.limit);
    } else {
      setRowData([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const handleEdit: (data: UserTableListType) => void = (data) =>
    navigate("../" + data?.id + "/update", { state: data });

  const handleDownload = async (data: any) => {
    const res: objectValues = await UserApi.patientTaskExport(data?.id);
    if (res?.data) {
      const binaryResponse = res?.data;
      const blob = new Blob([binaryResponse], {
        type: "application/zip",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "AssociatedPatientsTasksList.zip";
      link.click();
    }
  };

  const handleClick: (data: UserTableListType) => void = async (data) => {
    const newStatus = data?.status == "INACTIVE" ? "ACTIVE" : "INACTIVE";
    if (newStatus == "ACTIVE") handleAllowToChangeStatus(data);
    else {
      setSelectedUser(data);
      // according to 10932
      // const payload = {
      //   expectedColumnPair: [
      //     { key: "Status", value: "Open" },
      //     { key: "assigneeId", value: data.id },
      //   ],
      // };
      // const res = await PatientApi.fetchTaskList({
      //   data: payload,
      //   limit: 1,
      //   offset: 0,
      // });
      // if (res?.success) {
      //   const taskData = TaskFormatter.list(res?.data);
      //   if (taskData.length > 0) setSelectedUser(data);
      //   else handleAllowToChangeStatus(data);
      // }
    }
  };

  const handleAllowToChangeStatus: (data: UserTableListType) => void = async (
    data
  ) => {
    setSelectedUser(null);
    setLoading(true);
    const newStatus = data?.status == "INACTIVE" ? "ACTIVE" : "INACTIVE";
    const practiceId = typeof data?.id == "number" ? data?.id : 0;
    const res: any = await UserApi.changeStatus({
      id1: practiceId,
      status: newStatus,
    });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      handleSetData(page, limit);
      selectedUser && setSelectedUser(null);
    }
    setLoading(false);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={rowData}
      tableAction={(data: any) =>
        actionArr(
          handleEdit,
          handleClick,
          handleDownload,
          data,
          permission,
          myInfo
        )
      }
    />
  );

  return (
    <Box height="91%" sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}>
      <ModalLayout
        modwidth="33%"
        heading={"Warning"}
        content={
          <Modal.AssignPatient content="Please move any outstanding tasks and reassign patients for this user to an active user of the organization immediately." />
        }
        open={selectedUser ? true : false}
        onClose={() => setSelectedUser(null)}
        buttonArr={CommonButtonArray(
          () => selectedUser && handleAllowToChangeStatus(selectedUser),
          () => setSelectedUser(null),
          "Yes",
          "No"
        )}
      />
      <Box
        height={"10%"}
        justifyContent={"space-between"}
        sx={{ ...CommonStyle.sxRow }}
      >
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {`${organization.name} Staff List`}
        </Typography>
        <Box
          sx={{ ...CommonStyle.sxRow }}
          width="30%"
          justifyContent="flex-end"
        >
          {!organization.masterPractice && (
            <Typography sx={{ mr: 1 }} variant="subtitle1" fontWeight="bold">
              {`Model: ${organization.modelName}`}
            </Typography>
          )}
          <CstmBtn
            label="Add Staff"
            onClick={() => navigate(`../${RouteUrls.add}`)}
            width="40%"
          />
        </Box>
      </Box>
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol)}
        height={"89%"}
        loading={loading}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={handleSetData}
            tableStyle={true}
          />
        }
      />
    </Box>
  );
};
export default OrganizationStaffList;
