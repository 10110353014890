// #14143
import { FC, useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ImgPath from "Constant/Imgs";
import { ModalLayout } from "Layouts";
import { Modal, ControlledForm, CommonStyle, Loader } from "Components";
import { useNavigate } from "react-router-dom";
import { getScheduledData } from ".";
import NoData from "Components/NoData";
import { FormDataType } from "DataTypes/Form";
import { CheckStringEmpty, ValidateFormData } from "Utils/common";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import {
  addSelectedDevice,
  addSelectedPrgm,
  addSelectedTraining,
} from "Redux/Patient/Action";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import { RouteUrls } from "Constant/RouteUrls";
import DateTime from "Utils/DateTime";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import AppointmentApi from "Service/Appointment.api";
import PatientFormatter from "Utils/Patient.Formatter";
import { AppointmentType } from "DataTypes/Services/Patient.type";
import { label } from "Constant/En";
import CommonFormatter from "Utils/Common.Formatter";
import Stages from "Constant/Stages";

const AppointmentSection: FC<{ updateSchedule: () => void }> = ({
  updateSchedule,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [open, setOpen] = useState(false);
  const [appList, setAppList] = useState<AppointmentType[]>([]);
  const [appDate, setAppDate] = useState(DateTime.CurrentDate());
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [formData, setFormData] = useState<FormDataType[]>([]);
  const [editAppointment, setEditAppointment] =
    useState<AppointmentType | null>(null);

  useEffect(() => {
    handleDateAppointment();
  }, [appDate]);

  const handleDateAppointment: () => void = async () => {
    setLoading(true);
    const fromDate = DateTime.ToUTCDateTime(appDate);
    const newDate = DateTime.AddSubMin(fromDate, 1439);
    const toDate = DateTime.ToDbUtcFormat(newDate);
    const res = await getScheduledData({ fromDate, toDate });
    setAppList(res);
    setLoading(false);
  };

  useEffect(() => {
    setOpen(editAppointment ? true : false);
  }, [editAppointment]);

  const handleStart: (data: AppointmentType) => void = (data) => {
    const abbrevation = data.activity.slice(0, 3);
    const isRev = data.activity == "RPM Treatment Plan Revision" ? true : false;
    const recId = data.careplanRecurringId;
    const careId = data.careplanId;
    if (data.patientDeviceId) {
      dispatch(
        addSelectedTraining({
          ...PatientFormatter.CheckPatientDeviceTraining(data),
          trainingActivity: data.activity,
        })
      );
      dispatch(
        addSelectedDevice(PatientFormatter.CheckPatientDeviceDetails(data))
      );
      dispatch(
        addSelectedPrgm({
          ...selectedPrgmInitialState,
          careplanId: data.careplanId,
          programId: data.programId,
          providerName: data.provider,
          providerId: null,
        })
      );
      navigate(
        `${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}` +
        `/${RouteUrls.device}/${data.patientDeviceId}/${RouteUrls.callLog}`
      );
    } else {
      const route = CommonFormatter.HandleScreenRoute({
        prgm: abbrevation,
        careId: careId,
        isRec: recId ? true : false,
        recId: recId,
        isRev: isRev,
        careState: data.careplanState,
      });
      dispatch(
        addSelectedPrgm({
          ...selectedPrgmInitialState,
          abbrevation: abbrevation,
          callDefaultRoute: `../${route}`,
          careplanId: careId,
          careplanRecurringId: recId,
          careplanRecurringStatus: recId ? "not-completed" : "",
          careplanState: data.careplanState,
          careplanStage: PatientFormatter.GetCPStage(data.careplanState),
          careplanType: isRev ? "REVISION" : "",
          programId: data.programId,
          readOnlyArr: isRev ? ["team"] : [],
          showLogTime:
            abbrevation == "RPM"
              ? true
              : data.careplanState == Stages.CPSignOff
                ? false
                : true,
          ...(!recId ? { showSaveBtn: true } : {}),
          showSubmitBtn: true,
        })
      );
      if (recId &&
        (abbrevation == "CCM" || abbrevation == "PCM" || abbrevation == "BHI")
      ) {
        const appointYear = parseInt(DateTime.GetYear(data.datetime));
        const currentYear = parseInt(DateTime.CurrentYear());
        const appointMonth = parseInt(DateTime.GetMonth(data.datetime));
        const currentMonth = parseInt(DateTime.CurrentMonth());
        if ((appointYear < currentYear) ||
          ((appointYear == currentYear) && (!(appointMonth > currentMonth)))) {
          navigate(
            `/${RouteUrls.hs}/${RouteUrls.patient}` +
            `/${data.patientId}/${RouteUrls.callLog}`
          );
        } else {
          dispatch(setAlertData({
            openAlert: true,
            alertVariant: "warning",
            alertMessage: "The scheduled recurring month has not yet arrived."
          }))
        }
      } else {
        navigate(
          `/${RouteUrls.hs}/${RouteUrls.patient}/` +
          `${data.patientId}/${RouteUrls.callLog}`
        );
      }
    }
  };

  const handleSave: () => void = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    setAddLoading(true);
    if (!newArr.errorCheck) {
      const timezone = CheckStringEmpty(formData[6]?.dropValue);
      const dateTime = DateTime.ValidDate(formData[5]?.value);
      const payload = {
        patientId: formData[0]?.dropValue,
        appointmentType: formData[1]?.value,
        programId: selectedPrgm?.programId,
        appointmentDate: timezone
          ? DateTime.AddHoursToUTCDateTime(
            DateTime.HoursTimeZoneSwitch(timezone),
            dateTime
          )
          : DateTime.ToUTCDateTime(dateTime),
        timeZone: timezone,
        notes: formData[7]?.value,
        status: "OPEN",
        careplanId: selectedPrgm?.careplanId,
        careplanRecurringId: selectedPrgm?.careplanRecurringId,
        providerId: selectedPrgm?.providerId,
        patientDeviceId:
          (editAppointment
            ? editAppointment.patientDeviceId
            : formData[2]?.value) || null,
      };
      if (editAppointment) {
        const res = await AppointmentApi.Update({
          data: payload,
          id1: editAppointment.id,
        });
        if (res?.success) {
          dispatch(setAlertData(res?.alert));
          handleClose();
          handleDateAppointment();
          updateSchedule();
        }
      } else {
        const res = await AppointmentApi.Create({ data: payload });
        if (res?.success) {
          dispatch(setAlertData(res?.alert));
          handleClose();
          handleDateAppointment();
          updateSchedule();
        }
      }
    }
    setAddLoading(false);
  };

  const handleClose: () => void = () => {
    setEditAppointment(null);
    setOpen(false);
  };

  return (
    <Box width="25%" height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
      <ModalLayout
        modwidth="55%"
        heading={editAppointment ? "Edit Appointment" : "Create Appointment"}
        content={
          <Modal.AppointmentModal
            setForm={(data) => setFormData(data)}
            editAppointment={editAppointment}
          />
        }
        open={open}
        onClose={handleClose}
        buttonArr={[
          {
            id: "1",
            label: editAppointment ? "Save" : "Add",
            clickHandler: handleSave,
            disable: addLoading,
          },
          {
            id: "2",
            label: "Cancel",
            clickHandler: handleClose,
            type: "border",
          },
        ]}
      />
      <Box sx={{ ...sxHeadBox }}>
        <Typography fontWeight="bold">Schedule</Typography>
        <Box onClick={() => setOpen(true)}>
          <ImgPath.PlusIcon titleAccess="Add Appointment" />
        </Box>
      </Box>
      <ControlledForm.Calendar
        value={DateTime.CurrentDate()}
        onChange={(date) => setAppDate(date)}
      />
      {loading ? (
        <Loader />
      ) : appList.length ? (
        appList.map((el) => {
          const datetime = DateTime.UTCToLocalDateTime(el.datetime).split(" ");
          return (
            <Box key={el.id} sx={{ ...sxMain }}>
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {el.name}
                </Typography>
                <Typography variant="subtitle1">{el.activity}</Typography>
              </Box>
              {datetime.length > 2 ? (
                <Box>
                  <Typography variant="subtitle1">{datetime[0]}</Typography>
                  <Typography variant="subtitle1">
                    {datetime[1]} {datetime[2]}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="subtitle1">{label.naText}</Typography>
              )}
              <Box>
                <IconButton title="Start" onClick={() => handleStart(el)}>
                  <ImgPath.StartIcon />
                </IconButton>
                <IconButton
                  title="Edit Appointment"
                  onClick={() => setEditAppointment(el)}
                >
                  <ImgPath.EditOutlined />
                </IconButton>
              </Box>
            </Box>
          );
        })
      ) : (
        <NoData />
      )}
    </Box>
  );
};
export default AppointmentSection;

const sxHeadBox = {
  ...CommonStyle.sxRow,
  mx: 1,
  mt: 1,
  justifyContent: "space-between",
  "&>div": {
    ...CommonStyle.sxRoundIconsMainMd,
  },
};

const sxMain = {
  borderBottom: "1px solid",
  borderBottomColor: "custom.main",
  p: 1,
  ...CommonStyle.sxRow,
  "&>div:first-of-type": {
    width: "45%",
  },
  "&>div:nth-of-type(2)": {
    width: "30%",
    textAlign: "center",
  },
  "&>div:last-of-type": {
    ...CommonStyle.sxRow,
    width: "25%",
    justifyContent: "flex-end",
    "&>button": {
      ...CommonStyle.sxRoundIconsMainMd,
      ":first-of-type": {
        mr: 0.5,
      },
    },
  },
};
