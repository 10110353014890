import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { CommonStyle, Loader } from "Components";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import {
  PatientInitialStateType,
  ScreenInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { FC, Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CarePlanApi from "Service/CarePlan.api";
import UserApi from "Service/User.api";
import CPFormatter from "Utils/CP.Formatter";
import { FormatDropdownData } from "Utils/DataFormatter";
import UsersFormatter from "Utils/Users.Formatter";
import Format from "./Format";
import { FormArr1, FormArr2 } from "./Data";
import { commonFormSwitch } from "Utils/SwitchCases";
import { HandleChangeProps } from "DataTypes/Common";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { UserListApiType } from "DataTypes/Services/User.type";
import NoData from "Components/NoData";
import { CareTeamAddProType } from "DataTypes/PatientManagement.type";
import ImgPath from "Constant/Imgs";
import {
  setAlertData,
  setFormLoader,
  setRightHead,
  setTimerStatus,
} from "Redux/Screen/Action";
import { ModalLayout } from "Layouts";
import { DeleteButtonArray } from "Components/Modal/Data";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PatientFormatter from "Utils/Patient.Formatter";
import Stages from "Constant/Stages";
import { RouteUrls } from "Constant/RouteUrls";
import { addSelectedPrgm } from "Redux/Patient/Action";
import CstmBtn from "Components/CstmBtn";
import CommonFormatter from "Utils/Common.Formatter";

const CareTeam: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const {
    rightHead: { timerStatus },
  }: ScreenInitialStateType = useSelector((state: any) => state?.screen);
  const isRPM = selectedPrgm.abbrevation == "RPM" ? true : false;
  const seniorMember = selectedPrgm.seniorTeamMember;
  const currentStage = selectedPrgm.careplanStage;
  const revision = selectedPrgm.careplanType == "REVISION";
  const quesStage = PatientFormatter.GetCPStage(Stages.CPQues);
  const teamStage = PatientFormatter.GetCPStage(Stages.CPTeam);
  const shareStage = PatientFormatter.GetCPStage(Stages.CPShare);
  const recStage = PatientFormatter.GetCPStage(Stages.Recurring);

  //Condition For Save & Next Button & Edit form by Team Member
  let editFormByTM = false;
  let viewSaveNextBtn = false;
  editFormByTM = viewSaveNextBtn =
    seniorMember &&
    currentStage > quesStage &&
    currentStage < recStage &&
    !revision;

  //Condition For Save Button & Edit form by SA
  let viewSaveBtn = false;
  let editFormBySA = false;
  const currentUrl = window.location.href;
  viewSaveBtn = editFormBySA =
    currentUrl.includes(RouteUrls.editTeam) && myInfo.roleId == 1;

  //Condition For Next Button
  let viewNextBtn = false;
  revision && seniorMember && (viewNextBtn = true);
  (currentStage == recStage || (!seniorMember && currentStage > teamStage)) &&
    !viewSaveBtn &&
    (viewNextBtn = true);

  //Condition For Edit Form for User
  let editFormByUser = false;
  editFormByUser = editFormByTM || editFormBySA;

  const careId = selectedPrgm.careplanId;
  const orgLocId = selectedPrgm.practiceLocationId;
  const prgmName = selectedPrgm.abbrevation;
  const [ccUserList, setCCUserList] = useState<DropdownArrPropsType[]>([]);
  const [cpUserList, setCPUserList] = useState<UserListApiType[]>([]);
  const [form1, setForm1] = useState<FormDataType[]>([]);
  const [form2, setForm2] = useState<CareTeamAddProType[]>([]);
  const [selectSec, setSelectSec] = useState<CareTeamAddProType | null>(null);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    initailLoad();
    editFormBySA &&
      CommonFormatter.HandleNavArr({
        id: "edit-team",
        label: "Edit Team",
        path: `/${RouteUrls.hs}/${RouteUrls.patient}/${selectedPrgm.patientId}/${RouteUrls.carePlan}/${selectedPrgm.careplanId}/${RouteUrls.editTeam}`,
        level: 5,
        link: RouteUrls.editTeam,
        navType: "patient",
      });

    ///For RPM Program Changes
    if (isRPM && timerStatus == 1 && viewSaveNextBtn) {
      dispatch(setRightHead("care-plan"));
      dispatch(setTimerStatus({ status: 2, parentApiCall: true }));
    }
  }, [careId]);

  const initailLoad: () => void = async () => {
    setLoad(true);
    const cmlist = await handleUserApiCall(3);
    const cclist = await handleUserApiCall(4);
    setCCUserList(cclist);
    const cplist = await handleUserApiCall(5);
    const teamRes = await CarePlanApi.CPTeamSearch({ id1: careId });
    if (teamRes?.success) {
      const teamData = CPFormatter.CheckCPTeam(teamRes?.data);
      let newForm1: FormDataType[] = [];
      if (editFormBySA) {
        newForm1 = Format.SetEditUrlFormData1(
          FormArr1,
          teamData,
          cmlist,
          cclist,
          cplist,
          prgmName
        );
      } else {
        newForm1 = Format.SetFormData1(
          FormArr1,
          teamData,
          cmlist,
          cclist,
          cplist,
          prgmName
        );
      }
      setForm1(
        editFormByUser
          ? newForm1
          : [
            ...newForm1.map((el) => {
              el.readOnly = true;
              return el;
            }),
          ]
      );
      const newForm2 = Format.SetFormData2(FormArr2, teamData);
      setForm2(
        editFormByUser
          ? newForm2
          : [
            ...newForm2.map((el) => {
              return {
                ...el,
                formData: el.formData.map((formEl) => {
                  formEl.readOnly = true;
                  return formEl;
                }),
              };
            }),
          ]
      );
    }
    setLoad(false);
  };

  const handleUserApiCall: (
    roleId: number
  ) => Promise<DropdownArrPropsType[]> = async (roleId) => {
    const payload = {
      practiceLocationId: orgLocId,
      roleId: roleId,
    };
    const res = await UserApi.list({ data: payload });
    let list: DropdownArrPropsType[] = [];
    if (res?.success) {
      const data = UsersFormatter.VerifyListArrResponse(res?.data);
      const filterData = data.filter((el) => el.status != "INACTIVE");
      if (roleId == 5) setCPUserList(filterData);
      list = FormatDropdownData(filterData, "id", "fullNameDes", "id");
    }
    return list;
  };

  const handleForm1Change: (props: HandleChangeProps) => void = (props) => {
    const { id, value } = props;
    const newForm: FormDataType[] = JSON.parse(JSON.stringify(form1));
    if (id == "provider") {
      const findCP = cpUserList.find((el) => el.id == value);
      newForm[3].value = findCP?.speciality || "";
    }
    if (id == "pcc") {
      newForm[5].dropArr = ccUserList.filter((el) => el.value != value);
    }
    if (id == "scc") {
      newForm[4].dropArr = ccUserList.filter((el) => el.value != value);
    }
    setForm1(HandleFormChange(props, newForm));
  };

  const handleForm2Change: (
    props: HandleChangeProps,
    secIndex: number | null
  ) => void = (props, secIndex) => {
    const { id, value } = props;
    const findSecIndex = form2.findIndex((el) => el.id == secIndex);
    const findObjIndex = form2[findSecIndex].formData.findIndex(
      (el) => el.id == id
    );
    const newFormArr: CareTeamAddProType[] = JSON.parse(JSON.stringify(form2));
    newFormArr[findSecIndex].formData[findObjIndex].value = value;
    setForm2(newFormArr);
  };

  const handleAddProvider = () => {
    setForm2([
      ...form2,
      {
        id: form2.length + 1,
        addProviderid: 0,
        formData: JSON.parse(JSON.stringify([...FormArr2])),
      },
    ]);
  };

  const handleLocalRemove: (section: CareTeamAddProType) => void = (
    section
  ) => {
    if (section.addProviderid !== 0) setSelectSec(section);
    else {
      const newArr = form2.filter((el) => el?.id != section.id);
      const newArr1 = [...newArr].map((el, index) => {
        el.id = index + 1;
        return el;
      });
      setForm2([...newArr1]);
    }
  };

  const handleDeleteAddProvider: () => void = async () => {
    if (selectSec) {
      const res = await CarePlanApi.DeleteAddProvider({
        id1: careId,
        id2: selectSec?.addProviderid,
      });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        handleLocalRemove({ ...selectSec, addProviderid: 0 });
        setSelectSec(null);
      }
    }
  };

  const handleTeamSaveNext: () => void = async () => {
    let errorCheck = false;
    const newArr = ValidateFormData(form1);
    if (newArr.errorCheck) errorCheck = true;
    setForm1(newArr?.formData);
    const newArr2 = form2.map((el) => {
      const newArr3 = ValidateFormData(el.formData);
      if (newArr3.errorCheck) errorCheck = true;
      el.formData = newArr3?.formData;
      return el;
    });
    setForm2(newArr2);
    if (!errorCheck) {
      dispatch(setFormLoader(true));
      const addProviderArr = form2.map((el) => {
        return {
          id: el.addProviderid || null,
          careplanId: selectedPrgm.careplanId,
          programId: selectedPrgm.programId,
          patientId: selectedPrgm.patientId,
          practiceId: selectedPrgm.practiceId,
          practiceLocationId: selectedPrgm.practiceLocationId,
          additionalProviderName: el.formData[0].value || null,
          additionalProviderSpeciality: el.formData[1].value || null,
          addProviderOrganization: el.formData[2].value || null,
          phoneNumber: el.formData[3].value || null,
          faxNumber: el.formData[4].value || null,
        };
      });
      const payload = {
        careplanId: selectedPrgm.careplanId,
        programId: selectedPrgm.programId,
        patientId: selectedPrgm.patientId,
        practiceId: selectedPrgm.practiceId,
        practiceLocationId: selectedPrgm.practiceLocationId,
        caremanagerId: form1[0].dropValue || null,
        billableProviderId: form1[1].dropValue || null,
        teamProviderId: form1[2].dropValue || null,
        primaryCareCoordinatorId: form1[4].dropValue || null,
        secondaryCareCoordinatorId: form1[5].dropValue || null,
        authorizedRepresentative1: form1[6].value || null,
        authorizedRepresentative2: form1[7].value || null,
        authorizedRepresentative3: form1[8].value || null,
        careplanAdditionalProvider: addProviderArr,
      };
      const response = await CarePlanApi.CPTeamSave({
        id1: careId,
        data: { data: payload },
      });
      if (response?.success) {
        dispatch(setAlertData(response.alert));
        selectedPrgm &&
          dispatch(
            addSelectedPrgm({
              ...selectedPrgm,
              ...(selectedPrgm.careplanStage >
              PatientFormatter.GetCPStage(Stages.CPTeam)
                ? {}
                : {
                  careplanState: Stages.CPShare,
                  careplanStage: PatientFormatter.GetCPStage(Stages.CPShare),
                }),
            })
          );
        navigate(`../${RouteUrls.careSummary}`, { replace: true });
      }
      dispatch(setFormLoader(false));
    }
  };

  const handleTeamSave: () => void = async () => {
    let errorCheck = false;
    const newArr = ValidateFormData(form1);
    if (newArr.errorCheck) errorCheck = true;
    setForm1(newArr?.formData);
    if (!errorCheck) {
      dispatch(setFormLoader(true));
      const payload = {
        careplanId: selectedPrgm.careplanId,
        programId: selectedPrgm.programId,
        patientId: selectedPrgm.patientId,
        practiceId: selectedPrgm.practiceId,
        practiceLocationId: selectedPrgm.practiceLocationId,
        caremanagerId: form1[0].dropValue || null,
        billableProviderId: form1[1].dropValue || null,
        teamProviderId: form1[2].dropValue || null,
        primaryCareCoordinatorId: form1[4].dropValue || null,
        secondaryCareCoordinatorId: form1[5].dropValue || null,
        authorizedRepresentative1: form1[6].value || null,
        authorizedRepresentative2: form1[7].value || null,
        authorizedRepresentative3: form1[8].value || null,
        careplanAdditionalProvider: [],
        isCareplanStateUpdateRequire: false,
      };
      const response = await CarePlanApi.CPTeamSave({
        id1: careId,
        data: { data: payload },
      });
      if (response?.success) {
        dispatch(setAlertData(response.alert));
        navigate(-1);
      }
      dispatch(setFormLoader(false));
    }
  };

  const handleNext: () => void = async () => {
    if (selectedPrgm.careplanStage < shareStage) {
      const res = await CarePlanApi.CPStateUpdate({
        data: { toState: Stages.CPShare },
        id1: careId,
      });
      if (res?.success) {
        dispatch(
          addSelectedPrgm({
            ...selectedPrgm,
            careplanState: Stages.CPShare,
            careplanStage: shareStage,
          })
        );
      }
    }
    navigate(`../${RouteUrls.careSummary}`, { replace: true });
  };

  const PrimSection: JSX.Element = (
    <Grid container rowSpacing={1} columnSpacing={2} mb={2}>
      {form1.map((el) => {
        return el.boxType == "empty-block" ? (
          <Fragment />
        ) : (
          <Grid key={el.id} item {...{ md: 4, lg: 3, xl: 3 }}>
            {commonFormSwitch({
              formObj: el,
              onChange: handleForm1Change,
            })}
          </Grid>
        );
      })}
    </Grid>
  );

  const SecSection: JSX.Element = (
    <Fragment>
      {form2?.map((el) => {
        return (
          <Fragment key={el.id}>
            <Divider />
            <Box sx={{ ...CommonStyle.sxRow }} position="relative">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={2}
                sx={{ mb: 2, mt: 1, pr: editFormByTM ? 3 : 0 }}
              >
                {el.formData.map((formEl: FormDataType) => {
                  return (
                    <Grid key={formEl.id} item {...{ md: 4, lg: 3, xl: 3 }}>
                      {commonFormSwitch({
                        formObj: formEl,
                        onChange: (data) => handleForm2Change(data, el.id),
                      })}
                    </Grid>
                  );
                })}
              </Grid>
              {editFormByTM && (
                <IconButton
                  onClick={() => handleLocalRemove(el)}
                  title="Delete"
                  sx={{ ...IconRemove, ...CommonStyle.sxRoundIconsMainSm }}
                >
                  <ImgPath.CloseIcon />
                </IconButton>
              )}
            </Box>
          </Fragment>
        );
      })}
    </Fragment>
  );

  const BtnSection: JSX.Element = (
    <Box sx={{ ...sxBtnSection }}>
      {editFormByTM && form2.length < 5 && (
        <CstmBtn
          btnType="border"
          label={"Add Additional Provider"}
          onClick={() => {
            handleAddProvider();
          }}
          sxProps={{ mr: 2 }}
        />
      )}
      {viewSaveBtn && (
        <CstmBtn
          label={"Save"}
          onClick={() => handleTeamSave()}
          width="10%"
          enableFormLoad={true}
        />
      )}
      {viewSaveNextBtn && (
        <CstmBtn
          label={"Save & Next"}
          onClick={() => handleTeamSaveNext()}
          width="10%"
          enableFormLoad={true}
        />
      )}
      {viewNextBtn && (
        <CstmBtn label={"Next"} onClick={() => handleNext()} width="10%" />
      )}
    </Box>
  );

  return (
    <Box width="91%" sx={{ overflow: "auto", ...CommonStyle.sxWhiteCard }}>
      <ModalLayout
        modwidth="30%"
        heading={"Warning"}
        content={
          <Typography textAlign={"center"}>
            Are you sure want to remove this Additional Provider?
          </Typography>
        }
        open={selectSec ? true : false}
        onClose={() => setSelectSec(null)}
        buttonArr={DeleteButtonArray(handleDeleteAddProvider, () =>
          setSelectSec(null)
        )}
      />
      <Typography height="5%" variant="body2" fontWeight="bold">
        Enrolled Program - {prgmName}
      </Typography>
      {load ? (
        <Loader />
      ) : form1.length > 0 ? (
        <Fragment>
          {PrimSection}
          {!editFormBySA && SecSection}
          <Divider />
          {BtnSection}
        </Fragment>
      ) : (
        <NoData />
      )}
    </Box>
  );
};
export default CareTeam;

const IconRemove = {
  position: "absolute",
  top: 50,
  right: -12,
};

const sxBtnSection = {
  height: "10%",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  justifyContent: "flex-end",
};
