import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { SmartmeterSettingModalProps } from "DataTypes/Modal";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { FormDataType } from "DataTypes/Form";

const SmartmeterSettingsModel = ({
  formArr,
  handleChange,
}: SmartmeterSettingModalProps) => {
  const [formData, setFormData] = useState<FormDataType[]>([]);

  useEffect(() => {
    formArr && setFormData(formArr);
  }, [formArr]);
  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      {formData &&
        handleChange &&
        formData.length > 0 &&
        formData.map((el) => {
          return (
            <Grid
              item
              key={el.id}
              {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
            >
              {commonFormSwitch({
                formObj: el,
                onChange: (data) => handleChange(data, [el]),
              })}
            </Grid>
          );
        })}
    </Grid>
  );
};
export default SmartmeterSettingsModel;
