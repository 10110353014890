import { MasterPrgmPrblmType, OptionObjType } from "DataTypes/Master";
import { SampleInputObject } from "./Data";
import { AscendSort, AscendSortByNumber, CheckArr } from "Utils/common";
import SwitchCase from "Utils/SwitchCase";
import { FormDataType } from "DataTypes/Form";
import { HandleChangeProps } from "DataTypes/Common";
import { VisitPrgmType } from "DataTypes/Services/Visit.type";
import { FormatDropdownData } from "Utils/DataFormatter";
import { ProblemListType } from "DataTypes/Services/Problem.type";
import { QuesAnsType } from "DataTypes/Services/Question.type";

const SetOptionArr: (ansType: QuesAnsType) => OptionObjType[] = (ansType) => {
  if (ansType == "singleSelect" || ansType == "multiSelect") {
    return [
      {
        id: 1,
        edit: false,
        formObj: SampleInputObject("Option 1", false),
        optionId: null,
        required: false,
        lastVal: "Option 1",
      },
      {
        id: 2,
        edit: false,
        formObj: SampleInputObject("Option 2", false),
        optionId: null,
        required: false,
        lastVal: "Option 2",
      },
    ];
  } else {
    return [];
  }
};

const ValidateOptionData = (optionData: OptionObjType[]) => {
  let singleEntryErrorCheck = false;
  const newArr = optionData.map((el) => {
    let result = { error: false, errorText: "" };
    if (el.formObj.required) result = SwitchCase.FormValidation(el.formObj);
    if (result.error) singleEntryErrorCheck = true;
    el.formObj.errorText = result.errorText;
    el.formObj.error = result.error;
    return el;
  });
  return { formData: newArr, errorCheck: singleEntryErrorCheck };
};

const HandleOnChange: (
  formData: FormDataType[],
  masterVisit: VisitPrgmType[],
  masterPrblm: MasterPrgmPrblmType[],
  props: HandleChangeProps
) => FormDataType[] = (formData, masterVisit, masterPrblm, { id, value }) => {
  const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formData));
  const newVal = CheckArr(value);
  if (id == "program-id") {
    const existVal = CheckArr(newFormData[0].dropValue);
    if (newVal.length > existVal.length) {
      ///Increment
      const IncDiff = newVal.filter((x) => !existVal.includes(x));
      const filterVisit = masterVisit.filter(
        (filEl) => filEl.prgmId == IncDiff[0]
      );
      if (newFormData[2].dropArr) {
        newFormData[2].dropArr = FormatDropdownData(
          masterVisit.filter((visEl) => newVal.includes(visEl.prgmId)),
          "id",
          "name",
          "id"
        );
        // newFormData[2].dropArr = AscendSortByNumber(
        //   [
        //     ...newFormData[2].dropArr,
        //     ...FormatDropdownData(filterVisit, "id", "name", "id"),
        //   ],
        //   "sequenceNumber"
        // );
      }
      const findPrb = masterPrblm.find((filEl) => filEl.id == IncDiff[0]);
      if (findPrb && newFormData[1].dropArr) {
        const filterSamePrb = findPrb.problemArr.filter((filEl) => {
          if (!newFormData[1].dropArr?.some((soEl) => soEl.value == filEl.id))
            return filEl;
        });
        newFormData[1].dropArr = AscendSort(
          [
            ...newFormData[1].dropArr,
            ...FormatDropdownData(filterSamePrb, "id", "name", "id"),
          ],
          "label"
        );
      }
    } else {
      ///Decrement
      const DeDiff = existVal.filter((x) => !newVal.includes(x));
      //for visit we need to filter values from master visit and then match it with droparr and dropVal
      const filterVisit = masterVisit.filter(
        (filEl) => filEl.prgmId == DeDiff[0]
      );
      if (newFormData[2].dropArr) {
        newFormData[2].dropArr = FormatDropdownData(
          masterVisit.filter((visEl) => newVal.includes(visEl.prgmId)),
          "id",
          "name",
          "id"
        );
        // newFormData[2].dropArr = AscendSortByNumber(
        //   newFormData[2].dropArr.filter((filEl) => {
        //     if (!filterVisit.some((soEl) => soEl.id == filEl.value))
        //       return filEl;
        //   }),
        //   "id"
        // );
      }
      if (newFormData[2].dropValue) {
        newFormData[2].dropValue = newFormData[2].value =
          newFormData[2].dropValue.filter((filEl: any) => {
            if (!filterVisit.some((soEl) => soEl.id == filEl)) return filEl;
          });
      } else newFormData[1].dropValue = newFormData[1].value = [];

      //for prblms we need to filter reamining values from master problem and then match it with droparr and dropVal
      const intersection = existVal.filter((x) => newVal.includes(x));
      const filterPrblm: ProblemListType[] = intersection.reduce(
        (total: ProblemListType[], current: number) => {
          const find = masterPrblm.find((el) => el.id == current);
          let arr: ProblemListType[] = [];
          if (find) {
            arr = find.problemArr.filter((filEl) => {
              if (!total.some((soEl) => soEl.id == filEl.id)) return filEl;
            });
          }
          return [...total, ...arr];
        },
        []
      );
      newFormData[1].dropArr = AscendSort(
        FormatDropdownData(filterPrblm, "id", "name", "id"),
        "label"
      );
      if (newFormData[1].dropValue) {
        newFormData[1].dropValue = newFormData[1].value =
          newFormData[1].dropValue.filter((filEl: any) => {
            if (filterPrblm.some((soEl) => soEl.id == filEl)) return filEl;
          });
      } else newFormData[1].dropValue = newFormData[1].value = [];
    }
    if (newVal.find((newEl) => newEl == 3)) {
      newFormData[0].disableArr = [1, 2, 4, 5, 6];
      newFormData[1].readOnly = true;
      newFormData[2].readOnly = false;
    } else if (newVal.length == 0) {
      newFormData[0].disableArr = [];
      newFormData[1].readOnly = false;
      newFormData[2].readOnly = false;
    } else {
      newFormData[0].disableArr = [3];
      newFormData[1].readOnly = false;
      newFormData[2].readOnly = false;
    }
  }
  const newArr: FormDataType[] = newFormData.map((el) => {
    if (el.id == id) el.value = el.dropValue = value;
    return el;
  });
  return newArr;
};

export default {
  SetOptionArr,
  ValidateOptionData,
  HandleOnChange,
};
