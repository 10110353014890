import { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TableLayout } from "Layouts";
import { CommonStyle, ControlledTable, Filter2 } from "Components";
import { actionArr, FilterArr, TableCol } from "./Data";
import { FetchListProps, Filter2DataProps } from "DataTypes/Common";
import { CreateFilter2Payload } from "Utils/common";
import { ListInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { objectValues } from "DataTypes/Services/Index.type";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import PatientApi from "Service/Patient.api";
import TaskFormatter from "Utils/Task.Formatter";
import { PendingDeviceListType } from "DataTypes/Services/Tasks";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import CommonFormatter from "Utils/Common.Formatter";

const PendingDeviceList: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { taskStatusList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const filterStatus = taskStatusList.filter(
    (item) =>
      item.id == "TASK_PENDING" || item.id == "TASK_WAITING_FOR_DEVICE_ID"
  );
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<PendingDeviceListType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr(myInfo.roleId, filterStatus)))
  );
  const [pages, setPages] = useState<objectValues>({
    page: 1,
    total: 0,
    limit: 10,
  });
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "pending-device",
      label: "Pending Device",
      path: `/${RouteUrls.hs}/${RouteUrls.pendingDevice}`,
      level: 1,
      link: RouteUrls.pendingDevice,
      navType: "patient",
    });
  }, []);

  useEffect(() => {
    handleSetData({ page: 1, limit: pages.limit });
  }, [filterData]);

  const handleSetData: (props: FetchListProps) => void = async (props) => {
    const { page: currentPage, limit: currentLimit } = props;
    const newFilterPayload = CreateFilter2Payload(filterData);
    const {
      ["Created By"]: createdBy,
      ["Status"]: taskStatus,
      ["Priority"]: Priority,
      ["Date"]: Date,
      ["Assignee"]: Assignee,
      ...restFilterPayload
    } = newFilterPayload;

    const payload = {
      ...(createdBy
        ? { patientDeviceCreatedByUserName: String(createdBy) }
        : {}),
      ...(taskStatus ? { taskStatus: String(taskStatus) } : {}),
      ...(Priority ? { taskPriority: String(Priority) } : {}),
      ...(Date ? { taskCreatedAt: String(Date) } : {}),
      ...(Assignee ? { taskAssigneeName: String(Assignee) } : {}),
      ...restFilterPayload,
    };
    setLoading(true);
    const res = await PatientApi.pendingDeviceList({
      data: payload,
      limit: currentLimit,
      offset: (currentPage - 1) * currentLimit,
    });
    if (res?.success) {
      setRows(TaskFormatter.PendingDeviceList(res?.data));
      setPages({
        page: res.criterion.page,
        total: res?.criterion?.total,
        limit: res?.criterion?.limit,
      });
    } else {
      setRows([]);
      setPages({
        page: 1,
        total: 0,
        limit: 10,
      });
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: PendingDeviceListType) => void = (
    data
  ) => {
    navigate(`../${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`);
  };

  const handleChart: (data: PendingDeviceListType) => void = (data) => {
    navigate(
      `../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleTask: (data: PendingDeviceListType) => void = (data) => {
    navigate(
      `../${RouteUrls.patient}/${data.patientId}/${RouteUrls.tasking}/${data.taskId}/${RouteUrls.deviceUpdateTask}`
    );
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol(myInfo.roleId)}
      tableRow={rows}
      tableAction={(rowData) =>
        actionArr(
          () => handleChart(rowData),
          () => handlePatientDetails(rowData),
          () => handleTask(rowData)
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Box
      height="92%"
      sx={{ ...CommonStyle.sxWhiteBoxWithNavbar, px: 2, overflow: "auto" }}
    >
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        loading={loading}
        tblHead={ControlledTable.TblHead(TableCol(myInfo.roleId))}
        height={tableHeight}
        Pagination={
          <ControlledTable.Pagination
            mainPage={pages.page}
            limit={pages.limit}
            total={pages.total}
            handleClick={(currentPage, currentLimit) =>
              handleSetData({ page: currentPage, limit: currentLimit })
            }
            tableStyle={true}
          />
        }
      />
    </Box>
  );
};
export default PendingDeviceList;
