import { FC, Fragment, useEffect, useState } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { DropdownArrPropsType, SingleDropType } from "DataTypes/Form";
import { CommonStyle } from "Components";
import InputLabel from "Components/ControlledForm/Input/Label";
import { ScreenInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const SingleApi: FC<SingleDropType> = (props) => {
  const {
    id,
    label,
    placeholder,
    error,
    errorText,
    required,
    readOnly,
    dropArr,
    inputChange,
    dropVal,
    onClick,
    loading,
    apiSearch,
    inputVal,
    enableFormLoad,
  } = props;
  const { formLoad }: ScreenInitialStateType = useSelector(
    (state: any) => state?.screen
  );
  const viewOnly = readOnly || (enableFormLoad ? formLoad : false);
  const loadingOnly = loading || enableFormLoad ? formLoad : false;
  const [localInput, setLocalInput] = useState("");

  useEffect(() => {
    if (dropVal) {
      const find = dropArr?.find((el) => el.value == dropVal);
      if (find) setLocalInput(find.label);
      else setLocalInput("");
    } else setLocalInput("");
  }, [dropVal]);

  return (
    <Fragment>
      {label && (
        <InputLabel label={label} required={required ? required : false} />
      )}
      <Autocomplete
        id={id}
        readOnly={viewOnly}
        options={dropArr || []}
        loading={loadingOnly}
        value={dropArr?.find((el) => el.value == dropVal) || null}
        onChange={(event: any, newValue: DropdownArrPropsType | null) => {
          if (onClick && event?.type == "click")
            onClick(newValue?.value || null);
        }}
        renderOption={(props, option) => (
          <Typography variant="subtitle1" {...props}>
            {option.label}
          </Typography>
        )}
        {...(inputChange && apiSearch
          ? {
            filterOptions: (x) => x,
            onInputChange: (event, newInputValue) => {
              event?.type == "change" && inputChange(newInputValue);
            },
            inputValue: inputVal || "",
          }
          : {
            onInputChange: (event, newInputValue) => {
              event?.type == "change" && setLocalInput(newInputValue);
            },
            inputValue: localInput,
          })}
        disablePortal
        clearIcon={false}
        clearOnBlur={false}
        getOptionLabel={(option: DropdownArrPropsType) =>
          option.label ?? option
        }
        isOptionEqualToValue={(option, value) => option.id == value.id}
        fullWidth
        sx={{ p: "0px !important", fontSize: "0.85rem" }}
        renderInput={(params) => (
          <TextField
            placeholder={placeholder}
            error={error ? error : false}
            {...params}
            sx={{
              ...(readOnly ? { ...sxReadOnly } : { ...CommonStyle.sxWhiteBox }),
              "&>div": {
                p: "0px !important",
                fontSize: "0.85rem",
                "& >div": {
                  right: "0px !important",
                  position: "unset",
                },
                "& >input": {
                  px: 0.5,
                  py: 1,
                },
                "& >fieldset": {
                  borderWidth: 0,
                },
              },
            }}
          />
        )}
      />
      {error && (
        <Typography variant="subtitle2" color="red">
          {errorText}
        </Typography>
      )}
    </Fragment>
  );
};
export default SingleApi;

const sxReadOnly = {
  backgroundColor: "custom.grey",
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: 1.25,
  " >fieldset": {
    display: "none",
  },
};
