import { Box } from "@mui/material";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import { useState, FC, useEffect } from "react";

type CstmAccordion1Type = {
  mainContent?: JSX.Element;
  subContent?: any;
  item?: any;
  onClick?: (e: any) => void;
  open?: boolean;
};

const CstmAccordion1: FC<CstmAccordion1Type> = (props) => {
  const { mainContent, subContent, item, onClick, open } = props;
  const iconWidth = 40;
  const [view, setView] = useState(false);
  useEffect(() => {
    if (open) {
      setView(open);
    }
  }, []);
  return (
    <Box sx={{ ...sxMain }}>
      {mainContent && (
        <Box
          sx={{
            ...CommonStyle.sxRow,
            backgroundColor: "common.white",
            py: 0.5,
          }}
        >
          <Box width={iconWidth} sx={{ ...CommonStyle.sxRow }}>
            <Box
              sx={{ ...CommonStyle.sxRoundIconsMainSm }}
              onClick={() => {
                if (onClick) {
                  if (view) onClick(null);
                  else onClick(item);
                }
                setView(view ? false : true);
              }}
            >
              {view ? <ImgPath.MinusIcon /> : <ImgPath.PlusIcon />}
            </Box>
          </Box>
          <Box width={"calc(100% - " + iconWidth + "px)"}>{mainContent}</Box>
        </Box>
      )}
      {view && subContent && (
        <Box sx={{ ...CommonStyle.sxRow }}>
          <Box width={iconWidth} />
          <Box width={"calc(100% - " + iconWidth + "px)"}>{subContent}</Box>
        </Box>
      )}
    </Box>
  );
};

export default CstmAccordion1;

const sxMain = {
  borderBottom: "1px solid",
  borderColor: "custom.main",
  py: 0.5,
  width: "100%",
};
