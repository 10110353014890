import { label } from "Constant/En";
import { RouteUrls } from "Constant/RouteUrls";
import { SubNavDataType } from "DataTypes/Layout";

export const incoming: SubNavDataType = {
  id: RouteUrls.incoming,
  label: label.incoming,
  link: `${RouteUrls.incoming}`,
};

export const global: SubNavDataType = {
  id: RouteUrls.global,
  label: label.globalTask,
  link: `${RouteUrls.global}`,
};

export const outgoing: SubNavDataType = {
  id: RouteUrls.outgoing,
  label: label.outgoing,
  link: `${RouteUrls.outgoing}`,
};

export const group: SubNavDataType = {
  id: RouteUrls.group,
  label: label.groupTask,
  link: `${RouteUrls.group}`,
};
