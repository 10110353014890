import { CommonStyle, ControlledTable } from "Components";
import { TableLayout } from "Layouts";
import { FC, useEffect, useState } from "react";
import { TableCol } from "./Data";
import { Box } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ProblemApi from "Service/Problem.api";
import { ConvertStringToNum } from "Utils/common";
import ProbFormatter from "Utils/Prob.Formatter";
import { IcdCodeType } from "DataTypes/Services/Problem.type";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";
import { useDispatch } from "react-redux";
import { setNavArr } from "Redux/Screen/Action";
import { ScreenInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const ICDCodes: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { navArr }: ScreenInitialStateType = useSelector(
    (state: any) => state?.screen
  );
  const codeId = searchParams.get("id");
  const [codeList, setCodeList] = useState<IcdCodeType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const link = `${RouteUrls.icdCodes}?id=${location?.state?.problemId}`;
    CommonFormatter.HandleNavArr({
      id: "problem-code",
      label: `${location?.state?.problemName} > ICD-10 Codes`,
      path: `/${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.prob}/${link}`,
      level: 2,
      link: RouteUrls.icdCodes,
      navType: "master",
    });
    if (codeId) getCodeList();
    else navigate(`../${RouteUrls.list}`);
    return () => {
      dispatch(setNavArr([...navArr.filter((el) => el.level < 2)]));
    };
  }, [codeId]);

  const getCodeList = async () => {
    const res: any = await ProblemApi.icdCodeList({
      id1: ConvertStringToNum(codeId),
    });
    if (res?.success) setCodeList(ProbFormatter.IcdCodes(res?.data));
    setLoading(false);
  };
  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={codeList}
      cellType="border"
    />
  );

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}>
      <Box height="4%"></Box>
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol)}
        height={"93%"}
        loading={loading}
      />
    </Box>
  );
};
export default ICDCodes;
