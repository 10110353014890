import {
  MyInfoType,
  UserActionReturnType,
  UserInitialStateType,
} from "DataTypes/Redux";
import {
  SET_MY_INFO,
  CLEAR_MY_INFO,
  SET_MY_PERMISSION,
  CLEAR_MY_PERMISSION,
  CLEAR_USER,
  UPDATE_RPM_ALERT_COUNT,
  UPDATE_SELECTED_ALERT,
} from "./type";

const MyInfoInitialState: MyInfoType = {
  id: null,
  firstname: "",
  lastname: "",
  roleId: null,
  roleName: "",
  practiceId: null,
  practiceName: "",
  practiceModelId: null,
  practiceModelName: "",
  isMasterPractice: false,
  designation: "",
  timezone: "",
  dateFormat: "",
  dateTimeFormat: "",
  dateTimeSecFormat: "",
  chartDateTimeSecFormat: "",
  utcDiff: null,
};

const INITIAL_STATE: UserInitialStateType = {
  myInfo: JSON.parse(JSON.stringify(MyInfoInitialState)),
  myPermission: {},
  alert: {
    count: 0,
    selected: null,
  },
};

const reducer = (state = INITIAL_STATE, action: UserActionReturnType) => {
  switch (action.type) {
    case SET_MY_INFO:
      return {
        ...state,
        myInfo: action.payload,
      };
    case CLEAR_MY_INFO:
      return {
        ...state,
        myInfo: MyInfoInitialState,
      };
    case SET_MY_PERMISSION:
      return {
        ...state,
        myPermission: action.payload,
      };
    case CLEAR_MY_PERMISSION:
      return {
        ...state,
        myPermission: {},
      };
    case UPDATE_RPM_ALERT_COUNT:
      return {
        ...state,
        alert: {
          ...state.alert,
          count: action.payload,
        },
      };
    case UPDATE_SELECTED_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          selected: action.payload,
        },
      };
    case CLEAR_USER:
      return {
        ...state,
        myInfo: MyInfoInitialState,
        myPermission: {},
        alert: {
          count: 0,
          selected: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;
