import { Box, Divider } from "@mui/material";
import { useEffect, FC, Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "Components";
import CstmBtn from "Components/CstmBtn";
import {
  DeviceTypeType,
  ThresholdType,
} from "DataTypes/Services/Settings.type";
import ThresholdsFormatter from "Utils/Thresholds.Formatter";
import { ConvertStringToNum } from "Utils/common";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import AlertHeads from "Pages/Home/HeadScreen/Settings/GlobalAlerts/AlertHeads";
import ProblemAlert from "Pages/Home/HeadScreen/Settings/GlobalAlerts/ProblemAlert";
import PatientApi from "Service/Patient.api";
import { PatientThresholdProps } from "DataTypes/PatientManagement.type";
import NoData from "Components/NoData";
import { useNavigate, useParams } from "react-router-dom";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const PatientThreshold: FC<PatientThresholdProps> = ({
  showSaveButton,
  isSavedCounter,
  patientId,
  filterData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { careplanId, revisionPlanId } = useParams();
  const [data, setData] = useState<ThresholdType[]>([]);
  const [loading, setLoading] = useState(true);
  const [deviceType, setDeviceType] = useState<DeviceTypeType[]>([]);
  const { selectedPrgm, rpmPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const userId = myInfo.id;
  const pgrmDetails = careplanId || revisionPlanId ? selectedPrgm : rpmPrgm;
  let teamMember =
    pgrmDetails.billableproviderId == userId ||
    pgrmDetails.caremanagerId == userId ||
    pgrmDetails.providerId == userId ||
    pgrmDetails.primaryccId == userId ||
    pgrmDetails.secondaryccId == userId;

  ////For task #13110 in PMS
  if (
    selectedPrgm.abbrevation == "RPM" &&
    pgrmDetails.billableproviderId == userId &&
    pgrmDetails.providerId != userId &&
    selectedPrgm.careplanRecurringId
  ) {
    teamMember = false;
  }
  const isView = !teamMember;

  useEffect(() => {
    ThresApiCall();
  }, [filterData?.deviceTypeId]);

  useEffect(() => {
    if (
      typeof isSavedCounter === "number" &&
      isSavedCounter > 0 &&
      data.length
    ) {
      HandleSave();
    }
  }, [isSavedCounter]);

  const filterDevices = (devices: ThresholdType[]): DeviceTypeType[] => {
    const filteredDevices: { [key: string]: DeviceTypeType } = {};

    return devices.reduce((type: DeviceTypeType[], item) => {
      if (item.deviceTypeId && !filteredDevices[item.deviceTypeId]) {
        filteredDevices[item?.deviceTypeId] = {
          deviceTypeId: item.deviceTypeId,
          deviceTypeDescription: item.deviceTypeDescription,
        };
        type.push(filteredDevices[item?.deviceTypeId]);
      }
      return type;
    }, []);
  };

  const ThresApiCall: () => void = async () => {
    setLoading(true);
    const payload = {
      ...(filterData?.deviceTypeId
        ? { deviceTypeId: [filterData?.deviceTypeId] }
        : { ...filterData }),
      ...(careplanId && { careplanId: careplanId }),
      ...(revisionPlanId && { careplanId: revisionPlanId }),
    };
    const res = await PatientApi.ThresholdSearch({
      id1: patientId,
      offset: 0,
      limit: 10000,
      data: payload,
    });
    if (res?.success) {
      const data = ThresholdsFormatter.Search(res?.data);
      setData(data);

      setDeviceType(filterDevices(data));
    } else setData([]);
    setLoading(false);
  };

  const ChangeThreshold: (val: ThresholdType) => void = (val) => {
    setData((prevData) =>
      prevData.map((el) =>
        el.deviceTypeId === "WEIGHT_SCALE"
          ? el.deviceTypeId === val.deviceTypeId &&
            el.problemId === val.problemId
            ? val
            : el
          : el.deviceTypeId === val.deviceTypeId &&
            el.uomId === val.uomId &&
            el.alertType === val.alertType
            ? val
            : el
      )
    );
  };

  const HandleSave: () => void = async () => {
    showSaveButton
      ? setLoading(true)
      : dispatch(startLoading({ loading: true, loadtext: "Loading" }));
    const payload = data.map((el) => {
      return {
        operatorId: el.operatorId,
        uomId: el.uomId,
        deviceTypeId: el.deviceTypeId,
        alertType: el.alertType,
        problemId: el.problemId,
        upperLimit: ConvertStringToNum(el.upperLimit),
        weightValue: ConvertStringToNum(el.weightValue),
        weightFluctuation: el.weightFluctuation,
        daysLimit: ConvertStringToNum(el.daysLimit),
        lowerLimit: ConvertStringToNum(el.lowerLimit),
        ...(careplanId && { careplanId: careplanId }),
        ...(revisionPlanId && { careplanId: revisionPlanId }),
      };
    });
    const payload1 = {
      data: payload,
      id1: patientId,
    };
    const res = await PatientApi.ThresholdUpdate(payload1);
    if (res?.success) dispatch(setAlertData(res?.alert));
    showSaveButton ? setLoading(false) : dispatch(stopLoading());
  };

  return (
    <Fragment>
      {deviceType &&
        deviceType.some((device) => device.deviceTypeId !== "WEIGHT_SCALE") && (
        <Box my={1}>
          <AlertHeads singleDevice={filterData?.deviceTypeId} />
        </Box>
      )}
      {loading ? (
        <Loader />
      ) : deviceType && deviceType.length < 1 ? (
        <NoData />
      ) : (
        <Box
          overflow="auto"
          {...(!filterData?.deviceTypeId && { height: "70%" })}
        >
          {deviceType &&
            deviceType
              .filter((filEl) =>
                data.find((findEl) => findEl.deviceTypeId == filEl.deviceTypeId)
              )
              .map((el, index) => {
                const filterArr = data.filter(
                  (filEl1) => filEl1.deviceTypeId == el.deviceTypeId
                );
                return (
                  <Fragment key={el.deviceTypeId}>
                    <ProblemAlert
                      data={filterArr}
                      deviceName={el.deviceTypeDescription}
                      handleChange={ChangeThreshold}
                      singleDevice={filterData?.deviceTypeId}
                      isView={isView}
                    />
                    {deviceType.length - 1 != index && (
                      <Divider sx={{ my: 0.5 }} />
                    )}
                  </Fragment>
                );
              })}
        </Box>
      )}
      {showSaveButton && data.length > 0 && (
        <Box
          sx={{
            "&>button": { ml: 1, width: "10%" },
            display: "flex",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          {teamMember && <CstmBtn label="Save" onClick={HandleSave} />}
          <CstmBtn
            label="Cancel"
            onClick={() => navigate(-1)}
            btnType="border"
          />
        </Box>
      )}
    </Fragment>
  );
};
export default PatientThreshold;
