import { FC } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ControlledCstmAccordProps } from "DataTypes/Common";
import ImgPath from "Constant/Imgs";

const ControlledAccord: FC<ControlledCstmAccordProps> = (props) => {
  const { open, handleClick, head, body, arrowColor, style, roundIcon } = props;
  return (
    <Accordion expanded={open} sx={{ ...sxAccordion, ...style }} elevation={0}>
      <AccordionSummary
        sx={{ ...sxSummary }}
        expandIcon={
          roundIcon ? (
            <ImgPath.CPDropIcon
              onClick={handleClick}
              sx={{ color: arrowColor, transform: "rotate(-90deg)" }}
            />
          ) : (
            <ImgPath.StartIcon
              onClick={handleClick}
              sx={{ ...(arrowColor && { color: arrowColor }) }}
            />
          )
        }
      >
        {head}
      </AccordionSummary>
      <AccordionDetails sx={{ ...sxDetails }}>{body}</AccordionDetails>
    </Accordion>
  );
};

export default ControlledAccord;

const sxAccordion = {
  my: "0px !important",
  "&:before": {
    display: "none",
  },
  "& .Mui-expanded": {
    minHeight: "0px !important",
  },
  "& .MuiAccordionSummary-root": {
    minHeight: "0px",
    py: 0.5,
  },
  borderBottom: "1px solid",
  borderBottomColor: "custom.main",
};

const sxSummary = {
  padding: 0,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    ml: 1,
    my: 0,
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    ml: 1,
    my: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  mr: 1,
  backgroundColor: "common.white",
};

const sxDetails = {
  pb: 1,
};
