import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from ".";

const list: (data: any) => any = (data) => {
  const request = {
    subUrl: "user/search",
    data,
    isAuth: true,
  };
  return post(request);
};

const read: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `user/${payload.id1}/read`,
    isAuth: true,
  };
  return post(request);
};
const patientTaskExport: (userId: string | number | undefined) => any = (
  userId
) => {
  const request = {
    subUrl: `patient/${userId}/task-patient/export`,
    excel: true,
  };
  return post(request);
};

const permissionList: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: "permission/search",
    data: { data: data.data },
    isAuth: true,
  };
  return post(request);
};

const create: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "user/create",
    isAuth: true,
    data: {
      data: payload?.data,
    },
  };
  return post(request);
};

const update: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `user/${payload?.id1}/update`,
    isAuth: true,
    data: {
      data: payload?.data,
    },
  };
  return post(request);
};

const changeStatus: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `user/${payload?.id1}/status/${payload?.status}/changed`,
    isAuth: true,
  };
  return post(request);
};

const TrainerList: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: "user/trainer/search",
    data: {
      data: data.data,
      criterion: {
        offset: data.offset,
        limit: data.limit,
      },
    },
    isAuth: true,
  };
  return post(request);
};

export default {
  list,
  read,
  permissionList,
  update,
  create,
  changeStatus,
  patientTaskExport,
  TrainerList,
};
