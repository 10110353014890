import { post } from ".";

const list: () => any = () => {
  const request = {
    subUrl: "role/search",
    data: {
      data: {
        isDeleted: null,
      },
    },
    isAuth: true,
  };
  return post(request);
};

export default { list };
