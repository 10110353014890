import {
  BiometricBillingType,
  ServiceBillingDetailsType,
  ServiceBillingType,
} from "DataTypes/Services/Patient.type";
import CommonFormatter from "Utils/Common.Formatter";
import DateTime from "Utils/DateTime";
import {
  CheckArr,
  CheckBoolean,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const BillingList: (res: any) => ServiceBillingType[] = (res) => {
  const CheckedArr = CheckArr(res);
  return CheckedArr.map((el, index) => {
    return {
      child: DetailList(el?.billingList),
      id: index + 1,
      isEligibleForCombinedBilling: CheckBoolean(
        el?.isEligibleForCombinedBilling
      ),
      dob: DateTime.ValidDate(el?.patientDob),
      name: CheckStringEmpty(el?.patientFullName),
      patientId: CheckStringId(el?.patientId),
    };
  });
};

const DetailList: (res: any) => ServiceBillingDetailsType[] = (res) => {
  const CheckedArr = CheckArr(res);
  return CheckedArr.map((el) => {
    const clinicTeamSec = CheckNumber(el?.clinicTeamSec);
    const cvTeamSec = CheckNumber(el?.cvTeamSec);
    const clinicProviderSec = CheckNumber(el?.clinicProviderSec);
    const cmFirstName = CheckStringEmpty(el?.caremanagerFirstName);
    const cmLastName = CheckStringEmpty(el?.caremanagerLastName);
    const cmDesig = CheckStringEmpty(el?.caremanager_designation);
    const cmName = `${cmFirstName} ${cmLastName}`.trim();
    const clinicalStaff = CommonFormatter.MergeNameDesign(cmName, cmDesig);
    const providerFirst = CheckStringEmpty(el?.providerFirstName);
    const providerLast = CheckStringEmpty(el?.providerLastName);
    const providerDesig = CheckStringEmpty(el?.provider_designation);
    const providerName = `${providerFirst} ${providerLast}`.trim();
    const provider = CommonFormatter.MergeNameDesign(
      providerName,
      providerDesig
    );
    const totalTimeSec = CheckNumber(el?.totalTimeSec);
    return {
      careteamclinictime: Math.floor((clinicTeamSec || 0) / 60),
      careteamtime: Math.floor((cvTeamSec || 0) / 60),
      clinicalStaff,
      codebilled: CheckStringEmpty(el?.cptCode),
      id: CheckNumber(el?.billingId),
      program: CheckStringEmpty(el?.programAbbreviation),
      provider,
      providerclinictime: Math.floor((clinicProviderSec || 0) / 60),
      scopeme: CheckBoolean(el?.scopeOfServiceMet),
      status: CheckStringEmpty(el?.status),
      totaltime: Math.floor((totalTimeSec || 0) / 60),
    };
  });
};

const BiometricBillingList: (res: any) => BiometricBillingType[] = (res) => {
  const CheckedArr = CheckArr(res);
  return CheckedArr.map((el, index) => {
    return {
      careplanId: CheckNumber(el?.careplanId),
      cptCode1Reading: CheckStringEmpty(el?.cptCode1Reading),
      cptCode16Reading: CheckStringEmpty(el?.cptCode16Reading),
      deviceName: CheckStringEmpty(el?.deviceNames),
      dob: DateTime.ValidDate(el?.patientDob),
      enddatetime: DateTime.ValidDate(el?.endDate),
      id: index,
      name: CheckStringEmpty(el?.patientFullName),
      patientId: CheckStringId(el?.patientId),
      periodName: CheckStringEmpty(el?.periodName),
      readingDays: CheckNumber(el?.noOfDaysOfReadings),
      startdatetime: DateTime.ValidDate(el?.startDate),
      status: CheckStringEmpty(el?.billingStatus),
      trainerName: CheckStringEmpty(el?.trainerName),
    };
  });
};

export default { BillingList, BiometricBillingList };
