import { Box, Typography } from "@mui/material";
import { CommonStyle, Loader } from "Components";
import { FC, Fragment, useEffect, useState } from "react";
import CstmBtn from "Components/CstmBtn";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import { HeadAlertBoxPropType } from "DataTypes/Common";
import SettingsApi from "Service/Settings.api";
import { RPMAlertType } from "DataTypes/Services/Settings.type";
import SettingsFormatter from "Utils/Settings.Formatter";
import { useDispatch } from "react-redux";
import { updateSelectedAlert } from "Redux/User/Action";

const AlertBox: FC<HeadAlertBoxPropType> = ({ changeDropState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [list, setList] = useState<RPMAlertType[]>([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (changeDropState) initialLoad();
    else setList([]);
  }, [changeDropState]);

  const initialLoad: () => void = async () => {
    const payload = {
      alertStatus: "UNADDRESSED",
      deliveryMethod: "PUSH",
      isAlertRead: false,
    };
    const res = await SettingsApi.RPMAlertSearch({
      data: payload,
      offset: 0,
      limit: 5,
    });
    if (res?.success) {
      setList(SettingsFormatter.RPMAlertList(res?.data));
    }
    setLoad(false);
  };

  const handleAlertClick: (data: RPMAlertType) => void = async (data) => {
    const payload = {
      id1: data.alertReceiverId,
    };
    const res = await SettingsApi.RPMMarkAsReadAlert(payload);
    if (res?.success) {
      dispatch(updateSelectedAlert(data.alertId));
      navigate(`${RouteUrls.hs}/${RouteUrls.alert}/${RouteUrls.read}`);
    }
  };

  return (
    <Fragment>
      {changeDropState && (
        <Box
          sx={{
            ...CommonStyle.sxWhiteBox,
            ...sxAlertBox,
            ...sxRight,
          }}
        >
          {load ? (
            <Loader />
          ) : (
            <Fragment>
              <Box sx={{ ...sxDropBox }}>
                {list.map((el) => {
                  return (
                    <Box key={el.alertId} onClick={() => handleAlertClick(el)}>
                      <Typography variant="subtitle1">
                        {el.alertMessage}
                      </Typography>
                      <Typography variant="subtitle2">
                        {el.detailedMessage}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box>
                <CstmBtn
                  label="See All"
                  onClick={() =>
                    navigate(
                      `${RouteUrls.hs}/${RouteUrls.alert}/${RouteUrls.unread}`
                    )
                  }
                  width={"100%"}
                />
              </Box>
            </Fragment>
          )}
        </Box>
      )}
    </Fragment>
  );
};
export default AlertBox;

const sxAlertBox = {
  // maxHeight: 300,
  width: "17em",
  position: "absolute",
  top: "100%",
  zIndex: 10,
  transition: "0.5s",
  padding: "8px",
};

const sxDropBox = {
  overflow: "auto",
  p: 1,
  "& >div": {
    cursor: "pointer",
    p: 0.5,
    borderRadius: 1.25,
    borderBottom: "1px solid",
    borderColor: "custom.main",
    ":hover": {
      backgroundColor: "custom.light",
    },
    "& >h6": {
      p: "0px !important",
      ":last-of-type": {
        mt: 1,
        fontWeight: "bold",
      },
    },
  },
};

const sxRight = {
  right: 0,
  "&:before": {
    content: "''",
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid #ee7404",
    transform: "rotate(0deg)",
    backgroundColor: "transparent",
    top: "-10px",
    right: "2px",
    position: "absolute",
  },
};
