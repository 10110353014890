import { Fragment, FC, useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Active from "./Active";
import Historical from "./Historical";
import { CommonStyle } from "Components";
import { SideModalProps } from "DataTypes/Modal";
import TabPanel from "Components/TabPanel";
import ImgPath from "Constant/Imgs";
import RedoxApi from "Service/Redox.api";
import RedoxFormatter from "Utils/Redox.Formatter";
import { MedSearchType } from "DataTypes/Services/Redox.type";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Meds: FC<SideModalProps> = ({ onClose }) => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [medsList, setMedsList] = useState<MedSearchType[]>([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    getMedsData();
  }, [tabValue]);

  const getMedsData = async () => {
    setLoading(true);
    const payload = { medicationsActive: tabValue == 0 ? "active" : "history" };
    const res: any = await RedoxApi.MedSearch({
      id1: patientInfo.id,
      data: payload,
    });
    if (res?.success) {
      const formatedData = RedoxFormatter.MedSearch(res?.data?.medications);
      setMedsList(formatedData);
    } else setMedsList([]);
    setLoading(false);
  };

  const Layer1 = (
    <Fragment>
      <Box sx={{ ...sxHeader, ...CommonStyle.sxRow }}>
        <Typography fontWeight="bold">Medications</Typography>
        <Box sx={{ ...CommonStyle.sxRoundIconsMainSm }} onClick={onClose}>
          <ImgPath.CloseIcon titleAccess="Close" />
        </Box>
      </Box>
      <Box sx={{ ...sxTab }}>
        <Tabs
          value={tabValue}
          onChange={(event, index) => {
            setTabValue(index);
          }}
          aria-label="basic tabs example"
          variant="fullWidth"
          indicatorColor="secondary"
        >
          <Tab label="Active" {...a11yProps(0)} />
          <Tab label="Historical" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0} style={{ ...sxTabPanel }}>
        <Active data={medsList} loading={loading} />
      </TabPanel>
      <TabPanel value={tabValue} index={1} style={{ ...sxTabPanel }}>
        <Historical data={medsList} loading={loading} />
      </TabPanel>
    </Fragment>
  );

  return (
    <Fragment>
      <Box width="40%">{Layer1}</Box>
    </Fragment>
  );
};
export default Meds;

const sxTab = {
  px: 2,
  "&>div": {
    borderBottom: 1,
    borderColor: "custom.main",
  },
};

const sxHeader = {
  justifyContent: "space-between",
  height: "10%",
  px: 2,
};

const sxTabPanel = {
  height: "75%",
};
