import { useState, useEffect, FC } from "react";
import { Box } from "@mui/material";
import DeviceProgramBox from "./DeviceProgramBox";
import DeviceProblemBox from "./DeviceProblemBox";
import DeviceApi from "Service/Device.api";
import DeviceTypeFormatter from "Utils/DeviceType.Formatter";
import { DeviceTypeType } from "DataTypes/Services/Settings.type";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const DeviceProblem: FC = () => {
  const [loading, setLoading] = useState(true);
  const [deviceList, setDevicelist] = useState<DeviceTypeType[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<DeviceTypeType | null>(
    null
  );

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "device-prbm-map",
      label: "Device Problem Mapping",
      path: `/${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.deviceProbMap}`,
      level: 1,
      link: RouteUrls.deviceProbMap,
      navType: "master",
    });
    getDeviceTypeList();
  }, []);

  const getDeviceTypeList: () => void = async () => {
    setLoading(true);
    const res = await DeviceApi.deviceTypelist({
      limit: 25,
      offset: 0,
    });
    if (res?.success) {
      const list = DeviceTypeFormatter.DeviceTypeListRes(res?.data);
      setDevicelist(list);
      list.length > 0 && setSelectedDevice(list[0]);
    }
    setLoading(false);
  };

  return (
    <Box display="flex" flexDirection="row" height="100%">
      <Box width="30%" sx={{ mr: 1.5 }}>
        <DeviceProgramBox
          deviceList={deviceList}
          selectedDevice={selectedDevice}
          handleChangeDevice={(el) => setSelectedDevice(el)}
          loading={loading}
        />
      </Box>
      <Box width="70%" overflow="auto">
        {selectedDevice && selectedDevice?.deviceTypeId && (
          <DeviceProblemBox selectedDevice={selectedDevice} />
        )}
      </Box>
    </Box>
  );
};

export default DeviceProblem;
