import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CommonStyle, SubNavBar } from "Components";
import { Addressed, Read, Unread } from "./Data";
import { Outlet, useLocation } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import { SubNavDataType } from "DataTypes/Layout";
import { useSelector } from "react-redux";
import CommonFormatter from "Utils/Common.Formatter";

const RPMAlerts: FC = () => {
  const { myPermission }: any = useSelector((state: any) => state?.user);
  const location = useLocation();
  const pathArr = location.pathname.split("/");
  const currentPath = pathArr[pathArr.length - 1];
  const [arr, setArr] = useState<SubNavDataType[]>([]);

  useEffect(() => {
    setArr(AlertsArr(myPermission?.dashlinks));
  }, []);

  useEffect(() => {
    if (arr.length) {
      const obj = arr.find((el) => el.link == currentPath);
      CommonFormatter.HandleNavArr({
        id: "rpm-alerts",
        label: "RPM Alerts",
        path: `/${RouteUrls.hs}/${RouteUrls.alert}/${obj?.link}`,
        level: 1,
        link: obj?.link || "",
        navType: "my-items",
      });
    }
  }, [currentPath, arr]);

  return (
    <Box height="91%">
      <SubNavBar data={AlertsArr(myPermission?.dashlinks)} />
      <Box
        height="92%"
        sx={{ ...CommonStyle.sxWhiteBoxWithNavbar, px: 2, overflow: "auto" }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default RPMAlerts;

export const AlertsArr = (permission: any) => {
  const Arr: SubNavDataType[] = [
    ...(permission?.unreadalert ? [Unread] : []),
    ...(permission?.readalert ? [Read] : []),
    ...(permission?.addressedalert ? [Addressed] : []),
  ];
  return Arr;
};
