import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { HeaderInitialStateType } from "DataTypes/Redux";
import { useDispatch, useSelector } from "react-redux";
import PracticeApi from "Service/Practice.api";
import DataFormatter from "Utils/DataFormatter";
import { FormDataType } from "DataTypes/Form";
import { setAlertData } from "Redux/Screen/Action";
import { CommonStyle, ControlledTable, Filter2, Modal } from "Components";
import { FetchListProps, Filter2DataProps } from "DataTypes/Common";
import { actionArr, FilterArr, FormDataArr, TableCol } from "./Data";
import CstmBtn from "Components/CstmBtn";
import OrganizationFormatter from "Utils/Organization.Formatter";
import { ControlledBoxSizeSwitch, commonFormSwitch } from "Utils/SwitchCases";
import {
  CheckStringEmpty,
  CreateFilter2Payload,
  HandleFormChange,
} from "Utils/common";
import { ModalLayout, TableLayout } from "Layouts";
import { useUploadForm } from "Hooks/useUploadFile";
import { patientImportSearchType } from "DataTypes/Services/Practice.type";
import { RouteUrls } from "Constant/RouteUrls";
import SettingsApi from "Service/Settings.api";
import CommonFormatter from "Utils/Common.Formatter";

const PatientImport = () => {
  const dispatch = useDispatch();
  const { organization }: HeaderInitialStateType = useSelector(
    (state: any) => state?.header
  );
  const [formArr, setFormArr] = useState<FormDataType[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState<number>(10);
  const [rows, setRows] = useState<patientImportSearchType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [headHeight, setHeadHeight] = useState(20);
  const [openErrorList, setOpenErrorList] = useState(false);
  const [errorList, setErrorList] = useState<string[] | null>(null);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const [hideImportFileButton, setImportFileButton] = useState(false);
  const offsetHead = document?.getElementById("head")?.offsetHeight;
  const tableHeight = `calc(99% - ${filterHeight + headHeight}px)`;
  const { uploadForm, progress, setProgress, inProgress } = useUploadForm(
    `${process.env.REACT_APP_BASE_REDOX_URL}/redox/patient/practicelocation/${formArr[1]?.dropValue}/upload`
  );

  useEffect(() => {
    setHeadHeight(offsetHead || 0);
  }, [offsetHead]);

  useEffect(() => {
    setData();
    CommonFormatter.HandleNavArr({
      id: "upload patient",
      label: "Patient Import",
      path: `/${RouteUrls.hs}/${RouteUrls.organization}/${RouteUrls.patientUpload}`,
      level: 2,
      link: RouteUrls.patientUpload,
      navType: "org",
    });
  }, []);

  useEffect(() => {
    handleSetData({ page: 1, limit });
  }, [filterData]);

  const handleSetData: (props: FetchListProps) => void = async (props) => {
    const { page: currentPage, limit: currentLimit } = props;
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      configId: "IMPORT_PATIENT",
      ...(newFilterPayload || {}),
      practiceId: organization.id,
    };
    setLoading(true);
    const res = await PracticeApi.patientImportSearch({
      data: payload,
      limit: currentLimit,
      offset: (currentPage - 1) * currentLimit,
    });
    if (res?.success) {
      setRows(OrganizationFormatter.patientImportSearch(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res?.criterion?.total);
      setLimit(res?.criterion?.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  useEffect(() => {
    setProgress(0);
  }, [selectedFile]);

  const setData = async (): Promise<void> => {
    const payload = {
      id1: "exim",
      data: "ui.download.uploaded.file.show.button",
    };
    const res = await SettingsApi.SystemSettings(payload);
    if (res?.success) {
      if (res?.data) {
        const systemProperty = CheckStringEmpty(res?.data?.systemPropertyValue);
        setImportFileButton(systemProperty == "N" ? false : true);
      }
    }
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(FormDataArr));
    const practiceRes = await PracticeApi.read({
      id1: organization.id || null,
    });
    if (practiceRes?.success) {
      const verifiedRes = OrganizationFormatter.VerifyReadResponse(
        practiceRes?.data
      );
      const formatePracticeLocation = DataFormatter.FormatDropdownData(
        verifiedRes.practiceLocations,
        "id",
        "location1",
        "id"
      );
      newFormData[0].value = verifiedRes.name;
      newFormData[1].dropArr = formatePracticeLocation;
      setFormArr(newFormData);
    }
  };

  const hanldeImportFile = async (): Promise<void> => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const res = await uploadForm({
        data: formData,
      });
      if (res?.success) {
        res?.alert && dispatch(setAlertData(res.alert));
      }
      handleSetData({ page: 1, limit });
    }
  };

  const handleView = (data: patientImportSearchType): void => {
    if (data.errorList) {
      const cleanedString = data.errorList
        .replace("[", "")
        .replace("]", "")
        .trim();
      const errorListData = cleanedString.split(",").map((item) => item.trim());
      setErrorList(errorListData);
      setOpenErrorList(true);
    }
  };

  const handleImportFile = async (
    data: patientImportSearchType
  ): Promise<void> => {
    if (data.importFilePath) {
      const payload = {
        id1: data.id,
        data: {
          filePath: data.importFilePath,
        },
      };
      const res: any = await PracticeApi.patientDownload(payload);
      if (res?.success) {
        const binaryResponse = res?.data;
        const blob = new Blob([binaryResponse], {
          type: "application/ms-excel",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = data.fileName;
        link.click();
      }
    }
  };

  const handleFailedFile = async (
    data: patientImportSearchType
  ): Promise<void> => {
    if (data.failedFilePath) {
      const payload = {
        id1: data.id,
        data: {
          filePath: data.failedFilePath,
        },
      };
      const res: any = await PracticeApi.patientDownload(payload);
      if (res?.success) {
        const binaryResponse = res?.data;
        const blob = new Blob([binaryResponse], {
          type: "application/csv",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = data.failedFileName;
        link.click();
      }
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={rows}
      tableAction={(rowData) =>
        actionArr(
          () => handleView(rowData),
          () => handleImportFile(rowData),
          () => handleFailedFile(rowData),
          hideImportFileButton
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Box height="91%" sx={{ ...CommonStyle.sxWhiteBox, overflow: "auto" }}>
      <ModalLayout
        modwidth="35%"
        heading={"Upload File"}
        content={
          <Modal.UploadFileModal
            changeFile={(data) => setSelectedFile(data)}
            fileName={selectedFile?.name || ""}
            progress={progress}
            inProgress={inProgress}
          />
        }
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          !inProgress && setSelectedFile(null);
        }}
        buttonArr={[
          {
            id: "1",
            label: inProgress
              ? "Uploading"
              : progress == 100
                ? "Uploaded"
                : "Upload",
            clickHandler: hanldeImportFile,
            disable:
              inProgress || progress == 100 || !selectedFile ? true : false,
          },
          {
            id: "2",
            label: "Close",
            clickHandler: () => {
              setOpenModal(false);
              !inProgress && setSelectedFile(null);
            },
            type: "border",
          },
        ]}
      />
      <ModalLayout
        modwidth="50%"
        heading={"Error List"}
        content={
          <Box textAlign={"left"}>
            {errorList &&
              errorList.length > 0 &&
              errorList.map((err, index) => (
                <Typography key={index}>
                  <b>•</b>
                  {err}
                </Typography>
              ))}
          </Box>
        }
        open={openErrorList}
        onClose={() => setOpenErrorList(false)}
      />
      <Box sx={{ p: 2 }}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"flex-end"}
          sx={{ mb: 1 }}
          id="head"
        >
          <Box width="50%" mr={2}>
            <Grid container rowSpacing={1} columnSpacing={2}>
              {formArr && formArr.length > 0
                ? formArr.map((el: FormDataType) => {
                  return (
                    <Grid
                      key={el.id}
                      item
                      {...ControlledBoxSizeSwitch(el.boxSize)}
                    >
                      {commonFormSwitch({
                        formObj: el,
                        onChange: (props) => {
                          if (props.id == "location") setSelectedFile(null);
                          setFormArr(HandleFormChange(props, formArr));
                        },
                      })}
                    </Grid>
                  );
                })
                : null}
            </Grid>
          </Box>
          <CstmBtn
            label="Upload Patient Data"
            onClick={() => setOpenModal(true)}
            disable={!formArr[1]?.dropValue}
          />
        </Box>
        <Box>
          <Filter2
            filterDataArr={filterData}
            handleFilterClick={(data) => setFilterData(data)}
            changeSearchText={(val) => setSearchText(val)}
            setFilterHeight={(val) => setFilterHeight(val)}
          />
          <TableLayout
            tblBody={TblBodyJSX}
            loading={loading}
            tblHead={ControlledTable.TblHead(TableCol)}
            height={tableHeight}
            Pagination={
              <ControlledTable.Pagination
                mainPage={page}
                limit={limit}
                total={totalRecord}
                handleClick={(currentPage, currentLimit) =>
                  handleSetData({ page: currentPage, limit: currentLimit })
                }
                tableStyle={true}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
export default PatientImport;
