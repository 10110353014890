import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ControlledTable, CommonStyle } from "Components";
import { TableLayout } from "Layouts";
import { TableCol } from "./Data";
import { FetchListProps } from "DataTypes/Common";
import DeviceApi from "Service/Device.api";
import DeviceTypeFormatter from "Utils/DeviceType.Formatter";
import { DeviceListType } from "DataTypes/Services/Device.type";
import { objectValues } from "DataTypes/Services/Index.type";
import { useDispatch } from "react-redux";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const DeviceMaster: FC = () => {
  const [deviceList, setDeviceList] = useState<DeviceListType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pages, setPages] = useState<objectValues>({
    page: 1,
    total: 1,
    limit: 25,
  });

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "device-list",
      label: "Device Master",
      path: `/${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.device}`,
      level: 1,
      link: RouteUrls.device,
      navType: "master",
    });
    getDeviceList({
      page: pages.page,
      limit: pages.limit,
    });
  }, []);

  const getDeviceList: (props: FetchListProps) => void = async (props) => {
    const { page, limit } = props;
    const res = await DeviceApi.deviceList({
      offset: (page - 1) * limit,
      limit: limit,
    });
    if (res?.success) {
      const list = DeviceTypeFormatter.DeviceNameListRes(res?.data);
      setDeviceList(list);
      setPages({
        page: res.criterion?.page,
        limit: res.criterion?.limit,
        total: res.criterion?.total,
      });
    }
    setLoading(false);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={deviceList}
    />
  );

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox, px: 2 }}>
      <Box
        height={"10%"}
        justifyContent={"space-between"}
        sx={{ ...CommonStyle.sxRow }}
      >
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {"Device Master"}
        </Typography>
      </Box>
      <Box width="70%" height="88%">
        <TableLayout
          tblBody={TblBodyJSX}
          tblHead={ControlledTable.TblHead(TableCol)}
          loading={loading}
          height={"100%"}
          Pagination={
            <ControlledTable.Pagination
              mainPage={pages.page}
              limit={pages.limit}
              total={pages.total}
              handleClick={(page, limit) => {
                getDeviceList({ page: page, limit: limit });
              }}
              tableStyle={true}
            />
          }
        />
      </Box>
    </Box>
  );
};
export default DeviceMaster;
