import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CommonStyle } from "Components";
import CstmBtn from "Components/CstmBtn";
import { label } from "Constant/En";
import ImgPath from "Constant/Imgs";
import ColorData1 from "CstmTheme/ColorData";
import { actionArrType, columnType } from "DataTypes/Table";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { CheckStringEmpty } from "Utils/common";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";

const handlePatientSwitch: (rowData: any, highlight: string) => JSX.Element = (
  rowData,
  highlight
) => {
  const name = CheckStringEmpty(rowData?.name);
  const dob = rowData?.dob;
  const contact1 = PatientFormatter.MobileNumber(
    CheckStringEmpty(rowData?.contact1)
  );
  const contact2 = PatientFormatter.MobileNumber(
    CheckStringEmpty(rowData?.contact2)
  );
  return (
    <Box
      sx={{
        "&>div": {
          display: "flex",
          flexDirection: "row",
        },
      }}
    >
      <Typography variant="subtitle1" sx={{ mr: 1.25 }}>
        {name ? getHighlightedText(name, highlight) : label.naText}
      </Typography>
      <Box>
        <ImgPath.CalendarIcon
          color="secondary"
          sx={{ ...CommonStyle.smallIconFont }}
        />
        <Typography variant="subtitle2">
          {dob
            ? getHighlightedText(DateTime.ToLocalDate(dob), highlight)
            : label.naText}
        </Typography>
      </Box>
      <Box sx={{ mt: 0.5 }}>
        <ImgPath.CallIcon
          sx={{ ...CommonStyle.smallIconFont }}
          color="secondary"
          fontSize="small"
        />
        <Typography variant="subtitle2">
          {contact1 ? getHighlightedText(contact1, highlight) : label.naText}
        </Typography>
      </Box>
      <Box sx={{ mt: 0.5 }}>
        <ImgPath.SmartphoneIcon
          color="secondary"
          sx={{ ...CommonStyle.smallIconFont }}
        />
        <Typography variant="subtitle2">
          {contact2 ? getHighlightedText(contact2, highlight) : label.naText}
        </Typography>
      </Box>
    </Box>
  );
};

const handleSepDateTime: (rowData: any) => JSX.Element = (rowData) => {
  const datetime = DateTime.UTCToLocalDateTime(rowData?.datetime).split(" ");
  return datetime.length > 2 ? (
    <Box textAlign={"center"}>
      <Typography variant="subtitle1">{datetime[0]}</Typography>
      <Typography variant="subtitle1">
        {datetime[1]} {datetime[2]}
      </Typography>
    </Box>
  ) : (
    <Typography variant="subtitle1">{label.naText}</Typography>
  );
};

const handlePracticeInfoSwitch: (
  rowData: any,
  highlight: string
) => JSX.Element = (rowData, highlight) => {
  const practice = CheckStringEmpty(rowData?.practice);
  const location = CheckStringEmpty(rowData?.location);
  const timezone = CheckStringEmpty(rowData?.timezone);
  return (
    <Box
      sx={{
        "&>div": {
          display: "flex",
          flexDirection: "row",
        },
      }}
    >
      <Typography variant="subtitle1">
        {practice ? getHighlightedText(practice, highlight) : label.naText}
      </Typography>
      <Box>
        <ImgPath.LocationIcon
          color="secondary"
          sx={{ ...CommonStyle.smallIconFont }}
        />
        <Typography variant="subtitle1" sx={{ ml: 0.5 }}>
          {location ? getHighlightedText(location, highlight) : label.naText}
        </Typography>
      </Box>
      <Box sx={{ mt: 0.5 }}>
        <ImgPath.AddTimeLogIcon
          sx={{ ...CommonStyle.smallIconFont }}
          color="secondary"
          fontSize="small"
        />
        <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
          {timezone ? getHighlightedText(timezone, highlight) : label.naText}
        </Typography>
      </Box>
    </Box>
  );
};

const handleActionSwitch: (
  rowData: any,
  actionData: actionArrType[]
) => JSX.Element = (rowData: any, actionData: actionArrType[]) => (
  <Box sx={{ ...CommonStyle.sxRow }}>
    {actionData &&
      Array.isArray(actionData) &&
      actionData.map((act: actionArrType) => {
        return act.id == "Enabled" ? (
          <Box
            key={act.id}
            onClick={() =>
              act?.onClick && !act?.disable
                ? act?.onClick(rowData)
                : console.log("")
            }
          >
            {act.icon}
          </Box>
        ) : (
          <IconButton
            title={act.title ? act.title : "Img"}
            key={act.id}
            // onClick={() => act.onClick(rowData)}
            {...(!act.disable && {
              onClick: () =>
                act?.onClick ? act?.onClick(rowData) : console.log(""),
            })}
            sx={{
              ...(act.disable
                ? CommonStyle.sxRoundIconsDisabledMd
                : CommonStyle.sxRoundIconsMainMd),

              mr: 0.5,
              "& >svg": { width: act.width },
            }}
          >
            {act.icon}
          </IconButton>
        );
      })}
  </Box>
);

const handleExclaimationSwitch: (rowData: any) => JSX.Element = (
  rowData: any
) => (
  <Fragment>
    {rowData?.status !== "Completed" && (
      <ImgPath.InfoOutlined
        titleAccess={rowData.priority}
        color={rowData.priority == "Normal" ? "warning" : "error"}
        height="20px"
        width="20px"
        sx={{ transform: "rotate(180deg)" }}
      />
    )}
  </Fragment>
);

const handleVerArrSwitch: (value: any, highlight: string) => JSX.Element = (
  value,
  highlight
) => (
  <Fragment>
    {Array.isArray(value) ? (
      value.map((val: any) => {
        const newVal = CheckStringEmpty(val);
        return (
          <Typography variant="subtitle1" key={newVal} fontWeight="bold">
            • {getHighlightedText(CheckStringEmpty(newVal), highlight)}
          </Typography>
        );
      })
    ) : (
      <Typography variant="subtitle1">{label.naText}</Typography>
    )}
  </Fragment>
);

const handleLongTextSwitch: (
  value: string,
  highlight: string
) => JSX.Element = (value, highlight) => (
  <Typography
    variant="subtitle1"
    style={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "10em",
      textOverflow: "ellipsis",
    }}
  >
    {getHighlightedText(value, highlight)}
  </Typography>
);

const handleCopyLongTextSwitch: (
  value: string,
  highlight: string
) => JSX.Element = (value, highlight) => {
  let copy = false;
  return (
    <Typography
      variant="subtitle1"
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "10em",
        textOverflow: "ellipsis",
      }}
    >
      <IconButton
        title="Copy"
        onClick={() => {
          navigator.clipboard.writeText(value).then(
            () => {
              copy = true;
              setTimeout(() => (copy = false), 2000);
            },
            (err) => {
              console.error("Failed to copy text: ", err);
            }
          );
        }}
      >
        {!copy ? (
          <ImgPath.ContentCopyIcon color="primary" />
        ) : (
          <ImgPath.CheckIcon color="primary" />
        )}
      </IconButton>
      <Tooltip title={value} arrow>
        {getHighlightedText(value, highlight)}
      </Tooltip>
    </Typography>
  );
};

const handleQualityNotes: (value: string, highlight: string) => JSX.Element = (
  value,
  highlight
) => (
  <Typography
    variant="subtitle1"
    style={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "25em",
      textOverflow: "ellipsis",
    }}
  >
    {getHighlightedText(value, highlight)}
  </Typography>
);

const handleCallLogNote: (value: string, highlight: string) => JSX.Element = (
  value,
  highlight
) => (
  <Typography
    variant="subtitle1"
    style={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "28em",
      textOverflow: "ellipsis",
    }}
  >
    {getHighlightedText(value, highlight)}
  </Typography>
);

const handleBtnSwitch: (colData: columnType, rowData: any) => JSX.Element = (
  colData: columnType,
  rowData: any
) => (
  <CstmBtn
    label={colData?.btnLabel || ""}
    onClick={() =>
      colData?.rowClick
        ? colData?.rowClick({ rowData, value: "" })
        : console.log("test")
    }
    width="100%"
  />
);

const handleGoalsSwitch: (value: any) => JSX.Element = (value) =>
  value?.length > 0 ? (
    value?.map((el: any) => {
      return (
        <Box key={el?.id}>
          {el?.options?.length > 0 &&
            el?.options?.map((el1: any) => {
              return (
                <Typography key={el1?.id} variant="subtitle1">
                  {`• ${el1?.label}`}
                </Typography>
              );
            })}
          {el?.freeTextAnswer && el?.freeTextAnswer?.length > 0 && (
            <Typography variant="subtitle1">
              {`• ${el?.freeTextAnswer}`}
            </Typography>
          )}
          {el?.comment && el?.comment?.length > 0 && (
            <div
              style={{ fontSize: "0.8rem", color: ColorData1.primary.main }}
              dangerouslySetInnerHTML={{
                __html: typeof el?.comment == "string" ? el?.comment : "",
              }}
            />
          )}
        </Box>
      );
    })
  ) : (
    <Typography variant="subtitle1">{label.naText}</Typography>
  );

const handleInterSwitch: (value: any) => JSX.Element = (value) =>
  value?.length > 0 ? (
    value?.map((el: any) => {
      return (
        <Box key={el?.id}>
          {el?.options?.length > 0 &&
            el?.options?.map((el1: any) => {
              return (
                <Typography key={el1?.id} variant="subtitle1">
                  {`• ${el1?.label}`}
                </Typography>
              );
            })}
          {el?.freeTextAnswer && el?.freeTextAnswer?.length > 0 && (
            <Typography variant="subtitle1">
              <pre
                style={{ fontFamily: "inherit", margin: 0 }}
              >{`• ${el?.freeTextAnswer}`}</pre>
            </Typography>
          )}
          {/* {el?.comment && el?.comment?.length > 0 && (
            <Typography variant="subtitle1">
              <pre style={{ fontFamily: "inherit", margin: 0 }}>
                • Comment: {el?.comment}
              </pre>
            </Typography>
          )} */}
          {el?.comment && el?.comment?.length > 0 && (
            <div
              style={{ fontSize: "0.8rem", color: ColorData1.primary.main }}
              dangerouslySetInnerHTML={{
                __html: typeof el?.comment == "string" ? el?.comment : "",
              }}
            />
          )}
        </Box>
      );
    })
  ) : (
    <Typography variant="subtitle1">{label.naText}</Typography>
  );

//Separate switch case for tcm question in summary screen
const handleTCMQuesSwitch: (value: any) => JSX.Element = (value) =>
  value?.length > 0 ? (
    value?.map((el: any) => {
      return (
        <Fragment key={el?.id}>
          <Typography variant="subtitle1" fontWeight="bold">
            {el?.label}
          </Typography>
          {el?.options?.length > 0 &&
            el?.options?.map((el1: any) => {
              return (
                <Typography key={el1?.id} variant="subtitle1">
                  {`• ${el1?.label}`}
                </Typography>
              );
            })}
          {el?.freeTextAnswer && el?.freeTextAnswer?.length > 0 && (
            <Typography variant="subtitle1">
              {`• ${el?.freeTextAnswer}`}
            </Typography>
          )}
        </Fragment>
      );
    })
  ) : (
    <Typography variant="subtitle1">{label.naText}</Typography>
  );

const handleReQuesSwitch: (rowData: any) => JSX.Element = (rowData) => {
  return (
    <Fragment key={rowData?.id}>
      <Typography variant="subtitle1" fontWeight="bold">
        {rowData?.label}
      </Typography>
      {rowData?.options?.length > 0 &&
        rowData?.options?.map((el1: any) => {
          return (
            <Typography key={el1?.id} variant="subtitle1">
              {`• ${el1?.label}`}
            </Typography>
          );
        })}
      {rowData?.freeTextAnswer && rowData?.freeTextAnswer?.length > 0 && (
        <Typography variant="subtitle1">{`• ${rowData?.freeTextAnswer}`}</Typography>
      )}
    </Fragment>
  );
};

const getHighlightedText: (text: string, highlight: string) => JSX.Element = (
  text,
  highlight
) => {
  const escapeRegExp = (str = "") =>
    str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
  return (
    <Fragment>
      {text
        .split(new RegExp(`(${escapeRegExp(highlight)})`, "gi"))
        .map((part, index) => (
          <Fragment key={index}>
            {part.toLowerCase() === highlight.toLowerCase() ? (
              <b style={{ backgroundColor: "#EE7404" }}>{part}</b>
            ) : (
              part
            )}
          </Fragment>
        ))}
    </Fragment>
  );
};

const handleReadMoreSwitch: (
  value: string,
  colData: columnType,
  rowData: any
) => JSX.Element = (value, colData, rowData) => {
  const readMoreLength = colData.readMoreLength || 50;
  if (value.length > readMoreLength && colData?.checkReadMore) {
    const check = colData?.checkReadMore(rowData?.id) || false;
    return (
      <Fragment>
        <Typography variant="subtitle1">
          {!check ? value + " " : value.slice(0, readMoreLength + 10) + " "}
          <Link
            to={""}
            onClick={() =>
              colData?.handleReadMore && colData?.handleReadMore(rowData?.id)
            }
          >
            {!check ? "Read Less" : "Read More"}
          </Link>
        </Typography>
      </Fragment>
    );
  } else {
    return <Typography variant="subtitle1">{value}</Typography>;
  }
};

const handleMobileNumber: (val: any, highlight: string) => JSX.Element = (
  val,
  highlight
) => {
  const mobile = PatientFormatter.MobileNumber(CheckStringEmpty(val));
  return (
    <Typography variant="subtitle1">
      {mobile ? getHighlightedText(mobile, highlight) : label.naText}
    </Typography>
  );
};

const RowSwitchCase: (
  colData: columnType,
  rowData: any,
  value: any,
  actionData?: actionArrType[],
  searchText?: string
) => JSX.Element = (colData, rowData, value, actionData, searchText) => {
  const highlight = searchText || "";
  const newVal = String(value).trim();
  const newVal1 = newVal.length > 0 ? newVal : label.naText;
  const newVal2 = newVal.length > 0 ? newVal.toUpperCase() : label.naText;
  const newVal3 = Array.isArray(value) ? value.join(", ") : label.naText;
  switch (colData.rowType) {
    case "text":
      return (
        <Typography variant="subtitle1">
          {newVal1 ? getHighlightedText(newVal1, highlight) : label.naText}
        </Typography>
      );
    case "none":
      return <Typography variant="subtitle1" />;
    case "boldText":
      return (
        <Typography variant="subtitle1" fontWeight="bold">
          {getHighlightedText(newVal1, highlight)}
        </Typography>
      );
    case "mobile-no":
      return handleMobileNumber(newVal, highlight);
    case "read-more":
      return handleReadMoreSwitch(newVal1, colData, rowData);
    case "all-capital":
      return (
        <Typography variant="subtitle1">
          {getHighlightedText(newVal2, highlight)}
        </Typography>
      );
    case "longText":
      return handleLongTextSwitch(newVal1, highlight);
    case "copyLongText":
      return handleCopyLongTextSwitch(newVal1, highlight);
    case "calllognotes":
      return handleCallLogNote(newVal1, highlight);
    case "qualitynotes":
      return handleQualityNotes(newVal1, highlight);
    case "profilepic":
      return (
        <Box sx={{ width: 70, height: 70, ...CommonStyle.sxRowCenter }}>
          <Avatar src={value} alt="" sx={{ height: "100%", width: "100%" }} />
        </Box>
      );
    case "patientinfo":
      return handlePatientSwitch(rowData, highlight);
    case "sep-datetime":
      return handleSepDateTime(rowData);
    case "dateTime":
      return (
        <Typography variant="subtitle1">
          {newVal ? DateTime.UTCToLocalDateTime(newVal) : label.naText}
        </Typography>
      );
    case "dateTimeSec":
      return (
        <Typography variant="subtitle1">
          {newVal ? DateTime.UTCToLocalDateTimeSec(newVal) : label.naText}
        </Typography>
      );
    case "date":
      return (
        <Typography variant="subtitle1">
          {newVal ? DateTime.ToLocalDate(newVal) : label.naText}
        </Typography>
      );
    case "local-date":
      return (
        <Typography variant="subtitle1">
          {newVal ? DateTime.ToLocalDate(newVal) : label.naText}
        </Typography>
      );
    case "practice-info":
      return handlePracticeInfoSwitch(rowData, highlight);
    case "horizontalArr":
      return (
        <Typography variant="subtitle1">
          {getHighlightedText(newVal3, highlight)}
        </Typography>
      );
    case "billingPrgm":
      return (
        <Typography variant="subtitle1" display="flex" alignItems="center">
          {newVal1 ? getHighlightedText(newVal1, highlight) : label.naText}
          {rowData?.isEligibleForCombinedBilling && (
            <ImgPath.ArrowCircleUpIcon
              sx={{ fontSize: "1.5rem", ml: 1 }}
              color="success"
            />
          )}
        </Typography>
      );
    case "verticalArr":
      return handleVerArrSwitch(value, highlight);
    case "exclaimation":
      return handleExclaimationSwitch(rowData);
    case "goals":
      return handleGoalsSwitch(value);
    case "tcm-ques":
      return handleTCMQuesSwitch(value);
    case "re-plan":
      return handleReQuesSwitch(rowData);
    case "interventions":
      return handleInterSwitch(value);
    case "button":
      return handleBtnSwitch(colData, rowData);
    case "staffIcon":
      return (
        <IconButton
          title="View Staff"
          onClick={() =>
            colData.rowClick
              ? colData.rowClick({ rowData, value })
              : console.log("rowData", rowData)
          }
        >
          <ImgPath.GroupsOutlined color="primary" />
        </IconButton>
      );
    case "status":
      return (
        <Typography
          variant="subtitle1"
          sx={{ "& a": { color: "primary.main" } }}
          onClick={() =>
            colData.rowClick
              ? colData.rowClick({ rowData, value })
              : console.log("rowData", rowData)
          }
        >
          <Link
            to={colData?.path}
            state={{ value: value, path: "sidebarTask" }}
          >
            {value}
          </Link>
        </Typography>
      );
    case "checkbox":
      return colData.nonEditable ? (
        <Checkbox
          sx={{
            p: 0,
            "&.Mui-disabled": {
              color: value ? "#EE7404" : "rgba(0, 0, 0, 0.6)", // Color for the disabled state
            },
          }}
          size="small"
          color="secondary"
          checked={value}
          disabled={true}
        />
      ) : (
        <Checkbox
          sx={{ p: 0 }}
          size="small"
          color="secondary"
          checked={colData.isRowChecked}
          onClick={() =>
            colData?.rowClick
              ? colData?.rowClick({ rowData, value: rowData?.id })
              : console.log("test")
          }
        />
      );
    case "action":
      return handleActionSwitch(rowData, actionData || []);
    case "full-text":
      return (
        <Typography variant="subtitle1" sx={{ wordBreak: "break-all" }}>
          {getHighlightedText(newVal, highlight)}
        </Typography>
      );
    default:
      return (
        <Typography variant="subtitle1">
          {getHighlightedText(newVal1, highlight)}
        </Typography>
      );
  }
};

export default RowSwitchCase;
