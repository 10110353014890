import { Theme, createTheme, responsiveFontSizes } from "@mui/material/styles";
import ColorData from "./ColorData";

declare module "@mui/material/styles" {
  interface Palette {
    active: Palette["primary"];
    custom: Palette["primary"];
    bg: Palette["primary"];
    textHover: Palette["primary"];
    roundiconbg: Palette["primary"];
    roundiconbghover: Palette["primary"];
    checkbox: Palette["primary"];
    radio: Palette["primary"];
  }
  interface PaletteOptions {
    active: PaletteOptions["primary"];
    custom: PaletteOptions["primary"];
    bg: PaletteOptions["primary"];
    textHover: PaletteOptions["primary"];
    roundiconbg: PaletteOptions["primary"];
    roundiconbghover: PaletteOptions["primary"];
    checkbox: PaletteOptions["primary"];
    radio: PaletteOptions["primary"];
  }
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

function setSpacing(value: number) {
  const width = window.innerWidth;
  if (width < 600) return 5 * value;
  else if (width < 900) return 6 * value;
  else if (width < 1200) return 7 * value;
  else return 8 * value;
}

export let appTheme: Theme = createTheme({
  typography: {
    fontFamily: ["Poppins"].join(","),
    allVariants: {
      color: ColorData.primary.main,
    },
    h1: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.75rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "1.3rem",
    },
    h5: {
      fontSize: "1.2rem",
    },
    h6: {
      fontSize: "1.1rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.9rem",
    },
    subtitle1: {
      fontSize: "0.8rem",
    },
    subtitle2: {
      fontSize: "0.7rem",
    },
  },
  palette: {
    ...ColorData,
  },
  spacing: setSpacing,
  breakpoints: {
    values: {
      xs: 1200,
      sm: 1240,
      md: 1330,
      lg: 1420,
      xl: 1536,
    },
  },
});

appTheme = responsiveFontSizes(appTheme);
