import { Box } from "@mui/material";
import { CommonStyle, SubNavBar } from "Components";
import { SubNavDataType } from "DataTypes/Layout";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import {
  billing,
  completedCare,
  consent,
  eligible,
  enrolled,
  inactive,
  myPatients,
  ongoing,
  pendingApproval,
  pendingAssign,
  pendingCare,
} from "./Data";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const ListManagement: FC = () => {
  const location = useLocation();
  const pathArr = location.pathname.split("/");
  const currentPath = pathArr[pathArr.length - 1];
  const { myPermission, myInfo }: any = useSelector(
    (state: any) => state?.user
  );
  const [arr, setArr] = useState<SubNavDataType[]>([]);

  useEffect(() => {
    setArr(TabArr(myPermission?.dashlinks, myInfo.roleId));
  }, []);

  useEffect(() => {
    if (arr.length) {
      const obj = arr.find((el) => el.link == currentPath);
      CommonFormatter.HandleNavArr({
        id: "patient-list",
        label: obj?.label || "",
        path: `/${RouteUrls.hs}/${RouteUrls.list}/${obj?.link}`,
        level: 1,
        link: obj?.link || "",
        navType: "patient",
      });
    }
  }, [currentPath, arr]);

  return (
    <Box height="91%">
      <SubNavBar data={arr} />
      <Box
        height="92%"
        sx={{ ...CommonStyle.sxWhiteBoxWithNavbar, px: 2, overflow: "auto" }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default ListManagement;

const TabArr: (permission: any, roleId: number | null) => SubNavDataType[] = (
  permission,
  roleId
) => {
  return [
    ...(permission?.eligible ? [eligible] : []),
    ...(permission?.pendingassign ? [pendingAssign] : []),
    ...(permission?.enrolled ? [enrolled] : []),
    ...(permission?.mypatient ? [myPatients] : []),
    ...(permission?.pendingapproval ? [pendingApproval] : []),
    ...(permission?.pending_care ? [pendingCare] : []),
    ...(permission?.completedcare ? [completedCare] : []),
    ...(permission?.inactive ? [inactive] : []),
    ...(permission?.ongoing ? [ongoing] : []),
    ...(permission?.billing && roleId !== 6 ? [billing] : []),
    ...(permission?.consent ? [consent] : []),
  ];
};
