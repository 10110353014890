///Bug resolve azure-404

import { FC, useState, useEffect, Fragment } from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { TableCol, actionArr, FilterArr, TableColBio } from "./Data";
import { CommonStyle, ControlledTable, Filter2 } from "Components";
import { TableLayout } from "Layouts";
import CstmBtn from "Components/CstmBtn";
import { useSelector } from "react-redux";
import PatientApi from "Service/Patient.api";
import Format from "./Format";
import { Filter2DataProps } from "DataTypes/Common";
import { CheckStringEmpty, CreateFilter2Payload } from "Utils/common";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import { useDispatch } from "react-redux";
import {
  BiometricBillingType,
  ServiceBillingType,
} from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import dayjs from "dayjs";
import TblBillingBody from "./TblBillingBody";

const Billing: FC = () => {
  const dispatch = useDispatch();
  const permission: any = useSelector(
    (state: any) => state?.user?.myPermission?.billingpatient
  );
  const currentMonth = DateTime.CurrentMonthName();
  const currentYear = DateTime.CurrentYear();
  const downloadPermission = permission?.download || false;
  const [rows, setRows] = useState<ServiceBillingType[]>([]);
  const [bioRows, setBioRows] = useState<BiometricBillingType[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIdArr, setSelectedArr] = useState<number[]>([]);
  const [billingType, setBillingType] = useState<string>("service");
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>([]);
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [donwloadHeight, setDownloadHeight] = useState<number>(0);
  const tableHeight = `calc(99% - ${filterHeight + donwloadHeight + 15}px)`;
  const offsetDownload = document?.getElementById("download-id")?.offsetHeight;

  useEffect(() => {
    setDownloadHeight(offsetDownload || 0);
  }, [offsetDownload]);

  useEffect(() => {
    handleSetBillingType();
  }, [billingType]);

  const handleSetBillingType: () => void = () => {
    let filData: Filter2DataProps[] = [];
    if (billingType) {
      if (billingType == "service") {
        filData = JSON.parse(JSON.stringify(FilterArr(false)));
        handleServiceData(filData, currentMonth, currentYear);
      } else {
        filData = JSON.parse(JSON.stringify(FilterArr(true)));
        handleBiometricData(filData);
      }
    }
    setFilterData(filData);
  };

  const handleServiceData: (
    filData: Filter2DataProps[],
    selectedMonth: string,
    selectedYear: string
  ) => void = async (filData, selectedMonth, selectedYear) => {
    dispatch(startLoading({ loading: true, loadtext: "Loading" }));
    const payMonth = selectedMonth || currentMonth;
    const payYear = selectedYear || currentYear;
    const payload = {
      month: dayjs(payMonth, "MMMM").month() + 1,
      year: dayjs(payYear).year(),
      ...CreateFilter2Payload(filData),
    };
    const res = await PatientApi.ServiceBillingList({ data: payload });
    if (res?.success) {
      setRows(Format.BillingList(res?.data));
      setMonth(payMonth);
      setYear(payYear);
    } else setRows([]);
    dispatch(stopLoading());
  };

  const handleBiometricData: (filData: Filter2DataProps[]) => void = async (
    filData
  ) => {
    dispatch(startLoading({ loading: true, loadtext: "Loading" }));
    const payload = {
      ...CreateFilter2Payload(filData),
    };
    const res = await PatientApi.BioBillingList({ data: payload });
    if (res?.success) {
      setBioRows(Format.BiometricBillingList(res?.data));
    } else setBioRows([]);
    dispatch(stopLoading());
  };

  useEffect(() => {
    if (rows.length > 0) {
      const newWholeArr = rows
        .filter((el) => !CheckRowSelectablity(el))
        .map((el) => el.id);
      const difference = newWholeArr
        .filter((x) => !selectedIdArr.includes(x))
        .concat(selectedIdArr.filter((x) => !newWholeArr.includes(x)));
      setSelectAll(
        difference.length == 0 && selectedIdArr.length > 0 ? true : false
      );
    }
  }, [selectedIdArr]);

  const columnClick: () => void = () => {
    const totalSelectableRows = rows.filter((el) => !CheckRowSelectablity(el));
    let newArr: number[] = [];
    if (selectedIdArr.length == totalSelectableRows.length) newArr = [];
    else newArr = totalSelectableRows.map((el) => el.id);
    setSelectedArr(newArr);
  };

  const rowClick: (rowId: number | string) => void = (rowId) => {
    const find = selectedIdArr.find((el) => el == rowId);
    if (find) setSelectedArr(selectedIdArr.filter((el) => el != rowId));
    else typeof rowId == "number" && setSelectedArr([...selectedIdArr, rowId]);
  };

  const BillingSum: (
    Arr: ServiceBillingType[],
    key:
      | "careteamtime"
      | "careteamclinictime"
      | "providerclinictime"
      | "totaltime"
  ) => number = (Arr, key) => {
    function getNewArr(total: number, current: ServiceBillingType) {
      return current.child.reduce(
        (sum, obj) => sum + (obj[key] ? Number(obj[key]) : 0),
        total
      );
    }
    return Arr.reduce(getNewArr, 0);
  };

  const sendToBolling: () => void = async () => {
    dispatch(startLoading({ loading: true, loadtext: "Loading" }));
    const billingIds: number[] = [];
    selectedIdArr.forEach((el) => {
      const findEl = rows.find((el1) => el1.id == el);
      if (findEl) {
        findEl.child.forEach((el2) => el2.id && billingIds.push(el2.id));
      }
    });
    const payload = { billingIds };
    const res = await PatientApi.sendToBilling({ data: payload });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      // handleSetData();
      setSelectedArr([]);
    }
    dispatch(stopLoading());
  };

  const CheckRowSelectablity: (rowData: ServiceBillingType) => boolean = (
    rowData
  ) => {
    let disbleCheckBox = true;
    const findRPM = rowData?.child?.find((el) => el.program == "RPM");
    if (findRPM) disbleCheckBox = false;
    else {
      if (rowData.child.length > 2) {
        disbleCheckBox = false;
      } else {
        if (rowData.child.length > 1 && rowData.child[0].status == "Billable") {
          disbleCheckBox = false;
        }
      }
    }
    return disbleCheckBox;
  };

  const downLoadExcel = (data: any): void => {
    const binaryResponse = data;
    const blob = new Blob([binaryResponse], {
      type: "application/ms-excel",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download =
      billingType != "service" ? "Biometric_Billing.xls" : "Billing.xls";
    link.click();
  };

  const downloadExport: () => void = async () => {
    let payload;
    let res;
    if (billingType == "service") {
      const payMonth = month || currentMonth;
      const payYear = year || currentYear;
      payload = {
        month: dayjs(payMonth, "MMMM").month() + 1,
        year: dayjs(payYear).year(),
        ...CreateFilter2Payload(filterData),
      };
      res = await PatientApi.billingExport({ data: payload });
    } else {
      payload = {
        ...CreateFilter2Payload(filterData),
      };
      res = await PatientApi.BioBillingExport({ data: payload });
    }
    if (res?.data) {
      downLoadExcel(res?.data);
    } else {
      dispatch(
        setAlertData({
          alertMessage: "Error fetching or downloading Excel file",
          alertVariant: "error",
          openAlert: true,
        })
      );
    }
  };

  const ExtraRow = (
    <TableRow sx={{ ...sxExtraRow }}>
      <TableCell colSpan={downloadPermission ? 6 : 5}>Total</TableCell>
      <TableCell align="left">{BillingSum(rows, "careteamtime")}</TableCell>
      <TableCell align="left">
        {BillingSum(rows, "careteamclinictime")}
      </TableCell>
      <TableCell align="left">
        {BillingSum(rows, "providerclinictime")}
      </TableCell>
      <TableCell align="left">{BillingSum(rows, "totaltime")}</TableCell>
      <TableCell colSpan={4} />
    </TableRow>
  );

  let TblBodyJSX: React.JSX.Element = <></>;
  if (billingType) {
    if (billingType == "service") {
      TblBodyJSX = (
        <TblBillingBody
          tableColumn={(currentRow: any) => {
            const rowData: ServiceBillingType = JSON.parse(
              JSON.stringify(currentRow)
            );
            const find = selectedIdArr.find((el) => el == rowData.id);
            return TableCol(
              columnClick,
              rowClick,
              selectAll,
              find ? true : false,
              CheckRowSelectablity(rowData),
              downloadPermission
            );
          }}
          tableRow={rows}
          tableAction={(data) => actionArr(data)}
          downloadPermission={downloadPermission}
        />
      );
    } else {
      TblBodyJSX = (
        <ControlledTable.TblBody
          tableColumn={() => {
            return TableColBio();
          }}
          tableRow={bioRows}
        />
      );
    }
  }

  return (
    <Fragment>
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => {
          setFilterData(data);
          if (billingType == "service") handleServiceData(data, month, year);
          else handleBiometricData(data);
        }}
        setFilterHeight={(val) => setFilterHeight(val)}
        {...(billingType == "service" && {
          monthChange: (val) => handleServiceData(filterData, val, year),
          monthFilter: true,
          monthVal: month,
          yearChange: (val) => handleServiceData(filterData, month, val),
          yearFilter: true,
          yearVal: year,
        })}
        viewBillType={true}
        billTypeChange={(val) => setBillingType(CheckStringEmpty(val))}
        billTypeVal={billingType}
      />
      {permission?.search && (
        <TableLayout
          tblBody={TblBodyJSX}
          {...(billingType == "service" && { extraRow: ExtraRow })}
          tblHead={
            billingType == "service"
              ? ControlledTable.TblHead(
                TableCol(
                  columnClick,
                  rowClick,
                  selectAll,
                  false,
                  false,
                  downloadPermission
                )
              )
              : ControlledTable.TblHead(TableColBio())
          }
          height={tableHeight}
        />
      )}
      {billingType && (
        <Box id="download-id" sx={{ ...sxBtnRow }}>
          {selectedIdArr.length > 0 &&
            billingType == "service" &&
            permission?.sendtobilling && (
            <CstmBtn
              label="Send to billing"
              onClick={() => sendToBolling()}
              disable={true}
            />
          )}
          {downloadPermission && (
            <CstmBtn label="Download" onClick={() => downloadExport()} />
          )}
        </Box>
      )}
    </Fragment>
  );
};
export default Billing;

const sxExtraRow = {
  position: "sticky",
  bottom: "0",
  "& >td": {
    fontWeight: "Bold",
    fontSize: "0.8rem",
    zIndex: -1,
    padding: 0,
    p: 1,
    backgroundColor: "custom.main",
  },
};

const sxBtnRow = {
  ...CommonStyle.sxRow,
  justifyContent: "flex-end",
  mt: 1,
  "& >button": {
    ml: 1,
  },
};
