import { ControlledForm, ControlledTable } from "Components";
import { columnType } from "DataTypes/Table";
import { CarePlanCreated, Consent, TypeOfConsent } from "Constant/Arr";
import { FormDataType } from "DataTypes/Form";

export const FormDataArr: () => FormDataType[] = () => {
  return [
    ControlledForm.FormData.ProgramName({ readonly: true }),
    ControlledForm.FormData.ConsentDrop({ dropArr: Consent }),
    ControlledForm.FormData.DOEDrop({ readonly: true }),
    ControlledForm.FormData.ConsentTypeDrop({
      dropArr: TypeOfConsent,
      readonly: true,
    }),
    ControlledForm.FormData.ConsentCreatedDrop({
      dropArr: CarePlanCreated,
      readonly: true,
    }),
    ControlledForm.FormData.DOIDrop({ readonly: true }),
    ControlledForm.FormData.ReasonDrop({ readonly: true }),
  ];
};

export const ProgramColArr: {
  id: number;
  label: string;
  boxsize: number;
}[] = [
  {
    id: 1,
    label: "Program",
    boxsize: 1,
  },
  {
    id: 2,
    label: "Consent",
    boxsize: 2,
  },
  {
    id: 3,
    label: "Date Of Enrollment",
    boxsize: 2,
  },
  {
    id: 4,
    label: "Consent Type",
    boxsize: 2,
  },
  {
    id: 5,
    label: "Plan Created",
    boxsize: 2,
  },
  {
    id: 6,
    label: "Plan Initiated Date",
    boxsize: 2,
  },
  {
    id: 7,
    label: "Reason",
    boxsize: 3,
  },
];

export const TableCol: () => columnType[] = () => {
  return [
    ControlledTable.ColumnData.ProgramCol("7%", "text"),
    ControlledTable.ColumnData.ConsentCol("11%"),
    ControlledTable.ColumnData.DOECol("10%"),
    ControlledTable.ColumnData.TypeConsentCol("12%"),
    ControlledTable.ColumnData.CarePlanCreatedCol("13%"),
    ControlledTable.ColumnData.CPInitiationCol("10%"),
    ControlledTable.ColumnData.ReasonTypeCol("23%"),
  ];
};
