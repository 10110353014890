import { Box } from "@mui/material";
import { useEffect, FC } from "react";
import { CommonStyle } from "Components";
import PatientThreshold from "Components/PatientThreshold";
import { useParams } from "react-router-dom";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const PatientThresholdAlerts: FC = () => {
  const { patientId } = useParams();
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "patient-threshold",
      label: "Patient Thresholds",
      path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.thresholds}`,
      level: 3,
      link: RouteUrls.thresholds,
      navType: "patient",
    });
  }, []);

  return (
    <Box my={1} sx={{ ...CommonStyle.sxWhiteCard }} width="100%">
      {/* <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {"Patient Alert Thresholds"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: "20px",
          mb: "30px",
        }}
      >
        <Box
          sx={{
            ...sxSubBox,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>{"Patient"}</Typography>
          <Typography variant="subtitle1">{patientInfo?.name}</Typography>
        </Box>
        <Box
          sx={{
            ...sxSubBox,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>{"Organization"}</Typography>
          <Typography variant="subtitle1">{patientOrg.practiceName}</Typography>
        </Box>
        <Box sx={{ mr: "20px" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            {"Billable Provider"}
          </Typography>
          <Typography variant="subtitle1">{"Patient Thresholds"}</Typography>
        </Box>
      </Box> */}
      <PatientThreshold
        showSaveButton={true}
        isSavedCounter={null}
        patientId={patientId}
      />
    </Box>
  );
};
export default PatientThresholdAlerts;

const sxSubBox = {
  mr: "20px",
  borderRight: "1px solid transparent",
  borderColor: "custom.main",
  pr: "20px",
};
