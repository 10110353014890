import { BreadArrType, HeadContextType } from "DataTypes/Common";
import { ReactNode, useState, createContext, FC } from "react";
// @types.todo.ts

interface Props {
  children: ReactNode;
}

export const HeadContext = createContext<HeadContextType | null>(null);

const HeadProvider: FC<Props> = ({ children }) => {
  const [headRightUI, setHeadRightUI] = useState<JSX.Element>(<></>);
  const [headLeftUI, setHeadLeftUI] = useState<JSX.Element>(<></>);
  const [breadArr, setBreadArr] = useState<BreadArrType[]>([]);
  const [spaceBtw, setSpaceBtw] = useState(false);

  return (
    <HeadContext.Provider
      value={{
        headRightUI,
        setHeadRightUI,
        headLeftUI,
        setHeadLeftUI,
        breadArr,
        setBreadArr,
        spaceBtw,
        setSpaceBtw,
      }}
    >
      {children}
    </HeadContext.Provider>
  );
};

export default HeadProvider;
