import { Box } from "@mui/material";
import { TabPanel1Props } from "DataTypes/Common";
import { FC } from "react";

const TabPanel1: FC<TabPanel1Props> = (props) => {
  const { children, value, currentValue, style, ...other } = props;
  return (
    <Box
      height="100%"
      role="tabpanel"
      hidden={value !== currentValue}
      id={`simple-tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      style={style ?? null}
      {...other}
    >
      {value === currentValue && children}
    </Box>
  );
};

export default TabPanel1;
