import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from ".";

const list: () => any = () => {
  const request = {
    subUrl: "program/search",
  };
  return post(request);
};

const mappedProblemList: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `program/${payload.id1}/problem/search`,
    data: {
      data: { ...(payload.data ? payload.data : {}) },
      criterion: {
        offset: payload?.offset,
        limit: payload.limit,
      },
    },
  };
  return post(request);
};

const updateMappedProblem: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `program/${payload.id1}/problem/update`,
    data: { data: payload.data },
  };
  return post(request);
};

const UnenrollProgram: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data.id1}/eligibleprogramid/${data.id2}/unenroll`,
    data: { data: data.data },
  };
  return post(request);
};

const EnrollProgram: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `patient/${data.id1}/eligibleprogramid/${data.id2}/enroll`,
    data: { data: data.data },
  };
  return post(request);
};

export default {
  list,
  mappedProblemList,
  updateMappedProblem,
  UnenrollProgram,
  EnrollProgram,
};
