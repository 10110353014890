import { ControlledTable } from "Components";
import FilterData from "Components/Filter2/Data";
import { Filter2DataProps } from "DataTypes/Common";
import { columnType } from "DataTypes/Table";

export const TableCol: () => columnType[] = () => {
  const Arr: columnType[] = [
    {
      id: "actionType",
      label: "Action Type",
      width: "20%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    {
      id: "actionBy",
      label: "Action By",
      width: "15%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    {
      id: "description",
      label: "Description",
      width: "45%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    ControlledTable.ColumnData.DateTimeCol1("20%"),
  ];
  return Arr;
};

export const FilterArr: () => Filter2DataProps[] = () => {
  return [
    FilterData.actionType,
    FilterData.requestedBy,
    FilterData.requestedByDesig,
    FilterData.description,
    FilterData.startDate,
    FilterData.endDate,
  ];
};
