import { FC, useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import ImgPath from "Constant/Imgs";
import CstmBtn from "Components/CstmBtn";
import { label } from "Constant/En";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import { LoginLayout, ModalLayout } from "Layouts";
import { commonFormSwitch } from "Utils/SwitchCases";
import { HandleChangeProps } from "DataTypes/Common";
import { Modal } from "Components";
import { setLocalStorage } from "Utils/storage";
import AuthApi from "Service/Auth.api";
import { useDispatch } from "react-redux";
import { clearUser, setMyInfo, setMyPermission } from "Redux/User/Action";
import { CommonButtonArray } from "Components/Modal/Data";
import {
  CheckStringEmpty,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import ModelApi from "Service/Model.api";
import DataFormatter, { FormatDropdownData } from "Utils/DataFormatter";
import {
  addModelDropList,
  addPrgDropList,
  addProgramList,
  addQualityDropList,
  addRoleDropList,
  clearListData,
} from "Redux/List/Action";
import RoleApi from "Service/Role.api";
import UserApi from "Service/User.api";
import ProgramApi from "Service/Program.api";
import {
  clearScreenData,
  setAlertData,
  startLoading,
  stopLoading,
} from "Redux/Screen/Action";
import Formate from "./Formate";
import UserFormatter from "Utils/Users.Formatter";
import PrgmFormatter from "Utils/Prgm.Formatter";
import { PrgmListResType } from "DataTypes/Services/Prgm.type";
import { DataArr, DataArr1, IconClickArr } from "./Data";
import { UserReadApiType } from "DataTypes/Services/User.type";
import PracticeApi from "Service/Practice.api";
import OrganizationFormatter from "Utils/Organization.Formatter";
import { RouteUrls } from "Constant/RouteUrls";
import SettingsApi from "Service/Settings.api";
import SettingsFormatter from "Utils/Settings.Formatter";
import { clearPatient } from "Redux/Patient/Action";
import { clearHeader } from "Redux/Header/Action";

const Login: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormDataType[] | []>(
    JSON.parse(JSON.stringify(DataArr))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [attemptId, setAttemptId] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [formData1, setFormData1] = useState<FormDataType[] | []>(
    JSON.parse(JSON.stringify(DataArr1))
  );
  const [userId, setUserId] = useState<number | null>(null);
  const [modelData, setModelData] = useState<DropdownArrPropsType[]>([]);
  const [prgmData, setPrgmData] = useState<PrgmListResType[]>([]);
  const [prgmDropData, setPrgmDropData] = useState<DropdownArrPropsType[]>([]);
  const [totalPermissionSlugArr, setTotalPermissionSlugArr] = useState<
    string[]
  >([]);
  const [myDetails, setMyDetails] = useState<UserReadApiType | null>(null);

  useEffect(() => {
    dispatch(clearHeader());
    dispatch(clearListData());
    dispatch(clearUser());
    dispatch(clearPatient());
    dispatch(clearHeader());
    dispatch(clearScreenData());
    localStorage.clear();
  }, []);

  const handlePassClick = (id: string) => {
    const newArr = formData.map((el) => {
      if (el.id == id) el.type = el.type == "text" ? "password" : "text";
      return el;
    });
    setFormData(JSON.parse(JSON.stringify(newArr)));
  };

  const handleLogin: () => void = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck) {
      setIsLoading(true);
      const res: any = await AuthApi.signIn({
        data: Formate.LoginPayload(formData),
      });
      if (res.success) {
        setAttemptId(UserFormatter.LoginRes(res?.data));
        dispatch(setAlertData(res?.alert));
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    attemptId ? setOpenModal(true) : setOpenModal(false);
  }, [attemptId]);

  const handleVerify = async () => {
    const newArr = ValidateFormData(formData1);
    setFormData1(newArr.formData);
    if (!newArr.errorCheck) {
      dispatch(startLoading({ loading: true, loadtext: "Logging In" }));
      const res: any = await AuthApi.verifyOtp({
        data: Formate.VerifyOtpPayload(attemptId, formData1),
      });
      if (res.success) {
        setAttemptId(null);
        const { userId, token } = UserFormatter.VerifyOtpRes(res?.data);
        setUserId(userId);
        setLocalStorage("cm-token", token);
      }
    }
  };

  const handleCanel: () => void = () => {
    setAttemptId(null);
    setUserId(null);
    setFormData1(JSON.parse(JSON.stringify(DataArr1)));
  };

  useEffect(() => {
    userId && handleModalApi();
  }, [userId]);

  const handleModalApi: () => void = async () => {
    const ModelRes: any = await ModelApi.list();
    if (ModelRes?.success) {
      const dropData = DataFormatter.FormatDropdownData(
        ModelRes?.data,
        "id",
        "name",
        "id"
      );
      setModelData(dropData);
    } else {
      localStorage.removeItem("cm-token");
      handleCanel();
    }
  };

  useEffect(() => {
    modelData.length > 0 && handlePrgmApi();
  }, [modelData]);

  const handlePrgmApi: () => void = async () => {
    const PrgmRes: any = await ProgramApi.list();
    if (PrgmRes?.success) {
      const PrgmList = PrgmFormatter.PrgmListRes(PrgmRes?.data);
      setPrgmData(PrgmList);
      const DropData = DataFormatter.FormatDropdownData(
        PrgmList,
        "id",
        "abbrevation",
        "id"
      );
      setPrgmDropData(DropData);
    } else {
      localStorage.removeItem("cm-token");
      handleCanel();
    }
  };

  useEffect(() => {
    prgmData.length > 0 && prgmDropData.length > 0 && handlePermissionApi();
  }, [prgmData, prgmDropData]);

  const handlePermissionApi: () => void = async () => {
    const payload = {
      ///As discussed with Yash Ashapure and Lalit Dashora
      // isUserlevelPermission: true,
      isUserlevelPermission: null,
      isDeleted: false,
    };
    const res = await UserApi.permissionList({ data: payload });
    if (res?.success) {
      const Arr = res?.data.map((item: any) => CheckStringEmpty(item?.slug));
      setTotalPermissionSlugArr(Arr);
    } else {
      localStorage.removeItem("cm-token");
      handleCanel();
    }
  };

  useEffect(() => {
    totalPermissionSlugArr.length > 0 && handleUserApi();
  }, [totalPermissionSlugArr]);

  const handleUserApi: () => void = async () => {
    const res = await UserApi.read({ id1: userId });
    if (res?.success) {
      setMyDetails(UserFormatter.VerifyReadResponse(res?.data));
    } else {
      localStorage.removeItem("cm-token");
      handleCanel();
    }
  };

  useEffect(() => {
    myDetails && handlePracticeApi();
  }, [myDetails]);

  const handlePracticeApi: () => void = async () => {
    if (myDetails && myDetails.role[0]?.permission) {
      const myInfoData = Formate.FormateMyInfoData(myDetails);
      const userPermissionSlugArr = myDetails.role[0].permission.map(
        (el) => el.slug
      );
      const userAllowedPermission = Formate.getAllowedPermission(
        totalPermissionSlugArr,
        userPermissionSlugArr
      );
      if (myInfoData.practiceId) {
        const res = await PracticeApi.read({
          id1: myInfoData.practiceId,
        });
        if (res?.success) {
          const practiceInfo = OrganizationFormatter.VerifyReadResponse(
            res?.data
          );
          myInfoData.practiceModelId = practiceInfo.businessModels.id;
          myInfoData.practiceModelName = practiceInfo.businessModels.name || "";
          myInfoData.isMasterPractice = practiceInfo.masterPractice;
          dispatch(addModelDropList(modelData));
          dispatch(addPrgDropList(prgmDropData));
          dispatch(addProgramList(prgmData));
          dispatch(setMyInfo(myInfoData));
          dispatch(setMyPermission(userAllowedPermission));
          navigate("/");
          dispatch(stopLoading());
          dispatch(
            setAlertData({
              alertMessage: `Welcome  ${myInfoData.firstname} ${myInfoData.lastname}`,
              openAlert: true,
              alertVariant: "success",
            })
          );
        } else {
          localStorage.removeItem("cm-token");
          handleCanel();
        }
      } else {
        dispatch(
          setAlertData({
            alertMessage: "Organization not found",
            openAlert: true,
            alertVariant: "error",
          })
        );
        dispatch(stopLoading());
        localStorage.removeItem("cm-token");
        handleCanel();
      }
    }
  };

  const rightBox = (
    <Box sx={{ ...sxRight }}>
      <img src={ImgPath.CV_Logo} alt="" />
      {formData.map((el) => {
        return (
          <Box key={el.id}>
            {commonFormSwitch({
              formObj: el,
              onChange: (data) => setFormData(HandleFormChange(data, formData)),
              iconClickArr: IconClickArr(handlePassClick),
            })}
          </Box>
        );
      })}
      <CstmBtn
        label={label.login}
        onClick={handleLogin}
        width="30%"
        py={1.5}
        fontSize="1rem"
        loading={isLoading}
        loadingText="Loading..."
      />
      <Link onClick={() => navigate(`/${RouteUrls.forgot}`)}>
        <Typography fontWeight="bold">{`${label.forget} ?`}</Typography>
      </Link>
    </Box>
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="35%"
        heading={"Enter Verification Code"}
        content={
          <Modal.VerifyOtp
            formArr={formData1}
            handleChange={(data: HandleChangeProps) => {
              setFormData1(HandleFormChange(data, formData1));
            }}
          />
        }
        open={openModal}
        buttonArr={CommonButtonArray(
          handleVerify,
          handleCanel,
          "Verify",
          "Close"
        )}
      />
      <LoginLayout rightBox={rightBox} />
    </Fragment>
  );
};
export default Login;
const sxRight = {
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& >img:first-of-type": {
    width: "40%",
    mb: 2,
  },
  "& >div:nth-of-type(1)": {
    width: "60%",
  },
  "& >div:nth-of-type(2)": {
    width: "60%",
    mt: 3.6,
  },
  "& >button:nth-of-type(1)": {
    mb: 1,
    mt: 3.6,
  },
  "& >a:last-child": {
    textDecoration: "none",
    cursor: "pointer",
    "& >p": {
      color: "active.main",
    },
  },
};
