import {
  UserAuditType,
  UserDirectApiType,
  UserListApiType,
  UserLocationApiType,
  UserPermissionApiType,
  UserPracticeApiType,
  UserReadApiType,
  UserRoleApiType,
  UserRolePermissionApiType,
} from "DataTypes/Services/User.type";
import {
  AscendSortByNumber,
  CheckArr,
  CheckBoolean,
  CheckNumber,
  CheckStatus,
  CheckStringEmpty,
} from "./common";
import DateTime from "./DateTime";

const LoginRes: (resData: any) => number | null = (resData) => {
  return CheckNumber(resData?.attemptId);
};

const VerifyOtpRes: (resData: any) => {
  userId: number | null;
  token: string;
} = (resData) => {
  return {
    userId: CheckNumber(resData?.userId),
    token: CheckStringEmpty(resData?.token),
  };
};

const VerifyListArrResponse: (data: any) => UserListApiType[] = (data) => {
  const newArr = CheckArr(data);
  const UserArr =
    newArr.map((newEl) => {
      return VerifyListObjResponse(newEl);
    }) || [];
  return UserArr;
};

const VerifyListObjResponse: (data: any) => UserListApiType = (data) => {
  const firstName = CheckStringEmpty(data?.firstName);
  const lastName = CheckStringEmpty(data?.lastName);
  const designation = CheckStringEmpty(data?.designation);
  const fullNameDes = firstName
    ? firstName +
      `${lastName ? " " + lastName : ""}` +
      `${designation ? ", " + designation : ""}`
    : "";
  return {
    ...(data?.id && { id: CheckNumber(data?.id) }),
    firstName,
    lastName,
    designation,
    fullNameDes,
    directNo: CheckStringEmpty(data?.directNo),
    email: CheckStringEmpty(data?.email),
    mobile: CheckStringEmpty(data?.mobile),
    officeNo: CheckStringEmpty(data?.officeNo),
    practice: VerifyUserOrgResponse(data?.practice),
    role: VerifyUserRoleResponse(data?.role),
    status: CheckStatus(data?.status),
    timezone: CheckStringEmpty(data?.timezone),
    userName: CheckStringEmpty(data?.userName),
    speciality: CheckStringEmpty(data.speciality),
  };
};

const VerifyReadResponse: (data: any) => UserReadApiType = (data) => {
  return {
    ...VerifyListObjResponse(data),
    address1: CheckStringEmpty(data?.address1),
    address2: CheckStringEmpty(data?.address2),
    city: CheckStringEmpty(data?.city),
    country: CheckStringEmpty(data?.country),
    dob: CheckStringEmpty(data?.dob),
    gender: CheckStringEmpty(data?.gender),
    licence: CheckStringEmpty(data?.licence),
    permission: VerifyReadPermissionResponse(data?.permission),
    providerNpi: CheckStringEmpty(data?.providerNpi),
    secondaryEmail: CheckStringEmpty(data?.secondaryEmail),
    slug: CheckArr(data?.slug),
    speciality: CheckStringEmpty(data?.speciality),
    state: CheckStringEmpty(data?.state),
    zipcode: CheckStringEmpty(data?.zipcode),
  };
};

const VerifyUserOrgResponse: (data: any) => UserPracticeApiType[] = (data) => {
  const newArr: UserPracticeApiType[] =
    CheckArr(data).map((el) => {
      return {
        id: CheckNumber(el?.id),
        ...(el?.name && { name: CheckStringEmpty(el?.name) }),
        practiceLocations: VerifyUserLocResponse(el?.practiceLocations),
        isMasterPractice: CheckBoolean(el?.isMasterPractice),
        practiceOrder: CheckNumber(el?.practiceOrder),
        status: CheckStatus(el?.status),
      };
    }) || [];
  return AscendSortByNumber(newArr, "practiceOrder");
};

const VerifyUserLocResponse: (data: any) => UserLocationApiType[] = (data) => {
  const newArr: UserLocationApiType[] =
    CheckArr(data).map((el) => {
      return {
        id: CheckNumber(el?.id),
        ...(el?.location1 && { location1: CheckStringEmpty(el?.location1) }),
        isMasterPracticeLocation: CheckBoolean(el?.isMasterPracticeLocation),
        practiceLocationOrder: CheckNumber(el?.practiceLocationOrder),
        userPracticeLocationOrder: CheckNumber(el?.userPracticeLocationOrder),
        practiceLocationDirectNumbers: VerifyUserDirResponse(
          el?.practiceLocationDirectNumbers
        ),
        status: CheckStatus(el?.status),
      };
    }) || [];
  return AscendSortByNumber(newArr, "practiceLocationOrder");
};

const VerifyUserDirResponse: (data: any) => UserDirectApiType[] = (data) => {
  const newArr: UserDirectApiType[] =
    CheckArr(data).map((el) => {
      return {
        id: CheckNumber(el?.id),
        ...(el?.directNumber && {
          directNumber: CheckStringEmpty(el?.directNumber),
        }),
        status: CheckStatus(el?.status),
      };
    }) || [];
  return AscendSortByNumber(newArr, "id");
};

const VerifyUserRoleResponse: (data: any) => UserRoleApiType[] = (data) => {
  const arr = CheckArr(data);
  const newArr: UserRoleApiType[] =
    arr.map((el) => {
      return {
        id: CheckNumber(el?.id),
        name: CheckStringEmpty(el?.name),
        permission: VerifyUserRolePermissionResponse(el?.permission),
      };
    }) || [];
  return newArr;
};

const VerifyUserRolePermissionResponse: (
  data: any
) => UserRolePermissionApiType[] = (data) => {
  const arr = CheckArr(data);
  const newArr: UserRolePermissionApiType[] =
    arr.map((el) => {
      return {
        id: CheckNumber(el?.id),
        name: CheckStringEmpty(el?.name),
        slug: CheckStringEmpty(el?.slug),
      };
    }) || [];
  return newArr;
};

const VerifyReadPermissionResponse: (data: any) => UserPermissionApiType[] = (
  data
) => {
  const arr = CheckArr(data);
  const newArr: UserPermissionApiType[] = arr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      name: CheckStringEmpty(el?.name),
      description: CheckStringEmpty(el?.description),
    };
  });
  return newArr;
};

const VerifyLogRes: (data: any) => UserAuditType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    const name = CheckStringEmpty(el?.requestedByName);
    const des = CheckStringEmpty(el?.requestedByDesignation);
    const actionBy = name ? name + `${des ? ", " + des : ""}` : "";
    return {
      id: CheckNumber(el?.id),
      actionType: CheckStringEmpty(el?.actionType),
      actionOn: CheckStringEmpty(el?.actionOn),
      actionBy,
      description: CheckStringEmpty(el?.description),
      ipAddress: CheckStringEmpty(el?.sourceIp),
      datetime: DateTime.ValidDate(el?.createdAt),
    };
  });
};

export default {
  LoginRes,
  VerifyOtpRes,
  VerifyListArrResponse,
  VerifyListObjResponse,
  VerifyReadResponse,
  VerifyUserOrgResponse,
  VerifyUserLocResponse,
  VerifyUserDirResponse,
  VerifyUserRoleResponse,
  VerifyUserRolePermissionResponse,
  VerifyReadPermissionResponse,
  VerifyLogRes,
};
