import { label } from "Constant/En";
import { RouteUrls } from "Constant/RouteUrls";
import { SubNavDataType } from "DataTypes/Layout";

export const mytraining: SubNavDataType = {
  id: RouteUrls.myTraining,
  label: label.myTraining,
  link: `${RouteUrls.myTraining}`,
};

export const completedtraining: SubNavDataType = {
  id: RouteUrls.completedTraining,
  label: label.cpt,
  link: `${RouteUrls.completedTraining}`,
};

export const billingTraining: SubNavDataType = {
  id: RouteUrls.billing,
  label: label.billing,
  link: `${RouteUrls.billing}`,
};
