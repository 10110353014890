import { FormDataType } from "DataTypes/Form";
import { label } from "Constant/En";

export const DataArr: FormDataType[] = [
  {
    id: "userName",
    placeholder: label.userId,
    boxType: "login-input-box",
    validatetype: "userid",
    iconId: "login-icon",
    required: true,
    requiredSign: false,
  },
];
