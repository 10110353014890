// import { ControlledTable } from "Components";
// import BreadUrls from "Constant/BreadUrls";
// import { BreadArrType } from "DataTypes/Common";
// import { FormDataType } from "DataTypes/Form";
// import { PatientInfoType } from "DataTypes/Redux";

// export const CpProblemCol = [
//   ControlledTable.ColumnData.ProblemCol("25%"),
//   ControlledTable.ColumnData.IcdCol("15%"),
//   ControlledTable.ColumnData.DescriptionCol("60%"),
// ];

// export const EnterProblemBreadArr: (
//   patient: PatientInfoType
// ) => BreadArrType[] = (patient) => {
//   return [
//     {
//       id: 1,
//       label: `${patient.name}`,
//       path: BreadUrls.ClinicalSummary(patient.id),
//     },
//     {
//       id: 2,
//       label: "Problems",
//       path: "",
//     },
//   ];
// };

// export const FormArr: FormDataType[] = [
//   {
//     id: "cp-problem",
//     label: "Select Problems",
//     placeholder: "Select Care Plan Problems",
//     boxType: "single-select-drop",
//     boxSize: 3,
//     validatetype: "dropdown",
//   },
//   {
//     id: "cp-icdCodes",
//     label: "ICD Codes",
//     placeholder: "Select ICD Codes",
//     boxType: "multi-select-search-drop",
//     boxSize: 3,
//     validatetype: "dropdown",
//   },
// ];

import { ControlledTable } from "Components";
import {
  FormDataType,
  FormObjType,
  MultiFormObjType,
  SingleFormObjType,
} from "DataTypes/Form";
import { actionArrType, columnType } from "DataTypes/Table";

export const CpProblemCol = [
  ControlledTable.ColumnData.ProblemCol("25%"),
  ControlledTable.ColumnData.IcdCol("15%"),
  ControlledTable.ColumnData.DescriptionCol("60%"),
];

export const FormArr: FormDataType[] = [
  {
    id: "cp-problem",
    label: "Select Problems",
    placeholder: "Select Care Plan Problems",
    boxType: "single-select-drop",
    boxSize: 3,
    validatetype: "dropdown",
  },
  {
    id: "cp-icdCodes",
    label: "ICD Codes",
    placeholder: "Select ICD Codes",
    boxType: "multi-select-search-drop",
    boxSize: 3,
    validatetype: "dropdown",
  },
];

export const FormArr1 = (isRPM: boolean): FormObjType[] => [
  {
    id: "cp-problem",
    label: "Select Problems",
    placeholder: `Select ${isRPM ? "Treatment" : "Care"} Plan Problems`,
    boxType: "single-select",
    boxSize: 3,
    validatetype: "dropdown",
    apiSearch: true,
  },
  {
    id: "cp-icdCodes",
    label: "ICD Codes",
    placeholder: "Select ICD Codes",
    boxType: isRPM ? "single-select" : "multi-select",
    boxSize: 3,
    validatetype: isRPM ? "dropdown" : "multi-dropdown",
    apiSearch: true,
  } as SingleFormObjType | MultiFormObjType,
];

export const CpProblemCol1: columnType[] = [
  {
    id: "problemName",
    label: "Problem Name",
    width: "20%",
    align: "left",
    colType: "text",
    rowType: "boldText",
  },
  {
    id: "icdCode",
    label: "ICD Code",
    width: "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "icdCodeDescription",
    label: "Description",
    width: "55%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  ControlledTable.ColumnData.ActionCol("10%"),
];

export const actionArr: (handleDelete: () => void) => actionArrType[] = (
  handleDelete
) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.deleteAction(handleDelete),
  ];
  return Arr;
};
