import { FC, Fragment, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CategoryBox from "./CategoryBox";
import QuestionBox1 from "./QuestionBox";
import { CategoryApiObj } from "DataTypes/Services/Category.type";
import CategoryApi from "Service/Category.api";
import CategoryFormatter from "Utils/Category.Formatter";
import { MasterQuestionType } from "DataTypes/Services/Question.type";
import QuestionApi from "Service/Question.api";
import QuestionFormatter from "Utils/Question.Formatter";
import { useNavigate } from "react-router";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch, useSelector } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { UpdateMaster } from "Redux/Header/Action";
import { HeaderInitialStateType } from "DataTypes/Redux";
import { ModalLayout } from "Layouts";
import { DeleteButtonArray } from "Components/Modal/Data";
import CommonFormatter from "Utils/Common.Formatter";

const QuestionList: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { master }: HeaderInitialStateType = useSelector(
    (state: any) => state?.header
  );
  const currentCatId = master.categoryId;
  const currentPage = master.page || 1;
  const currentLimit = master.limit || 25;
  const [categoryArr, setCategoryArr] = useState<CategoryApiObj[]>([]);
  const [catLoading, setCatLoading] = useState(true);
  const [selectedCat, setSelectedCat] = useState<CategoryApiObj | null>(null);
  const [questionArr, setQuestionArr] = useState<MasterQuestionType[]>([]);
  const [quesLoading, setQuesLoading] = useState(false);
  const [loadingQuesId, setLoadingQuesId] = useState<number | null>(null);
  const [openArr, setOpenArr] = useState<number[]>([]);
  const [deleteQues, setDeleteQues] = useState<MasterQuestionType | null>(null);
  const [totalRecord, setTotalRecord] = useState(0);
  const [quesSearch, setQuesSearch] = useState("");

  useEffect(() => {
    ///14127 pms bug resolved
    CommonFormatter.HandleNavArr({
      id: "ques-master",
      label: "Questionnaire Master",
      path:
        `/${RouteUrls.hs}/${RouteUrls.master}/` +
        `${RouteUrls.questionnaire}/${RouteUrls.list}`,
      level: 1,
      link: RouteUrls.list,
      navType: "master",
    });
    categoryApiCall();
  }, []);

  const categoryApiCall: () => void = async () => {
    setCatLoading(true);
    let newCategory: CategoryApiObj | null = null
    const payload = { isDeleted: false };
    const res = await CategoryApi.list({ data: payload });
    if (res?.success) {
      const formatedArr = CategoryFormatter.CheckCategoryArr(res?.data);
      setCategoryArr(formatedArr);
      if (currentCatId) {
        const find = formatedArr.find((catEl) => catEl.id == currentCatId);
        if (find) newCategory = find;
      } else newCategory = formatedArr.length > 0 ? formatedArr[0] : null;
      setOpenArr([]);
    }
    setSelectedCat(newCategory);
    fetchParentQuesList(newCategory, currentPage, currentLimit);
    setCatLoading(false);
  };

  const fetchParentQuesList: (
    catDetails: CategoryApiObj | null,
    page: number,
    limit: number
  ) => void = async (catDetails, page, limit) => {
    setQuesLoading(true);
    setLoadingQuesId(null);
    let quesArr: MasterQuestionType[] = [];
    const search = catDetails?.id == selectedCat?.id ? quesSearch : "";
    if (catDetails && catDetails.id) {
      const res = await QuestionApi.list({
        data: { categoryId: catDetails.id, questionsName: search },
        limit: limit,
        offset: (page - 1) * limit,
      });
      if (res?.success) {
        setTotalRecord(res?.criterion?.total);
        quesArr = QuestionFormatter.CheckMasterQuestionArr(res?.data);
      } else quesArr = [];
    } else quesArr = [];
    setQuesSearch(search);
    setSelectedCat(catDetails);
    setQuestionArr(quesArr);
    setQuesLoading(false);
    setOpenArr([]);
    dispatch(
      UpdateMaster({
        ...master,
        categoryId: catDetails?.id || 1,
        page,
        limit,
      })
    );
  };

  const handleChange: (id: number | null) => void = (id) => {
    const newCategory = categoryArr.find((el) => el.id == id) || null;
    fetchParentQuesList(newCategory, 1, 25);
  };

  const fetchChildQuesList: (
    parentQuesId: number
  ) => Promise<MasterQuestionType[]> = async (parentQuesId) => {
    const res = await QuestionApi.list({
      data: { parentQuestionId: parentQuesId },
      limit: 100,
      offset: 0,
    });
    if (res?.success) {
      return QuestionFormatter.CheckMasterQuestionArr(res?.data);
    } else return [];
  };

  const childQuestionCall: (quesData: MasterQuestionType) => void = async (
    quesData
  ) => {
    if (quesData.id) {
      const findQuesId = openArr.find((openEl) => openEl == quesData.id);
      if (findQuesId)
        setOpenArr(openArr.filter((openEl) => openEl != quesData.id));
      else {
        setOpenArr([...openArr, quesData.id]);
        setQuesLoading(true);
        setLoadingQuesId(quesData.id);
        const find = questionArr.find(
          (quesEl) => quesEl.parentQuestionId == quesData.id
        );
        if (!find) {
          const newQuesArr = await fetchChildQuesList(quesData.id);
          setQuestionArr([...questionArr, ...newQuesArr]);
        }
        setQuesLoading(false);
        setLoadingQuesId(null);
      }
    }
  };

  const handleAdd: (
    quesData?: MasterQuestionType,
    optionId?: number | null
  ) => void = async (quesData, optionId) => {
    if (quesData && quesData.id && optionId) {
      navigate(
        `../${selectedCat?.id}/${quesData.id}/${optionId}/`
        + `${RouteUrls.add}`
      );
    } else navigate(`../${selectedCat?.id}/${RouteUrls.add}`);
  };

  const handleUpdate: (quesData: MasterQuestionType) => void = async (
    quesData
  ) => {
    if (quesData && quesData.id) {
      if (quesData.parentOptionId && quesData.parentQuestionId) {
        navigate(
          `../${selectedCat?.id}/${quesData.parentQuestionId}/`
          + `${quesData.parentOptionId}/${quesData.id}/${RouteUrls.update}`
        );
      } else {
        navigate(`../${selectedCat?.id}/${quesData.id}/${RouteUrls.update}`);
      }
    }
  };

  const handleDeleteClick: () => void = async () => {
    const res = await QuestionApi.deleteQues({ id1: deleteQues?.id });
    if (res?.success) {
      categoryApiCall();
      dispatch(setAlertData(res?.alert));
    }
    setDeleteQues(null);
  };

  return (
    <Fragment>
      <ModalLayout
        modwidth="30%"
        heading={"Warning"}
        content={
          <Box textAlign="center">
            <Typography mb={2}>Do you want to delete the following:</Typography>
            <Typography>{deleteQues?.question}</Typography>
          </Box>
        }
        onClose={() => setDeleteQues(null)}
        open={deleteQues ? true : false}
        buttonArr={DeleteButtonArray(handleDeleteClick, () => {
          setDeleteQues(null);
        })}
      />
      <Box display="flex" flexDirection="row" height="100%">
        <Box width="30%" height="100%" sx={{ mr: 2 }}>
          <CategoryBox
            categoryArr={categoryArr}
            catLoading={catLoading}
            selectedCat={selectedCat}
            onClick={handleChange}
          />
        </Box>
        <Box width="70%">
          {selectedCat && (
            <QuestionBox1
              selectedCat={selectedCat}
              questionArr={questionArr}
              openArr={openArr}
              quesLoading={quesLoading}
              loadingQuesId={loadingQuesId}
              handleAdd={handleAdd}
              handleDelete={(quesData) => setDeleteQues(quesData)}
              handleUpdate={handleUpdate}
              callChildQues={childQuestionCall}
              totalRecord={totalRecord}
              handleChange={(newPage, newLimit) => {
                fetchParentQuesList(selectedCat, newPage, newLimit)
              }}
              searchVal={quesSearch}
              handleSetQuesSearch={(val) => setQuesSearch(val)}
            />
          )}
        </Box>
      </Box>
    </Fragment>
  );
};
export default QuestionList;
