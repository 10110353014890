import { Box, Tooltip, Typography } from "@mui/material";
import { FC, Fragment, useEffect, useState } from "react";
import { CommonStyle, Loader } from "Components";
import AlertHeads from "./AlertHeads";
import SettingsApi from "Service/Settings.api";
import Format from "./Format";
import {
  AlertSetListType,
  AlertSetType,
} from "DataTypes/Services/Settings.type";
import AlertRows from "./AlertRows";
import { AlertSetChangeType } from "DataTypes/SettingsFormatter.type";
import { ListInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import { RouteUrls } from "Constant/RouteUrls";
import { CheckNumber, HandleFormChange, ValidateFormData } from "Utils/common";
import { FormDataArr } from "./Data";
import { commonFormSwitch } from "Utils/SwitchCases";
import CommonFormatter from "Utils/Common.Formatter";
import ImgPath from "Constant/Imgs";

const AlertSettings: FC = () => {
  const emptyWidth = "13.5%";
  const emptyWidth2 = "27%";
  const dispatch = useDispatch();
  const { orgDropList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const [loading, setLoading] = useState(true);
  const [orgList, setOrgList] = useState<DropdownArrPropsType[]>([]);
  const [userData, setUserData] = useState<AlertSetListType[]>([]);
  const [patientData, setPatientData] = useState<AlertSetType[]>([]);
  const [formArr, setFormArr] = useState<FormDataType[]>(FormDataArr());

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "rpm-alert-settings",
      label: "RPM Alert Settings",
      path: `/${RouteUrls.hs}/${RouteUrls.alertSettings}`,
      level: 1,
      link: RouteUrls.alertSettings,
      navType: "settings",
    });
  }, []);

  useEffect(() => {
    orgDropList.length > 0 && LoadOrgList();
  }, [orgDropList]);

  const LoadOrgList: () => void = () => {
    let newArr: DropdownArrPropsType[] = JSON.parse(
      JSON.stringify(orgDropList)
    );
    newArr = newArr.filter((el) => el.label != "CareVitality");
    setOrgList(newArr);
  };

  useEffect(() => {
    if (orgList.length > 0) {
      formArr[0].dropArr = orgList;
      formArr[0].dropValue = orgList[0].value;
      setFormArr([...formArr]);
    }
  }, [orgList]);

  useEffect(() => {
    formArr[0].dropValue && InitialLoad();
  }, [formArr[0].dropValue]);

  const InitialLoad: () => void = async () => {
    setLoading(true);
    if (formArr[0].dropValue) {
      const patientRes = await InitialPatientApiCall();
      setPatientData(patientRes);
      const UserRes = await InitialUserApiCall();
      setUserData(UserRes);
    } else {
      setPatientData([]);
      setUserData([]);
    }
    setLoading(false);
  };

  const InitialPatientApiCall: () => Promise<AlertSetType[]> = async () => {
    const payload = { practiceId: formArr[0].dropValue };
    const res = await SettingsApi.AlertSetPatientSearch({ data: payload });
    let data: AlertSetType[] = [];
    if (res?.success) data = Format.PatientAlertSetSearch(res?.data);
    return data;
  };

  const InitialUserApiCall: () => Promise<AlertSetListType[]> = async () => {
    const payload = { practiceId: formArr[0].dropValue };
    const res = await SettingsApi.AlertSetUserSearch({ data: payload });
    let data: AlertSetListType[] = [];
    if (res?.success) data = Format.UserAlertSetSearch(res?.data);
    return data;
  };

  const handleChange: (changedData: AlertSetChangeType) => void = async ({
    newData,
    userId,
  }) => {
    const newArr = ValidateFormData(formArr);
    setFormArr(newArr.formData);
    if (!newArr.errorCheck) {
      const payload = {
        practiceId: formArr[0].dropValue,
        ...(userId ? { userId: userId } : {}),
        ...newData,
      };
      const res = await SettingsApi.AlertSetUserUpdate({ data: payload });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        newData.id = CheckNumber(res?.data?.id);
        if (userId) {
          const newArr: AlertSetListType[] = JSON.parse(
            JSON.stringify(userData)
          );
          const userIndex = newArr.findIndex((el) => el.userId == userId);
          const alertIndex = newArr[userIndex].alertSettings.findIndex(
            (el) => el.alertType == newData.alertType
          );
          if (alertIndex == -1) newArr[userIndex].alertSettings.push(newData);
          else newArr[userIndex].alertSettings[alertIndex] = newData;
          setUserData([...newArr]);
        } else {
          const newArr: AlertSetType[] = JSON.parse(
            JSON.stringify(patientData)
          );
          const alertIndex = newArr.findIndex(
            (el) => el.alertType == newData.alertType
          );
          if (alertIndex == -1) newArr.push({ ...newData });
          else newArr[alertIndex] = newData;
          setPatientData([...newArr]);
        }
      }
    }
  };

  return (
    <Box my={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {"RPM Alert Settings"}
        </Typography>
        <Box width="20%">
          {formArr?.map((el: FormDataType) => {
            return (
              <Fragment key={el.id}>
                {commonFormSwitch({
                  formObj: el,
                  onChange: (props) =>
                    setFormArr(HandleFormChange(props, formArr)),
                })}
              </Fragment>
            );
          })}
        </Box>
      </Box>
      <Box my={1}>
        <AlertHeads />
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Box mb={0.5} display={"flex"}>
            <Box width={emptyWidth2} sx={{ ...sxHead }}>
              <Typography variant="subtitle1" fontWeight="bold">
                {"All Patient's Alert Settings"}
              </Typography>
            </Box>
            <AlertRows
              rowData={patientData}
              userId={null}
              handleChange={handleChange}
            />
          </Box>
          <Box display="flex">
            <Typography
              width={emptyWidth2}
              variant="subtitle1"
              fontWeight="bold"
              textAlign="center"
            >
              Staff List
            </Typography>
            <Box width="73%" ml={2} />
          </Box>
          <Box>
            {userData.map((userEl) => {
              return (
                <Box display={"flex"} key={userEl.userId}>
                  <Box sx={{ ...sxHead }} width={emptyWidth}>
                    <Typography variant="subtitle1">
                      {userEl.userFullNAme}
                    </Typography>
                    {!userEl.hasUserAccessToReceiveSMS && (
                      <Tooltip
                        arrow
                        title="Not given verbal consent to receive SMS notification"
                      >
                        <ImgPath.WarningRoundIcon color="warning" />
                      </Tooltip>
                    )}
                  </Box>
                  <Box width={emptyWidth} sx={{ ...sxHead }}>
                    <Typography variant="subtitle1">
                      {userEl.roleName}
                    </Typography>
                  </Box>
                  <AlertRows
                    rowData={userEl.alertSettings}
                    userId={userEl.userId}
                    handleChange={handleChange}
                  />
                </Box>
              );
            })}
          </Box>
        </Fragment>
      )}
      <Typography variant="body2" mt={1}>
        {" "}
        <b>NOTE:</b> Only clinical staff assigned to care plan or treatment plan
        will receive push notifications when set ON
      </Typography>
    </Box>
  );
};
export default AlertSettings;

const sxIconHead = {
  display: "flex",
  justifyContent: "space-between",
  "& h6": {
    px: 1,
  },
  "& svg": {
    px: 1,
  },
};

const sxHead = {
  ...CommonStyle.sxSetCol,
  backgroundColor: "bg.main",
  py: 0.5,
  ...sxIconHead,
};
