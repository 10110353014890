import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { TaskPriorityArr } from "Constant/Arr";
import { Filter2DataProps } from "DataTypes/Common";
import { TaskListType } from "DataTypes/Services/Tasks";
import FilterData from "Components/Filter2/Data";
import { DropdownArrPropsType } from "DataTypes/Form";

export const actionArr: (
  handleView: () => void,
  handleEdit: () => void,
  handleDelete: () => void,
  rowData: TaskListType
) => actionArrType[] = (handleView, handleEdit, handleDelete, rowData) => {
  return [
    ControlledTable.ActionData.viewTitleAction(handleView),
    ...(rowData?.status != "Close" && rowData?.status != "Completed"
      ? [
          ControlledTable.ActionData.editAction({ handleClick: handleEdit }),
          ControlledTable.ActionData.deleteAction(handleDelete),
        ]
      : []),
  ];
};

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.ExclaimationCol("3%"),
  ControlledTable.ColumnData.DateTimeCol1("10%"),
  ControlledTable.ColumnData.TaskTypeCol("10%"),
  ControlledTable.ColumnData.StatusCol("10%"),
  ControlledTable.ColumnData.PriorityCol("10%"),
  ControlledTable.ColumnData.PatientCol("10%"),
  ControlledTable.ColumnData.CreatedByCol("10%"),
  ControlledTable.ColumnData.AssigneeCol("10%"),
  ControlledTable.ColumnData.ActionCol("10%"),
];

export const FilterArr = (
  TypeArr: DropdownArrPropsType[],
  taskStatusList: DropdownArrPropsType[]
): Filter2DataProps[] => {
  return [
    FilterData.taskDate,
    { ...FilterData.taskType, dropArr: TypeArr },
    { ...FilterData.taskStatus, dropArr: taskStatusList },
    { ...FilterData.taskPriority, dropArr: TaskPriorityArr },
    FilterData.taskCreatedBy,
    FilterData.taskCreatedByDesig,
    FilterData.taskAssignee,
    FilterData.taskAssigneeDesig,
    FilterData.patientTask,
  ];
};
