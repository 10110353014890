import {
  ReduxLoadType,
  ReduxScreenNavType,
  ReduxTimerStatusType,
  ScreenActionReturnType,
} from "DataTypes/Redux";
import {
  SET_ALERT,
  CLEAR_ALERT,
  START_SCREEN_LOAD,
  STOP_SCREEN_LOAD,
  CLEAR_SCREEN,
  SET_TIMER_STATUS,
  SET_TIMER_START_DATE,
  SET_TIMER_VALUE,
  SET_NAV_ARR,
  SET_LEFT_HEAD,
  SET_RIGHT_HEAD,
  SET_ONLY_TIMER,
  SET_FORM_LOAD,
} from "./type";
import { AlertProps } from "DataTypes/Common";

export const setAlertData: (data: AlertProps) => ScreenActionReturnType = (
  data
) => {
  return {
    type: SET_ALERT,
    payload: data,
  };
};

export const clearAlertData: () => ScreenActionReturnType = () => {
  return {
    type: CLEAR_ALERT,
  };
};

export const startLoading: (data: ReduxLoadType) => ScreenActionReturnType = (
  data
) => {
  return {
    type: START_SCREEN_LOAD,
    payload: data,
  };
};

export const stopLoading: () => ScreenActionReturnType = () => {
  return {
    type: STOP_SCREEN_LOAD,
  };
};

export const setNavArr: (data: ReduxScreenNavType[]) => any = (data) => {
  return {
    type: SET_NAV_ARR,
    payload: data,
  };
};

export const setLeftHead: (data: any) => any = (data) => {
  return {
    type: SET_LEFT_HEAD,
    payload: data,
  };
};

export const setRightHead: (data: any) => any = (data) => {
  return {
    type: SET_RIGHT_HEAD,
    payload: data,
  };
};

export const setTimerStatus: (data: ReduxTimerStatusType) => any = (data) => {
  return {
    type: SET_TIMER_STATUS,
    payload: data,
  };
};

export const setTimerStartDate: (data: any) => any = (data) => {
  return {
    type: SET_TIMER_START_DATE,
    payload: data,
  };
};

export const setTimeVal: (data: any) => any = (data) => {
  return {
    type: SET_TIMER_VALUE,
    payload: data,
  };
};

export const setIsOnlyTimer: (data: any) => any = (data) => {
  return {
    type: SET_ONLY_TIMER,
    payload: data,
  };
};

export const setFormLoader: (data: any) => any = (data) => {
  return {
    type: SET_FORM_LOAD,
    payload: data,
  };
};

export const clearScreenData: () => ScreenActionReturnType = () => {
  return {
    type: CLEAR_SCREEN,
  };
};
