import { FC, Fragment, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { Loader, SideBarNav } from "Components";
import TimerProvider from "Context/TimerContext";
import { useParams } from "react-router-dom";
import PatientApi from "Service/Patient.api";
import { CheckStringId } from "Utils/common";
import { useNavigate } from "react-router-dom";
import PatientFormatter from "Utils/Patient.Formatter";
import { useDispatch } from "react-redux";
import {
  addPatientInfo,
  addPatientOrgInfo,
  clearPatientInfo,
  clearPatientOrgInfo,
} from "Redux/Patient/Action";
import { RouteUrls } from "Constant/RouteUrls";
import { ScreenInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import CommonFormatter from "Utils/Common.Formatter";

const PatientManagement: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const newPatientId = CheckStringId(patientId);
  const [loading, setLoading] = useState(true);
  const { navArr }: ScreenInitialStateType = useSelector(
    (state: any) => state?.screen
  );

  useEffect(() => {
    return () => {
      dispatch(clearPatientInfo());
      dispatch(clearPatientOrgInfo());
    };
  }, []);

  useEffect(() => {
    fetchPatientInfo();
  }, [patientId]);

  const fetchPatientInfo: () => void = async () => {
    setLoading(true);
    const patientInfoRes = await PatientApi.demographic({ id1: newPatientId });
    const clinicalNotesRes = await PatientApi.PatientClinicalNotesSearch({
      id1: newPatientId,
    });
    let notes = "";
    if (clinicalNotesRes?.success) {
      notes = clinicalNotesRes?.data?.alertNotes;
    }
    if (patientInfoRes?.success) {
      const patientData = { ...patientInfoRes?.data?.patients, notes: notes };
      const formateRes = PatientFormatter.FormatePatientInfo(patientData);
      dispatch(addPatientInfo(formateRes));
      CommonFormatter.HandleNavArr({
        id: "patient-details",
        label: formateRes.name,
        icon: "patient-notes",
        path: `/${RouteUrls.hs}/${RouteUrls.patient}/${formateRes.id}/${RouteUrls.details}`,
        level: 2,
        link: RouteUrls.details,
        navType: "patient",
      });
    } else navigate(-1);
    const patientOrgInfoRes = await PatientApi.practice({ id1: newPatientId });
    if (patientOrgInfoRes?.success) {
      const formateRes = PatientFormatter.FormatPatientOrg(
        patientOrgInfoRes?.data?.practiceLocations
      );
      dispatch(addPatientOrgInfo(formateRes));
    } else navigate(-1);
    setLoading(false);
  };

  return (
    <Box display="flex" flexDirection="row" height="91%">
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <SideBarNav />
          <TimerProvider>
            <Outlet />
          </TimerProvider>
        </Fragment>
      )}
    </Box>
  );
};
export default PatientManagement;
