import { CategoryApiObj } from "DataTypes/Services/Category.type";
import { CheckArr, CheckNumber, CheckStringEmpty } from "./common";

const CheckCategoryObj: (data: any) => CategoryApiObj = (data) => {
  return {
    id: CheckNumber(data?.id),
    name: CheckStringEmpty(data?.name),
    questioncount: CheckNumber(data?.questionCount),
    sortOrder: CheckNumber(data?.sortOrder),
    type: CheckStringEmpty(data?.type),
  };
};

const CheckCategoryArr: (res: any) => CategoryApiObj[] = (res) => {
  const CheckedArr = CheckArr(res);
  const newArr: CategoryApiObj[] = CheckedArr.map((el) => CheckCategoryObj(el));
  return newArr;
};

export default { CheckCategoryObj, CheckCategoryArr };
