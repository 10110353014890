import { FC, useEffect, useState } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Box } from "@mui/material";
import { TextEditorProps } from "DataTypes/Form";

const TextEditor: FC<TextEditorProps> = (props) => {
  const { value, onFocus, onBlur, readOnly, placeholder } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [local, setLocal] = useState("");

  useEffect(() => {
    setEditorState(TextToEditor(value));
    setLocal(value);
  }, [value]);

  const EditorToText: (data: EditorState) => string = (data) => {
    const content = data.getCurrentContent();
    const rawData = convertToRaw(content);
    let val = draftToHtml(rawData);
    if (val == "<p></p>" || val == "<p></p>\n" || val.length < 8) {
      val = "";
    }
    val = val.replaceAll(
      /<ul>/g,
      "<ul style='padding-left: 20px; margin-top: 0px; margin-bottom: 0px'>"
    );
    val = val.replaceAll(
      /<ol>/g,
      "<ol style='padding-left: 20px; margin-top: 0px; margin-bottom: 0px'>"
    );
    val = val.replaceAll(/<li>/g, "<li style='margin-bottom: 8px'>");
    val = val.replaceAll(
      /<p>/g,
      "<p style='margin-top: 0px; margin-bottom: 8px'>"
    );
    val = val.replaceAll(/<br>/g, "<br/>");
    return val;
  };

  const TextToEditor: (data: string) => EditorState = (data) => {
    const contentBlock = htmlToDraft(data);
    const editorData = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
      contentBlock.entityMap
    );
    return EditorState.createWithContent(editorData);
  };

  return (
    <Box
      sx={{
        "&>div": {
          ...sxWrap,
          "&>div:first-of-type": { ...sxToolbar },
          "&>div:last-of-type": {
            ...sxInput,
            "&>div": {
              "&>div": {
                ...(local.length == 0 ? sxPlaceholder : sxText),
              },
            },
          },
        },
      }}
    >
      <Editor
        editorState={editorState}
        onEditorStateChange={(data) => {
          setLocal(EditorToText(data));
          setEditorState(data);
        }}
        toolbar={{
          options: ["inline", "list", "colorPicker"],
          inline: {
            options: ["bold", "italic", "underline", "strikethrough"],
          },
          list: { options: ["unordered", "ordered"] },
        }}
        onBlur={() => onBlur(local)}
        onFocus={onFocus}
        readOnly={readOnly}
        placeholder={local.length == 0 ? placeholder : ""}
      />
    </Box>
  );
};
export default TextEditor;

const sxWrap = {
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: 1.25,
  p: 0.5,
  backgroundColor: "#fff",
};

const sxToolbar = {
  border: "0px",
  m: 0,
  p: 0,
  borderBottom: "1px solid",
  borderBottomColor: "custom.main",
};

const sxInput = {
  border: "0px",
  px: 0.5,
  py: 1,
  minHeight: 70,
  cursor: "text",
  "& div": {
    m: 0,
  },
  "& >div": {
    "& >div": {
      fontFamily: "Poppins",
      fontSize: "0.9rem",
    },
  },
};

const sxText = {
  ":first-of-type": {
    color: "primary.main",
  },
};

const sxPlaceholder = {
  ":first-of-type": {
    color: "#B1B1B1",
  },
};
