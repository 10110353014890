import { AppointmentTypeType } from "DataTypes/PatientManagement.type";
import {
  CheckArr,
  CheckBoolean,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "./common";
import DateTime from "./DateTime";
import PatientFormatter from "./Patient.Formatter";
import { AppointmentType } from "DataTypes/Services/Patient.type";

const ReadAppointmemt: (data: any) => AppointmentType = (data) => {
  const careId = CheckNumber(data?.careplanId)
  const providerName = CheckStringEmpty(
    careId ? data?.teamProviderName : data?.providerName
  );
  const providerDes = CheckStringEmpty(
    careId ? data?.teamProviderDesignation : ""
  );
  const provider = providerName
    ? `${providerName}${providerDes ? ", " + providerDes : ""}`
    : ""
  return {
    ...PatientFormatter.ListPatientInfo1(data),
    ...PatientFormatter.CheckPatientDevice(data),
    activity: CheckStringEmpty(data?.appointmentType),
    appointmentType: "",
    appointmentTimzone: CheckStringEmpty(data?.timeZone),
    careplanId: careId,
    careplanRecurringId: CheckNumber(data?.careplanRecurringId),
    careplanState: "",
    clinicalStaff: "",
    datetime: DateTime.ValidDate(data?.appointmentDate),
    id: CheckNumber(data?.id),
    importance: "",
    notes: CheckStringEmpty(data?.notes),
    patientId: CheckStringId(data?.patientId),
    practiceId: CheckNumber(data?.practiceId),
    practice: CheckStringEmpty(data?.practiceName),
    programId: CheckNumber(data?.programId),
    provider,
    providerName: provider,
    timezone: CheckStringEmpty(data?.timeZone),
  };
};

const TypeArr: (data: any) => AppointmentTypeType[] = (data) => {
  return CheckArr(data).map((el) => Type(el));
};

const Type: (data: any) => AppointmentTypeType = (data) => {
  const providerName = CheckStringEmpty(data?.providerName);
  const providerDes = CheckStringEmpty(data?.providerDesignation);
  const appointmentType = CheckStringEmpty(data?.appointmentType);
  const providerNameDes = providerName
    ? providerName + `${providerDes ? ", " + providerDes : ""}`
    : "";
  return {
    abbrevation: CheckStringEmpty(data?.abbrevation),
    appointmentType,
    billableproviderId: CheckNumber(data?.billableproviderId),
    //string check on call reminder appointment types to forcefully 
    // send care plan id and recurring id null for inactive programs 
    // in appointment creation
    careplanId: appointmentType.toLowerCase().includes("call reminder")
      ? null
      : CheckNumber(data?.careplanId),
    //string check on call reminder appointment types to forcefully 
    // send care plan id and recurring id null for inactive programs 
    // in appointment creation
    careplanRecurringId: appointmentType.toLowerCase().includes("call reminder")
      ? null
      : CheckNumber(data?.careplanrecurringId),
    isRpmTraining: CheckBoolean(data?.isRpmTraining),
    programId: CheckNumber(data?.programId),
    providerId: CheckNumber(data?.providerId),
    providerName,
    providerNameDes,
  };
};

export default {
  ReadAppointmemt,
  TypeArr,
  Type,
};
