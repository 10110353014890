export const setLocalStorage: (name: string, value: any) => void = (
  name,
  value
) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const getLocalStorage: (name: string) => void = (name) => {
  const value = localStorage.getItem(name);
  if (value) {
    return JSON.parse(value);
  }
  return "";
};
