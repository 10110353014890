import {
  QuestionVisitType,
  VisitPrgmType,
} from "DataTypes/Services/Visit.type";
import {
  AscendSortByNumber,
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
} from "./common";
import { DropdownArrPropsType } from "DataTypes/Form";

const QuestionVisit: (data: any) => QuestionVisitType = (data) => {
  return {
    id: CheckNumber(data?.id),
    name: CheckStringEmpty(data?.name),
    sequenceNumber: CheckNumber(data?.sequenceNumber),
  };
};

const QuestionVisitArr: (data: any) => QuestionVisitType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: QuestionVisitType[] = CheckedArr.map((el) => QuestionVisit(el));
  return AscendSortByNumber(newArr, "sequenceNumber");
};

const VisitPrgmArr: (
  data: QuestionVisitType[],
  prgmArr: DropdownArrPropsType[]
) => VisitPrgmType[] = (data, prgmArr) => {
  const newArr: VisitPrgmType[] = data.map((el) => {
    const find = prgmArr.find((prgmEl) => el.name?.includes(prgmEl.label));
    return {
      ...el,
      prgmId: typeof find?.value == "number" ? find?.value : null,
    };
  });
  return newArr;
};

export default { QuestionVisit, QuestionVisitArr, VisitPrgmArr };
