import { ApiServiceProps } from "DataTypes/Services/Index.type";
import { post } from ".";

const list: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: "question/search",
    data: {
      data: data.data,
      criterion: {
        offset: data.offset,
        limit: data.limit,
      },
    },
  };
  return post(request);
};

const create: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: "question/create",
    data: { data: data.data },
  };
  return post(request);
};

const read: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `question/${props.id1}/read`,
  };
  return post(request);
};

const update: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `question/${data.id1}/update`,
    data: { data: data.data },
  };
  return post(request);
};

const deleteQues: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `question/${data.id1}/delete`,
  };
  return post(request);
};

const deleteOption: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `question/option/${data.id1}/delete`,
  };
  return post(request);
};

export default {
  list,
  create,
  read,
  update,
  deleteQues,
  deleteOption,
};
