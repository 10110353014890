import { Box, Typography } from "@mui/material";
import ColorData1 from "CstmTheme/ColorData";
import { CPSummaryV3QuesType } from "DataTypes/Services/Careplan.type";
import { FC, Fragment } from "react";

const RenderAns: FC<{
  data: CPSummaryV3QuesType,
  pgmName?: string
}> = ({ data, pgmName }) => {
  return (
    <Fragment>
      <Box
        sx={{
          color: ColorData1.primary.main,
          "& ul": { pl: 2.5, my: 0 },
          "& ol": { pl: 2.5, my: 0 },
          "& li": { mb: 1 },
        }}
      >
        {pgmName == "TCM" ?
          <Typography variant="subtitle1" fontWeight="bold">
            {data.quesText}
          </Typography>
          :
          <Fragment />
        }
        <ul>
          {data.optList.map((optEl) => {
            return (
              <li key={optEl.id}>
                <Typography variant="subtitle1">{optEl.optText}</Typography>
                {optEl.subQues.map((quesEl) => (
                  <RenderAns key={quesEl.id} data={quesEl} />
                ))}
              </li>
            );
          })}
          {data.freeText && (
            <li>
              <Typography variant="subtitle1">{data.freeText}</Typography>
            </li>
          )}
        </ul>
      </Box>
      {data.quesComment && (
        <div
          style={{
            fontSize: "0.8rem",
            color: ColorData1.primary.main,
            fontFamily: "Poppins",
          }}
          dangerouslySetInnerHTML={{
            __html: data.quesComment,
          }}
        />
      )}
    </Fragment>
  );
};
export default RenderAns;
