import { DropdownArrPropsType } from "DataTypes/Form";

export const AnsDropArr = [
  {
    id: 1,
    label: "Single Select",
    value: "singleSelect",
  },
  {
    id: 2,
    label: "Multi Select",
    value: "multiSelect",
  },
  {
    id: 3,
    label: "Free Text",
    value: "freeText",
  },
];

export const PageLimitArr: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "10",
    value: 10,
  },
  {
    id: 2,
    label: "25",
    value: 25,
  },
  {
    id: 3,
    label: "50",
    value: 50,
  },
  {
    id: 4,
    label: "75",
    value: 75,
  },
];

export const LimitArr2: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "100",
    value: 100,
  },
  {
    id: 2,
    label: "250",
    value: 250,
  },
  {
    id: 3,
    label: "500",
    value: 500,
  },
  {
    id: 4,
    label: "1000",
    value: 1000,
  },
];
