import { FC, useState, useEffect, Fragment } from "react";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { CommonStyle, ControlledTable } from "Components";
import { ModalLayout, TableLayout } from "Layouts";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { actionArr, FormDataArr, TableCol } from "./Data";
import { useDispatch } from "react-redux";
import {
  setAlertData,
  setLeftHead,
  setNavArr,
  startLoading,
  stopLoading,
} from "Redux/Screen/Action";
import {
  PatientInitialStateType,
  ScreenInitialStateType,
} from "DataTypes/Redux";
import TrainingApi from "Service/Training.api";
import TrainingFormatter from "Utils/Training.Formatter";
import { TrainingNotesListType } from "DataTypes/Services/Training.type";
import CstmBtn from "Components/CstmBtn";
import { CheckNumber, HandleFormChange, ValidateFormData } from "Utils/common";
import { commonFormSwitch } from "Utils/SwitchCases";
import { FormDataType } from "DataTypes/Form";
import { CommonButtonArray, SingleBtnArray } from "Components/Modal/Data";
import { RouteUrls } from "Constant/RouteUrls";
import ImgPath from "Constant/Imgs";
import DeviceApi from "Service/Device.api";
import PatientFormatter from "Utils/Patient.Formatter";
import { DeviceReadingType } from "DataTypes/Services/Patient.type";
import { label } from "Constant/En";
import DateTime from "Utils/DateTime";
import CommonFormatter from "Utils/Common.Formatter";

const TrainingDeviceNotes: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientInfo, selectedDevice }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { navArr }: ScreenInitialStateType = useSelector(
    (state: any) => state?.screen
  );
  const { patientDeviceId, trainingId } = useParams();
  const [rowData, setRowData] = useState<TrainingNotesListType[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const [formArr, setFormArr] = useState<FormDataType[]>(FormDataArr());
  const [deleteModal, setDeleteModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [headHeight, setHeadHeight] = useState(20);
  const [infoHeight, setInfoHeight] = useState(20);
  const [readmoreIds, setReadmoreIds] = useState<string[]>([]);
  const [clickedRow, setClickedRow] = useState<TrainingNotesListType | null>(
    null
  );
  const [edit, setEdit] = useState<number | null>(null);
  const [reading, setReading] = useState<DeviceReadingType[]>([]);
  const [readLoad, setReadLoad] = useState(true);
  const offsetHead = document?.getElementById("head")?.offsetHeight;
  const offsetInfo = document?.getElementById("info")?.offsetHeight;
  const tableHeight = `calc(99% - ${headHeight + infoHeight}px - 32px)`;
  const isView = trainingId ? true : false;

  useEffect(() => {
    handleSetData(page, limit);
    formArr[0].required = true;
    handleFirstReading();
    CommonFormatter.HandleNavArr({
      id: "training-notes",
      label: "Notes",
      path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.device}/${patientDeviceId}/${RouteUrls.trainingNotes}`,
      level: 6,
      link: RouteUrls.trainingNotes,
      navType: "patient",
    });
    return () => {
      dispatch(setNavArr([...navArr.filter((el) => el.level < 6)]));
      dispatch(setLeftHead(""));
    };
  }, []);

  useEffect(() => {
    setHeadHeight(offsetHead || 0);
  }, [offsetHead]);

  useEffect(() => {
    setInfoHeight(offsetInfo || 0);
  }, [offsetInfo]);

  const handleFirstReading: () => void = async () => {
    setReadLoad(true);
    const res = await DeviceApi.DeviceFirstReading({
      id1: selectedDevice.patientDeviceId,
    });
    if (res?.success) {
      setReading(PatientFormatter.FormatDeviceReading(res?.data));
    }
    setReadLoad(false);
  };

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const res: any = await TrainingApi.trainingNotesList({
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
      id1: patientDeviceId,
    });
    if (res?.success) {
      setRowData(TrainingFormatter.TrainingNotesList(res?.data));
      setTotalRecord(res?.criterion?.total);
      setPage(res?.criterion?.page);
      setLimit(res?.criterion?.limit);
    }
    setLoading(false);
  };

  const handleEdit = (data: TrainingNotesListType) => {
    setEdit(data.id);
    const updatedFormArr = [...formArr];
    updatedFormArr[0].value = data.tnotes;
    updatedFormArr[0].dropValue = data.tnotes;
    setFormArr([...updatedFormArr]);
  };

  const handleDelete = async (
    data: TrainingNotesListType | null
  ): Promise<void> => {
    if (data) {
      const id = CheckNumber(data.id);
      if (id) {
        dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
        const res = await TrainingApi.trainingNoteDelete({
          id1: id,
        });
        if (res?.success) {
          handleSetData(page, limit);
          dispatch(setAlertData(res?.alert));
          setDeleteModal(false);
          dispatch(stopLoading());
        }
      }
    }
  };

  const handleCompleteSuccesfully = (): void => {
    navigate(
      `../../../../${RouteUrls.training}/${RouteUrls.completedTraining}`
    );
  };

  const handleReadMore = (id: number | null): void => {
    setReadmoreIds((prevIds) => {
      if (prevIds.includes(id?.toString() ?? "")) {
        return prevIds.filter((prevId) => prevId !== (id?.toString() ?? ""));
      } else {
        return [...prevIds, id?.toString() ?? ""];
      }
    });
  };
  const checkReadMore = (id: number | null): boolean => {
    return !readmoreIds.includes(id?.toString() ?? "");
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={(currentRow: any) =>
        TableCol(
          isView,
          handleReadMore,
          currentRow.readMoreLength,
          checkReadMore
        )
      }
      tableRow={rowData}
      tableAction={(rowData) =>
        actionArr(
          () => handleEdit(rowData),
          () => {
            setClickedRow(rowData);
            setDeleteModal(true);
          }
        )
      }
    />
  );

  const handleSave = async (val: string) => {
    const newArr = ValidateFormData(formArr);
    setFormArr(newArr.formData);
    if (!newArr.errorCheck) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const payload = {
        ...(edit && { id: edit }),
        note: newArr.formData[0].dropValue,
      };
      const res = await TrainingApi.updateTrainingNotes({
        id1: trainingId,
        data: payload,
      });
      if (res?.success) {
        handleSetData(page, limit);
        dispatch(setAlertData(res?.alert));

        if (val === "Complete") {
          const payload1 = {
            trainingStatusId: "TRANING_COMPLETED",
          };
          const res = await TrainingApi.trainingStatusUpdate({
            id1: trainingId,
            data: payload1,
          });
          if (res?.success) {
            dispatch(setAlertData(res?.alert));
            setCompleteModal(true);
          }
        }
      }
      dispatch(stopLoading());
      const updatedFormArr = [...formArr];
      updatedFormArr[0].value = "";
      setFormArr([...updatedFormArr]);
      setEdit(null);
    }
  };

  return (
    <Fragment>
      <Box display="flex" justifyContent="space-between" pt={1} id="info">
        <Box mr={4}>
          <Typography variant="subtitle1" color="custom.dark">
            Device Name
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {selectedDevice.deviceName}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            "& >div": {
              px: 2,
              borderRight: "1px solid",
              borderColor: "custom.main",
              ":last-of-type": {
                border: "0px",
              },
            },
          }}
        >
          <Box>
            <Typography variant="subtitle1" color="custom.dark">
              First Reading
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {reading.length > 0
                ? DateTime.ToLocalDateTime(reading[0].recordedTime)
                : label.naText}
            </Typography>
          </Box>
          {readLoad ? (
            <CircularProgress size={30} />
          ) : (
            reading.map((readEl) => {
              return (
                <Box key={readEl.readingId}>
                  <Typography variant="subtitle1" color="custom.dark">
                    {readEl.readingTypeDescription}
                  </Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {readEl.value
                      ? `${Math.ceil(parseFloat(readEl.value))} ${
                        readEl.measureUomAbbreviation
                      }`
                      : label.naText}
                  </Typography>
                </Box>
              );
            })
          )}
          {isView && (
            <IconButton
              title="Refesh"
              sx={{ ...CommonStyle.sxRoundIconsMainSm, ml: 2 }}
              onClick={handleFirstReading}
            >
              <ImgPath.RefreshIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      {isView && (
        <Box id="head" pb={1}>
          <Box display="flex" justifyContent="space-between">
            <Box
              width="90%"
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              {formArr?.map((el: FormDataType) => {
                return (
                  <Fragment key={el.id}>
                    {commonFormSwitch({
                      formObj: el,
                      onChange: (props) =>
                        setFormArr(HandleFormChange(props, formArr)),
                    })}
                  </Fragment>
                );
              })}
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={"100%"}
              sx={{ "&>button": { mt: 4 } }}
            >
              <CstmBtn
                label="Save"
                sxProps={{ ml: 1 }}
                onClick={() => handleSave("Save")}
              />
              <CstmBtn
                label="Complete"
                onClick={() => handleSave("Complete")}
                sxProps={{ ml: 1 }}
                disable={reading.length < 1}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box height={tableHeight} sx={{ "&>div:first-of-type": { py: 2 } }}>
        <TableLayout
          tblBody={TblBodyJSX}
          tblHead={ControlledTable.TblHead(
            TableCol(isView, handleReadMore, 50, checkReadMore)
          )}
          loading={loading}
          height={"100%"}
          Pagination={
            <ControlledTable.Pagination
              mainPage={page}
              limit={limit}
              total={totalRecord}
              handleClick={handleSetData}
              tableStyle={true}
            />
          }
        />
      </Box>
      <ModalLayout
        modwidth="30%"
        heading={""}
        content={
          <Typography>
            Device Training to the Patient has been completed successfully.
          </Typography>
        }
        open={completeModal}
        buttonArr={SingleBtnArray(handleCompleteSuccesfully)}
      />

      <ModalLayout
        modwidth="30%"
        heading={"Warning"}
        content={<Typography>Do you want to delete training note?</Typography>}
        open={deleteModal}
        buttonArr={CommonButtonArray(
          () => handleDelete(clickedRow),
          () => setDeleteModal(false),
          "Yes",
          "No"
        )}
      />
    </Fragment>
  );
};
export default TrainingDeviceNotes;
