import { post } from ".";
import { ApiServiceProps } from "DataTypes/Services/Index.type";

const search: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/search`,
  };
  return post(request);
};

const careplanProblemUpdate: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/problem/update`,
    data: { data: payload.data },
  };
  return post(request);
};

const careplanProblemsRead: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/problem/search`,
  };
  return post(request);
};

const QuestionSearch: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `careplan/${data.id1}/question/search`,
    data: { data: data.data },
  };
  return post(request);
};

const QuesOptSearch: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `careplan/${data.id1}/question-option/search`,
    data: { data: data.data },
  };
  return post(request);
};

const QuesUpdate: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: `careplan/${data.id1}/question/update`,
    data: { data: data.data },
  };
  return post(request);
};

const careplanSummary: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/summary`,
  };
  return post(request);
};

const careplanRecurringsearch: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/recurring/${props?.id1}/search`,
  };
  return post(request);
};

const careplanSubmit: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/submit`,
    data: { data: props.data },
  };
  return post(request);
};

const SummarySave: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/save`,
    data: { data: props.data },
  };
  return post(request);
};

const CareplanPdfGenerate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/summarypdf`,
    data: { data: props.data },
  };
  return post(request);
};

const TcmPdfGenerate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/tcm-summarypdf`,
    data: { data: props.data },
  };
  return post(request);
};

const RecurringCPPdfGenerate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/recurring-summarypdf`,
    data: { data: props.data },
  };
  return post(request);
};

const RecurringCPSubmit: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/recurring/${props.id1}/submit`,
    data: { data: props.data },
  };
  return post(request);
};

const CpNotes: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/recurringnotes/search`,
  };
  return post(request);
};

const CPRecurringPDFDownload: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/recurring-summarypdf`,
    excel: true,
  };
  return post(request);
};

const CpQuestionStatusUpdate: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/questionarie-status/update`,
    data: { data: payload.data },
  };
  return post(request);
};

const RecurringCpQuestionStatusUpdate: (payload: ApiServiceProps) => any = (
  payload
) => {
  const request = {
    subUrl:
      `careplan/careplan-recurring/${payload.id1}` +
      "/questionarie-status/update",
    data: { data: payload.data },
  };
  return post(request);
};

const DeviceTypeSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `device-type/problem/${payload.id1}/search`,
  };
  return post(request);
};

const DeviceSuspend: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient-device/${payload.id1}/suspend`,
  };
  return post(request);
};

const PatientDeviceSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient-device/${payload.id1}/search`,
    data: { data: payload.data },
  };
  return post(request);
};

const DeviceReminderSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "reading-reminder/search",
    data: { data: payload.data },
  };
  return post(request);
};

const TrainingTypeSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "training-type/search",
    data: { data: payload.data },
  };
  return post(request);
};

const AddPatientDevice: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient-device/create",
    data: { data: payload.data },
  };
  return post(request);
};

const UpdatePatientDevice: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "patient-device/update",
    data: { data: payload.data },
  };
  return post(request);
};

const CPStateUpdate: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/state/update`,
    data: { data: payload.data },
  };
  return post(request);
};

const CPSummaryV3: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/v3/summary`,
  };
  return post(request);
};

const TCMSummaryV2: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/v2/tcm-summary`,
  };
  return post(request);
};

const CopyGlobalToPatient: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `threshold/${payload.id1}/copy-global-to-patient`,
    data: { data: payload.data },
  };
  return post(request);
};

const CPSummaryV2Pdf: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/v2/summarypdf`,
    data: { data: props.data },
  };
  return post(request);
};

const TCMSummaryV2Pdf: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/v2/tcm-summarypdf`,
    data: { data: props.data },
  };
  return post(request);
};

const RCPSummaryV2Pdf: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/v2/recurring-summarypdf`,
    data: { data: props.data },
  };
  return post(request);
};

const CPInteractiveSearch: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/interactive-communication/search`,
  };
  return post(request);
};

const CPInteractiveUpdate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/interactive-communication/update`,
    data: { data: props.data },
  };
  return post(request);
};

const CPMedicallySearch: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/medically-necessary/search`,
  };
  return post(request);
};

const CPMedicallyUpdate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/medically-necessary/update`,
    data: { data: props.data },
  };
  return post(request);
};

const CP30SecSerach: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `timelog/${props.id1}/time-spent`,
    data: { data: props.data },
  };
  return post(request);
};

const RecNotesSearch: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/${props.id2}/recurring-notes/search`,
    data: {
      data: props.data,
      criterion: {
        offset: props?.offset,
        limit: props.limit,
      },
    },
  };
  return post(request);
};

const RecNotesUpdate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/${props.id2}/recurring-notes/update`,
    data: { data: props.data },
  };
  return post(request);
};

const RecMedSearch: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/${props.id2}/medically-necessary/search`,
  };
  return post(request);
};

const RecMedUpdate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/${props.id2}/medically-necessary/update`,
    data: { data: props.data },
  };
  return post(request);
};

const RecpdfUpdate: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/v2/recurring-rpm-pdf`,
    data: { data: {} },
  };
  return post(request);
};

const RecurringCompletion: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/recurring/${props.id1}/complete`,
  };
  return post(request);
};

const GenericInfo: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: "careplan/generic-info/search",
    data: { data: props.data },
  };
  return post(request);
};

const CPTeamSearch: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/team/search`,
  };
  return post(request);
};

const DeleteAddProvider: (props: ApiServiceProps) => any = (props) => {
  const request = {
    subUrl: `careplan/${props.id1}/additionalprovider/${props.id2}/delete`,
  };
  return post(request);
};

const CPTeamSave: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/team/update`,
    data: payload.data,
  };
  return post(request);
};

const CPPDFDownload: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `careplan/${payload.id1}/getPDF/${payload.subUrl}`,
    excel: true,
  };
  return post(request);
};

const ConsentPDFDownload: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `patient/${payload.id1}/getPDF/${payload.subUrl}`,
    excel: true,
  };
  return post(request);
};

export default {
  search,
  careplanProblemUpdate,
  careplanProblemsRead,
  QuestionSearch,
  QuesOptSearch,
  QuesUpdate,
  careplanSubmit,
  careplanSummary,
  CpNotes,

  CpQuestionStatusUpdate,
  CPRecurringPDFDownload,
  RecurringCpQuestionStatusUpdate,
  RecurringCPSubmit,
  CareplanPdfGenerate,
  RecurringCPPdfGenerate,
  TcmPdfGenerate,
  careplanRecurringsearch,

  //New Changes
  SummarySave,
  DeviceTypeSearch,
  DeviceSuspend,
  PatientDeviceSearch,
  DeviceReminderSearch,
  TrainingTypeSearch,
  AddPatientDevice,
  UpdatePatientDevice,
  CPStateUpdate,
  CPSummaryV3,
  TCMSummaryV2,
  CPSummaryV2Pdf,
  RCPSummaryV2Pdf,
  TCMSummaryV2Pdf,
  CopyGlobalToPatient,
  CPInteractiveSearch,
  CPInteractiveUpdate,
  CPMedicallySearch,
  CPMedicallyUpdate,
  CP30SecSerach,
  CPTeamSearch,
  DeleteAddProvider,
  CPTeamSave,
  CPPDFDownload,
  ConsentPDFDownload,

  //Recurring
  RecNotesSearch,
  RecNotesUpdate,
  RecMedSearch,
  RecMedUpdate,
  RecpdfUpdate,
  RecurringCompletion,
  GenericInfo,
};
