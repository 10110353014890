import {
  EligiblePrgmListType,
  InsuranceListResType,
  PrgmListResType,
  QuestionPrgmType,
} from "DataTypes/Services/Prgm.type";
import {
  AscendSortByNumber,
  CheckArr,
  CheckBoolean,
  CheckNumber,
  CheckStringEmpty,
} from "./common";

const PrgmListRes: (res: any) => PrgmListResType[] = (res) => {
  const newArr = CheckArr(res).map((el) => {
    return {
      id: CheckNumber(el?.id),
      name: CheckStringEmpty(el?.name),
      abbrevation: CheckStringEmpty(el?.abbrevation),
      isDeleted: CheckBoolean(el?.isDeleted),
    };
  });
  return AscendSortByNumber(newArr, "id");
};

const InsuranceListRes: (res: any) => InsuranceListResType[] = (res) => {
  const newArr = CheckArr(res);
  const newArr1 =
    newArr.map((el) => {
      return {
        companyId: CheckStringEmpty(el.companyId),
        companyName: CheckStringEmpty(el.companyName),
        insuranceId: CheckNumber(el.insuranceId),
        planId: CheckStringEmpty(el.planId),
        planName: CheckStringEmpty(el.planName),
      };
    }) || [];
  return newArr1;
};

const InsuranceFormatRes: (res: any) => InsuranceListResType[] = (res) => {
  const checkedArr = CheckArr(res);
  return checkedArr.map((el) => {
    return {
      companyId: CheckStringEmpty(el.companyId),
      companyName: CheckStringEmpty(el.companyName),
      insuranceId: CheckNumber(el.id),
      planId: CheckStringEmpty(el.planId),
      planName: CheckStringEmpty(el.planName),
    };
  });
};

const EligiblePrgmList: (res: any) => EligiblePrgmListType = (res) => {
  return {
    eligibleProgramId: CheckNumber(res?.eligible_program_id),
    abbrevation: CheckStringEmpty(res?.abbrevation),
    activity: CheckStringEmpty(res?.activity),
    careplanId: CheckStringEmpty(res?.careplan_id),
    careplanState: CheckStringEmpty(res?.careplan_state),
    enrollmentId: CheckNumber(res?.enrollment_id),
    name: CheckStringEmpty(res?.name),
    nextSchedule: CheckStringEmpty(res?.next_schedule),
    program_id: CheckNumber(res?.program_id),
    status: CheckStringEmpty(res?.status),
  };
};

const QuestionPrgm: (data: any) => QuestionPrgmType = (data) => {
  return {
    id: CheckNumber(data?.id),
    name: CheckStringEmpty(data?.name),
  };
};

const QuestionPrgmArr: (data: any) => QuestionPrgmType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: QuestionPrgmType[] = CheckedArr.map((el) => QuestionPrgm(el));
  return AscendSortByNumber(newArr, "id");
};

export default {
  PrgmListRes,
  InsuranceListRes,
  EligiblePrgmList,
  QuestionPrgm,
  QuestionPrgmArr,
  InsuranceFormatRes,
};
