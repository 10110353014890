import { FC, Fragment } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Radio,
  Typography,
} from "@mui/material";
import { CommonStyle, ControlledForm } from "Components";
import {
  CPQuesBoxProps,
  CPQuesOptType,
} from "DataTypes/PatientManagement.type";
import ControlledAccord from "Components/Accordion1/Controlled";
import ImgPath from "Constant/Imgs";
import NoData from "Components/NoData";
import PatientFormatter from "Utils/Patient.Formatter";
import Stages from "Constant/Stages";
import TextEditor from "Components/TextEditor";

const QuestionBox: FC<CPQuesBoxProps> = (props) => {
  const {
    catQuesOpt,
    handleQuestionClick,
    openQuestionIds,
    handleOptionClick,
    handleChangeText,
    handleUpdateText,
    handleSave,
    loadingOptQuesId,
    setLocalInput,
    localInput,
    readOnly,
    selectedPrgm,
    pressedNext,
  } = props;

  const MainContent: (props: CPQuesOptType) => JSX.Element = (props) => (
    <Box display={"flex"}>
      <Typography variant="subtitle1" sx={{ wordBreak: "break-all" }}>
        {props.question}
      </Typography>
      {props.requiredQuestion && (
        <Typography variant="subtitle1" color="red">
          *
        </Typography>
      )}
    </Box>
  );

  const SubContent: (props: CPQuesOptType) => JSX.Element = (props) => (
    <Fragment>
      {props.questionType == "singleSelect" ||
        props.questionType == "multiSelect" ? (
          <Fragment>
            {loadingOptQuesId == props.questionsId ? (
              <CircularProgress size={20} />
            ) : (
              props.options.length > 0 && (
                <Fragment>
                  {props.options.map((optEl, index) => {
                    let filteredArr: CPQuesOptType[] = [];
                    if (catQuesOpt) {
                      filteredArr = catQuesOpt.questionList.filter(
                        (quesEl) => quesEl.parentOptionId == optEl.id
                      );
                    }
                    return (
                      <Fragment key={`${optEl.id} ${index}`}>
                        <Box
                          key={`${optEl.id} ${index}`}
                          {...(!readOnly && {
                            onClick: () => handleOptionClick(props, optEl.id),
                          })}
                          sx={{
                            cursor: readOnly ? "default" : "pointer",
                            ...CommonStyle.sxRow,
                          }}
                        >
                          {props.questionType == "multiSelect" && (
                            <Checkbox
                              checked={optEl.answered}
                              color="secondary"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: "1rem",
                                },
                              }}
                              disabled={readOnly}
                            />
                          )}
                          {props.questionType == "singleSelect" && (
                            <Radio
                              checked={optEl.answered}
                              color="secondary"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: "1rem",
                                },
                              }}
                              disabled={readOnly}
                            />
                          )}
                          <Box sx={{ ...CommonStyle.sxRow }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ wordBreak: "break-all" }}
                            >
                              {optEl.option}
                            </Typography>
                            {optEl.requiredOption && (
                              <Typography variant="subtitle1" color="red">
                              *
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {filteredArr.length > 0 &&
                        optEl.answered &&
                        filteredArr.map((filEl) => {
                          const find = openQuestionIds.find(
                            (openQuesEl) => openQuesEl == filEl.questionsId
                          );
                          return (
                            <ControlledAccord
                              key={filEl.questionsId}
                              open={find ? true : false}
                              head={MainContent(filEl)}
                              body={find ? SubContent(filEl) : <></>}
                              {...(pressedNext &&
                                filEl.requiredQuestion &&
                                (!filEl.isAnswered ||
                                  !filEl.isRequiredOptionAnswered) && {
                                arrowColor: "red",
                              })}
                              {...(filEl.isAnswered &&
                                filEl.requiredQuestion &&
                                !filEl.isRequiredOptionAnswered && {
                                arrowColor: "red",
                              })}
                              {...(filEl.isAnswered &&
                                filEl.requiredQuestion &&
                                filEl.isRequiredOptionAnswered && {
                                arrowColor: "green",
                              })}
                              {...(filEl.isAnswered &&
                                !filEl.requiredQuestion && {
                                arrowColor: "green",
                              })}
                              handleClick={() => handleQuestionClick(filEl)}
                              roundIcon={true}
                              style={{ ml: "32px !important" }}
                            />
                          );
                        })}
                      </Fragment>
                    );
                  })}
                </Fragment>
              )
            )}
          </Fragment>
        ) : (
          <Box width="100%">
            <ControlledForm.Input
              id="FreeTextBox"
              placeholder="Enter Answer"
              value={props.freeTextAnswer}
              onChange={(el) =>
                handleChangeText(
                  props.questionsId,
                  typeof el == "string" ? el : "",
                  "answer"
                )
              }
              onBlurChange={() => {
                props.freeTextAnswer != localInput &&
                handleUpdateText(
                  props,
                  JSON.parse(JSON.stringify(localInput)),
                  "answer"
                );
              }}
              onFocusChange={() => setLocalInput(props.freeTextAnswer)}
              readOnly={readOnly}
              multiline
            />
          </Box>
        )}
      {/* {props.questionType != "freeText" && (
        <Box width="100%" mt={1}>
          <ControlledForm.Input
            id="CommonBox"
            placeholder="Enter Comments"
            value={props.questionComment}
            onChange={(el) =>
              handleChangeText(
                props.questionsId,
                typeof el == "string" ? el : "",
                "comment"
              )
            }
            onBlurChange={() => {
              props.questionComment != localInput &&
                handleUpdateText(
                  props,
                  JSON.parse(JSON.stringify(localInput)),
                  "comment"
                );
            }}
            onFocusChange={() => setLocalInput(props.questionComment)}
            readOnly={readOnly}
            multiline
          />
        </Box>
      )} */}
      <Box width="100%" mt={1}>
        <TextEditor
          onBlur={(el) => {
            el != localInput &&
              handleUpdateText(
                { ...props, questionComment: el },
                JSON.parse(JSON.stringify(localInput)),
                "comment"
              );
          }}
          // onChange={(el) =>
          //   handleChangeText(
          //     props.questionsId,
          //     typeof el == "string" ? el : "",
          //     "comment"
          //   )
          // }
          onFocus={() => setLocalInput(props.questionComment)}
          placeholder="Enter Comments"
          value={props.questionComment}
          readOnly={readOnly}
        />
      </Box>
    </Fragment>
  );

  const NullFilterArr: CPQuesOptType[] = catQuesOpt
    ? catQuesOpt.questionList.filter(
      (el) => el.parentOptionId == null && el.parentQuestionId == null
    )
    : [];

  return (
    <Box width="66%" height="100%">
      {catQuesOpt ? (
        <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
          <Box
            sx={{
              ...CommonStyle.sxRow,
              ...sxQuestionHead,
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{ ...CommonStyle.sxRow }}
            >
              {`Questions > ${catQuesOpt.category}`}
            </Typography>
            {readOnly ? (
              (selectedPrgm?.careplanStage || 0) >
              PatientFormatter.GetCPStage(Stages.CPQues) && (
                <Box
                  onClick={handleSave}
                  sx={{
                    ...CommonStyle.sxRoundIconsMainSm,
                    mr: 1,
                    p: 0.1,
                    "&>svg": {
                      rotate: "180deg",
                    },
                  }}
                >
                  <ImgPath.BreadcrumbIcon
                    titleAccess="Next"
                    sx={{ width: "0.9rem" }}
                  />
                </Box>
              )
            ) : (
              <Box
                onClick={handleSave}
                sx={{
                  ...CommonStyle.sxRoundIconsMainSm,
                  mr: 1,
                  p: 0.1,
                  "&>svg": {
                    rotate: "180deg",
                  },
                }}
              >
                <ImgPath.BreadcrumbIcon
                  titleAccess="Save & Next"
                  sx={{ width: "0.9rem" }}
                />
              </Box>
            )}
          </Box>

          <Box height="90%" overflow="auto" sx={{ px: 2 }}>
            {NullFilterArr.length > 0 ? (
              NullFilterArr.map((el) => {
                const find = openQuestionIds.find(
                  (openQuesEl) => openQuesEl == el.questionsId
                );
                return (
                  <ControlledAccord
                    key={el.questionsId}
                    open={find ? true : false}
                    head={MainContent(el)}
                    body={find ? SubContent(el) : <></>}
                    {...(pressedNext &&
                      el.requiredQuestion &&
                      (!el.isAnswered || !el.isRequiredOptionAnswered) && {
                      arrowColor: "red",
                    })}
                    {...(el.isAnswered &&
                      el.requiredQuestion &&
                      !el.isRequiredOptionAnswered && { arrowColor: "red" })}
                    {...(el.isAnswered &&
                      el.requiredQuestion &&
                      el.isRequiredOptionAnswered && { arrowColor: "green" })}
                    {...(el.isAnswered &&
                      !el.requiredQuestion && { arrowColor: "green" })}
                    handleClick={() => handleQuestionClick(el)}
                    roundIcon={true}
                  />
                );
              })
            ) : (
              <NoData />
            )}
          </Box>
        </Box>
      ) : (
        <NoData />
      )}
    </Box>
  );
};
export default QuestionBox;

const sxQuestionHead = {
  height: "10%",
  px: 2,
};
