import { label } from "Constant/En";
import { RouteUrls } from "Constant/RouteUrls";
import { SubNavDataType } from "DataTypes/Layout";

export const eligible: SubNavDataType = {
  id: RouteUrls.eligible,
  label: label.eligible,
  link: `${RouteUrls.eligible}`,
};

export const pendingAssign: SubNavDataType = {
  id: RouteUrls.pendingAssign,
  label: label.pcp,
  link: `${RouteUrls.pendingAssign}`,
};

export const enrolled: SubNavDataType = {
  id: RouteUrls.enrolled,
  label: label?.enrolledPatient,
  link: `${RouteUrls.enrolled}`,
};

export const myPatients: SubNavDataType = {
  id: RouteUrls.myPatients,
  label: label?.mp,
  link: `${RouteUrls.myPatients}`,
};

export const pendingApproval: SubNavDataType = {
  id: RouteUrls.pendingApproval,
  label: label.pendpr,
  link: `${RouteUrls.pendingApproval}`,
};

export const pendingCare: SubNavDataType = {
  id: RouteUrls.pendingCare,
  label: label.continuousCare,
  link: `${RouteUrls.pendingCare}`,
};

export const completedCare: SubNavDataType = {
  id: RouteUrls.completedCare,
  label: label.ccc,
  link: `${RouteUrls.completedCare}`,
};

export const inactive: SubNavDataType = {
  id: RouteUrls.inactive,
  label: label.inactive,
  link: `${RouteUrls.inactive}`,
};

export const ongoing: SubNavDataType = {
  id: RouteUrls.ongoing,
  label: label.ongpt,
  link: `${RouteUrls.ongoing}`,
};

export const billing: SubNavDataType = {
  id: RouteUrls.billing,
  label: label.billing,
  link: `${RouteUrls.billing}`,
};

export const consent: SubNavDataType = {
  id: RouteUrls.consent,
  label: label.consent,
  link: `${RouteUrls.consent}`,
};
