import { Grid, Typography } from "@mui/material";
import { FormDataType } from "DataTypes/Form";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { HandleChangeProps } from "DataTypes/Common";
import { FC, Fragment, useEffect, useState } from "react";
import Format from "./Format";
import { AppointmentTypeType } from "DataTypes/PatientManagement.type";
import { FormatDropdownData } from "Utils/DataFormatter";
import { HandleFormChange } from "Utils/common";
import { AppointmentFormData } from "./Data";
import Loader from "Components/Loader";
import AppointmentApi from "Service/Appointment.api";
import DateTime from "Utils/DateTime";
import {
  AppointmentPatientType,
  AppointmentType,
} from "DataTypes/Services/Patient.type";
import { useDispatch } from "react-redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import AppointmentFormatter from "Utils/Appointment.Formatter";
import TrainingApi from "Service/Training.api";
import TrainingFormatter from "Utils/Training.Formatter";
import { TrainingListType } from "DataTypes/Services/Training.type";
import { UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

interface AppointmentModalProps {
  setForm: (data: FormDataType[]) => void;
  editAppointment: AppointmentType | null;
}

const AppointmentModal: FC<AppointmentModalProps> = ({
  setForm,
  editAppointment,
}: AppointmentModalProps) => {
  const dispatch = useDispatch();
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const [formArr, setFormArr] = useState<FormDataType[]>([]);
  const [patientList, setPatientList] = useState<AppointmentPatientType[]>([]);
  const [appTypeArr, setAppTypeArr] = useState<AppointmentTypeType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPatientList();
  }, []);

  const fetchPatientList: () => void = async () => {
    const newFormData: FormDataType[] = JSON.parse(
      JSON.stringify(AppointmentFormData(editAppointment ? true : false))
    );
    const res = await AppointmentApi.PatientList({
      offset: 0,
      limit: 1000,
    });
    let patientArr: AppointmentPatientType[] = [];
    if (res?.success) {
      patientArr = Format.AppointmentPatientList(res?.data);
      setPatientList(patientArr);
      newFormData[0].dropArr = FormatDropdownData(
        patientArr,
        "patientId",
        "name",
        "patientId"
      );
    }
    if (editAppointment) {
      const typeRes = await AppointmentApi.Type({
        id1: editAppointment.patientId,
      });
      if (typeRes?.success) {
        const appTypeData = AppointmentFormatter.TypeArr(typeRes?.data);
        setAppTypeArr(appTypeData);
        newFormData[1].dropArr = FormatDropdownData(
          appTypeData,
          "appointmentType",
          "appointmentType",
          "appointmentType"
        );
        newFormData[0].dropValue = editAppointment.patientId;
        newFormData[0].readOnly = true;
        newFormData[1].dropValue = newFormData[1].value =
          editAppointment.activity;
        newFormData[1].readOnly = true;
        newFormData[2].dropValue = newFormData[2].value =
          editAppointment.deviceTypeDescription;
        newFormData[2].readOnly = true;
        newFormData[2].boxType = editAppointment.patientDeviceId
          ? "input-box"
          : "empty-block";
        newFormData[2].required = editAppointment.patientDeviceId
          ? true
          : false;
        newFormData[3].dropValue = newFormData[3].value =
          editAppointment.practice;
        newFormData[4].dropValue = newFormData[4].value =
          editAppointment.provider;
        const date = editAppointment.appointmentTimzone
          ? DateTime.AddHoursUTCToLocalDateTime(
            DateTime.HoursTimeZoneSwitch(editAppointment.appointmentTimzone),
            editAppointment.datetime
          )
          : DateTime.UTCToLocalDateTime(editAppointment.datetime);
        newFormData[5].dropValue = newFormData[5].value = date;
        newFormData[5].minVal = `${DateTime.GetYear(date)}-${DateTime.GetMonth(
          date
        )}-01T00:00`;
        newFormData[6].dropValue = newFormData[6].value =
          editAppointment.appointmentTimzone;
        ///As per the task requirement 10450
        // newFormData[6].readOnly = true;
        newFormData[7].value = editAppointment.notes;
      }
    }
    setForm(newFormData);
    setFormArr(newFormData);
    setLoading(false);
  };

  const handleChange: (props: HandleChangeProps) => void = async (props) => {
    const { id, value } = props;
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formArr));
    if (id == "patient") {
      const find = patientList.find((el) => el.patientId == value);
      let appTypeData: AppointmentTypeType[] = [];
      if (find && value) {
        newFormData[3].value = find.practice;
        newFormData[6].value = newFormData[6].dropValue = find.timezone;
        const typeRes = await AppointmentApi.Type({
          id1: find.patientId,
        });
        if (typeRes?.success) {
          appTypeData = AppointmentFormatter.TypeArr(typeRes?.data);
        }
      } else newFormData[3].value = "";
      setAppTypeArr(appTypeData);
      newFormData[1].dropArr = FormatDropdownData(
        appTypeData,
        "appointmentType",
        "appointmentType",
        "appointmentType"
      );
      newFormData[1].value = newFormData[1].dropValue = "";
      newFormData[2].dropArr = [];
      newFormData[2].value = newFormData[1].dropValue = "";
      newFormData[2].boxType = "empty-block";
      newFormData[4].value = "";
    }
    if (id == "appointment-type") {
      const findType = appTypeArr.find((el) => el.appointmentType == value);
      dispatch(
        addSelectedPrgm({
          ...selectedPrgmInitialState,
          ...findType,
        })
      );
      let provName = "";
      let trainingList: TrainingListType[] = [];
      if (findType && value) {
        provName = findType.providerNameDes;
        if (findType.isRpmTraining) {
          const res = await TrainingApi.myTraininglist({
            data: {
              patientId: newFormData[0]?.dropValue,
              trainerId: myInfo?.id,
            },
            limit: 10,
            offset: 0,
          });
          if (res?.success) {
            trainingList = TrainingFormatter.TrainingList(res?.data);
          }
        }
      }
      newFormData[4].value = provName;
      if (trainingList.length > 0) {
        newFormData[2].boxType = "single-select-drop";
        newFormData[2].readOnly = editAppointment ? true : false;
        newFormData[2].required = true;
      } else {
        newFormData[2].boxType = "empty-block";
        newFormData[2].readOnly = true;
        newFormData[2].required = false;
      }
      newFormData[2].dropArr = FormatDropdownData(
        trainingList,
        "patientDeviceId",
        "deviceTypeDescription",
        "patientDeviceId"
      );
      newFormData[2].value = newFormData[2].dropValue = "";
    }
    const newArr = HandleFormChange(props, newFormData);
    setFormArr(newArr);
    setForm(newArr);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Grid container rowSpacing={1} columnSpacing={2}>
            {formArr.map((el: FormDataType) => {
              if (el.boxType == "empty-block") return <Fragment key={el.id} />;
              return (
                <Grid
                  key={el.id}
                  item
                  {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
                >
                  {commonFormSwitch({
                    formObj: el,
                    onChange: handleChange,
                  })}
                </Grid>
              );
            })}
          </Grid>
          {!editAppointment && (
            <Typography variant="subtitle1" mt={1}>
              Note: Any, previously created appointments for the selected
              appointment type will get closed.
            </Typography>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
export default AppointmentModal;
