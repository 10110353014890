import { DropdownArrPropsType } from "DataTypes/Form";
import {
  AppointmentType,
  DashDataType,
  PrgmDashDataType,
  TCMDashDataType,
} from "DataTypes/Services/Patient.type";
import SettingsApi from "Service/Settings.api";
import { FormatDropdownData } from "Utils/DataFormatter";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const AppointmentList: (data: any) => AppointmentType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: AppointmentType[] = CheckedArr.map((el) => {
    const careplanId = CheckNumber(el?.careplanId);
    const provider = CheckStringEmpty(
      careplanId ? el?.teamProviderName : el?.providerName
    );
    const providerDes = CheckStringEmpty(
      careplanId ? el?.teamProviderDesignation : ""
    );
    const staff = CheckStringEmpty(el?.clinicalStaff);
    const staffDes = CheckStringEmpty(el?.clinicalStaffDesignation);
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      ...PatientFormatter.CheckPatientDevice(el),
      activity: CheckStringEmpty(el?.appointmentType),
      appointmentType: CheckStringEmpty(el?.nextSchedule),
      appointmentTimzone: CheckStringEmpty(el?.timezone),
      careplanId,
      careplanRecurringId: CheckNumber(el?.careplanRecurringId),
      careplanState: CheckStringEmpty(el?.careplanState),
      clinicalStaff: staff ? `${staff}${staffDes ? ", " + staffDes : ""}` : "",
      datetime: DateTime.ValidDate(el?.appointmentDatetime),
      id: CheckNumber(el?.appointmentId),
      importance: CheckStringEmpty(el?.loginUserImportance),
      notes: CheckStringEmpty(el?.notes),
      patientId: CheckStringId(el?.patientId),
      programId: CheckNumber(el?.programId),
      provider: provider
        ? `${provider}${providerDes ? ", " + providerDes : ""}`
        : "",
      timezone: CheckStringEmpty(el?.practiceLocationTimezone),
    };
  });
  return newArr;
};

const ExtractKeyVal: (data: any) => any = (data) => {
  const CheckedArr = CheckArr(data);
  const keyPair = [
    { apiKey: "Eligible", uiKey: "eligible" },
    { apiKey: "Enrolled", uiKey: "enrolled" },
    { apiKey: "PTDevices", uiKey: "ptDevice" },
    { apiKey: "0-14 Mins", uiKey: "min014" },
    { apiKey: "15-19 Mins", uiKey: "min1519" },
    { apiKey: "0-19 Mins", uiKey: "min019" },
    { apiKey: "20-39 Mins", uiKey: "min2039" },
    { apiKey: "20 Mins+", uiKey: "min20" },
    { apiKey: "30-59 Mins", uiKey: "min3059" },
    { apiKey: "40-59 Mins", uiKey: "min4059" },
    { apiKey: "60 Mins+", uiKey: "min60" },
  ];
  return keyPair.reduce(
    (previousObject, currentObject) => {
      const find = CheckedArr.find((el) => el?.key == currentObject.apiKey);
      return {
        ...previousObject,
        [currentObject.uiKey]: find ? find?.value : "0",
      };
    },
    { id: 1 }
  );
};

const ExtractTCMKeyVal: (data: any) => any = (data) => {
  const CheckedArr = CheckArr(data);
  const keyPair = [
    { apiKey: "TCM Opportunity", uiKey: "tcmopportunity" },
    { apiKey: "Ongoing", uiKey: "ongoing" },
    { apiKey: "Completed", uiKey: "completed" },
    { apiKey: "Billed", uiKey: "billed" },
  ];
  return keyPair.reduce(
    (previousObject, currentObject) => {
      const find = CheckedArr.find((el) => el?.key == currentObject.apiKey);
      return {
        ...previousObject,
        [currentObject.uiKey]: find ? find?.value : "0",
      };
    },
    { id: 1 }
  );
};

const DashboardData: (data: any) => PrgmDashDataType = (data) => {
  const ccm: DashDataType[] = [ExtractKeyVal(data?.CCM)];
  const pcm: DashDataType[] = [ExtractKeyVal(data?.PCM)];
  const bhi: DashDataType[] = [ExtractKeyVal(data?.BHI)];
  const rpm: DashDataType[] = [ExtractKeyVal(data?.RPM)];
  const tcm: TCMDashDataType[] = [ExtractTCMKeyVal(data?.TCM)];
  return { ccm, pcm, bhi, rpm, tcm };
};

const DropdownSettingCalls: (
  slug: string
) => Promise<DropdownArrPropsType[]> = async (slug) => {
  let data: DropdownArrPropsType[] = [];
  const payload = {
    isDeleted: false,
    groupLabel: slug,
  };
  const res = await SettingsApi.searchDropSettings({
    data: payload,
  });
  if (res?.success) {
    data = FormatDropdownData(res?.data, "id", "label", "value");
  }
  return data;
};

export default { AppointmentList, DashboardData, DropdownSettingCalls };
