import { FormDataType } from "DataTypes/Form";
import { AddProviderFormType } from "DataTypes/PatientManagement.type";
import { CPTeamSearchType } from "DataTypes/Services/Careplan.type";

const MainForm: (
  res: CPTeamSearchType,
  formArr: FormDataType[],
  prgmName: string
) => FormDataType[] = (res, formArr, prgmName) => {
  const newArr: FormDataType[] = JSON.parse(JSON.stringify(formArr));
  newArr[0].value = res.caremanagerId ? res.caremanagerName : "";
  newArr[1].value = res.billableProviderId ? res.billableProviderName : "";
  newArr[1].boxType =
    res.teamInitiatorRoleId == 5 ? "input-box" : "empty-block";
  if (prgmName == "RPM" || prgmName == "TCM") {
    newArr[1].label = "Treatment Plan Billable Provider";
    newArr[1].placeholder = `Select ${formArr[1].label}`;
  }

  newArr[2].value = res.providerId ? res.providerName : "";
  newArr[3].value = res.speciality ? res.speciality : "";
  newArr[4].value = res.primaryCareCoordinatorId
    ? res.primaryCareCoordinatorName
    : "";
  newArr[5].value = res.secondaryCareCoordinatorId
    ? res.secondaryCareCoordinatorName
    : "";
  newArr[6].value = res.authorizedRepresentative1;
  newArr[7].value = res.authorizedRepresentative2;
  newArr[8].value = res.authorizedRepresentative3;
  return newArr
    .filter((el) => el.value)
    .map((el) => {
      return {
        ...el,
        boxType: "input-box",
        readOnly: true,
        validatetype: "name",
      };
    });
};

const AddForm: (
  res: CPTeamSearchType,
  formArr: FormDataType[]
) => AddProviderFormType[] = (res, formArr) => {
  return res.careplanAdditionalProvider.map((el, index) => {
    const newForm: FormDataType[] = JSON.parse(JSON.stringify(formArr));
    newForm[0].value = el.additionalProviderName;
    newForm[1].value = el.additionalProviderSpeciality;
    newForm[2].value = el.addProviderOrganization;
    newForm[3].value = el.phoneNumber;
    newForm[4].value = el.faxNumber;
    return {
      addProviderId: el.id || null,
      formArr: newForm.filter((newEl) => newEl.value),
      id: index + 1,
    };
  });
};

export default { MainForm, AddForm };
