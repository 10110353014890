import React, { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DeviceCol } from "./Data";
import ControlledTable from "Components/ControlledTable";
import { TableLayout } from "Layouts";
import CarePlanApi from "Service/CarePlan.api";
import CPFormatter from "Utils/CP.Formatter";
import { CPDeviceListType } from "DataTypes/Services/Careplan.type";
import { CMTabSelectedEnrollmentType } from "DataTypes/Modal";

const RPMDevices: FC<CMTabSelectedEnrollmentType> = ({ prgmDetails }) => {
  const { careplanId } = prgmDetails;
  const [rows, setRows] = useState<CPDeviceListType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData();
  }, []);

  const setData: () => void = async () => {
    const res = await CarePlanApi.PatientDeviceSearch({
      id1: careplanId,
      data: { isDeleted: false, isDeviceSuspended: false },
    });
    if (res?.success) setRows(CPFormatter.CheckCPPatientDevice(res?.data));
    setLoading(false);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody tableColumn={() => DeviceCol} tableRow={rows} />
  );

  return (
    <Box width="100%">
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(DeviceCol)}
        height={"100%"}
        loading={loading}
      />
    </Box>
  );
};
export default RPMDevices;
