import {
  PendingDeviceListType,
  TaskListType,
  taskStatusTypeAPi,
} from "DataTypes/Services/Tasks";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "./common";
import DateTime from "./DateTime";

const list: (data: any) => TaskListType[] = (data) => {
  const checkedArr = CheckArr(data);
  return checkedArr.map((el) => {
    const assigneeName = CheckStringEmpty(el?.assigneeName);
    const assigneeDes = CheckStringEmpty(el?.assigneeDesignation);
    const assignee = assigneeName
      ? assigneeName + `${assigneeDes ? ", " + assigneeDes : ""}`
      : "";
    const userName = CheckStringEmpty(el?.userName);
    const userDes = CheckStringEmpty(el?.userDesignation);
    const user = userName ? userName + `${userDes ? ", " + userDes : ""}` : "";
    return {
      assignee,
      assigneeId: CheckNumber(el?.assigneeUserId),
      datetime: DateTime.ValidDate(el?.createdAt),
      createdby: user,
      createdbyId: CheckNumber(el?.createdBy),
      id: CheckNumber(el?.id),
      note: CheckStringEmpty(el?.notes),
      name: CheckStringEmpty(el?.patientName),
      patientId: CheckStringId(el?.patientId),
      priority: CheckStringEmpty(el?.priority),
      source: CheckStringEmpty(el?.source),
      status: CheckStringEmpty(el?.status),
      subject: CheckStringEmpty(el?.subject),
      targetDate: DateTime.ValidDate(el?.targateDate),
      taskDescription: CheckStringEmpty(el?.taskDescription),
      tasktype: CheckStringEmpty(el?.type),
      patientDeviceId: CheckNumber(el?.patientDeviceId),
    };
  });
};

const PendingDeviceList: (data: any) => PendingDeviceListType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    const userName = CheckStringEmpty(el?.patientDeviceCreatedByUserName);
    const userDes = CheckStringEmpty(el?.deviceCreatedByUserDesignation);
    const assignee = CheckStringEmpty(el?.taskAssigneeName);
    const assigneeDes = CheckStringEmpty(el?.taskAssigneeDesignation);
    const trainerName = CheckStringEmpty(el?.trainerName);
    const trainerDes = CheckStringEmpty(el?.trainerDesignation);

    const user = userName ? userName + `${userDes ? ", " + userDes : ""}` : "";
    const assignedUser = assignee
      ? assignee + `${assigneeDes ? ", " + assigneeDes : ""}`
      : "";
    const trainer = trainerName
      ? trainerName + `${trainerDes ? ", " + trainerDes : ""}`
      : "";

    return {
      datetime: DateTime.ValidDate(el?.taskCreatedAt),
      status: CheckStringEmpty(el?.status),
      createdby: user,
      name: CheckStringEmpty(el?.patientName),
      deviceName: CheckStringEmpty(el?.deviceName),
      deviceTypeDescription: CheckStringEmpty(el?.deviceTypeDescription),
      taskId: CheckNumber(el?.taskId),
      patientId: CheckStringId(el?.patientId),
      patientDeviceId: CheckNumber(el?.patientDeviceId),
      priority: CheckStringEmpty(el?.taskPriority),
      assignee: assignedUser,
      trainerName: trainer,
    };
  });
};

const TaskStatus: (data: any) => taskStatusTypeAPi[] = (data) => {
  const newArr = CheckArr(data);
  return newArr.map((el) => {
    return {
      id: CheckStringEmpty(el?.id),
      label: CheckStringEmpty(el?.description),
      statusType: CheckStringEmpty(el?.statusType),
      sequenceNumber: CheckNumber(el?.sequenceNumber),
    };
  });
};

export default { list, PendingDeviceList, TaskStatus };
