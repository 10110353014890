import { CarePlanCreated, Consent, TypeOfConsent } from "Constant/Arr";
import { label } from "Constant/En";
import { columnType, rowClickType } from "DataTypes/Table";

const PicCol: (width?: string) => columnType = (width) => {
  return {
    id: "pic",
    label: "",
    width: width || "5%",
    align: "center",
    colType: "text",
    rowType: "profilepic",
  };
};

const PatientInfoCol: (width?: string) => columnType = (width) => {
  return {
    id: "patient",
    label: label.patientinfo,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "patientinfo",
  };
};

const ClinicalStaffCol: (width?: string) => columnType = (width) => {
  return {
    id: "clinicalStaff",
    label: label.clinicalStaff,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const DateTimeCol: (width?: string, align?: "center") => columnType = (
  width,
  align
) => {
  return {
    id: "datetime",
    label: label.datetime,
    width: width || "10%",
    align: align || "left",
    colType: "text",
    rowType: "dateTime",
  };
};

const DateTimeCol1: (width?: string, align?: "center") => columnType = (
  width,
  align
) => {
  return {
    id: "datetime",
    label: label.datetime,
    width: width || "10%",
    align: align || "left",
    colType: "text",
    rowType: "dateTime",
  };
};

const SepDateTimeCol: (width?: string, align?: "center") => columnType = (
  width,
  align
) => {
  return {
    id: "datetime",
    label: label.datetime,
    width: width || "10%",
    align: align || "left",
    colType: "text",
    rowType: "sep-datetime",
  };
};

const StartDateTimeCol: (width?: string) => columnType = (width) => {
  return {
    id: "startdatetime",
    label: "Start Date & Time",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "dateTime",
  };
};

const EndDateTimeCol: (width?: string) => columnType = (width) => {
  return {
    id: "enddatetime",
    label: "End Date & Time",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "dateTime",
  };
};

const DurationCol: (width?: string) => columnType = (width) => {
  return {
    id: "duration",
    label: "Duration (mins)",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const ActivityTypeCol: (width?: string) => columnType = (width) => {
  return {
    id: "activitytype",
    label: "Type",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const EmailCol: (width?: string) => columnType = (width) => {
  return {
    id: "email",
    label: label.email,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const ProviderCol: (width?: string) => columnType = (width) => {
  return {
    id: "provider",
    label: label.provider,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const TeamProviderCol: (width?: string) => columnType = (width) => {
  return {
    id: "teamProvider",
    label: label.provider,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const SourceCol: (width?: string) => columnType = (width) => {
  return {
    id: "source",
    label: label.source,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const ActivityCol: (width?: string) => columnType = (width) => {
  return {
    id: "activity",
    label: label.activity,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const TrainingActivityCol: (width?: string) => columnType = (width) => {
  return {
    id: "trainingActivity",
    label: label.activity,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const ImportanceCol: (width?: string) => columnType = (width) => {
  return {
    id: "importance",
    label: label.importance,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const ActionCol: (
  width?: string,
  align?: "left" | "right" | "center"
) => columnType = (width, align) => {
  return {
    id: "action",
    label: label.action,
    width: width || "11%",
    align: align ? align : "left",
    colType: "text",
    rowType: "action",
  };
};

const NameCol: (width?: string, name?: string) => columnType = (
  width,
  name
) => {
  return {
    id: "name",
    label: name || label.name,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const FullLengthCol: (width?: string, name?: string) => columnType = (
  width,
  name
) => {
  return {
    id: "name",
    label: name || label.name,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "full-text",
  };
};

const orderByCol: (width?: string, centerAlign?: boolean) => columnType = (
  width,
  centerAlign
) => {
  return {
    id: "sortOrder",
    label: label.oderBy,
    width: width || "10%",
    align: centerAlign ? "center" : "left",
    colType: "text",
    rowType: "text",
  };
};

const quesCountCol: (width?: string, centerAlign?: boolean) => columnType = (
  width,
  centerAlign
) => {
  return {
    id: "questioncount",
    label: label.quesCount,
    width: width || "10%",
    align: centerAlign ? "center" : "left",
    colType: "text",
    rowType: "text",
  };
};

const DesignationCol: (width?: string) => columnType = (width) => {
  return {
    id: "designation",
    label: label.designation,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const RoleCol: (width?: string) => columnType = (width) => {
  return {
    id: "role",
    label: label.role,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const MobileNoCol: (width?: string) => columnType = (width) => {
  return {
    id: "mobileNo",
    label: label.mobileNo,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "mobile-no",
  };
};

const PracticeCol: (width?: string) => columnType = (width) => {
  return {
    id: "practice",
    label: label.practice,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "practice-info",
  };
};
const PracticeColWithoutInfo: (width?: string) => columnType = (width) => {
  return {
    id: "practiceName",
    label: label.practice,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const OfficeNoCol: (width?: string) => columnType = (width) => {
  return {
    id: "officeNo",
    label: label.officeNo,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "mobile-no",
  };
};

const DirectNoCol: (width?: string) => columnType = (width) => {
  return {
    id: "directNo",
    label: label.directNo,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "mobile-no",
  };
};

const ProgramCol: (
  width?: string,
  type?: "text" | "billingPrgm"
) => columnType = (width, type) => {
  return {
    id: "program",
    label: label.programs,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: type ? type : "horizontalArr",
  };
};

const ConsentGainCol: (width?: string) => columnType = (width) => {
  return {
    id: "consentgain",
    label: "Consent Gain",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const ProgramSFCol: (width?: string) => columnType = (width) => {
  return {
    id: "abbrevation",
    label: label.abbreviation,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "all-capital",
  };
};

const TrainingTypeDescCol: (width?: string) => columnType = (width) => {
  return {
    id: "trainingTypeDescription",
    label: label.trainingTypeDescription,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const ModelCol: (width?: string) => columnType = (width) => {
  return {
    id: "model",
    label: label.model,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const StaffIconCol: (
  handleStaff: (row: any) => void,
  width?: string
) => columnType = (handleStaff, width) => {
  return {
    id: "staff",
    label: label.staff,
    width: width || "5%",
    align: "left",
    colType: "text",
    rowType: "staffIcon",
    rowClick: ({ rowData, value }) => handleStaff(rowData),
  };
};

const PrimeInsCol: (width?: string) => columnType = (width) => {
  return {
    id: "primaryins",
    label: label.primins,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const SecInsCol: (width?: string) => columnType = (width) => {
  return {
    id: "secondaryins",
    label: label.secins,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const ConsentGainedByCol: (width?: string) => columnType = (width) => {
  return {
    id: "consentgainedby",
    label: label.consentgainedby,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const EnrollmentDateCol: (width?: string) => columnType = (width) => {
  return {
    id: "enrollmentdate",
    label: label.enrollmentdate,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "date",
  };
};

const StatusCol: (width?: string) => columnType = (width) => {
  return {
    id: "status",
    label: label.status,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CPICol: (width?: string) => columnType = (width) => {
  return {
    id: "cpinitiation",
    label: label.cpinitiation,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "date",
  };
};

const DeviceTypeCol: (width?: string) => columnType = (width) => {
  return {
    id: "deviceTypeDescription",
    label: label.deviceTypeDescription,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const DeviceNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "deviceName",
    label: label.deviceName,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const DeviceSupplierNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "supplierName",
    label: label.supplierName,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const ExpectedArrivalCol: (width?: string) => columnType = (width) => {
  return {
    id: "expectedArrival",
    label: label.expectedArrival,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const AssignedByCol: (width?: string) => columnType = (width) => {
  return {
    id: "assignedby",
    label: label.assignedby,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CPCompletionCol: (width?: string) => columnType = (width) => {
  return {
    id: "careplancompletion",
    label: label.careplancompletion,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "date",
  };
};

const LastCallCol: (width?: string) => columnType = (width) => {
  return {
    id: "lastcall",
    label: label.lastcall,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "date",
  };
};

const ReasonCol: (width?: string) => columnType = (width) => {
  return {
    id: "reason",
    label: label.reason,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CareCoordinationCol: (width?: string) => columnType = (width) => {
  return {
    id: "carecoordination",
    label: label.carecoordination,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CheckBoxCol: (
  width: string,
  columnClick: () => void,
  rowClick: (props: rowClickType) => void,
  selectAll: boolean,
  selectedIdChecked: boolean,
  nonEditable?: boolean
) => columnType = (
  width,
  columnClick,
  rowClick,
  selectAll,
  selectedIdChecked,
  nonEditable
) => {
  return {
    id: "checkbox",
    label: "",
    width: width || "3%",
    align: "center",
    colType: "checkbox",
    columnClick: columnClick,
    isColumnChecked: selectAll,
    rowType: "checkbox",
    rowClick: rowClick,
    isRowChecked: selectedIdChecked,
    nonEditable: nonEditable ? true : undefined,
  };
};

const PatientCol: (width?: string) => columnType = (width) => {
  return {
    id: "name",
    label: label.patientname,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const DOBCol: (width?: string) => columnType = (width) => {
  return {
    id: "dob",
    label: label.dob,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "date",
  };
};

const CareTeamTimeCol: (width?: string) => columnType = (width) => {
  return {
    id: "careteamtime",
    label: label.cvcareteamtime,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CareTeamClinicTimeCol: (width?: string) => columnType = (width) => {
  return {
    id: "careteamclinictime",
    label: label.careteamclinictime,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const ProviderClinicTimeCol: (width?: string) => columnType = (width) => {
  return {
    id: "providerclinictime",
    label: label.providerclinictime,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const TotalTimeCol: (width?: string) => columnType = (width) => {
  return {
    id: "totaltime",
    label: label.totaltime,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CodeBilledCol: (width?: string) => columnType = (width) => {
  return {
    id: "codebilled",
    label: label.codebilled,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CPTCodeBilledCol: (width?: string) => columnType = (width) => {
  return {
    id: "cptCode1Reading",
    label: "CPT Code",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CPTCodeBilled2Col: (width?: string) => columnType = (width) => {
  return {
    id: "cptCode16Reading",
    label: "CPT Code",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const PrimCareCoordinatorCol: (width?: string) => columnType = (width) => {
  return {
    id: "primcarecoordinator",
    label: label.primcarecoordinator,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const DueDateCol: (width?: string) => columnType = (width) => {
  return {
    id: "duedate",
    label: label.duedate,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "date",
  };
};

const DateCol: (width?: string, align?: "center") => columnType = (
  width,
  align
) => {
  return {
    id: "date",
    label: label.date,
    width: width || "10%",
    align: align || "left",
    colType: "text",
    rowType: "date",
  };
};

const MgdlCol: (width?: string, align?: "center") => columnType = (
  width,
  align
) => {
  return {
    id: "mgdl",
    label: label.mgdl,
    width: width || "10%",
    align: align || "left",
    colType: "text",
    rowType: "text",
  };
};

const SBPCol: (width?: string, align?: "center") => columnType = (
  width,
  align
) => {
  return {
    id: "sbp",
    label: label.sbp,
    width: width || "10%",
    align: align || "left",
    colType: "text",
    rowType: "text",
  };
};

const DBPCol: (width?: string, align?: "center") => columnType = (
  width,
  align
) => {
  return {
    id: "dbp",
    label: label.dbp,
    width: width || "10%",
    align: align || "left",
    colType: "text",
    rowType: "text",
  };
};

const BPMCol: (width?: string, align?: "center") => columnType = (
  width,
  align
) => {
  return {
    id: "bpm",
    label: label.bpm,
    width: width || "10%",
    align: align || "left",
    colType: "text",
    rowType: "text",
  };
};

const ExclaimationCol: (width?: string) => columnType = (width) => {
  return {
    id: "exclaimation",
    label: "",
    width: width || "1%",
    align: "left",
    colType: "text",
    rowType: "exclaimation",
  };
};

const TaskTypeCol: (width?: string) => columnType = (width) => {
  return {
    id: "tasktype",
    label: label.tasktype,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const PriorityCol: (width?: string) => columnType = (width) => {
  return {
    id: "priority",
    label: label.priority,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const AssigneeCol: (width?: string) => columnType = (width) => {
  return {
    id: "assignee",
    label: label.assignee,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const NoteCol: (width?: string) => columnType = (width) => {
  return {
    id: "note",
    label: label.note,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "longText",
  };
};

const CallNoteCol: (width?: string) => columnType = (width) => {
  return {
    id: "note",
    label: label.note,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "calllognotes",
  };
};

const SubjectCol: (width?: string) => columnType = (width) => {
  return {
    id: "subject",
    label: label.subject,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "longText",
  };
};

const CreatedByCol: (width?: string) => columnType = (width) => {
  return {
    id: "createdby",
    label: label.createdby,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const TrainingNotesCol: (
  width?: string,
  handleReadMore?: (id: number | null) => void,
  readMoreLength?: number,
  checkReadMore?: (id: number | null) => boolean
) => columnType = (width, handleReadMore, readMoreLength, checkReadMore) => {
  return {
    id: "tnotes",
    label: "Previous Notes",
    width: width || "35%",
    align: "center",
    colType: "text",
    rowType: "read-more",
    readMoreLength: readMoreLength,
    checkReadMore: checkReadMore,
    handleReadMore: handleReadMore,
    rowClick: ({ rowData, value }) => handleReadMore,
  };
};

const AlertDescripCol: (width?: string) => columnType = (width) => {
  return {
    id: "alertdescrip",
    label: label.alertdescription,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const AlertTimeCol: (width?: string) => columnType = (width) => {
  return {
    id: "alerttime",
    label: label.alerttime,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const TcmProblemCol: (width?: string) => columnType = (width) => {
  return {
    id: "tcmProblem",
    label: label.problems,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "verticalArr",
  };
};
const ProblemCol: (width?: string) => columnType = (width) => {
  return {
    id: "problem",
    label: label.problems,
    width: width || "20%",
    align: "left",
    colType: "text",
    rowType: "boldText",
  };
};

const QuesAnsCol: (width?: string) => columnType = (width) => {
  return {
    id: "quesAns",
    label: "Questions / Answers",
    width: width || "20%",
    align: "left",
    colType: "text",
    rowType: "re-plan",
  };
};

const IcdCol: (width?: string) => columnType = (width) => {
  return {
    id: "icdCode",
    label: label.icd10codes,
    width: width || "20%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const DescriptionCol: (width?: string) => columnType = (width) => {
  return {
    id: "description",
    label: "Description",
    width: width || "20%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const InterventionCol: (width?: string) => columnType = (width) => {
  return {
    id: "intervention",
    label: label.interventions,
    width: width || "40%",
    align: "left",
    colType: "text",
    rowType: "interventions",
  };
};

const GoalCol: (width?: string) => columnType = (width) => {
  return {
    id: "goal",
    label: label.goals,
    width: width || "40%",
    align: "left",
    colType: "text",
    rowType: "goals",
  };
};

//Separate column for tcm question in summary screen
const TcmQuesCol: (width?: string) => columnType = (width) => {
  return {
    id: "goal",
    label: label.tcmFull,
    width: width || "85%",
    align: "left",
    colType: "text",
    rowType: "tcm-ques",
  };
};

const CallerNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "callername",
    label: "Staff Member Name",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CallNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "callername",
    label: "Caller Name",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CallResultCol: (width?: string) => columnType = (width) => {
  return {
    id: "callresult",
    label: "Result",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
    placeholder: "Select Call Result",
    dropArr: [],
    required: true,
  };
};

const ConsentCol: (width: string) => columnType = (width) => {
  return {
    id: "consent",
    label: "Consent",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
    placeholder: "Select",
    dropArr: Consent,
  };
};

const DOECol: (width?: string) => columnType = (width) => {
  return {
    id: "doe",
    label: "Date Of Enrollment",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "date",
  };
};

const TypeConsentCol: (width?: string) => columnType = (width) => {
  return {
    id: "typeconsent",
    label: "Consent Type",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
    placeholder: "Select",
    dropArr: TypeOfConsent,
  };
};

const CarePlanCreatedCol: (width?: string) => columnType = (width) => {
  return {
    id: "cpcreated",
    label: "Care Plan Created",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
    placeholder: "Select",
    dropArr: CarePlanCreated,
  };
};

const CPInitiationCol: (width?: string) => columnType = (width) => {
  return {
    id: "cpinitiation",
    label: "Care Plan Initiation",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "date",
  };
};

const ScopeMeCol: (width?: string) => columnType = (width) => {
  return {
    id: "scopeme",
    label: "Scope of Service Met",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "checkbox",
    nonEditable: true,
    // isRowChecked: true,
  };
};

const CompanyNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "companyName",
    label: label.companyName,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const RelationCol: (width?: string) => columnType = (width) => {
  return {
    id: "relation",
    label: label.relation,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const HomeNumberCol: (width?: string) => columnType = (width) => {
  return {
    id: "homeNumber",
    label: label.homeNumber,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "mobile-no",
  };
};

const PlanNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "planName",
    label: label.planName,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const EffectiveDateCol: (width?: string) => columnType = (width) => {
  return {
    id: "effectiveDate",
    label: label.effectiveDate,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "local-date",
  };
};

const ExpirationDateCol: (width?: string) => columnType = (width) => {
  return {
    id: "expirationDate",
    label: label.expirationDate,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "local-date",
  };
};

const ReasonTypeCol: (width?: string) => columnType = (width) => {
  return {
    id: "reason",
    label: "Reason",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

// const NextCallCol: (edit: boolean, width?: string) => columnType = (
//   edit,
//   width
// ) => {
//   return {
//     id: "nextcall",
//     label: "Next Call",
//     width: width || "15%",
//     align: "left",
//     colType: "text",
//     rowType: edit ? "date" : "text",
//   };
// };

const NextCallCol: (width?: string) => columnType = (width) => {
  return {
    id: "nextcall",
    label: "Call Reminder (Date & Time)",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "dateTime",
  };
};

const ButtonCol: (
  handleSave: (row: any) => void,
  width?: string
) => columnType = (handleSave, width) => {
  return {
    id: "button",
    label: "",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "button",
    btnLabel: "Save",
    rowClick: handleSave,
  };
};

const PolicyNumCol: (width?: string) => columnType = (width) => {
  return {
    id: "policyNumber",
    label: label.policyNumber,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const VisitNumCol: (width?: string) => columnType = (width) => {
  return {
    id: "visitNumber",
    label: label.visitNumber,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const FacilityCol: (width?: string) => columnType = (width) => {
  return {
    id: "facility",
    label: label.facility,
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const TypeCol: (width?: string) => columnType = (width) => {
  return {
    id: "settingType",
    label: label.type,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const DataModelCol: (width?: string) => columnType = (width) => {
  return {
    id: "dataModel",
    label: label.dataModel,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const SourceIdCol: (width?: string) => columnType = (width) => {
  return {
    id: "sourceId",
    label: label.sourceId,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const SourceNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "sourceName",
    label: label.sourceName,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const destinationIdCol: (width?: string) => columnType = (width) => {
  return {
    id: "destinationId",
    label: label.destinationId,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const destinationNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "destinationName",
    label: label.destinationName,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const webhookTokenCol: (width?: string) => columnType = (width) => {
  return {
    id: "webhookToken",
    label: label.webhookToken,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const webhookTokenExpireAtCol: (width?: string) => columnType = (width) => {
  return {
    id: "webhookTokenExpireAt",
    label: label.webhookTokenExpireAt,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const keyLabelCol: (width?: string) => columnType = (width) => {
  return {
    id: "keyLabel",
    label: label.keyLabel,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const valueCol: (width?: string) => columnType = (width) => {
  return {
    id: "value",
    label: label.value,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

////HomeScreen Dashboard Columns
const EligibleCol: (width?: string) => columnType = (width?: string) => {
  return {
    id: "eligible",
    label: label.eligible,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const EnrolledCol: (width?: string) => columnType = (width?: string) => {
  return {
    id: "enrolled",
    label: label.submyptSuper,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const NoneCol: (width?: string) => columnType = (width?: string) => {
  return {
    id: "",
    label: "",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "none",
  };
};
const Min014Col: (width?: string) => columnType = (width?: string) => {
  return {
    id: "min014",
    label: label.min014,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const Min1519Col: (width?: string) => columnType = (width?: string) => {
  return {
    id: "min1519",
    label: label.min1519,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const Min2039Col: (width?: string) => columnType = (width?: string) => {
  return {
    id: "min2039",
    label: label.min2039,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const Min4059Col: (width?: string) => columnType = (width?: string) => {
  return {
    id: "min4059",
    label: label.min4059,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const Min3059Col: (width?: string) => columnType = (width?: string) => {
  return {
    id: "min3059",
    label: label.min3059,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const Min60Col: (width?: string) => columnType = (width?: string) => {
  return {
    id: "min60",
    label: label.min60,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const Min20Col: (width?: string) => columnType = (width?: string) => {
  return {
    id: "min20",
    label: label.min20,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};
const InactiveCol: (width?: string) => columnType = (width?: string) => {
  return {
    id: "inactive",
    label: label.inactive,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const TcmOpportunityCol: (width?: string) => columnType = (width?: string) => {
  return {
    id: "tcmopportunity",
    label: label.tcmopportunity,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const deviceReadingCol: (width?: string) => columnType = (width?: string) => {
  return {
    id: "readingTaken",
    label: label.deviceReading,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const OngoingCol: (width?: string) => columnType = (width?: string) => {
  return {
    id: "ongoing",
    label: label.ongoing,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const CompletedCol: (width?: string) => columnType = (width?: string) => {
  return {
    id: "completed",
    label: label.completed,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const BilledCol: (width?: string) => columnType = (width?: string) => {
  return {
    id: "billed",
    label: label.billed,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const PtDeviceCol: (width?: string) => columnType = (width?: string) => {
  return {
    id: "ptDevice",
    label: label.ptDevices,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const Min019Col: (width?: string) => columnType = (width?: string) => {
  return {
    id: "min019",
    label: label.min019,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const RevisionInitiatedByCol: (width?: string) => columnType = (width) => {
  return {
    id: "revisionInitiatedByName",
    label: label.revisionInitiatedBy,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const TrainerNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "trainerName",
    label: "Trainer",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const NumberOfReadingDays: (width?: string) => columnType = (width) => {
  return {
    id: "readingDays",
    label: label.readingDays,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const RevisionInitDateTimeCol: (width?: string) => columnType = (width) => {
  return {
    id: "revisionInitDateTime",
    label: "Initiated Date",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "dateTime",
  };
};

const TokenExpiryInMinutesCol: (width?: string) => columnType = (width) => {
  return {
    id: "tokenExpiryInMinutes",
    label: "Token Expire (min)",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const InteractiveCommunicationCol: (width?: string) => columnType = (width) => {
  return {
    id: "interactiveCommunication",
    label: "Interactive Communication",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const BaseUrlCol: (width?: string) => columnType = (width) => {
  return {
    id: "baseUrl",
    label: "Base URL",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "copyLongText",
  };
};

const SecretKeyCol: (width?: string) => columnType = (width) => {
  return {
    id: "secretKey",
    label: "Secret Key",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "copyLongText",
  };
};

const SecretTokenCol: (width?: string) => columnType = (width) => {
  return {
    id: "secretToken",
    label: "Secret Token",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "copyLongText",
  };
};

const AlertMsg: (width?: string) => columnType = (width) => {
  return {
    id: "alertMessage",
    label: label.alertdescription,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const AlertDetail: (width?: string) => columnType = (width) => {
  return {
    id: "detailedMessage",
    label: label.alertDetails,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const AlertTime: (width?: string) => columnType = (width) => {
  return {
    id: "alertTime",
    label: label.alerttime,
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "dateTime",
  };
};

const AddressedBy: (width?: string) => columnType = (width) => {
  return {
    id: "addressedBy",
    label: "Addressed By",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const LocationNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "locationName",
    label: "Location Name",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const FileNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "fileName",
    label: "File Name",
    width: width || "10%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

const UserFullNameCol: (width?: string) => columnType = (width) => {
  return {
    id: "userFullName",
    label: "User Name",
    width: width || "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  };
};

export default {
  webhookTokenExpireAtCol,
  webhookTokenCol,
  destinationNameCol,
  destinationIdCol,
  SourceNameCol,
  SourceIdCol,
  DataModelCol,
  TypeCol,
  PicCol,
  PatientInfoCol,
  ClinicalStaffCol,
  DateTimeCol,
  EndDateTimeCol,
  StartDateTimeCol,
  ProviderCol,
  TeamProviderCol,
  ActivityCol,
  ActivityTypeCol,
  ImportanceCol,
  ActionCol,
  NameCol,
  FullLengthCol,
  orderByCol,
  DesignationCol,
  RoleCol,
  MobileNoCol,
  OfficeNoCol,
  DirectNoCol,
  ProgramCol,
  ProgramSFCol,
  ModelCol,
  PrimeInsCol,
  SecInsCol,
  EnrollmentDateCol,
  ConsentGainedByCol,
  CPICol,
  StaffIconCol,
  StatusCol,
  DeviceTypeCol,
  DeviceNameCol,
  DeviceSupplierNameCol,
  AssignedByCol,
  CPCompletionCol,
  LastCallCol,
  ReasonCol,
  CareCoordinationCol,
  CheckBoxCol,
  PatientCol,
  DOBCol,
  CareTeamTimeCol,
  CareTeamClinicTimeCol,
  ProviderClinicTimeCol,
  TotalTimeCol,
  CodeBilledCol,
  PrimCareCoordinatorCol,
  DueDateCol,
  DateCol,
  MgdlCol,
  SBPCol,
  DBPCol,
  BPMCol,
  TaskTypeCol,
  PriorityCol,
  SourceCol,
  AssigneeCol,
  NoteCol,
  CallNoteCol,
  SubjectCol,
  ExclaimationCol,
  CreatedByCol,
  TrainingNotesCol,
  AlertDescripCol,
  AlertTimeCol,
  ProblemCol,
  InterventionCol,
  GoalCol,
  CallerNameCol,
  CallResultCol,
  NextCallCol,
  ButtonCol,
  ConsentCol,
  ReasonTypeCol,
  CPInitiationCol,
  CarePlanCreatedCol,
  TypeConsentCol,
  DOECol,
  ConsentGainCol,
  PracticeCol,
  ScopeMeCol,
  ExpectedArrivalCol,
  PracticeColWithoutInfo,
  TcmQuesCol,
  TcmProblemCol,
  DurationCol,
  CompanyNameCol,
  PlanNameCol,
  EffectiveDateCol,
  ExpirationDateCol,
  RelationCol,
  HomeNumberCol,
  EmailCol,
  PolicyNumCol,
  VisitNumCol,
  FacilityCol,
  DateTimeCol1,
  SepDateTimeCol,
  keyLabelCol,
  valueCol,
  IcdCol,
  DescriptionCol,
  quesCountCol,
  QuesAnsCol,
  EligibleCol,
  EnrolledCol,
  Min014Col,
  Min1519Col,
  Min2039Col,
  Min20Col,
  Min3059Col,
  Min4059Col,
  Min60Col,
  InactiveCol,
  TcmOpportunityCol,
  OngoingCol,
  CompletedCol,
  BilledCol,
  PtDeviceCol,
  Min019Col,
  NoneCol,
  TrainingTypeDescCol,
  TrainingActivityCol,
  CallNameCol,
  deviceReadingCol,
  NumberOfReadingDays,
  RevisionInitiatedByCol,
  RevisionInitDateTimeCol,
  TrainerNameCol,
  SecretTokenCol,
  SecretKeyCol,
  TokenExpiryInMinutesCol,
  BaseUrlCol,
  InteractiveCommunicationCol,
  AlertMsg,
  AlertDetail,
  AlertTime,
  AddressedBy,
  CPTCodeBilledCol,
  CPTCodeBilled2Col,
  LocationNameCol,
  FileNameCol,
  UserFullNameCol,
};
