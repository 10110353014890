import {
  FormObjType,
  FullRouteProps,
  HandleChangeType,
  HandleClickType,
  ParentRouteProps,
} from "DataTypes/Form";
import SwitchCase from "./SwitchCase";
import { RouteUrls } from "Constant/RouteUrls";
import { store } from "Redux/Store";
import { setNavArr } from "Redux/Screen/Action";
import { ReduxScreenNavType, ScreenInitialStateType } from "DataTypes/Redux";

const HandleValidateForm: (formData: FormObjType[]) => {
  formData: FormObjType[];
  errorCheck: boolean;
} = (formData) => {
  let singleEntryErrorCheck = false;
  const newArr = formData.map((el) => {
    let result = { error: false, errorText: "" };
    if (el.boxType == "input" && (el.required || el.inputVal)) {
      result = SwitchCase.InputValidation(el);
    }
    if (el.boxType == "single-select" && (el.required || el.dropVal)) {
      result = SingleValidation(el);
    }
    if (el.boxType == "multi-select" && (el.required || el.dropVal)) {
      result = MultiValidation(el);
    }
    if (result.error) singleEntryErrorCheck = true;
    el.errorText = result.errorText;
    el.error = result.error;
    return el;
  });
  return { formData: newArr, errorCheck: singleEntryErrorCheck };
};

const HandleInputChange: (
  data: HandleChangeType,
  formArr: FormObjType[]
) => FormObjType[] = ({ inputVal, id }, formArr) => {
  return formArr.map((formEl) => {
    if (formEl.id == id && formEl.boxType == "input") {
      formEl.inputVal = typeof inputVal == "string" ? inputVal : "";
      const temp = SwitchCase.InputValidation(formEl);
      formEl.error = temp.error;
      formEl.errorText = temp.errorText;
    }
    return formEl;
  });
};

const HandleClickChange: (
  data: HandleClickType,
  formArr: FormObjType[]
) => FormObjType[] = ({ dropVal, id, boxType }, formArr) => {
  return formArr.map((formEl) => {
    if (
      formEl.id == id &&
      formEl.boxType == "single-select" &&
      boxType == "single-select" &&
      !Array.isArray(dropVal)
    ) {
      formEl.dropVal = dropVal || null;
      const temp = SingleValidation(formEl);
      formEl.error = temp.error;
      formEl.errorText = temp.errorText;
    }
    if (
      formEl.id == id &&
      formEl.boxType == "multi-select" &&
      boxType == "multi-select"
    ) {
      formEl.dropVal = dropVal || null;
      const temp = MultiValidation(formEl);
      formEl.error = temp.error;
      formEl.errorText = temp.errorText;
    }
    return formEl;
  });
};

const SingleValidation: (formObj: FormObjType) => {
  errorText: string;
  error: boolean;
} = (formObj) => {
  let errorText = "";
  let error = false;
  const singleDropVal =
    formObj.boxType == "single-select" ? formObj.dropVal : null;
  if (formObj.required || singleDropVal) {
    if (!singleDropVal || singleDropVal.toString().length === 0) {
      errorText = "Please select a value";
      error = true;
    }
  }
  return { errorText, error };
};

const MultiValidation: (formObj: FormObjType) => {
  errorText: string;
  error: boolean;
} = (formObj) => {
  let errorText = "";
  let error = false;
  const dropVal = formObj.boxType == "multi-select" ? formObj.dropVal : [];
  if (formObj.required || (dropVal && dropVal?.length > 0)) {
    if (!dropVal || dropVal.length < 1) {
      errorText = "Please select minimum 1 option";
      error = true;
    }
  }
  return { errorText, error };
};

const CheckDistinctArrVal: (arr: (number | string | null)[]) => boolean = (
  arr
) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr.indexOf(arr[i]) !== i) {
      return false;
    }
  }
  return true;
};

const HandleParentRoute: (props: ParentRouteProps) => string = (props) => {
  const { prgm, careId, isRec, recId, isRev } = props;
  if (prgm == "TCM") return `${RouteUrls.tcm}/${careId}`;
  else if (prgm == "RPM") {
    if (isRev) {
      return `${RouteUrls.revisionPlan}/${careId}`;
    }
    if (isRec) {
      return `${RouteUrls.treatmentPlan}/${careId}/${RouteUrls.recurring}/${recId}`;
    }
    return `${RouteUrls.treatmentPlan}/${careId}`;
  } else {
    if (isRec) {
      return `${RouteUrls.carePlan}/${careId}/${RouteUrls.recurring}/${recId}`;
    }
    return `${RouteUrls.carePlan}/${careId}`;
  }
};

const HandleScreenRoute: (props: FullRouteProps) => string = (props) => {
  const { prgm, careId, isRec, recId, isRev, careState } = props;
  if (prgm == "TCM") {
    if (careId)
      return `${RouteUrls.tcm}/${careId}/${HandleStateRoute(careState)}`;
    else return `${RouteUrls.dischargeSummary}`;
  } else if (prgm == "RPM") {
    if (careId) {
      if (isRev) {
        return `${RouteUrls.revisionPlan}/${careId}/${HandleStateRoute(
          careState
        )}`;
      } else if (isRec) {
        return `${RouteUrls.treatmentPlan}/${careId}/${RouteUrls.recurring}/${recId}/${RouteUrls.rpmrecurring}`;
      } else {
        return `${RouteUrls.treatmentPlan}/${careId}/${HandleStateRoute(
          careState
        )}`;
      }
    } else return `${RouteUrls.program}`;
  } else {
    if (careId) {
      if (isRec) {
        return `${RouteUrls.carePlan}/${careId}/${RouteUrls.recurring}/${recId}/${RouteUrls.careQuestion}`;
      } else {
        return `${RouteUrls.carePlan}/${careId}/${HandleStateRoute(careState)}`;
      }
    } else return `${RouteUrls.program}`;
  }
};

const HandleStateRoute: (cpState: string) => string = (cpState) => {
  switch (cpState) {
    case "Pending problem assignment":
      return RouteUrls.careProblem;
    case "Pending device assignment":
      return RouteUrls.rpmAddDevice;
    case "Pending questionnaire":
      return RouteUrls.careQuestion;
    case "Pending team assignment":
      return RouteUrls.careTeam;
    case "Pending share with provider":
      return RouteUrls.careSummary;
    case "Pending for provider approval":
      return RouteUrls.careSummary;
    default:
      return RouteUrls.careSummary;
  }
};

const MergeNameDesign: (name: string, design: string) => string = (
  name,
  design
) => {
  return name ? name + `${design ? ", " + design : ""}` : "";
};

const HandleNavArr: (navObj: ReduxScreenNavType) => void = (navObj) => {
  const dispatch = store.dispatch;
  let { navArr }: ScreenInitialStateType = store.getState().screen;
  navArr = navArr.filter(
    (el) => el.level < navObj.level && el.navType == navObj.navType
  );
  dispatch(setNavArr([...navArr, navObj]));
};

export default {
  HandleInputChange,
  HandleClickChange,
  SingleValidation,
  MultiValidation,
  HandleValidateForm,
  CheckDistinctArrVal,
  HandleParentRoute,
  MergeNameDesign,
  HandleScreenRoute,
  HandleNavArr,
};
