import { Box, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import CstmBtn from "Components/CstmBtn";
import { UserInitialStateType } from "DataTypes/Redux";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { startLoading } from "Redux/Screen/Action";
import { HeadDropBoxPropType, HeaderBoxType } from "DataTypes/Common";
import { Logout } from "Utils/common";

const DropBox = ({
  dataArr,
  width,
  position,
  changeDropState,
  onClick,
}: HeadDropBoxPropType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDrop, setOpenDrop] = useState(false);
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );

  useEffect(() => {
    setOpenDrop(changeDropState);
  }, [changeDropState]);

  const handleClick: (el: HeaderBoxType) => void = (el) => {
    setOpenDrop(false);
    onClick();
    navigate(el.path);
  };

  return (
    <Fragment>
      {openDrop && (
        <Box
          sx={{
            ...CommonStyle.sxWhiteBox,
            ...sxDropBox,
            width,
            ...(position == "center" ? sxCenter : sxRight),
          }}
        >
          <Box>
            {dataArr.map((el) => {
              return (
                <Fragment key={el.id}>
                  {el.type == "text" ? (
                    <Typography
                      variant="subtitle1"
                      onClick={() => handleClick(el)}
                    >
                      {el.label}
                    </Typography>
                  ) : (
                    <CstmBtn
                      label={el.label}
                      onClick={() => {
                        dispatch(
                          startLoading({
                            loading: true,
                            loadtext: "Logging Out...",
                          })
                        );
                        setOpenDrop(false);
                        Logout(myInfo.id);
                      }}
                      width="100%"
                    />
                  )}
                </Fragment>
              );
            })}
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
export default DropBox;

const sxDropBox = {
  position: "absolute",
  top: "100%",
  zIndex: 999,
  transition: "0.5s",
  "& >div": {
    p: 1,
    "& >h6": {
      cursor: "pointer",
      p: 0.5,
      borderRadius: 1.25,
      borderBottom: "1px solid",
      borderColor: "custom.main",
    },
    "& >h6:hover": {
      backgroundColor: "custom.light",
    },
  },
};

const sxRight = {
  right: 0,
  "&:before": {
    content: "''",
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid #ee7404",
    transform: "rotate(0deg)",
    backgroundColor: "transparent",
    top: "-10px",
    right: "2px",
    position: "absolute",
  },
};

const sxCenter = {
  right: "-100%",
  "&:before": {
    content: "''",
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid #ee7404",
    transform: "rotate(0deg)",
    backgroundColor: "transparent",
    top: "-10px",
    right: "0px",
    left: "0px",
    margin: "0 auto",
    position: "absolute",
  },
};
