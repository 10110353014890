import { Loader } from "Components";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";
import { FC, Fragment, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

const ProblemMaster: FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "problem-list",
      label: "Problem Master",
      path: `/${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.prob}`,
      level: 1,
      link: RouteUrls.list,
      navType: "master",
    });
    setLoading(false);
  }, []);

  return <Fragment>{loading ? <Loader /> : <Outlet />}</Fragment>;
};
export default ProblemMaster;
