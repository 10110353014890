import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import { CareTeamAddProType } from "DataTypes/PatientManagement.type";
import { CPTeamSearchType } from "DataTypes/Services/Careplan.type";

const SetFormData1: (
  formArr: FormDataType[],
  teamDetails: CPTeamSearchType,
  cmList: DropdownArrPropsType[],
  ccList: DropdownArrPropsType[],
  cpList: DropdownArrPropsType[],
  prgmName: string
) => FormDataType[] = (formArr, team, cmList, ccList, cpList, prgmName) => {
  const pCCList = ccList.filter(
    (el) => el.value != team.secondaryCareCoordinatorId
  );
  const sCCList = ccList.filter(
    (el) => el.value != team.primaryCareCoordinatorId
  );
  formArr[0].value = formArr[0].dropValue = team.caremanagerId || "";
  formArr[0].readOnly = team.teamInitiatorRoleId == 3 ? true : false;
  formArr[0].dropArr = cmList;

  formArr[1].value = formArr[1].dropValue = team.billableProviderId || "";
  formArr[1].dropArr = cpList;
  formArr[1].readOnly = team.teamInitiatorRoleId == 5 ? true : false;
  formArr[1].required = team.teamInitiatorRoleId == 5 ? true : false;
  formArr[1].boxType =
    team.teamInitiatorRoleId == 5 ? "single-select-drop" : "empty-block";
  if (prgmName == "RPM" || prgmName == "TCM") {
    formArr[1].label = "Treatment Plan Billable Provider";
    formArr[1].placeholder = `Select ${formArr[1].label}`;
  }

  formArr[2].value = formArr[2].dropValue = team.providerId || "";
  formArr[2].dropArr = cpList;

  formArr[3].value = formArr[3].dropValue = team.speciality || "";
  formArr[3].readOnly = true;

  formArr[4].value = formArr[4].dropValue = team.primaryCareCoordinatorId || "";
  formArr[4].dropArr = pCCList;
  formArr[4].required = team.programId == 3 ? false : true;
  formArr[4].boxType =
    team.programId == 3 ? "empty-block" : "single-select-drop";

  formArr[5].value = formArr[5].dropValue =
    team.secondaryCareCoordinatorId || "";
  formArr[5].dropArr = sCCList;
  formArr[5].required = false;
  formArr[5].boxType =
    team.programId == 3 ? "empty-block" : "single-select-drop";

  formArr[6].value = formArr[6].dropValue =
    team.authorizedRepresentative1 || "";

  formArr[7].value = formArr[7].dropValue =
    team.authorizedRepresentative2 || "";

  formArr[8].value = formArr[8].dropValue =
    team.authorizedRepresentative3 || "";
  return formArr;
};

const SetEditUrlFormData1: (
  formArr: FormDataType[],
  teamDetails: CPTeamSearchType,
  cmList: DropdownArrPropsType[],
  ccList: DropdownArrPropsType[],
  cpList: DropdownArrPropsType[],
  prgmName: string
) => FormDataType[] = (formArr, team, cmList, ccList, cpList, prgmName) => {
  const pCCList = ccList.filter(
    (el) => el.value != team.secondaryCareCoordinatorId
  );
  const sCCList = ccList.filter(
    (el) => el.value != team.primaryCareCoordinatorId
  );
  formArr[0].value = formArr[0].dropValue = team.caremanagerId || "";
  formArr[0].readOnly = false;
  formArr[0].dropArr = cmList;
  formArr[0].required = team.caremanagerId ? true : false;

  formArr[1].value = formArr[1].dropValue = team.billableProviderId || "";
  formArr[1].dropArr = cpList;
  formArr[1].readOnly = false;
  formArr[1].required = team.teamInitiatorRoleId == 5 ? true : false;
  formArr[1].boxType =
    team.teamInitiatorRoleId == 5 ? "single-select-drop" : "empty-block";
  if (prgmName == "RPM" || prgmName == "TCM") {
    formArr[1].label = "Treatment Plan Billable Provider";
    formArr[1].placeholder = `Select ${formArr[1].label}`;
  }

  formArr[2].value = formArr[2].dropValue = team.providerId || "";
  formArr[2].dropArr = cpList;
  formArr[2].required = team.providerId ? true : false;

  formArr[3].value = formArr[3].dropValue = team.speciality || "";
  formArr[3].readOnly = true;
  formArr[3].required = team.speciality ? true : false;

  formArr[4].value = formArr[4].dropValue = team.primaryCareCoordinatorId || "";
  formArr[4].dropArr = pCCList;
  formArr[4].required =
    team.programId == 3 ? false : team.primaryCareCoordinatorId ? true : false;
  formArr[4].boxType =
    team.programId == 3 ? "empty-block" : "single-select-drop";

  formArr[5].value = formArr[5].dropValue =
    team.secondaryCareCoordinatorId || "";
  formArr[5].dropArr = sCCList;
  formArr[5].required = false;
  formArr[5].boxType =
    team.programId == 3 ? "empty-block" : "single-select-drop";

  formArr[6].value = formArr[6].dropValue =
    team.authorizedRepresentative1 || "";
  formArr[6].required = false;
  formArr[6].boxType = "empty-block";

  formArr[7].value = formArr[7].dropValue =
    team.authorizedRepresentative2 || "";
  formArr[7].required = false;
  formArr[7].boxType = "empty-block";

  formArr[8].value = formArr[8].dropValue =
    team.authorizedRepresentative3 || "";
  formArr[8].required = false;
  formArr[8].boxType = "empty-block";
  return formArr;
};

const SetFormData2: (
  formArr: FormDataType[],
  teamDetails: CPTeamSearchType
) => CareTeamAddProType[] = (formArr, team) => {
  return team.careplanAdditionalProvider.map((el, index) => {
    const newForm: FormDataType[] = JSON.parse(JSON.stringify(formArr));
    newForm[0].value = el.additionalProviderName;
    newForm[1].value = el.additionalProviderSpeciality;
    newForm[2].value = el.addProviderOrganization;
    newForm[3].value = el.phoneNumber;
    newForm[4].value = el.faxNumber;
    return {
      id: index + 1,
      addProviderid: el.id || null,
      formData: newForm,
    };
  });
};

export default { SetFormData1, SetFormData2, SetEditUrlFormData1 };
