////Bug fixing Azure-393

import { FC, Fragment, useEffect, useState } from "react";
import { CommonStyle, ControlledForm, ControlledTable } from "Components";
import { TableCol } from "./Data";
import { TableLayout } from "Layouts";
import { Box, Divider, Typography } from "@mui/material";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "Redux/Screen/Action";
import { RouteUrls } from "Constant/RouteUrls";
import DeviceApi from "Service/Device.api";
import { FormatDropdownData } from "Utils/DataFormatter";
import {
  DeviceReadingInfo,
  DeviceReading,
  DeviceDropDownCycle,
} from "DataTypes/Services/Problem.type";
import DeviceTypeFormatter from "Utils/DeviceType.Formatter";
import NoData from "Components/NoData";
import DateTime from "Utils/DateTime";
import { CheckEmptyData } from "Utils/common";
import CommonFormatter from "Utils/Common.Formatter";

const DeviceReadings: FC = () => {
  const { patientInfo, selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const dispatch = useDispatch();
  const [cycle, setCycle] = useState<DeviceDropDownCycle[]>([]);
  const [selectedCycle, setSelectedCycle] = useState<string>("");
  const [row1, setRows1] = useState<DeviceReading[] | []>([]);
  const [rows2, setRows2] = useState<DeviceReading[]>([]);
  const [allReadings, setAllReadings] = useState<DeviceReadingInfo | null>(
    null
  );
  const [headHeight, setHeadHeight] = useState(20);
  const offsetHead = document?.getElementById("head")?.offsetHeight;
  const tableHeight = `calc(100% - ${headHeight}px - 16px)`;

  useEffect(() => {
    setHeadHeight(offsetHead || 0);
  }, [offsetHead]);

  useEffect(() => {
    fetchReadingCycleDropDown();
    CommonFormatter.HandleNavArr({
      id: "device-reading",
      label: "Device Readings",
      path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.deviceReading}`,
      level: 3,
      link: RouteUrls.deviceReading,
      navType: "patient",
    });
  }, []);

  useEffect(() => {
    fetchDeviceReadings();
  }, [selectedCycle]);

  const fetchReadingCycleDropDown = async (): Promise<void> => {
    const payload = {
      id1: patientInfo.id,
      data: {},
    };
    const deviceCylceRes = await DeviceApi.fetchDeviceReadingCycle(payload);
    if (deviceCylceRes.success) {
      const dateFormatter =
        deviceCylceRes.data &&
        deviceCylceRes.data.reduce(
          (acc: any, item: DeviceDropDownCycle, index: number) => {
            const startDate = item.date.split("to")[0];
            const endDate = item.date.split("to")[1];
            const newFormate = `${DateTime.ToLocalDate(
              startDate
            )} - ${DateTime.ToLocalDate(endDate)}`;
            acc.push({
              date: newFormate,
              dateCycle: `Cycle:${index}-${newFormate}`,
            });
            return acc;
          },
          []
        );
      setCycle(dateFormatter);
      if (deviceCylceRes.data && deviceCylceRes.data.length > 0) {
        setSelectedCycle(dateFormatter[0].date);
      }
    }
  };

  const fetchDeviceReadings = async (): Promise<void> => {
    if (selectedCycle) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const payload1 = {
        id1: patientInfo.id,
        data: {
          fromDate: DateTime.ToUTCDate(selectedCycle.split("-")[0]),
          toDate: DateTime.ToUTCDate(selectedCycle.split("-")[1]),
        },
      };
      const deviceReadingRes = await DeviceApi.deviceReadings(payload1);
      if (deviceReadingRes.success) {
        const formattedData = DeviceTypeFormatter.DeviceReadingFormate(
          deviceReadingRes.data
        );
        setAllReadings(formattedData);
        const readings = formattedData.readings;
        if (readings.length > 15) {
          const firstPart = readings.slice(0, 15);
          const secondPart = readings.slice(15);

          setRows1(firstPart);
          setRows2(secondPart);
        } else {
          setRows1(readings);
          setRows2([]);
        }
      }
      dispatch(stopLoading());
    }
  };

  const TblBodyNextDayJSX = (
    <ControlledTable.TblBody tableColumn={TableCol} tableRow={rows2} />
  );

  const TblBodyJSX = (
    <ControlledTable.TblBody tableColumn={TableCol} tableRow={row1} />
  );

  return (
    <Fragment>
      <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox, px: 2 }} width="100%">
        <Box
          id="head"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ py: 1 }}
        >
          <Box>
            <Typography variant="body2">Patient</Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {patientInfo.name}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            <Typography variant="body2">Organization</Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {selectedPrgm.practiceName}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box width="20%">
            <Typography variant="body2">Reading Cycle</Typography>
            <ControlledForm.SingleSelectDrop
              id="month"
              dropArr={FormatDropdownData(cycle, "date", "date", "date")}
              placeholder="Select Cycle"
              dropValue={selectedCycle}
              onClick={(val) => setSelectedCycle(String(val))}
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            <Typography variant="body2">Reading Count</Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {CheckEmptyData(allReadings?.activeCount) +
                "/" +
                CheckEmptyData(allReadings?.totalCount)}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            <Typography variant="body2">Reading %</Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {CheckEmptyData(allReadings?.avg)}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            <Typography variant="body2">30 Day Reading Status </Typography>
            <Typography
              sx={
                allReadings &&
                allReadings.activeCount &&
                allReadings?.activeCount >= 16
                  ? { ...statusGreenBox }
                  : { ...statusRedBox }
              }
            ></Typography>
          </Box>
        </Box>
        <Box height={tableHeight}>
          {row1 && row1.length > 0 ? (
            <Box
              display="flex"
              height="100%"
              justifyContent="space-between"
              sx={{ "&>div": { width: "49%" } }}
            >
              <TableLayout
                tblBody={TblBodyJSX}
                tblHead={ControlledTable.TblHead(TableCol())}
                height={"100%"}
              />
              <TableLayout
                tblBody={TblBodyNextDayJSX}
                tblHead={ControlledTable.TblHead(TableCol())}
                height={"100%"}
              />
            </Box>
          ) : (
            <NoData />
          )}
        </Box>
      </Box>
    </Fragment>
  );
};
export default DeviceReadings;

const statusGreenBox = {
  height: "15px",
  width: "15px",
  margin: "0 auto",
  borderRadius: "50%",
  backgroundColor: "green",
};

const statusRedBox = {
  height: "15px",
  width: "15px",
  margin: "0 auto",
  borderRadius: "50%",
  backgroundColor: "red",
};
