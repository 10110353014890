import ControlledForm from "Components/ControlledForm";
import ControlledTable from "Components/ControlledTable";
import { columnType } from "DataTypes/Table";
export const TableCol: columnType[] = [
  ControlledTable.ColumnData.InterventionCol(),
  ControlledTable.ColumnData.GoalCol(),
];

export const Arr = [
  ControlledForm.FormData.AddNotes({ multiline: 12 }),
  ControlledForm.FormData.Callfreq(),
  ControlledForm.FormData.CompletionDate(),
];
