import { FC, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const AboutUs: FC = () => {
  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "about",
      label: "About",
      path: `/${RouteUrls.hs}/${RouteUrls.aboutUs}`,
      level: 1,
      link: RouteUrls.aboutUs,
      navType: "my-items",
    });
  }, []);

  return (
    <Box sx={{ ...sxMain }}>
      <img src={ImgPath.CV_Logo} alt="" />
      <Typography variant="body1">
        <b>Version:</b> {process.env.REACT_APP_VERSION}
      </Typography>
    </Box>
  );
};
export default AboutUs;

const sxMain = {
  ...CommonStyle.sxWhiteBox,
  textAlign: "end",
  height: "91%",
  overflow: "auto",
  px: 2,
  "& >img:first-of-type": {
    width: "7%",
    mt: 2,
  },
};
