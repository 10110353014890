import { FC, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import { CatSearchDocType } from "DataTypes/Services/Redox.type";

interface ModalProps {
  file: CatSearchDocType;
  handleClose: () => void;
}

const ViewModal: FC<ModalProps> = (props) => {
  const { file, handleClose } = props;

  return (
    <Fragment>
      <Box sx={{ ...sxHeader, ...CommonStyle.sxRow }}>
        <Typography fontWeight="bold">{file.documentName}</Typography>
        <Box display={"flex"} alignItems={"center"}>
          <Box
            sx={{ ...CommonStyle.sxRoundIconsMainSm, mr: 1 }}
            onClick={handleClose}
          >
            <ImgPath.CloseIcon titleAccess="Close" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ ...sxMain }}>
        {/* <iframe
          src="http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&navpanes=0&scrollbar=0"
          // height="100%"
          // width="100%"
        /> */}
        {/* <embed
          src="http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&navpanes=0&scrollbar=0"
          type="application/pdf"
          // frameBorder="0"
          // scrolling="auto"
          // height="100%"
          // width="100%"
        ></embed> */}
        <object width={"100%"} height={"100%"} data={file.url} />
      </Box>
    </Fragment>
  );
};

export default ViewModal;

const sxHeader = {
  justifyContent: "space-between",
  height: "10%",
  px: 2,
};

const sxMain = {
  height: "85%",
  px: 2,
};
