import { FC } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import { ScreenInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const OverlayLoader: FC = () => {
  const { screenLoad }: ScreenInitialStateType = useSelector(
    (state: any) => state?.screen
  );

  return screenLoad.loading ? (
    <Box
      sx={{
        ...CommonStyle.sxColumnCenter,
        position: "fixed",
        zIndex: 999,
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        right: 0,
        left: 0,
      }}
    >
      <CircularProgress size={60} sx={{ color: "common.white" }} />
      <Typography
        fontWeight="bold"
        variant="h4"
        sx={{ color: "common.white", mt: 2, letterSpacing: 2 }}
      >
        {screenLoad.loadtext || "Loading ..."}
      </Typography>
    </Box>
  ) : (
    <></>
  );
};

export default OverlayLoader;
