import { FormDataType } from "DataTypes/Form";
import { MyInfoType } from "DataTypes/Redux";
import {
  UserLoginPayloadType,
  UserReadApiType,
  UserVerifyOtpPayloadType,
} from "DataTypes/Services/User.type";
import DateTime from "Utils/DateTime";
import { CheckStringEmpty } from "Utils/common";

const LoginPayload: (Arr: FormDataType[]) => UserLoginPayloadType = (Arr) => {
  return {
    userName: CheckStringEmpty(Arr[0].value),
    password: CheckStringEmpty(Arr[1].value),
  };
};

const VerifyOtpPayload: (
  attemptId: number | null,
  arr: FormDataType[]
) => UserVerifyOtpPayloadType = (attemptId, arr) => {
  return {
    id: attemptId,
    otp: CheckStringEmpty(arr[0].value),
  };
};

const getAllowedPermission = (
  TotalPermissions: string[],
  totalUserPermission: string[]
) => {
  let tempObj: any = {};
  TotalPermissions.forEach((item: string) => {
    if (totalUserPermission.includes(item)) {
      const splitVal = item.split(":");
      tempObj = {
        ...tempObj,
        [splitVal?.[1].trim()]: {
          ...tempObj?.[splitVal?.[1].trim()],
          [splitVal[0]]: true,
        },
      };
    } else {
      const splitVal = item.split(":");
      if (splitVal[1] in tempObj) {
        tempObj = {
          ...tempObj,
          [splitVal?.[1].trim()]: {
            ...tempObj?.[splitVal?.[1].trim()],
            [splitVal[0]]: false,
          },
        };
      } else {
        tempObj = {
          ...tempObj,
          [splitVal?.[1].trim()]: {
            ...tempObj?.[splitVal?.[1].trim()],
            [splitVal[0]]: false,
          },
        };
      }
    }
  });
  return tempObj;
};

const FormateMyInfoData: (data: UserReadApiType) => MyInfoType = (data) => {
  const findCV = data.practice.find(
    (pracEl) => pracEl.isMasterPractice == true
  );
  const timezone = data.timezone;
  const utcDiff = DateTime.HoursTimeZoneSwitch(timezone);
  const { date, datetime, datetimesec, chartDateTimeSec } =
    DateTime.TimeZoneRegionSwitch(timezone);
  return {
    id: data.id || null,
    firstname: data.firstName,
    lastname: data.lastName,
    roleId: data.role[0].id,
    roleName: data.role[0]?.name || "",
    practiceId: findCV ? findCV.id : null,
    practiceName: (findCV ? findCV.name : "") || "",
    practiceModelId: null,
    practiceModelName: "",
    isMasterPractice: false,
    designation: data.designation,
    timezone,
    dateFormat: date,
    dateTimeFormat: datetime,
    dateTimeSecFormat: datetimesec,
    chartDateTimeSecFormat: chartDateTimeSec,
    utcDiff: utcDiff == 0 ? null : utcDiff,
  };
};

export default {
  LoginPayload,
  VerifyOtpPayload,
  getAllowedPermission,
  FormateMyInfoData,
};
