import { FC, useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { actionArr, FilterArr, TableCol } from "./Data";
import { ModalLayout, TableLayout } from "Layouts";
import { ControlledTable, Filter2, Modal } from "Components";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch } from "react-redux";
import { CommonButtonArray } from "Components/Modal/Data";
import { setAlertData } from "Redux/Screen/Action";
import PatientApi from "Service/Patient.api";
import Formate from "./Formate";
import { UserInitialStateType } from "DataTypes/Redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { Filter2DataProps } from "DataTypes/Common";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import { useSelector } from "react-redux";
import PatientFormatter from "Utils/Patient.Formatter";
import { MyPatientType } from "DataTypes/Services/Patient.type";
import { CreateFilter2Payload } from "Utils/common";
import CommonFormatter from "Utils/Common.Formatter";

const MyPatients: FC = () => {
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState<number>(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<MyPatientType[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<MyPatientType | null>(
    null
  );
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    handleSetData(1, limit);
  }, [filterData]);

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
    };
    const res = await PatientApi.MyPatient({
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
      data: payload,
    });
    if (res?.success) {
      setRows(Formate.MyPatientList(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res.criterion.total);
      setLimit(res.criterion.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: MyPatientType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`
    );
  };

  const handleChart: (data: MyPatientType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleTask: (data: MyPatientType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${RouteUrls.add}`
    );
  };

  const handleCall: (data: MyPatientType) => void = (data) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        showLogTime: true,
        enrollmentDate: data.enrollmentdate,
        careplanType: data.careplanType,
        careplanId: data.careplanId,
        careplanStage: PatientFormatter.GetCPStage(data.careplanState),
        eligibleProgramId: data.eligibleProgramId,
        programId: data.programId,
        caremanagerId: data.caremanagerId,
        billableproviderId: data.billableproviderId,
        providerId: data.teamProviderId,
        abbrevation: data.program,
        callDefaultRoute: `../${CommonFormatter.HandleParentRoute({
          prgm: data.program,
          isRev: data.careplanType == "REVISION",
          careId: data.careplanId,
        })}/${PatientFormatter.StateRouteSwitch(data.careplanState)}`,
        careplanState: data.careplanState,
        showSaveBtn: true,
        showSubmitBtn: true,
        readOnlyArr:
          data.careplanType == "REVISION" ? ["team", "editDevice"] : [],
        teamInitiatedBy: data.teamInitiatedBy,
        teamInitiatorRoleId: data.teamInitiatorRoleId,
      })
    );
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.callLog}`
    );
  };

  const handleSaveModal = async () => {
    const cmpayload = {
      caremanagerId: myInfo.id,
    };
    const provpayload = {
      billableProviderId: myInfo.id,
    };
    const res = await PatientApi.Unassign({
      id1: selectedPatient && selectedPatient.enrolledProgramId,
      data:
        myInfo.roleId == 5 ? provpayload : myInfo.roleId == 3 ? cmpayload : {},
    });
    if (res.success) {
      dispatch(setAlertData(res.alert));
      setOpenModal(false);
      navigate(`../${RouteUrls.pendingAssign}`);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPatient(null);
  };

  const handleUnassign: (data: any) => void = (data: any) => {
    setSelectedPatient(data);
    setOpenModal(true);
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol()}
      tableRow={rows}
      tableAction={(data: MyPatientType) =>
        actionArr(
          () => handleChart(data),
          () => handlePatientDetails(data),
          () => handleTask(data),
          () => handleCall(data),
          () => handleUnassign(data)
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="33%"
        heading={"Unassign Patient"}
        content={
          <Modal.AssignPatient
            content={`${selectedPatient?.name} who enrolled to receive ${selectedPatient?.program} services will be unassigned from your list.`}
          />
        }
        open={openModal}
        onClose={() => setOpenModal(false)}
        buttonArr={CommonButtonArray(
          handleSaveModal,
          handleCloseModal,
          "Yes",
          "No"
        )}
      />
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol())}
        height={tableHeight}
        loading={loading}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={handleSetData}
            tableStyle={true}
          />
        }
      />
    </Fragment>
  );
};
export default MyPatients;
