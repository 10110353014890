import { ControlledForm } from "Components";
import { FormDataType } from "DataTypes/Form";
import { DeviceUOMType } from "DataTypes/Services/Device.type";
import {
  DeviceDetailInfo,
  GraphDataset,
} from "DataTypes/Services/Problem.type";
import { columnType } from "DataTypes/Table";
import DateTime from "Utils/DateTime";
export const TableCol = (data: DeviceUOMType[]): columnType[] => {
  const newArr: columnType[] = data.map((el) => {
    return {
      id: el.readingTypeUomId,
      label: `${el.readingTypeUomDescription} (${el.measureUomAbbreviation})`,
      width: `${60 / data.length}%`,
      align: "left",
      colType: "text",
      rowType: "text",
    };
  });
  return [
    {
      id: "datetime",
      label: "Date & Time",
      width: "25%",
      align: "left",
      colType: "text",
      rowType: "dateTimeSec",
    },
    ...newArr,
    {
      id: "actualDeviceId",
      label: "Device ID",
      width: "15%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
  ];
};

export const generateRows = (data: GraphDataset[]): any[] => {
  if (data?.length === 0) return [];

  const rows: any[] = [];

  // Create a set of all unique measurementId entries across all datasets
  const measurementIdSet = new Set<number | null>();
  data.forEach((item) => {
    item.data.forEach((entry) => {
      measurementIdSet.add(entry.measurementId);
    });
  });

  // Convert the set to an array and sort it
  const measurementIds = Array.from(measurementIdSet);

  // Create a map to store rows by datetime
  const rowMap: { [key: string]: any } = {};

  // Create rows for each measurementId
  measurementIds.forEach((measurementId) => {
    data.forEach((item) => {
      const dataPoint = item.data.find(
        (d) => d.measurementId === measurementId
      );
      if (dataPoint) {
        const datetimeKey = dataPoint.x; // Use datetime as the key for rows
        if (!rowMap[datetimeKey]) {
          rowMap[datetimeKey] = {
            datetime: datetimeKey,
            measurementId: measurementId,
          }; // Initialize the row
        }
        // Add value and actualDeviceId
        rowMap[datetimeKey][item.label.toLowerCase()] = dataPoint.y;
        rowMap[datetimeKey].actualDeviceId = dataPoint.actualDeviceId; // Store the actualDeviceId
      }
    });
  });

  // Convert the rowMap to an array and sort by datetime
  rows.push(...Object.values(rowMap));
  rows.sort(
    (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
  );

  return rows;
};

export const FormDataArr: () => FormDataType[] = () => {
  return [
    ControlledForm.FormData.StartDateTime({ label: true }),
    ControlledForm.FormData.EndDateTime({ label: true }),
  ];
};

export const transformData = (
  data: DeviceDetailInfo[],
  deviceType: string | null
): GraphDataset[] => {
  // Initialize an empty object to hold datasets
  const datasets: { [key: string]: GraphDataset } = {};

  // Helper function to generate unique colors
  const getColor = (index: number): string => {
    return colors[index % colors.length];
  };

  // Filter data by deviceType if needed
  const filteredData = data.filter((entry) => entry.deviceTypeId == deviceType);
  // Process each entry
  filteredData.forEach((entry) => {
    entry.readings.forEach((reading) => {
      // Use full timestamp to ensure uniqueness
      const timeStamp = DateTime.UTCToLocalDateTimeGraph(reading.recordedTime);
      // const parsedVal = Math.ceil(parseFloat(reading.value));
      const value = parseFloat(reading.value);
      const readingType = reading.readingType;
      const label = `${
        reading.readingTypeUomDescription || reading.readingType
      } (${reading?.abbreviation || ""})`;

      // Create dataset if it doesn't exist
      if (!datasets[readingType]) {
        datasets[readingType] = {
          label: label,
          data: [],
          borderColor: getColor(Object.keys(datasets).length),
          backgroundColor: `${getColor(Object.keys(datasets).length)}80`,
          fill: true,
          borderWidth: 2,
          pointRadius: 3,
          tension: 0.4,
          pointHoverRadius: 7,
          hitRadius: 10,
          shadow: {
            shadowColor: `${getColor(Object.keys(datasets).length)}80`,
            shadowBlur: 10,
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
        };
      }

      // Add data to the corresponding dataset
      if (timeStamp) {
        datasets[readingType].data.push({
          x: timeStamp,
          y: value,
          actualDeviceId: reading?.actualDeviceId || "",
          measurementId: reading?.measurementId,
        });
      }
    });
  });

  return Object.values(datasets);
};

export const formatDateUnit = (startDate: string, endDate: string) => {
  const daysDiff = DateTime.CalculateDurationInDays(startDate, endDate);
  if (daysDiff === 0) {
    return "hour"; // Single date
  }
  // else if (daysDiff <= 3) {
  //   return "hour"; // 2-3 days
  // }
  else if (daysDiff < 120) {
    // Less than 4 months
    return "day"; // More than 3 days but less than 4 months
  } else {
    return "month"; // More than 4 months
  }
};

export const colors = [
  "#002255",
  "#EE7404",
  "#1077BB",
  "pink",
  "brown",
  "grey",
  "purple",
  "orange",
];

export const getDateRange = (startDate: Date, endDate: Date) => {
  const dates = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};
