import { buttonArrType } from "DataTypes/Modal";
import { FormDataType } from "DataTypes/Form";
import ControlledForm from "Components/ControlledForm";
import { TimeZoneArr } from "Constant/Arr";

export const TimeModalFormData: FormDataType[] = [
  ControlledForm.FormData.StartDateTime(),
  ControlledForm.FormData.EndDateTime(),
  ControlledForm.FormData.Reason(),
  ControlledForm.FormData.Duration(),
  ControlledForm.FormData.Notes(),
];

export const bpdataArr: FormDataType[] = [
  {
    id: "diastolic",
    label: "Diastolic",
    placeholder: " ",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "name",
    required: true,
  },
  {
    id: "systolic",
    label: "Systolic",
    placeholder: " ",
    boxType: "input-box",
    boxSize: 4,
    validatetype: "name",
    required: true,
  },
];
export const hrdataArr: FormDataType[] = [
  {
    id: "low",
    label: "Low",
    placeholder: "",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
    required: true,
  },
  {
    id: "high",
    label: "High",
    placeholder: " ",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
    required: true,
  },
];
export const gludataArr: FormDataType[] = [
  {
    id: "low",
    label: "Low",
    placeholder: "",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
    required: true,
  },
  {
    id: "high",
    label: "High",
    placeholder: " ",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
    required: true,
  },
];
export const thrdataArr: FormDataType[] = [
  {
    id: "low",
    label: "Low",
    placeholder: "",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
    required: true,
  },
  {
    id: "high",
    label: "High",
    placeholder: " ",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
    required: true,
  },
];
export const pluoxdataArr: FormDataType[] = [
  {
    id: "oxsolu",
    label: "Oxygen Saturation % ",
    placeholder: " ",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "name",
    required: true,
  },
];

export const CommonButtonArray: (
  ConfirmHandler: () => void,
  CancelHandler: () => void,
  SaveLabel?: string,
  CancelLabel?: string,
  disableParameter?: boolean
) => buttonArrType[] = (
  ConfirmHandler,
  CancelHandler,
  SaveLabel,
  CancelLabel,
  disableParameter
) => {
  return [
    {
      id: "1",
      label: SaveLabel ? SaveLabel : "Save",
      clickHandler: ConfirmHandler,
      disable: disableParameter,
    },
    {
      id: "2",
      label: CancelLabel ? CancelLabel : "Cancel",
      clickHandler: CancelHandler,
      type: "border",
    },
  ];
};

export const DeleteButtonArray: (
  ConfirmHandler: () => void,
  CancelHandler: () => void
) => buttonArrType[] = (ConfirmHandler, CancelHandler) => {
  return [
    {
      id: "1",
      label: "Yes",
      clickHandler: ConfirmHandler,
    },
    {
      id: "2",
      label: "No",
      clickHandler: CancelHandler,
      type: "border",
    },
  ];
};

export const SingleBtnArray: (
  OkHandler: () => void,
  BtnLabel?: string
) => buttonArrType[] = (OkHandler, BtnLabel) => {
  return [
    {
      id: "1",
      label: BtnLabel ? BtnLabel : "Ok",
      clickHandler: OkHandler,
    },
  ];
};

export const AppointmentFormData: (edit: boolean) => FormDataType[] = (
  edit
) => {
  return [
    ControlledForm.FormData.PatientSearch(),
    ...(edit
      ? [
        ControlledForm.FormData.AppointmentTypeName(),
        ControlledForm.FormData.DeviceTypeName(),
      ]
      : [
        ControlledForm.FormData.AppointmentType(),
        ControlledForm.FormData.DeviceType(),
      ]),
    ControlledForm.FormData.OrgName(),
    ControlledForm.FormData.ProviderName(),
    ControlledForm.FormData.AppointmentDateTime(),
    ControlledForm.FormData.TimezoneData({ dropArr: TimeZoneArr, boxSize: 6 }),
    ControlledForm.FormData.AppointmentNotes(),
  ];
};
