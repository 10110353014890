import {
  TrainingCallLogsListType,
  TrainingListType,
  TrainingNotesListType,
} from "DataTypes/Services/Training.type";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "./common";
import DateTime from "./DateTime";
import PatientFormatter from "./Patient.Formatter";
import { DropdownArrPropsType } from "DataTypes/Form";

const TrainingList: (data: any) => TrainingListType[] = (data) => {
  const checkedArr = CheckArr(data);
  return checkedArr.map((el) => CheckTrainingType(el));
};

const CheckTrainingType: (data: any) => TrainingListType = (data) => {
  const providerName = CheckStringEmpty(data?.teamProviderName);
  const providerDes = CheckStringEmpty(data?.teamProviderDesignation);
  const teamProvider = providerName
    ? providerName + `${providerDes ? ", " + providerDes : ""}`
    : "";
  return {
    ...PatientFormatter.ListPatientInfo1(data),
    ...PatientFormatter.CheckPatientDevice(data),
    careplanId: CheckNumber(data?.careplanId),
    location: CheckStringEmpty(data?.location1),
    locationId: CheckNumber(data?.locationId),
    name: CheckStringEmpty(data?.patientName),
    patientId: CheckStringId(data?.patientId),
    practice: CheckStringEmpty(data?.practiceName),
    practiceId: CheckNumber(data?.practiceId),
    programId: CheckNumber(data?.programId),
    provider: CheckStringEmpty(data?.providerName),
    providerId: CheckNumber(data?.providerId),
    trainingActivity: CheckStringEmpty(data?.activity),
    teamProvider: teamProvider,
    teamProviderId: CheckNumber(data?.teamProviderId),
  };
};

const TrainingCallLogList: (
  data: any,
  callResultList: DropdownArrPropsType[]
) => TrainingCallLogsListType[] = (data, callResultList) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    const find = callResultList.find(
      (dropEl) => dropEl.value == el?.callResult
    );
    return {
      id: CheckNumber(el?.id),
      callername: CheckStringEmpty(el?.callerName),
      datetime: DateTime.ValidDate(el?.createdAt),
      deviceName: CheckStringEmpty(el?.deviceName),
      callresult: CheckStringEmpty(find ? find.label : el?.callResult),
      nextcall: DateTime.ValidDate(el?.nextCallDateTime),
      note: CheckStringEmpty(el?.notes),
      trainingActivity: CheckStringEmpty(el?.activity),
    };
  });
};

const TrainingNotesList: (data: any) => TrainingNotesListType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      datetime: DateTime.ValidDate(el?.createdAt),
      tnotes: CheckStringEmpty(el?.note),
    };
  });
};

export default {
  TrainingList,
  TrainingCallLogList,
  TrainingNotesList,
};
