export const label = {
  firstname: "First Name",
  lastname: "Last Name",
  email: "E-mail",
  oderBy: "Display Sequence Number",
  quesCount: "Question Count",
  userId: "UserID",
  password: "Password",
  dob: "Date Of Birth",
  login: "Login",
  forget: "Forget Password",
  cm: "Care Management",
  assessment: "Assessment",
  search: "Search",
  filter: "Filter",
  today: "Today's",
  overdue: "Overdue",
  schedule: "Schedule",
  scheduleDate: "Scheduled",
  enrolled: "Enrolled",
  enrolledPatient: "Enrolled",
  proname: "Program Name",
  selectpractice: "Select Organization",
  selectrole: "Select Role",
  selectmonth: "Select Month",
  selectyear: "Select Year",
  goback: "Go Back",
  edit: "Edit",
  confirm: "Confirm",
  forward: "Forward",
  group: "Group",
  point: "Point",
  task: "Task",
  newconsentgained: "New Consent Gained",
  enrollunderprogram: "Enroll under Program",
  recipient: "Recipient",
  savenext: "Save & Next",
  filterByStatus: "Filter Status",
  filterByPriority: "Filter Priority",

  //Header
  home: "Home",
  cvstaff: "CareVitality Staff",
  master: "Master",
  practicemanagement: "Organizations",
  settings: "Settings",
  categoriesmaster: "Categories Master",
  catName: "Category Name",
  programsmaster: "Programs Master",
  problemsmaster: "Problems Master",
  questionnaire: "Questionnaire",
  questionnairelist: "Questionnaire List",
  personalInfo: "Personal Info",
  assignPractice: "Assign Organization",
  accessLevel: "Access Level",
  categorymaster: "Category Master",
  problemlist: "Problem List",
  icd10codes: "ICD-10 Codes",
  modelmaster: "Model Master",
  heartchart: "Heart Rate Chart",
  BpChart: "Blood Pressure Chart",
  BpHead: "Blood Pressure",
  HrHead: "Heart Rate",
  GluCGMHead: "Glucometer/CGM",
  TmHead: "Thermometer",
  PoHead: "Pulse Oximeter",
  glucosechart: "Glucose Chart",
  tcmFull: "Transitional Care Management",
  tcmDischarge: "TCM Discharge Summary",
  dischargeSummary: "Discharge Summary",
  alertRpm: "Patient Remote Monitoring Alerts",
  alertHead: "Remote Monitoring Alerts",
  patientalertRpm: "Patient's Remote Monitoring Alerts",

  //SideBar Navigation
  profile: "Profile",
  find: "Find",
  problems: "Problems",
  taskvisit: "Tasks & Visits",
  tasks: "Tasks",
  visits: "Visits",
  pastvisits: "Past Visits",
  meds: "Meds",
  medications: "Medications",
  allergies: "Allergies",
  vaccines: "Vaccines",
  vitals: "Vitals",
  result: "Results",
  labresults: "Lab Results",
  quality: "Quality",
  careplan: "Care Plan",
  tcmPlan: "TCM",
  careteam: "Care Team",
  ccmnotes: "Previous CCM Notes",

  //patient labels
  patientname: "Patient's Name",
  patientid: "Patient's ID",
  patientinfo: "Patient's Info",
  patientdetails: "Patient's Details",
  patientconsent: "Patient's Consent",
  patientprofile: "Patient's Profile",

  //Program labels
  prgmoverview: "Program Overview",

  //screen labels
  ep: "Eligible Patients",
  eligible: "Eligible",
  pcp: "Pending Assignment",
  mp: "My Patients",
  ppa: "Pending Care Plan Approval",
  declined: "Declined",
  closed: "Closed",
  unresponsive: "Unresponsive",
  unresponsivePatient: "Unresponsive",
  ongoing: "Ongoing",
  ongoingPatient: "Ongoing",
  billing: "Billing",
  gs: "Goal Sheets",
  pcc: "Continuous Care",
  ccc: "Completed Continuous Care",
  cpt: "Completed Trainings",
  consent: "Consent",
  consentGained: "Consent Gained",
  ct: "Create Task",
  qc: "Question Categories",
  chart: "Clinical Summary",
  cd: "Call Details",
  adddevice: "Patients > Thomas Lima",
  heartratedashboard: "Dashboard > Heart Rate Chart",
  BpBreadCrumbChart: "Dashboard > Blood Pressure Chart",
  taskVisit: "Task-Visit",
  myTask: "Tasking",
  completedTask: "Completed Task",
  incoming: "Incoming",
  incomingTask: "Incoming Task",
  outingTask: "Outgoing Task",
  outgoing: "Outgoing",
  groupTask: "Group Task",
  globalTask: "Global Tasks",
  cretedByMeTask: "Task Created By Me",
  Brpat: "Dashboard > Patient Alert Thresholds",
  glucosedashboard: "Dashboard > Glucose Chart",
  naText: "--",
  unread: "Unread",
  open: "Open",
  addressed: "Addressed",

  //Tile Label
  el: "Eligible",
  subel: "",
  pend: "Pending",
  pendingAssignment: "Pending Assignment",
  subpend: "Care Plan",
  mypt: "My",
  submypt: "Patients",
  submyptSuper: "Enrolled",
  pendpr: "Pending Plan Approval",
  subpendpr: "Care Plan Approval",
  continuousCare: "Pending Continuous Care",
  subpendcc: "Care",
  comcc: "Completed Continuous Care",
  inactive: "Inactive",
  subcomcc: "Care",
  optout: "Unresponsive",
  subongpt: "Patients",
  suboptout: "Patients",
  clpt: "Closed",
  subclpt: "",
  dcpt: "Declined",
  subdcpt: "",
  ongpt: "Ongoing",
  comtr: "Completed",
  subcomtr: "Training",

  //column labels
  datetime: "Date & Time",
  name: "Name",
  importance: "Importance",
  designation: "Designation",
  role: "Role",
  mobileNo: "Mobile No.",
  officeNo: "Office No.",
  homeNumber: "Home No.",
  directNo: "Direct No.",
  abbreviation: "Abbreviation",
  date: "Date",
  time: "Time",
  provider: "Provider",
  source: "Source",
  clinicalStaff: "Clinical Staff",
  pn: "Provider's Name",
  activity: "Activity",
  imp: "Importance",
  action: "Actions",
  primins: "Primary Insurance",
  secins: "Secondary Insurance",
  programs: "Programs",
  status: "Status",
  billableprovide: "Billabe Provider",
  careteamtime: "CareVitality Care Team Time",
  careteamclinictime: "Clinic Care Team Time (mins)",
  providerclinictime: "Clinic Provider Time (mins)",
  totaltime: "Total Time (mins)",
  codebilled: "Code Billed",
  practicename: "Organization Name",
  carecoordination: "Care Coordination",
  primcarecoordinator: "Primary Care Coordinator",
  duedate: "Date of Completion",
  enrollmentdate: "Enrollment Date",
  consentgainedby: "Consent Gained By",
  cpinitiation: "CP Initiation",
  careplancompletion: "Care Plan Completion",
  lastcall: "Last Call",
  reason: "Reason",
  activities: "Activities",
  appointmenttime: "Appointment Time",
  practice: "Organization",
  timespent: "Time Spent",
  pointsearned: "Points Earned",
  callcounts: "Call Counts",
  categorylist: "Category List",
  programlist: "Program List",
  model: "Model",
  staff: "Staff",
  sbp: "SBP",
  dbp: "DBP",
  bpm: "BPM",
  appointmentminimumlength: "Appointment's Minimum Length",
  applicableCPTcode: "Applicable CPT Code",
  deviceTypeDescription: "Device Type",
  deviceName: "Device Name",
  supplierName: "Supply Name",
  expectedArrival: "Expected Arrival",
  assignedby: "Assigned By",
  mgdl: "mg/dL",
  staffname: "Staff Name",
  cvcareteamtime: "CV Care Team Time (mins)",
  tasktype: "Task type",
  priority: "Priority",
  assignee: "Assignee",
  note: "Notes",
  subject: "Subject",
  createdby: "Created By",
  alertdescription: "Alert Description",
  alertDetails: "Alert Details",
  alerttime: "Alert Date and Time",
  objectives: "Objectives",
  interventions: "Interventions",
  goals: "Goals",
  companyName: "Company Name",
  relation: "Relation",
  planName: "Plan Name",
  effectiveDate: "Start Date",
  expirationDate: "End Date",
  policyNumber: "Policy Number",
  visitNumber: "Visit number",
  facility: "Facility",
  type: "Type",
  dataModel: "Data Model",
  sourceId: "Source Id",
  sourceName: "Source Name",
  destinationId: "Destination Id",
  destinationName: "Destination Name",
  webhookToken: "Webhook Token",
  webhookTokenExpireAt: "webhook Token Expire At",
  keyLabel: "Key Label",
  value: "Value",
  trainingTypeDescription: "Training Type",
  deviceReading: "Reading Taken",
  trainer: "Trainer",
  readingDays: "Number of days of readings",

  //Revision
  revision: "Revision",
  revisionInitiatedBy: "Initiated By",

  ///Home screen dashboard label
  min014: "0-14 Mins",
  min1519: "15-19 Mins",
  min2039: "20-39 Mins",
  min4059: "40-59 Mins",
  min3059: "30-59 Mins",
  min60: "60+ Mins",
  min20: "20+ Mins",
  min019: "0-19 Mins ",
  tcmopportunity: "TCM Opportunity",
  completed: "Completed",
  billed: "Billed",
  ptDevices: "Patients with Devices",
  myTraining: "My Trainings",
  pendingTraining: "Pending Trainings",
  pendingDevice: "Pending Device",
};

export const Locale = {
  //UI Locale
  date: "MM/DD/YYYY",
  dateTime: "MM/DD/YYYY, hh:mm A",
};
