import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataArr } from "./Data";
import PatientApi from "Service/Patient.api";
import PatientFormatter from "Utils/Patient.Formatter";
import { LoggedTimeType } from "DataTypes/Services/Patient.type";
import {
  ListInitialStateType,
  PatientInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { CMTabSelectedEnrollmentType } from "DataTypes/Modal";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import NoData from "Components/NoData";
import DateTime from "Utils/DateTime";
import { FormDataType } from "DataTypes/Form";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import DataFormatter from "Utils/DataFormatter";

const AddonLogTime: FC<CMTabSelectedEnrollmentType> = ({ prgmDetails }) => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { careplanId, programId } = prgmDetails;
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const {
    addTimeLogType,
    addRPMTimeLogType,
    programList,
    trainingReq,
  }: ListInitialStateType = useSelector((state: any) => state?.list);
  const RPMTimeLogList = [...addTimeLogType, ...addRPMTimeLogType];
  const [rowIndex, setRowIndex] = useState<number>(0);
  const [rows, setRows] = useState<LoggedTimeType[]>([]);
  const [formData, setFormData] = useState<FormDataType[]>(
    JSON.parse(JSON.stringify(DataArr()))
  );
  const prgDropData = DataFormatter.FormatDropdownData(
    programList,
    "id",
    "abbrevation",
    "id"
  );

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    initialiseFormData();
  }, [rows, rowIndex]);

  const initialiseFormData = (): void => {
    const selected = rows && rows[rowIndex] && rows[rowIndex];
    const formArr: FormDataType[] = JSON.parse(JSON.stringify(formData));
    formArr[0].dropValue = selected?.programId;
    formArr[0].dropArr = prgDropData;
    formArr[1].value = selected?.activity;
    formArr[2].value = DateTime.UTCToLocalDateTime(selected?.startdatetime);
    formArr[3].value = DateTime.UTCToLocalDateTime(selected?.enddatetime);
    formArr[4].dropArr =
      selected?.programId == 5 ? RPMTimeLogList : addTimeLogType;
    formArr[4].dropValue = selected?.activitytype;
    formArr[5].value = selected?.duration;
    formArr[6].dropArr = trainingReq;
    formArr[6].dropValue = selected?.interactiveCommunication ? "Yes" : "No";
    formArr[6].boxType = programId == 5 ? "single-select-drop" : "empty-block";
    formArr[7].value = selected?.note;
    setFormData(formArr);
  };

  const setData: () => void = async () => {
    const payload = {
      patientId: patientInfo.id,
      userId: myInfo.id,
      state: "addon time",
      programId: programId,
      careplanId: careplanId,
    };
    const res = await PatientApi.TimeLogSearch({ data: payload });
    if (res.success) {
      const data = PatientFormatter.LoggedTimeList(res?.data);
      setRows(data);
    }
  };

  return (
    <Box width="100%" height="100%" display="flex">
      <Box width="20%" height="100%">
        <Tabs
          value={rowIndex}
          onChange={(event, index) => setRowIndex(index)}
          aria-label="basic tabs example"
          variant="scrollable"
          indicatorColor="secondary"
          orientation="vertical"
          scrollButtons="auto"
          sx={{
            borderRight: "1px solid",
            borderColor: "custom.main",
            height: "100%",
          }}
        >
          {rows.map((el, index) => {
            return (
              <Tab
                sx={{ ...sxFilterPrgmTab }}
                key={el.id}
                label={
                  <Box
                    width="100%"
                    sx={{
                      ...(index == rowIndex ? sxListBoxSelected : sxListBox),
                    }}
                  >
                    <Typography variant="subtitle1">
                      {DateTime.UTCToLocalDateTime(el.startdatetime)}
                    </Typography>
                  </Box>
                }
                id={`simple-tab-${el.id}`}
                aria-controls={`simple-tabpanel-${el.id}`}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box width="80%" height="100%" pl={2}>
        {rows && rows[rowIndex] && rows[rowIndex] ? (
          <Box height="90%">
            <Grid container columnSpacing={2} sx={{ mb: 1 }}>
              {formData && formData.length > 0
                ? formData.map((el: FormDataType) => {
                  return (
                    <Grid
                      key={el.id}
                      item
                      {...ControlledBoxSizeSwitch(el.boxSize)}
                    >
                      {commonFormSwitch({
                        formObj: el,
                      })}
                    </Grid>
                  );
                })
                : null}
            </Grid>
          </Box>
        ) : (
          <NoData />
        )}
      </Box>
    </Box>
  );
};
export default AddonLogTime;

const sxFilterPrgmTab = {
  minHeight: 0,
  minWidth: 0,
  py: 1,
  px: 0,
};

const sxListBox = {
  "& >h6": {
    color: "custom.dark",
  },
};

const sxListBoxSelected = {
  "& >h6": {
    color: "primary.main",
  },
};
