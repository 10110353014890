///Bug resolve azure-394

import { Fragment, FC, useEffect, useState } from "react";
import { Box, Breadcrumbs, IconButton, Typography } from "@mui/material";
import { CommonStyle, Modal } from "Components";
import { label } from "Constant/En";
import ImgPath from "Constant/Imgs";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import {
  PatientInitialStateType,
  ScreenInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch } from "react-redux";
import { clearScreenData } from "Redux/Screen/Action";
import CstmBtn from "Components/CstmBtn";
import { RouteUrls } from "Constant/RouteUrls";
import { ModalLayout } from "Layouts";
import StartStopLogTime from "Components/StartStopLogTime";
import CstmTooltip from "Components/CstmTooltip";

const HeadScreen: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathArr = location.pathname.split("/");
  const currentPath = pathArr[pathArr.length - 1];
  const {
    navArr,
    leftHead,
    rightHead,
    rightHead: { isOnlyTimer },
  }: ScreenInitialStateType = useSelector((state: any) => state?.screen);
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const {
    patientInfo,
    selectedPrgm,
    rpmPrgm,
    selectedDevice,
  }: PatientInitialStateType = useSelector((state: any) => state?.patient);
  const [timeModal, setTimeModal] = useState(false);
  const [thresModal, setThresModal] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [trainingNotesModal, setTrainingNotesModal] = useState(false);
  const [clinicalNotesModal, setClinicalNotesModal] = useState(false);
  const deviceList =
    rpmPrgm.patientDevices &&
    rpmPrgm.patientDevices.filter(
      (item) => item.deviceId !== selectedDevice.deviceId
    );

  useEffect(() => {
    return () => {
      dispatch(clearScreenData());
    };
  }, []);

  const handleRightHeadSwitch: (id: string) => JSX.Element = (id) => {
    switch (id) {
      case "patient-details":
        return (
          <Box sx={{ ...CommonStyle.sxRow, width: "100%" }}>
            <IconButton
              sx={{ ...CommonStyle.sxRoundIconsMainMd, mr: 1 }}
              title={label.ct}
              onClick={() =>
                navigate(
                  `${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.task}/${RouteUrls.add}`
                )
              }
            >
              <ImgPath.CreateTaskIcon />
            </IconButton>

            {myInfo.roleId !== 6 &&
              myInfo.roleId != 1 &&
              myInfo.roleId != 2 && (
              <IconButton
                sx={{
                  ...(myInfo.roleId == 1 || myInfo.roleId == 2
                    ? CommonStyle.sxRoundIconsDisabledMd
                    : CommonStyle.sxRoundIconsMainMd),
                  mr: 1,
                }}
                title="Log Time"
                onClick={() =>
                  navigate(
                    `${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.addonTime}`
                  )
                }
              >
                <ImgPath.AddTimeLogIcon />
              </IconButton>
            )}
          </Box>
        );
      case "care-plan":
        return (
          <Fragment>
            {selectedPrgm.showLogTime && (
              <StartStopLogTime
                openLogModal={() => setTimeModal(true)}
                openTaskModal={() => setTaskModal(true)}
                openPatientThresholdModal={() => setThresModal(true)}
                isOnlyTimer={isOnlyTimer}
              />
            )}
          </Fragment>
        );
      case "org-list":
        return (
          <CstmBtn
            sxProps={{ ml: 1 }}
            onClick={() =>
              navigate(`${RouteUrls.organization}/${RouteUrls.add}`)
            }
            label="Add Organization"
          />
        );
      default:
        return <Fragment />;
    }
  };

  const handleBreadcrubIcons: (id: string) => JSX.Element = (id) => {
    switch (id) {
      case "patient-notes":
        return (
          <CstmTooltip
            title={
              <Typography color="white" variant="body2">
                {patientInfo?.notes}
              </Typography>
            }
            icon={<ImgPath.WarningRoundIcon />}
            tooltipDelay={2000}
            autoTooltipDisplay={patientInfo?.firstTimeNotesVisit}
            sxProps={{ ...Tooltip }}
          />
        );
      default:
        return <Fragment />;
    }
  };

  const handleLeftHeadSwitch: (id: string) => JSX.Element = (id) => {
    switch (id) {
      case "patient-details":
        return (
          <CstmBtn
            onClick={() =>
              navigate(
                `${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.clinicalSummary}`
              )
            }
            label="Clinical Summary"
          />
        );
      case "patient-clinical-summary":
        return (
          <Fragment>
            <CstmBtn
              onClick={() =>
                navigate(
                  `${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.thresholds}`
                )
              }
              label="Patient Thresholds"
            />
            <CstmBtn
              sxProps={{ ml: 1 }}
              onClick={() => setTrainingNotesModal(true)}
              label="Training Notes"
            />
            <CstmBtn
              sxProps={{ ml: 1 }}
              onClick={() => setClinicalNotesModal(true)}
              label="Clinical Notes"
            />
          </Fragment>
        );
      case "device-details":
        return (
          <Fragment>
            {deviceList &&
              deviceList.map((item) => (
                <CstmBtn
                  key={item.deviceId}
                  label={item.deviceTypeDescription}
                  onClick={() => {
                    navigate(
                      `${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.device}/${item.patientDeviceId}/${RouteUrls.deviceDetails}`
                    );
                  }}
                  sxProps={{ mr: 1 }}
                />
              ))}
          </Fragment>
        );
      default:
        return <Fragment />;
    }
  };

  const BreadUI = (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ ...BreadcrumbList }}
      >
        {navArr.map((el) => {
          if (currentPath == el.link || el.disabled) {
            return (
              <Typography
                key={el.id}
                variant="body2"
                fontWeight="bold"
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  svg: {
                    fontSize: "1.4rem",
                    ml: 0.6,
                    path: {
                      fill: "#EE7404",
                    },
                  },
                }}
              >
                {el.label}
                {el?.icon &&
                  patientInfo?.notes &&
                  handleBreadcrubIcons(el?.icon)}
              </Typography>
            );
          }
          return (
            <Typography
              key={el.id}
              variant="body2"
              fontWeight="bold"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                svg: {
                  fontSize: "1.4rem",
                  ml: 0.6,
                  path: {
                    fill: "#EE7404",
                  },
                },
              }}
            >
              <Link
                underline="hover"
                color="inherit"
                onClick={() => navigate(el.path, { replace: true })}
              >
                {el.label}
              </Link>
              {el?.icon && patientInfo?.notes && handleBreadcrubIcons(el?.icon)}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="50%"
        heading={"Log Time"}
        content={<Modal.LogTimeModal setClose={() => setTimeModal(false)} />}
        open={timeModal}
        onClose={() => setTimeModal(false)}
      />
      <ModalLayout
        modwidth="80%"
        heading={"Patient Alert Thresholds"}
        modheight="80%"
        content={
          <Modal.ThresholdsModal setClose={() => setThresModal(false)} />
        }
        open={thresModal}
        onClose={() => setThresModal(false)}
      />
      <ModalLayout
        modwidth="55%"
        heading={"Create Task"}
        content={
          <Modal.Task from="care plan" setClose={() => setTaskModal(false)} />
        }
        open={taskModal}
        onClose={() => setTaskModal(false)}
      />
      <ModalLayout
        modwidth="50%"
        heading={"Training Notes"}
        content={
          <Modal.TrainingNotesModal
            setClose={() => setTrainingNotesModal(false)}
          />
        }
        open={trainingNotesModal}
        onClose={() => setTrainingNotesModal(false)}
      />
      <ModalLayout
        modwidth="50%"
        heading={"Clinical Notes"}
        content={
          <Modal.ClinicalNotesModal
            setClose={() => setClinicalNotesModal(false)}
          />
        }
        open={clinicalNotesModal}
        onClose={() => setClinicalNotesModal(false)}
      />
      <Box
        height="8%"
        sx={{
          ...CommonStyle.sxRow,
          justifyContent: rightHead.id ? "space-between" : "flex-start",
        }}
      >
        <Box sx={{ ...CommonStyle.sxRow, mr: 2 }}>
          <IconButton
            title={label.goback}
            onClick={() => {
              const findIndex = navArr.findIndex(
                (el) => el.link == currentPath
              );
              if (findIndex < 1) navigate("");
              else navigate(navArr[findIndex - 1].path);
            }}
            sx={{ p: 0, ...BackIconBox }}
          >
            <ImgPath.BreadcrumbIcon />
          </IconButton>
          {BreadUI}
          {leftHead.id && (
            <Box sx={{ ...CommonStyle.sxRow, ml: 1 }}>
              {handleLeftHeadSwitch(leftHead.id)}
            </Box>
          )}
        </Box>
        <Box sx={{ ...CommonStyle.sxRow }}>
          {handleRightHeadSwitch(rightHead.id)}
        </Box>
      </Box>
      <Outlet />
    </Fragment>
  );
};
export default HeadScreen;

const BackIconBox = {
  backgroundColor: "common.white",
  padding: 0.8,
  borderRadius: 2,
  mr: 1.2,
  transition: "all 0.6s",
  "&:hover": {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 14px 0px #c7c7c7",
    transition: "all 0.6s",
  },
  svg: {
    width: "0.9em",
    height: "0.9em",
  },
};

const BreadcrumbList = {
  "& > ol": {
    "& > li[aria-hidden='true']": {
      margin: "unset",
    },
  },
};

const Tooltip = {
  ".MuiTooltip-popper": {
    ".css-1h40q88-MuiTooltip-tooltip": {
      fontSize: "smaller",
    },
  },
};
