import {
  DeviceDetailInfo,
  DeviceReadingTableType,
} from "DataTypes/Services/Problem.type";
import { CheckStringEmpty } from "Utils/common";
import DateTime from "Utils/DateTime";

const TabularReadings: (
  data: DeviceDetailInfo[]
) => DeviceReadingTableType[] = (data) => {
  const newArr: DeviceReadingTableType[] = [];
  data[0]?.readings.forEach((el) => {
    const index = newArr.findIndex(
      (findEl) => findEl.measurementId == el.measurementId
    );
    if (index == -1) {
      newArr.push({
        actualDeviceId: CheckStringEmpty(el?.actualDeviceId),
        datetime: DateTime.ValidDate(el?.recordedTime),
        id: el.measurementId,
        measurementId: el.measurementId,
        [el.readingType]: el.value,
      });
    } else {
      newArr[index] = { ...newArr[index], [el.readingType]: el.value };
    }
  });
  return newArr.reverse();
};

export default { TabularReadings };
