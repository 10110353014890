import { FC, useEffect } from "react";
import Router from "./Router";
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./CstmTheme/Theme";
import { BrowserRouter } from "react-router-dom";
import OverlayLoader from "Components/OverlayLoader";
import AlertPopUp from "Components/AlertPopUp";
import { useDispatch } from "react-redux";
import { clearPatient } from "Redux/Patient/Action";
import { clearUser } from "Redux/User/Action";
import { clearListData } from "Redux/List/Action";
import { clearScreenData } from "Redux/Screen/Action";


const App: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearPatient());
      dispatch(clearUser());
      dispatch(clearListData());
      dispatch(clearScreenData());
      localStorage.clear();
    };
  }, []);

  return (
    <ThemeProvider theme={appTheme}>
      <OverlayLoader />
      <AlertPopUp />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
