import { ChangePasswordType } from "DataTypes/Services/User.type";
import { post } from ".";
import { ApiServiceProps } from "DataTypes/Services/Index.type";

const signIn: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: "user/login",
    data: { data: data?.data },
    isAuth: true,
  };
  return post(request);
};

const verifyOtp: (data: ApiServiceProps) => any = (data) => {
  const request = {
    subUrl: "user/verify-otp",
    data: data.data,
    isAuth: true,
  };
  return post(request);
};

const forget: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "user/forgot/password",
    data: { data: payload.data },
    isAuth: true,
  };
  return post(request);
};

const reset: (data: any) => any = (data) => {
  const request = {
    subUrl: "user/password/reset",
    data,
    isAuth: true,
  };
  return post(request);
};

const signOut: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "user/logout",
    data: { data: payload.data },
    isAuth: true,
  };
  return post(request);
};

export const changesPwd = (userId: number | null, data: ChangePasswordType) => {
  const request = {
    subUrl: `user/${userId}/changepassword`,
    data,
    isAuth: true,
  };
  return post(request);
};

export default { signIn, verifyOtp, forget, reset, signOut };
