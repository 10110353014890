import { ControlledTable } from "Components";
import FilterData from "Components/Filter2/Data";
import { Filter2DataProps } from "DataTypes/Common";
import { FormDataType } from "DataTypes/Form";
import { actionArrType, columnType } from "DataTypes/Table";

export const FormDataArr: FormDataType[] = [
  {
    id: "practice",
    label: "Organization Name",
    placeholder: "Select Organization",
    boxType: "input-box",
    boxSize: 6,
    validatetype: "org-name",
    readOnly: true,
  },
  {
    id: "location",
    label: "Location Name",
    placeholder: "Select Location",
    boxType: "single-select-drop",
    dropArr: [],
    boxSize: 6,
    validatetype: "dropdown",
  },
];

export const actionArr: (
  handleView: () => void,
  handleImportFile: () => void,
  handleFailedFile: () => void,
  hideImportFileButton: boolean
) => actionArrType[] = (
  handleView,
  handleImportFile,
  handleFailedFile,
  hideImportFileButton
) => {
  return [
    ControlledTable.ActionData.viewTitleAction(handleView),
    ...(hideImportFileButton
      ? [
        ControlledTable.ActionData.downloadAction(
          handleImportFile,
          "Download Uploaded File"
        ),
      ]
      : []),
    ControlledTable.ActionData.downloadAction(
      handleFailedFile,
      "Download Failed File"
    ),
  ];
};

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.StartDateTimeCol("9%"),
  ControlledTable.ColumnData.EndDateTimeCol("10%"),
  ControlledTable.ColumnData.UserFullNameCol("9%"),
  ControlledTable.ColumnData.LocationNameCol("10%"),
  ControlledTable.ColumnData.FileNameCol("9%"),
  ControlledTable.ColumnData.StatusCol("9%"),
  ControlledTable.ColumnData.ActionCol("9%"),
];

export const FilterArr = (): Filter2DataProps[] => {
  return [
    // FilterData.title,
    FilterData.locationName,
    FilterData.startDate,
    FilterData.endDate,
    FilterData.userName,
    FilterData.status,
  ];
};
