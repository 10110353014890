import { FC, useState, useEffect } from "react";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import {
  CommonStyle,
  ControlledForm,
  ControlledTable,
  Loader,
} from "Components";
import { PrgmPrbmBoxProps, ProbListProps } from "DataTypes/Master";
import { ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { ProblemListType } from "DataTypes/Services/Problem.type";
import { OptionsProps } from "DataTypes/Form";
import CstmBtn from "Components/CstmBtn";
import NoData from "Components/NoData";
import { LimitArr2 } from "Constant/DropArr";
import ProblemFormatter from "Utils/Prob.Formatter";
import ProblemApi from "Service/Problem.api";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import ProgramApi from "Service/Program.api";
import ProbFormatter from "Utils/Prob.Formatter";
import { GetUniqueArr } from "Utils/common";
import { ListInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const ProblemBox: FC<PrgmPrbmBoxProps> = (props) => {
  const { selectedPrgm } = props;
  const dispatch = useDispatch();
  const { weightscaleproblem }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const [headHeight, setHeadHeight] = useState(60);
  const [pageHeight, setPageHeight] = useState(40);
  const [infoHeight, setInfoHeight] = useState(20);
  const headOffset = document?.getElementById("head")?.offsetHeight;
  const pageOffset = document?.getElementById("page")?.offsetHeight;
  const infoOffset = document?.getElementById("info-box")?.offsetHeight;
  const tableHeight = `calc(100% - ${headHeight + pageHeight + infoHeight}px)`;
  const [problemList, setProblemList] = useState<ProblemListType[]>([]);
  const [probPage, setProbPage] = useState(1);
  const [probLimit, setProbLimit] = useState(100);
  const [totalProb, setTotalProb] = useState(0);
  const [totalSearchProb, setTotalSearchProb] = useState(0);
  const [mappedList, setMappedList] = useState<(number | null)[]>([]);
  const [localChanges, setLocalChanges] = useState<(number | null)[]>([]);
  const [loading, setLoading] = useState(true);
  const [disableSave, setDisableSave] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setHeadHeight(headOffset || 60);
  }, [headOffset]);

  useEffect(() => {
    setPageHeight(pageOffset || 40);
  }, [pageOffset]);

  useEffect(() => {
    setInfoHeight(infoOffset || 20);
  }, [infoOffset]);

  useEffect(() => {
    selectedPrgm?.id && InitialLoad();
  }, [selectedPrgm?.id]);

  const InitialLoad: () => void = async () => {
    await getProblemList({ page: 1, limit: probLimit, probName: "" });
    await getLinkedProblems();
  };

  const getProblemList: (props: ProbListProps) => Promise<void> = async (
    props
  ) => {
    setLoading(true);
    const { page, limit, probName } = props;
    const offset = (page - 1) * limit;
    const res = await ProblemApi.list({
      limit: limit,
      offset,
      data: { problemName: probName },
    });
    if (res?.success) {
      if (selectedPrgm && selectedPrgm.abbrevation == "RPM") {
        setProblemList([
          ...ProblemFormatter.List(res?.data, weightscaleproblem),
        ]);
      } else {
        setProblemList([...ProblemFormatter.List(res?.data)]);
      }
      setProbPage(res.criterion.page);
      !probName && setTotalProb(res?.criterion?.total);
      setTotalSearchProb(res?.criterion?.total);
    } else {
      setProblemList([]);
      setProbPage(1);
      setTotalProb(0);
      setTotalSearchProb(0);
    }
    setProbLimit(limit);
    setLoading(false);
  };

  const getLinkedProblems: () => Promise<void> = async () => {
    setLoading(true);
    const res = await ProgramApi.mappedProblemList({
      id1: selectedPrgm?.id,
      limit: 10000,
      offset: 0,
    });
    if (res?.success) {
      const list = ProbFormatter.MappedProblems(res?.data);
      const idList = list.map((el) => el.id);
      setMappedList(idList);
      setLocalChanges(idList);
    } else {
      setMappedList([]);
      setLocalChanges([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    compareArr();
    checkSelectAll();
  }, [localChanges, problemList]);

  const checkSelectAll: () => void = () => {
    const arr1 = problemList.map((el) => el.id);
    const arr2 = [...localChanges];
    const difference = arr1.filter((x) => !arr2.includes(x));
    setSelectAll(difference.length == 0 ? true : false);
  };

  const compareArr: () => void = () => {
    const arr1 = [...localChanges];
    const arr2 = [...mappedList];
    const difference = arr1
      .filter((x) => !arr2.includes(x))
      .concat(arr2.filter((x) => !arr1.includes(x)));
    setDisableSave(difference.length == 0 ? true : false);
  };

  const handleChange: (obj: OptionsProps, alreadyChecked: boolean) => void = (
    obj,
    alreadyChecked
  ) => {
    if (alreadyChecked)
      setLocalChanges(localChanges.filter((el) => el != obj.id));
    else setLocalChanges([...localChanges, obj.id]);
  };

  const handleSelectAll: () => void = () => {
    if (selectAll) {
      const arr1 = [...localChanges];
      const arr2 = problemList.map((el) => el.id);
      const difference = arr1.filter((x) => !arr2.includes(x));
      setLocalChanges(difference);
    } else {
      const probArr = problemList.map((el) => el.id);
      const newArr = [...probArr, ...localChanges];
      setLocalChanges(GetUniqueArr(newArr));
    }
  };

  const handleSave: () => void = async () => {
    if (selectedPrgm?.id) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const payload = {
        problemIds: [...localChanges],
      };
      const res = await ProgramApi.updateMappedProblem({
        id1: selectedPrgm?.id,
        data: payload,
      });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        await getLinkedProblems();
      }
      dispatch(stopLoading());
    }
  };

  const handleSelectAllSave: () => void = async () => {
    if (selectedPrgm?.id) {
      dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
      const payload = {
        problemIds: [-1],
      };
      const res = await ProgramApi.updateMappedProblem({
        id1: selectedPrgm?.id,
        data: payload,
      });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        await getLinkedProblems();
      }
      dispatch(stopLoading());
    }
  };

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
      <Box sx={{ ...sxHead }} id="head">
        <Typography variant="subtitle1">
          {`Problems > ${selectedPrgm?.name}`}
        </Typography>
        <ControlledForm.Input
          id="Search Box"
          placeholder="Search Problem"
          sxProps={{ width: "30%" }}
          onChange={async (el) => {
            const name = typeof el == "string" ? el : "";
            await getProblemList({ page: 1, limit: probLimit, probName: name });
          }}
        />
        <Box
          sx={{
            ...CommonStyle.sxRow,
            "& >button": { ml: 1 },
          }}
        >
          <CstmBtn
            label="Select All & Save"
            onClick={handleSelectAllSave}
            btnType="border"
          />
          <CstmBtn label="Save" onClick={handleSave} disable={disableSave} />
        </Box>
      </Box>
      <Box
        py={1}
        px={2}
        id="info-box"
        sx={{ backgroundColor: "custom.light", "& label": { mb: 0 } }}
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectAll}
            color="secondary"
            onClick={handleSelectAll}
            sx={{ "& .MuiSvgIcon-root": { fontSize: "1rem" }, p: 0 }}
          />
          <Typography variant="subtitle1" ml={1}>
            Select all <b>{problemList.length}</b> problem(s) on this page
          </Typography>
        </Box>
        <Typography variant="subtitle1">
          Total <b>{localChanges.length}</b> problem(s) are selected out of{" "}
          <b>{totalProb}</b>
        </Typography>
      </Box>
      <Box height={tableHeight} overflow="auto" px={2}>
        {loading ? (
          <Loader />
        ) : problemList.length == 0 ? (
          <NoData />
        ) : (
          <Grid container spacing={1} pt={1}>
            {problemList.map((el) => {
              const found = localChanges.find((el1) => el1 == el.id);
              return (
                <Grid key={el.id} item {...ControlledBoxSizeSwitch(6)}>
                  <ControlledForm.CheckBoxGroup
                    items={[
                      {
                        id: el.id,
                        title: el.name,
                        checked: found ? true : false,
                        disabled: el.disabled,
                      },
                    ]}
                    onChange={(obj) => handleChange(obj, found ? true : false)}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <Box id={"page"} py={1}>
        <ControlledTable.Pagination
          handleClick={async (page, limit) => {
            await getProblemList({ page, limit, probName: "" });
          }}
          limit={probLimit}
          mainPage={probPage}
          total={totalSearchProb}
          tableStyle={false}
          limitArr={LimitArr2}
        />
      </Box>
    </Box>
  );
};
export default ProblemBox;

const sxHead = {
  px: 2,
  py: 1,
  ...CommonStyle.sxRow,
  justifyContent: "space-between",
  "& >h6:first-of-type": {
    fontWeight: "bold",
  },
};
