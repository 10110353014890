import { Fragment, FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import PatientApi from "Service/Patient.api";
import { RedoxProblemAltCodeType } from "DataTypes/Services/Patient.type";
import Loader from "Components/Loader";
import NoData from "Components/NoData";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { SideModalProps } from "DataTypes/Modal";
import RedoxFormatter from "Utils/Redox.Formatter";

const Problems: FC<SideModalProps> = () => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [problemList, setProblemList] = useState<RedoxProblemAltCodeType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleRedoxCall();
  }, []);

  const handleRedoxCall: () => void = async () => {
    const res = await PatientApi.RedoxProblemSearch({ id1: patientInfo.id });
    if (res?.success) {
      setProblemList(RedoxFormatter.ProblemAltCodeList(res?.data));
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <Fragment>
      {problemList.length ? (
        problemList.map((el) => {
          return (
            <Typography variant="body2" mb={1} key={el.id}>{`• ${
              el.altCode + " -"
            } ${el.altCodeName}`}</Typography>
          );
        })
      ) : (
        <NoData />
      )}
    </Fragment>
  );
};
export default Problems;
