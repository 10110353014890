import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { HeadData, SubHead } from "../Data";
import { CommonStyle } from "Components";

const AlertHeads: FC = () => {
  const emptyWidth = "13.5%";
  const filledWidth = "14.6%";
  return (
    <Box display="flex">
      <Box width={emptyWidth} />
      <Box width={emptyWidth} />
      {HeadData.map((el) => {
        return (
          <Box key={el.id} width={filledWidth} ml={0.5}>
            <Typography sx={{ ...sxHead, color: el.color }}>
              {el.label}
            </Typography>
            <Box display="flex">
              {SubHead.map((el1) => {
                return (
                  <Typography
                    key={el1.id}
                    variant="body2"
                    sx={{ ...sxSubHead }}
                  >
                    {el1.label}
                  </Typography>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
export default AlertHeads;

const sxHead = {
  ...CommonStyle.sxSetCol,
  fontWeight: "bold",
  backgroundColor: "bg.main",
  py: 0.5,
};

const sxSubHead = {
  ...CommonStyle.sxSetCol,
  backgroundColor: "secondary.main",
  color: "white",
  py: 0.5,
  width: `${100 / 3}%`,
};
