import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, ControlledAccord, Loader } from "Components";
import { PrgmInsProgramBoxType } from "DataTypes/Master";

const PrgmBox: FC<PrgmInsProgramBoxType> = (props) => {
  const { loading, programList, selectedPrg, handleChangePrg } = props;
  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
      <Typography variant="subtitle1" sx={{ ...CommonStyle.sxRow, ...sxHead }}>
        {"Program Name"}
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ ...sxBody }}>
          {programList.map((el) => {
            return (
              <ControlledAccord
                key={el.id}
                handleClick={() =>
                  handleChangePrg(selectedPrg?.id == el.id ? null : el)
                }
                open={selectedPrg?.id == el.id ? true : false}
                head={
                  <Typography
                    variant="subtitle1"
                    sx={{ ml: 1, ...(selectedPrg?.id == el.id && sxSelected) }}
                  >
                    {el.name}
                  </Typography>
                }
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};
export default PrgmBox;

const sxHead = {
  ...CommonStyle.sxRow,
  height: "10%",
  width: "100%",
  fontWeight: "bold",
  px: 2,
};

const sxBody = {
  height: "90%",
  overflow: "auto",
  px: 2,
};

const sxSelected = {
  fontWeight: "bold",
};
