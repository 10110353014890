import { TableRow, TableCell, Typography } from "@mui/material";
import { columnType } from "DataTypes/Table";
import { ColumnSwitch } from "Utils/SwitchCases";

const TblHead = (tableColumn: columnType[], minWidth?: boolean) => {
  return (
    <TableRow>
      {tableColumn.map((col) => {
        return (
          <TableCell
            key={col.id}
            align={col.align}
            sx={{
              ...sxTblHead,
              width: col.width,
              ...(minWidth && { minWidth: 100 }),
            }}
          >
            {ColumnSwitch(col)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
export default TblHead;

const sxTblHead = {
  padding: 0,
  p: 1,
  backgroundColor: "secondary.main",
};
