import {
  DropdownArrPropsType,
  DropdownReportArrPropsType,
} from "DataTypes/Form";

export type TypeArrProps = {
  id: number;
  label: string;
};

export const ConsentData = (currentProgram: string) => {
  return [
    {
      id: 1,
      label: "The nature of " + currentProgram + ".",
    },
    {
      id: 2,
      label: "How " + currentProgram + " may be accessed.",
    },
    {
      id: 3,
      label:
        "Only one practitioner at a time can furnish " +
        currentProgram +
        " for the beneficiary.",
    },
    {
      id: 4,
      label:
        "The beneficiary’s health information will be shared with other practitioners for care coordination purposes.",
    },
    {
      id: 5,
      label:
        "The beneficiary may stop " +
        currentProgram +
        " at any time by revoking consent, effective at end of then-current calendar month.",
    },
    {
      id: 6,
      label:
        "The beneficiary will be responsible for any associated coinsurance or deductible.",
    },
  ];
};

export const CarePlanCreated: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "Yes",
    value: "Yes",
  },
  {
    id: 2,
    label: "No",
    value: "No",
  },
];

export const Consent: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "Enroll",
    value: "Enrolled",
  },
  {
    id: 2,
    label: "Pending",
    value: "Pending",
  },
  {
    id: 3,
    label: "Decline",
    value: "Declined",
  },
];

export const NotificationTypeArr: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "Read",
    value: "Read",
  },
  {
    id: 2,
    label: "Unread",
    value: "Unread",
  },
];

export const TypeOfConsent: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "Written",
    value: "Written",
  },
  {
    id: 2,
    label: "Verbal",
    value: "Verbal",
  },
];

export const PendingReason: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "Need to talk with Daughter",
    value: "Need to talk with Daughter",
  },
  {
    id: 2,
    label: "Need to talk with Son",
    value: "Need to talk with Son",
  },
  {
    id: 3,
    label: "Need to talk with Spouse",
    value: "Need to talk with Spouse",
  },
  {
    id: 4,
    label: "Want to talk with Provider",
    value: "Want to talk with Provider",
  },
  {
    id: 5,
    label: "Want to check with Insurance",
    value: "Want to check with Insurance",
  },
  {
    id: 6,
    label: "Want to think about it",
    value: "Want to think about it",
  },
  {
    id: 7,
    label: "Out of Town",
    value: "Out of Town",
  },
];

export const DeclinedReason: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "Not Interested",
    value: "Not Interested",
  },
  {
    id: 2,
    label: "Not In Town",
    value: "Not In Town",
  },
  {
    id: 3,
    label: "Receiving Service by another Organization",
    value: "Receiving Service by another Organization",
  },
  {
    id: 4,
    label: "Does not like to talk on phone",
    value: "Does not like to talk on phone",
  },
  {
    id: 5,
    label: "No Chronic Conditions",
    value: "No Chronic Conditions",
  },
  {
    id: 6,
    label: "Receiving Services from Insurance Company",
    value: "Receiving Services from Insurance Company",
  },
  {
    id: 7,
    label: "Phone Number Disconnected",
    value: "Phone Number Disconnected",
  },
  {
    id: 8,
    label: "Wrong Number",
    value: "Wrong Number",
  },
  {
    id: 9,
    label: "Patient Seen only in Urgent Care",
    value: "Patient Seen only in Urgent Care",
  },
  {
    id: 10,
    label: "Patient in Hospice",
    value: "Patient in Hospice",
  },
  {
    id: 11,
    label: "Patient in Homecare",
    value: "Patient in Homecare",
  },
  {
    id: 12,
    label: "Does not want to pay coinsurance",
    value: "Does not want to pay coinsurance",
  },
  {
    id: 13,
    label: "Patient is in a nursing home",
    value: "Patient is in a nursing home",
  },
  {
    id: 14,
    label: "Seen by a non-qualifying provider for service",
    value: "Seen by a non-qualifying provider for service",
  },
  {
    id: 15,
    label: "Patient has moved",
    value: "Patient has moved",
  },
  {
    id: 16,
    label: "Patient has changed Organizations",
    value: "Patient has changed Organizations",
  },
  {
    id: 17,
    label: "Patient believes no longer needs this service",
    value: "Patient believes no longer needs this service",
  },
];

export const TimeZoneArr = [
  {
    id: 2,
    label: "(UTC-05:00) Eastern Standard Time",
    value: "EST",
  },
  {
    id: 3,
    label: "(UTC-06:00) Central Standard Time",
    value: "CST",
  },
  {
    id: 4,
    label: "(UTC-07:00) Mountain Standard Time",
    value: "MST",
  },
  {
    id: 1,
    label: "(UTC-08:00) Pacific Standard Time",
    value: "PST",
  },
];

export const RoleArr = [
  {
    id: 1,
    label: "Care Manager",
    value: "CM",
    selected: false,
  },
  {
    id: 2,
    label: "Care Coordinator",
    value: "CC",
    selected: false,
  },
  {
    id: 3,
    label: "Super Admin",
    value: "SA",
    selected: false,
  },
  {
    id: 4,
    label: "Practice Admin",
    value: "PA",
    selected: false,
  },
];

export const LinkedProgramArr: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "CCM",
    value: "CCM",
  },
  {
    id: 2,
    label: "PCM",
    value: "PCM",
  },
];

export const TcmLinkedProblemArr: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "Multiple Trauma",
    value: "Multiple Trauma",
  },
  {
    id: 2,
    label: "Severe Respiratory Distress",
    value: "Severe Respiratory Distress",
  },
  {
    id: 3,
    label: "Psychiatric Illness",
    value: "Psychiatric Illness",
  },
];

export const LinkedProblemArr: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "Acquired Hypothyroidism",
    value: "Acquired Hypothyroidism",
  },
  {
    id: 2,
    label: "Acute Myocardial Infarction",
    value: "Acute Myocardial Infarction",
  },
  {
    id: 3,
    label: "Alzheimer’s",
    value: "Alzheimer’s",
  },
  {
    id: 4,
    label: "Anemia",
    value: "Anemia",
  },
  {
    id: 5,
    label: "Benign Prostatic Hypertrophy",
    value: "Benign Prostatic Hypertrophy",
  },
  {
    id: 6,
    label: "Breast Cancer",
    value: "Breast Cancer",
  },
  {
    id: 7,
    label: "Chronic Kidney Disease",
    value: "Chronic Kidney Disease",
  },
  {
    id: 8,
    label: "Diabetes Mellitus",
    value: "Diabetes Mellitus",
  },
  {
    id: 9,
    label: "Hypertension",
    value: "Hypertension",
  },
  {
    id: 10,
    label: "Lung Cancer",
    value: "Lung Cancer",
  },
];

export const LinkedVisitArr: DropdownArrPropsType[] = [
  {
    id: 1,
    label: "CCM Care Plan (Provider)",
    value: "CCM Care Plan (Provider)",
  },
  {
    id: 2,
    label: "CCM Care Plan (Care Manager)",
    value: "CCM Care Plan (Care Manager)",
  },
  {
    id: 3,
    label: "CCM Recurring (Provider)",
    value: "CCM Recurring (Provider)",
  },
  {
    id: 4,
    label: "CCM Recurring (Care Manager)",
    value: "CCM Recurring (Care Manager)",
  },
  {
    id: 5,
    label: "CCM Recurring (Care Coordinator)",
    value: "CCM Recurring (Care Coordinator)",
  },
];
export const DevicetypeArr = [
  {
    id: 1,
    label: "Blood Pressure Monitor",
    value: "Blood Pressure Monitor",
  },
  {
    id: 2,
    label: "Continous Glucose Monitor",
    value: "Continous Glucose Monitor",
  },
  {
    id: 3,
    label: "Glucometer",
    value: "Glucometer",
  },
  {
    id: 4,
    label: "Heart Rate Monitor",
    value: "Heart Rate Monitor",
  },
  {
    id: 5,
    label: "Pulse Oximeter",
    value: "Pulse Oximeter",
  },
  {
    id: 6,
    label: "Thermometer",
    value: "Thermometer",
  },
  {
    id: 7,
    label: "Weight Scale",
    value: "Weight Scale",
  },
];
export const DeviceNameArr = [
  {
    id: 1,
    label: "ACCU-CHECK Aviva Connect Glucose Meter",
    value: "ACCU-CHECK Aviva Connect Glucose Meter",
  },
  {
    id: 2,
    label: "Agamatrix Jazz Wireless 2 Glucose Meter",
    value: "Agamatrix Jazz Wireless 2 Glucose Meter",
  },
  {
    id: 3,
    label: "CVS TRUE METRIX AIR Glucose Meter",
    value: "CVS TRUE METRIX AIR Glucose Meter",
  },
];
export const DeviceProviderArr = [
  {
    id: 1,
    label: "Organization",
    value: "Organization",
  },
  {
    id: 2,
    label: "CareVitality",
    value: "CareVitality",
  },
  {
    id: 3,
    label: "Patient",
    value: "Patient",
  },
];
export const DeviceShipped = [
  {
    id: 1,
    label: "Yes",
    value: "Yes",
  },
  {
    id: 2,
    label: "No",
    value: "No",
  },
];
export const TrainingRequired = [
  {
    id: 1,
    label: "Yes",
    value: "Yes",
  },
  {
    id: 2,
    label: "No",
    value: "No",
  },
];
export const Trainer = [
  {
    id: 1,
    label: "Patricia Lynch",
    value: "Patricia Lynch",
  },
  {
    id: 2,
    label: "Philip Bradley",
    value: "Philip Bradley",
  },
  {
    id: 3,
    label: "Ryan Sanders",
    value: "Ryan Sanders",
  },
  {
    id: 4,
    label: "Roger Ford",
    value: "Roger Ford",
  },
  {
    id: 5,
    label: "Harry Jordan",
    value: "Harry Jordan",
  },
  {
    id: 6,
    label: "Jose Carpenter",
    value: "Jose Carpenter",
  },
  {
    id: 7,
    label: "Kevin Wade",
    value: "Kevin Wade",
  },
  {
    id: 8,
    label: "Timothy Lucas",
    value: "Timothy Lucas",
  },
];

export const Report: DropdownReportArrPropsType[] = [
  {
    id: 1,
    label: "Heart Rate Chart",
    value: "Heart Rate",
    geturl: "/heart-rate-chart",
  },
  {
    id: 2,
    label: "Blood Pressure Chart",
    value: "Blood Pressure",
    geturl: "/blood-pressure-chart",
  },
  {
    id: 3,
    label: "Glucose Chart",
    value: "Glucose",
    geturl: "/glucose-chart",
  },
];
// export const TaskStatusArr = [
//   {
//     id: 1,
//     label: "Open",
//     value: "Open",
//   },
//   {
//     id: 3,
//     label: "Pending",
//     value: "Pending",
//   },
//   {
//     id: 4,
//     label: "Waiting for Device ID",
//     value: "Waiting for Device ID",
//   },
//   {
//     id: 2,
//     label: "Completed",
//     value: "Completed",
//   },
// ];

// export const DeviceTaskStatusArr = [
//   {
//     id: 1,
//     label: "Pending",
//     value: "Pending",
//   },
//   {
//     id: 2,
//     label: "Waiting for Device ID",
//     value: "Waiting for Device ID",
//   },
// ];

export const TaskPriorityArr = [
  {
    id: 1,
    label: "Urgent",
    value: "Urgent",
  },
  {
    id: 2,
    label: "Normal",
    value: "Normal",
  },
];

export const ReasonArr = [
  {
    id: 1,
    label: "Not Interested",
    value: "Not Interested",
  },
  {
    id: 2,
    label: "Not in Town",
    value: "Not in Town",
  },
  {
    id: 3,
    label: "Receiving Service by Another Organization",
    value: "Receiving Service by Another Organization",
  },
  {
    id: 4,
    label: "Does not like to talk on the phone",
    value: "Does not like to talk on the phone",
  },
  {
    id: 5,
    label: "No Chronic Conditions",
    value: "No Chronic Conditions",
  },
  {
    id: 6,
    label: "Receiving Services from Insurance Company",
    value: "Receiving Services from Insurance Company",
  },
  {
    id: 7,
    label: "Phone Number Disconnected",
    value: "Phone Number Disconnected",
  },
  {
    id: 8,
    label: "Wrong Number",
    value: "Wrong Number",
  },
  {
    id: 9,
    label: "Patient Seen only in Urgent Care",
    value: "Patient Seen only in Urgent Care",
  },
  {
    id: 10,
    label: "Patient in Hospice",
    value: "Patient in Hospice",
  },
  {
    id: 11,
    label: "Patient in Homecare",
    value: "Patient in Homecare",
  },
  {
    id: 12,
    label: "Does not want to pay coinsurance",
    value: "Does not want to pay coinsurance",
  },
  {
    id: 13,
    label: "Patient is in a Nursing Home",
    value: "Patient is in a Nursing Home",
  },
  {
    id: 14,
    label: "Seen by a non-qualifying provider for service",
    value: "Seen by a non-qualifying provider for service",
  },
  {
    id: 15,
    label: "Patient has moved",
    value: "Patient has moved",
  },
  {
    id: 16,
    label: "Patient has changed Organizations",
    value: "Patient has changed Organizations",
  },
  {
    id: 17,
    label: "Unresponsive - Never Answered",
    value: "Unresponsive - Never Answered",
  },
  {
    id: 18,
    label: "Unresponsive - After Consent Given",
    value: "Unresponsive - After Consent Given",
  },
  {
    id: 29,
    label: "Unresponsive - After Services Rendered",
    value: "Unresponsive - After Services Rendered",
  },
];
