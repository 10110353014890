import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { actionArr, NavArr, TableCol } from "./Data";
import { label } from "Constant/En";
import TabSection from "./TabSection";
import AppointmentSection from "./AppointmentSection";
import { CommonStyle, ControlledTable } from "Components";
import { TableLayout } from "Layouts";
import { useDispatch, useSelector } from "react-redux";
import { ListInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import PatientApi from "Service/Patient.api";
import { objectValues } from "DataTypes/Services/Index.type";
import {
  addSelectedDevice,
  addSelectedPrgm,
  addSelectedTraining,
} from "Redux/Patient/Action";
import { RouteUrls } from "Constant/RouteUrls";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import DateTime from "Utils/DateTime";
import Format from "./Format";
import AppointmentApi from "Service/Appointment.api";
import PatientFormatter from "Utils/Patient.Formatter";
import Stages from "Constant/Stages";
import { AppointmentType } from "DataTypes/Services/Patient.type";
import Dashboard from "./Dashboard";
import TrainingApi from "Service/Training.api";
import {
  addCallDropList,
  AddDeviceProvider,
  AddDeviceUOMList,
  addFreqDropList,
  addGenderDropList,
  addGroupList,
  addQualityDropList,
  addRoleDropList,
  addRPMFreqDropList,
  addRPMTimeLogTypeDropList,
  addSmartmeterUserList,
  addTaskStatusList,
  addTaskTypeDropList,
  addTimeLogTypeDropList,
  addTrainingReqTypeDropList,
  addWeightScaleProblemDropList,
  isLoggedInFirstTimeRender,
} from "Redux/List/Action";
import { FormatDropdownData } from "Utils/DataFormatter";
import { AscendSort, AscendSortByNumber } from "Utils/common";
import DeviceApi from "Service/Device.api";
import TaskFormatter from "Utils/Task.Formatter";
import { formatList } from "../HeadScreen/PatientMgmt/DeviceTask/formatFormData";
import CommonFormatter from "Utils/Common.Formatter";
import RoleApi from "Service/Role.api";
import DeviceTypeFormatter from "Utils/DeviceType.Formatter";

export const getScheduledData: (props: {
  fromDate?: string;
  toDate?: string;
}) => Promise<AppointmentType[]> = async ({ fromDate, toDate }) => {
  const payload = {
    ...(fromDate ? { dateFrom: fromDate } : {}),
    ...(toDate ? { dateTo: toDate } : {}),
  };
  const response = await AppointmentApi.Search({ data: payload });
  if (response?.success) {
    return Format.AppointmentList(response?.data);
  } else return [];
};

const HomeScreen: FC = () => {
  const { myPermission, myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { isLoggedInFirstTime }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permission = myPermission?.dashlinks;
  const [Todayrows, setTodayRows] = useState<AppointmentType[]>([]);
  const [overdueRows, setOverdueRows] = useState<AppointmentType[]>([]);
  const [tilesCount, setTilesCount] = useState<objectValues>();
  const [todayLoading, setTodayLoading] = useState(true);
  const [overdueLoading, setOverdueLoading] = useState(true);

  useEffect(() => {
    updateSchedule();
    fetchTilesCount();
    if (isLoggedInFirstTime) {
      handleInitialLoad();
    }
  }, []);

  const handleInitialLoad: () => void = async () => {
    ///Call All Global Dropdown APIs
    const genderArr = await Format.DropdownSettingCalls("Gender");
    dispatch(addGenderDropList(genderArr));

    const rpmFreqArr = await Format.DropdownSettingCalls("rpm-frequency");
    dispatch(addRPMFreqDropList(rpmFreqArr));

    const callResArr = await Format.DropdownSettingCalls("callresult");
    dispatch(addCallDropList(AscendSort(callResArr, "value")));

    const freqArr = await Format.DropdownSettingCalls("frequency");
    dispatch(addFreqDropList(AscendSortByNumber(freqArr, "value")));

    const taskTypeArr = await Format.DropdownSettingCalls("task-search");
    dispatch(addTaskTypeDropList(taskTypeArr));

    const weightProbArr = await Format.DropdownSettingCalls(
      "weightscaleproblem"
    );
    dispatch(addWeightScaleProblemDropList(weightProbArr));

    const logTimeArr = await Format.DropdownSettingCalls("loggedTimeType");
    dispatch(addTimeLogTypeDropList(logTimeArr));

    const rpmLogTimeArr = await Format.DropdownSettingCalls(
      "rpmLoggedTimeType"
    );
    dispatch(addRPMTimeLogTypeDropList(rpmLogTimeArr));

    const trainReqArr = await Format.DropdownSettingCalls("trainingrequired");
    dispatch(addTrainingReqTypeDropList(trainReqArr));

    const deviceProArr = await Format.DropdownSettingCalls("deviceprovider");
    dispatch(AddDeviceProvider(deviceProArr));

    const qualityMeasArr = await Format.DropdownSettingCalls("Quality Measure");
    dispatch(addQualityDropList(qualityMeasArr));

    ///Call APIs related to redux listing
    const res1 = await DeviceApi.fetchDeviceTaskStatus({
      limit: 50,
      offset: 0,
      data: { statusType: "TASK_STATUS" },
    });
    if (res1?.success) {
      const data = TaskFormatter.TaskStatus(res1?.data);
      const ascendData = AscendSortByNumber(data, "sequenceNumber");
      const formatData = FormatDropdownData(ascendData, "id", "label", "label");
      dispatch(addTaskStatusList(formatData));
    }
    const res2 = await DeviceApi.fetchSmartmeterUserList();
    if (res2?.success) {
      dispatch(addSmartmeterUserList(formatList(res2?.data)));
    }
    const res3 = await PatientApi.fetchGroupList();
    if (res3?.success) {
      dispatch(addGroupList(formatList(res3?.data)));
    }
    dispatch(isLoggedInFirstTimeRender(false));
    const res4 = await RoleApi.list();
    if (res4?.success) {
      const data = FormatDropdownData(res4?.data, "id", "name", "id");
      dispatch(addRoleDropList(data));
    }
    const res5 = await DeviceApi.UOMSearch({ data: {} });
    if (res5?.success) {
      const data = DeviceTypeFormatter.DeviceUOMList(res5?.data);
      dispatch(AddDeviceUOMList(data));
    }
  };

  const updateSchedule: () => void = () => {
    permission?.todayschedule && setTodaysScheduleData();
    permission?.overdueschedule && setOverDueScheduleData();
  };

  const setTodaysScheduleData: () => void = async () => {
    const currentDate = DateTime.CurrentDate();
    const fromDate = DateTime.ToUTCDateTime(currentDate);
    const newDate = DateTime.AddSubMin(fromDate, 1439);
    const toDate = DateTime.ToDbUtcFormat(newDate);
    const formattedData = await getScheduledData({ fromDate, toDate });
    setTodayRows(formattedData);
    setTodayLoading(false);
  };

  const setOverDueScheduleData: () => void = async () => {
    const currentDate = DateTime.CurrentDate();
    const toDate = DateTime.ToUTCDateTime(currentDate);
    const formattedData = await getScheduledData({ toDate });
    setOverdueRows(formattedData);
    setOverdueLoading(false);
  };

  const handlePatientDetails: (data: AppointmentType) => void = (data) => {
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`
    );
  };

  const handleChart: (data: AppointmentType) => void = (data) => {
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleCallDetails: (data: AppointmentType) => void = (data) => {
    const abbrevation = data.activity.slice(0, 3);
    const isRev = data.activity == "RPM Treatment Plan Revision" ? true : false;
    const recId = data.careplanRecurringId;
    const careId = data.careplanId;
    if (data.patientDeviceId) {
      dispatch(
        addSelectedTraining({
          ...PatientFormatter.CheckPatientDeviceTraining(data),
          trainingActivity: data.activity,
        })
      );
      dispatch(
        addSelectedDevice(PatientFormatter.CheckPatientDeviceDetails(data))
      );
      dispatch(
        addSelectedPrgm({
          ...selectedPrgmInitialState,
          careplanId: data.careplanId,
          programId: data.programId,
          providerName: data.provider,
          providerId: null,
        })
      );
      navigate(
        `${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}/${RouteUrls.device}/${data.patientDeviceId}/${RouteUrls.callLog}`
      );
    } else {
      const route = CommonFormatter.HandleScreenRoute({
        prgm: abbrevation,
        careId: careId,
        isRec: recId ? true : false,
        recId: recId,
        isRev: isRev,
        careState: data.careplanState,
      });
      dispatch(
        addSelectedPrgm({
          ...selectedPrgmInitialState,
          abbrevation: abbrevation,
          callDefaultRoute: `../${route}`,
          careplanId: careId,
          careplanRecurringId: recId,
          careplanRecurringStatus: recId ? "not-completed" : "",
          careplanState: data.careplanState,
          careplanStage: PatientFormatter.GetCPStage(data.careplanState),
          careplanType: isRev ? "REVISION" : "",
          programId: data.programId,
          readOnlyArr: isRev ? ["team"] : [],
          showLogTime:
            abbrevation == "RPM"
              ? true
              : data.careplanState == Stages.CPSignOff
                ? false
                : true,
          ...(!recId ? { showSaveBtn: true } : {}),
          showSubmitBtn: true,
        })
      );
      navigate(
        `/${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}/${RouteUrls.callLog}`
      );
    }
  };

  const handleTask: (data: AppointmentType) => void = (data) => {
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${RouteUrls.add}`
    );
  };

  const TodayScheduleTblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol(myInfo?.roleName)}
      tableRow={Todayrows}
      tableAction={(data) =>
        actionArr(
          () => handleChart(data),
          () => handleCallDetails(data),
          () => handlePatientDetails(data),
          () => handleTask(data)
        )
      }
    />
  );

  const OverdueTblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol(myInfo?.roleName)}
      tableRow={overdueRows}
      tableAction={(data) =>
        actionArr(
          () => handleChart(data),
          () => handleCallDetails(data),
          () => handlePatientDetails(data),
          () => handleTask(data)
        )
      }
    />
  );

  const setData: (
    newObj: objectValues,
    result: objectValues,
    field: string
  ) => any = (newObj, result, field) => {
    if (result.success) {
      newObj[field] = result.data;
      return newObj;
    }
  };

  const fetchDataFromApi: (apiFunction: any) => any = async (apiFunction) => {
    const response = await apiFunction();
    return response;
  };

  const fetchTilesCount: () => void = async () => {
    const NewObj = {};
    const apiCallData = [
      {
        apiFunction: PatientApi.eligiblePatientCount,
        responseKey: "eligible",
      },
      {
        apiFunction: PatientApi.pendingAssignmentCount,
        responseKey: "pendingAssignment",
      },
      {
        apiFunction: PatientApi.enrolledPatientCount,
        responseKey: "enrolledPatient",
      },
      {
        apiFunction: PatientApi.pendingPlanApprovalCount,
        responseKey: "pendingPlanApproval",
      },
      {
        apiFunction: PatientApi.pendingContinuousCareCount,
        responseKey: "pendingContinuousCare",
      },
      {
        apiFunction: PatientApi.completedContinuousCareCount,
        responseKey: "completedContinuousCare",
      },
      {
        apiFunction: PatientApi.patientInactiveCount,
        responseKey: "patientInactive",
      },
      {
        apiFunction: PatientApi.taskCount,
        responseKey: "tasking",
      },
      {
        apiFunction: PatientApi.billingCount,
        responseKey: "billing",
      },
      {
        apiFunction: PatientApi.myPatientCount,
        responseKey: "myPatient",
      },
      {
        apiFunction: PatientApi.onGoingCount,
        responseKey: "onGoing",
      },
      {
        apiFunction: TrainingApi.myTrainingCount,
        responseKey: "myTraining",
      },
      {
        apiFunction: TrainingApi.completedTrainingCount,
        responseKey: "completedTraining",
      },
      // {
      //   apiFunction: PatientApi.revisionCount,
      //   responseKey: "revision",
      // },
      {
        apiFunction: TrainingApi.pendingDeviceCount,
        responseKey: "pendingDevice",
      },
    ];
    const promises = apiCallData.map(async ({ apiFunction, responseKey }) => {
      try {
        const response = await fetchDataFromApi(apiFunction);
        return setData(NewObj, response, responseKey);
      } catch (error) {
        console.error(`API call failed for ${responseKey}:`, error);
      }
    });
    await Promise.all(promises);
    setTilesCount(NewObj);
  };

  return (
    <Box display="flex" flexDirection="row" sx={{ pt: 2 }}>
      <Box
        {...(permission?.schedular
          ? { sx: { width: "75%", pr: 2 } }
          : { sx: { width: "100%" } })}
      >
        <TabSection
          navArr={NavArr(myPermission?.dashlinks, tilesCount, myInfo)}
          schedular={permission?.schedular ? permission?.schedular : false}
        />
        {permission?.schedular && (
          <Box>
            {permission?.todayschedule && (
              <Box sx={{ ...CommonStyle.sxWhiteCard, mt: 2 }}>
                <Typography variant="body1" fontWeight="bold" height="10%">
                  {`${label.today} ${label.schedule}`}
                </Typography>
                <TableLayout
                  tblBody={TodayScheduleTblBodyJSX}
                  tblHead={ControlledTable.TblHead(TableCol(myInfo?.roleName))}
                  maxHeight={"20rem"}
                  loading={todayLoading}
                />
              </Box>
            )}
            {permission?.overdueschedule && (
              <Box sx={{ ...CommonStyle.sxWhiteCard, mt: 2 }}>
                <Typography variant="body1" fontWeight="bold" height="10%">
                  {`${label.overdue}  ${label.schedule}`}
                </Typography>
                <TableLayout
                  tblBody={OverdueTblBodyJSX}
                  tblHead={ControlledTable.TblHead(TableCol(myInfo?.roleName))}
                  maxHeight={"20rem"}
                  loading={overdueLoading}
                />
              </Box>
            )}
          </Box>
        )}
        {myPermission?.count?.["static-dashboard"] && <Dashboard />}
      </Box>
      {permission?.schedular && (
        <AppointmentSection updateSchedule={updateSchedule} />
      )}
    </Box>
  );
};
export default HomeScreen;
