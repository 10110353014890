import { FC } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { CommonStyle } from "Components";

const Loader: FC = () => {
  return (
    <Box sx={{ ...CommonStyle.sxColumnCenter }} height="100%" width="100%">
      <CircularProgress />
      <Typography>Loading ...</Typography>
    </Box>
  );
};

export default Loader;
