import { ControlledForm, ControlledTable } from "Components";
import BreadUrls from "Constant/BreadUrls";
import { BreadArrType } from "DataTypes/Common";
import { FormDataType } from "DataTypes/Form";
import { PatientInfoType } from "DataTypes/Redux";
import { columnType } from "DataTypes/Table";

export const Arr = [ControlledForm.FormData.AddNotes()];

export const Arr1: FormDataType[] = [
  ControlledForm.FormData.Callfreq(),
  ControlledForm.FormData.CompletionDate(),
];

export const Arr2: FormDataType[] = [
  {
    id: "interactive",
    label: "Was interactive communication done with the Patient?",
    placeholder: "Select an option",
    boxType: "empty-block",
    boxSize: 3,
    validatetype: "dropdown",
    dropArr: [
      { id: "Yes", label: "Yes", value: "Yes" },
      { id: "No", label: "No", value: "No" },
    ],
  },
  {
    id: "medically",
    label:
      "Are RPM Services medically reasonable & necessary for this patient?",
    placeholder: "Select an option",
    boxType: "empty-block",
    boxSize: 3,
    validatetype: "dropdown",
    dropArr: [
      { id: "Yes", label: "Yes", value: "Yes" },
      { id: "No", label: "No", value: "No" },
    ],
  },
];

export const BreadData: (
  patient: PatientInfoType,
  isRecurring: boolean,
  isTCM: boolean,
  careplanId: number | null,
  recurringId: number | null,
  isCareplanComprehensive: boolean,
  isRPM: boolean
) => BreadArrType[] = (
  patient,
  isRecurring,
  isTCM,
  careplanId,
  recurringId,
  isCareplanComprehensive,
  isRPM
) => {
  if (isRecurring) {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Questionnaire",
        path: BreadUrls.RecurringQues(patient.id, careplanId, recurringId),
      },
      {
        id: 3,
        label: "Summary",
        path: "",
      },
    ];
  }
  if (isTCM) {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Questionnaire",
        path: BreadUrls.TCMCPQues(patient.id, careplanId),
      },
      {
        id: 3,
        label: "Team",
        path: BreadUrls.TCMCPTeam(patient.id, careplanId),
      },
      {
        id: 4,
        label: "Summary",
        path: "",
      },
    ];
  } else {
    return [
      {
        id: 1,
        label: `${patient.name}`,
        path: BreadUrls.ClinicalSummary(patient.id),
      },
      {
        id: 2,
        label: "Problems",
        path: BreadUrls.CPProbs(patient.id, careplanId),
      },
      ...(isRPM
        ? [
            {
              id: 3,
              label: "Device Assignment",
              path: BreadUrls.CPDevices(patient.id, careplanId),
            },
          ]
        : []),
      ...(!isCareplanComprehensive
        ? [
            {
              id: 4,
              label: "Questionnaire",
              path: BreadUrls.CPQues(patient.id, careplanId),
            },
          ]
        : []),
      {
        id: 5,
        label: "Team",
        path: BreadUrls.CPTeam(patient.id, careplanId),
      },
      {
        id: 6,
        label: "Summary",
        path: "",
      },
    ];
  }
};

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.ProblemCol(),
  ControlledTable.ColumnData.GoalCol(),
  ControlledTable.ColumnData.InterventionCol(),
];
export const TCMTableCol: columnType[] = [
  ControlledTable.ColumnData.TcmProblemCol(),
  ControlledTable.ColumnData.TcmQuesCol(), //Separate column for tcm question in summary screen
];

export const RecurringCol: columnType[] = [
  ControlledTable.ColumnData.QuesAnsCol(),
];

export const rawData = [
  {
    problemId: 1002,
    problemName: "Diabetes Mellitus",
    problemICDCodeList: [
      {
        icdCode: "E08.00",
        description:
          "Diabetes mellitus due to underlying condition with hyperosmolarity without nonketotic hyperglycemic-hyperosmolar coma (NKHHC)",
        status: "ACTIVE",
      },
    ],
    summary: {
      Goals: [
        {
          careplanId: 1032,
          careplanQuestionId: 1108,
          categoryId: 1000,
          categoryName: "Goals",
          categoryType: "Goals",
          createdAt: "2024-05-20T11:34:31.102709",
          questionId: 10000,
          questionText: "This is the first dummy question?",
          questionType: "singleSelect",
          questionComment: "",
          freeTextAnswer: "",
          optionList: [
            {
              optionId: 10000,
              optionText: "Option 1",
              subQuestion: [
                {
                  careplanId: null,
                  careplanQuestionId: null,
                  categoryId: 1000,
                  categoryName: "Goals",
                  categoryType: null,
                  createdAt: null,
                  questionId: 10001,
                  questionText: "This is the first dummy child question?",
                  questionType: null,
                  questionComment: "<ul>\n<li>yes comment</li>\n</ul>",
                  freeTextAnswer: "",
                  optionList: [
                    {
                      optionId: 10002,
                      optionText: "Yes",
                      subQuestion: [
                        {
                          careplanId: null,
                          careplanQuestionId: null,
                          categoryId: 1000,
                          categoryName: "Goals",
                          categoryType: null,
                          createdAt: null,
                          questionId: 10011,
                          questionText: "Yes Question 1",
                          questionType: null,
                          questionComment: "",
                          freeTextAnswer: "",
                          optionList: [
                            {
                              optionId: 10023,
                              optionText: "Yes Ans 1",
                              subQuestion: [
                                {
                                  careplanId: null,
                                  careplanQuestionId: null,
                                  categoryId: 1000,
                                  categoryName: "Goals",
                                  categoryType: null,
                                  createdAt: null,
                                  questionId: 10013,
                                  questionText: "Yes yes Qiest",
                                  questionType: null,
                                  questionComment: "",
                                  freeTextAnswer: "",
                                  optionList: [
                                    {
                                      optionId: 10027,
                                      optionText: "Option 1",
                                      subQuestion: null,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      Interventions: [
        {
          careplanId: 1032,
          careplanQuestionId: 1108,
          categoryId: 1000,
          categoryName: "Goals",
          categoryType: "Goals",
          createdAt: "2024-05-20T11:34:31.102709",
          questionId: 10000,
          questionText: "Interventions This is the first dummy question?",
          questionType: "singleSelect",
          questionComment: "",
          freeTextAnswer: "",
          optionList: [
            {
              optionId: 10000,
              optionText: "Option 1",
              subQuestion: [
                {
                  careplanId: null,
                  careplanQuestionId: null,
                  categoryId: 1000,
                  categoryName: "Goals",
                  categoryType: null,
                  createdAt: null,
                  questionId: 10001,
                  questionText:
                    "Interventions This is the first dummy child question?",
                  questionType: null,
                  questionComment: "",
                  freeTextAnswer: "",
                  optionList: [
                    {
                      optionId: 10002,
                      optionText: "Yes",
                      subQuestion: [
                        {
                          careplanId: null,
                          careplanQuestionId: null,
                          categoryId: 1000,
                          categoryName: "Goals",
                          categoryType: null,
                          createdAt: null,
                          questionId: 10011,
                          questionText: "Interventions Yes Question 1",
                          questionType: null,
                          questionComment: "",
                          freeTextAnswer: "",
                          optionList: [
                            {
                              optionId: 10023,
                              optionText: "Yes Ans 1",
                              subQuestion: [
                                {
                                  careplanId: null,
                                  careplanQuestionId: null,
                                  categoryId: 1000,
                                  categoryName: "Goals",
                                  categoryType: null,
                                  createdAt: null,
                                  questionId: 10013,
                                  questionText: "Interventions Yes yes Qiest",
                                  questionType: null,
                                  questionComment: "",
                                  freeTextAnswer: "",
                                  optionList: [
                                    {
                                      optionId: 10027,
                                      optionText: "Option 1",
                                      subQuestion: null,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
