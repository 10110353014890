import { FC, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, Loader } from "Components";
import ImgPath from "Constant/Imgs";
import { CPCatBoxProps } from "DataTypes/PatientManagement.type";
import NoData from "Components/NoData";

const CategoryBox: FC<CPCatBoxProps> = (props) => {
  const {
    catQuesOptArr,
    selectedCat,
    handleSelectCat,
    loadingCat,
    pressedNext,
  } = props;

  return (
    <Box height="50%" sx={{ ...CommonStyle.sxWhiteBox, mr: 2 }}>
      <Typography
        variant="subtitle1"
        sx={{ ...CommonStyle.sxRow, ...sxCategoryHead }}
      >
        {"Category(s)"}
      </Typography>
      <Box height="80%" overflow="auto" sx={{ px: 2 }}>
        {loadingCat ? (
          <Loader />
        ) : catQuesOptArr.length < 1 ? (
          <NoData />
        ) : (
          <Fragment>
            {catQuesOptArr.map((el) => {
              return (
                <Box
                  key={el.categoryId}
                  sx={{ ...CommonStyle.sxRow, ...sxCategoryBox }}
                  onClick={() =>
                    handleSelectCat(
                      el.categoryId == selectedCat?.categoryId
                        ? null
                        : {
                          categoryId: el.categoryId,
                          category: el.category,
                        }
                    )
                  }
                >
                  <Box
                    sx={{
                      ...CommonStyle.sxRoundIconsMainSm,
                      ...(el.isQuestionAnsweringStart &&
                        !el.isAllRequiredQuestionAnswered &&
                        !el.isAllRequiredQuesOptionAnswered && {
                        backgroundColor: "roundiconbghover.main",
                      }),
                      ...(el.isQuestionAnsweringStart &&
                        el.isAllRequiredQuestionAnswered &&
                        !el.isAllRequiredQuesOptionAnswered && {
                        backgroundColor: "red",
                      }),
                      ...(pressedNext &&
                        (!el.isAllRequiredQuestionAnswered ||
                          !el.isAllRequiredQuesOptionAnswered) && {
                        backgroundColor: "red",
                      }),
                      ...(el.isQuestionAnsweringStart &&
                        el.isAllRequiredQuestionAnswered &&
                        el.isAllRequiredQuesOptionAnswered && {
                        backgroundColor: "green",
                      }),
                    }}
                  >
                    {el.categoryId == selectedCat?.categoryId ? (
                      <ImgPath.StartIcon sx={{ transform: "rotate(90deg)" }} />
                    ) : (
                      <ImgPath.StartIcon />
                    )}
                  </Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      ml: 1,
                      ...(el.categoryId == selectedCat?.categoryId &&
                        sxSelected),
                      wordBreak: "break-all",
                    }}
                  >
                    {el.category}
                  </Typography>
                </Box>
              );
            })}
          </Fragment>
        )}
      </Box>
    </Box>
  );
};
export default CategoryBox;

const sxCategoryHead = {
  height: "20%",
  fontWeight: "bold",
  px: 2,
};

const sxCategoryBox = {
  borderBottom: "0.5px solid",
  borderColor: "custom.main",
  cursor: "pointer",
  py: 1,
  ":hover": {
    "& >div": {
      backgroundColor: "roundiconbghover.main",
    },
    "& >h6": {
      color: "textHover.main",
    },
  },
};

const sxSelected = {
  fontWeight: "bold",
};
