import { useState, FC, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import Formate from "./Formate";
import CPFormatter from "Utils/CP.Formatter";
import PatientFormatter from "Utils/Patient.Formatter";
import CarePlanApi from "Service/CarePlan.api";
import PatientApi from "Service/Patient.api";
import {
  setAlertData,
  setRightHead,
  setTimerStatus,
  startLoading,
  stopLoading,
} from "Redux/Screen/Action";
import { addSelectedPrgm } from "Redux/Patient/Action";
import {
  PatientInitialStateType,
  ScreenInitialStateType,
} from "DataTypes/Redux";
import {
  CPCatQuesOptType,
  CPQuesOptType,
  HandleQuestionUpdateProps,
} from "DataTypes/PatientManagement.type";
import { CPApiCatType } from "DataTypes/Services/Careplan.type";
import { RouteUrls } from "Constant/RouteUrls";
import CategoryBox from "./CategoryBox";
import QuestionBox from "./QuestionBox";
import ProblemBox from "./ProblemBox";
import Stages from "Constant/Stages";

const CarePlanQuestionnaire: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    rightHead: { timerStatus },
  }: ScreenInitialStateType = useSelector((state: any) => state?.screen);
  const { selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const careId = selectedPrgm.careplanId;
  const recurringId = selectedPrgm.careplanRecurringId;
  const currentUrl = window.location.href;
  const isRecurring = currentUrl.includes(RouteUrls.recurring);
  const isTCM = currentUrl.includes(RouteUrls.tcm);
  const isRPM = selectedPrgm.abbrevation == "RPM" ? true : false;
  const state = isRecurring ? "recurring" : "careplan";
  const readOnly = selectedPrgm
    ? selectedPrgm.readOnlyArr.some((el) => el == "question")
    : true;
  const [catQuesOptArr, setCatQuesOptArr] = useState<CPCatQuesOptType[]>([]);
  const [selectedCat, setSelectedCat] = useState<CPApiCatType | null>(null);
  const [loadingOptQuesId, setLoadingOptQuesId] = useState<number | null>(null);
  const [loadingCat, setLoadingCat] = useState(true);
  const [loadingProb, setLoadingProb] = useState(true);
  const [problemArr, setProblemArr] = useState<string[]>([]);
  const [openQuestionIds, setOpenQuestionIds] = useState<(number | null)[]>([]);
  const [localInput, setLocalInput] = useState("");
  const [pressedNext, setPressedNext] = useState(false);

  useEffect(() => {
    handleOnLoadCalls();
    isTCM ? handleTCMProblemsLoad() : handleProblemsLoad();
    handleUpdateStatus();

    ///For RPM Program Changes
    if (isRPM && timerStatus == 1 && !readOnly) {
      dispatch(setRightHead("care-plan"));
      dispatch(setTimerStatus({ status: 2, parentApiCall: true }));
    }
  }, [careId, recurringId]);

  const handleOnLoadCalls: () => void = async () => {
    setLoadingCat(true);
    const newArr = await handleQuesApiCall(null, null);
    const newCatArr = Formate.SortCategoryQuestion(newArr, catQuesOptArr);
    const newCatArr1 = Formate.CheckSelectedCategory(
      newCatArr,
      selectedCat?.categoryId || null
    );
    const newObj = Formate.CheckAllCategory(newCatArr1);
    setCatQuesOptArr(newObj.catArr);
    newObj?.catArr?.length > 0 && setSelectedCat(newObj.catArr[0]);
    setLoadingCat(false);
  };

  const handleQuesApiCall: (
    parentQuestionId: number | null,
    parentOptionId: number | null
  ) => Promise<CPCatQuesOptType[]> = async (
    parentQuestionId,
    parentOptionId
  ) => {
    const payload = {
      parentQuestionId,
      parentOptionId,
      state,
      careplanRecurringId: recurringId,
    };
    const res = await CarePlanApi.QuestionSearch({
      data: payload,
      id1: careId,
    });
    if (res?.success) return Formate.QuestionList(res?.data);
    else return [];
  };

  const handleTCMProblemsLoad = async () => {
    setLoadingProb(true);
    const res = await PatientApi.DischargeSummarySearch({
      id1: careId,
    });
    if (res?.success) {
      if (res.data.length) {
        const formattedData = PatientFormatter.DischargeSummary(res?.data[0]);
        setProblemArr(
          [
            ...(formattedData.primaryDiagnosis
              ? [formattedData.primaryDiagnosis]
              : []),
            ...(formattedData.secondaryDiagnosis
              ? [formattedData.secondaryDiagnosis]
              : []),
            ...(formattedData.tertiaryDiagnosis
              ? [formattedData.tertiaryDiagnosis]
              : []),
          ].sort()
        );
      }
    }
    setLoadingProb(false);
  };

  const handleProblemsLoad = async () => {
    setLoadingProb(true);
    const probRes = await CarePlanApi.careplanProblemsRead({
      id1: careId,
    });
    if (probRes?.success) {
      const newArr = CPFormatter.CheckCPProblem(probRes?.data?.careplanProblem);
      //Client changes PMS task id: #13853
      // if (isRPM) setProblemArr(Formate.FormatRPMProblems(newArr));
      // else setProblemArr(Formate.FormatProblems(newArr));
      setProblemArr(Formate.FormatRPMProblems(newArr));
    }
    setLoadingProb(false);
  };

  const handleUpdateStatus: () => void = async () => {
    if (isRecurring) {
      const payload = { hasCareplanRecurringQuestionnaireCompleted: false };
      const updateStatusRes = await CarePlanApi.RecurringCpQuestionStatusUpdate(
        {
          id1: recurringId,
          data: payload,
        }
      );
    } else {
      const payload = { hasCareplanQuestionnaireCompleted: false };
      const updateStatusRes = await CarePlanApi.CpQuestionStatusUpdate({
        id1: careId,
        data: payload,
      });
    }
  };

  const handleCategoryChange: (obj: CPApiCatType | null) => void = async (
    obj
  ) => {
    if (obj) {
      const newCatArr1 = Formate.CheckSelectedCategory(
        catQuesOptArr,
        obj.categoryId
      );
      setCatQuesOptArr(newCatArr1);
    }
    setSelectedCat(obj);
  };

  const handleQuestionClick: (question: CPQuesOptType) => void = async (
    question
  ) => {
    const find = openQuestionIds.find((el) => el == question.questionsId);
    if (find) {
      setOpenQuestionIds(
        openQuestionIds.filter((el) => el != question.questionsId)
      );
    } else {
      setLoadingOptQuesId(question.questionsId);
      setOpenQuestionIds([...openQuestionIds, question.questionsId]);
      if (
        question.options.length == 0 &&
        (question.questionType == "singleSelect" ||
          question.questionType == "multiSelect")
      ) {
        const newCatArr = await handleOptApiCall(
          question.questionsId,
          catQuesOptArr
        );
        const newCatArr1 = Formate.CheckSelectedCategory(
          newCatArr,
          selectedCat?.categoryId || null
        );
        setCatQuesOptArr(newCatArr1);
      }
      setLoadingOptQuesId(null);
    }
  };

  const handleOptApiCall: (
    id: number | null,
    categoryArr: CPCatQuesOptType[]
  ) => Promise<CPCatQuesOptType[]> = async (id, categoryArr) => {
    const newcategoryArr: CPCatQuesOptType[] = JSON.parse(
      JSON.stringify(categoryArr)
    );
    setLoadingOptQuesId(id);
    const payload = {
      questionId: id,
      state,
      careplanRecurringId: recurringId,
    };
    const res = await CarePlanApi.QuesOptSearch({
      data: payload,
      id1: careId,
    });
    if (res?.success) {
      setLoadingOptQuesId(null);
      const optionArr = CPFormatter.CheckCarePlanQuesOpt(res?.data);
      const catIndex = newcategoryArr.findIndex(
        (el) => el.categoryId == selectedCat?.categoryId
      );
      if (catIndex != -1) {
        const quesIndex = newcategoryArr[catIndex].questionList.findIndex(
          (el) => el.questionsId == id
        );
        if (quesIndex != -1)
          newcategoryArr[catIndex].questionList[quesIndex].options = optionArr;
      }
      let newcategoryArr1: CPCatQuesOptType[] = JSON.parse(
        JSON.stringify(newcategoryArr)
      );
      const filterOptArr = optionArr.filter((optEl) => optEl.answered);
      await (async function loop() {
        for (let i = 0; i < filterOptArr.length; i++) {
          const findOptToQues = newcategoryArr[catIndex].questionList.find(
            (quesEl) => quesEl.parentOptionId == filterOptArr[i].id
          );
          if (filterOptArr[i].answered && !findOptToQues) {
            const newSet = await handleQuesApiCall(id, filterOptArr[i].id);
            newcategoryArr1 = Formate.SortCategoryQuestion(
              newcategoryArr1,
              newSet
            );
          }
        }
      })();
      return newcategoryArr1;
    } else {
      setLoadingOptQuesId(null);
      return newcategoryArr;
    }
  };

  const handleOptionClick: (
    question: CPQuesOptType,
    optId: number | null
  ) => void = async (question, optId) => {
    const newQuestion: CPQuesOptType = JSON.parse(JSON.stringify(question));
    if (newQuestion.questionType == "multiSelect") {
      const OptIndex = newQuestion.options.findIndex((el) => el.id == optId);
      newQuestion.options[OptIndex].answered =
        !newQuestion.options[OptIndex].answered;
    } else if (newQuestion.questionType == "singleSelect") {
      const findCpOptionId = newQuestion.options.find(
        (el) => el.careplanQuestionOptionId
      );
      const newOptArr = newQuestion.options.map((el) => {
        if (el.id == optId) {
          el.answered = !el.answered;
          el.careplanQuestionOptionId = findCpOptionId?.careplanQuestionOptionId
            ? findCpOptionId?.careplanQuestionOptionId
            : null;
        } else el.answered = false;
        return el;
      });
      newQuestion.options = newOptArr;
    }
    dispatch(startLoading({ loading: true, loadtext: "Loading" }));
    const updatedArr = await handleQuestionUpdate({
      question: newQuestion,
      categoryArr: catQuesOptArr,
      optId,
    });
    const newCatArr1 = Formate.CheckSelectedCategory(
      updatedArr,
      selectedCat?.categoryId || null
    );
    setCatQuesOptArr(newCatArr1);
    dispatch(stopLoading());
  };

  const handleChangeText: (
    questionId: number | null,
    text: string,
    textType: "answer" | "comment"
  ) => void = (questionId, text, textType) => {
    const newcategoryArr: CPCatQuesOptType[] = JSON.parse(
      JSON.stringify(catQuesOptArr)
    );
    const catIndex = newcategoryArr.findIndex(
      (el) => el.categoryId == selectedCat?.categoryId
    );
    if (catIndex != -1) {
      const quesIndex = newcategoryArr[catIndex].questionList.findIndex(
        (el) => el.questionsId == questionId
      );
      if (quesIndex != -1) {
        if (textType == "comment")
          newcategoryArr[catIndex].questionList[quesIndex].questionComment =
            text;
        else
          newcategoryArr[catIndex].questionList[quesIndex].freeTextAnswer =
            text;
      }
    }
    const newCatArr1 = Formate.CheckSelectedCategory(
      newcategoryArr,
      selectedCat?.categoryId || null
    );
    setCatQuesOptArr(newCatArr1);
  };

  const handleQuestionUpdate: (
    props: HandleQuestionUpdateProps
  ) => Promise<CPCatQuesOptType[]> = async ({
    question,
    categoryArr,
    optId,
    oldComment,
    oldAnswer,
  }) => {
    const newcategoryArr: CPCatQuesOptType[] = JSON.parse(
      JSON.stringify(categoryArr)
    );
    const filterOption = question.options.filter((el) => el.answered);
    const isRequiredOptionsAnswered = question.options.find(
      (el) => !el.answered && el.requiredOption
    )
      ? false
      : true;
    const formateOptionArr = filterOption.map((el) => {
      return {
        ...(el.careplanQuestionOptionId && {
          id: el.careplanQuestionOptionId,
        }),
        optionId: el.id,
        optionText: el.option,
      };
    });
    const payload = {
      careplanQuestionId: question.careplanQuestionId,
      questionId: question.questionsId,
      questionText: question.question,
      questionComment: question.questionComment.trim() || null,
      freeTextAnswer: question.freeTextAnswer.trim() || null,
      categoryId: selectedCat?.categoryId,
      categoryText: selectedCat?.category,
      programId: question.programId,
      options: formateOptionArr,
      careplanRecurringId: recurringId,
      state,
      isRequiredOptionsAnswered,
      isChildRequiredOptionsAnswered: false,
      hasChidAnswered: false,
    };
    const res = await CarePlanApi.QuesUpdate({
      data: payload,
      id1: careId,
    });
    const catIndex = newcategoryArr.findIndex(
      (el) => el.categoryId == selectedCat?.categoryId
    );
    if (catIndex != -1) {
      const quesIndex = newcategoryArr[catIndex].questionList.findIndex(
        (el) => el.questionsId == question.questionsId
      );
      if (quesIndex != -1) {
        if (res?.success) {
          question.isRequiredOptionAnswered = isRequiredOptionsAnswered;
          const newQuesObj = Formate.FormateUpdateQuestion(res?.data, question);
          newcategoryArr[catIndex].questionList[quesIndex] = newQuesObj;
          if (optId) {
            const unAnsOptIds = newQuesObj.options
              .filter((opt) => !opt.answered)
              .map((opt1) => opt1.id);
            const { quesList, openArr } = await Formate.FilterOutQuestions(
              unAnsOptIds,
              newcategoryArr[catIndex].questionList,
              openQuestionIds
            );
            setOpenQuestionIds(openArr);
            newcategoryArr[catIndex].questionList = quesList;
            if (newQuesObj.options.find((el) => el.id == optId)?.answered) {
              const newQuesSet = await handleQuesApiCall(
                question.questionsId,
                optId
              );
              return Formate.SortCategoryQuestion(newcategoryArr, newQuesSet);
            } else return newcategoryArr;
          } else return newcategoryArr;
        } else {
          if (oldComment) {
            const newQuesObj: CPQuesOptType = JSON.parse(
              JSON.stringify(question)
            );
            newQuesObj.questionComment = oldComment;
            newcategoryArr[catIndex].questionList[quesIndex] = newQuesObj;
          }
          if (oldAnswer) {
            const newQuesObj: CPQuesOptType = JSON.parse(
              JSON.stringify(question)
            );
            newQuesObj.freeTextAnswer = oldAnswer;
            newcategoryArr[catIndex].questionList[quesIndex] = newQuesObj;
          }
          return newcategoryArr;
        }
      } else return newcategoryArr;
    } else return newcategoryArr;
  };

  const handleUpdateText: (
    question: CPQuesOptType,
    localText: string,
    textType: "answer" | "comment"
  ) => void = async (question, localText, textType) => {
    setLocalInput("");
    dispatch(startLoading({ loading: true, loadtext: "Loading" }));
    const updatedArr = await handleQuestionUpdate({
      question,
      categoryArr: catQuesOptArr,
      oldComment: textType == "comment" ? localText : "",
      oldAnswer: textType == "answer" ? localText : "",
    });
    const newCatArr1 = Formate.CheckSelectedCategory(
      updatedArr,
      selectedCat?.categoryId || null
    );
    setCatQuesOptArr(newCatArr1);
    dispatch(stopLoading());
  };

  const handleSave: () => void = async () => {
    setPressedNext(true);
    if (readOnly) {
      if (isRecurring)
        navigate(`../${RouteUrls.careSummary}`, { replace: true });
      else navigate(`../${RouteUrls.careTeam}`, { replace: true });
    } else {
      const newObj = Formate.CheckAllCategory(catQuesOptArr);
      setCatQuesOptArr(newObj.catArr);
      if (newObj.allCatSuccess) {
        dispatch(startLoading({ loading: true, loadtext: "Loading" }));
        if (isRecurring) {
          const payload = { hasCareplanRecurringQuestionnaireCompleted: true };
          const updateStatusRes =
            await CarePlanApi.RecurringCpQuestionStatusUpdate({
              id1: recurringId,
              data: payload,
            });
          if (updateStatusRes?.success)
            navigate(`../${RouteUrls.careSummary}`, { replace: true });
        } else {
          const payload = { hasCareplanQuestionnaireCompleted: true };
          const updateStatusRes = await CarePlanApi.CpQuestionStatusUpdate({
            id1: careId,
            data: payload,
          });
          if (updateStatusRes?.success) {
            selectedPrgm &&
              dispatch(
                addSelectedPrgm({
                  ...selectedPrgm,
                  ...(selectedPrgm.careplanStage >
                  PatientFormatter.GetCPStage(Stages.CPQues)
                    ? {}
                    : {
                      careplanState: Stages.CPTeam,
                      careplanStage: PatientFormatter.GetCPStage(
                        Stages.CPTeam
                      ),
                    }),
                })
              );
            navigate(`../${RouteUrls.careTeam}`, { replace: true });
          }
        }
        dispatch(stopLoading());
      } else {
        dispatch(
          setAlertData({
            alertMessage: "Please answer all required questions",
            alertVariant: "error",
            openAlert: true,
          })
        );
      }
    }
  };

  return (
    <Fragment>
      <Box width="25%" height="100%">
        <CategoryBox
          catQuesOptArr={catQuesOptArr}
          handleSelectCat={handleCategoryChange}
          selectedCat={selectedCat}
          loadingCat={loadingCat}
          pressedNext={pressedNext}
        />
        <Box height="3%" />

        {/* {isRPM ? (
          ///For RPM Program Changes
          <DeviceBox loadingProblem={loadingProb} problemArr={problemArr} />
        ) : (
          <ProblemBox loadingProblem={loadingProb} problemArr={problemArr} />
        )} */}
        <ProblemBox loadingProblem={loadingProb} problemArr={problemArr} />
      </Box>
      {selectedCat && (
        <QuestionBox
          catQuesOpt={
            catQuesOptArr.find(
              (el) => el.categoryId == selectedCat.categoryId
            ) || null
          }
          handleQuestionClick={handleQuestionClick}
          openQuestionIds={openQuestionIds}
          handleOptionClick={handleOptionClick}
          handleChangeText={handleChangeText}
          handleUpdateText={handleUpdateText}
          handleSave={handleSave}
          loadingOptQuesId={loadingOptQuesId}
          setLocalInput={(val) => setLocalInput(val)}
          localInput={localInput}
          readOnly={readOnly}
          selectedPrgm={selectedPrgm}
          pressedNext={pressedNext}
        />
      )}
    </Fragment>
  );
};
export default CarePlanQuestionnaire;
