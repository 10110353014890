import { Fragment, useState, useEffect, FC } from "react";
import { Box } from "@mui/material";
import { commonFormSwitch } from "Utils/SwitchCases";
import { FormDataType } from "DataTypes/Form";
import { AppSettingsModalProps } from "../../DataTypes/Modal";
import Loader from "Components/Loader";

const AppSettingsModal: FC<AppSettingsModalProps> = (props) => {
  const { formArr, handleChange } = props;
  const [formData, setFormData] = useState<FormDataType[]>([]);

  useEffect(() => {
    formArr && setFormData(formArr);
  }, [formArr]);

  return (
    <Fragment>
      {formData.length == 0 ? (
        <Loader />
      ) : (
        formData.map((el) => {
          return (
            <Box key={el.id} margin={"10px"}>
              {commonFormSwitch({
                formObj: el,
                onChange: (data) => handleChange(data),
              })}
            </Box>
          );
        })
      )}
    </Fragment>
  );
};
export default AppSettingsModal;
