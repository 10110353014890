import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { Filter2DataProps } from "DataTypes/Common";
import FilterData from "Components/Filter2/Data";
import { DropdownArrPropsType } from "DataTypes/Form";

export const actionArr: (
  handleChart: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void
) => actionArrType[] = (handleChart, handlePatientDetails, handleTask) => {
  return [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ControlledTable.ActionData.pendingAction(handleTask),
  ];
};

export const TableCol: (roleId: number | null) => columnType[] = (roleId) => {
  return [
    ControlledTable.ColumnData.DateTimeCol1("10%"),
    ControlledTable.ColumnData.StatusCol("9%"),
    ...(roleId == 1 || roleId == 2 || roleId == 6
      ? [ControlledTable.ColumnData.CreatedByCol("9%")]
      : []),
    ControlledTable.ColumnData.PatientCol("9%"),
    ...(roleId != 6 ? [ControlledTable.ColumnData.AssigneeCol("9%")] : []),
    ...(roleId != 6 ? [ControlledTable.ColumnData.TrainerNameCol("9%")] : []),
    ControlledTable.ColumnData.DeviceTypeCol("9%"),
    ControlledTable.ColumnData.DeviceNameCol("10%"),
    // ControlledTable.ColumnData.ActionCol("14%"),
  ];
};

export const FilterArr = (
  roleId: number | null,
  filterStatus: DropdownArrPropsType[]
): Filter2DataProps[] => {
  return [
    FilterData.taskDate,
    { ...FilterData.taskStatus, dropArr: filterStatus },
    ...(roleId == 1 || roleId == 2 || roleId == 6
      ? [FilterData.taskCreatedBy]
      : []),
    ...(roleId == 1 || roleId == 2 || roleId == 6
      ? [FilterData.taskCreatedByDesig]
      : []),
    FilterData.patientName,
    FilterData.deviceName,
    FilterData.deviceTypeDescription,
    ...(roleId != 6 ? [FilterData.taskAssignee] : []),
    ...(roleId != 6 ? [FilterData.trainerName] : []),
  ];
};
