import { FC, useEffect, Fragment, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  PatientInitialStateType,
  ReduxScreenNavType,
  ScreenInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { useDispatch, useSelector } from "react-redux";
import { CPNavArr, RPMNavArr, TCMNavArr } from "./Data";
import { setNavArr } from "Redux/Screen/Action";
import { CareNavType } from "DataTypes/PatientManagement.type";
import { RouteUrls } from "Constant/RouteUrls";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import CarePlanApi from "Service/CarePlan.api";
import { Loader } from "Components";
import CPFormatter from "Utils/CP.Formatter";

const RevisionPlan: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientInfo, selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { navArr }: ScreenInitialStateType = useSelector(
    (state: any) => state?.screen
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const currentUrl = window.location.href;
  const [loading, setLoading] = useState(true);
  const isRPM =
    currentUrl.includes(RouteUrls.treatmentPlan) ||
    currentUrl.includes(RouteUrls.revisionPlan);
  const isTCM = currentUrl.includes(RouteUrls.tcm);

  useEffect(() => {
    dispatch(
      setNavArr([
        ...navArr.filter((el) => el.level < 4),
        ...handleNavArr(
          selectedPrgm.careplanId || 0,
          selectedPrgm.careplanStage
        ),
      ])
    );
    return () => {
      dispatch(setNavArr([...navArr.filter((el) => el.level < 4)]));
    };
  }, [selectedPrgm.careplanId, selectedPrgm.careplanStage]);

  useEffect(() => {
    handleInitalLoad();
    return () => {
      dispatch(addSelectedPrgm({ ...selectedPrgmInitialState }));
    };
  }, []);

  const handleInitalLoad: () => void = async () => {
    setLoading(true);
    const payload = {
      careplanId: selectedPrgm.careplanId,
    };
    const res = await CarePlanApi.GenericInfo({ data: payload });
    if (res?.success) {
      const checkedRes = CPFormatter.CPGenericInfo(res?.data);
      const isSTM = checkedRes.teamMember && myInfo.roleId != 4 ? true : false;
      dispatch(
        addSelectedPrgm({
          ...selectedPrgm,
          ...checkedRes,
          showLogTime: selectedPrgm.showLogTime,
          recurringPlanningMonth: selectedPrgm.recurringPlanningMonth,
          recurringPlanningYear: selectedPrgm.recurringPlanningYear,
          readOnlyArr: selectedPrgm.readOnlyArr,
          callDefaultRoute: selectedPrgm.callDefaultRoute,
          showSaveBtn: selectedPrgm.showSaveBtn,
          showSubmitBtn: selectedPrgm.showSubmitBtn,
          careplanActivity: "RPM Revision",
          seniorTeamMember: isSTM,
        })
      );
    } else navigate(-1);
    setLoading(false);
  };

  const handleNavArr: (id: number, stage: number) => ReduxScreenNavType[] = (
    id,
    stage
  ) => {
    let newArr: CareNavType[] = [];
    newArr = (isTCM ? TCMNavArr : isRPM ? RPMNavArr : CPNavArr).filter(
      (el) => el.stage <= stage
    );
    let switchpath = "";
    if (isTCM) switchpath = `${RouteUrls.tcm}/${id}`;
    else if (isRPM) switchpath = `${RouteUrls.revisionPlan}/${id}`;
    else switchpath = `${RouteUrls.carePlan}/${id}`;
    return newArr.map((el) => {
      return {
        id: el.id,
        label: el.label,
        path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${switchpath}/${el.path}`,
        level: el.level,
        link: el.link,
        navType: "patient",
      };
    });
  };

  return (
    <Fragment>{selectedPrgm && loading ? <Loader /> : <Outlet />}</Fragment>
  );
};

export default RevisionPlan;
