import { FC, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import Loader from "Components/Loader";
import { QualityMeasureType } from "DataTypes/Services/Patient.type";
import NoData from "Components/NoData";
import UncontrolledAccord from "Components/NewAccord/Uncontrolled";
import { TableLayout } from "Layouts";
import ControlledTable from "Components/ControlledTable";
import { TableCol, actionArr } from "./Data";
import { QualityTabType } from "DataTypes/Common";

const TestCompleted: FC<QualityTabType> = ({ data, loading, handleEdit }) => {
  return (
    <Box sx={{ ...sxMain }}>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {data && data?.length > 0 ? (
            data?.map((el) => {
              return (
                <UncontrolledAccord
                  key={el.id}
                  head={
                    <Typography variant="body2" fontWeight={"bold"}>
                      {el.canonicalName}
                    </Typography>
                  }
                  body={
                    <Box width={"100%"}>
                      <TableLayout
                        tblBody={
                          <ControlledTable.TblBody
                            tableColumn={() => TableCol}
                            tableRow={el.dateArr}
                            tableAction={(data: QualityMeasureType) => {
                              return actionArr(() => handleEdit(data, true));
                            }}
                          />
                        }
                        tblHead={ControlledTable.TblHead(TableCol)}
                        height={"100%"}
                      />
                    </Box>
                  }
                />
              );
            })
          ) : (
            <NoData />
          )}
        </Fragment>
      )}
    </Box>
  );
};
export default TestCompleted;

const sxMain = {
  height: "100%",
  overflow: "auto",
  px: 2,
};
