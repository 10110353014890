import { ControlledForm } from "Components";
import { TimeZoneArr } from "Constant/Arr";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";

export const DetailForm: (
  roleList: DropdownArrPropsType[],
  genderList: DropdownArrPropsType[]
) => FormDataType[] = (roleList, genderList) => {
  return [
    ControlledForm.FormData.UserIdData({ required: true }),
    ControlledForm.FormData.FirstNameData(),
    ControlledForm.FormData.LastNameData(),
    ControlledForm.FormData.GenderData({ dropArr: genderList }),
    ControlledForm.FormData.SpecialityData(),
    ControlledForm.FormData.DesignationData(),
    ControlledForm.FormData.RoleData({ dropArr: roleList }),
    ControlledForm.FormData.LicenseIdData(),
    ControlledForm.FormData.ProviderNpiData(),
    {
      ...ControlledForm.FormData.PrimEmailData({ required: true }),
      iconId: "info-icon",
      infoText:
        "Temporary password will be sent to user email for first time login.",
    },
    ControlledForm.FormData.MobNoData(),
    ControlledForm.FormData.OfficeNoData(),
    ControlledForm.FormData.DirectNoData(),
    ControlledForm.FormData.DobData(),
    ControlledForm.FormData.AddressData(),
    ControlledForm.FormData.CityNameData(),
    ControlledForm.FormData.StateNameData(),
    ControlledForm.FormData.ZipcodeData(),
    ControlledForm.FormData.CountryNameData(),
    ControlledForm.FormData.TimezoneData({ dropArr: TimeZoneArr }),
  ];
};

export const LocationForm: (
  locationArr: DropdownArrPropsType[],
  directArr?: DropdownArrPropsType[]
) => FormDataType[] = (locationArr, directArr) => {
  return [
    {
      id: "location",
      label: "Location Name",
      placeholder: "Select Location",
      boxType: "single-select-drop",
      dropArr: locationArr,
      boxSize: 6,
      validatetype: "dropdown",
      required: true,
    },
    {
      id: "directNumber",
      label: "Direct Number",
      placeholder: "Select Direct Number",
      boxType: "single-select-drop",
      dropArr: directArr || [],
      boxSize: 6,
      validatetype: "dropdown",
      required: true,
    },
  ];
};

export const PracticeForm: (
  practiceArr: DropdownArrPropsType[],
  locationArr?: DropdownArrPropsType[],
  directArr?: DropdownArrPropsType[]
) => FormDataType[] = (practiceArr, locationArr, directArr) => {
  return [
    {
      id: "practice",
      label: "Organization Name",
      placeholder: "Select Organization",
      boxType: "single-select-drop",
      dropArr: practiceArr,
      boxSize: 3,
      validatetype: "dropdown",
      required: true,
    },
    {
      id: "location",
      label: "Location Name",
      placeholder: "Select Location",
      boxType: "single-select-drop",
      dropArr: locationArr || [],
      boxSize: 3,
      validatetype: "dropdown",
      required: true,
    },
    {
      id: "directNumber",
      label: "Direct Number",
      placeholder: "Select Direct Number",
      boxType: "single-select-drop",
      dropArr: directArr || [],
      boxSize: 3,
      validatetype: "dropdown",
      required: true,
    },
  ];
};

export const DisForm: FormDataType[] = [
  {
    id: "practice",
    label: "Organization Name",
    placeholder: "Select Organization",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "name",
    readOnly: true,
  },
  {
    id: "location",
    label: "Location Name",
    placeholder: "Select Location",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "name",
    readOnly: true,
  },
  {
    id: "directNumber",
    label: "Direct Number",
    placeholder: "Select Direct Number",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "name",
    readOnly: true,
  },
];

export const DisLocForm: FormDataType[] = [
  {
    id: "location",
    label: "Location Name",
    placeholder: "Select Location",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "name",
    readOnly: true,
  },
  {
    id: "directNumber",
    label: "Direct Number",
    placeholder: "Select Direct Number",
    boxType: "input-box",
    boxSize: 3,
    validatetype: "name",
    readOnly: true,
  },
];
