import { Filter2DataProps, FilterValType } from "DataTypes/Common";
import DateTime from "Utils/DateTime";
import { CheckArr, CheckStringEmpty } from "Utils/common";

const FilterVal: (obj: Filter2DataProps) => FilterValType = (obj) => {
  switch (obj.type) {
    case "single-select":
      return CheckStringEmpty(obj.value);
    case "multi-select":
      return CheckArr(obj.value);
    case "date":
      return DateTime.ToUTCDate(obj.value);
    case "input":
      if (obj.payType == "string") {
        return CheckArr(obj.value).join(", ");
      } else if (obj.payType == "mobile") {
        const newArr = CheckArr(obj.value).map((el) =>
          CheckStringEmpty(el).replace(/[^0-9]/g, "")
        );
        return newArr.join(", ");
      } else {
        return CheckArr(obj.value);
      }
    default:
      return "";
  }
};

export default FilterVal;
