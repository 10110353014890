import { FC } from "react";
import { Box, Typography } from "@mui/material";
import React from "react";
import { CommonStyle } from "../../Components"
const NoData: FC = () => {
  return (
    <Box sx={{ ...CommonStyle.sxColumnCenter }} height="100%">
      <Typography variant="body1">No Data Found</Typography>
    </Box>
  );
};

export default NoData;
