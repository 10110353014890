import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, CstmAccordion, Loader } from "Components";
import Insurance from "./Insurance";
import PatientApi from "Service/Patient.api";
import {
  RedoxAppointmentType,
  RedoxContactType,
  RedoxInsuranceType,
} from "DataTypes/Services/Patient.type";
import EmergencyContacts from "./EmergencyContacts";
import Appointments from "./Appointments";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import RedoxFormatter from "Utils/Redox.Formatter";

const PatientViewCard: FC = () => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [InsuranceData, setInsuranceData] = useState<RedoxInsuranceType[]>([]);
  const [EmergencyContactsData, setEmergencyContactsData] = useState<
    RedoxContactType[]
  >([]);
  const [AppointmentsData, setAppointmentsData] = useState<
    RedoxAppointmentType[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInsurance();
    getEmergencyContacts();
    getAppointments();
  }, []);

  const getInsurance: () => void = async () => {
    const res: any = await PatientApi.RedoxInsurance({ id1: patientInfo.id });
    if (res?.success) {
      setInsuranceData(RedoxFormatter.InsuranceList(res?.data?.insurances));
    }
    setLoading(false);
  };

  const getEmergencyContacts: () => void = async () => {
    const res = await PatientApi.RedoxContact({ id1: patientInfo.id });
    if (res?.success) {
      setEmergencyContactsData(RedoxFormatter.ContactList(res?.data));
    }
  };

  const getAppointments: () => void = async () => {
    const res = await PatientApi.RedoxAppointments({ id1: patientInfo.id });
    if (res?.success) {
      setAppointmentsData(RedoxFormatter.AppointmentList(res?.data));
    }
  };

  return (
    <Box height="100%" overflow={"auto"} sx={{ ...CommonStyle.sxWhiteBox }}>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ p: 2 }}>
          <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
            {"Patient's Notes"}
          </Typography>
          <CstmAccordion
            mainContent={
              <Typography variant="subtitle1" fontWeight="bold">
                Emergency Contact
              </Typography>
            }
            subContent={EmergencyContacts(EmergencyContactsData)}
          />
          <CstmAccordion
            mainContent={
              <Typography variant="subtitle1" fontWeight="bold">
                Insurance
              </Typography>
            }
            subContent={Insurance(InsuranceData)}
          />
          <CstmAccordion
            mainContent={
              <Typography variant="subtitle1" fontWeight="bold">
                Appointments
              </Typography>
            }
            subContent={Appointments(AppointmentsData)}
          />
        </Box>
      )}
    </Box>
  );
};
export default PatientViewCard;
