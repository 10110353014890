import { Box, Divider, Grid, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import { FormDataType } from "DataTypes/Form";
import CstmBtn from "Components/CstmBtn";
import { FC, useEffect, useState } from "react";
import { DataArr } from "./Data";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { useNavigate } from "react-router-dom";
import { HandleChangeProps } from "DataTypes/Common";
import { ListInitialStateType, PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import PatientApi from "Service/Patient.api";
import DataFormatter from "Utils/DataFormatter";
import {
  GetUniqueObjArr,
  HandleFormChange,
  ValidateFormData,
} from "Utils/common";
import PatientFormatter from "Utils/Patient.Formatter";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { ActivityTimeLogType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import CommonFormatter from "Utils/Common.Formatter";

const AddOnTimeLog: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { patientInfo, patientOrg }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const {
    addTimeLogType,
    addRPMTimeLogType,
    trainingReq,
  }: ListInitialStateType = useSelector((state: any) => state?.list);
  const RPMTimeLogList = [...addTimeLogType, ...addRPMTimeLogType];
  const newPatientId = patientInfo.id;
  const [formData, setFormData] = useState<FormDataType[]>(
    JSON.parse(JSON.stringify(DataArr()))
  );
  const [programList, setProgramList] = useState<ActivityTimeLogType[]>([]);
  const [cpObj, setCpObj] = useState<ActivityTimeLogType | null>(null);

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "patient-details",
      label: "Clinical Summary",
      path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${RouteUrls.addonTime}`,
      level: 3,
      link: RouteUrls.addonTime,
      navType: "patient",
    });
    handleOnload();
  }, []);

  const handleOnload: () => void = async () => {
    const formArr: FormDataType[] = JSON.parse(JSON.stringify(formData));
    const timeLogActivityRes = await PatientApi.timeLogActivity({
      id1: newPatientId,
    });
    if (timeLogActivityRes?.success) {
      const activityFormateRes = PatientFormatter.ActivityTimeLogRes(
        timeLogActivityRes?.data
      );
      setProgramList(activityFormateRes);
      const uniquePgrmList = GetUniqueObjArr(activityFormateRes, "programId");
      const prgmDropData = DataFormatter.FormatDropdownData(
        uniquePgrmList,
        "programId",
        "abbreviation",
        "programId"
      );
      formArr[0].dropArr = prgmDropData;
      formArr[2].maxVal = DateTime.CurrentDate() + ", 11:59 PM";
      formArr[3].maxVal = DateTime.CurrentDate() + ", 11:59 PM";
      formArr[5].value = "0";
      formArr[4].dropArr = addTimeLogType;
      formArr[6].dropArr = trainingReq;
      setFormData(formArr);
    }
  };

  const handleChange: (props: HandleChangeProps) => void = (props) => {
    const { value, id } = props;
    const newFormData: FormDataType[] = JSON.parse(JSON.stringify(formData));
    if (id == "programName") {
      const selectedPgrm = programList.filter((el) => el.programId == value);
      if (selectedPgrm && selectedPgrm.length > 1) {
        const activityDropArr = DataFormatter.FormatDropdownData(
          selectedPgrm,
          "careplanId",
          "activity",
          "activity"
        );
        newFormData[1].boxType = "single-select-drop";
        newFormData[1].validatetype = "dropdown";
        newFormData[1].dropArr = activityDropArr;
        newFormData[1].readOnly = false;
        newFormData[6].boxType = "single-select-drop";
        newFormData[6].required = true;
        newFormData[4].dropArr = RPMTimeLogList;
      } else {
        const find = selectedPgrm[0];
        setCpObj(find || null);
        newFormData[1].boxType = "input-box";
        newFormData[1].validatetype = "name";
        newFormData[1].readOnly = true;
        newFormData[1].value = find?.activity || "";
        newFormData[2].minVal = DateTime.ToLocalDate(
          value == 3 ? find?.dischargeDate : find?.enrollmentDate
        );
        newFormData[6].boxType =
          find?.abbreviation == "RPM" ? "single-select-drop" : "empty-block";
        newFormData[6].required = find?.abbreviation == "RPM" ? true : false;
        newFormData[4].dropArr =
          find?.abbreviation != "RPM" ? addTimeLogType : RPMTimeLogList;
      }
    }
    if (id == "activity") {
      const selectedPgrm = programList.find(
        (el) => el.programId == newFormData[0].value && el.activity == value
      );
      newFormData[2].minVal = DateTime.ToLocalDate(
        newFormData[0].value == 3
          ? selectedPgrm?.dischargeDate
          : selectedPgrm?.enrollmentDate
      );
      setCpObj(selectedPgrm || null);
    }
    if (id == "start-date-time") {
      newFormData[5].value = DateTime.CalculateDuration(
        DateTime.ValidDate(value),
        DateTime.ValidDate(newFormData[3].value)
      ).toString();
      newFormData[3].minVal = DateTime.ValidDate(value);
    }
    if (id == "end-date-time") {
      newFormData[5].value = DateTime.CalculateDuration(
        DateTime.ValidDate(newFormData[2].value),
        DateTime.ValidDate(value)
      ).toString();
    }
    setFormData(HandleFormChange(props, newFormData));
  };

  const handleSave: () => void = async () => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck && cpObj) {
      const payload = {
        careplanId: cpObj.careplanId,
        careplanRecurringId: cpObj?.careplanRecurringId,
        startDateTime: DateTime.ToUTCDateTime(formData[2].value),
        endDateTime: DateTime.ToUTCDateTime(formData[3].value),
        ...(formData[6].value && {
          interactiveCommunicationDone:
            formData[6].value == "Yes" ? true : false,
        }),
        notes: formData[7].value,
        reasons: formData[1].value,
        state: "addon time",
        type: formData[4].dropValue,
      };
      const res = await PatientApi.timeLog({
        id1: cpObj?.careplanId || null,
        data: payload,
      });
      if (res?.success) {
        dispatch(setAlertData(res?.alert));
        navigate(-1);
      }
    }
  };

  return (
    <Box width="91%" sx={{ ...CommonStyle.sxWhiteCard }} overflow="auto">
      <Box sx={{ p: 2, ...CommonStyle.sxRowCenter }}>
        <Box width="70%">
          <Box sx={{ ...sxHead }}>
            <Box mr={3}>
              <Typography variant="body2">{"Patient's Name:"}</Typography>
              <Typography variant="body2" fontWeight="bold">
                {patientInfo.name}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">Organization Name:</Typography>
              <Typography variant="body2" fontWeight="bold">
                {patientOrg.practiceName}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ color: "custom.main", my: 0.5 }} />
          <Grid container columnSpacing={2} sx={{ mb: 1 }}>
            {formData && formData.length > 0
              ? formData.map((el: FormDataType) => {
                return (
                  <Grid
                    key={el.id}
                    item
                    {...ControlledBoxSizeSwitch(el.boxSize)}
                  >
                    {commonFormSwitch({
                      formObj: el,
                      onChange: (data) => handleChange(data),
                    })}
                  </Grid>
                );
              })
              : null}
          </Grid>
          <Box sx={{ mt: 2.5, ...CommonStyle.sxRow }} justifyContent={"end"}>
            <CstmBtn
              label="save"
              sxProps={{ mr: 2 }}
              onClick={() => {
                handleSave();
              }}
              width="15%"
            />
            <CstmBtn
              label="Cancel"
              onClick={() => navigate(`../${RouteUrls.clinicalSummary}`)}
              btnType="border"
              width="15%"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AddOnTimeLog;

const sxHead = {
  display: "flex",
  flexDirection: "row",
  "& >div": {
    display: "flex",
    alignItems: "center",
    "& >p:first-of-type": {
      mr: 1,
    },
  },
};
