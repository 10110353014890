import {
  CheckNumber,
  GetUniqueArr,
  AscendSortByNumber,
  CheckStringEmpty,
  FormatSysMobileNo,
} from "Utils/common";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import {
  UserListApiType,
  UserPracticeApiType,
  UserReadApiType,
  UserRolePermissionApiType,
} from "DataTypes/Services/User.type";
import {
  PracticeListApiType,
  PracticeReadApiType,
} from "DataTypes/Services/Practice.type";
import {
  PracticeDetailsFormArrType,
  PracticeLocationArrType,
} from "DataTypes/PracticeMangement.type";
import DateTime from "Utils/DateTime";
import { DisForm, DisLocForm } from "./Data";

///// Local formating
const FilterOrgList: (list: PracticeListApiType[]) => PracticeListApiType[] = (
  list
) => {
  const newList = list.filter((listEl) => {
    if (
      listEl.status == "ACTIVE" &&
      listEl.masterPractice == false &&
      (listEl.businessModels.id == 1 || listEl.businessModels.id == 3)
    )
      return listEl;
  });
  return newList;
};

const DropOrgList: (list: PracticeListApiType[]) => DropdownArrPropsType[] = (
  list
) => {
  const newList: DropdownArrPropsType[] =
    list.map((listEl, index: number) => {
      return {
        id: listEl.id || index,
        label: listEl.name,
        value: listEl.id || 0,
      };
    }) || [];
  return newList;
};

const DropLocationList: (
  data: PracticeReadApiType
) => PracticeLocationArrType[] = (data) => {
  return data.status == "ACTIVE"
    ? data.practiceLocations
        .filter((locEl) => locEl.status == "ACTIVE")
        .map((locEl, index) => {
          return {
            practiceLocationOrder: locEl.practiceLocationOrder,
            dropVal: {
              id: locEl.id || index,
              label: locEl.location1,
              value: `${locEl.id}:${data.id}:${0}`,
            },
          };
        })
    : [];
};

const DropDirectList: (data: PracticeReadApiType) => DropdownArrPropsType[] = (
  data
) => {
  const newList: DropdownArrPropsType[] = [];
  data.practiceLocations.forEach((locEl) => {
    locEl.practiceLocationDirectNumbers.forEach((dirEl, index: number) => {
      newList.push({
        id: dirEl.id || index,
        label: FormatSysMobileNo(dirEl.directNumber),
        value: `${dirEl.id}:${locEl.id}:${0}`,
      });
    });
  });
  return newList;
};

const FilteredMasterRoleList: (
  list: DropdownArrPropsType[]
) => DropdownArrPropsType[] = (list) => {
  let filterList: DropdownArrPropsType[] = [];
  filterList = list.filter((listEl) => listEl.id != 2);
  return filterList;
};

const FilteredOtherRoleList: (
  list: DropdownArrPropsType[],
  modelId: number | null
) => DropdownArrPropsType[] = (list, modelId) => {
  let filterList: DropdownArrPropsType[] = [];
  filterList = list.filter((listEl) => listEl.id != 1);
  if (modelId == 3) {
    filterList = filterList.filter((filEl) => filEl.id != 3 && filEl.id != 4);
  }
  return filterList;
};

const MapUserToForm: (
  user: UserReadApiType,
  formArr: FormDataType[]
) => FormDataType[] = (user, formArr) => {
  formArr[0].value = user.userName;
  formArr[0].readOnly = true;
  formArr[1].value = user.firstName;
  formArr[2].value = user.lastName;
  formArr[3].dropValue = formArr[3].value = user.gender;
  formArr[4].value = user?.speciality;
  formArr[4].required = user.role[0].id == 5 ? true : false;
  formArr[5].value = user.designation;
  formArr[6].dropValue = formArr[6].value = user.role[0].id || 0;
  formArr[6].readOnly = true;
  formArr[7].value = user.licence;
  formArr[8].value = user.providerNpi;
  formArr[8].required = user.role[0].id == 5 ? true : false;
  formArr[9].value = user.email;
  formArr[10].value = FormatSysMobileNo(user.mobile);
  formArr[11].value = FormatSysMobileNo(user.officeNo);
  formArr[12].value = FormatSysMobileNo(user.directNo);
  formArr[13].value = DateTime.ToLocalDate(user.dob);
  formArr[14].value = user.address1;
  formArr[15].value = user.city;
  formArr[16].value = user.state;
  formArr[17].value = user.zipcode;
  formArr[18].value = user.country;
  formArr[19].dropValue = formArr[19].value = user.timezone;
  return formArr;
};

const CVMapForm2Val: (
  myProfile: UserReadApiType,
  assignArr: FormDataType[]
) => { newAssign: PracticeDetailsFormArrType[]; uniquePrac: number[] } = (
  myProfile,
  assignArr
) => {
  const newArr: PracticeDetailsFormArrType[] = [];
  let pracIdArr: number[] = [];
  if (myProfile.role[0].id != 1) {
    const newPracticeArr = myProfile.practice.filter((arrEl) => {
      if (!(arrEl.id == 501 || arrEl.name == "CareVitality")) return arrEl;
    });
    newPracticeArr.forEach((pracEl) => {
      pracIdArr.push(pracEl.id || 0);
      pracEl.practiceLocations.forEach((locEl) => {
        locEl.practiceLocationDirectNumbers.forEach((dirEl) => {
          let newFormArr: FormDataType[] = [];
          let status: "ACTIVE" | "DISABLED" = "ACTIVE";
          if (pracEl.status == "ACTIVE" && locEl.status == "ACTIVE") {
            newFormArr = JSON.parse(JSON.stringify(assignArr));
            newFormArr[0].dropValue = newFormArr[0].value = pracEl.id || 0;
            newFormArr[1].dropValue =
              newFormArr[1].value = `${locEl.id}:${pracEl.id}:${locEl.userPracticeLocationOrder}`;
            newFormArr[2].dropValue =
              newFormArr[2].value = `${dirEl.id}:${locEl.id}:${locEl.userPracticeLocationOrder}`;
            status = "ACTIVE";
          } else {
            newFormArr = JSON.parse(JSON.stringify(DisForm));
            newFormArr[0].value = pracEl.name;
            newFormArr[0].dropValue = pracEl.id;
            newFormArr[1].value = locEl.location1;
            newFormArr[1].dropValue = `${locEl.id}:${pracEl.id}:${locEl.userPracticeLocationOrder}`;
            newFormArr[2].value = dirEl.directNumber;
            newFormArr[2].dropValue = `${dirEl.id}:${locEl.id}:${locEl.userPracticeLocationOrder}`;
            status = "DISABLED";
          }
          newArr.push({
            id: locEl.userPracticeLocationOrder,
            practiceLocationOrder: locEl.practiceLocationOrder,
            status,
            formArr: newFormArr,
          });
        });
      });
    });
    pracIdArr = pracIdArr.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    pracIdArr = pracIdArr.filter((el) => el != 0);
  }
  const newArr1: PracticeDetailsFormArrType[] = AscendSortByNumber(
    newArr,
    "id"
  );
  const newArr2: PracticeDetailsFormArrType[] = newArr1.map((el, index) => {
    const newLocation = index + 1;
    const Arr1 = el.formArr[1].dropValue.split(":");
    el.formArr[1].dropValue = `${Arr1[0]}:${Arr1[1]}:${newLocation}`;
    const Arr2 = el.formArr[2].dropValue.split(":");
    el.formArr[2].dropValue = `${Arr2[0]}:${Arr2[1]}:${newLocation}`;
    return {
      ...el,
      id: newLocation,
    };
  });
  return { newAssign: newArr2, uniquePrac: pracIdArr };
};

const OtherMapForm2Val: (
  myProfile: UserReadApiType,
  assignArr: FormDataType[]
) => PracticeDetailsFormArrType[] = (myProfile, assignArr) => {
  const newArr: PracticeDetailsFormArrType[] = [];
  if (myProfile.role[0].id != 2) {
    myProfile.practice.forEach((pracEl) => {
      pracEl.practiceLocations.forEach((locEl) => {
        locEl.practiceLocationDirectNumbers.forEach((dirEl) => {
          let newFormArr: FormDataType[] = [];
          let status: "ACTIVE" | "DISABLED" = "ACTIVE";
          if (pracEl.status == "ACTIVE" && locEl.status == "ACTIVE") {
            newFormArr = JSON.parse(JSON.stringify(assignArr));
            newFormArr[0].dropValue =
              newFormArr[0].value = `${locEl.id}:${pracEl.id}:${locEl.userPracticeLocationOrder}`;
            newFormArr[1].dropValue =
              newFormArr[1].value = `${dirEl.id}:${locEl.id}:${locEl.userPracticeLocationOrder}`;
            status = "ACTIVE";
          } else {
            newFormArr = JSON.parse(JSON.stringify(DisLocForm));
            newFormArr[0].value = locEl.location1;
            newFormArr[0].dropValue = `${locEl.id}:${pracEl.id}:${locEl.userPracticeLocationOrder}`;
            newFormArr[1].value = dirEl.directNumber;
            newFormArr[1].dropValue = `${dirEl.id}:${locEl.id}:${locEl.userPracticeLocationOrder}`;
            status = "DISABLED";
          }
          newArr.push({
            id: locEl.userPracticeLocationOrder,
            practiceLocationOrder: locEl.practiceLocationOrder,
            status,
            formArr: newFormArr,
          });
        });
      });
    });
  }
  const newArr1: PracticeDetailsFormArrType[] = AscendSortByNumber(
    newArr,
    "id"
  );
  const newArr2: PracticeDetailsFormArrType[] = newArr1.map((el, index) => {
    const newLocation = index + 1;
    const Arr1 = el.formArr[0].dropValue.split(":");
    el.formArr[0].dropValue = `${Arr1[0]}:${Arr1[1]}:${newLocation}`;
    const Arr2 = el.formArr[1].dropValue.split(":");
    el.formArr[1].dropValue = `${Arr2[0]}:${Arr2[1]}:${newLocation}`;
    return {
      ...el,
      id: newLocation,
    };
  });
  return newArr2;
};

const CVLocationPayload: (
  myPractice: PracticeReadApiType,
  form2: PracticeDetailsFormArrType[],
  roleId: number
) => UserPracticeApiType[] = (myPractice, form2, roleId) => {
  let praticeOrder = 0;
  let locationOrder = form2.length > 0 ? form2[form2.length - 1]?.id : 0;
  const PracticeArr: UserPracticeApiType[] = [];
  if (roleId != 1) {
    form2 = form2.map((formEl) => {
      const currentDirVal = formEl.formArr[2].dropValue;
      const currentArr =
        typeof currentDirVal == "string" ? currentDirVal.split(":") : "";
      const currentLocation =
        typeof currentArr[1] == "string" ? parseInt(currentArr[1]) : 0;
      const currentDirect =
        typeof currentArr[0] == "string" ? parseInt(currentArr[0]) : 0;
      formEl.formArr[1].dropValue = formEl.formArr[1].value = currentLocation;
      formEl.formArr[2].dropValue = formEl.formArr[2].value = currentDirect;
      return formEl;
    });
    form2.forEach((formEl) => {
      const index = PracticeArr.findIndex(
        (pracEl) => pracEl.id == formEl.formArr[0].dropValue
      );
      praticeOrder = praticeOrder + 1;
      if (index == -1) {
        PracticeArr.push({
          id: formEl.formArr[0].dropValue,
          isMasterPractice: false,
          practiceOrder: praticeOrder,
          practiceLocations: [
            {
              id: formEl.formArr[1].dropValue,
              isMasterPracticeLocation: false,
              practiceLocationOrder: formEl.practiceLocationOrder,
              userPracticeLocationOrder: formEl.id,
              practiceLocationDirectNumbers: [
                {
                  id: formEl.formArr[2].dropValue,
                },
              ],
            },
          ],
        });
      } else {
        PracticeArr[index].practiceLocations.push({
          id: formEl.formArr[1].dropValue,
          isMasterPracticeLocation: false,
          practiceLocationOrder: formEl.practiceLocationOrder,
          userPracticeLocationOrder: formEl.id,
          practiceLocationDirectNumbers: [
            {
              id: formEl.formArr[2].dropValue,
            },
          ],
        });
      }
    });
  }
  const mainPractice: UserPracticeApiType = {
    id: CheckNumber(myPractice.id),
    isMasterPractice: true,
    practiceOrder: praticeOrder + 1,
    practiceLocations: myPractice.practiceLocations.map((el) => {
      locationOrder = locationOrder ? locationOrder + 1 : null;
      return {
        id: CheckNumber(el.id),
        isMasterPracticeLocation: false,
        practiceLocationOrder: el.practiceLocationOrder,
        userPracticeLocationOrder: locationOrder,
        practiceLocationDirectNumbers: el.practiceLocationDirectNumbers.map(
          (el1) => {
            return {
              id: CheckNumber(el1.id),
            };
          }
        ),
      };
    }),
  };
  PracticeArr.push(mainPractice);
  return PracticeArr;
};

const OtherLocationPayload: (
  form2: PracticeDetailsFormArrType[],
  practice: PracticeReadApiType,
  roleId: number
) => UserPracticeApiType[] = (form2, practice, roleId) => {
  const PracticeArr: UserPracticeApiType[] = [];
  if (roleId == 2) {
    PracticeArr.push({
      id: practice.id || null,
      isMasterPractice: true,
      practiceOrder: 1,
      practiceLocations: practice.practiceLocations.map((locEl) => {
        return {
          id: locEl.id || null,
          isMasterPracticeLocation: locEl.isMasterPracticeLocation,
          practiceLocationOrder: locEl.practiceLocationOrder,
          userPracticeLocationOrder: locEl.practiceLocationOrder,
          practiceLocationDirectNumbers:
            locEl.practiceLocationDirectNumbers.map((dirEl) => {
              return {
                id: dirEl.id || null,
              };
            }),
        };
      }),
    });
  } else {
    const newForm2 =
      form2.map((formEl) => {
        const newFormArr =
          formEl.formArr.map((formArrEl) => {
            const formArrElVal =
              typeof formArrEl.dropValue == "string"
                ? formArrEl.dropValue.split(":")[0]
                : "";
            formArrEl.dropValue = formArrEl.value = parseInt(formArrElVal) || 0;
            return formArrEl;
          }) || [];
        formEl.formArr = newFormArr;
        return formEl;
      }) || [];
    PracticeArr.push({
      id: practice.id || null,
      isMasterPractice: true,
      practiceOrder: 1,
      practiceLocations: [],
    });
    newForm2.forEach((el) => {
      PracticeArr[0]?.practiceLocations?.push({
        id: el?.formArr[0]?.dropValue,
        isMasterPracticeLocation: false,
        practiceLocationOrder: el.practiceLocationOrder,
        userPracticeLocationOrder: el.id,
        practiceLocationDirectNumbers: [{ id: el.formArr[1]?.dropValue }],
      });
    });
  }
  return PracticeArr;
};

const ApiPayload: (
  form1: FormDataType[],
  permissionArr: UserRolePermissionApiType[],
  practiceArr: UserPracticeApiType[],
  myProfile: UserReadApiType | null
) => UserReadApiType = (form1, permissionArr, practiceArr, myProfile) => {
  const newArr = permissionArr.map((el) => {
    return { id: el.id };
  });
  return {
    ...(myProfile && myProfile.id && { id: myProfile.id }),
    userName: typeof form1[0].value == "string" ? form1[0].value : "",
    firstName: typeof form1[1].value == "string" ? form1[1].value : "",
    lastName: typeof form1[2].value == "string" ? form1[2].value : "",
    gender: typeof form1[3].dropValue == "string" ? form1[3]?.dropValue : "",
    speciality: typeof form1[4].value == "string" ? form1[4].value : "",
    designation: typeof form1[5].value == "string" ? form1[5].value : "",
    role: [
      {
        id: typeof form1[6].dropValue == "number" ? form1[6]?.dropValue : null,
      },
    ],
    licence: typeof form1[7].value == "string" ? form1[7].value : "",
    providerNpi: typeof form1[8].value == "string" ? form1[8].value : "",
    email: typeof form1[9].value == "string" ? form1[9].value : "",
    secondaryEmail: "test@gmail.com",
    mobile: CheckStringEmpty(form1[10].value).replaceAll("-", ""),
    officeNo: CheckStringEmpty(form1[11].value).replaceAll("-", ""),
    directNo: CheckStringEmpty(form1[12].value).replaceAll("-", ""),
    dob: DateTime.ToUTCDate(form1[13].value),
    address1: typeof form1[14].value == "string" ? form1[14].value : "",
    address2: "test",
    city: typeof form1[15].value == "string" ? form1[15].value : "",
    state: typeof form1[16].value == "string" ? form1[16].value : "",
    country: typeof form1[18].value == "string" ? form1[18].value : "",
    zipcode: typeof form1[17].value == "string" ? form1[17].value : "",
    timezone: typeof form1[19].value == "string" ? form1[19].value : "",
    practice: practiceArr,
    status: myProfile?.status || "ACTIVE",
    ...(!myProfile && { password: "123456" }),
    permission: newArr && newArr.length > 0 ? newArr : [{ id: 3 }],
  };
};

const FindUniqueFromUser: (
  userArr: UserListApiType[],
  myDetails?: UserReadApiType | null
) => {
  uniqueMail: string[];
  uniqueUserName: string[];
} = (userArr, myDetails) => {
  let mailArr = userArr.map((userEl) => userEl.email);
  mailArr = GetUniqueArr(mailArr);
  mailArr = mailArr.filter((mailEl) => mailEl.length > 0);
  myDetails &&
    (mailArr = mailArr.filter((mailEL) => mailEL != myDetails?.email));
  let userNameArr = userArr.map((userEl) => userEl.userName);
  userNameArr = GetUniqueArr(userNameArr);
  userNameArr = userNameArr.filter((userEl) => userEl.length > 0);
  myDetails &&
    (userNameArr = userNameArr.filter(
      (userEl) => userEl != myDetails?.userName
    ));
  return { uniqueMail: mailArr, uniqueUserName: userNameArr };
};

export default {
  FilterOrgList,
  DropOrgList,
  DropLocationList,
  DropDirectList,
  MapUserToForm,
  // MapForm2Val,
  ApiPayload,
  CVMapForm2Val,
  OtherMapForm2Val,
  CVLocationPayload,
  OtherLocationPayload,
  FilteredMasterRoleList,
  FilteredOtherRoleList,
  FindUniqueFromUser,
};
