import { ThresholdType } from "DataTypes/Services/Settings.type";
import {
  CheckAlertType,
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckThresholdIdType,
} from "./common";
import DeviceTypeFormatter from "./DeviceType.Formatter";

const CheckThreshold: (data: any) => ThresholdType = (data) => {
  const day = CheckNumber(data?.daysLimit);
  const low = CheckNumber(data?.lowerLimit);
  const up = CheckNumber(data?.upperLimit);
  const weightValue = CheckNumber(data?.weightValue);
  return {
    ...DeviceTypeFormatter.DeviceTypeObj(data),
    alertTypeId: CheckNumber(data?.id),
    thresholdType: CheckThresholdIdType(data?.thresholdType),
    alertType: CheckAlertType(data?.alertType),
    daysLimit: day ? `${day}` : "",
    lowerLimit: low ? `${low}` : "",
    problemId: CheckNumber(data?.problemId),
    practiceId: CheckNumber(data?.practiceId),
    upperLimit: up ? `${up}` : "",
    uomId: CheckStringEmpty(data?.uomId),
    uomLabel: CheckStringEmpty(data?.uomDescription),
    problemName: CheckStringEmpty(data?.problemName),
    operatorDescription: CheckStringEmpty(data?.operatorDescription),
    weightFluctuation: CheckStringEmpty(data?.weightFluctuation),
    weightValue: weightValue ? `${weightValue}` : "",
    operatorId: CheckStringEmpty(data?.operatorId),
  };
};

const Search: (data: any) => ThresholdType[] = (data) => {
  const CheckedArr = CheckArr(data);
  return CheckedArr.map((el) => CheckThreshold(el));
};

export default { Search };
