export const STORE_MODEL_DROP_LIST = "STORE_MODEL_DROP_LIST";
export const STORE_PROGRAM_DROP_LIST = "STORE_PROGRAM_DROP_LIST";
export const STORE_PROGRAM_LIST = "STORE_PROGRAM_LIST";
export const STORE_ROLE_DROP_LIST = "STORE_ROLE_DROP_LIST";
export const CLEAR_LIST = "CLEAR_LIST";
export const STORE_NOTIFICATION_LIST = "STORE_NOTIFICATION_LIST";
export const STORE_GENDER_LIST = "STORE_GENDER_LIST";
export const STORE_QUALITY_LIST = "STORE_QUALITY_LIST";
export const STORE_CALLRESULT_LIST = "STORE_CALLRESULT_LIST";
export const STORE_FREQUENCY_LIST = "STORE_FREQUENCY_LIST";
export const STORE_ORG_DROP_LIST = "STORE_ORG_DROP_LIST";
export const STORE_TASK_TYPE_LIST = "STORE_TASK_TYPE_LIST";
export const STORE_REMINDER_DROP_LIST = "STORE_REMINDER_DROP_LIST";
export const STORE_RPM_FREQUENCY_LIST = "STORE_RPM_FREQUENCY_LIST";
export const STORE_TASK_STATUS_LIST = "STORE_TASK_STATUS_LIST";
export const STORE_SMARTMETER_USER_LIST = "STORE_SMARTMETER_USER_LIST";
export const STORE_GROUP_LIST = "STORE_GROUP_LIST";
export const IS_LOGGEDIN_FIRST_TIME = "IS_LOGGEDIN_FIRST_TIME";
export const STORE_WEIGHTSCALE_PROBLEM_LIST = "STORE_WEIGHTSCALE_PROBLEM_LIST";
export const STORE_TIMELOG_TYPE_LIST = "STORE_TIMELOG_TYPE_LIST";
export const STORE_RPM_TIMELOG_TYPE_LIST = "STORE_RPM_TIMELOG_TYPE_LIST";
export const STORE_TRAINING_REQ_TYPE_LIST = "STORE_TRAINING_REQ_TYPE_LIST";
export const STORE_DEVICE_PROVIDER_LIST = "STORE_DEVICE_PROVIDER_LIST";
export const STORE_DEVICE_UOM_LIST = "STORE_DEVICE_UOM_LIST";
