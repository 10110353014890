import { useState, FC, useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CstmBtn from "Components/CstmBtn";
import { FormDataType } from "DataTypes/Form";
import {
  TargetDateArr,
  TaskDeviceData,
  TaskFormDataArr,
  TaskPermission,
  TaskStatusData,
} from "./Data";
import { commonFormSwitch, ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { CommonStyle } from "Components";
import { useLocation, useParams } from "react-router-dom";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { useSelector, useDispatch } from "react-redux";
import PatientApi from "Service/Patient.api";
import { objectValues } from "DataTypes/Services/Index.type";
import { setAlertData, setFormLoader, stopLoading } from "Redux/Screen/Action";
import {
  applyConditionToList,
  capitalizeAllWords,
  formatList,
} from "./formatFormData";
import Patient from "Utils/ApiDataFormatter/Patient";
import DateTime from "Utils/DateTime";
import { ListInitialStateType, PatientInitialStateType } from "DataTypes/Redux";
import { RouteUrls } from "Constant/RouteUrls";
import { ModalLayout } from "Layouts";
import { CommonButtonArray, SingleBtnArray } from "Components/Modal/Data";
import CommonFormatter from "Utils/Common.Formatter";

interface propsTypes {
  from?: string;
  closeModal?: () => void;
  handleAddDevice?: () => Promise<number | null | void>;
  deviceType?: string;
  handleTaskAddSuccess?: () => void;
}
const DeviceTask: FC<propsTypes> = ({
  from,
  closeModal,
  handleAddDevice,
  deviceType,
  handleTaskAddSuccess,
}: propsTypes) => {
  const isDevice = true;
  const {
    taskTypeList,
    taskStatusList,
    smartmeterUserList,
    groupList,
  }: ListInitialStateType = useSelector((state: any) => state?.list);
  const taskFormDataList: FormDataType[] = JSON.parse(
    JSON.stringify(TaskFormDataArr(taskTypeList))
  );
  const taskDeviceFormlist = [...taskFormDataList];
  if (isDevice) {
    TaskDeviceData.forEach((deviceData) => {
      taskDeviceFormlist.push(deviceData);
    });
  } else {
    TaskStatusData.forEach((taskStatusData) => {
      taskDeviceFormlist.push(taskStatusData);
    });
  }
  const dispatch = useDispatch();

  const { taskId } = useParams();
  const currentUrl = window.location.href;
  const [formData, setFormData] = useState<FormDataType[]>(taskDeviceFormlist);
  const [formData1, setFormData1] = useState<FormDataType[]>(TargetDateArr);
  const location = useLocation();
  const loggedUser = useSelector((state: any) => state.user.myInfo);
  const { patientInfo, patientOrg }: PatientInitialStateType = useSelector(
    (state: any) => state.patient
  );

  const patId = patientInfo.id;
  const [priorityType, setPriorityType] = useState("Urgent");
  const [assigneeType, setAssigneType] = useState("User");
  const [readRes, setReadRes] = useState<objectValues>();
  const [userAssignee, setUserAssignee] = useState<objectValues[]>([]);
  const [groupAssignee, setGroupAssignee] = useState<objectValues[]>([]);
  const [status, setStatus] = useState("Pending");
  const [currPermission, setCurrPermission] = useState("");
  const [loading, setLoading] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [prevAssignee, setPrevAssignee] = useState<objectValues | null>(null);
  const [openAssigneeWarningModal, setAssigneeWarningModal] =
    useState<boolean>(false);

  const onAssigneeChange = (e: any): void => {
    setAssigneType(e.target.value);
  };
  const view = currentUrl.includes("device-view");
  const deviceAdd = currentUrl.includes("device-add");
  const addDeviceTask = currentUrl.includes("add-device");
  const isValidTaskId = taskId && !isNaN(parseInt(taskId));
  const assigneeUserName =
    formData[2].dropValue &&
    formData[2].dropArr &&
    formData[2].dropArr.find((user) => user.id == formData[2].dropValue);

  const permissions = (data: any) => {
    if (
      (data?.status && data?.status === "Close") ||
      data?.status === "Completed"
    ) {
      return TaskPermission.VIEW;
    } else if (view) {
      return TaskPermission.VIEW;
    } else if (loggedUser.roleName === "Super Admin") {
      return TaskPermission.ADD;
    } else if (data?.createdBy === loggedUser.id) {
      return TaskPermission.OUTGOING;
    } else if (loggedUser.id === data?.assigneeUserId) {
      return TaskPermission.INCOMING;
    } else {
      return TaskPermission.VIEW;
    }
  };

  useEffect(() => {
    initializeData();
    const link = view ? RouteUrls.deviceViewTask : RouteUrls.deviceUpdateTask;
    const path = view
      ? `${RouteUrls.task}/${taskId}/${link}`
      : `${RouteUrls.tasking}/${taskId}/${link}`;
    if (!addDeviceTask) {
      CommonFormatter.HandleNavArr({
        id: "device-task",
        label: view ? "Task View" : "Task Update",
        path: `/${RouteUrls.hs}/${RouteUrls.patient}/${patientInfo.id}/${path}`,
        level: 3,
        link: link,
        navType: "patient",
      });
    }
  }, []);

  const initializeData = async () => {
    setFormData1(
      formData1.map((el) => {
        el.value = DateTime.TomorrowDateTime();
        el.readOnly = false;
        return el;
      })
    );

    if (isDevice) {
      const indexToUpdate = formData.findIndex(
        (obj: any) => obj.label === "Assignee"
      );

      if (indexToUpdate !== -1) {
        formData[indexToUpdate].dropArr = smartmeterUserList;
      }
      setUserAssignee(smartmeterUserList);
    } else {
      setGroupAssignee(groupList);
      const userList = await PatientApi.fetchUserList({
        data: {
          data: {
            practiceLocationId: patientOrg.practiceLocationId,
            isDeleted: false,
          },
        },
      });
      if (userList.success) {
        setUserAssignee(formatList(userList.data));
      }
    }
    if (deviceAdd || from) {
      setCurrPermission(TaskPermission.ADD);
      const data = applyConditionToList(
        formData,
        TaskPermission.ADD,
        {
          deviceType: deviceType,
          taskId: taskId,
        },
        loggedUser?.id
      );
      setFormData(data);
    }
  };

  useEffect(() => {
    fetchFunc();
  }, [patId, taskId]);

  const fetchFunc = async () => {
    let result: any = null;
    if (isValidTaskId) {
      result = await PatientApi.readTask(parseInt(taskId));
      const formattedData = Patient.TaskResFormatter(result?.data);
      const taskType = permissions(formattedData);
      setCurrPermission(taskType);
      const data = applyConditionToList(
        formData,
        taskType,
        {
          ...formattedData,
          taskId: taskId,
        },
        loggedUser?.id
      );
      if (taskType === TaskPermission.INCOMING || view) {
        setFormData1(
          formData1.map((el) => {
            el.readOnly = true;
            return el;
          })
        );
      }
      if (formattedData?.targateDate) {
        setFormData1(
          formData1.map((el) => {
            el.value = DateTime.UTCToLocalDateTime(formattedData?.targateDate);
            return el;
          })
        );
      }
      formattedData?.status && setStatus(formattedData?.status);
      setFormData(data);
      setReadRes(formattedData);
      setPriorityType(capitalizeAllWords(result?.data?.priority));
      setAssigneType(result?.data?.assigneeUserId ? "User" : "Group");
      setPrevAssignee({
        id: formattedData?.assigneeUserId,
        value: formattedData?.assigneeUserName,
      });
    }
  };

  const onRadioChange = (e: any) => {
    setPriorityType(e.target.value);
  };
  // const onDateChange = (data: any, formData: FormDataType[]) => {
  //   // setDate(data);
  // };

  const handleTaskCreateUpdate: () => void = async () => {
    setLoading(true);
    const newArr = ValidateFormData(formData);
    const newArr1 = ValidateFormData(formData1);
    setFormData(newArr.formData);
    setFormData1(newArr1.formData);
    const isValidStatus =
      !newArr.errorCheck &&
      !newArr1.errorCheck &&
      status !== "Completed" &&
      !!newArr.formData[7].value;
    if (!newArr.errorCheck && !newArr1.errorCheck) {
      if (isValidStatus) {
        setOpenWarningModal(true);
      } else if (
        prevAssignee &&
        assigneeUserName &&
        prevAssignee?.id !== assigneeUserName?.id
      ) {
        setAssigneeWarningModal(true);
      } else {
        updateTask();
      }
    }
    setLoading(false);
  };

  const updateTask = async (): Promise<void> => {
    dispatch(setFormLoader(true));
    const object: objectValues = formData.filter(
      (data) => data.id === "task-assign-to"
    )[0];

    const selectedValue: string = object?.dropValue;
    const assigneeGroupId = object?.dropArr.find(
      (item: objectValues) => item.value === selectedValue
    );

    const IsDeviceId: any = formData.some(
      (item) => item.id === "deviceId" && item.value
    );

    const payload = {
      data: {
        patientId: patId,
        priority: priorityType,
        source: formData[0]?.dropValue,
        type: formData[1]?.dropValue,
        assigneeUserId:
          assigneeType === "Group" ? null : assigneeGroupId?.userId,
        taskDescription: formData[4]?.value,
        subject: formData[3]?.value,
        notes: formData[5]?.value,
        status: status,
        targateDate:
          priorityType === "Normal"
            ? formData1[0].value
              ? DateTime.ToUTCDateTime(formData1[0].value)
              : DateTime.TomorrowDateTime()
            : DateTime.TomorrowDateTime(),
        assigneeGroupId: assigneeType === "Group" ? assigneeGroupId?.id : null,
        deviceType: formData[6]?.value,
        actualDeviceId: IsDeviceId ? formData[7].value || null : null,
      },
    };
    let taskRes;
    if (isValidTaskId)
      taskRes = await PatientApi.updateTask(readRes?.id, payload);
    else {
      if (handleAddDevice) {
        const patientDeviceId = await handleAddDevice();
        if (patientDeviceId) {
          const payload1 = {
            data: {
              ...payload.data,
              patientDeviceId,
            },
          };
          taskRes = await PatientApi.createTask(patId, payload1);
        }
      } else taskRes = await PatientApi.createTask(patId, payload);
    }
    if (taskRes?.success) {
      dispatch(setAlertData(taskRes?.alert));
      if (from) {
        closeModal && closeModal();
        handleTaskAddSuccess && handleTaskAddSuccess();
      } else window.history.back();
    }
    setLoading(false);
    dispatch(stopLoading());
    dispatch(setFormLoader(false));
  };

  const handleStatuschange = (val: string) => {
    const newStatus = val;
    setStatus(newStatus);

    const updatedFormData = formData.map((item) => {
      if (item.id === "deviceId") {
        return {
          ...item,
          required: newStatus === "Completed",
        };
      }
      return item;
    });

    setFormData(updatedFormData);
  };

  return (
    <Box
      height="100%"
      width={from ? "100%" : "91%"}
      overflow="auto"
      paddingLeft={from ? "" : "40px"}
      sx={
        from
          ? {
            ...CommonStyle.sxContentLeft,
          }
          : { ...CommonStyle.sxWhiteBox, ...CommonStyle.sxContentLeft }
      }
    >
      <Box width={from ? "100%" : "85%"}>
        <Grid
          item
          sx={{ ...CommonStyle.sxRow }}
          {...ControlledBoxSizeSwitch(6)}
          justifyContent={"space-between"}
        >
          <Box width={"100%"} display="flex">
            <Box mr={3}>
              <Typography variant="body2">{"Patient's Name:"}</Typography>
              <Typography variant="body2">{"Created By:"}</Typography>
              <Typography variant="body2">{"Date & Time:"}</Typography>
            </Box>
            <Box mr={3}>
              <Typography variant="body2" fontWeight="bold">
                {view || isValidTaskId
                  ? readRes?.patientName
                  : patientInfo.name}
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {view || isValidTaskId
                  ? readRes?.createdByName
                  : `${loggedUser?.firstname}  ${loggedUser?.lastname}`}
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {isValidTaskId
                  ? DateTime.UTCToLocalDateTime(readRes?.createdAt)
                  : DateTime.CurrentDateTime()}
              </Typography>
            </Box>
          </Box>
          {/* <Box display="flex"> */}
          <Box width={"100%"} display="flex" flexDirection="column">
            <Box width="100%" display="flex">
              <Typography sx={{ mr: 2, mt: 1.3, ml: 1 }} variant="body2">
                Priority :
              </Typography>

              <RadioGroup
                row
                value={priorityType}
                onChange={(e) => onRadioChange(e)}
                name="controlled-radio-buttons-group"
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "0.8rem",
                  },
                }}
              >
                <FormControlLabel
                  value="Urgent"
                  disabled={view}
                  control={<Radio color="secondary" />}
                  label="Urgent"
                />
                <FormControlLabel
                  value="Normal"
                  disabled={view}
                  control={<Radio color="secondary" />}
                  label="Normal"
                />
              </RadioGroup>
            </Box>
            <Box width="100%" display="flex" alignItems="center">
              <Typography
                sx={{
                  mx: 1,
                  "&>span:first-of-type": {
                    color: "error.main",
                    mr: 0.25,
                  },
                }}
                variant="body2"
              >
                Target Date & Time<span>*</span>
                <span>:</span>
              </Typography>
              {formData1.map((el) => {
                return (
                  <Grid
                    key={el.id}
                    item
                    {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
                  >
                    {commonFormSwitch({
                      formObj: {
                        ...el,
                        readOnly: priorityType == "Urgent" ? true : el.readOnly,
                      },
                      onChange: (data) =>
                        setFormData1(HandleFormChange(data, formData1)),
                    })}
                  </Grid>
                );
              })}
            </Box>
            <Box width="100%" display="flex">
              <Typography sx={{ mr: 2, mt: 1.3, ml: 1 }} variant="body2">
                Status :
              </Typography>

              <RadioGroup
                row
                value={status}
                onChange={(e) => handleStatuschange(e.target.value)}
                name="controlled-radio-buttons-group"
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "0.8rem",
                  },
                }}
              >
                {taskStatusList &&
                  taskStatusList?.length > 0 &&
                  taskStatusList?.map((task: any) => {
                    if (task.id === "TASK_CLOSED" || task.id === "TASK_OPEN") {
                      return <></>;
                    }
                    return (
                      <FormControlLabel
                        key={task.id}
                        disabled={
                          view || (task.id === "TASK_COMPLETED" && !taskId)
                        }
                        value={task.label}
                        control={<Radio color="secondary" />}
                        label={task.label}
                      />
                    );
                  })}
              </RadioGroup>
            </Box>
          </Box>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={2}>
          {formData && formData.length > 0
            ? formData.slice(0, 2).map((el: FormDataType) => {
              return (
                <Grid
                  key={el.id}
                  item
                  {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
                >
                  {commonFormSwitch({
                    formObj: {
                      ...el,
                    },
                    onChange: (data) =>
                      setFormData(HandleFormChange(data, formData)),
                  })}
                </Grid>
              );
            })
            : null}
        </Grid>
        <Grid
          item
          sx={{ ...CommonStyle.sxRow }}
          {...ControlledBoxSizeSwitch(6)}
          justifyContent={"space-between"}
        >
          {/* <Box display="flex"> */}
          <Box width="100%" display="flex" sx={{ mt: 1.3 }}>
            <Typography sx={{ mr: 2, mt: 1.3 }} variant="body2">
              Assignee Type:
            </Typography>
            <RadioGroup
              row
              value={assigneeType}
              onChange={(e) => onAssigneeChange(e)}
              name="controlled-radio-buttons-group"
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "0.8rem",
                },
              }}
            >
              <FormControlLabel
                disabled={true}
                value="User"
                control={<Radio color="secondary" />}
                label="User"
              />
              <FormControlLabel
                disabled={true}
                value="Group"
                control={<Radio color="secondary" />}
                label="Group"
              />
            </RadioGroup>
          </Box>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {formData && formData.length > 0
            ? formData.slice(2, formData.length + 1).map((el: FormDataType) => {
              return (
                <Grid
                  key={el.id}
                  item
                  {...(el.boxSize && ControlledBoxSizeSwitch(el.boxSize))}
                >
                  {commonFormSwitch({
                    formObj: {
                      ...el,
                    },
                    onChange: (data) =>
                      setFormData(HandleFormChange(data, formData)),
                  })}
                </Grid>
              );
            })
            : null}
        </Grid>
        <Box sx={{ mt: 2.5, ...CommonStyle.sxRow }} justifyContent={"end"}>
          {location?.state?.path == "sidebarTask" ? (
            location?.state?.value == "Completed" ? (
              <></>
            ) : view ? (
              ""
            ) : (
              <CstmBtn
                sxProps={{ mr: 2 }}
                label="Save"
                onClick={() => console.log(true)}
                width="15%"
              />
            )
          ) : view ? (
            ""
          ) : (
            <CstmBtn
              sxProps={{ mr: 2 }}
              label="Save"
              onClick={handleTaskCreateUpdate}
              width="15%"
              loading={loading}
              enableFormLoad={true}
            />
          )}
          <CstmBtn
            label="Cancel"
            onClick={() => {
              from && closeModal ? closeModal() : window.history.back();
            }}
            btnType="border"
            width="15%"
          />
        </Box>
      </Box>
      <ModalLayout
        modwidth="30%"
        heading={"Warning"}
        content={
          <Typography textAlign={"center"}>
            You have selected wrong status for task after providing Device ID.
            Kindly select correct status.
          </Typography>
        }
        open={openWarningModal}
        onClose={() => setOpenWarningModal(false)}
        buttonArr={SingleBtnArray(() => setOpenWarningModal(false), "Close")}
      />
      <ModalLayout
        modwidth="50%"
        heading={"Warning"}
        content={
          <Typography textAlign={"center"}>
            Task assignee {prevAssignee && prevAssignee.value} is changed to{" "}
            {assigneeUserName && assigneeUserName?.userFullName}. This task will
            no longer be visible to your incoming task list as assigned to a new
            user.
          </Typography>
        }
        open={openAssigneeWarningModal}
        onClose={() => setAssigneeWarningModal(false)}
        buttonArr={CommonButtonArray(
          () => {
            updateTask();
          },
          () => {
            setAssigneeWarningModal(false);
          },
          "Save",
          "Cancel"
        )}
      />
    </Box>
  );
};
export default DeviceTask;
