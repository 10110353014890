import { Box, Typography } from "@mui/material";
import Loader from "Components/Loader";
import NoData from "Components/NoData";
import { CommonStyle } from "Components";
import { CMTabSelectedEnrollmentType } from "DataTypes/Modal";
import { PatientInitialStateType } from "DataTypes/Redux";
import CarePlanApi from "Service/CarePlan.api";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CstmIconBtn from "Components/CstmIconBtn";
import ImgPath from "Constant/Imgs";

const UnenrollPdf: FC<CMTabSelectedEnrollmentType> = ({ prgmDetails }) => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { enrollmentId } = prgmDetails;
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleGetPdf(true);
    return () => {
      pdfUrl && URL.revokeObjectURL(pdfUrl);
    };
  }, [enrollmentId]);

  const handleGetPdf: (isViewOnly: boolean) => void = async (isViewOnly) => {
    isViewOnly && setLoading(true);
    const subUrl = `Unenrolled?isViewRequest=${isViewOnly}`;
    const res = await CarePlanApi.ConsentPDFDownload({
      id1: enrollmentId,
      subUrl,
    });
    const binaryResponse = res.data;
    const blob = new Blob([binaryResponse], {
      type: "application/pdf",
    });
    const newUrl = `${window.URL.createObjectURL(blob)}#toolbar=0&navpanes=0`;
    if (isViewOnly) {
      setPdfUrl(newUrl);
      isViewOnly && setLoading(false);
    } else {
      const link = document.createElement("a");
      link.href = newUrl;
      link.download = `${patientInfo.name}_Cancellation_Form.pdf`;
      link.click();
      URL.revokeObjectURL(newUrl);
    }
  };

  if (loading) return <Loader />;

  if (!pdfUrl) return <NoData />;

  return (
    <Box height="100%">
      <Box
        height="10%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {patientInfo.name}
        </Typography>
        <CstmIconBtn
          icon={<ImgPath.DownloadIcon />}
          onClick={() => handleGetPdf(false)}
          title="Download"
        />
      </Box>
      <Box height="90%">
        <object width={"100%"} height={"100%"} data={pdfUrl} />
      </Box>
    </Box>
  );
};

export default UnenrollPdf;
