import { Box, Checkbox, Typography } from "@mui/material";
import { DropdownArrPropsType, MultiDropBoxProps } from "DataTypes/Form";
import { FC, Fragment } from "react";

const MultiSelectDrop: FC<MultiDropBoxProps> = (props: MultiDropBoxProps) => {
  const { dropArr, disableArr, clickedItem, dropVal, loading } = props;
  return (
    <Box sx={{ ...sxDrop }}>
      {loading ? (
        <Box sx={{ ...sxBox }}>
          <Typography variant="subtitle1">Loading...</Typography>
        </Box>
      ) : dropArr.length > 0 ? (
        <Fragment>
          {dropArr.map((el: DropdownArrPropsType) => {
            const found = dropVal.find((el1) => el.id == el1);
            const isDisable = (disableArr || []).find(
              (disEl) => disEl == el.value
            );
            return (
              <Box
                key={el.id}
                {...(!isDisable && { onClick: () => clickedItem(el) })}
                sx={{
                  ...(isDisable ? sxDisable : sxNonDisable),
                  ...sxBox,
                }}
              >
                <Checkbox checked={found ? true : false} size="small" />
                <Typography variant="subtitle1">{el.label}</Typography>
              </Box>
            );
          })}
        </Fragment>
      ) : (
        <Box sx={{ ...sxBox }}>
          <Typography variant="subtitle1">No Data Found</Typography>
        </Box>
      )}
    </Box>
  );
};
export default MultiSelectDrop;

const sxBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  p: 1,
  "& >span": {
    p: 0,
    mr: 1,
    "&.Mui-checked": {
      color: "checkbox.main",
    },
  },
};

const sxDisable = {
  opacity: 0.5,
  cursor: "default",
};

const sxNonDisable = {
  cursor: "pointer",
  ": hover": {
    backgroundColor: "custom.light",
  },
};

const sxDrop = {
  width: "100%",
  position: "absolute",
  zIndex: "10",
  maxHeight: "300px",
  overflow: "auto",
  backgroundColor: "common.white",
  boxShadow: "0px 3px 6px #00000029",
  borderBottomLeftRadius: "5px",
  borderBottomRightRadius: "5px",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  top: "100%",
  py: 1,
};
