import { useState, useEffect, FC } from "react";
import { Box } from "@mui/material";
import PrgmBox from "./ProgramBox";
import InsuranceBox from "./InsuranceBox";
import { useSelector } from "react-redux";
import InsuranceApi from "Service/Insurance.api";
import { useDispatch } from "react-redux";
import { setAlertData, startLoading, stopLoading } from "Redux/Screen/Action";
import {
  InsuranceListResType,
  PrgmListResType,
} from "DataTypes/Services/Prgm.type";
import PrgmFormatter from "Utils/Prgm.Formatter";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const PrgmInsuranceMapper: FC = () => {
  const dispatch = useDispatch();
  const programs: PrgmListResType[] = useSelector(
    (state: any) => state.list.programList
  );
  const [programList, setProgramList] = useState<PrgmListResType[]>([]);
  const [selectedPrg, setSelectedPrg] = useState<PrgmListResType | null>(null);
  const [insuranceList, setInsuranceList] = useState<InsuranceListResType[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  const getInsuranceList = async () => {
    const res: any = await InsuranceApi.list();
    setLoading(false);
    if (res.success) {
      const formattedData = PrgmFormatter.InsuranceFormatRes(res?.data);
      setInsuranceList(formattedData);
    }
  };

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "prgm-ins-map",
      label: "Program Insurance Mapping",
      path: `/${RouteUrls.hs}/${RouteUrls.master}/${RouteUrls.insPrgmMap}`,
      level: 1,
      link: RouteUrls.insPrgmMap,
      navType: "master",
    });
    getInsuranceList();
    programs?.length > 0 && setSelectedPrg(programs[0]);
    setProgramList(programs);
  }, []);

  const handleSave: (ids: any) => Promise<boolean> = async (ids) => {
    dispatch(startLoading({ loading: true, loadtext: "Loading..." }));
    const insurances = ids.map((item: any) => {
      return {
        planId: item.planId,
        companyId: item.companyId,
      };
    });
    if (selectedPrg) {
      const payload = {
        programId: selectedPrg?.id,
        insurance: insurances,
      };
      const res: any = await InsuranceApi.saveUpdate({ data: payload });
      if (res.success) {
        dispatch(setAlertData(res?.alert));
        dispatch(stopLoading());
        return true;
      } else return false;
    } else return false;
  };

  return (
    <Box display="flex" flexDirection="row" height="100%">
      <Box width="30%" height="100%" sx={{ mr: 1.5 }}>
        <PrgmBox
          programList={programList}
          selectedPrg={selectedPrg}
          handleChangePrg={(el) => setSelectedPrg(el)}
          loading={loading}
        />
      </Box>
      <Box width="70%" height="100%">
        {selectedPrg && selectedPrg?.id && (
          <InsuranceBox
            selectedPrg={selectedPrg}
            InsuranceList={insuranceList}
            handleSave={handleSave}
          />
        )}
      </Box>
    </Box>
  );
};

export default PrgmInsuranceMapper;
