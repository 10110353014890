import { Box, Typography } from "@mui/material";
import { CPSummaryV3Type } from "DataTypes/Services/Careplan.type";
import { FC, Fragment } from "react";
import RenderAns from "./RenderAns";

const SummaryView: FC<{ summary: CPSummaryV3Type[] }> = ({ summary }) => {
  return (
    <Fragment>
      {summary.map((el) => {
        return (
          <Box
            key={el.id}
            border="1px solid"
            borderColor="custom.main"
            borderRadius={1.25}
            mb={2}
          >
            <Box sx={{ backgroundColor: "secondary.main" }} py={1}>
              {el.icdCodeList.map((icdEl, index) => {
                return (
                  <Typography
                    key={icdEl.id}
                    variant="subtitle1"
                    ml={1}
                    {...(index != el.icdCodeList.length - 1 && { mb: 1 })}
                    color="white"
                  >
                    {icdEl.icdCodeDescription
                      ? `${icdEl.icdCodeDescription} `
                      : ""}
                    {icdEl.icdCodeName ? `(${icdEl.icdCodeName})` : ""}
                  </Typography>
                );
              })}
            </Box>
            {el.summary.goals.length > 0 && (
              <Box sx={{ backgroundColor: "bg.main" }}>
                <Typography variant="body2" p={1}>
                  Goals:
                </Typography>
                <Box sx={{ backgroundColor: "white" }} p={1}>
                  {el.summary.goals.map((quesEl) => (
                    <RenderAns key={quesEl.id} data={quesEl} />
                  ))}
                </Box>
              </Box>
            )}
            {el.summary.interventions.length > 0 && (
              <Box sx={{ backgroundColor: "bg.main" }}>
                <Typography variant="body2" p={1}>
                  Interventions:
                </Typography>
                <Box sx={{ backgroundColor: "white" }} p={1}>
                  {el.summary.interventions.map((quesEl) => (
                    <RenderAns key={quesEl.id} data={quesEl} />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        );
      })}
    </Fragment>
  );
};

export default SummaryView;
