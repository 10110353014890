import { useState, FC } from "react";
import { Box, Typography } from "@mui/material";
import { navBarArr } from "./Data";
import { CommonStyle, SidebarModal } from "Components";
import ColorData1 from "CstmTheme/ColorData";
import { SidebarModalLayout } from "Layouts";
import { SidebarNavProps } from "DataTypes/Common";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const SwitchModal: (
  modalId: string,
  onClose: () => void,
  width: string
) => JSX.Element = (modalId: string, onClose: () => void, width) => {
  switch (modalId) {
    case "profile":
      return <SidebarModal.Profile onClose={onClose} width={width} />;
    case "find":
      return <SidebarModal.Find onClose={onClose} width={width} />;
    case "cm":
      return <SidebarModal.CareManagement onClose={onClose} width={width} />;
    case "probs":
      return <SidebarModal.Problems onClose={onClose} width={width} />;
    case "t&v":
      return <SidebarModal.TaskVisit onClose={onClose} width={width} />;
    case "meds":
      return <SidebarModal.Meds onClose={onClose} width={width} />;
    case "allergies":
      return <SidebarModal.Allergy onClose={onClose} width={width} />;
    case "vaccines":
      return <SidebarModal.Vaccine onClose={onClose} width={width} />;
    case "vitals":
      return <SidebarModal.Vital onClose={onClose} width={width} />;
    case "results":
      return <SidebarModal.Result onClose={onClose} width={width} />;
    case "quality":
      return <SidebarModal.Quality onClose={onClose} width={width} />;
    default:
      return <SidebarModal.Profile onClose={onClose} width={width} />;
  }
};

const SidebarNav: FC = () => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const [view, setView] = useState<SidebarNavProps | null>(null);
  const [openmodal, setopenmodal] = useState(false);

  const handleClick: (data: SidebarNavProps) => void = (
    data: SidebarNavProps
  ) => {
    setView(data);
    if (openmodal === false) setopenmodal(true);
  };

  return (
    <Box width="9%" height="100%" sx={{ zIndex: openmodal ? 9999 : 1 }}>
      <Box
        height="100%"
        sx={{ ...CommonStyle.sxWhiteBox, mr: 2 }}
        overflow="auto"
      >
        <Box
          sx={{
            px: 2,
            position: "relative",
          }}
        >
          <SidebarModalLayout
            modwidth={view?.width || "25%"}
            heading={view?.heading || "Profile"}
            content={SwitchModal(
              view?.id || "",
              () => setopenmodal(false),
              view?.width || "25%"
            )}
            open={openmodal}
            onClose={() => setopenmodal(false)}
            twoLayers={view?.twoLayers || false}
          />
          {navBarArr(patientInfo).map((data: SidebarNavProps) => {
            const dis = data.disRoleIdArr.some((el) => el == myInfo.roleId);
            return (
              <Box
                key={data.id}
                sx={{
                  ...sxMain,
                  ...(!dis && sxEnabled),
                  ...(!dis && (data.iconType == "mui" ? sxMUI : sxFlatI)),
                  ...(dis && sxDisabled),
                }}
                {...(!dis && { onClick: () => handleClick(data) })}
              >
                {data.icon}
                <Typography variant="subtitle1">{data.label}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default SidebarNav;

const sxMain = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  pb: 0.6,
  mb: 2.5,
  borderBottom: "0.5px solid",
  borderBottomColor: "custom.main",
  mt: 2,
  "& >h6": {
    display: "flex",
    textAlign: "center",
  },
};

const sxMUI = {
  ":hover": {
    "& >svg": {
      color: "textHover.main",
    },
    "& >p": {
      color: "textHover.main",
    },
  },
};

const sxFlatI = {
  ":hover": {
    "& path": {
      stroke: ColorData1.textHover.main,
      fill: ColorData1.textHover.main,
    },
    "& >p": {
      color: "textHover.main",
    },
  },
};

const sxEnabled = {
  cursor: "pointer",
};

const sxDisabled = {
  opacity: "0.5",
}
