import { FC, Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TableLayout } from "Layouts";
import { ControlledTable, Filter2 } from "Components";
import { actionArr, FilterArr, TableCol } from "./Data";
import { RouteUrls } from "Constant/RouteUrls";
import { FetchListProps, Filter2DataProps } from "DataTypes/Common";
import SettingsApi from "Service/Settings.api";
import SettingsFormatter from "Utils/Settings.Formatter";
import { RPMAlertType } from "DataTypes/Services/Settings.type";
import { CreateFilter2Payload } from "Utils/common";
import UI from "Constant/UI";
import CommonFormatter from "Utils/Common.Formatter";
import { useDispatch } from "react-redux";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import { UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { updateSelectedAlert } from "Redux/User/Action";

const AlertListUI: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { alert }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const pathname = location.pathname.split("/").pop() || "";
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState(UI.pageData);
  const [rows, setRows] = useState<RPMAlertType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>([]);
  const [initial, setInitial] = useState(false);
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    if (filterData && initial) {
      handleSetData({ page: 1, limit: pageData.limit });
    }
  }, [filterData]);

  useEffect(() => {
    initialLoad();
    return () => {
      setInitial(false);
      dispatch(updateSelectedAlert(null));
    };
  }, [pathname]);

  const initialLoad: () => void = async () => {
    setFilterData(JSON.parse(JSON.stringify(FilterArr(pathname))));
    setSearchText("");
    setInitial(true);
    handleSetData({ page: 1, limit: 10 });
  };

  const handleSetData: (props: FetchListProps) => void = async (props) => {
    const { page: currentPage, limit: currentLimit } = props;
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      alertStatus:
        pathname == RouteUrls.addressed ? "ADDRESSED" : "UNADDRESSED",
      deliveryMethod: "PUSH",
      ...(pathname != RouteUrls.addressed && {
        isAlertRead: pathname == RouteUrls.read,
      }),
      ...(newFilterPayload || {}),
    };
    setLoading(true);
    const res = await SettingsApi.RPMAlertSearch({
      data: payload,
      limit: currentLimit,
      offset: (currentPage - 1) * currentLimit,
    });
    if (res?.success) {
      let list = SettingsFormatter.RPMAlertList(res?.data);
      if (alert?.selected) {
        const find = list.find((el) => el.alertId == alert.selected);
        if (find) {
          list = list.map((el) => {
            return {
              ...el,
              highlight: el.alertId == alert.selected,
            };
          });
        }
      }
      setRows(list);
      setPageData(res?.criterion);
    } else {
      setRows([]);
      setPageData(UI.pageData);
    }
    setLoading(false);
  };

  const handleSummary: (data: RPMAlertType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleView: (data: RPMAlertType) => void = (data) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        abbrevation: "RPM",
        careplanId: data.careplanId,
        careplanRecurringId: data.activeRecurringId,
        careplanRecurringStatus: data.activeRecurringId ? "not-completed" : "",
      })
    );
    navigate(
      `../../${RouteUrls.patient}/${
        data.patientId
      }/${CommonFormatter.HandleParentRoute({
        prgm: "RPM",
        isRec: data.activeRecurringId ? true : false,
        careId: data.careplanId,
        recId: data.activeRecurringId,
      })}/${
        data.activeRecurringId
          ? RouteUrls.rpmrecurring
          : RouteUrls.deviceReading
      }?deviceTypeId=${data.deviceTypeId}`
    );
  };

  const handleTimeLog: (data: RPMAlertType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.addonTime}`
    );
  };

  const handleAddressed: (data: RPMAlertType) => Promise<void> = async (
    data
  ) => {
    const payload = {
      id1: data.alertId,
    };
    const res = await SettingsApi.RPMMarkAsAddressedAlert(payload);
    if (res?.success) {
      handleSetData({ page: 1, limit: pageData.limit });
    }
  };

  const handleRead: (data: RPMAlertType) => Promise<void> = async (data) => {
    const payload = {
      id1: data.alertReceiverId,
    };
    const res = await SettingsApi.RPMMarkAsReadAlert(payload);
    if (res?.success) {
      handleSetData({ page: 1, limit: pageData.limit });
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol(pathname)}
      tableRow={rows}
      tableAction={(rowData) =>
        actionArr(
          () => handleSummary(rowData),
          () => handleView(rowData),
          () => handleTimeLog(rowData),
          () => handleRead(rowData),
          () => handleAddressed(rowData),
          pathname,
          rowData
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Fragment>
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        loading={loading}
        tblHead={ControlledTable.TblHead(TableCol(pathname))}
        height={tableHeight}
        Pagination={
          <ControlledTable.Pagination
            mainPage={pageData.page}
            limit={pageData.limit}
            total={pageData.total}
            handleClick={(currentPage, currentLimit) =>
              handleSetData({ page: currentPage, limit: currentLimit })
            }
            tableStyle={true}
          />
        }
      />
    </Fragment>
  );
};

export default AlertListUI;
