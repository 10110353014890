import { Box, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import { useParams } from "react-router-dom";
import { PatientInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import { FC, useState } from "react";
import CstmBtn from "Components/CstmBtn";
import { ThresholdsModalProps } from "DataTypes/PatientManagement.type";
import PatientThreshold from "Components/PatientThreshold";

const ThresholdsModal: FC<ThresholdsModalProps> = ({ setClose }) => {
  const { patientId } = useParams();
  const { selectedPrgm, rpmPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const userId = myInfo.id;
  const [counterSave, setCounterSave] = useState(0);
  const teamMember =
    selectedPrgm.billableproviderId == userId ||
    selectedPrgm.caremanagerId == userId ||
    selectedPrgm.providerId == userId ||
    selectedPrgm.primaryccId == userId ||
    selectedPrgm.secondaryccId == userId;

  return (
    <Box height="96%">
      {/* <Box>
        <Box sx={{ ...CommonStyle.sxHeading, ...sxHeading }} width="100%">
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Patient Name
            </Typography>
            <Typography variant="subtitle1">{patientInfo?.name}</Typography>
          </Box>
          <Box sx={{ ...sxSperator }}></Box>
          <Box>
            <Typography variant="body2" fontWeight="bold">
              {patientOrg.practiceName}
            </Typography>
            <Typography variant="subtitle1">
              Woodlands Family Medicine
            </Typography>
          </Box>
          <Box sx={{ ...sxSperator }}></Box>
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Billable Provider Name
            </Typography>
            <Typography variant="subtitle1">Louis McDonald</Typography>
          </Box>
        </Box>
      </Box> */}
      <Box
        height="96%"
        // overflow="auto"
      >
        <PatientThreshold
          showSaveButton={false}
          isSavedCounter={counterSave}
          patientId={patientId}
        />
      </Box>

      <Box
        sx={{
          "&>button": { ml: 1, width: "10%" },
          display: "flex",
          justifyContent: "flex-end",
          mt: 1,
        }}
      >
        {teamMember && (
          <CstmBtn
            label="Save"
            onClick={() => setCounterSave(counterSave + 1)}
          />
        )}
        <CstmBtn label="Close" onClick={setClose} btnType="border" />
      </Box>
    </Box>
  );
};

export default ThresholdsModal;

const sxHeading = {
  display: "flex",
  flexDirection: "row",
  mb: 2,
  "& >div": {
    "& >p:first-of-type": {
      fontSize: "0.9rem",
    },
  },
};
const sxSperator = {
  mr: 4,
  ml: 4,
  backgroundColor: "#c3c3c3",
  width: "0.1%",
};
