import { ControlledForm, ControlledTable } from "Components";
import { columnType } from "DataTypes/Table";
import { FormDataType } from "DataTypes/Form";
import DateTime from "Utils/DateTime";

export const TableCol1: (
  handleReadMore?: (id: number | null) => void,
  readMoreLength?: number,
  checkReadMore?: (id: number | null) => boolean
) => columnType[] = (handleReadMore, readMoreLength, checkReadMore) => {
  const columns: columnType[] = [
    ControlledTable.ColumnData.DateTimeCol("20%"),
    {
      id: "interactiveAndPartyName",
      label: "Interactive communication done? / With whom?",
      width: "25%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    {
      id: "completedBy",
      label: "Completed By",
      width: "25%",
      align: "left",
      colType: "text",
      rowType: "text",
    },
    {
      ...ControlledTable.ColumnData.TrainingNotesCol(
        "30%",
        handleReadMore,
        readMoreLength,
        checkReadMore
      ),
      label: "Notes",
    },
  ];
  return columns;
};

export const FormDataArr: () => FormDataType[] = () => {
  return [
    {
      id: "interactive",
      label: "Was interactive communication done with the Patient?",
      placeholder: "Select an option",
      boxType: "single-select-drop",
      boxSize: 3,
      validatetype: "dropdown",
      dropArr: [
        { id: "Yes", label: "Yes", value: "Yes" },
        { id: "No", label: "No", value: "No" },
      ],
      required: true,
    },
    {
      id: "whom-interactive",
      label: "With whom did you have interactive communication?",
      placeholder: "Enter Name",
      boxType: "empty-block",
      boxSize: 3,
      validatetype: "interact-com-name",
      required: false,
    },
    {
      id: "medically",
      label:
        "Are RPM Services medically reasonable & necessary for this patient?",
      placeholder: "Select an option",
      boxType: "single-select-drop",
      boxSize: 3,
      validatetype: "dropdown",
      dropArr: [
        { id: "Yes", label: "Yes", value: "Yes" },
        { id: "No", label: "No", value: "No" },
      ],
      required: true,
    },
    {
      ...ControlledForm.FormData.Notes(),
      required: true,
    },
  ];
};

export const DateArr: FormDataType[] = [
  {
    id: "start-date-time",
    boxType: "mui-date",
    boxSize: 6,
    validatetype: "date",
    required: true,
    dropValue: DateTime.CurrentMonthStartDate(),
    value: DateTime.CurrentMonthStartDate(),
  },
  {
    id: "end-date-time",
    boxType: "mui-date",
    boxSize: 6,
    validatetype: "date",
    required: true,
    dropValue: DateTime.CurrentDate(),
    value: DateTime.CurrentDate(),
  },
];
