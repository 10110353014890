export interface RedoxSettingListType {
  id: number | null;
  version: number | null;
  praticeLocationId: number | null;
  type: string;
  data_model: string;
  sourceId: string;
  sourceName: string;
  destinationId: string;
  destinationName: string;
  webhookToken: string;
  webhookTokenExpireAt: string;
  apiKey: string;
  apiSecret: string;
  apiKID: string;
  apiExpiry: string;
  patientUniqueIdtype: string;
  createdBy: number | null;
  updated_by: number | null;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  status: string;
  deletedAt: string;
  deletedBy: number | null;
  authType: string;
  urlEndPoint: string;
}

export interface SmartmeterSettingListType {
  id: number | null;
  practiceId: number | null;
  practiceLocationId: number | null;
  baseUrl: string;
  secretKey: string;
  secretToken: string;
  tokenExpiryInMinutes: number | null;
}

export interface AppSettingListType {
  id: number | null;
  groupLabel: string;
  groupSlug: string;
  keyLable: string;
  keySlug: string;
  value: string;
  label: string;
  typeId: "number" | "string";
}

export interface MasterAppSetType {
  id: string;
  groupLabel: string;
  groupSlug: string;
  keys: MasterKeySetType[];
}

export interface MasterKeySetType {
  id: number | null;
  keyLabel: string;
  keySlug: string;
  value: string;
  typeId: "number" | "string";
}

///Checked

export const DeviceTypeIdList = [
  "BP_MONITOR",
  "PULSE_OXIMETER",
  "GLUCOMETER",
  "WEIGHT_SCALE",
] as const;
export type DeviceTypeIdType = (typeof DeviceTypeIdList)[number];

export const UOMIdList = [
  "SYSTOLIC",
  "DIASTOLIC",
  "BP_PULSE",
  "SPO2",
  "PULSE",
  "GLUCOSE_BEFORE_MEAL",
  "GLUCOSE_AFTER_MEAL",
] as const;
export type UOMIdType = (typeof UOMIdList)[number];

export const AlertIdList = [
  "CRITICAL_LOW",
  "ABNORMAL_LOW",
  "NORMAL",
  "ABNORMAL_HIGH",
  "CRITICAL_HIGH",
] as const;
export type AlertIdType = (typeof AlertIdList)[number];

export const ThresholdIdList = [
  "DEFAULT_THRESHOLD",
  "PRACTICE_THRESHOLD",
  "PATIENT_THRESHOLD",
] as const;
export type ThresholdIdType = (typeof ThresholdIdList)[number];

export const RangeIdList = [
  "upperLimit",
  "lowerLimit",
  "daysLimit",
  "weightValue",
] as const;
export type RangeIdType = (typeof RangeIdList)[number];

export const AlertSetTypeIdList = [
  "smsEnabled",
  "emailEnabled",
  "pushEnabled",
] as const;
export type AlertSetTpyeIdType = (typeof AlertSetTypeIdList)[number];
export const AlertStatusIdList = ["ADDRESSED", "UNADDRESSED"] as const;
export type AlertStatusIdType = (typeof AlertStatusIdList)[number];

export interface UOMType {
  uomId: string;
  uomLabel: string;
}

export interface probUOMType extends UOMType {
  problemId: number | null;
  problemName: string;
}

export interface WeightDataType {
  id: string;
  label: string | number | null;
  color: string | null;
  width: string;
  type: string;
}

export interface DeviceTypeType {
  deviceTypeId: DeviceTypeIdType | null;
  deviceTypeDescription: string;
}

export interface ProblemType {
  problemId: number | null;
  problemName: string;
  weightFluctuation: string;
  operatorDescription: string;
}

export type AlertType = {
  [key in RangeIdType]: string;
} & {
  alertTypeId: number | null;
  alertType: AlertIdType | null;
};

export interface ThresholdType
  extends AlertType,
    DeviceTypeType,
    UOMType,
    ProblemType {
  thresholdType: ThresholdIdType | null;
  practiceId: number | null;
  operatorId: string;
}

export interface ThresholdHeadType {
  id: AlertIdType;
  label: string;
  color: string;
  range: ThresholdRangeType[];
}

export interface ThresholdRangeType {
  id: RangeIdType;
  label: string;
  width: string;
}

export interface AlertSetHeadType {
  id: AlertIdType;
  label: string;
  color: string;
}

export interface AlertSetSubHeadType {
  id: AlertSetTpyeIdType;
  label: string;
}

export interface AlertSetListType {
  alertSettings: AlertSetType[];
  bussinessModel: string;
  hasUserAccessToReceiveSMS: boolean;
  roleName: string;
  userFullNAme: string;
  userId: number | null;
}

export type AlertSetType = {
  [key in AlertSetTpyeIdType]: boolean;
} & {
  alertType: AlertIdType | null;
  id: number | null;
};

export interface RPMAlertType {
  activeRecurringId: number | null;
  addressedByUserId: number | null;
  addressedAt: string;
  addressedBy: string;
  alertId: number | null;
  alertMessage: string;
  alertRead: boolean;
  alertReceiverId: number | null;
  alertStatus: AlertStatusIdType | null;
  alertTime: string;
  alertType: AlertIdType | null;
  careplanId: number | null;
  concent: string;
  detailedMessage: string;
  deviceId: number | null;
  deviceName: string;
  deviceTypeId: string;
  highlight: boolean;
  id: number | null;
  name: string;
  patientId: string | null;
  practiceName: string;
}
