import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CommonStyle, ControlledTable, Filter2 } from "Components";
import { FetchListProps, Filter2DataProps } from "DataTypes/Common";
import Notification from "Service/Notification";
import { ListInitialStateType, UserInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import NotificationFormatter from "Utils/Notification.Formatter";
import { NotificationType } from "DataTypes/Notification";
import { TableLayout } from "Layouts";
import { FilterArr, TableCol, actionArr } from "./Data";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { addNotificationList } from "Redux/List/Action";
import { CreateFilter2Payload } from "Utils/common";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const Notifications: FC = () => {
  const dispatch = useDispatch();
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { notificationList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const [tblRows, setTblRows] = useState<NotificationType[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    CommonFormatter.HandleNavArr({
      id: "notification",
      label: "Notifications",
      path: `/${RouteUrls.hs}/${RouteUrls.notifications}`,
      level: 1,
      link: RouteUrls.notifications,
      navType: "my-items",
    });
  }, []);

  useEffect(() => {
    handleSetData({ page: 1, limit });
  }, [filterData]);

  const handleSetData: (props: FetchListProps) => void = async (props) => {
    const { page: currentPage, limit: currentLimit } = props;
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      receiverUserId: myInfo.id,
      ...newFilterPayload,
    };
    const res = await Notification.search({
      data: payload,
      limit: currentLimit,
      offset: (currentPage - 1) * currentLimit,
    });
    if (res?.success) {
      setTblRows(NotificationFormatter.Arr(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res?.criterion?.total);
      setLimit(res?.criterion?.limit);
    } else {
      setTblRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const handleMarkread: (data: NotificationType) => void = async (data) => {
    const res = await Notification.markAsRead({ id1: data.id });
    if (res?.success) {
      const newArr: NotificationType[] = JSON.parse(
        JSON.stringify(notificationList)
      );
      dispatch(addNotificationList(newArr.filter((el) => el.id != data.id)));
      dispatch(setAlertData(res?.alert));
      handleSetData({ page, limit });
    }
  };

  const handleDelete: (data: NotificationType) => void = async (data) => {
    const res = await Notification.markDelete({ id1: data.id });
    if (res?.success) {
      const newArr: NotificationType[] = JSON.parse(
        JSON.stringify(notificationList)
      );
      dispatch(addNotificationList(newArr.filter((el) => el.id != data.id)));
      dispatch(setAlertData(res?.alert));
      handleSetData({ page, limit });
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={tblRows}
      searchText={searchText}
      tableAction={(data: NotificationType) =>
        actionArr(
          () => handleMarkread(data),
          () => handleDelete(data),
          data
        )
      }
    />
  );

  return (
    <Box
      height="91%"
      sx={{ ...CommonStyle.sxWhiteBox, px: 2, overflow: "auto" }}
    >
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol, true)}
        height={tableHeight}
        loading={loading}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={(currentPage, currentLimit) =>
              handleSetData({ page: currentPage, limit: currentLimit })
            }
            tableStyle={true}
          />
        }
      />
    </Box>
  );
};

export default Notifications;
