import { ControlledForm } from "Components";
import { ClickIconArrDataType, FormDataType } from "DataTypes/Form";

export const DataArr: FormDataType[] = [
  ControlledForm.FormData.LoginData(),
  ControlledForm.FormData.PasswordData(),
];

export const DataArr1: FormDataType[] = [
  ControlledForm.FormData.OtpData()
];

export const IconClickArr = (handlePassClick: (id: string) => void) => {
  const arr: ClickIconArrDataType[] = [
    {
      iconId: "password-icon",
      onClick: (id: string) => handlePassClick(id),
    },
  ];
  return arr;
};
