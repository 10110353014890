export const RouteUrls = {
  createTask: "create-task",
  patientDetails: "/patient-details",
  chart: "/chart",
  callLogs: "call-logs",
  startCarePlan: "/ques-catagory",
  enterProblem: "/enter-problem",
  heartratechart: "/heart-rate-chart",
  glucosechart: "/glucose-chart",
  bpchart: "/blood-pressure-chart",
  dischargeSummary: "dischargeSummary",
  myOrganization: "myOrganization",
  View: "view",
  login: "login",
  forgot: "forgot",
  passwordRecovery: "password-recovery",
  signUp: "sign-up",
  hs: "page",
  allPatients: "all-patient",
  patientUpload: "patientimport",
  changePass: "changepassword",
  aboutUs: "aboutus",
  glist: "g-list",
  eligible: "eligible",
  pendingAssign: "pendingassignment",
  enrolled: "enrolled",
  myPatients: "mypatient",
  pendingApproval: "pendingapproval",
  pendingCare: "pendingcare",
  completedCare: "completedcare",
  inactive: "inactive",
  ongoing: "ongoing",
  billing: "billing",
  consent: "consent",
  master: "master",
  category: "category",
  insPrgmMap: "programinsurance",
  prgmProbMap: "programproblem",
  deviceProbMap: "deviceproblem",
  prob: "problem",
  list: "list",
  icdCodes: "icdcode",
  prgm: "program",
  device: "device",
  deviceDetails: "device-details",
  questionnaire: "question",
  add: "add",
  update: "update",
  myProfile: "myprofile",
  organization: "organization",
  orgList: "org-list",
  staff: "staff",
  patient: "patient",
  details: "detail",
  clinicalSummary: "clinicalsummary",
  patientAudit: "patientAudit",
  callLog: "calllog",
  program: "program",
  carePlan: "careplan",
  tcm: "tcm",
  treatmentPlan: "treatmentplan",
  recurring: "recurring",
  rpmrecurring: "rpm-services",
  carePlanSummary: "careplansummary",
  tcmSummary: "tcmsummary",
  recurringSummary: "recurringsummary",
  pastCarePlan: "pastcareplan",
  pendingCareplan: "pendingcareplan",
  careProblem: "problem",
  rpmAddDevice: "add-device",
  rpmDeviceList: "device-listing",
  careTeam: "assignteam",
  editTeam: "editTeam",
  careQuestion: "question",
  careSummary: "summary",
  setting: "settings",
  task: "task",
  userAudit: "useraudit",
  notifications: "notifications",
  tasking: "tasking",
  incoming: "incoming",
  outgoing: "outgoing",
  global: "global",
  group: "group",
  addonTime: "addontime",
  redoxSettings: "redox",
  smartmeterSettings: "smartmeter",
  appSettings: "application",
  globalThresholdAlerts: "globalalerts",
  alertSettings: "alertsettings",
  deviceAddTask: "device-add",
  deviceViewTask: "device-view",
  deviceUpdateTask: "device-update",
  thresholds: "thresholds",
  training: "training",
  myTraining: "mytraining",
  completedTraining: "completed-training",
  trainingNotes: "training-notes",
  pendingDevice: "pending-device",
  revision: "revision",
  revisionPlan: "revision-plan",
  deviceReading: "device-reading",
  alert: "alert",
  unread: "unread",
  read: "read",
  addressed: "addressed",
  notfound: "not-found",
};
