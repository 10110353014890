import { FC, useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { TableCol, actionArr, FilterArr } from "./Data";
import { TableLayout } from "Layouts";
import { ControlledTable, Filter2 } from "Components";
import { RouteUrls } from "Constant/RouteUrls";
import { useDispatch, useSelector } from "react-redux";
import Formate from "./Formate";
import PatientApi from "Service/Patient.api";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { Filter2DataProps } from "DataTypes/Common";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";
import { UserInitialStateType } from "DataTypes/Redux";
import { CompleteCareType } from "DataTypes/Services/Patient.type";
import { CreateFilter2Payload } from "Utils/common";
import CommonFormatter from "Utils/Common.Formatter";

const CompleteCare: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const [limit, setLimit] = useState<number>(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<CompleteCareType[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [filterData, setFilterData] = useState<Filter2DataProps[]>(
    JSON.parse(JSON.stringify(FilterArr()))
  );
  const tableHeight = `calc(99% - ${filterHeight}px)`;

  useEffect(() => {
    handleSetData(1, limit);
  }, [filterData]);

  const handleSetData: (
    currentPage: number,
    currentLimit: number
  ) => void = async (currentPage, currentLimit) => {
    setLoading(true);
    const newFilterPayload = CreateFilter2Payload(filterData);
    const payload = {
      ...(newFilterPayload || {}),
    };
    const res = await PatientApi.CompletedContinuous({
      offset: (currentPage - 1) * currentLimit,
      limit: currentLimit,
      data: payload,
    });
    if (res?.success) {
      setRows(Formate.CompleteCareList(res?.data));
      setPage(res.criterion.page);
      setTotalRecord(res.criterion.total);
      setLimit(res.criterion.limit);
    } else {
      setRows([]);
      setPage(1);
      setTotalRecord(0);
      setLimit(10);
    }
    setLoading(false);
  };

  const handlePatientDetails: (data: CompleteCareType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.details}`
    );
  };

  const handleChart: (data: CompleteCareType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.clinicalSummary}`
    );
  };

  const handleTask: (data: CompleteCareType) => void = (data) => {
    navigate(
      `../../${RouteUrls.patient}/${data.patientId}/${RouteUrls.task}/${RouteUrls.add}`
    );
  };

  const handleViewCare: (data: CompleteCareType) => void = (data) => {
    dispatch(
      addSelectedPrgm({
        ...selectedPrgmInitialState,
        readOnlyArr: [
          "uptoDate",
          "problem",
          "addDevice",
          "editDevice",
          "question",
          "team",
          "addNotes",
          "frequency",
          "interactive",
          "medically",
        ],
      })
    );
    navigate(
      `/${RouteUrls.hs}/${RouteUrls.patient}/${
        data.patientId
      }/${CommonFormatter.HandleScreenRoute({
        careState: data.careplanState,
        careId: data.careplanId,
        prgm: data.program,
      })}`
    );
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol(myInfo)}
      tableRow={rows}
      tableAction={(data: CompleteCareType) =>
        actionArr(
          () => handleChart(data),
          () => handlePatientDetails(data),
          () => handleTask(data),
          () => handleViewCare(data),
          data.program
        )
      }
      searchText={searchText}
    />
  );

  return (
    <Fragment>
      <Filter2
        filterDataArr={filterData}
        handleFilterClick={(data) => setFilterData(data)}
        changeSearchText={(val) => setSearchText(val)}
        setFilterHeight={(val) => setFilterHeight(val)}
      />
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(TableCol(myInfo))}
        height={tableHeight}
        loading={loading}
        Pagination={
          <ControlledTable.Pagination
            mainPage={page}
            limit={limit}
            total={totalRecord}
            handleClick={handleSetData}
            tableStyle={true}
          />
        }
      />
    </Fragment>
  );
};
export default CompleteCare;
