import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { CommonStyle, ControlledAccord, Loader } from "Components";
import { DeviceTypeProgrmBoxProps } from "DataTypes/Services/Device.type";

const DeviceProgramBox: FC<DeviceTypeProgrmBoxProps> = (props) => {
  const { deviceList, selectedDevice, handleChangeDevice, loading } = props;

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
      <Typography variant="subtitle1" sx={{ ...CommonStyle.sxRow, ...sxHead }}>
        {"Device Name"}
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ ...sxBody }}>
          {deviceList.map((el) => {
            return (
              <ControlledAccord
                key={el.deviceTypeId}
                handleClick={() =>
                  handleChangeDevice(
                    selectedDevice?.deviceTypeId == el.deviceTypeId ? null : el
                  )
                }
                open={
                  selectedDevice?.deviceTypeId == el.deviceTypeId ? true : false
                }
                head={
                  <Typography
                    variant="subtitle1"
                    sx={{
                      ml: 1,
                      ...(selectedDevice?.deviceTypeId == el.deviceTypeId &&
                        sxSelected),
                    }}
                  >
                    {el.deviceTypeDescription}
                  </Typography>
                }
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};
export default DeviceProgramBox;

const sxHead = {
  ...CommonStyle.sxRow,
  height: "10%",
  width: "100%",
  fontWeight: "bold",
  px: 2,
};

const sxBody = {
  height: "90%",
  overflow: "auto",
  px: 2,
};

const sxSelected = {
  fontWeight: "bold",
};
