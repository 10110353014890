import { FC, Fragment, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  CommonStyle, ControlledForm, ControlledTable, Loader
} from "Components";
import ControlledAccord from "Components/Accordion1/Controlled";
import CstmBtn from "Components/CstmBtn";
import NoData from "Components/NoData";
import { QuestionBoxProps } from "DataTypes/Master";
import { MasterQuestionType } from "DataTypes/Services/Question.type";
import ImgPath from "Constant/Imgs";
import TooltipBox from "./TooltipBox";
import { FormatDropdownData } from "Utils/DataFormatter";
import { PageLimitArr } from "Constant/DropArr";
import { CheckStringEmpty } from "Utils/common";
import { HeaderInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";

const QuestionBox1: FC<QuestionBoxProps> = (props) => {
  const {
    questionArr,
    quesLoading,
    selectedCat,
    openArr,
    loadingQuesId,
    handleAdd,
    handleUpdate,
    handleDelete,
    callChildQues,
    totalRecord,
    handleChange,
    handleSetQuesSearch,
    searchVal
  } = props;
  const { master }: HeaderInitialStateType = useSelector(
    (state: any) => state?.header
  );
  const currentPage = master.page;
  const currentLimit = master.limit;
  const [headHeight, setHeadHeight] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const tableHeight = `calc(100% - ${headHeight + pageHeight}px)`;
  const headOffset = document?.getElementById("head")?.offsetHeight;
  const pageOffset = document?.getElementById("page")?.offsetHeight;

  useEffect(() => {
    setHeadHeight(headOffset || 0);
  }, [headOffset]);

  useEffect(() => {
    setPageHeight(pageOffset || 0);
  }, [pageOffset]);

  const MainContent: (quesData: MasterQuestionType) => JSX.Element = (
    quesData
  ) => (
    <Box
      sx={{
        ...CommonStyle.sxRow,
        justifyContent: "space-between",
        wordBreak: "break-all",
      }}
      width="100%"
    >
      <Typography variant="subtitle1">{quesData.question}</Typography>
      <Box sx={{ ...CommonStyle.sxRow }}>
        <Box
          onClick={() => handleUpdate(quesData)}
          sx={{ ...CommonStyle.sxRoundIconsMainSm, mr: 1, p: 0.1 }}
        >
          <ImgPath.PencilIcon titleAccess="Edit" sx={{ width: "0.9rem" }} />
        </Box>
        <Box
          onClick={() => handleDelete(quesData)}
          sx={{ ...CommonStyle.sxRoundIconsMainSm, p: 0.1 }}
        >
          <ImgPath.DeleteOutline
            titleAccess="Delete"
            sx={{ width: "0.9rem" }}
          />
        </Box>
      </Box>
    </Box>
  );

  const SubContent: (quesData: MasterQuestionType) => JSX.Element = (
    quesData
  ) => {
    return (
      <Fragment>
        {quesLoading && loadingQuesId == quesData.id ? (
          <Box sx={{ ...CommonStyle.sxRowCenter }}>
            <CircularProgress />
          </Box>
        ) : (
          <Fragment>
            <Box sx={{ ...CommonStyle.sxRow }}>
              <Box width="4%" />
              <Box
                sx={{ ...CommonStyle.sxRow, justifyContent: "space-between" }}
                width="96%"
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  Options:
                </Typography>
                <Box sx={{ ...CommonStyle.sxRow }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Type:
                  </Typography>
                  <Typography variant="subtitle1" ml={0.4}>
                    {quesData.typeLabel}
                  </Typography>
                </Box>
                <TooltipBox
                  dataArr={FormatDropdownData(
                    quesData.programs,
                    "id",
                    "name",
                    "id"
                  )}
                  noDataText="No Programs Available"
                  label="Programs"
                />
                <TooltipBox
                  dataArr={FormatDropdownData(
                    quesData.problems,
                    "id",
                    "name",
                    "id"
                  )}
                  noDataText="No Problems Available"
                  label="Problems"
                />
                <TooltipBox
                  dataArr={FormatDropdownData(
                    quesData.visittypes,
                    "id",
                    "name",
                    "id"
                  )}
                  noDataText="No Visits Available"
                  label="Visit Types"
                />
              </Box>
            </Box>
            {quesData.options.map((el) => {
              const filterQuestion = questionArr.filter(
                (quesEl) => quesEl.parentOptionId == el.id
              );
              return (
                <Box
                  key={el.id}
                  sx={{
                    ...(filterQuestion.length < 1 && {
                      borderBottom: "1px solid",
                      borderBottomColor: "custom.main",
                    }),
                  }}
                >
                  <Box sx={{ ...CommonStyle.sxRow, py: 1 }}>
                    <Box width="4%">
                      <Box
                        onClick={() => handleAdd(quesData, el.id)}
                        sx={{ ...CommonStyle.sxRoundIconsMainSm, p: 0.1 }}
                      >
                        <ImgPath.PlusIcon
                          titleAccess={
                            filterQuestion.length > 0
                              ? "Add Another Question"
                              : "Add Question"
                          }
                        />
                      </Box>
                    </Box>
                    <Box sx={{ ...CommonStyle.sxRow }} width="96%">
                      <Typography
                        variant="subtitle1"
                        sx={{ wordBreak: "break-all" }}
                      >
                        {el.option}
                      </Typography>
                    </Box>
                  </Box>
                  {filterQuestion.map((quesEl) => {
                    const find = openArr.find((openEl) => openEl == quesEl.id);
                    return (
                      <Box key={quesEl.id} sx={{ ...CommonStyle.sxRow }}>
                        <Box width="4%" />
                        <Box width="96%">
                          <ControlledAccord
                            key={el.id}
                            open={find ? true : false}
                            head={MainContent(quesEl)}
                            body={find ? SubContent(quesEl) : <></>}
                            handleClick={() => callChildQues(quesEl)}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Fragment>
        )}
      </Fragment>
    );
  };

  const NullQuesArr = questionArr.filter(
    (quesEl) => quesEl.parentOptionId == null && quesEl.parentQuestionId == null
  );

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
      <Box
        id="head"
        sx={{ ...CommonStyle.sxRow, px: 2, py: 1, wordBreak: "break-all" }}
        justifyContent={"space-between"}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          {"Question > " + selectedCat?.name}
        </Typography>
        <Box
          display="flex"
          sx={{ "&>button": { ml: 1 } }}
          alignItems={"flex-end"}
        >
          <ControlledForm.Input
            id="quesSearch"
            placeholder="Question Search"
            onChange={(val) => handleSetQuesSearch(CheckStringEmpty(val))}
            iconId="search"
            iconClickHandler={() => handleChange(1, 25)}
            value={searchVal}
            sxProps={{ width: 300 }}
          />
          <CstmBtn label="Add Question" onClick={() => handleAdd()} />
        </Box>
      </Box>
      <Box height={tableHeight} overflow="auto" sx={{ px: 2 }}>
        {quesLoading && !loadingQuesId ? (
          <Loader />
        ) : NullQuesArr.length > 0 ? (
          NullQuesArr.map((el) => {
            const find = openArr.find((openEl) => openEl == el.id);
            return (
              <ControlledAccord
                key={el.id}
                open={find ? true : false}
                head={MainContent(el)}
                body={find ? SubContent(el) : <></>}
                handleClick={() => callChildQues(el)}
              />
            );
          })
        ) : (
          <NoData />
        )}
      </Box>
      <Box id={"page"} py={1}>
        <ControlledTable.Pagination
          handleClick={handleChange}
          limit={currentLimit}
          mainPage={currentPage}
          total={totalRecord}
          tableStyle={false}
          limitArr={PageLimitArr}
        />
      </Box>
    </Box>
  );
};
export default QuestionBox1;
