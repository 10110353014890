import { Box, Typography } from "@mui/material";
import { CommonStyle } from "Components";
import { FC, Fragment, useEffect, useState } from "react";
import CstmBtn from "Components/CstmBtn";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "Constant/RouteUrls";
import { HeadNotifBoxPropType } from "DataTypes/Common";

const NotificationBox: FC<HeadNotifBoxPropType> = ({
  dataArr,
  changeDropState,
  handleClick,
}) => {
  const navigate = useNavigate();
  const [openDrop, setOpenDrop] = useState(false);

  useEffect(() => {
    setOpenDrop(changeDropState);
  }, [changeDropState]);

  return (
    <Fragment>
      {openDrop && (
        <Box
          sx={{
            ...CommonStyle.sxWhiteBox,
            ...sxDropBox,
            ...sxRight,
            p: 1,
          }}
        >
          {dataArr.map((el) => {
            return (
              <Typography
                variant="subtitle1"
                key={el.id}
                onClick={() => handleClick(el)}
              >
                {el.message}
              </Typography>
            );
          })}
          <CstmBtn
            label="See All"
            onClick={() =>
              navigate(`${RouteUrls.hs}/${RouteUrls.notifications}`)
            }
            sxProps={{ width: "100%" }}
          />
        </Box>
      )}
    </Fragment>
  );
};
export default NotificationBox;

const sxDropBox = {
  position: "absolute",
  top: "100%",
  zIndex: 10,
  transition: "0.5s",
  width: "17em",
  maxHeight: 300,
  overflow: "auto",
  "& >h6": {
    cursor: "pointer",
    p: 0.5,
    borderRadius: 1.25,
    borderBottom: "1px solid",
    borderColor: "custom.main",
    ":hover": {
      backgroundColor: "custom.light",
    },
  },
};

const sxRight = {
  right: 0,
  "&:before": {
    content: "''",
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid #ee7404",
    transform: "rotate(0deg)",
    backgroundColor: "transparent",
    top: "-10px",
    right: "2px",
    position: "absolute",
  },
};
