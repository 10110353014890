import { Box, Typography } from "@mui/material";
import { TCMSummaryV2Type } from "DataTypes/Services/Careplan.type";
import { FC, Fragment } from "react";
import RenderAns from "./RenderAns";

const TCMSummaryView: FC<{ summary: TCMSummaryV2Type | null }> = ({
  summary,
}) => {
  if (!summary) return <Fragment />;
  return (
    <Box border="1px solid" borderColor="custom.main" borderRadius={1.25}>
      <Box sx={{ backgroundColor: "secondary.main" }} py={1}>
        <Typography variant="subtitle1" ml={1} mb={1} color="white">
          {summary.problemName}
        </Typography>
      </Box>
      {summary.questionList.length > 0 && (
        <Box sx={{ backgroundColor: "bg.main" }}>
          <Typography variant="body2" p={1}>
            Transitional Care Management:
          </Typography>
          <Box sx={{ backgroundColor: "white" }} p={1}>
            {summary.questionList.map((quesEl) => (
              <RenderAns key={quesEl.id} data={quesEl} pgmName="TCM" />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TCMSummaryView;
