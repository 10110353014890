import { DropdownArrPropsType } from "DataTypes/Form";
import {
  CPApiCatQuesType,
  CPApiOptType,
  CPApiQuesUpdate,
  CPDeviceListType,
  CPDeviceType,
  CPProblemArr,
  CPProblemRead,
  CPProblemType,
  CPRecNoteType,
  CPSearchType,
  CPSummaryQuesOptType,
  CPSummaryQuesType,
  CPSummaryType,
  CPSummaryV3OptType,
  CPSummaryV3QuesType,
  CPSummaryV3Type,
  CPTeamAddProviderType,
  CPTeamSearchType,
  ReminderType,
  TCMSummaryV2Type,
} from "DataTypes/Services/Careplan.type";
import {
  AscendSort,
  CheckArr,
  CheckBoolean,
  CheckCPComprehensive,
  CheckCPStatus,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "./common";
import DateTime from "./DateTime";
import DeviceTypeFormatter from "./DeviceType.Formatter";
import { ICDCodeType } from "DataTypes/Services/Problem.type";
import PatientFormatter from "./Patient.Formatter";
import { EligiblePrgmResType } from "DataTypes/Services/Patient.type";
import { selectedPrgmInitialState } from "Redux/Patient/reducer";

const CreateassignTeamDropData: (
  data: any,
  idKey: string,
  labelKey1: string,
  labelKey2: string,
  valKey: string
) => DropdownArrPropsType[] = (data, idKey, labelKey1, labelKey2, valKey) => {
  return data?.map((el: any) => ({
    id: el[idKey],
    label: el[labelKey1] + " " + el[labelKey2],
    value: el[valKey],
  }));
};

const CPSummaryArr: (data: any) => CPSummaryType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: CPSummaryType[] = CheckedArr.map((el) => CPSummary(el));
  return AscendSort(newArr, "problemName");
};

const CPSummary: (data: any) => CPSummaryType = (data) => {
  return {
    problemId: CheckNumber(data?.problemId),
    problemName: CheckStringEmpty(data?.problemName),
    careplanQuetion: CPSummaryQuesArr(data?.careplanQuetion),
  };
};

const CPSummaryQuesArr: (data: any) => CPSummaryQuesType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr = CheckedArr.map((el) => CPSummaryQues(el));
  return newArr;
};

const CPSummaryQues: (data: any) => CPSummaryQuesType = (data) => {
  return {
    careplanQuestionId: CheckNumber(data?.careplanQuestionId),
    careplanQuestionName: CheckStringEmpty(data?.careplanQuestionName),
    careplanSummaryQuestionOptions: CPSummaryQuesOpt(
      data?.careplanSummaryQuestionOptions
    ),
    category_text: CheckStringEmpty(data?.category_text),
    categorytype: CheckStringEmpty(data?.categorytype),
    freeTextAnswer: CheckStringEmpty(data?.freeTextAnswer),
    questionComment: CheckStringEmpty(data?.questionComment),
  };
};

const CPSummaryQuesOpt: (data: any) => CPSummaryQuesOptType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: CPSummaryQuesOptType[] = CheckedArr.map((el) => {
    return {
      optionId: CheckNumber(el?.optionId),
      optionTest: CheckStringEmpty(el?.optionTest),
    };
  });
  return newArr;
};

const CheckCPSearch: (data: any) => CPSearchType = (data) => {
  return {
    careplan_frequency: CheckStringEmpty(data?.careplan_frequency),
    carePlanAdditionalNotes: CheckStringEmpty(data?.carePlanAdditionalNotes),
    careplanCompletionDate: DateTime.ValidDate(data?.careplanCompletionDate),
  };
};
const CheckRecurringCPSearch: (data: any) => CPSearchType = (data) => {
  return {
    carePlanAdditionalNotes: CheckStringEmpty(data?.additionalNotes),
    careplan_frequency: CheckStringEmpty(data?.careplan_frequency),
    careplanCompletionDate: DateTime.ValidDate(
      data?.recurringCompletedDatetime
    ),
  };
};

const CheckCarePlanQuesArr: (res: any) => CPApiCatQuesType[] = (res) => {
  const CheckedArr = CheckArr(res);
  const newArr: CPApiCatQuesType[] =
    CheckedArr.map((el) => {
      return CheckCarePlanQuesObj(el);
    }) || [];
  return newArr;
};

const CheckCarePlanQuesObj: (resObj: any) => CPApiCatQuesType = (resObj) => {
  return {
    categoryId: CheckNumber(resObj?.category_id),
    category: CheckStringEmpty(resObj?.category),
    careplanQuestionId: CheckNumber(resObj?.careplan_question_id),
    isAnswered: CheckBoolean(resObj?.is_answered),
    parentOptionId: CheckNumber(resObj?.parent_option_id),
    parentQuestionId: CheckNumber(resObj?.parent_question_id),
    question: CheckStringEmpty(resObj?.question),
    questionComment: CheckStringEmpty(resObj?.question_comment),
    questionType: CheckStringEmpty(resObj?.question_type),
    questionsId: CheckNumber(resObj?.questions_id),
    requiredQuestion: CheckBoolean(resObj?.required_question),
    programId: CheckNumber(resObj?.program_id),
    isRequiredOptionAnswered: CheckBoolean(
      resObj?.is_required_options_answered
    ),
    freeTextAnswer: CheckStringEmpty(resObj?.freeTextAnswer),
  };
};

const CheckCarePlanQuesOpt: (res: any) => CPApiOptType[] = (res) => {
  const CheckedArr = CheckArr(res);
  const newArr: CPApiOptType[] =
    CheckedArr.map((el) => {
      return {
        id: CheckNumber(el?.option_id),
        option: CheckStringEmpty(el?.option),
        requiredOption: CheckBoolean(el?.required_option),
        answered: CheckBoolean(el?.answered),
        careplanQuestionOptionId: CheckNumber(el?.careplan_option_id),
      };
    }) || [];
  return newArr;
};

const CheckCarePlanQuesUpdateObj: (resObj: any) => CPApiQuesUpdate = (
  resObj
) => {
  const checkedArr = CheckArr(resObj?.careplanQuestionOption);
  const newArr = checkedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      optionId: CheckNumber(el?.optionId),
    };
  });
  return {
    id: CheckNumber(resObj?.id),
    careplanQuestionOption: newArr,
  };
};

const CheckCPProblemRead: (res: any) => CPProblemRead = (res) => {
  return {
    isCareplanComprehensive: CheckCPComprehensive(res?.isCareplanComprehensive),
    careplanProblem: CheckCPProblemArr(res?.careplanProblem),
  };
};

const CheckCPProblemArr: (res: any) => CPProblemArr[] = (res) => {
  const checkedArr = CheckArr(res);
  return checkedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      icdCode: CheckNumber(el?.icdCode),
      problemId: CheckNumber(el?.problemId),
    };
  });
};

const CheckCPTeam: (res: any) => CPTeamSearchType = (res) => {
  const AddProviderArr = CheckCPTeamAddProvider(
    res?.careplanAdditionalProvider
  );
  const caremanagerName = CheckStringEmpty(res?.caremanagerName);
  const caremanagerDes = CheckStringEmpty(res?.caremanagerDesignation);
  const caremanagerNameDes = caremanagerName
    ? caremanagerName + `${caremanagerDes ? ", " + caremanagerDes : ""}`
    : "";
  const providerName = CheckStringEmpty(res?.teamProviderName);
  const providerDes = CheckStringEmpty(res?.teamProviderDesignation);
  const providerNameDes = providerName
    ? providerName + `${providerDes ? ", " + providerDes : ""}`
    : "";
  const billProviderName = CheckStringEmpty(res?.billableProviderName);
  const billProviderDes = CheckStringEmpty(res?.billableProviderDesignation);
  const billProviderNameDes = billProviderName
    ? billProviderName + `${billProviderDes ? ", " + billProviderDes : ""}`
    : "";
  const primaryCareCoordinatorName = CheckStringEmpty(
    res?.primaryCareCoordinatorName
  );
  const primaryCareCoordinatorDes = CheckStringEmpty(
    res?.primaryCareCoordinatorDesignation
  );
  const primaryCareCoordinatorNameDes = primaryCareCoordinatorName
    ? primaryCareCoordinatorName +
      `${primaryCareCoordinatorDes ? ", " + primaryCareCoordinatorDes : ""}`
    : "";
  const secondaryCareCoordinatorName = CheckStringEmpty(
    res?.secondaryCareCoordinatorName
  );
  const secondaryCareCoordinatorDes = CheckStringEmpty(
    res?.secondaryCareCoordinatorDesignation
  );
  const secondaryCareCoordinatorNameDes = secondaryCareCoordinatorName
    ? secondaryCareCoordinatorName +
      `${secondaryCareCoordinatorDes ? ", " + secondaryCareCoordinatorDes : ""}`
    : "";

  return {
    authorizedRepresentative1: CheckStringEmpty(res?.authorizedRepresentative1),
    authorizedRepresentative2: CheckStringEmpty(res?.authorizedRepresentative2),
    authorizedRepresentative3: CheckStringEmpty(res?.authorizedRepresentative3),
    billableProviderId: CheckNumber(res?.billableProviderId),
    billableProviderName: billProviderNameDes,
    caremanagerId: CheckNumber(res?.caremanagerId),
    caremanagerName: caremanagerNameDes,
    careplanAdditionalProvider: AddProviderArr,
    careplanId: CheckNumber(res?.careplanId),
    practiceId: CheckNumber(res?.practiceId),
    practiceLocationId: CheckNumber(res?.practiceLocationId),
    patientId: CheckStringId(res?.patientId),
    programId: CheckNumber(res?.programId),
    providerId: CheckNumber(res?.teamProviderId),
    providerName: providerNameDes,
    primaryCareCoordinatorId: CheckNumber(res?.primaryCareCoordinatorId),
    primaryCareCoordinatorName: primaryCareCoordinatorNameDes,
    secondaryCareCoordinatorId: CheckNumber(res?.secondaryCareCoordinatorId),
    secondaryCareCoordinatorName: secondaryCareCoordinatorNameDes,
    speciality: CheckStringEmpty(res?.teamProviderSpeciality),
    teamInitiatedBy: CheckNumber(res?.teamInitiatedBy),
    teamInitiatorRoleId: CheckNumber(res?.teamInitiatorRoleId),
  };
};

const CheckCPTeamAddProvider: (res: any) => CPTeamAddProviderType[] = (res) => {
  const CheckedArr = CheckArr(res);
  return CheckedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      careplanId: CheckNumber(el?.careplanId),
      programId: CheckNumber(el?.programId),
      patientId: CheckNumber(el?.patientId),
      practiceId: CheckNumber(el?.practiceId),
      practiceLocationId: CheckNumber(el?.practiceLocationId),
      additionalProviderName: CheckStringEmpty(el?.additionalProviderName),
      additionalProviderSpeciality: CheckStringEmpty(
        el?.additionalProviderSpeciality
      ),
      addProviderOrganization: CheckStringEmpty(el?.addProviderOrganization),
      phoneNumber: CheckStringEmpty(el?.phoneNumber),
      faxNumber: CheckStringEmpty(el?.faxNumber),
      isDeleted: CheckBoolean(el?.isDeleted),
    };
  });
};

const CheckCPProblem: (res: any) => CPProblemType[] = (res) => {
  const checkedArr = CheckArr(res);
  return checkedArr.map((el) => {
    return {
      id: CheckNumber(el?.icdCode),
      icdCodeId: CheckNumber(el?.icdCode),
      icdCode: CheckStringEmpty(el?.icdCodeName),
      icdCodeDescription: CheckStringEmpty(el?.description),
      problemId: CheckNumber(el?.problemId),
      problemName: CheckStringEmpty(el?.problemName),
    };
  });
};

const CheckCPDeviceType: (res: any) => CPDeviceType[] = (res) => {
  const checkedArr = CheckArr(res);
  return checkedArr.map((el) => {
    return {
      ///Device Type Details
      ...DeviceTypeFormatter.DeviceTypeObj(el),

      id: CheckStringEmpty(el?.deviceTypeId),
      deviceTypeAlreadyAssigned: CheckBoolean(el?.deviceTypeAlreadyAssigned),
    };
  });
};

const CheckCPPatientDevice: (res: any) => CPDeviceListType[] = (res) => {
  const checkedArr = CheckArr(res);
  return checkedArr.map((el) => {
    return {
      ///Device Details
      ...PatientFormatter.CheckPatientDevice(el),

      id: CheckNumber(el?.id),
      patientDeviceId: CheckNumber(el?.id),
      reminderId: CheckStringEmpty(el?.readingIntervalId),
      reminderDescription: CheckStringEmpty(el?.readingIntervalDescription),
      trainingRequired: CheckBoolean(el?.isTrainingRequired),
      isTrainingRequired: CheckBoolean(el?.isTrainingRequired) ? "Yes" : "No",
      parentPatientDeviceId: CheckNumber(el?.parentPatientDeviceId),
      fromDate: DateTime.ToLocalDate(el?.fromDate),
      toDate: DateTime.ToLocalDate(el?.thruDate),
      isDeviceSuspended: CheckBoolean(el?.isDeviceSuspended),
    };
  });
};

const CheckReminder: (res: any) => ReminderType[] = (res) => {
  const checkedArr = CheckArr(res);
  return checkedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      reminderDescription: CheckStringEmpty(el?.description),
      reminderId: CheckStringEmpty(el?.id),
    };
  });
};

const CPV3Summary: (res: any) => CPSummaryV3Type[] = (res) => {
  return CheckArr(res).map((el) => {
    return {
      icdCodeList: CPV3SummaryIcdCodeList(el?.problemICDCodeList),
      id: CheckNumber(el?.problemId),
      problemId: CheckNumber(el?.problemId),
      problemName: CheckStringEmpty(el?.problemName),
      summary: {
        goals: CPV3SummaryQuesList(el?.summary?.Goals),
        interventions: CPV3SummaryQuesList(el?.summary?.Interventions),
      },
    };
  });
};

const CPV3SummaryIcdCodeList: (data: any) => ICDCodeType[] = (data) => {
  return CheckArr(data).map((el) => {
    return {
      id: CheckNumber(el?.icdCode),
      icdCodeDescription: CheckStringEmpty(el?.description),
      icdCodeId: CheckNumber(el?.icdCode),
      icdCodeName: CheckStringEmpty(el?.icdCode),
    };
  });
};

const CPV3SummaryQuesList: (data: any) => CPSummaryV3QuesType[] = (data) => {
  return CheckArr(data).map((el) => {
    return {
      id: CheckNumber(el?.careplanId),
      cpQuesId: CheckNumber(el?.careplanId),
      freeText: CheckStringEmpty(el?.freeTextAnswer),
      optList: CPV3SummaryOptList(el?.optionList),
      quesComment: CheckStringEmpty(el?.questionComment),
      quesId: CheckNumber(el?.questionId),
      quesText: CheckStringEmpty(el?.questionText),
      quesType: CheckStringEmpty(el?.questionType),
    };
  });
};

const CPV3SummaryOptList: (data: any) => CPSummaryV3OptType[] = (data) => {
  return CheckArr(data).map((el) => {
    return {
      id: CheckNumber(el?.optionId),
      optId: CheckNumber(el?.optionId),
      optText: CheckStringEmpty(el?.optionText),
      subQues: CPV3SummaryQuesList(el?.subQuestionList),
    };
  });
};

const TCMV2Summary: (res: any) => TCMSummaryV2Type = (res) => {
  return {
    problemName: CheckStringEmpty(res?.problemName),
    questionList: TCMV2SummaryQuesList(res?.questionList),
  };
};

const TCMV2SummaryQuesList: (data: any) => CPSummaryV3QuesType[] = (data) => {
  return CheckArr(data).map((el) => {
    return {
      id: CheckNumber(el?.careplanId),
      cpQuesId: CheckNumber(el?.careplanId),
      freeText: CheckStringEmpty(el?.freeTextAnswer),
      optList: TCMV2SummaryOptList(el?.questionOption),
      quesComment: CheckStringEmpty(el?.questionComment),
      quesId: CheckNumber(el?.questionId),
      quesText: CheckStringEmpty(el?.questionText),
      quesType: CheckStringEmpty(el?.questionType),
    };
  });
};

const TCMV2SummaryOptList: (data: any) => CPSummaryV3OptType[] = (data) => {
  return CheckArr(data).map((el) => {
    return {
      id: CheckNumber(el?.optionId),
      optId: CheckNumber(el?.optionId),
      optText: CheckStringEmpty(el?.optionText),
      subQues: TCMV2SummaryQuesList(el?.subQuestion),
    };
  });
};

const RecNote: (data: any) => CPRecNoteType = (data) => {
  const interactiveVal = CheckBoolean(data?.isInteractiveCommunicationDone);
  const interactive = interactiveVal ? "Yes" : "No";
  const interactivePartyName = CheckStringEmpty(
    data?.isInteractiveCommunicationDoneWithPartyName
  );
  const interactiveAndPartyName = interactiveVal
    ? `${interactive} / ${interactivePartyName}`
    : interactive;
  return {
    careplanRecurringNotesId: CheckNumber(data?.careplanRecurringNotesId),
    id: CheckNumber(data?.careplanRecurringNotesId),
    isInteractiveCommunicationDone: interactiveVal,
    isInteractiveCommunicationDoneWithPartyName: interactivePartyName,
    interactive,
    interactiveAndPartyName,
    tnotes: CheckStringEmpty(data?.notes),
    whomInteractive: CheckStringEmpty(
      data?.isInteractiveCommunicationDoneWithPartyName
    ),
    datetime: DateTime.ValidDate(data?.interactiveCommunicationUpdatedAt),
    completedBy: CheckStringEmpty(data?.interactiveCommunicationUpdatedByName),
  };
};

const RecNoteArr: (data: any) => CPRecNoteType[] = (data) => {
  return CheckArr(data).map((el) => RecNote(el));
};

const CPGenericInfo: (data: any) => EligiblePrgmResType = (data) => {
  const compreCare = CheckStringEmpty(
    data?.is_careplan_comprehensive
  ).toLowerCase();
  return {
    ...selectedPrgmInitialState,
    abbrevation: CheckStringEmpty(data?.abbreviation),
    careplanId: CheckNumber(data?.careplanId),
    careplanInitDateInEhr: CheckStringEmpty(data?.careplanInitDateInEhr),
    careplanRecurringId: CheckNumber(data?.recurringId),
    careplanRecurringStatus: CheckStringEmpty(data?.recurringStatus),
    careplanStage: PatientFormatter.GetCPStage(data?.careplanState),
    careplanState: CheckStringEmpty(data?.careplanState),
    careplanType: CheckStringEmpty(data?.careplanType),
    eligibleProgramId: CheckNumber(data?.eligibleProgramId),
    enrollmentDate: DateTime.ValidDate(data?.enrollmentDate),
    enrollmentId: CheckNumber(data?.enrolledProgramId),
    isCareplanComprehensive: compreCare && compreCare == "yes" ? true : false,
    patientId: CheckStringEmpty(data?.patientId),
    practiceId: CheckNumber(data?.practiceId),
    practiceLocationId: CheckNumber(data?.practiceLocationId),
    programId: CheckNumber(data?.programId),
    status: CheckCPStatus(data?.consent),
    teamMember: CheckBoolean(data?.isTeamMember),
  };
};

export default {
  CreateassignTeamDropData,
  CPSummary,
  CPSummaryQues,
  CPSummaryQuesOpt,
  CheckCPSearch,
  CheckCarePlanQuesArr,
  CheckCarePlanQuesObj,
  CheckCarePlanQuesOpt,
  CheckCarePlanQuesUpdateObj,
  CheckCPProblemRead,
  CheckCPProblemArr,
  CheckRecurringCPSearch,

  ///Checked
  CheckCPTeam,
  CheckCPProblem,
  CheckCPDeviceType,
  CheckCPPatientDevice,
  CheckReminder,
  CPV3Summary,
  TCMV2Summary,
  RecNoteArr,
  CPGenericInfo,
};
