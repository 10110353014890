import { Box } from "@mui/material";
import CstmBtn from "Components/CstmBtn";
import { RouteUrls } from "Constant/RouteUrls";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import { PatientInitialStateType } from "DataTypes/Redux";
import { FC, Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addSelectedDevice } from "Redux/Patient/Action";
import { HandleFormChange, ValidateFormData } from "Utils/common";
import { FormatDropdownData } from "Utils/DataFormatter";
import PatientFormatter from "Utils/Patient.Formatter";
import { commonFormSwitch } from "Utils/SwitchCases";

const TrainingNotesModal: FC<{ setClose: () => void }> = ({ setClose }) => {
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { rpmPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const dropArr = FormatDropdownData(
    rpmPrgm?.patientDevices,
    "patientDeviceId",
    "deviceName",
    "patientDeviceId"
  );
  const [formData, setFormData] = useState<FormDataType[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    formArr && setFormData(formArr(dropArr));
  }, [formArr]);

  const handleSubmit = (): void => {
    const newArr = ValidateFormData(formData);
    setFormData(newArr.formData);
    if (!newArr.errorCheck) {
      const patientDeviceId = newArr.formData[0].dropValue;
      if (patientDeviceId) {
        setClose();
        const prgm = rpmPrgm?.patientDevices.find(
          (el) => el.patientDeviceId == patientDeviceId
        );
        if (prgm)
          dispatch(
            addSelectedDevice(PatientFormatter.CheckPatientDevice(prgm))
          );
        navigate(
          `${RouteUrls.patient}/${patientId}/${RouteUrls.device}/${patientDeviceId}/${RouteUrls.trainingNotes}`
        );
      }
    }
  };

  return (
    <Fragment>
      <Box>
        {formData.length > 0 &&
          formData.map((el) => {
            return (
              <Box key={el.id} sx={{ margin: "auto", width: "50%" }}>
                {commonFormSwitch({
                  formObj: el,
                  onChange: (data) =>
                    setFormData(HandleFormChange(data, formArr(dropArr))),
                })}
              </Box>
            );
          })}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"flex-end"}
          sx={{ "& >button": { ml: 1, mt: 3, mb: 1 } }}
        >
          <CstmBtn label={"View"} onClick={handleSubmit} />
        </Box>
      </Box>
    </Fragment>
  );
};
export default TrainingNotesModal;

export const formArr = (dropArr: DropdownArrPropsType[]): FormDataType[] => [
  {
    id: "supplyName",
    label: "Device Name",
    placeholder: "Select Device Name",
    boxType: "single-select-drop",
    dropArr: dropArr,
    boxSize: 3,
    validatetype: "dropdown",
    required: true,
  },
];
