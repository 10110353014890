import { DropdownArrPropsType } from "DataTypes/Form";

export const FormatDropdownData: (
  data: any,
  idKey: string,
  labelKey: string,
  valKey: string
) => DropdownArrPropsType[] = (data, idKey, labelKey, valKey) => {
  return data?.map((el: any, index: number) => ({
    id: idKey ? el[idKey] : index,
    label: el[labelKey],
    value: el[valKey],
  }));
};

export default {
  FormatDropdownData,
};
