export const SET_ALERT = "SET_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const START_SCREEN_LOAD = "START_SCREEN_LOAD";
export const STOP_SCREEN_LOAD = "STOP_SCREEN_LOAD";
export const SET_NAV_ARR = "SET_NAV_ARR";
export const SET_LEFT_HEAD = "SET_LEFT_HEAD";
export const SET_RIGHT_HEAD = "SET_RIGHT_HEAD";
export const SET_TIMER_STATUS = "SET_TIMER_STATUS";
export const SET_TIMER_START_DATE = "SET_TIMER_START_DATE";
export const SET_TIMER_VALUE = "SET_TIMER_VALUE";
export const SET_ONLY_TIMER = "SET_ONLY_TIMER";
export const SET_FORM_LOAD = "SET_FORM_LOAD";
export const CLEAR_SCREEN = "CLEAR_SCREEN";
