import { AppointmentPatientType } from "DataTypes/Services/Patient.type";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import {
  CheckArr,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "Utils/common";

const AppointmentPatientList: (data: any) => AppointmentPatientType[] = (
  data
) => {
  const CheckedArr = CheckArr(data);
  const newArr: AppointmentPatientType[] = CheckedArr.map((el, index) => {
    return {
      ...PatientFormatter.ListPatientInfo1(el),
      dob: DateTime.ValidDate(el?.dOB),
      id: index,
      patientId: CheckStringId(el?.patientId),
      practice: CheckStringEmpty(el?.practiceName),
      provider: CheckStringEmpty(el?.providerName),
      providerId: CheckNumber(el?.providerId),
    };
  });
  return newArr;
};

export default { AppointmentPatientList };
