import { Fragment, FC, useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Tasks from "./Tasks";
import Visits from "./Visits";
import { CommonStyle } from "Components";
import { SideModalProps } from "DataTypes/Modal";
import ImgPath from "Constant/Imgs";
import TabPanel from "Components/TabPanel";
import RedoxApi from "Service/Redox.api";
import RedoxFormatter from "Utils/Redox.Formatter";
import { VisitSearchType } from "DataTypes/Services/Redox.type";
import PatientApi from "Service/Patient.api";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import TaskFormatter from "Utils/Task.Formatter";
import { TaskListType } from "DataTypes/Services/Tasks";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TaskVisit: FC<SideModalProps> = ({ onClose }) => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const patId = patientInfo.id;
  const [visitData, setVisitData] = useState<VisitSearchType[]>([]);
  const [rows, setRows] = useState<TaskListType[]>([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    tabValue == 0 ? getTaskData() : getVisitData();
  }, [tabValue, patId]);

  const getVisitData: () => void = async () => {
    setLoading(true);
    const res = await RedoxApi.VisitSearch({ id1: patId });
    if (res?.success) {
      setVisitData(RedoxFormatter.VisitSearch(res?.data));
    }
    setLoading(false);
  };

  const getTaskData: () => void = async () => {
    setLoading(true);
    const res = await PatientApi.PatientTaskSearch({
      id1: patId,
      offset: 0,
      limit: 100,
    });
    if (res?.success) setRows(TaskFormatter.list(res?.data));
    setLoading(false);
  };

  const Layer1 = (
    <Fragment>
      <Box sx={{ ...sxHeader, ...CommonStyle.sxRow }}>
        <Typography fontWeight="bold">Tasks & Visits</Typography>
        <Box sx={{ ...CommonStyle.sxRoundIconsMainSm }} onClick={onClose}>
          <ImgPath.CloseIcon titleAccess="Close" />
        </Box>
      </Box>
      <Box sx={{ ...sxTab }}>
        <Tabs
          value={tabValue}
          onChange={(event, index) => {
            setTabValue(index);
          }}
          aria-label="basic tabs example"
          variant="fullWidth"
          indicatorColor="secondary"
        >
          <Tab label="Tasks" {...a11yProps(0)} />
          <Tab label="Visits" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0} style={{ ...sxTabPanel }}>
        <Tasks data={rows} loading={loading} onClose={onClose} />
      </TabPanel>
      <TabPanel value={tabValue} index={1} style={{ ...sxTabPanel }}>
        <Visits data={visitData} loading={loading} />
      </TabPanel>
    </Fragment>
  );

  return (
    <Fragment>
      <Box width="100%">{Layer1}</Box>
    </Fragment>
  );
};
export default TaskVisit;

const sxTab = {
  px: 2,
  "&>div": {
    borderBottom: 1,
    borderColor: "custom.main",
  },
};

const sxHeader = {
  justifyContent: "space-between",
  height: "10%",
  px: 2,
};

const sxTabPanel = {
  height: "75%",
};
