import { columnType } from "DataTypes/Table";

export const DeviceCol: columnType[] = [
  {
    id: "deviceTypeDescription",
    label: "Device Type",
    width: "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "deviceName",
    label: "Device Name",
    width: "18%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "deviceProviderValue",
    label: "Device Provider",
    width: "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "actualDeviceId",
    label: "Device ID",
    width: "12%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "reminderDescription",
    label: "Reading Reminders",
    width: "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
  {
    id: "isTrainingRequired",
    label: "Training Required",
    width: "15%",
    align: "left",
    colType: "text",
    rowType: "text",
  },
];
