import { Box, Tab, Tabs, Typography } from "@mui/material";
import { label } from "Constant/En";
import { SideModalCMTabType, SideModalProps } from "DataTypes/Modal";
import {
  ListInitialStateType,
  PatientInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { EligiblePrgmResType } from "DataTypes/Services/Patient.type";
import PatientApi from "Service/Patient.api";
import DateTime from "Utils/DateTime";
import PatientFormatter from "Utils/Patient.Formatter";
import { FC, Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoData from "Components/NoData";
import { CommonStyle, Loader } from "Components";
import { PrgmListResType } from "DataTypes/Services/Prgm.type";
import ImgPath from "Constant/Imgs";
import { TabArr, unenrollObj } from "./Data";
import ConsentPdf from "./ConsentPdf";
import TabPanel1 from "Components/TabPanel/index1";
import UnenrollPdf from "./UnenrollPdf";
import DischargeSummary from "./DischargeSummary";
import CareTeam from "./CareTeam";
import CarePlan1 from "./CarePlan";
import RPMDevices from "./RPMDevices";
import PlanPdf from "./PlanPdf";
import PreviousNotes from "./PreviousNotes";
import AddonLogTime from "./Addon Log Time";
import RevisionNotes from "./RevisionNotes";

const CareManagement1: FC<SideModalProps> = ({ onClose }) => {
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { programList }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const [filterPrgmList, setFilterPrgmList] = useState<PrgmListResType[]>([]);
  const [enrollList, setEnrollList] = useState<EligiblePrgmResType[]>([]);
  const [loading, setLoading] = useState(true);
  const [prgmTVal, setPrgmTVal] = useState(0);
  const [filterEList, setFilterEList] = useState<EligiblePrgmResType[]>([]);
  const [enrollTVal, setEnrollTVal] = useState(0);
  const [selectedEnrollment, setSelectedEnrollment] =
    useState<EligiblePrgmResType | null>(null);
  const [filterTabArr, setFilterTabArr] = useState<SideModalCMTabType[]>([]);
  const [tabValue, setTabValue] = useState("");

  useEffect(() => {
    setData();
  }, [patientInfo.id]);

  const setData: () => void = async () => {
    const res = await PatientApi.enrollPrgmHistory({
      id1: patientInfo.id,
    });
    if (res.success) {
      const formatArr = PatientFormatter.CheckEligiblePrgmRes(
        res?.data,
        myInfo.id
      );
      setFilterPrgmList(
        programList.filter((el) => {
          const index = formatArr.findIndex((el1) => el1.programId == el.id);
          if (index != -1) return el;
        })
      );
      setEnrollList(formatArr);
    }
    setLoading(false);
  };

  useEffect(() => {
    setEnrollTVal(0);
    if (filterPrgmList.length > 0) {
      const prgmId = filterPrgmList[prgmTVal].id;
      setFilterEList(enrollList.filter((el) => el.programId == prgmId));
    } else setFilterEList([]);
  }, [prgmTVal, filterPrgmList]);

  useEffect(() => {
    if (filterEList.length > 0) {
      setSelectedEnrollment(filterEList[enrollTVal]);
    } else setSelectedEnrollment(null);
  }, [enrollTVal, filterEList]);

  useEffect(() => {
    handleTabArr();
  }, [selectedEnrollment]);

  const handleTabArr: () => void = () => {
    if (selectedEnrollment) {
      setTabValue(
        selectedEnrollment.abbrevation == "TCM"
          ? "discharge-summary"
          : "enroll-pdf"
      );
      let filArr = TabArr.filter((filEl) => {
        const include = filEl.prgmArr.includes(selectedEnrollment.abbrevation);
        const stage = selectedEnrollment.careplanStage >= filEl.stage;
        const showCarePdf = !(
          selectedEnrollment.isCareplanComprehensive &&
          (filEl.id == "care-plan-pdf" ||
            filEl.id == "tcm-plan-pdf" ||
            filEl.id == "treatment-plan-pdf")
        );
        if (include && stage && showCarePdf) return filEl;
      });
      if (selectedEnrollment.status == "Unenrolled") {
        filArr = [...filArr, unenrollObj];
      }
      setFilterTabArr([...filArr]);
    } else {
      setTabValue("");
      setFilterTabArr([]);
    }
  };

  const PrgmListUI = (
    <Box height="100%" sx={{ px: 2, "& >div": { height: "100%" } }}>
      <Tabs
        value={enrollTVal}
        onChange={(event, index) => {
          setEnrollTVal(index);
        }}
        aria-label="basic tabs example"
        variant="scrollable"
        indicatorColor="secondary"
        orientation="vertical"
        scrollButtons="auto"
        sx={{
          "& >div:first-of-type": {
            height: "20px",
          },
          "& >div:last-of-type": {
            height: "20px",
          },
        }}
      >
        {filterEList.map((el) => {
          return (
            <Tab
              sx={{ ...sxFilterPrgmTab }}
              key={el.enrollmentId}
              label={
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  sx={{
                    ...(el.enrollmentId == selectedEnrollment?.enrollmentId
                      ? sxListBoxSelected
                      : sxListBox),
                  }}
                >
                  <Typography variant="subtitle1">
                    {`${DateTime.MonthName(el.planningMonth) || label.naText} ${
                      el.planningYear || label.naText
                    }`}
                  </Typography>
                  <Typography variant="subtitle1" mr={1}>
                    {el.status}
                  </Typography>
                </Box>
              }
              id={`simple-tab-${el.enrollmentId}`}
              aria-controls={`simple-tabpanel-${el.enrollmentId}`}
            />
          );
        })}
      </Tabs>
    </Box>
  );

  const Layer1 = (
    <Fragment>
      <Box sx={{ ...sxHeader }}>
        <Typography fontWeight="bold">{label.cm}</Typography>
        <Box sx={{ ...CommonStyle.sxRoundIconsMainSm }} onClick={onClose}>
          <ImgPath.CloseIcon titleAccess="Close" />
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Box sx={{ ...sxPrgmMainBox }}>
            <Tabs
              value={prgmTVal}
              onChange={(event, index) => {
                setPrgmTVal(index);
              }}
              aria-label="basic tabs example"
              variant="fullWidth"
              indicatorColor="secondary"
            >
              {filterPrgmList.map((el) => {
                return (
                  <Tab
                    sx={{ ...sxPrgmTab }}
                    key={el.id}
                    label={el.abbrevation}
                    id={`simple-tab-${el.id}`}
                    aria-controls={`simple-tabpanel-${el.id}`}
                  />
                );
              })}
            </Tabs>
          </Box>
          <Box height="85%">
            {filterEList.length > 0 ? PrgmListUI : <NoData />}
          </Box>
        </Fragment>
      )}
    </Fragment>
  );

  const Layer2 = (
    <Fragment>
      <Box sx={{ ...sxTabMainBox }}>
        <Tabs
          value={tabValue}
          onChange={(event, value) => {
            setTabValue(value);
          }}
          aria-label="basic tabs example"
          variant="scrollable"
          indicatorColor="secondary"
        >
          {filterTabArr.map((filEl) => {
            return (
              <Tab
                key={filEl.id}
                value={filEl.id}
                sx={{ ...sxTabTab }}
                label={filEl.label}
                id={`simple-tab-${filEl.id}`}
                aria-controls={`simple-tabpanel-${filEl.id}`}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box height="90%" px={2} py={1}>
        {selectedEnrollment ? (
          <Fragment>
            <TabPanel1 value={"enroll-pdf"} currentValue={tabValue}>
              <ConsentPdf prgmDetails={selectedEnrollment} />
            </TabPanel1>
            <TabPanel1 value={"discharge-summary"} currentValue={tabValue}>
              <DischargeSummary prgmDetails={selectedEnrollment} />
            </TabPanel1>
            <TabPanel1 value={"rpm-device"} currentValue={tabValue}>
              <RPMDevices prgmDetails={selectedEnrollment} />
            </TabPanel1>
            <TabPanel1 value={"care-team"} currentValue={tabValue}>
              <CareTeam prgmDetails={selectedEnrollment} onClose={onClose} />
            </TabPanel1>
            <TabPanel1 value={"rpm-team"} currentValue={tabValue}>
              <CareTeam prgmDetails={selectedEnrollment} onClose={onClose} />
            </TabPanel1>
            <TabPanel1 value={"tcm-team"} currentValue={tabValue}>
              <CareTeam prgmDetails={selectedEnrollment} onClose={onClose} />
            </TabPanel1>
            <TabPanel1 value={"care-plan"} currentValue={tabValue}>
              <CarePlan1 prgmDetails={selectedEnrollment} onClose={onClose} />
            </TabPanel1>
            <TabPanel1 value={"treatment-plan"} currentValue={tabValue}>
              <CarePlan1 prgmDetails={selectedEnrollment} onClose={onClose} />
            </TabPanel1>
            <TabPanel1 value={"tcm"} currentValue={tabValue}>
              <CarePlan1 prgmDetails={selectedEnrollment} onClose={onClose} />
            </TabPanel1>
            <TabPanel1 value={"care-plan-pdf"} currentValue={tabValue}>
              <PlanPdf prgmDetails={selectedEnrollment} />
            </TabPanel1>
            <TabPanel1 value={"tcm-plan-pdf"} currentValue={tabValue}>
              <PlanPdf prgmDetails={selectedEnrollment} />
            </TabPanel1>
            <TabPanel1 value={"treatment-plan-pdf"} currentValue={tabValue}>
              <PlanPdf prgmDetails={selectedEnrollment} />
            </TabPanel1>
            <TabPanel1 value={"revision-notes"} currentValue={tabValue}>
              <RevisionNotes
                prgmDetails={selectedEnrollment}
                onClose={onClose}
              />
            </TabPanel1>
            <TabPanel1 value={"previous-notes"} currentValue={tabValue}>
              <PreviousNotes
                prgmDetails={selectedEnrollment}
                onClose={onClose}
              />
            </TabPanel1>
            <TabPanel1 value={"add-time-log"} currentValue={tabValue}>
              <AddonLogTime
                prgmDetails={selectedEnrollment}
                onClose={onClose}
              />
            </TabPanel1>
            <TabPanel1 value={"unenroll-pdf"} currentValue={tabValue}>
              <UnenrollPdf prgmDetails={selectedEnrollment} />
            </TabPanel1>
          </Fragment>
        ) : (
          <NoData />
        )}
      </Box>
    </Fragment>
  );

  return (
    <Fragment>
      <Box width="25%">{Layer1}</Box>
      <Box width="1%" />
      {selectedEnrollment && <Box width="74%">{Layer2}</Box>}
    </Fragment>
  );
};

export default CareManagement1;

const sxHeader = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  justifyContent: "space-between",
  height: "7%",
  px: 2,
};

const sxPrgmMainBox = {
  height: "8%",
  px: 2,
  "&>div": {
    borderBottom: 1,
    borderColor: "custom.main",
    minHeight: 0,
    height: "100%",
    "& >div": {
      height: "100%",
      "& >div": {
        height: "100%",
      },
    },
  },
};

const sxPrgmTab = {
  minHeight: 0,
  minWidth: 0,
};

const sxFilterPrgmTab = {
  minHeight: 0,
  minWidth: 0,
  py: 1,
  px: 0,
};

const sxListBox = {
  "& >h6": {
    color: "custom.dark",
  },
};

const sxListBoxSelected = {
  "& >h6": {
    color: "primary.main",
  },
};

const sxTabMainBox = {
  height: "8%",
  px: 2,
  "& >div": {
    borderBottom: 1,
    borderColor: "custom.main",
    minHeight: 0,
    height: "100%",
    "& >div": {
      height: "100%",
      "& >div": {
        height: "100%",
      },
    },
  },
};

const sxTabTab = {
  minHeight: 0,
  minWidth: 0,
  height: "100%",
  fontSize: "0.8rem",
};
