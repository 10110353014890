import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { BreadArrType, Filter2DataProps } from "DataTypes/Common";
import { TaskPriorityArr } from "Constant/Arr";
import { TaskListType } from "DataTypes/Services/Tasks";
import FilterData from "Components/Filter2/Data";
import { DropdownArrPropsType } from "DataTypes/Form";

export const actionArr: (
  handlePatientDetails: () => void,
  handleEdit: () => void,
  handleDelete: () => void,
  data: TaskListType
) => actionArrType[] = (
  handlePatientDetails,
  handleEdit,
  handleDelete,
  data
) => {
  return [
    ControlledTable.ActionData.viewTitleAction(handlePatientDetails),
    ...(data?.status != "Close" && data?.status != "Completed"
      ? [
          ControlledTable.ActionData.editAction({ handleClick: handleEdit }),
          ControlledTable.ActionData.deleteAction(handleDelete),
        ]
      : []),
  ];
};

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.ExclaimationCol("4%"),
  ControlledTable.ColumnData.DateTimeCol1("9%"),
  ControlledTable.ColumnData.TaskTypeCol("9%"),
  ControlledTable.ColumnData.StatusCol("9%"),
  ControlledTable.ColumnData.PriorityCol("9%"),
  ControlledTable.ColumnData.SourceCol("9%"),
  ControlledTable.ColumnData.AssigneeCol("9%"),
  ControlledTable.ColumnData.PatientCol("9%"),
  ControlledTable.ColumnData.SubjectCol("12%"),
  ControlledTable.ColumnData.NoteCol("12%"),
  ControlledTable.ColumnData.ActionCol("9%"),
];

export const BreadArr: BreadArrType[] = [
  {
    id: 1,
    label: "Outgoing Tasks",
    path: "",
  },
];

export const FilterArr = (
  TypeArr: DropdownArrPropsType[],
  taskStatusList: DropdownArrPropsType[],
): Filter2DataProps[] => {
  return [
    FilterData.taskDate,
    { ...FilterData.taskType, dropArr: TypeArr },
    { ...FilterData.taskStatus, dropArr: taskStatusList },
    { ...FilterData.taskPriority, dropArr: TaskPriorityArr },
    FilterData.taskAssignee,
    FilterData.taskAssigneeDesig,
    FilterData.patientTask,
  ];
};
