///Bug resolve azure-404

import { FC, useState, useEffect, Fragment } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ControlledForm from "Components/ControlledForm";
import { DropdownArrPropsType } from "DataTypes/Form";
import { CommonStyle } from "Components";
import ImgPath from "Constant/Imgs";
import { MonthArr } from "Pages/Home/HeadScreen/ListMgmt/Billing/Data";
import { label } from "Constant/En";
import { Filter2DataProps, FilterProps, FilterValType } from "DataTypes/Common";
import DateTime from "Utils/DateTime";
import { CheckArr, CheckStringEmpty } from "Utils/common";

export const BillingTypeArr = [
  {
    id: 1,
    label: "Service Billing",
    value: "service",
  },
  {
    id: 2,
    label: "Biometric Billing",
    value: "biometric",
  },
];

const Filter2: FC<FilterProps> = (props) => {
  const {
    // changeSearchText,
    handleFilterClick,
    monthChange,
    monthFilter,
    monthVal,
    filterDataArr,
    yearChange,
    yearFilter,
    yearVal,
    setFilterHeight,
    billTypeChange,
    billTypeVal,
    viewBillType,
  } = props;
  const ColumnFilterwidth = 20;
  const ValueFilterwidth = 20;
  const MonthFilterWidth = monthFilter ? 15 : 0;
  const YearFilterWidth = yearFilter ? 15 : 0;
  const RefreshIconWidth = 3;
  const ApplyIconWidth = 3;
  const ClearFilterWidth = 8;
  const BillTypeWidth = viewBillType ? 12 : 0;
  const searchWidth =
    100 -
    (MonthFilterWidth +
      YearFilterWidth +
      ColumnFilterwidth +
      ValueFilterwidth +
      RefreshIconWidth +
      ApplyIconWidth +
      ClearFilterWidth +
      BillTypeWidth);

  const [selectedFilterLabel, setSelectedFilterLabel] =
    useState<Filter2DataProps | null>(null);
  const [filterdata, setFilterData] = useState<Filter2DataProps[]>([]);
  const [labelArr, setLabelArr] = useState<DropdownArrPropsType[]>([]);
  // const [showFilter, setShowFilter] = useState(true);
  const offsetFilter = document?.getElementById("filter-id")?.offsetHeight;
  const [yearArr, setYearArr] = useState<DropdownArrPropsType[]>([]);

  useEffect(() => {
    if (yearFilter) {
      const arr: DropdownArrPropsType[] = [];
      const minYear = 2023;
      const maxYear = new Date().getFullYear();
      for (let i = minYear; i <= maxYear; i++) {
        const val = i.toString();
        arr.push({
          id: val,
          label: val,
          value: val,
        });
      }
      setYearArr(arr.reverse());
    }
  }, []);

  useEffect(() => {
    setFilterHeight(offsetFilter || 0);
  }, [offsetFilter]);

  useEffect(() => {
    handleDataSorting();
  }, [filterDataArr]);

  //Sorting of selected and unselected filter objects
  const handleDataSorting: () => void = () => {
    const selectedArr = filterDataArr.filter((el) => el.value.length > 0);
    setLabelArr(
      filterDataArr.map((el) => {
        return {
          id: el.id,
          label: el.label,
          value: el.id,
        };
      })
    );
    setFilterData(selectedArr);
  };

  //set state for selected filter dropdown
  const handleFilterLabel: (val: string | number) => void = (val) => {
    const newObj = filterDataArr.find((el) => el.id == val);
    setSelectedFilterLabel(newObj ? newObj : null);
  };

  //set answer for selected filter dropdown
  const handleAnsSwitch: (val: FilterValType) => void = (val) => {
    if (selectedFilterLabel) {
      setSelectedFilterLabel({ ...selectedFilterLabel, value: val });
    }
  };

  //send new data to the actual filter
  const handleApplyFilter: () => void = () => {
    const newArr = filterDataArr.map((el) => {
      if (selectedFilterLabel?.id == el.id) {
        if (
          el.type == "input" &&
          Array.isArray(el.value) &&
          typeof selectedFilterLabel.value == "string"
        ) {
          el.value = [...el.value, selectedFilterLabel.value];
        } else el.value = selectedFilterLabel.value;
      }
      return el;
    });
    handleFilterClick(newArr);
    setSelectedFilterLabel(null);
  };

  //clear selected filter value
  const handleRefreshFilter: () => void = () => {
    setSelectedFilterLabel(null);
  };

  //clear selected filter or all filters
  const handleClearFilter: (id: string) => void = (id) => {
    let newArr = [];
    if (id == "all") {
      newArr = filterDataArr.map((el) => {
        el.value = [];
        return el;
      });
    } else {
      newArr = filterDataArr.map((el) => {
        if (el.id == id) el.value = [];
        return el;
      });
    }
    handleFilterClick(newArr);
  };

  //clear selected filter or all filters
  const handleSingleValClearFilter: (
    id: string,
    val: string | number
  ) => void = (id, val) => {
    let newArr: Filter2DataProps[] = JSON.parse(JSON.stringify(filterDataArr));
    newArr = newArr.map((el) => {
      if (el.id == id && Array.isArray(el.value))
        el.value = el.value.filter((el1) => el1 != val);
      return el;
    });
    handleFilterClick(newArr);
  };

  const AnswerSwitch: (
    selectedFilterLabel: Filter2DataProps | null
  ) => JSX.Element = (selectedFilterLabel) => {
    const newVal = selectedFilterLabel?.value;
    switch (selectedFilterLabel?.type) {
      case "single-select":
        return (
          <ControlledForm.SingleSelectDrop
            id={selectedFilterLabel?.id}
            placeholder="Select Option"
            dropArr={selectedFilterLabel?.dropArr || []}
            onClick={(val) => handleAnsSwitch(CheckStringEmpty(val))}
            dropValue={CheckStringEmpty(newVal)}
          />
        );
      case "multi-select":
        return (
          <ControlledForm.MultiSelectDrop
            id={selectedFilterLabel?.id}
            placeholder="Select Option"
            dropArr={selectedFilterLabel?.dropArr || []}
            onClick={(val) => handleAnsSwitch(val)}
            dropValue={CheckArr(newVal)}
          />
        );
      case "input":
        return (
          <ControlledForm.Input
            id={selectedFilterLabel.id}
            placeholder="Enter Value"
            onChange={(val) => handleAnsSwitch(CheckStringEmpty(val))}
          />
        );
      case "date":
        return (
          <ControlledForm.MuiDatePicker
            id={selectedFilterLabel?.id}
            onChange={(val) => handleAnsSwitch(CheckStringEmpty(val))}
            value={CheckStringEmpty(newVal)}
          />
        );
      default:
        return (
          <ControlledForm.MultiSelectDrop
            id={selectedFilterLabel?.id || ""}
            placeholder="Select Option"
            dropArr={selectedFilterLabel?.dropArr || []}
            onClick={(val) => handleAnsSwitch(val)}
            dropValue={CheckArr(newVal)}
          />
        );
    }
  };

  return (
    <Box id="filter-id" pt={1} pb={0.5}>
      <Box sx={{ ...CommonStyle.sxRow }}>
        {viewBillType && (
          <ControlledForm.SingleSelectDrop
            id="Billing-type"
            placeholder="Select Billing Type"
            dropArr={BillingTypeArr || []}
            onClick={(val) =>
              billTypeChange && billTypeChange(CheckStringEmpty(val))
            }
            dropValue={billTypeVal}
            sxProps={{ width: BillTypeWidth + "%" }}
          />
        )}
        {/* <ControlledForm.Input
          id="Search"
          placeholder="Search"
          iconId="search"
          sxProps={{ width: searchWidth + "%", mr: 1 }}
          onChange={(val) =>
            typeof val == "string" && changeSearchText && changeSearchText(val)
          }
        /> */}
        <Box width={searchWidth + "%"} />
        {monthFilter && (
          <ControlledForm.SingleSelectDrop
            id="label.selectmonth"
            placeholder={label.selectmonth}
            dropArr={MonthArr}
            dropValue={monthVal}
            onClick={(val) =>
              monthChange && monthChange(typeof val == "string" ? val : "")
            }
            sxProps={{ width: MonthFilterWidth + "%", mr: 1 }}
          />
        )}
        {yearFilter && (
          <ControlledForm.SingleSelectDrop
            id="label.selectyear"
            placeholder={label.selectyear}
            dropArr={yearArr}
            dropValue={yearVal}
            onClick={(val) =>
              yearChange && yearChange(typeof val == "string" ? val : "")
            }
            sxProps={{ width: YearFilterWidth + "%", mr: 1 }}
          />
        )}
        <ControlledForm.SingleSelectDrop
          id="filterlabel"
          placeholder="Select Column Label"
          onClick={(val) => {
            handleFilterLabel(
              typeof val == "string" || typeof val == "number" ? val : ""
            );
          }}
          dropValue={selectedFilterLabel?.id}
          dropArr={labelArr}
          sxProps={{ width: ColumnFilterwidth + "%", mr: 1 }}
        />
        <Box sx={{ width: ValueFilterwidth + "%", mr: 1 }}>
          {AnswerSwitch(selectedFilterLabel)}
        </Box>
        <Box
          width={ApplyIconWidth + "%"}
          display="flex"
          justifyContent="center"
        >
          <IconButton
            title="Apply Filter"
            sx={{ ...CommonStyle.sxRoundIconsMainSm }}
            onClick={handleApplyFilter}
          >
            <ImgPath.SaveIcon />
          </IconButton>
        </Box>
        <Box
          width={RefreshIconWidth + "%"}
          display="flex"
          justifyContent="center"
        >
          <IconButton
            title="Refesh"
            sx={{ ...CommonStyle.sxRoundIconsMainSm }}
            onClick={handleRefreshFilter}
          >
            <ImgPath.RefreshIcon />
          </IconButton>
        </Box>
        <Typography
          textAlign={"center"}
          width={ClearFilterWidth + "%"}
          onClick={() => handleClearFilter("all")}
          variant="subtitle1"
          sx={{
            cursor: "pointer",
            textDecorationLine: "underline",
          }}
        >
          Clear Filter
        </Typography>
        {/* <IconButton onClick={() => setShowFilter(!showFilter)}>
          {showFilter ? <ImgPath.ArrowDropUp /> : <ImgPath.ArrowDropDown />}
        </IconButton> */}
      </Box>
      <Box
        className="SelectedTags"
        display="flex"
        alignItems={"center"}
        flexWrap="wrap"
        overflow="auto"
        maxHeight={140}
      >
        {filterdata.map((el) => {
          return (
            <Box key={el.id} display="flex" sx={{ ...sxChip }}>
              {Array.isArray(el.value) ? (
                <Fragment>
                  <Typography variant="subtitle1">{el.label}:</Typography>
                  {el.value.map((el1, index) => {
                    let newVal = el1;
                    if (el.type == "multi-select") {
                      newVal =
                        el.dropArr?.find((obj) => obj.value == el1)?.label ||
                        "";
                    }
                    return (
                      <IconButton
                        sx={{
                          p: 0,
                          ml: 0.5,
                          "& h6:hover": {
                            textDecoration: "underline",
                          },
                        }}
                        key={el1}
                        onClick={() => handleSingleValClearFilter(el.id, el1)}
                      >
                        <Typography variant="subtitle1">
                          {newVal}
                          {index != el.value.length - 1 ? "," : ""}
                        </Typography>
                      </IconButton>
                    );
                  })}
                </Fragment>
              ) : (
                <Typography variant="subtitle1">{`${
                  el.label
                }: ${DateTime.ValidDate(el.value)}`}</Typography>
              )}
              <IconButton
                sx={{ p: 0, ml: 0.5 }}
                onClick={() => handleClearFilter(el.id)}
              >
                <ImgPath.CloseRound />
              </IconButton>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
export default Filter2;

const sxChip = {
  backgroundColor: "roundiconbg.main",
  px: 0.5,
  py: 0.25,
  mt: 0.5,
  mb: 0.25,
  mr: 0.25,
  borderRadius: 5,
  "& h6": {
    color: "common.white",
  },
  "& svg": {
    ml: 0.5,
    fontSize: "1rem",
    color: "common.white",
  },
};
