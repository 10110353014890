import { actionArrType, columnType } from "DataTypes/Table";
import { ControlledTable } from "Components";
import { Filter2DataProps } from "DataTypes/Common";
import FilterData from "Components/Filter2/Data";
import { store } from "Redux/Store";
import { FormatDropdownData } from "Utils/DataFormatter";

export const TableCol: columnType[] = [
  ControlledTable.ColumnData.PicCol(),
  ControlledTable.ColumnData.PatientInfoCol(),
  ControlledTable.ColumnData.PracticeCol(),
  ControlledTable.ColumnData.ProviderCol(),
  ControlledTable.ColumnData.CareCoordinationCol(),
  ControlledTable.ColumnData.PrimCareCoordinatorCol(),
  {
    id: "duedate",
    label: "CP Completion Date",
    width: "10%",
    align: "left",
    colType: "text",
    rowType: "date",
  },
  ControlledTable.ColumnData.StatusCol(),
  ControlledTable.ColumnData.ActionCol(),
];

export const actionArr: (
  handleChart: () => void,
  handlePatientDetails: () => void,
  handleTask: () => void,
  handleAddTimeLog: () => void,
  roleId: number | null
) => actionArrType[] = (
  handleChart,
  handlePatientDetails,
  handleTask,
  handleAddTimeLog,
  roleId
) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.userAction(handlePatientDetails),
    ControlledTable.ActionData.chartAction(handleChart),
    ControlledTable.ActionData.taskAction(handleTask),
    ...(roleId == 1 || roleId == 2
      ? []
      : [ControlledTable.ActionData.timeLogAction(handleAddTimeLog)]),
  ];
  return Arr;
};

export const FilterArr: () => Filter2DataProps[] = () => {
  const { prgDropList } = store.getState().list;
  const Arr: Filter2DataProps[] = [
    FilterData.patientName,
    FilterData.dob,
    FilterData.homeNo,
    FilterData.mobile,
    FilterData.orgName,
    FilterData.orgLocation,
    FilterData.provider,
    FilterData.providerDesig,
    {
      ...FilterData.prgmName,
      dropArr: FormatDropdownData(prgDropList, "id", "label", "label"),
    },
    FilterData.primCoordinator,
    FilterData.primCoordinatorDesig,
    FilterData.careplanCompletionFrom,
    FilterData.careplanCompletionTo,
  ];
  return Arr;
};
