import { FormDataType } from "DataTypes/Form";
import { ControlledTable } from "Components";
import { actionArrType, columnType } from "DataTypes/Table";

export const actionArr: (handleEdit: (data: any) => void) => actionArrType[] = (
  handleEdit
) => {
  const Arr: actionArrType[] = [
    ControlledTable.ActionData.editAction({ handleClick: handleEdit }),
  ];
  return Arr;
};
export const TableCol: columnType[] = [
  ControlledTable.ColumnData.BaseUrlCol(),
  ControlledTable.ColumnData.SecretKeyCol(),
  ControlledTable.ColumnData.SecretTokenCol(),
  ControlledTable.ColumnData.TokenExpiryInMinutesCol(),
  ControlledTable.ColumnData.ActionCol(),
];

export const InitialFormDataArr = (): FormDataType[] => {
  const Arr: FormDataType[] = [
    {
      id: "baseUrl",
      label: "Base Url",
      placeholder: "Enter Base Url",
      boxType: "input-box",
      boxSize: 6,
      validatetype: "description",
      required: true,
    },
    {
      id: "tokenExpiryInMinutes",
      label: "Token Expire in Minutes",
      placeholder: "Enter Token Expire Time",
      boxType: "input-box",
      boxSize: 6,
      validatetype: "number",
      required: true,
    },
    {
      id: "secretKey",
      label: "Secret Key",
      placeholder: "Enter Secret Key",
      boxType: "input-box",
      boxSize: 6,
      validatetype: "alphanumericName",
      required: true,
      multiline: true,
      multilineRows: 2,
    },
    {
      id: "secretToken",
      label: "Secret Token",
      placeholder: "Enter Secret Token",
      boxType: "input-box",
      boxSize: 6,
      validatetype: "alphanumericName",
      required: true,
      multiline: true,
      multilineRows: 2,
    },
  ];
  return Arr;
};
