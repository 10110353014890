import { Loader } from "Components";
import { PatientInitialStateType } from "DataTypes/Redux";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { addSelectedPrgm } from "Redux/Patient/Action";
import { setAlertData } from "Redux/Screen/Action";
import CarePlanApi from "Service/CarePlan.api";
import CPFormatter from "Utils/CP.Formatter";

const Recurring: FC = () => {
  const dispatch = useDispatch();
  const { selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleInitialLoad();
  }, []);

  const handleInitialLoad: () => void = async () => {
    let readOnly = true;
    if (selectedPrgm.abbrevation == "RPM") {
      const res = await CarePlanApi.PatientDeviceSearch({
        id1: selectedPrgm.careplanId,
        data: { isDeleted: false, isDeviceSuspended: false },
      });
      if (res?.success) {
        const newArr = CPFormatter.CheckCPPatientDevice(res?.data);
        const activeDevice = newArr.find((el) => el.actualDeviceId);
        if (!activeDevice) {
          dispatch(
            setAlertData({
              alertMessage:
                "The RPM services cannot be started for the patient until the Device ID is entered.",
              alertVariant: "warning",
              openAlert: true,
            })
          );
        }
        readOnly = !(
          selectedPrgm.careplanRecurringStatus == "not-completed" &&
          selectedPrgm.teamMember &&
          activeDevice
        );
      }
    } else {
      readOnly = !(
        selectedPrgm.careplanRecurringStatus == "not-completed" &&
        selectedPrgm.teamMember
      );
    }
    dispatch(
      addSelectedPrgm({
        ...selectedPrgm,
        showLogTime: !readOnly,
        readOnlyArr: !readOnly
          ? []
          : ["rpm-service", "question", "addNotes", "frequency"],
        showSubmitBtn: !readOnly,
      })
    );
    setLoading(false);
  };

  return loading ? <Loader /> : <Outlet />;
};

export default Recurring;
