import { post } from ".";
import { ApiServiceProps } from "DataTypes/Services/Index.type";

const userAudit: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: "activity-log/search",
    data: {
      data: payload.data,
      criterion: { limit: payload.limit, offset: payload.offset },
    },
  };
  return post(request);
};

const patientAudit: (payload: ApiServiceProps) => any = (payload) => {
  const request = {
    subUrl: `activity-log/patient/${payload.id1}/search`,
    data: {
      data: payload.data,
      criterion: { limit: payload.limit, offset: payload.offset },
    },
  };
  return post(request);
};

export default { userAudit, patientAudit };
