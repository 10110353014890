import {
  PatientActionReturnType,
  PatientInfoType,
  PatientInitialStateType,
  PatientOrgInfoType,
} from "DataTypes/Redux";
import {
  ADD_PATIENT_INFO,
  ADD_PATIENT_ORG_INFO,
  ADD_SELECTED_PRGM_INFO,
  CLEAR_PATIENT,
  CLEAR_PATIENT_INFO,
  CLEAR_PATIENT_ORG_INFO,
  CLEAR_SELECTED_PRGM_INFO,
  ADD_SELECTED_TRAINING_INFO,
  CLEAR_SELECTED_TRAINING_INFO,
  ADD_SELECTED_DEVICE_INFO,
  CLEAR_SELECTED_DEVICE_INFO,
  ADD_RPM_PRGM_INFO,
  CLEAR_RPM_PRGM_INFO,
} from "./type";
import { FormatEligiblePrgmType } from "DataTypes/PatientManagement.type";
import {
  PatientDeviceDetails,
  PatientDeviceTrainingType,
} from "DataTypes/Services/Patient.type";

export const PatientInfoInitialState: PatientInfoType = {
  address: "",
  age: "",
  city: "",
  country: "",
  dob: "",
  email: "",
  firstName: "",
  gender: "",
  home: "",
  id: null,
  lastName: "",
  middleName: "",
  mobile: "",
  name: "",
  sex: "",
  state: "",
  streetAddress: "",
  zip: "",
  notes: "",
  firstTimeNotesVisit: false,
};

export const PatientOrgInfoInitialState: PatientOrgInfoType = {
  practiceId: null,
  practiceName: "",
  practiceLocationId: null,
  practiceLocationName: "",
  practiceLocationTimezone: "",
};

export const selectedPrgmInitialState: FormatEligiblePrgmType = {
  abbrevation: "",
  activity: "",
  appointment_date: "",
  appointment_id: null,
  billableproviderId: null,
  caremanagerId: null,
  careplanActivity: null,
  careplanCreatedInEhr: "",
  careplanFrequency: "",
  careplanId: null,
  careplanInitDateInEhr: "",
  careplanRecurringId: null,
  careplanRecurringStatus: "",
  careplanState: "",
  careplanStage: 0,
  consentGainedBy: null,
  dischargeDate: "",
  eligibleProgramId: null,
  enrollmentId: null,
  isCareplanComprehensive: false,
  nextSchedule: "",
  patientDevices: [],
  patientId: null,
  planningMonth: null,
  planningYear: null,
  practiceId: null,
  practiceLocationId: null,
  practiceLocationName: "",
  practiceLocationTimezone: "",
  practiceName: "",
  primaryccId: null,
  programId: null,
  providerId: null,
  providerName: "",
  status: null,
  secondaryccId: null,
  seniorTeamMember: false,
  teamInitiatorRoleId: null,
  teamInitiatedBy: null,
  teamMember: false,

  showLogTime: false,
  enrollmentDate: "",
  recurringPlanningMonth: null,
  recurringPlanningYear: null,
  readOnlyArr: [],
  callDefaultRoute: "",
  showSaveBtn: false,
  showSubmitBtn: false,
  careplanType: "",
};

export const selectedTrainingInitialState: PatientDeviceTrainingType = {
  id: null,
  trainerId: null,
  trainerName: "",
  trainingActivity: "",
  trainingCreatedById: null,
  trainingCreatedBy: "",
  trainingId: null,
  trainingRequired: false,
  trainingStatusDescription: "",
  trainingStatusId: "",
  trainingTypeDescription: "",
  trainingTypeId: "",
};

export const selectedDeviceInitialState: PatientDeviceDetails = {
  actualDeviceId: "",
  deviceId: null,
  deviceName: "",
  deviceProvider: "",
  deviceProviderValue: "",
  deviceTypeDescription: "",
  deviceTypeId: null,
  fromDate: "",
  id: null,
  isDeleted: false,
  isDeviceSuspended: false,
  patientDeviceId: null,
  supplierName: null,
  thruDate: "",
};

const INITIAL_STATE: PatientInitialStateType = {
  patientInfo: PatientInfoInitialState,
  selectedPrgm: selectedPrgmInitialState,
  rpmPrgm: selectedPrgmInitialState,
  patientOrg: PatientOrgInfoInitialState,
  selectedTraining: selectedTrainingInitialState,
  selectedDevice: selectedDeviceInitialState,
};

const reducer = (state = INITIAL_STATE, action: PatientActionReturnType) => {
  switch (action.type) {
    case ADD_PATIENT_INFO:
      return {
        ...state,
        patientInfo: action.payload,
      };
    case CLEAR_PATIENT_INFO:
      return {
        ...state,
        patientInfo: INITIAL_STATE.patientInfo,
      };
    case ADD_PATIENT_ORG_INFO:
      return {
        ...state,
        patientOrg: action.payload,
      };
    case CLEAR_PATIENT_ORG_INFO:
      return {
        ...state,
        patientOrg: INITIAL_STATE.patientOrg,
      };
    case ADD_SELECTED_PRGM_INFO:
      return {
        ...state,
        selectedPrgm: action.payload,
      };
    case CLEAR_SELECTED_PRGM_INFO:
      return {
        ...state,
        selectedPrgm: INITIAL_STATE.selectedPrgm,
      };
    case ADD_RPM_PRGM_INFO:
      return {
        ...state,
        rpmPrgm: action.payload,
      };
    case CLEAR_RPM_PRGM_INFO:
      return {
        ...state,
        rpmPrgm: INITIAL_STATE.rpmPrgm,
      };
    case ADD_SELECTED_TRAINING_INFO:
      return {
        ...state,
        selectedTraining: action.payload,
      };
    case CLEAR_SELECTED_TRAINING_INFO:
      return {
        ...state,
        selectedTraining: INITIAL_STATE.selectedTraining,
      };
    case ADD_SELECTED_DEVICE_INFO:
      return {
        ...state,
        selectedDevice: action.payload,
      };
    case CLEAR_SELECTED_DEVICE_INFO:
      return {
        ...state,
        selectedDevice: INITIAL_STATE.selectedDevice,
      };
    case CLEAR_PATIENT:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
