import {
  AppSettingListType,
  MasterAppSetType,
} from "DataTypes/Services/Settings.type";

const Settings: (data: AppSettingListType[]) => MasterAppSetType[] = (data) => {
  function getNewArr(total: MasterAppSetType[], current: AppSettingListType) {
    const newKeyEl = {
      id: current.id,
      keyLabel: current.keyLable,
      keySlug: current.keySlug,
      value: current.value,
      typeId: current.typeId,
    };
    const newArr: MasterAppSetType[] = JSON.parse(JSON.stringify(total));
    const grpIndex = newArr.findIndex((el) => el.id == current.groupSlug);
    if (grpIndex == -1) {
      newArr.push({
        id: current.groupSlug,
        groupSlug: current.groupSlug,
        groupLabel: current.groupLabel,
        keys: new Array(newKeyEl),
      });
    } else newArr[grpIndex].keys.push(newKeyEl);
    return newArr;
  }
  return data.reduce(getNewArr, []);
};

export default { Settings };
