import { Loader } from "Components";
import { RouteUrls } from "Constant/RouteUrls";
import { clearMaster } from "Redux/Header/Action";
import CommonFormatter from "Utils/Common.Formatter";
import { FC, Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

const QuestionnaireMaster: FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    CommonFormatter.HandleNavArr({
      id: "ques-master",
      label: "Questionnaire Master",
      path:
        `/${RouteUrls.hs}/${RouteUrls.master}/` +
        `${RouteUrls.questionnaire}/${RouteUrls.list}`,
      level: 1,
      link: RouteUrls.list,
      navType: "master",
    });
    setLoading(false);
    return () => {
      dispatch(clearMaster());
    };
  }, []);

  return <Fragment>{loading ? <Loader /> : <Outlet />}</Fragment>;
};
export default QuestionnaireMaster;
