import { DropdownArrPropsType, FormObjType } from "DataTypes/Form";
import {
  ListInitialStateType,
  PatientInitialStateType,
  ScreenInitialStateType,
  UserInitialStateType,
} from "DataTypes/Redux";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setAlertData,
  setFormLoader,
  setRightHead,
  setTimerStatus,
} from "Redux/Screen/Action";
import { actionArr, DeviceCol, FormData } from "./Data";
import {
  CPDeviceListType,
  CPDeviceType,
} from "DataTypes/Services/Careplan.type";
import CarePlanApi from "Service/CarePlan.api";
import CPFormatter from "Utils/CP.Formatter";
import UserApi from "Service/User.api";
import { FormatDropdownData } from "Utils/DataFormatter";
import SettingsApi from "Service/Settings.api";
import { Box, Grid, Typography } from "@mui/material";
import { CommonStyle, ControlledTable, Modal } from "Components";
import { FormObjSwitch } from "Utils/SwitchCases";
import CommonFormatter from "Utils/Common.Formatter";
import { ModalLayout, TableLayout } from "Layouts";
import Format from "./Format";
import PatientFormatter from "Utils/Patient.Formatter";
import Stages from "Constant/Stages";
import { CommonButtonArray } from "Components/Modal/Data";
import CstmBtn from "Components/CstmBtn";
import { CheckNumber } from "Utils/common";
import { RouteUrls } from "Constant/RouteUrls";
import { addSelectedPrgm } from "Redux/Patient/Action";

const CareDevice: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedPrgm }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );
  const { trainingReq }: ListInitialStateType = useSelector(
    (state: any) => state?.list
  );
  const {
    rightHead: { timerStatus },
  }: ScreenInitialStateType = useSelector((state: any) => state?.screen);
  const { myInfo }: UserInitialStateType = useSelector(
    (state: any) => state?.user
  );
  const careId = selectedPrgm.careplanId;
  const [form, setForm] = useState<FormObjType[]>(
    JSON.parse(JSON.stringify(FormData))
  );
  const [tblRow, setTblRow] = useState<CPDeviceListType[]>([]);
  const [tblLoad, setTblLoad] = useState(false);
  const [eligibleList, setEligibleList] = useState<CPDeviceType[]>([]);
  const [openWarnModal, setOpenWarnModal] = useState(false);
  const [nextBtnWarn, setNextBtnWarn] = useState<string | null>(null);
  const [taskDeviceType, setTaskDeviceType] = useState<string | null>(null);
  const [editData, setEditData] = useState<CPDeviceListType | null>(null);
  const [suspendData, setSuspendData] = useState<CPDeviceListType | null>(null);
  const [viewData, setViewData] = useState<CPDeviceListType | null>(null);
  const currentStage = selectedPrgm.careplanStage;
  const recStage = PatientFormatter.GetCPStage(Stages.Recurring);
  const deviceStage = PatientFormatter.GetCPStage(Stages.CPDeviceAssign);
  const consentGainedCC =
    selectedPrgm.consentGainedBy == myInfo.id && currentStage < deviceStage + 1;
  const seniorTM = selectedPrgm.seniorTeamMember;
  const senTMConGCC = seniorTM || consentGainedCC;
  const patientId = selectedPrgm.patientId;
  const cpEnrolled =
    selectedPrgm.status == "Enrolled" ||
    selectedPrgm.status == "ENROLLED_DRAFT";
  const addDevice = senTMConGCC && currentStage < recStage && cpEnrolled;
  const editDevice = senTMConGCC && cpEnrolled;
  const suspendDevice = senTMConGCC && cpEnrolled;
  const reassignDevice = senTMConGCC && currentStage < recStage && cpEnrolled;
  const viewDevice = !senTMConGCC || !cpEnrolled;
  const formReadOnlyMode = !addDevice && !reassignDevice;
  const resetBtn = editDevice || addDevice || reassignDevice;
  const saveBtn = editDevice || addDevice || reassignDevice;
  const currentUrl = window.location.href;
  const deviceListUrl = currentUrl.includes(RouteUrls.rpmDeviceList);

  ///Next Btn Conditions
  const nextBtnCond1 = cpEnrolled && seniorTM && currentStage >= deviceStage;
  const nextBtnCond2 = !seniorTM && currentStage > deviceStage;
  const nextBtn = !deviceListUrl && (nextBtnCond1 || nextBtnCond2);

  ///Close Btn Conditions
  const closeBtnCond1 = deviceListUrl;
  const closeBtnCond2 = currentStage < deviceStage + 1 && !cpEnrolled;
  const closeBtnCond3 =
    currentStage < deviceStage + 1 && cpEnrolled && !seniorTM;
  const closeBtn = closeBtnCond1 || closeBtnCond2 || closeBtnCond3;

  useEffect(() => {
    if (timerStatus == 2)
      dispatch(setTimerStatus({ status: 1, parentApiCall: true }));
    dispatch(setRightHead(""));
    if (deviceListUrl) {
      CommonFormatter.HandleNavArr({
        id: "device-list",
        label: "RPM Device List",
        path: selectedPrgm.callDefaultRoute,
        level: 3,
        link: RouteUrls.rpmDeviceList,
        navType: "patient",
      });
    }
    loadDeviceHistory();
    initailLoad();
  }, []);

  //load initial data in the form
  const initailLoad: () => void = async () => {
    dispatch(setFormLoader(true));
    const newForm: FormObjType[] = await handleResetForm(form);
    const reminders = await loadReminder();
    const trainers = await loadTrainerSearch();
    const trainingType = await loadTrainingType();
    const deviceProvider = await loadDeviceProvider();

    if (newForm[2].boxType == "single-select") {
      newForm[2].dropArr = reminders;
    }
    if (newForm[3].boxType == "single-select") {
      newForm[3].dropArr = trainingReq;
    }
    if (newForm[4].boxType == "single-select") {
      newForm[4].dropArr = trainers;
    }
    if (newForm[5].boxType == "single-select") {
      newForm[5].dropArr = trainingType;
    }
    if (newForm[6].boxType == "single-select") {
      newForm[6].dropArr = deviceProvider;
    }
    setForm(newForm);
    dispatch(setFormLoader(false));
  };

  //Load device assignment history
  const loadDeviceHistory: () => void = async () => {
    setTblLoad(true);
    const res = await CarePlanApi.PatientDeviceSearch({
      id1: careId,
      data: {},
    });
    let arr: CPDeviceListType[] = [];
    if (res?.success) arr = CPFormatter.CheckCPPatientDevice(res?.data);
    setTblLoad(false);
    setTblRow(arr);
  };

  //Load device list eligible for assignment
  const loadEligibleDevices: () => Promise<
    DropdownArrPropsType[]
  > = async () => {
    const res = await CarePlanApi.DeviceTypeSearch({ id1: careId });
    let arr: DropdownArrPropsType[] = [];
    if (res?.success) {
      const filterArr = CPFormatter.CheckCPDeviceType(res?.data).filter(
        (el) => !el.deviceTypeAlreadyAssigned
      );
      setEligibleList(filterArr);
      arr = FormatDropdownData(
        filterArr,
        "deviceTypeId",
        "deviceTypeDescription",
        "deviceTypeId"
      );
    }
    return arr;
  };

  //Load device reminder list
  const loadReminder: () => Promise<DropdownArrPropsType[]> = async () => {
    const res = await CarePlanApi.DeviceReminderSearch({});
    let arr: DropdownArrPropsType[] = [];
    if (res?.success) {
      arr = FormatDropdownData(
        CPFormatter.CheckReminder(res?.data),
        "reminderId",
        "reminderDescription",
        "reminderId"
      );
    }
    return arr;
  };

  //Load org trainer list
  const loadTrainerSearch: () => Promise<DropdownArrPropsType[]> = async () => {
    const res = await UserApi.TrainerList({
      data: {},
      offset: 0,
      limit: 25,
    });
    if (res?.success) {
      return FormatDropdownData(res?.data, "userId", "userFullName", "userId");
    } else return [];
  };

  //Load training type list
  const loadTrainingType: () => Promise<DropdownArrPropsType[]> = async () => {
    const res = await CarePlanApi.TrainingTypeSearch({ data: {} });
    if (res?.success) {
      return FormatDropdownData(
        res?.data,
        "trainingTypeId",
        "description",
        "trainingTypeId"
      );
    } else return [];
  };

  //Load device provider list
  const loadDeviceProvider: () => Promise<
    DropdownArrPropsType[]
  > = async () => {
    const data = {
      isDeleted: false,
      groupLabel: "deviceprovider",
      groupSlug: "deviceprovider",
    };
    const res = await SettingsApi.searchDropSettings({ data });
    if (res?.success) {
      return FormatDropdownData(res?.data, "id", "label", "value");
    } else return [];
  };

  const handleResetForm: (
    form: FormObjType[]
  ) => Promise<FormObjType[]> = async (form) => {
    let newArr: FormObjType[] = JSON.parse(JSON.stringify(form));
    newArr = newArr.map((el) => {
      if (el.boxType == "single-select") {
        el.dropVal = null;
      }
      return {
        ...el,
        inputVal: "",
      };
    });
    const eligibleDevices = await loadEligibleDevices();
    newArr[0].required = true;
    newArr[0].readOnly = false;
    if (newArr[0].boxType == "single-select")
      newArr[0].dropArr = eligibleDevices;

    newArr[1].required = true;
    newArr[1].readOnly = true;

    newArr[2].required = true;
    newArr[2].readOnly = false;

    newArr[3].required = true;
    newArr[3].readOnly = false;

    newArr[4].required = false;
    newArr[4].readOnly = true;

    newArr[5].required = false;

    newArr[6].required = true;
    newArr[6].readOnly = false;

    newArr[7].required = false;
    newArr[7].readOnly = false;

    if (formReadOnlyMode) {
      newArr = newArr.map((el) => {
        return {
          ...el,
          readOnly: true,
        };
      });
    }
    return newArr;
  };

  const handleResetBtn: () => void = async () => {
    if (editData) setEditData(null);
    else if (viewData) setViewData(null);
    else {
      dispatch(setFormLoader(true));
      setForm(await handleResetForm(form));
      dispatch(setFormLoader(false));
    }
  };

  useEffect(() => {
    if (tblRow.length) {
      if (editData) {
        setForm(Format.HandleEditDevice(editData, form));
      } else handleResetEditForm();
    }
  }, [editData]);

  const handleResetEditForm: () => void = async () => {
    setForm(await handleResetForm(form));
    loadDeviceHistory();
  };

  useEffect(() => {
    if (tblRow.length) {
      if (viewData) {
        setForm(Format.HandleViewDevice(viewData, form));
      } else handleResetViewForm();
    }
  }, [viewData]);

  const handleResetViewForm: () => void = async () => {
    setForm(await handleResetForm(form));
  };

  const handleSave: (
    validateTask: boolean
  ) => Promise<number | null | void> = async (validateTask) => {
    const formValidate = CommonFormatter.HandleValidateForm(form);
    setForm(formValidate.formData);
    if (
      !formValidate.errorCheck &&
      formValidate.formData[0].boxType == "single-select" &&
      formValidate.formData[1].boxType == "single-select" &&
      formValidate.formData[2].boxType == "single-select" &&
      formValidate.formData[3].boxType == "single-select" &&
      formValidate.formData[4].boxType == "single-select" &&
      formValidate.formData[5].boxType == "single-select" &&
      formValidate.formData[6].boxType == "single-select" &&
      formValidate.formData[7].boxType == "input"
    ) {
      dispatch(setFormLoader(true));
      const readingReminder = formValidate.formData[2].dropVal || null;
      const isTrainingRequired =
        formValidate.formData[3].dropVal == "Yes" ? true : false;
      const trainerId = formValidate.formData[4].dropVal || null;
      const trainingType = formValidate.formData[5].dropVal || null;
      const deviceProvider = formValidate.formData[6].dropVal || null;
      const actualDeviceId = formValidate.formData[7].inputVal || null;
      const payload = {
        readingReminder,
        isTrainingRequired,
        trainerId,
        trainingType,
        deviceProvider,
        actualDeviceId,
        careplanId: careId,
      };
      if (editData) {
        const updatePayload = {
          ...payload,
          patientDeviceId: editData.id,
        };
        const res = await CarePlanApi.UpdatePatientDevice({
          data: updatePayload,
        });
        if (res?.success) {
          dispatch(setAlertData(res?.alert));
          setEditData(null);
        }
      } else {
        const deviceTypeId = formValidate.formData[0].dropVal || null;
        if (actualDeviceId || validateTask) {
          const addPayload = {
            ...payload,
            patientId: patientId,
            deviceId: formValidate.formData[1].dropVal || null,
          };
          const res = await CarePlanApi.AddPatientDevice({ data: addPayload });
          if (res?.success) {
            const res1 = await CarePlanApi.CopyGlobalToPatient({
              id1: patientId,
              data: { devicesTypeIds: [deviceTypeId], careplanId: careId },
            });
            if (res1.success) {
              dispatch(setAlertData(res?.alert));
              if (validateTask) {
                dispatch(setFormLoader(false));
                return CheckNumber(res?.data?.id);
              }
              handleReCheckNSetDevices(formValidate.formData);
            }
          }
        } else setOpenWarnModal(true);
      }
      dispatch(setFormLoader(false));
    }
  };

  const handleWarnModalYes: () => void = () => {
    setOpenWarnModal(false);
    if (form[0].boxType == "single-select") {
      const deviceTypeId = form[0].dropVal || null;
      const deviceTypeArr = form[0].dropArr || [];
      const findDevice = deviceTypeArr.find((el) => el.value == deviceTypeId);
      if (findDevice) setTaskDeviceType(findDevice.label);
    }
  };

  const handleTaskSuccess: () => void = async () => {
    setTaskDeviceType(null);
    handleReCheckNSetDevices(form);
  };

  const handleSuspendDevice: () => void = async () => {
    const res = await CarePlanApi.DeviceSuspend({
      id1: suspendData?.patientDeviceId,
    });
    if (res?.success) {
      setSuspendData(null);
      handleReCheckNSetDevices(form);
    }
  };

  const handleReCheckNSetDevices: (formArr: FormObjType[]) => void = async (
    formArr
  ) => {
    dispatch(setFormLoader(true));
    setForm(await handleResetForm(formArr));
    loadDeviceHistory();
    dispatch(setFormLoader(false));
  };

  const handleNext: () => void = async () => {
    dispatch(setFormLoader(true));
    if (nextBtn) {
      if (!(currentStage > deviceStage)) {
        const res = await CarePlanApi.CPStateUpdate({
          data: { toState: Stages.CPQues },
          id1: careId,
        });
        if (res?.success) {
          dispatch(
            addSelectedPrgm({
              ...selectedPrgm,
              careplanState: Stages.CPQues,
              careplanStage: currentStage + 1,
            })
          );
          navigate(`../${RouteUrls.careQuestion}`);
        }
      }
      navigate(`../${RouteUrls.careQuestion}`);
    }
    if (closeBtn) {
      if (deviceListUrl) navigate(`../${RouteUrls.clinicalSummary}`);
      else navigate(`../../${RouteUrls.clinicalSummary}`);
    }
    dispatch(setFormLoader(false));
  };

  const FormSection: JSX.Element = (
    <Grid container rowSpacing={1} columnSpacing={2}>
      {form.map((el) => {
        return (
          <Grid key={el.id} item {...{ md: 4, lg: 3, xl: 3 }}>
            {FormObjSwitch({
              formObj: el,
              inputChange: async (props) => {
                setForm(CommonFormatter.HandleInputChange(props, form));
              },
              onClick: async (props) =>
                setForm(await Format.HandleClickChange(props, form, tblRow)),
            })}
          </Grid>
        );
      })}
    </Grid>
  );

  const ButtonSection: JSX.Element = (
    <Box
      display="flex"
      justifyContent="flex-end"
      my={1}
      sx={{ "&>button": { ml: 1 } }}
    >
      {resetBtn && (
        <CstmBtn
          label={"Reset"}
          onClick={handleResetBtn}
          enableFormLoad={true}
        />
      )}
      {saveBtn && (
        <CstmBtn
          label={editData ? "Save Changes" : "Save"}
          onClick={() => handleSave(false)}
          disable={
            form.length && form[0].boxType == "single-select" && form[0].dropVal
              ? false
              : true
          }
          enableFormLoad={true}
        />
      )}
      {nextBtn && (
        <CstmBtn
          label={"Next"}
          onClick={() =>
            eligibleList.length ? setNextBtnWarn("next") : handleNext()
          }
          disable={tblRow.length < 1}
          enableFormLoad={true}
        />
      )}
      {closeBtn && (
        <CstmBtn
          label={"Close"}
          onClick={() =>
            eligibleList.length && consentGainedCC
              ? setNextBtnWarn("close")
              : handleNext()
          }
          enableFormLoad={true}
        />
      )}
    </Box>
  );

  const TblBodyJSX: JSX.Element = (
    <ControlledTable.TblBody
      tableColumn={() => DeviceCol}
      tableRow={tblRow}
      tableAction={(rowData: CPDeviceListType) =>
        actionArr(
          () => setEditData(rowData),
          () => setSuspendData(rowData),
          () => setViewData(rowData),
          editDevice,
          suspendDevice,
          viewDevice,
          rowData
        )
      }
    />
  );

  return (
    <Box width="91%" sx={{ ...CommonStyle.sxWhiteCard, overflow: "auto" }}>
      {FormSection}
      {ButtonSection}
      <TableLayout
        tblBody={TblBodyJSX}
        tblHead={ControlledTable.TblHead(DeviceCol)}
        height={""}
        loading={tblLoad}
      />

      {/* Add device without device id warning modal */}
      <ModalLayout
        modwidth="40%"
        heading={"Warning"}
        content={
          <Box textAlign={"center"}>
            <Typography>Device ID details are not provided.</Typography>
            <Typography my={1}>
              Would you like to create a task to order the new device?
            </Typography>
            <Typography>
              If not, please enter the Device ID or the device will not be added
              to the patient’s device list.
            </Typography>
          </Box>
        }
        open={openWarnModal}
        onClose={() => setOpenWarnModal(false)}
        buttonArr={CommonButtonArray(
          handleWarnModalYes,
          () => setOpenWarnModal(false),
          "Yes",
          "No"
        )}
      />

      {/* Create new task for device*/}
      <ModalLayout
        modwidth="80%"
        heading={"Create Task"}
        content={
          <Modal.DeviceTask
            from="care plan"
            closeModal={() => setTaskDeviceType(null)}
            handleAddDevice={() => handleSave(true)}
            deviceType={taskDeviceType || ""}
            handleTaskAddSuccess={handleTaskSuccess}
          />
        }
        open={taskDeviceType ? true : false}
        onClose={() => setTaskDeviceType(null)}
      />

      {/* Suspension Modal */}
      <ModalLayout
        modwidth="40%"
        heading={"Warning"}
        content={
          <Box textAlign={"center"}>
            <Typography>
              All reading from the device will stop getting captured after
              suspension. Are you sure you want to suspend the device?
            </Typography>
          </Box>
        }
        open={suspendData ? true : false}
        onClose={() => setSuspendData(null)}
        buttonArr={CommonButtonArray(
          handleSuspendDevice,
          () => setSuspendData(null),
          "Yes",
          "No"
        )}
      />

      {/* Warning before going to next screen or exiting */}
      <ModalLayout
        modwidth="40%"
        heading={"Warning"}
        content={
          <Box textAlign={"center"}>
            <Typography>
              Are you sure you have assigned all needed devices to the patient
              {nextBtnWarn == "next"
                ? " and want to move to the next screen?"
                : " and want to exit the treatment plan?"}
            </Typography>
          </Box>
        }
        open={nextBtnWarn ? true : false}
        onClose={() => setNextBtnWarn(null)}
        buttonArr={CommonButtonArray(
          handleNext,
          () => setNextBtnWarn(null),
          "Yes",
          "No"
        )}
      />
    </Box>
  );
};
export default CareDevice;
