import { Box, Typography } from "@mui/material";
import { Fragment, useEffect, useState, FC } from "react";
import {
  CommonStyle,
  ControlledForm,
  ControlledTable,
  Modal,
} from "Components";
import { ModalLayout, TableLayout } from "Layouts";
import { FormDataArr, TableCol, actionArr } from "./Data";
import { CheckStringEmpty, HandleFormChange } from "Utils/common";
import { useDispatch } from "react-redux";
import { setAlertData } from "Redux/Screen/Action";
import { DropdownArrPropsType, FormDataType } from "DataTypes/Form";
import Formate from "./Formate";
import SettingsApi from "Service/Settings.api";
import {
  MasterAppSetType,
  MasterKeySetType,
} from "DataTypes/Services/Settings.type";
import { FormatDropdownData } from "Utils/DataFormatter";
import SettingsFormatter from "Utils/Settings.Formatter";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const ApplicationSettings: FC = () => {
  const dispatch = useDispatch();
  const [masterList, setMasterList] = useState<MasterAppSetType[]>([]);
  const [selectedGrp, setSelectedGrp] = useState("");
  const [settingsList, setSettingsList] = useState<MasterKeySetType[]>([]);
  const [settingGroup, setSettingGroup] = useState<DropdownArrPropsType[]>([]);
  const [editRow, setEditRow] = useState<MasterKeySetType | null>(null);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<FormDataType[]>([]);

  useEffect(() => {
    getAppSettingFunction();
    CommonFormatter.HandleNavArr({
      id: "app-settings",
      label: "Application Settings",
      path: `/${RouteUrls.hs}/${RouteUrls.setting}/${RouteUrls.appSettings}`,
      level: 1,
      link: RouteUrls.appSettings,
      navType: "settings",
    });
  }, []);

  const getAppSettingFunction = async () => {
    const { success, data } = await handleAppApiCall();
    if (success) {
      setSettingGroup(
        FormatDropdownData(data, "id", "groupLabel", "groupSlug")
      );
      data.length > 0 && setSelectedGrp(data[0].id);
    }
    setMasterList(data);
    setLoading(false);
  };

  const handleAppApiCall: (groupSlug?: string) => Promise<{
    success: boolean;
    data: MasterAppSetType[];
  }> = async (groupSlug) => {
    const payload = {
      ...(groupSlug ? { groupSlug } : {}),
    };
    const res: any = await SettingsApi.listApplicationSettings({
      data: payload,
    });
    if (res?.success) {
      const formattedData = SettingsFormatter.List(res?.data);
      const masterData = Formate.Settings(formattedData);
      return {
        success: true,
        data: masterData,
      };
    } else {
      return {
        success: false,
        data: [],
      };
    }
  };

  useEffect(() => {
    handleSetTbl();
  }, [selectedGrp, masterList]);

  const handleSetTbl: () => void = () => {
    const findKeyInd = masterList.findIndex((el) => el.id == selectedGrp);
    if (findKeyInd == -1) setSettingsList([]);
    else setSettingsList(masterList[findKeyInd].keys);
  };

  useEffect(() => {
    handleEditRow();
  }, [editRow]);

  const handleEditRow: () => void = () => {
    let newArr: FormDataType[] = JSON.parse(JSON.stringify(FormDataArr));
    if (editRow) {
      newArr[0].value = editRow.keyLabel;
      newArr[1].value = editRow.value;
      newArr[1].type = editRow.typeId == "string" ? "text" : "number";
      setEditModal(true);
    } else {
      newArr = [];
      setEditModal(false);
    }
    setFormData(newArr);
  };

  const handleEditSave: () => void = async () => {
    const grp = masterList.find((el) => el.groupSlug == selectedGrp);
    const payload = {
      groupLabel: grp?.groupLabel || "",
      groupSlug: grp?.groupSlug || "",
      label: "",
      keyLable: editRow?.keyLabel || "",
      keySlug: editRow?.keySlug || "",
      value: formData[1].value,
      typeId: editRow?.typeId || "",
    };
    const res: any = await SettingsApi.UpdateAppSettings({
      id1: editRow?.id,
      data: payload,
    });
    if (res?.success) {
      dispatch(setAlertData(res?.alert));
      setEditRow(null);
      const { success, data } = await handleAppApiCall(grp?.groupSlug);
      if (success) {
        setMasterList(
          masterList.map((el) => {
            if (el.groupSlug == grp?.groupSlug && data.length > 0)
              el.keys = data[0].keys;
            return el;
          })
        );
      }
    }
  };

  const TblBodyJSX = (
    <ControlledTable.TblBody
      tableColumn={() => TableCol}
      tableRow={settingsList}
      tableAction={(data) => actionArr(() => setEditRow(data))}
    />
  );

  return (
    <Fragment>
      <ModalLayout
        modwidth="35%"
        heading={"Edit Setting"}
        content={
          <Modal.AppSettingsModal
            formArr={formData}
            handleChange={(data) =>
              setFormData(HandleFormChange(data, formData))
            }
          />
        }
        open={editModal}
        onClose={() => setEditRow(null)}
        buttonArr={[
          {
            id: "1",
            label: "Save",
            clickHandler: handleEditSave,
            disable: formData[1]?.error,
          },
          {
            id: "2",
            label: "Cancel",
            clickHandler: () => setEditRow(null),
            type: "border",
          },
        ]}
      />
      <Box sx={{ my: 2 }}>
        <Typography sx={{ ...CommonStyle.sxHeading }}>
          {"Application Settings"}
        </Typography>
        <ControlledForm.SingleSelectDrop
          id="settingGroup"
          dropValue={selectedGrp}
          onClick={(value) => setSelectedGrp(CheckStringEmpty(value))}
          dropArr={settingGroup}
          placeholder="Select Setting Group"
          label="Select Setting Group"
          sxProps={{ mb: 1, width: "20%" }}
        />
        <TableLayout
          tblBody={TblBodyJSX}
          loading={loading}
          tblHead={ControlledTable.TblHead(TableCol)}
          height={"100%"}
        />
      </Box>
    </Fragment>
  );
};
export default ApplicationSettings;
