import { FC } from "react";
import { Box } from "@mui/material";
import ImgPath from "Constant/Imgs";
import { LoginLayoutType } from "DataTypes/Layout";

const LoginLayout: FC<LoginLayoutType> = ({ rightBox }) => {
  return (
    <Box sx={{ ...sxMain }}>
      <img src={ImgPath.Login_Img1} alt="" />
      {rightBox}
    </Box>
  );
};
export default LoginLayout;

const sxMain = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
  "& >img:first-of-type": {
    width: "60%",
    height: "100%",
  },
};
