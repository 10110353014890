import { Fragment, useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { CommonModalType } from "DataTypes/Modal";
import { commonFormSwitch } from "Utils/SwitchCases";
import { FormDataType } from "DataTypes/Form";

const VerifyOtp = ({ formArr, handleChange }: CommonModalType) => {
  const [formData, setFormData] = useState<FormDataType[]>(
    JSON.parse(JSON.stringify(formArr))
  );

  useEffect(() => {
    formArr && setFormData(formArr);
  }, [formArr]);

  return (
    <Fragment>
      <Box height="70%">
        <Typography sx={{ mb: 1 }}>
          We have sent a 6 digit verification code on your registered Email
          ID/Mobile Number. Please enter the verification code to login.
        </Typography>
        {formData &&
          handleChange &&
          formData.length > 0 &&
          formData.map((el) => {
            return (
              <Box key={el.id}>
                {commonFormSwitch({
                  formObj: el,
                  onChange: (data) => handleChange(data, formData),
                })}
              </Box>
            );
          })}
      </Box>
    </Fragment>
  );
};
export default VerifyOtp;
