import { Fragment, FC } from "react";
import { Box, Typography } from "@mui/material";
import { ConsentData } from "Constant/Arr";
import { PatientInitialStateType } from "DataTypes/Redux";
import { useSelector } from "react-redux";
import DateTime from "Utils/DateTime";

const ProgramConsentModal: FC<{ modalPrgm: string }> = ({ modalPrgm }) => {
  const { patientInfo }: PatientInitialStateType = useSelector(
    (state: any) => state?.patient
  );

  return (
    <Fragment>
      <Box>
        <Box sx={{ ...sxConHead }}>
          <Typography>New Consent Gained</Typography>
          <Box sx={{ ...sxTimeBox }}>
            <Typography>Date</Typography>
            <Typography>{DateTime.CurrentDate()}</Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ py: 2 }}
        >
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              mr: "10px",
            }}
          >
            Enrolled under Program:
          </Typography>
          <Typography
            variant="body2"
            sx={{
              p: 0.5,
            }}
          >
            {modalPrgm}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "1.2rem",
            pb: 2,

            fontWeight: 700,
          }}
        >
          Recipient:
        </Typography>
        <Typography variant="body2" sx={{ pb: 2 }}>
          {`${
            patientInfo.name
          } gave verbal consent for ${modalPrgm} services on ${DateTime.CurrentDate()}.`}
        </Typography>
        <Typography variant="body2" sx={{ pb: 2 }}>
          {"Beneficiary consented to " +
            modalPrgm +
            " services and the patient was explained the benefits."}
        </Typography>
        {ConsentData(modalPrgm || "").map((el) => {
          return (
            <Typography
              variant="body2"
              key={el.id}
            >{`(${el.id}) ${el.label}`}</Typography>
          );
        })}
      </Box>
    </Fragment>
  );
};

export default ProgramConsentModal;

const sxConHead = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "& >p:first-of-type": {
    fontSize: "1.5rem",
    fontWeight: "bold",
    width: "100%",
  },
};

const sxTimeBox = {
  width: "15%",
  "& >p:first-of-type": {
    fontSize: "0.75rem",
  },
  "& >p:last-child": {
    fontSize: "1rem",
    fontWeight: "500",
  },
};
