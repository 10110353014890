/////Bug resolve Azure-391

import { FormDataType } from "DataTypes/Form";
import { TargetFormatter } from "DataTypes/PatientManagement.type";
import { objectValues } from "DataTypes/Services/Index.type";
import DateTime from "Utils/DateTime";

const capitalizeFirstLetter = (str: string) => {
  if (!str) return ""; // Handle empty string
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatFormData = (
  item: string,
  data: objectValues,
  dropArr?: objectValues[]
) => {
  switch (item) {
    case "source":
      return capitalizeFirstLetter(data?.source);

    case "type":
      return capitalizeFirstLetter(data?.type);

    case "task-description":
      return data?.taskDescription;

    case "subject":
      return data?.subject;

    case "task-assign-to":
      return dropArr && data?.assigneeUserId
        ? capitalizeFirstLetter(
          dropArr?.filter((item) => item.id === data?.assigneeUserId)[0]
            ?.label
        )
        : capitalizeFirstLetter(data?.groupName);

    case "note":
      return data?.notes;

    case "status":
      return data?.status;

    default:
      break;
  }
};

export const checkTargetdate = (data: TargetFormatter) => {
  const newArr = data.formData.map((el) => {
    if (data.priorityType == "Urgent") {
      el.readOnly = true;
    } else if (data.userId === data.assigneeUserId && data.incoming) {
      el.readOnly = true;
    } else if (!data.taskId && data.priorityType !== "Normal") {
      el.readOnly = false;
    } else {
      el.readOnly = data.view;
      el.value = "";
    }
    if (!data.taskId && data.priorityType !== "Normal") {
      el.value = DateTime.TomorrowDateTime();
    } else if (data.targateDate) {
      el.value = DateTime.UTCToLocalDateTime(data.targateDate);
    }
    return el;
  });
  return newArr;
};

export const formatList = (list: objectValues[]) => {
  return list.map((item: objectValues) => {
    return {
      ...item,
      label: item.name
        ? capitalizeAllWords(item.name)
        : item?.firstName
          ? capitalizeAllWords(item?.firstName) +
          `${item?.lastName ? " " + capitalizeAllWords(item?.lastName) : ""}` +
          `${
            item?.designation
              ? ", " + capitalizeAllWords(item?.designation)
              : ""
          }`
          : capitalizeAllWords(item.userFullName) || "",
      value: item.name
        ? capitalizeAllWords(item.name)
        : item?.firstName
          ? capitalizeAllWords(item?.firstName) +
          `${item?.lastName ? " " + capitalizeAllWords(item?.lastName) : ""}` +
          `${
            item?.designation
              ? ", " + capitalizeAllWords(item?.designation)
              : ""
          }`
          : item.userId || "",
      ...(item.userId && {
        id: item.userId,
      }),
    };
  });
};

export const capitalizeAllWords = (str: string) => {
  if (!str) return ""; // Handle empty string
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const applyConditionToList = (
  list: FormDataType[],
  condition: string,
  value: any,
  loggedUser: number
) => {
  list.forEach((item: FormDataType) => {
    switch (item.id) {
      case "source":
        item.dropValue =
          condition === "add"
            ? "Authorized Representative"
            : value?.source
              ? value.source
              : null;
        item.readOnly = true;
        break;
      case "type":
        item.dropValue =
          condition === "add"
            ? "Device Order"
            : value?.type
              ? value.type
              : null;
        item.readOnly = true;
        break;
      case "task-assign-to":
        item.dropValue = value?.assigneeUserId ? value?.assigneeUserId : null;
        item.readOnly =
          condition === "add"
            ? false
            : condition === "view"
              ? true
              : condition === "global"
                ? false
                : loggedUser === value.assigneeUserId ||
              loggedUser === value.createdBy
                  ? false
                  : true;
        break;
      case "status":
        item.dropValue = value ? value : null;
        item.readOnly = condition !== "incoming" && condition !== "outgoing";
        break;
      case "note":
        item.value = value?.notes ? value.notes : null;
        item.readOnly =
          condition !== "incoming" &&
          condition !== "outgoing" &&
          condition !== "add";
        break;
      case "subject":
        item.value = value?.subject ? value.subject : null;
        item.readOnly = condition !== "outgoing" && condition !== "add";
        break;
      case "task-description":
        item.value = value?.taskDescription ? value.taskDescription : null;
        item.readOnly = condition !== "outgoing" && condition !== "add";
        break;
      case "deviceId":
        item.value = value?.actualDeviceId || "";
        item.readOnly = condition === "view" || !value?.taskId;
        break;
      case "deviceType":
        item.value = item.value = value?.deviceType
          ? value?.deviceType
          : "Blood Pressure";
        item.readOnly = true;
        break;
      default:
        break;
    }
  });
  return list;
};
