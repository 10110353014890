import { FC, useState, Fragment, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { CommonStyle, ControlledForm, Loader } from "Components";
import { PrgmInsInsuranceBoxType } from "DataTypes/Master";
import { ControlledBoxSizeSwitch } from "Utils/SwitchCases";
import { OptionsProps } from "DataTypes/Form";
import CstmBtn from "Components/CstmBtn";
import InsuranceApi from "Service/Insurance.api";
import {
  InsuranceListResType,
} from "DataTypes/Services/Prgm.type";
import PrgmFormatter from "Utils/Prgm.Formatter";

const InsuranceBox: FC<PrgmInsInsuranceBoxType> = (props) => {
  const { handleSave, selectedPrg, InsuranceList } =
    props;
    
  const [loading, setLoading] = useState(true);
  const [mappedInsurances, setMappedInsurances] = useState<InsuranceListResType[]>(
    []
  );
  const [localChanges, setLocalChanges] = useState<InsuranceListResType[]>([]);
  const [viewSave, setViewSave] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const getLinkedInsurances: (
    programId: number | string | null
  ) => void = async (programId) => {
    setLoading(true);
    const res: any = await InsuranceApi.read({
      data: {
        programId: programId,
      },
    });
    if (res.success) {
      const verifiedArr = PrgmFormatter.InsuranceListRes(
        res?.data?.insurance
      );
      setMappedInsurances(verifiedArr);
      setLocalChanges(verifiedArr);
    }
    setLoading(false);
  };

  const compareArr: () => void = () => {
    const newArr1 = mappedInsurances.map((el) => el.insuranceId);
    const newArr2 = localChanges.map((el) => el.insuranceId);
    const difference = newArr1
      .filter((x) => !newArr2.includes(x))
      .concat(newArr2.filter((x) => !newArr1.includes(x)));
    setViewSave(difference.length > 0 ? true : false);
  };


  useEffect(() => {
    selectedPrg && selectedPrg.id && getLinkedInsurances(selectedPrg.id);
  }, [selectedPrg]);

  useEffect(() => {
    compareArr();
    checkSelectAll();
  }, [localChanges]);

  const handleChange: (obj: OptionsProps) => void = (obj) => {
    const find = localChanges.find((el) => el.insuranceId == obj.id);
    if (find) {
      setLocalChanges(localChanges.filter((el) => el.insuranceId != obj.id));
    } else {
      const find = InsuranceList.find((el) => el.insuranceId == obj.id);
      if (find) {
        setLocalChanges([
          ...localChanges,
          {
            companyId: find?.companyId,
            planId: find?.planId,
            companyName: find?.companyName,
            planName: find?.planName,
            insuranceId: find?.insuranceId,
          },
        ]);
      }
    }
  };

  const checkSelectAll: () => void = () => {
    const newArr1 = InsuranceList.map((el) => el.insuranceId);
    const newArr2 = localChanges.map((el) => el.insuranceId);
    const difference = newArr1
      .filter((x) => !newArr2.includes(x))
      .concat(newArr2.filter((x) => !newArr1.includes(x)));
    if (difference.length > 0) setSelectAll(false);
    else setSelectAll(true);
  };

  const handleSelectAll: () => void = () => {
    if (selectAll) setLocalChanges([]);
    else setLocalChanges([...InsuranceList]);
  };

  const handleSaveClick: () => void = async () => {
    if (selectedPrg) {
      const saveReturn = await handleSave(
        localChanges.map((el) => {
          return {
            planId: el.planId,
            companyId: el.companyId,
          };
        })
      );
      if (saveReturn == true) getLinkedInsurances(selectedPrg.id);
    }
  };

  return (
    <Box height="100%" sx={{ ...CommonStyle.sxWhiteBox }}>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Box sx={{ ...sxHead }}>
            <Typography variant="subtitle1">
              {`Insurance > ${selectedPrg?.name}`}
            </Typography>
            <Box sx={{ ...CommonStyle.sxRow, "& >button": { ml: 1 } }}>
              <ControlledForm.CheckBoxGroup
                items={[{ id: 10, title: "Select All", checked: selectAll }]}
                onChange={() => handleSelectAll()}
              />
              <CstmBtn
                label="Save"
                onClick={handleSaveClick}
                disable={!viewSave}
              />
            </Box>
          </Box>
          <Box sx={{ ...sxBody }}>
            <Grid container spacing={1}>
              {InsuranceList.map((el) => {
                const found = localChanges.find(
                  (el1) => el1.insuranceId == el.insuranceId
                );
                return (
                  <Grid
                    key={el.insuranceId}
                    item
                    {...ControlledBoxSizeSwitch(12)}
                  >
                    <ControlledForm.CheckBoxGroup
                      items={[
                        {
                          id: el.insuranceId,
                          title: `${el.companyName} ${el.planName}`,
                          checked: found ? true : false,
                        },
                      ]}
                      onChange={(obj) => handleChange(obj)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
export default InsuranceBox;

const sxHead = {
  height: "10%",
  px: 2,
  ...CommonStyle.sxRow,
  justifyContent: "space-between",
  "& >h6:first-of-type": {
    fontWeight: "bold",
  },
};

const sxBody = {
  height: "90%",
  overflow: "auto",
  pl: 2,
};
