import { Box } from "@mui/material";
import { CommonStyle, SubNavBar } from "Components";
import { SubNavDataType } from "DataTypes/Layout";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { global, group, incoming, outgoing } from "./Data";
import { RouteUrls } from "Constant/RouteUrls";
import CommonFormatter from "Utils/Common.Formatter";

const Tasks: FC = () => {
  const location = useLocation();
  const pathArr = location.pathname.split("/");
  const currentPath = pathArr[pathArr.length - 1];
  const { myPermission }: any = useSelector((state: any) => state?.user);
  const [arr, setArr] = useState<SubNavDataType[]>([]);

  useEffect(() => {
    setArr(TaskArr(myPermission?.dashlinks));
  }, []);

  useEffect(() => {
    if (arr.length) {
      const obj = arr.find((el) => el.link == currentPath);
      CommonFormatter.HandleNavArr({
        id: "task-list",
        label: obj?.label || "",
        path: `/${RouteUrls.hs}/${RouteUrls.tasking}/${obj?.link}`,
        level: 1,
        link: obj?.link || "",
        navType: "patient",
      });
    }
  }, [currentPath, arr]);

  return (
    <Box height="91%">
      <SubNavBar data={arr} />
      <Box
        height="92%"
        sx={{ ...CommonStyle.sxWhiteBoxWithNavbar, px: 2, overflow: "auto" }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default Tasks;

export const TaskArr = (taskPermission: any) => {
  const Arr: SubNavDataType[] = [
    ...(taskPermission?.incomingtask ? [incoming] : []),
    ...(taskPermission?.outgoingtask ? [outgoing] : []),
    ...(taskPermission?.grouptask ? [group] : []),
    ...(taskPermission?.globaltask ? [global] : []),
  ];
  return Arr;
};
