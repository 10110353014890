import { useState, FC, useEffect } from "react";
import { Box, ClickAwayListener } from "@mui/material";
import { DropdownArrPropsType, MultiDropType } from "DataTypes/Form";
import MultiSelectDrop from "./Box";
import ControlledForm from "Components/ControlledForm";

const MultiApi: FC<MultiDropType> = (props) => {
  const {
    dropArr,
    disableArr,
    inputChange,
    dropVal,
    onClick,
    loading,
    apiSearch,
    inputVal,
    enableFormLoad,
    readOnly,
  } = props;
  const [openDrop, setOpenDrop] = useState(false);
  const [localDropArr, setLocalDropArr] = useState<DropdownArrPropsType[]>([]);
  const [localInput, setLocalInput] = useState("");

  useEffect(() => {
    setLocalDropArr(dropArr || []);
  }, [dropArr]);

  useEffect(() => {
    setLocalInput(inputVal || "");
  }, [inputVal]);

  const handleSelect: (obj: DropdownArrPropsType) => void = (obj) => {
    if (dropVal) {
      const found = dropVal?.find((el) => el == obj.value);
      if (!found) onClick && onClick([...dropVal, obj.value]);
      else {
        onClick && onClick(dropVal.filter((el) => el !== obj.value));
      }
    }
  };

  const handleInput: (val: string) => void = (val) => {
    if (apiSearch) inputChange && inputChange(val);
    else {
      setLocalInput(val);
      setLocalDropArr([
        ...localDropArr.filter((el) =>
          el.label.toLowerCase().includes(val.toLowerCase())
        ),
      ]);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenDrop(false)}>
      <Box sx={{ ...sxDrop }} width={"100%"}>
        <ControlledForm.InputApi
          {...props}
          iconClick={() => setOpenDrop(openDrop ? false : true)}
          iconId={openDrop ? "dropup" : "dropdown"}
          inputChange={(val) => typeof val == "string" && handleInput(val)}
          onFocusChange={() => setOpenDrop(true)}
          inputVal={localInput}
          readOnly={readOnly}
          enableFormLoad={enableFormLoad}
          loading={loading}
        />
        {openDrop && (
          <MultiSelectDrop
            dropArr={localDropArr}
            dropVal={dropVal || []}
            clickedItem={(el) => handleSelect(el)}
            disableArr={disableArr}
            loading={loading || false}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};
export default MultiApi;

const sxDrop = {
  position: "relative",
  cursor: "pointer",
};
